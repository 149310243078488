/* eslint-disable max-len */
/* eslint-disable no-bitwise */
/** This file contains all non-APP specific browser utilities  */
import _ from 'lodash';

// Utility
import { BROWSER_PERMISSIONS_STATUS, PERMISSION, TABLET_WIDTH } from 'UTILS/constants/UtilityConstants';
import Utils from 'UTILS/CommonUtility';
import { isMobileOnly, isTablet } from 'react-device-detect';
import WebrtcUtils from 'SERVICES/MediaServerService/WebRtcUtils';

// Constants
import { FILL_LIGHT_MODE, MEDIA_TYPE, MEDIA_DEVICE_TYPE } from 'UTILS/constants/MediaServerConstants';
import { IlluminationModeFlags } from 'UTILS/constants/DatastreamConstant';
import { CALL_DASHBOARD } from 'UTILS/constants/DOMElementConstants';

// Services
import { AppLogger, LOG_NAME } from 'SERVICES/Logging/AppLogger';

const logger = AppLogger(LOG_NAME.WebAPI);

export const DEVICE_CAMERA = {
  FRONT: 'Front Camera',
  BACK: 'Back Camera',
};

export const GUM_EXCEPTION = {
  ABORT_ERROR: 'AbortError',
  NOT_ALLOWED_ERROR: 'NotAllowedError',
  NOT_FOUND_ERROR: 'NotFoundError',
  NOT_READABLE_ERROR: 'NotReadableError',
  OVER_CONSTRAINED_ERROR: 'OverconstrainedError',
  SECURITY_ERROR: 'SecurityError',
  TYPE_ERROR: 'TypeError',
};

export const WINDOW_EVENTS = {
  ORIENTATION_CHANGE: 'orientationChange',
};

class BrowserAPI {
  static initBrowserAPI() {
    if (!BrowserAPI.instance) {
      BrowserAPI.instance = new BrowserAPI();
    }
    return BrowserAPI.instance;
  }

  constructor() {
    // Initialize and get the browser
    this.userAgent = navigator.userAgent;
    this.isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' &&
      navigator.maxTouchPoints > 1)) &&
      !window.MSStream;

    this.isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') === -1 &&
      navigator.userAgent.indexOf('FxiOS') === -1;

    this.isBrowserPermissions = {
      audio: false,
      video: false,
      geoLocation: false,
    };

    // Cached list of devices
    this.deviceList = null;
  }

  /**
   * Gets lists of IN/OUT media devices on the device
   *
   * @param {callback} cb Callback to be called on completion of asyc call to get devices
   * Callback returns the cached or updated devicelist along with error if any.
   * @param {Object} options { forceRefresh: false, fetchVideoSource: false }
   * specifies flags indicating the getDevies operation
   * - forceRefresh - when true will force a browser API query to fetch devices from system.
   *  When false - will simply return the cached list
   * - fetchVideoSource - when set to true, indicates that
   * @returns none
   */
  async getDevices(cb, options = null) {
    let processedList = null;
    logger.assert(typeof cb === 'function', 'Invalid argument', cb);
    logger.debug('DEVICES:: getting with options', options);
    // Return the cached list of devices if we have one and app
    // does not expect a refresh
    if (!options?.forceRefresh && this.deviceList) {
      cb(this.deviceList);
      return;
    }
    if (options?.fetchVideoSource) {
      // Application wants to get video sources
      // Seek permission if not already has it.
      await this.isBrowserPermissionsAllowed({ video: true },
        { forceCheck: options.forceRefresh });
      logger.debug('DEVICES::Permissions', this.isBrowserPermissions);
    }
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      processedList = WebrtcUtils.processListOfDevices(devices);
      this.deviceList = processedList;
      logger.debug('DEVICES::', processedList.videoInputDevices, processedList.audioInputDevices);
      cb(processedList, this.isBrowserPermissions.lastErr ?? null);
    }).catch((err) => {
      logger.error('BrowserAPI: Error getting list of devices', err);
      cb(null, err);
    });
  }

  /**
   * Generic function to register watch for browser permission changes
   * @param {PERMISSION} permissionName
   * @param {*} onPermissionChangeCB
   */
  onBrowserPermissionChange = (permissionName, onPermissionChangeCB = null) => {
    const prevPermissionsStatus = _.clone(this.isBrowserPermissions);
    navigator?.permissions?.query({ name: permissionName }).then((result) => {
      result.onchange = () => {
        if (permissionName === PERMISSION.CAMERA) {
          this.isBrowserPermissions.video = result.state !== BROWSER_PERMISSIONS_STATUS.DENIED;
        } else if (permissionName === PERMISSION.MIC) {
          this.isBrowserPermissions.audio = result.state !== BROWSER_PERMISSIONS_STATUS.DENIED;
        } else if (permissionName === PERMISSION.GEOLOCATION) {
          this.isBrowserPermissions.geoLocation = result.state !== BROWSER_PERMISSIONS_STATUS.DENIED;
        }
        logger.assert(this.isBrowserPermissions !== prevPermissionsStatus,
          'BrowserAPI: Getting onchange event without change in permissions!');
        if (onPermissionChangeCB) onPermissionChangeCB(this.isBrowserPermissions);
      };
      return this.isBrowserPermissions;
    }).catch((error) => {
      logger.error('BrowserAPI: Error while detecting browser permission change', JSON.stringify(error));
      return prevPermissionsStatus;
    });
  }

  /**
   * Checks prevelant state of broswer permission status
   *
   * @param {boolean} video true to check permission for video device
   * @param {boolean} audio true to check permission for audio device
   * @param {string} deviceId videoDeviceId to check permission of that device
   * @returns true/false based on status of permission
   */
  isBrowserPermissionsAllowed = async (
    media = { video: false, audio: false },
    options = { deviceId: null, forceCheck: false },
  ) => {
    let constraint;
    const { deviceId, forceCheck } = options;

    if (!forceCheck &&
      ((media?.video && this.isBrowserPermissions?.video) ||
      (media?.audio && this.isBrowserPermissions?.audio))) {
      // No need to get if we already have required media permission
      // and app does not need refresh
      return this.isBrowserPermissions;
    }

    if (Utils.isIOS() || ((navigator.userAgent.match(/Mac OS/i) &&
       window.safari))) {
      this.isBrowserPermissions.audio = true;
      this.isBrowserPermissions.video = true;
      return this.isBrowserPermissions;
    }
    if (media.video) {
      constraint = deviceId ? { deviceId: { exact: deviceId } } : true;
    }
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: media.video ? constraint : undefined,
        audio: media.audio,
      });
      stream?.getTracks()?.forEach((track) => {
        track.stop();
      });
      this.isBrowserPermissions.video = media.video ? constraint : this.isBrowserPermissions.video;
      this.isBrowserPermissions.audio = media.audio ? media.audio : this.isBrowserPermissions.audio;
    } catch (err) {
      this.isBrowserPermissions.audio = media.audio ? false : this.isBrowserPermissions.audio;
      this.isBrowserPermissions.video = media.video ? false : this.isBrowserPermissions.video;
      logger.warn(`BrowserAPI::isBrowserPermissionsAllowed() Error for permission for ${media}`, err);
      this.isBrowserPermissions.lastErr = err; // Will get cleaned on next request
    }
    return this.isBrowserPermissions;
  }

  isGeoLocationPermissionAllowed = async () => {
    if (Utils.isIOS() || ((navigator.userAgent.match(/Mac OS/i) &&
    window.safari))) {
      this.isBrowserPermissions.geoLocation = true;
      return this.isBrowserPermissions.geoLocation;
    }
    const permissionStatus = await navigator?.permissions?.query({ name: PERMISSION.GEOLOCATION });
    this.isBrowserPermissions.geoLocation = permissionStatus.state !== BROWSER_PERMISSIONS_STATUS.DENIED;
    return this.isBrowserPermissions.geoLocation;
  }

  onDeviceChange = (onDeviceChangeCB) => {
    navigator.mediaDevices.ondevicechange = () => {
      logger.info(
        'BrowserAPI::Event-navigator.mediaDevices.ondevicechange::Update list of devices on addition or removal of audio/video devices::',
      );
      // Update the list of devices; get the latest
      this.getDevices(onDeviceChangeCB, { forceRefresh: true });
    };
  }

  onWindowResize = (onResizeCB) => {
    window.onresize = () => {
      onResizeCB();
    };
  }

  /**
   * Attach the media stream (audio/video) to DOM element and auto play.
   * @param {DOMElement} mediaElement
   * @param {MediaStream} stream
   * @param {bool} isAudio
   */
  attachStreamToMediaElement = (mediaElement, stream, isAudio = false) => {
    if (!mediaElement || !stream) {
      logger.warn('BrowserAPI::Can not attach media due to invalid arguments',
        { mediaElement: mediaElement?.id, stream });
    }
    try {
      const track = isAudio ? WebrtcUtils.getAudioTrack(stream) : WebrtcUtils.getVideoTrack(stream);
      logger.info(`BrowserAPI::Attaching ${isAudio ? 'Audio' : 'Video'} track: ${Utils.printableId(track?.id)}`,
        `from device: ${track?.label} stream-id: ${Utils.printableId(stream.id)} to: ${mediaElement?.id}`,
        (!isAudio && track?.canvas) ? 'from Canvas' : 'from device');
      mediaElement.srcObject = stream;
      if (!isAudio) { // Video stream
        if (navigator.userAgent.match(/iPhone OS/i) || navigator.userAgent.match(/Mac OS/i)) {
          mediaElement.play();
        }
      } else {
        // For audio stream
        this.pausePlayAudio(mediaElement);
      }
    } catch (error) {
      logger.warn('BrowserAPI::attachStreamToMediaElement() error in attach and play video:', error);
    }
  }

  /* Add given event listner when browser detects orientation change */
  onOrientationChange = (cb) => {
    if (cb) {
      logger.debug('BrowserAPI::Event listener for orientation change added');
      window.addEventListener('orientationchange', (event) => {
        logger.debug('BrowserAPI::Orientation change event fired', event);
        cb();
      });
    }
  }

  /* Removes listener if previously set */
  removeOrientationChange = () => {
    logger.debug('BrowserAPI::Event listener for orientation change removed');
    window.onorientationchange = null;
  }

  isDesktopMode = (isLandscape) => {
    // Desktop mode is when its not mobile device and when its not iPad/tablet in potrait mode
    const isTabletLandscape = window.innerWidth >= TABLET_WIDTH || (isTablet && isLandscape);
    return !isMobileOnly && isTabletLandscape;
  };

  isImageCaptureSupported() {
    return ('ImageCapture' in window);
  }

  /**
   * @param {string} deviceId
   * @param {string} mediaDeviceType
   * @returns [Promise] resolving device, , if found else null
   * @category API
   */
  getDeviceFromDeviceId = (deviceId, mediaDeviceType) => new Promise((resolve) => {
    this.getDevices((list) => {
      if (!list) {
        logger.warn('BrowserAPI::getDeviceFromDeviceId() failed to get deviceList');
        resolve(null);
      }
      const { audioInputDevices, audioOutputDevices, videoInputDevices } = list;
      let mediaDevice;
      switch (mediaDeviceType) {
        case MEDIA_DEVICE_TYPE.VIDEO_INPUT:
          mediaDevice = videoInputDevices.find((device) => device.deviceId === deviceId);
          break;
        case MEDIA_DEVICE_TYPE.AUDIO_INPUT:
          mediaDevice = audioInputDevices.find((device) => device.deviceId === deviceId);
          break;
        case MEDIA_DEVICE_TYPE.AUDIO_OUTPUT:
          mediaDevice = audioOutputDevices.find((device) => device.deviceId === deviceId);
          break;
        default:
          logger.warn('BrowserAPI::getDeviceFromDeviceId() not supported mediaType:', mediaDeviceType);
          break;
      }
      logger.debug('BrowserAPI::getDeviceFromDeviceId() found device:', mediaDevice, ' with deviceId:', deviceId);
      resolve(mediaDevice);
    });
  });

  /**
   * Pause and play audio trac
   * @param {DOMElement} audioTag
   */
  pausePlayAudio(audioTag) {
    logger.debug('BrowserAPI:: Audio track from', audioTag?.id,
      'status', audioTag?.paused ? 'paused' : 'playing');
    try {
      if (audioTag?.paused) {
        audioTag?.play();
        logger.debug('BrowserAPI:: Playing paused audio tag');
      } else {
        logger.debug('BrowserAPI:: Pause and play audio tag');
        audioTag?.pause();
        audioTag?.play();
      }
    } catch (error) {
      logger.warn('Error in play/pause audio:', error);
    }
  }

  // eslint-disable-next-line consistent-return
  isIlluminationSupportedBydevice = async (videoDeviceId) => {
    if (!videoDeviceId) {
      logger.warn('BrowserAPI::isIlluminationSupportedBydevice() deviceId cant be null, returning false');
      return false;
    }
    let videoStream;
    let isIllumSupported = false;
    try {
      videoStream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: { exact: videoDeviceId } } });
    } catch (error) {
      logger.warn('BrowserAPI::isIlluminationSupportedBydevice() getUserMedia() failed: ', error);
      return false;
    }
    isIllumSupported = this.isIlluminationSupportedByTrack(videoStream.getVideoTracks()[0]);
    videoStream?.getTracks().forEach((track) => {
      track.stop();
    });
    return isIllumSupported;
  }

  getPhotoIlluminationCapabilitiesOfTrack = async (videoTrack) => {
    const capabilities = {
      off: false,
      flash: false,
      auto: false,
    };
    if (!('ImageCapture' in window) || !videoTrack || videoTrack.kind !== MEDIA_TYPE.VIDEO) {
      logger.warn('BrowserAPI::getPhotoIlluminationCapabilitiesOfTrack() not checking, returning false');
      return capabilities;
    }
    const imageCapture = new ImageCapture(
      videoTrack,
    );

    try {
      const photoCapabilities = await imageCapture.getPhotoCapabilities();
      logger.debug('BrowserAPI::getPhotoIlluminationCapabilities() photoCapabilities:', JSON.stringify(photoCapabilities));

      if ('fillLightMode' in photoCapabilities) {
        photoCapabilities.fillLightMode.forEach((capability) => {
          if (capability === FILL_LIGHT_MODE.FLASH) {
            capabilities.flash = true;
          } else if (capability === FILL_LIGHT_MODE.AUTO) {
            capabilities.auto = true;
          } else if (capability === FILL_LIGHT_MODE.OFF) {
            capabilities.off = true;
          }
        });
      }
    } catch (error) {
      logger.warn('Error in getting photo illumination capabilities:', error);
    }
    return capabilities;
  }

  getPhotoIlluminationCapabilitiesOfDevice = async (deviceId) => {
    let capabilities = {
      off: false,
      flash: false,
      auto: false,
    };

    if (!this.isImageCaptureSupported()) {
      return capabilities;
    }

    let videoStream;
    try {
      videoStream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: { exact: deviceId } },
        audio: false,
      });
      capabilities = await this.getPhotoIlluminationCapabilitiesOfTrack(videoStream.getVideoTracks()[0]);
    } catch (error) {
      logger.warn('Error in getting photo illumination capabilities, GUM:', error);
    }
    videoStream?.getTracks().forEach((track) => {
      track.stop();
    });

    return capabilities;
  }

  isIlluminationSupportedByTrack = (videoTrack) => {
    logger.debug('BrowserAPI::isIlluminationSupportedByTrack() videotrack:', videoTrack);
    if (!videoTrack || videoTrack?.kind !== MEDIA_TYPE.VIDEO) {
      logger.warn('BrowserAPI::isIlluminationSupportedByTrack() invalid track, returning false.', videoTrack);
      return false;
    }

    logger.debug('BrowserAPI::isIlluminationSupportedByTrack() track capabilities:', JSON.stringify(videoTrack.getCapabilities()));

    const isTorchConfigurable = videoTrack.getCapabilities().torch;
    logger.debug('BrowserAPI::isIlluminationSupportedByTrack() is illumination supported: ', isTorchConfigurable);
    return isTorchConfigurable ?? false;
  };

  getIllumInfoOfActiveVideoTrack = async (devices, videoTrack) => {
    logger.debug('BrowserAPI::getIllumInfoOfActiveVideoTrack() devices:', devices, ' videoTrack:', videoTrack);

    const illumInfo = {
      levelMax: 0, // provided for backwards compatibility, just set to the same as the first source
      levelMin: 0, // provided for backwards compatibility, just set to the same as the first source
      perVideoSourceIllumInfo: [],
    };

    const isTorchSupported = await this.isIlluminationSupportedByTrack(videoTrack);
    logger.debug('BrowserAPI::getIllumInfoOfActiveVideoTrack() torch support:', isTorchSupported);
    if (isTorchSupported) {
      illumInfo.levelMax = 1;
    }
    const { flash, auto } = await this.getPhotoIlluminationCapabilitiesOfTrack(videoTrack);
    logger.debug('BrowserAPI::getIllumInfoOfActiveVideoTrack() photo illum support: flash:', flash, ' auto:', auto);
    let stillImageSupportedModes = IlluminationModeFlags.IllumModeOff;
    if (flash) {
      stillImageSupportedModes |= IlluminationModeFlags.IllumModeOn;
    }
    if (auto) {
      stillImageSupportedModes |= IlluminationModeFlags.IllumModeAuto;
    }

    const activeDevice = devices.find((device) => device.deviceId === videoTrack?.getSettings()?.deviceId);
    logger.debug('BrowserAPI::getIllumInfoOfActiveVideoTrack() activeDevice:', activeDevice);
    let deviceIllumInfo = {
      levelMax: isTorchSupported ? 1 : 0,
      levelMin: 0,
      sourceName: activeDevice?.label,
      stillImageSupportedModes,
      videoSupportedModes: !isTorchSupported ? IlluminationModeFlags.IllumModeOff :
        (IlluminationModeFlags.IllumModeOff | IlluminationModeFlags.IllumModeTorchOn),
    };
    illumInfo.perVideoSourceIllumInfo.push(deviceIllumInfo);
    deviceIllumInfo = {
      levelMax: 0,
      levelMin: 0,
      sourceName: activeDevice?.label,
      stillImageSupportedModes: IlluminationModeFlags.IllumModeOff,
      videoSupportedModes: IlluminationModeFlags.IllumModeOff,
    };
    devices.forEach((device) => {
      if (device !== activeDevice) {
        deviceIllumInfo.sourceName = device?.label;
        illumInfo.perVideoSourceIllumInfo.push(deviceIllumInfo);
      }
    });
    logger.debug('BrowserAPI::getIllumInfoOfActiveVideoTrack() illumInfo:', illumInfo);
    return illumInfo;
  }

  getIllumInfoOfLocalVideoDevices = async (devices) => {
    logger.debug('BrowserAPI::getIllumInfoAboutLocalVideoDevices() devices:', devices);

    const illumInfo = {
      levelMax: 0, // provided for backwards compatibility, just set to the same as the first source
      levelMin: 0, // provided for backwards compatibility, just set to the same as the first source
      perVideoSourceIllumInfo: [],
    };

    for (let index = 0; index < devices.length; index += 1) {
      const device = devices[index];
      // eslint-disable-next-line no-await-in-loop
      const isTorchSupported = await this.isIlluminationSupportedBydevice(device.deviceId);
      logger.debug('BrowserAPI::getIllumInfoAboutLocalVideoDevices() torch support:', isTorchSupported);
      if (isTorchSupported && index === 0) {
        illumInfo.levelMax = 1;
      }
      // eslint-disable-next-line no-await-in-loop
      const { flash, auto } = await this.getPhotoIlluminationCapabilitiesOfDevice(
        device.deviceId,
      );
      logger.debug('BrowserAPI::getIllumInfoAboutLocalVideoDevices() photo illum support: flash:', flash, ' auto:', auto, ' device:', device.deviceId);
      let stillImageSupportedModes = IlluminationModeFlags.IllumModeOff;
      if (flash) {
        stillImageSupportedModes |= IlluminationModeFlags.IllumModeOn;
      }
      if (auto) {
        stillImageSupportedModes |= IlluminationModeFlags.IllumModeAuto;
      }

      const deviceIllumInfo = {
        levelMax: isTorchSupported ? 1 : 0,
        levelMin: 0,
        sourceName: device.label,
        stillImageSupportedModes,
        videoSupportedModes: !isTorchSupported ? IlluminationModeFlags.IllumModeOff :
          (IlluminationModeFlags.IllumModeOff | IlluminationModeFlags.IllumModeTorchOn),
      };
      illumInfo.perVideoSourceIllumInfo.push(deviceIllumInfo);
    }
    logger.debug('BrowserAPI::getIllumInfoAboutLocalVideoDevices() illumInfo:', illumInfo);
    return illumInfo;
  }

  watchPosition = (success, error, options) => {
    if (!navigator.geolocation) {
      if (typeof error === 'function') {
        return error(new Error('Geolocation is not supported by browser.'));
      }
      return null;
    }
    return navigator.geolocation.watchPosition((position) => {
      if (typeof success === 'function') {
        return success(position);
      }
      return position;
    }, (err) => {
      if (typeof error === 'function') {
        return error(err);
      }
      return err;
    }, options);
  }

  clearWatchPosition = (watchId) => {
    navigator.geolocation.clearWatch(watchId);
  }

  checkIfSetCodecPreferencesSupported = (pc) => {
    const transceivers = pc?.getTransceivers();

    let videoTransceiver;
    let isSetCodecPreferencesSupported = false;
    if (transceivers && transceivers.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const t of transceivers) {
        if (
          (t.sender &&
            t.sender.track &&
            t.sender.track.kind === MEDIA_TYPE.VIDEO) ||
          (t.receiver &&
            t.receiver.track &&
            t.receiver.track.kind === MEDIA_TYPE.VIDEO)
        ) {
          videoTransceiver = t;
          break;
        }
      }
      if (videoTransceiver) {
        isSetCodecPreferencesSupported =
          !!videoTransceiver.setCodecPreferences;
      }
    }
    console.debug(
      'BrowserAPI::checkIfSetCodecPreferencesSupported() isSupported:',
      isSetCodecPreferencesSupported,
    );

    return isSetCodecPreferencesSupported;
  };

  getLocalHtmlVideoEle = (canUseCanvas, isScreenShareOn) => canUseCanvas && !(isScreenShareOn && Utils.isSafari()) ?
    document.getElementById(CALL_DASHBOARD.LOCAL_VIDEO_ID) :
    document.getElementById(CALL_DASHBOARD.SELF_VIDEO_ID);
}

const browserAPI = BrowserAPI.initBrowserAPI();
export default browserAPI;
