import moment from 'moment';
import getStore from 'STORE/Store';

import { getWebAppState } from 'STORE/CallControl/CallControlSelector';
import { IMAGE_SHARE_MODE } from 'UTILS/constants/DatastreamConstant';
import { STREAM_SHARE_STATE } from 'UTILS/constants/UtilityConstants';
import Logger from 'js-logger';

// Global Logger object
// const Logger = require('js-logger');

/**
 * Supported log levels
 */
export const LOG_LEVEL = {
  TRACER: 0,
  TRACE: 1,
  DEBUG: 2,
  INFO: 3,
  WARN: 4,
  ERROR: 5,
  SILENT: 5,
};

export const DEFAULT_LOG_LEVEL = LOG_LEVEL.DEBUG;
export const TraceFlags = {
  DIRECTION: {
    IN: 0,
    OUT: 1,
  },
  TYPE: {
    REQ: 0,
    RSP: 1,
  },
  MESSAGE: {
    XML: 0,
    JSON: 1,
    JANUS: 2,
    OPM_API: 3,
    LSDATA: 4,
  },
};

const LOG_TIMESTAMP_FORMAT = 'HH:mm:ss.SSS';
const LOG_PREFIX_END = '';

const FLAG_UNSET = '-';
const NO_CALL = undefined;
const LOG_FLD_SEPERATOR = '|';
const LOG_TRACER_LEVEL = 'TRACE';

// #region
/*
Following conversion function is copied from CommonUtility, repeated here to avoid
circular dependency issue
*/
function xmlToJson(xml) {
  const json = {
    attributes: {},
    contents: {},
  };
  if (xml.attributes) {
    const numberOfAttributes = xml.attributes.length;
    if (numberOfAttributes > 0) {
      for (let i = 0; i < numberOfAttributes; i += 1) {
        const attribute = xml.attributes.item(i);
        json.attributes[attribute.nodeName] = attribute.nodeValue;
      }
    }
  }
  if (xml.nodeType === 3) {
    json.contents = xml.nodeValue;
  }
  if (
    xml.hasChildNodes() &&
    xml.childNodes.length === 1 &&
    xml.childNodes[0].nodeType === 3
  ) {
    json.contents = xml.childNodes[0].nodeValue;
  } else if (xml.hasChildNodes()) {
    const numberOfChilds = xml.childNodes.length;
    for (let i = 0; i < numberOfChilds; i += 1) {
      const child = xml.childNodes.item(i);
      if (child.nodeType === 3) {
        json.contents = xml.nodeValue;
      } else if (json.contents[child.nodeName] instanceof Array) {
        json.contents[child.nodeName].push(xmlToJson(child));
      } else {
        const keys = Object.keys(json.contents);
        if (keys.includes(child.nodeName)) {
          json.contents[child.nodeName] = [json.contents[child.nodeName]];
          json.contents[child.nodeName].push(xmlToJson(child));
        } else {
          json.contents[child.nodeName] = xmlToJson(child);
        }
      }
    }
  }
  return json;
}

function convertXmlToJson(xmlString) {
  if (!xmlString) return '';

  const xmlDoc = new DOMParser().parseFromString(xmlString, 'text/xml');
  if (xmlDoc.hasChildNodes()) {
    const firstChild = xmlDoc.childNodes.item(0);
    const json = {
      [firstChild.nodeName]: xmlToJson(firstChild),
    };
    return json;
  }
  return {};
}

// #endregion

function apiHandler(event) {
  let message = '';
  if (event.type === TraceFlags.TYPE.REQ) {
    if (event.headers?.httpMethod) {
      message += 'HTTP-' + event.headers.httpMethod + ':';
    }
    message += event.headers.uri;
    message += '|';
  }
  if (event.message) {
    message += event.message;
  }
  return message;
}

const webAppStateStr = (store) => {
  const webappState = getWebAppState(
    store.getState(),
  );

  const videoFlag = () => {
    switch (webappState.streamShareState) {
      case STREAM_SHARE_STATE.UNKNOWN:
      case STREAM_SHARE_STATE.READY:
      // eslint-disable-next-line default-case-last, no-fallthrough
      default: // all above and default
        return FLAG_UNSET;

      case STREAM_SHARE_STATE.TOKEN_REQUESTED:
      case STREAM_SHARE_STATE.PREPARE_TO_SHARE:
      case STREAM_SHARE_STATE.READY_TO_SHARE:
      case STREAM_SHARE_STATE.STOP_SHARE:
      case STREAM_SHARE_STATE.SHARER_CHANGED:
        return 'H'; // handshake in progress for all above cases

      case STREAM_SHARE_STATE.STREAMING_STARTED:
        return 'V'; // streaming
    }
  };
  const screenShareFlag = () => webappState.screenShare ? 'S' : FLAG_UNSET;
  const imageShareFlag = () => {
    if (webappState.imageMode === IMAGE_SHARE_MODE.SEND) return 'S';
    if (webappState.imageMode === IMAGE_SHARE_MODE.RECEIVE) return 'R';
    return FLAG_UNSET;
  };

  return webappState.callInProgress ?
    `${videoFlag()}${screenShareFlag()}${imageShareFlag()}` :
    NO_CALL;
};

// JS logger
const DEFAULT_APP_LOG_LEVEL = LOG_LEVEL.TRACER;
let _Logger = null;

function logHandler(messages, context) {
  const { store } = getStore();
  const newArgs = [];
  const ts = moment(new Date()).format(LOG_TIMESTAMP_FORMAT);
  newArgs.push(ts);
  const appStateStr = webAppStateStr(store);
  if (appStateStr !== NO_CALL) {
    newArgs.push(appStateStr);
  }
  newArgs.push(context.level.name.toUpperCase());
  newArgs.push(context.name); // module name
  newArgs.push(LOG_PREFIX_END);
  messages.unshift(newArgs.join(LOG_FLD_SEPERATOR));
}

function mapLevel(level) {
  const LogLevelMap = {
    [LOG_LEVEL.TRACER]: Logger.TRACE,
    [LOG_LEVEL.TRACE]: Logger.TRACE,
    [LOG_LEVEL.DEBUG]: Logger.DEBUG,
    [LOG_LEVEL.INFO]: Logger.INFO,
    [LOG_LEVEL.WARN]: Logger.WARN,
    [LOG_LEVEL.ERROR]: Logger.ERROR,
    [LOG_LEVEL.SILENT]: Logger.OFF,
  };

  if (level in LogLevelMap) return LogLevelMap[level];
  return Logger.DEBUG; // Default
}

export function initLogging(defaultLogLevel = LOG_LEVEL.DEBUG) {
  if (!_Logger) {
    _Logger = Logger;
    _Logger.useDefaults({
      defaultLevel: mapLevel(defaultLogLevel),
      formatter: (messages, context) => logHandler(messages, context),
    });
  }
  return Logger.get('App'); // Return default logger
}

/**
 * Initializes logging object with the level specific to the module.
 *
 * @param {string} module name of module/component
 * @param {LOG_LEVEL} logLevel Logging level for the module
 * @returns {object}  Logger object used for making log calls to using .info, .debug, .log
 * etc methods.
 */
export const AppLogger = (module, logLevel = DEFAULT_APP_LOG_LEVEL) => {
  let _logger = null;
  if (window.dynamicEnv.REACT_APP_CUSTOM_LOG !== 'true') {
    // Logging mechanism short circuited to use default console log
    console.tracer = console.debug;
    _logger = console;
    return _logger;
  }
  if (!_Logger) initLogging();
  _logger = _Logger.get(module); // .setLevel(logLevel);
  _logger.setLevel(mapLevel(logLevel));

  // Custom log methods not supported by js-logger
  _logger.assert = console.assert;
  _logger.tracer = (event) => {
    if (logLevel > LOG_LEVEL.TRACE) return;
    const ts = moment(new Date()).format(LOG_TIMESTAMP_FORMAT);
    let message = null;
    const msgHandler = {
      [TraceFlags.MESSAGE.XML]: () => convertXmlToJson(event.message),
      [TraceFlags.MESSAGE.JANUS]: () => (event.message), // dummy
      [TraceFlags.MESSAGE.OPM_API]: () => apiHandler(event),
    };

    if ('msgType' in event &&
      typeof msgHandler[event.msgType] === 'function') {
      message = msgHandler[event.msgType]();
    } else {
      message = event.message;
    }

    const newArgs = [];
    newArgs.push(ts);
    newArgs.push(LOG_TRACER_LEVEL);
    newArgs.push(module);
    if ('type' in event) newArgs.push((event.type === TraceFlags.TYPE.REQ) ? 'REQ' : 'RSP');
    newArgs.push(LOG_PREFIX_END);
    console.debug(newArgs.join(LOG_FLD_SEPERATOR), message);
  };

  return _logger;
};

export const LOG_NAME = {
  Root: 'App',
  Branding: 'Branding',
  AppManager: 'AppMgr',
  CallManager: 'Call.Control',
  MediaHandler: 'Call.Media',
  SIPCallManager: 'Call.MediaServer',
  LSDS: 'LSDS',
  LSDSEvents: 'LSDS.Events',
  CallDashboard: 'UI.CallDashboard',
  Header: 'UI.Header',
  TeleHelper: 'TeleHelper',
  Participant: 'Call.Participant',
  AxiosHelper: 'OPM-API',
  SessionFSM: 'Session.FSM',
  CallFSM: 'Call.FSM',
  AppFSM: 'Call.FSM',
  NLPHelper: 'NLP.Helper',
  NLPManager: 'NLP.Manager',
  CallStatsMgr: 'Call.StatsMgr',
  OPMAPI: 'OPM-API',
  ContactsAPI: 'OPM-Contacts',
  WebAPI: 'Web.API',
  CommonUtils: 'Common.Utils',
  Login: 'UI.Login',
  CustomMessageHelper: 'UI.CustomMessage',
  AppServices: 'App.service',
  Contacts: 'Dashboard.Contacts',
};
