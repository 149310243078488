import { ACTIONS } from 'UTILS/constants/ActionConstants';

const initialState = {
  userProfile: {},
  contactList: [],
  callHistoryRecordsList: [],
  participants: [],
  selectedTelestrationDetails: {},
  custoMessagedisable: false,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_USER_PROFILE:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          ...action.payload,
        },
      };

    case ACTIONS.ADD_CONTACT:
      return {
        ...state,
        contactList: [...state.contactList, action.payload],
      };

    case ACTIONS.ADD_PARTICIPANTS:
      return {
        ...state,
        participants: action.participants,
      };

    case ACTIONS.CLEAR_PARTICIPANTS:
      return {
        ...state,
        participants: initialState.participants,
      };

    case ACTIONS.SET_SELECTED_ICON:
      return {
        ...state,
        selectedTelestrationDetails: action.selectedTelestrationDetails,
      };
    case ACTIONS.SET_SELECTED_ILLUMINATION_ITEM:
      return {
        ...state,
        activeIlluminationItem: action.activeIlluminationItem,
      };
    case ACTIONS.SET_CUSTOM_MESSAGE_DISABLE:
      return {
        ...state,
        custoMessagedisable: action.disable,
      };
    default:
      return state;
  }
};
