/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */

import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import { withOrientationChange, isMobileOnly, isMobile } from 'react-device-detect';
import { isLoggedInUserActiveVideoSharer } from 'SERVICES/LSDatastream/LSDSCommon';
import {
  isStreamRequested,
  readyToShare,
} from 'COMPONENTS/AppManager/AppUtils';

// constants
import { ICON_NAMES, mediaType } from 'UTILS/constants/HeaderConstants';

// Action
import { logoutAction } from 'STORE/Auth/AuthAction';

// Selector
import { getBrandTheme, getBrandUrl } from 'STORE/Theme/ThemeSelector';
import { isAudioOn, isScreenShareOn, getStreamShareState } from 'STORE/CallControl/CallControlSelector';

// Services
import { AppLogger, LOG_NAME } from 'SERVICES/Logging/AppLogger';

// Style
import './Header.scss';

const logger = AppLogger(LOG_NAME.Header);

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a
    href=''
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      menuItemClicked: false,
      isPortraitMode: window?.innerHeight > window?.innerWidth,
    };
    // LS_DATASTREAM_PROTOCOL.enableVideo = this.enableVideo;
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({
        isPortraitMode: window?.innerHeight > window?.innerWidth,
      });
    });
  }

  /**
   * Deduces the state for video icon based on the state of application
   *
   * @returns {toggled, enabled}
   */
  getVideoIconState = () => {
    const videoIconState = { toggled: true, enabled: '' };
    if (!readyToShare(this.props.streamShareState)) {
      videoIconState.enabled = 'disabled';
      return videoIconState;
    }

    if (isStreamRequested(this.props.streamShareState)) {
      videoIconState.toggled = false;
      videoIconState.enabled = 'disabled';
    } else {
      videoIconState.toggled = (this.props.screenShareOn || !isLoggedInUserActiveVideoSharer());
      videoIconState.enabled = '';
    }
    return videoIconState;
  }

  getScreenShareIconState = () => {
    const toBeEnabled = readyToShare(this.props.streamShareState) &&
      this.props.screenShareProps?.isAllowed();
    const isImageSharedOverSS = (this.props.isImageShared && this.props.screenShareOn);
    const screenShareState = {
      toggled: this.props.screenShareOn,
      className: toBeEnabled && !isImageSharedOverSS ? ''
        : this.props.screenShareProps?.isAllowed() // To make screen share icon enabled based
          ? '' : 'disabled-icon', // on the response of props on loading header
      enabled: toBeEnabled,
    };
    return screenShareState;
  }

  getAudioIconState = () => {
    const toBeEnabled = readyToShare(this.props.streamShareState);
    const audioState = {
      toggled: !this.props.audioOn,
      enabled: toBeEnabled,
      className: toBeEnabled ? '' : 'disabled',
    };
    return audioState;
  }

  /*
  Render top level icons in Header
  */
  renderIcon = (item) => {
    if (item?.isToggle) {
      switch (item?.type) {
        case mediaType.VIDEO: {
          const videoIconState = this.getVideoIconState();
          return videoIconState.toggled ? (
            <item.toggleIcon
              className={videoIconState.enabled}
              id={item.toggleIconId}
            />
          ) : (
            <item.icon
              className={`${videoIconState.enabled} ${item.className}`}
              id={item.iconId}
            />
          );
        }
        case mediaType.AUDIO: {
          const audioState = this.getAudioIconState();
          return !audioState.toggled ? (
            <item.icon id={item.iconId} className={`${audioState.className} ${item.className}`} />
          ) : (
            <item.toggleIcon id={item.toggleIconId} className={audioState.className} />
          );
        }

        case mediaType.SCREEN: {
          const ssIconState = this.getScreenShareIconState();
          return !ssIconState.toggled ? (
            <item.icon id={item.iconId} className={`${ssIconState.className} ${item.className}`} />
          ) : (
            <item.toggleIcon id={item.toggleIconId} className={ssIconState.className} />
          );
        }

        default:
          return <item.icon className={item.className} />;
      }
    } else {
      return <item.icon className={item.className} />;
    }
  };

  checkForEnterKeyPress = (e, item) => {
    if (e.key === 'Enter' && e.target.value !== '') {
      this.handleIconClick(item);
    }
  }

  handleIconClick = (item) => {
    if (item.isToggle && item.groupIcon) {
      switch (item.type) {
        case mediaType.VIDEO:
          this.startStream();
          break;
        case mediaType.AUDIO:
          // Executed DS mute function to mute self as well as notify other participants
          this.props.muteAudio();
          break;

        default:
      }
    } else {
      this.props.clickHandler(item.iconName);
    }
  };

  logout = () => {
    logger.info('Logging out...');
    logoutAction();
  };

  handleClick = (data) => {
    this.setState((prevState) => ({
      menuItemClicked: !prevState.menuItemClicked,
    }));
    switch (data.name) {
      // If header is loaded from Calldashboard, end call then logout else just logout
      case ICON_NAMES.LOGOUT: {
        const logoutHandler = this.props.endCallAndLogout
          ? this.props.endCallAndLogout
          : this.logout;
        logoutHandler();
        break;
      }
      default:
        this.props.clickHandler(data.id);
        break;
    }
  };

  toggleOpen = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  startStream() {
    this.props.startOrRequestVideoStream();
  }

  render() {
    const isScreenShareSupported = !!navigator.mediaDevices.getDisplayMedia;
    logger.debug('Screen share icon visibility', !!navigator.mediaDevices.getDisplayMedia);

    const currentPath = this.props.activePath?.split('/').pop();
    const ssIconState = this.getScreenShareIconState();
    return (
      <Col
        id='dashboard-header'
        md={12}
        className={`header-container px-0 ${this.props.className}`}
      >
        <Row className='header' lg={12} md={12} sm={12}>
          <Row className='px-0 py-1'>
            <Col
              xl={2}
              sm={3}
              xs={4}
              className={
                isMobileOnly && this.state.isPortraitMode
                  ? 'd-none'
                  : 'align-center'
              }
            >
              <img
                src={this.props.brandUrl}
                className='logo'
                alt='ONsight'
                loading='lazy'
                // eslint-disable-next-line no-return-assign
                onError={(event) =>
                  (event.target.style.display = 'none')
                }
                // eslint-disable-next-line no-return-assign
                onLoad={(event) => (event.target.style.display = 'inline-block')}
              />
            </Col>
            <div
              className={
                'icons-set ' +
                (isMobileOnly && this.state.isPortraitMode
                  ? 'col-lg-12'
                  : 'col-8 col-sm-9 col-xl-10')
              }
            >
              {this.props.iconSet.map((item, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={i}>
                  {item.iconType !== 'dropdown' ? (
                    item.iconGroup ? (
                      <Col
                        lg={3}
                        sm={3}
                        xs={3}
                        md={3}
                        className={`group-icons-width modified-group-icons ${item.disabled
                          ? 'disabled'
                          : ''
                        } ${item.paddingRight
                          ? 'pad-right'
                          : ''
                        }`}
                      >
                        <Row className='full-height modified-row'>
                          <Col
                            lg={6}
                            sm={7}
                            xs={7}
                            md={7}
                            id={item.iconName}
                            tabIndex='0'
                            onKeyPress={(e) => {
                              this.checkForEnterKeyPress(e, item);
                            }}
                            onClick={
                              isStreamRequested(this.props.streamShareState) &&
                                item?.type ===
                                'VIDEO'
                                ? () => { }
                                : () => {
                                  this.handleIconClick(
                                    item,
                                  );
                                }
                            }
                            className={`actual-icon ${currentPath ===
                              item.iconPath &&
                              item.type !==
                              'VIDEO' &&
                              item.type !==
                              'AUDIO' &&
                              'active-icon pl-0'
                            }`}
                          >
                            {this.renderIcon(item)}
                          </Col>

                          <Col
                            lg={5}
                            sm={5}
                            xs={5}
                            md={5}
                            tabIndex='0'
                            onKeyPress={(e) => {
                              this.props.checkForEnterKeyPress(e, item.iconGroupName);
                            }}
                            className={`chevron-arrow full-height align-items-end-center ${currentPath ===
                              item.iconPath &&
                              'active-icon'
                            } ${readyToShare(this.props.streamShareState) ? '' : ' disabled'
                            }`}
                            onClick={() => {
                              this.props.clickHandler(item.iconGroupName);
                            }}
                            id={item?.type === 'VIDEO' ? 'VideoSelection' : (item?.type === 'AUDIO' ? 'AudioSelection' : '')}
                          >
                            {item.groupIcon}
                          </Col>

                        </Row>

                      </Col>
                    ) :
                      item.isToggle && !item.iconGroup ? (
                        (!isMobile && (isScreenShareSupported) &&
                          window.dynamicEnv.REACT_APP_ALLOW_SCREEN_SHARE === 'true') && (
                          <Col
                            lg={1}
                            sm={1}
                            xs={1}
                            md={1}
                            id={item.iconName}
                            tabIndex='0'
                            onKeyPress={(e) => {
                              this.checkForEnterKeyPress(e, item);
                            }}
                            onClick={ssIconState.enabled ?
                              () => {
                                this.handleIconClick(
                                  item,
                                );
                              } :
                              () => { }
                            }
                            className={`icons-width ${this.props.screenShareOn &&
                              'active-icon pl-0 '
                            }
                            ${!ssIconState.enabled && 'disable-icon'}
                            `}
                          >
                            {this.renderIcon(item)}
                          </Col>
                        )
                      )
                        : (
                          <>
                            {item.leftBorder && (
                              <span className='left-border' />
                            )}
                            <Col
                              lg={1}
                              sm={1}
                              xs={1}
                              md={1}
                              id={item.iconName}
                              tabIndex='0'
                              onKeyPress={(e) => {
                                this.props.checkForEnterKeyPress(e, item.iconName);
                              }}
                              className={`icons-width button ${currentPath ===
                                item.iconPath &&
                                'active-icon'
                              } ${item.disabled
                                ? ' disabled'
                                : ''
                              } ${this.props.isWebappReady
                                ? ''
                                : ' disabled'
                              }  'p-0' `}
                              onClick={() => {
                                this.props.clickHandler(
                                  item.iconName,
                                );
                              }}
                            >
                              <item.icon className={item.className} />
                            </Col>
                          </>
                        )
                  ) : (
                    <Col
                      lg={1}
                      sm={1}
                      xs={1}
                      md={1}
                      id={item.iconName}
                      className={`align-center icons-width button p-0 ${currentPath === item.iconPath &&
                        'active-icon'
                      } ${item.disabled ? ' disabled' : ''
                      }`}
                    >
                      {isMobileOnly ? ( // more actions panel for mobile only
                        <Col
                          className='align-center'
                          tabIndex='0'
                          onKeyPress={(e) => {
                            this.props.checkForEnterKeyPress(e, item.iconName);
                          }}
                          onClick={() => {
                            this.props.clickHandler(
                              item.iconName,
                            );
                          }}
                        >
                          <item.icon className={item.className} />
                        </Col>
                      ) : (
                        <Dropdown
                          isOpen={this.state.isOpen}
                          toggle={this.toggleOpen}
                          className={`dropdown-container ${(this.state.isOpen ||
                            this.props
                              .isSettingClosed) &&
                            'active-icon'
                          }`}
                          direction='down'
                        >
                          <DropdownToggle
                            id='dropdown-basic'
                            as={CustomToggle}
                          >
                            <item.icon className={item.className} />
                          </DropdownToggle>

                          <DropdownMenu right>
                            {item.dropdownDetails.map(
                              (data) => (
                                <DropdownItem
                                  id={data.id}
                                  key={
                                    data.name
                                  }
                                  onClick={() => {
                                    this.handleClick(
                                      data,
                                    );
                                  }}
                                  disabled={
                                    data.disabled
                                  }
                                  hidden={data.hidden}
                                  className='p-1 align-horizontal-start dropdown-items'
                                >
                                  <span className='svg-icon'>
                                    <data.icon className={data.className} />
                                  </span>
                                  <span className='ps-3 dropdown-label'>
                                    {
                                      data.name
                                    }
                                  </span>
                                </DropdownItem>
                              ),
                            )}
                          </DropdownMenu>
                        </Dropdown>
                      )}
                    </Col>
                  )}
                </React.Fragment>
              ))}
            </div>
          </Row>
        </Row>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  theme: getBrandTheme(state),
  audioOn: isAudioOn(state),
  screenShareOn: isScreenShareOn(state),
  brandUrl: getBrandUrl(state),
  streamShareState: getStreamShareState(state),
});

// eslint-disable-next-line no-unused-vars
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {},
    dispatch,
  );
}

export default withOrientationChange(
  connect(mapStateToProps, mapDispatchToProps)(Header),
);
