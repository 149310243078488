import {
  CAMERA_FACING_MODE,
  VIDEO_MEDIA_CONFIGS,
  VIDEO_MEDIA_PROFILE,
  MEDIA_SERVER_CONNECTION,
  CALL_ERROR,
  MEDIA_SERVER_NO_SESSION,
  MEDIA_SERVER_ERROR_EVENT,
  MEDIA_SERVER_EVENT,
  MEDIA_TYPE,
} from 'UTILS/constants/MediaServerConstants';
import { JANUS_MESSAGE_KEYS } from 'UTILS/constants/JanusConstants';

import WebrtcUtils from 'SERVICES/MediaServerService/WebRtcUtils';
import { AppLogger, LOG_NAME } from 'SERVICES/Logging/AppLogger';
import { Janus } from './janus';

const logger = AppLogger(LOG_NAME.SIPCallManager);
const JANUS_KEEPALIVE_PERIOD = 10000;

/**
 * Provides API to CallManager, wrapper around Janus SDK
 * Performs Janus specific WebRTC OPs
 */
class JanusCallManager {
  static instance = null;

  /**
   * @returns Singleton instance of Janus Call Manager
   */
  static getJanusCallManager() {
    if (!JanusCallManager.instance) {
      JanusCallManager.instance = new JanusCallManager();
    }
    return JanusCallManager.instance;
  }

  constructor() {
    this.server = {
      mediaServerUrls: [],
      iceServers: [],
    };
    this.callback = {
      error: null,
      event: null,
      ice: null,
    };
    this.webrtc = {
      janus: null,
      token: null,
      sipCallPluginHandle: null,
    };
    this.privateMethod = new WeakMap();
    /**
     * Private Methods of JanusCallManager
     */
    this.privateMethod.set(this, {
      /**
       * Handler to handle incoming msgs from Janus
       * @param {*} msg JANUS msgs
       * @param {*} jsep SDP Offer/Answer received
       */
      handleJanusMessages: (msg, jsep) => {
        logger.debug(
          'handleJanusMessages::Got a message::',
          JSON.stringify(msg),
        );
        const result = msg[JANUS_MESSAGE_KEYS.RESULT];
        if (result) {
          if (result[JANUS_MESSAGE_KEYS.LIST]) {
            const list = result[JANUS_MESSAGE_KEYS.LIST];
            logger.debug(
              'handleJanusMessages::Got a list of registered peers:',
              list,
            );

            const listkeys = Object.keys(list);
            listkeys.forEach((userName) => {
              Janus.debug(' >> [' + list[userName] + ']');
              // TODO: save users in Reducer so as to show on Contacts list
            });
          } else if (result[JANUS_MESSAGE_KEYS.EVENT]) {
            const event = result[JANUS_MESSAGE_KEYS.EVENT];
            switch (event) {
              case JANUS_MESSAGE_KEYS.EVENTS.REGISTATION_FAILED: {
                logger.debug(
                  'Janus registraton failed',
                  `${result[JANUS_MESSAGE_KEYS.USERNAME]}`,
                  '!',
                );
                this.callback.event(MEDIA_SERVER_EVENT.REGISTATION_FAILED);
                break;
              }
              case JANUS_MESSAGE_KEYS.EVENTS.REGISTERED: {
                logger.debug(
                  'Successfully registered as',
                  `${result[JANUS_MESSAGE_KEYS.USERNAME]}`,
                  '!',
                );
                this.callback.event(MEDIA_SERVER_EVENT.REGISTERED);
                break;
              }
              case JANUS_MESSAGE_KEYS.EVENTS.CALLING: {
                logger.debug(
                  'handleJanusMessages::Calling :: Waiting for the peer to answer...',
                  JSON.stringify(event),
                );
                this.callback.event(MEDIA_SERVER_EVENT.CALLING);
                break;
              }
              case JANUS_MESSAGE_KEYS.EVENTS.ACCEPTING: {
                // Response to an offerless INVITE, let's wait for an 'accepted'
                logger.debug(
                  'handleJanusMessages::accepting ::',
                  JSON.stringify(event),
                );
                break;
              }
              case JANUS_MESSAGE_KEYS.EVENTS.PROGRESS: {
                logger.debug(
                  'handleJanusMessage::There is early media from ' +
                  result[JANUS_MESSAGE_KEYS.USERNAME] +
                  ' waiting for the call!',
                  JSON.stringify(jsep && jsep.sdp),
                );
                // Call can start already: handle the remote answer
                if (jsep) {
                  this.webrtc.sipCallPluginHandle.handleRemoteJsep({
                    jsep,
                    error: (error) => {
                      logger.error(
                        'handleJanusMessages::Error while handleRemoteJsep()::Hanging up',
                        error,
                      );
                      this.callback.error(MEDIA_SERVER_ERROR_EVENT.REMOTE_JSEP_ACCEPTANCE_ERROR,
                        { updateState: false });
                    },
                  });
                }
                break;
              }
              case JANUS_MESSAGE_KEYS.EVENTS.INCOMING_CALL: {
                if (this.webrtc.sipCallPluginHandle) {
                  this.webrtc.sipCallPluginHandle.peer =
                    result[JANUS_MESSAGE_KEYS.USERNAME];
                }
                logger.debug(
                  'Incoming call from::handleJanusMessage::Call from',
                  `${result[JANUS_MESSAGE_KEYS.USERNAME]}`,
                );
                const { displayname } = result;
                const rtnName = displayname.replace(/"/g, '');
                logger.debug('displayname: ', displayname, rtnName);

                this.callback.event(MEDIA_SERVER_EVENT.INCOMING_CALL,
                  {
                    pc: this.RTCPeer.getPeerConnection(),
                    callId: result[JANUS_MESSAGE_KEYS.CALL_ID],
                    srtp: result[JANUS_MESSAGE_KEYS.SRTP],
                    callerName: result[JANUS_MESSAGE_KEYS.USERNAME],
                    jsep,
                    displayname,
                  });
                break;
              }
              case JANUS_MESSAGE_KEYS.EVENTS.MISSED_CALL: {
                const { displayname } = result;
                const rtnName = displayname.replace(/"/g, '');
                const callId = msg[JANUS_MESSAGE_KEYS.CALL_ID];
                const caller = result[JANUS_MESSAGE_KEYS.CALLER];
                this.callback.event(MEDIA_SERVER_EVENT.MISSED_CALL, { rtnName, callId, caller });
                break;
              }
              case JANUS_MESSAGE_KEYS.EVENTS.ACCEPTED: {
                const acceptedUserName = result[JANUS_MESSAGE_KEYS.USERNAME];
                logger.debug(
                  'handleJanusMessage::Call is started/accepted by::',
                  acceptedUserName,
                  '!',
                );
                this.callback.event(
                  MEDIA_SERVER_EVENT.CALL_ACCEPTED_BY_REMOTE,
                  { acceptedUserName, pc: this.RTCPeer.getPeerConnection() },
                );
                // mute video at the starting
                this.CallFunction.muteUnmuteAudioVideo(MEDIA_TYPE.VIDEO, true);
                // Video call can start
                if (jsep) {
                  this.webrtc.sipCallPluginHandle.handleRemoteJsep({
                    jsep,
                    error: (error) => {
                      logger.error(
                        'handleJanusMessages::Error while handleRemoteJsep()::Hanging up',
                        error,
                      );
                      this.callback.error(MEDIA_SERVER_ERROR_EVENT.REMOTE_JSEP_ACCEPTANCE_ERROR,
                        { updateState: true });
                    },
                  });
                }
                break;
              }
              case JANUS_MESSAGE_KEYS.EVENTS.UPDATING_CALL: {
                // An 'update' event may be used to provide renegotiation attempts
                if (jsep) {
                  const doAudio = jsep.sdp.indexOf('m=audio ') > -1;
                  const doVideo = jsep.sdp.indexOf('m=video ') > -1;
                  const self = this;
                  this.webrtc.sipCallPluginHandle.createAnswer({
                    jsep,
                    media: {
                      audio: doAudio,
                      video: doVideo,
                      iceRestart: true,
                    },
                    success: (jsepupdate) => {
                      logger.debug(
                        'handleJanusMessage:: Got SDP while updating::',
                        JSON.stringify(jsep && jsep.sdp),
                      );
                      const body = {
                        request:
                          JANUS_MESSAGE_KEYS.REQUESTS.UPDATE,
                        autoaccept_reinvites:
                          MEDIA_SERVER_CONNECTION.AUTO_ACCEPT_REINVITE,
                      };
                      self.Session.sendMessage(body, jsepupdate);
                    },
                    error: (error) => {
                      logger.error(
                        'handleJanusMessage:: Got error while updating::',
                        JSON.stringify(error),
                      );
                      self.callback.error(
                        MEDIA_SERVER_ERROR_EVENT.SIP_UPDATE_ERROR, { error: msg },
                      );
                    },
                  });
                }
                break;
              }
              case JANUS_MESSAGE_KEYS.EVENTS.MESSAGE: {
                const sender = result.displayname
                  ? result.displayname
                  : result.sender;
                const { content } = result;
                logger.debug(
                  'Got message from::',
                  sender,
                  '::contents',
                  content,
                );
                break;
              }
              case JANUS_MESSAGE_KEYS.EVENTS.INFO: {
                const sender = result[JANUS_MESSAGE_KEYS.DISPLAY_NAME]
                  ? result[JANUS_MESSAGE_KEYS.DISPLAY_NAME]
                  : result[JANUS_MESSAGE_KEYS.SENDER];
                const content = result[JANUS_MESSAGE_KEYS.CONTENT];
                logger.debug(
                  'handleJanusMessage:: Got info from::',
                  sender,
                  'with contents::',
                  content,
                );
                break;
              }
              case JANUS_MESSAGE_KEYS.EVENTS.NOTIFY: {
                const notify = result[JANUS_MESSAGE_KEYS.NOTIFY];
                const content = result[JANUS_MESSAGE_KEYS.CONTENT];
                logger.debug(
                  'handelJanusMessage:: Notify event::',
                  notify,
                  '::contants::',
                  content,
                );
                break;
              }
              case JANUS_MESSAGE_KEYS.EVENTS.TRANSFER: {
                const referTo = result[JANUS_MESSAGE_KEYS.REFFER_TO];
                const referredBy = result[JANUS_MESSAGE_KEYS.REFFER_BY]
                  ? result[JANUS_MESSAGE_KEYS.REFFER_BY]
                  : JANUS_MESSAGE_KEYS.UNKNOWN_PARTY;
                const referId = result[JANUS_MESSAGE_KEYS.REFER_ID];
                const replaces = result[JANUS_MESSAGE_KEYS.REPLACE];
                logger.debug(
                  'handelJanusMessage:: Transfer to::',
                  referTo,
                  'by::',
                  referredBy,
                  'refferId::',
                  referId,
                  'replace::',
                  replaces,
                );
                break;
              }
              case JANUS_MESSAGE_KEYS.EVENTS.HANGUP: {
                const hangupBy = result[JANUS_MESSAGE_KEYS.USERNAME];
                const hangupReason = result[JANUS_MESSAGE_KEYS.REASON];
                const reasonHeader = result[JANUS_MESSAGE_KEYS.REASON_HEADER];
                let hangupCode = null;
                logger.debug(
                  'Hangup received',
                  `(Hangup by : ${hangupBy} Reason: ${hangupReason}, ReasonHeader: ${reasonHeader})!`,
                );
                if (result[JANUS_MESSAGE_KEYS.HANGUP_CODE]) {
                  hangupCode = result[JANUS_MESSAGE_KEYS.HANGUP_CODE];
                }

                this.callback.event(MEDIA_SERVER_EVENT.CALL_HANGUP,
                  { hangupReason, hangupCode, reasonHeader, hangupBy });
                break;
              }
              case JANUS_MESSAGE_KEYS.EVENTS.REGISTERING:
              case JANUS_MESSAGE_KEYS.EVENTS.RINGING:
              case JANUS_MESSAGE_KEYS.EVENTS.PROCEEDING:
                /* We do not need to act on this */
                break;

              case JANUS_MESSAGE_KEYS.EVENTS.DECLINING:
                logger.info('Declining!');
                break;

              case JANUS_MESSAGE_KEYS.EVENTS.HANGINGUP:
                logger.info('Hangingup!');
                break;

              case JANUS_MESSAGE_KEYS.EVENTS.UPDATED:
                logger.warn('UPDATED!');
                break;

              default: {
                logger.debug(
                  'handelJanusMessage:: default case',
                  JSON.stringify(event),
                );
                logger.warn(`Janus EVENT ${event} not processed`);
                break;
              }
            }
          }
        } else {
          this.callback.event(MEDIA_SERVER_EVENT.CALL_HANGUP_BY_SERVER);
        }
      },
    });
  }

  Init = {
    /**
     * Intializes followings
     * @param {*} mediaServerUrls Janus server URLs
     * @param {*} iceServers STUN/TURN servers required at Client
     * @param {*} callbacks Callbacks to CallManager
     * @param {*} webRtcToken for HMAC authentication
     */
    initialize: (mediaServerUrls, iceServers, callbacks, webRtcToken) => {
      this.server.mediaServerUrls = mediaServerUrls;
      this.server.iceServers = iceServers;
      this.callback = callbacks;
      this.webrtc.token = webRtcToken.token;
    },
  };

  Session = {
    /**
     * @returns Janus sessionID
     */
    getSessionId: () => this.webrtc.janus?.getSessionId(),
    /**
     * Establishes connection with Janus
     * @returns {Boolean} true if connects successfully
     */
    initMediaServerSession: () => {
      logger.info(
        'Initiate Janus session with server::',
        JSON.stringify(this.server),
      );
      const self = this;
      const debugFlag = window.dynamicEnv.REACT_APP_JANUS_DEBUG === 'true' ? true : ['error', 'warn', 'log'];
      // Initialize the library (logger debug enabled)
      logger.assert(
        this.webrtc.janus === null,
        'Error starting initialize with non-null Janus instance',
      );
      if (this.webrtc.janus === null) {
        Janus.init({
          debug: debugFlag,
          keepAlivePeriod: JANUS_KEEPALIVE_PERIOD,
          callback() {
            // Create session on Janus
            self.webrtc.janus = new Janus({
              server: self.server.mediaServerUrls,
              iceServers: self.server.iceServers,
              withCredentials: window.dynamicEnv.REACT_APP_JANUS_SERVER_WITH_CREDENTIALS === 'true',
              token: self.webrtc.token,
              success: () => self.callback.event(MEDIA_SERVER_EVENT.SERVER_CONNECTED),
              error: (error) => {
                logger.error(
                  'initJanus-error callback::error while initiating Janus::',
                  JSON.stringify(error),
                );
                /* Note! At this stage we are not able to distinguish between
                network or other issues */
                self.callback.error(MEDIA_SERVER_ERROR_EVENT.SERVER_CONNECTION_FAILED);
              },
              destroyed: () => {
                logger.debug(
                  'initJanus-destroyed callback called destroyed::',
                  JSON.stringify(self.webrtc.janus),
                );
                // reset janus to ensure proper initialization on login,bug#498
                // reset all properties
                self.callback.event(MEDIA_SERVER_EVENT.SERVER_CONN_DESTROYED);
              },
            });
          },
        });
      } else {
        logger.warn('Need to check this condition');
        this.callback.event('START_ATTACH');
      }
      return true;
    },
    /**
     * Attaches Janus Sip Plugin
     * @param {String} opaqueId Handle Id
     */
    attachSipSession: (opaqueId) => {
      this.webrtc.janus?.attach({
        plugin: MEDIA_SERVER_CONNECTION.SIP_PLUGIN,
        opaqueId,
        token: this.webrtc.token,
        success: (pluginHandle) => {
          this.webrtc.sipCallPluginHandle = pluginHandle;
          // logger.debug(
          //   'onJanusSessionSuccess:: Plugin attached:: ',
          // eslint-disable-next-line max-len
          //   `(${this.webrtc.sipCallPluginHandle?.getPlugin()}, id = ${this.webrtc.sipCallPluginHandle?.getId()})`,
          // );
          // Register the logged in user to SIP plugin
          this.callback.event(MEDIA_SERVER_EVENT.SIP_SESSION_ATTACHED);
        },
        error: (error) => {
          logger.error(
            'onJanusSessionSuccess-error call back for attaching plugin::Error attaching plugin::',
            JSON.stringify(error),
          );
          this.callback.error(MEDIA_SERVER_ERROR_EVENT.SIP_SESSION_ATTACH_FAILED);
        },
        consentDialog: () => {
          this.callback.event(MEDIA_SERVER_EVENT.ON_CONSENT_DIALOG);
        },
        iceState: (state) => {
          this.callback.ice(state);
        },
        mediaState: (medium, on) => {
          if (on) {
            logger.info('MEDIAPATH::'
              + (on ? 'started' : 'stopped') + ' receiving our ' + medium);
          } else {
            logger.warn('MEDIAPATH::'
              + (on ? 'started' : 'stopped') + ' receiving our ' + medium);
          }
        },
        webrtcState: (on) => {
          logger.debug('onJanusSessionSuccess::webRTC PeerConnection is ::', (on ? 'up' : 'down') +
            ' now');

          /* Initiate Media handler for call now that PeerConnection is up */
          if (!on) this.callback.event(MEDIA_SERVER_EVENT.ON_WEBRTC_STATE_DOWN);
        },
        onmessage: (msg, jsep) => {
          const error = msg[JANUS_MESSAGE_KEYS.ERROR];
          if (error) {
            logger.error('Got error:', JSON.stringify(error), ' error msg:', JSON.stringify(msg));
            this.callback.error(MEDIA_SERVER_ERROR_EVENT.SIP_SESSION_ERROR, { error });
            return;
          }
          this.callback.event(MEDIA_SERVER_EVENT.RECEIVED_UID,
            {
              callId: this.webrtc.sipCallPluginHandle ?
                msg[JANUS_MESSAGE_KEYS.CALL_ID] : undefined,
              pid: msg[JANUS_MESSAGE_KEYS.LSDS_PID],
            });
          this.privateMethod.get(this).handleJanusMessages(msg, jsep);
        },
        onlocalstream: (stream) => {
          logger.debug(
            'onJanusSessionSuccess:: Got a local stream ::',
          );
          this.callback.event(MEDIA_SERVER_EVENT.ON_LOCAL_STREAM, { stream });
        },
        onremotestream: (stream) => {
          logger.debug(
            'onJanusSessionSuccess::Got a remote stream::',
            stream,
          );
          this.callback.event(MEDIA_SERVER_EVENT.ON_REMOTE_STREAM, { stream });
        },
        slowLink: (uplink, lost) => {
          logger.debug(
            'slowLink::: uplink: ' +
            uplink +
            ' lost packets: ' +
            lost,
          );
        },
        // eslint-disable-next-line no-unused-vars
        ondataopen: (label, protocol) => {
          // logger.info(`DATA opened for ${protocol} label ${label}`);
        },
        ondataclose: (label, protocol) => {
          this.callback.event(MEDIA_SERVER_EVENT.ON_DATA_CLOSE, { label, protocol });
        },
        ondata: (data, label) => {
          this.callback.event(MEDIA_SERVER_EVENT.ON_DATA, { data, label });
        },
        oncleanup: () => {
          Janus.debug('Session Got a WebRTC peer cleanup notification::');
          this.callback.event(MEDIA_SERVER_EVENT.ON_WEBRTC_PEER_CLEANUP);
        },
      });
    },
    /**
     * Try to reconnect with Janus,
     * notifies success/failure to CallManager
     */
    reconnectSession: () => {
      this.webrtc.janus?.reconnect({
        success: () => {
          logger.info('RECONNECT Successfully reconnected to Janus session : ',
            this.Session.getSessionId());
          this.callback.event(MEDIA_SERVER_EVENT.SERVER_SESSION_RECONNECTED);
        },
        error: (error) => {
          if (error.includes(MEDIA_SERVER_NO_SESSION)) {
            logger.warn('Got \'No session\' error in reconnect ', JSON.stringify(error));
            logger.info('Earlier session lost, re-initing');
            this.callback.event(MEDIA_SERVER_EVENT.REINIT_SERVER_SESSION);
          } else {
            logger.warn('Error in reconnect ', error);
            this.callback.error(MEDIA_SERVER_ERROR_EVENT.SERVER_SESSION_RECONNECT_ERROR);
          }
        },
      });
    },
    /**
     * Destroyes Janus Session
     */
    endMediaServerSession: () => {
      /* When we call destroy, if  the session was never connected then we may not
      get notifyed in gateway callbacks.
      We specify notifyDestroyed to true to always get notified indepdent of the session
      connected or not connected
      */
      this.webrtc.janus?.destroy({
        notifyDestroyed: true,
      });
    },
    /**
     * Sends Msgs to Janus
     * @param {json} msg To be sent to Janus
     * @param {json} jsep Optional SDP
     */
    sendMessage: (msg, jsep) => {
      logger.debug(
        'sendJanusMessage ::: Send a message :::',
        JSON.stringify(msg),
        jsep ? 'SDP ' + JSON.stringify(jsep?.sdp) : '',
      );
      const toSend = {};
      toSend.message = msg;
      if (jsep) {
        toSend.jsep = jsep;
      }
      toSend.success = () => {
        // logger.info('sendJanusMessage ::: Success :::',
        //  JSON.stringify(data));
      };
      toSend.error = (error) => {
        logger.warn(
          'sendJanusMessage ::: Received Error :::',
          JSON.stringify(error),
          'for ',
          JSON.stringify(msg),
        );
      };
      this.webrtc.sipCallPluginHandle?.send(toSend);
    },
    /**
     * Sends keep alive msgs to Janus
     */
    sendKeepAlive: () => {
      this.webrtc.janus.keepAlive();
    },
    /**
     * Pre check for call, based on Janus connection and pluginHandle
     * @returns {boolean}
     */
    isMediaServerPreparedForCall: () => this.webrtc.janus && this.webrtc.sipCallPluginHandle,
    cleanUp: () => {
      this.webrtc.janus = null;
      this.webrtc.sipCallPluginHandle = null;
    },
  };

  Token = {
    /**
     * Updates Janus tokens periodically
     * @param {string} mediaServerToken
     */
    updateWebRTCToken: (mediaServerToken) => {
      if (mediaServerToken) {
        this.webrtc.token = mediaServerToken.token;
        if (this.webrtc.janus) {
          this.webrtc.janus.updateToken(this.webrtc.token);
        }
        if (this.webrtc.sipCallPluginHandle) {
          this.webrtc.sipCallPluginHandle.token = this.webrtc.token;
        }
      }
    },
  };

  Register = {
    /**
     * Sends SIP register requests to Janus
     * @param {*} updateRegistration
     * @param {*} regData contains info like sip username
     */
    // eslint-disable-next-line default-param-last
    registerUser: (updateRegistration = false, regData) => {
      const register = {
        request: JANUS_MESSAGE_KEYS.REQUESTS.REGISTER,
        refresh: updateRegistration,
        ...regData,
      };
      logger.info(`Registering: ${regData?.username}`);
      this.Session.sendMessage(register);
    },
  };

  Call = {
    /**
     * Performs call request by creating SDP and sending msg to Janus
     * @param {object} callOptions consists of stream, lsdsConfigs, calleeUserName, datachannel
     */
    makeCall: (callOptions) => {
      const { data, localStream, lsdsConfigs, calleeUserName } = callOptions;
      try {
        this.webrtc.sipCallPluginHandle?.createOffer({
          // By default, it's sendrecv for audio and video...
          media: {
            video: {
              facingMode: CAMERA_FACING_MODE.ENVIRONMENT,
              width: VIDEO_MEDIA_CONFIGS[VIDEO_MEDIA_PROFILE.LOW].width,
              height: VIDEO_MEDIA_CONFIGS[VIDEO_MEDIA_PROFILE.LOW].height,
            },
            audioSend: true,
            audioRecv: true,
            data,
            iceRestart: true,
          },
          stream: localStream,
          success: (jsep) => {
            logger.debug(
              'doCall::got SDP!',
              jsep && jsep.sdp,
            );
            const body = {
              request: JANUS_MESSAGE_KEYS.REQUESTS.CALL,
              uri: calleeUserName,
              srtp: 'sdes_mandatory',
              lsds: lsdsConfigs,
              autoaccept_reinvites: MEDIA_SERVER_CONNECTION.AUTO_ACCEPT_REINVITE,
            };
            this.Session.sendMessage(body, jsep);
          },
          // 1478: call works w/o SDP munging
          customizeSdp: (jsep) => {
            if (jsep && jsep.sdp) {
              // Customize sdp, if needed
            }
          },
          error: (error) => {
            this.callback.error(MEDIA_SERVER_ERROR_EVENT.MAKE_CALL_FAILED, { error });
          },
        });
      } catch (error) {
        this.callback.error(MEDIA_SERVER_ERROR_EVENT.MAKE_CALL_FAILED, { error });
      }
    },
    /**
     * Performs call hangup
     */
    hangup: () => {
      this.webrtc.sipCallPluginHandle?.hangup(true);
    },
    /**
     * Declines incoming call
     * @param {*} acknowledgementCode
     */
    declineCall: (acknowledgementCode) => {
      const decline = {
        request: JANUS_MESSAGE_KEYS.REQUESTS.DECLINE,
        code: acknowledgementCode || CALL_ERROR.CALLEE_BUSY,
      };
      this.Session.sendMessage(decline);
    },
    /**
     * Sends request to Janus accept call by accepting incoming Offer and providing answer SDP
     * @param {object} callAcceptOptions consists of stream, offer sdp,
     * whether to send audio or not, if this is offerLessInvite
     */
    acceptCall: (callAcceptOptions) => {
      const { offerlessInvite, jsep, doAudio, localStream } = callAcceptOptions;
      const sipcallAction = offerlessInvite
        ? this.webrtc.sipCallPluginHandle.createOffer
        : this.webrtc.sipCallPluginHandle.createAnswer;

      sipcallAction({
        jsep,
        media: {
          audio: doAudio,
          video: {
            facingMode: CAMERA_FACING_MODE.ENVIRONMENT,
            width: VIDEO_MEDIA_CONFIGS[VIDEO_MEDIA_PROFILE.LOW].width,
            height: VIDEO_MEDIA_CONFIGS[VIDEO_MEDIA_PROFILE.LOW].height,
          },
          data: !offerlessInvite,
          iceRestart: true,
        },
        stream: localStream,
        success: (jsepAnswer) => {
          const body = {
            request: JANUS_MESSAGE_KEYS.REQUESTS.ACCEPT,
            autoaccept_reinvites: MEDIA_SERVER_CONNECTION.AUTO_ACCEPT_REINVITE,
          };
          logger.debug(
            'acceptCall:: got SDP answer!',
            jsepAnswer && jsepAnswer.sdp,
          );
          this.Session.sendMessage(body, jsepAnswer);
          this.callback.event(MEDIA_SERVER_EVENT.CALL_ACCEPTED,
            { pc: this.RTCPeer.getPeerConnection() });
        },
        customizeSdp: (jsepAnswer) => {
          if (jsepAnswer && jsepAnswer.sdp) {
            // Customize sdp, if needed
          }
        },
        error: (error) => {
          logger.error('Got error while accepting call:', error);
          this.callback.error(MEDIA_SERVER_ERROR_EVENT.CALL_ACCEPT_FAILED, { error });
        },
      });
    },
  };

  CallFunction = {
    /**
     * Performs mute/unmute on audio/video media
     * @param {MEDIA_TYPE} mediaType
     * @param {*} muteFlag
     */
    muteUnmuteAudioVideo: (mediaType, muteFlag) => {
      logger.debug(
        `muteUnmute() mediaType:${mediaType} muteFlag:${muteFlag}`,
      );
      if (mediaType === MEDIA_TYPE.AUDIO) {
        if (!WebrtcUtils.hasAudio(this.webrtc.sipCallPluginHandle?.webrtcStuff?.myStream)) {
          logger.warn('muteUnmute::Local stream has no audio tracks!');
          return;
        }
        if (muteFlag) {
          this.webrtc.sipCallPluginHandle.muteAudio();
        } else {
          this.webrtc.sipCallPluginHandle.unmuteAudio();
        }
      } else if (mediaType === MEDIA_TYPE.VIDEO) {
        if (!WebrtcUtils.hasVideo(this.webrtc.sipCallPluginHandle?.webrtcStuff?.myStream)) {
          logger.warn('muteUnmute::Local stream has no video tracks!');
          return;
        }
        if (muteFlag) {
          this.webrtc.sipCallPluginHandle.muteVideo();
        } else {
          this.webrtc.sipCallPluginHandle.unmuteVideo();
        }
      }
    },
  };

  DATA = {
    /**
     * This function will send data channel messages
     */
    sendDataMessageToMediaServer: (data) => {
      this.webrtc.sipCallPluginHandle?.data(data);
    },
  };

  RTCPeer = {
    getPeerConnection: () => this.webrtc.sipCallPluginHandle?.webrtcStuff?.pc,
    getRtcPeerStream: () => this.webrtc.sipCallPluginHandle?.webrtcStuff?.myStream,
    getBitarte: () => this.webrtc.sipCallPluginHandle?.getBitrate(),
    clearPeer: () => {
      if (this.webrtc.sipCallPluginHandle) {
        this.webrtc.sipCallPluginHandle.peer = null;
      }
    },
  };
}

const JanusCallMgr = JanusCallManager.getJanusCallManager();
export default JanusCallMgr;
