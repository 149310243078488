import { ReactComponent as PauseIcon } from 'ASSETS/icon_pause.svg';
import { ReactComponent as PlayIcon } from 'ASSETS/icon_play.svg';
// import { ReactComponent as RecordIcon }  from 'ASSETS/icon_record.svg';
import { ReactComponent as TelestrationIcon } from 'ASSETS/icon_telestration.svg';
import { ReactComponent as ZoomIcon } from 'ASSETS/icon_zoom.svg';
import { ReactComponent as IlluminationIcon } from 'ASSETS/icon_illumination.svg';
import { ReactComponent as SnapshotEnabledIcon } from 'ASSETS/icon_snapshot.svg';
// import { ReactComponent as SnapshotDisabledIcon } from 'ASSETS/icon_snapshot_disabled.svg';
// import { ReactComponent as ShareEnabledIcon } from 'ASSETS/icon_share_on.svg';
// import { ReactComponent as ShareDisabledIcon } from 'ASSETS/icon_share.svg';
import { ReactComponent as BrainIcon } from 'ASSETS/icon_ai.svg';
import { ReactComponent as FlashOnIcon } from 'ASSETS/FlashOn.svg';
import { ReactComponent as FlashOffIcon } from 'ASSETS/FlashOff.svg';
import { ReactComponent as FlashAutoIcon } from 'ASSETS/FlashAuto.svg';

// import { ReactComponent as AudioIcon } from 'ASSETS/icon_audio.svg';

export const ICON_NAMES = {
  PLAY: 'Play',
  PAUSE: 'Pause',
  RECORD: 'Record',
  TELESTRATION: 'Telestration',
  ZOOM: 'Zoom',
  ILLUMINATION: 'Illumination',
  SNAPSHOT_ENABLED: 'SnapshotEnabled',
  SNAPSHOT_DISABLED: 'SnapshotDisabled',
  SHARE_ENABLED: 'ShareEnabled',
  SHARE_DISABLED: 'ShareDisabled',
  AUDIO: 'Audio',
  AI: 'AI',
};

export const callDashboardFooterIconSet = [
  {
    iconName: ICON_NAMES.PAUSE,
    icon: PauseIcon,
    disabled: false,
    id: 'pause',
    isToggle: true,
    toggleIcon: PlayIcon,
    toggleIconId: 'play',
    toggleIconName: ICON_NAMES.PLAY,
    className: 'pause-icon',
  },
  // {
  //   iconName: ICON_NAMES.RECORD,
  //   icon: RecordIcon,
  //   disabled: true,
  // },
  {
    iconName: ICON_NAMES.TELESTRATION,
    icon: TelestrationIcon,
    disabled: false,
    id: 'telestration',
    className: 'telestration-icon',
  },
  {
    iconName: ICON_NAMES.SNAPSHOT_ENABLED,
    icon: SnapshotEnabledIcon,
    isToggle: false,
    id: 'image_capture',
    className: 'snapshot-enabled-icon',
  },
  {
    iconName: ICON_NAMES.ILLUMINATION,
    icon: IlluminationIcon,
    disabled: false,
    id: 'illumination',
    className: 'illumination-icon',
  },
  {
    iconName: ICON_NAMES.AI,
    icon: BrainIcon,
    disabled: false,
    id: 'brain',
    className: 'brain-icon',
  },
  {
    iconName: ICON_NAMES.ZOOM,
    icon: ZoomIcon,
    disabled: false,
    id: 'zoom',
    className: 'zoom-icon',
  },
  // {
  //   iconName: ICON_NAMES.AUDIO,
  //   icon: Audio,
  //   disabled: true,
  // },
];

export const portraitFooterIconSet = [
  {
    iconName: ICON_NAMES.PAUSE,
    icon: PauseIcon,
    disabled: false,
    id: 'pause',
    isToggle: true,
    toggleIcon: PlayIcon,
    toggleIconId: 'play',
    toggleIconName: ICON_NAMES.PLAY,
    className: 'pause-icon',
  },
  // {
  //   iconName: ICON_NAMES.RECORD,
  //   icon: RecordIcon,
  //   disabled: true,
  //   className: 'record-icon',
  // },
  {
    iconName: ICON_NAMES.TELESTRATION,
    icon: TelestrationIcon,
    disabled: false,
    id: 'telestration',
    className: 'telestration-icon',
  },
  {
    iconName: ICON_NAMES.SNAPSHOT_ENABLED,
    icon: SnapshotEnabledIcon,
    disabled: false,
    id: 'image_capture',
    className: 'snapshot-enabled-icon',
  },
  {
    iconName: ICON_NAMES.ILLUMINATION,
    icon: IlluminationIcon,
    disabled: false,
    id: 'illumination',
    className: 'illumination-icon',
  },
  {
    iconName: ICON_NAMES.AI,
    icon: BrainIcon,
    disabled: false,
    id: 'brain',
    className: 'brain-icon',
  },
  {
    iconName: ICON_NAMES.ZOOM,
    icon: ZoomIcon,
    disabled: false,
    id: 'zoom',
    className: 'zoom-icon',
  },
  // {
  //   iconName: ICON_NAMES.AUDIO,
  //   icon: AudioIcon,
  //   disabled: true,
  //   className: 'audio-icon',
  // },
];

export const ILLUMINATION_DROP_UP_DETAILS = [
  {
    id: 'flash',
    name: 'illumination.flashOn',
    icon: FlashOnIcon,
    disabled: false,
    className: 'flash-on-icon',
  },
  {
    id: 'off',
    name: 'illumination.flashOff',
    icon: FlashOffIcon,
    disabled: false,
    className: 'flash-off-icon',
  },
  {
    id: 'auto',
    name: 'illumination.flashAuto',
    icon: FlashAutoIcon,
    disabled: false,
    className: 'flash-auto-icon',
  },
];
