/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { clone } from 'lodash';

// Custom component
import CustomDropdown from 'COMPONENTS/CustomComponents/CustomDropdown/CustomDropdown';

// Translation
import { translate } from 'SERVICES/i18n';

// Selector
import {
  getAudioInputDeviceList,
  getAudioOutputDeviceList,
  getAudioInputDevice,
  getAudioOutputDevice,
} from 'STORE/CallControl/CallControlSelector';
import {
  MEDIA_TYPE,
} from 'UTILS/constants/MediaServerConstants';

// Asset
import { ReactComponent as BackIcon } from 'ASSETS/icon_back_arrow.svg';

// Style
import './AudioSource.scss';

// eslint-disable-next-line react/prefer-stateless-function
class AudioSource extends Component {
  static noDevice = { label: translate('noDeviceFound') };

  componentDidMount() {
    // Update devices list in app/system
    this.props.getDeviceList(MEDIA_TYPE.AUDIO);
  }

  /* Setting id for the usage in custom drop down */
  setInputOptions = () => {
    const options = this.props.audioInputDeviceList?.map((item) => {
      const option = clone(item);
      option.id = item.deviceId;
      return option;
    });
    return options;
  }

  setOutputOptions = () => {
    const options = this.props.audioOutputDeviceList?.map((item) => {
      const option = clone(item);
      option.id = item.deviceId;
      return option;
    });
    return options;
  }

  setSelectedAudioInput = () => {
    if (this.props.audioInputDeviceList?.length === 0) {
      return AudioSource.noDevice;
    } if (this.props.selectedAudioInputDevice) {
      return this.props.selectedAudioInputDevice;
    }
    return this.props.audioInputDeviceList[0];
  }

  setSelectedAudioOutput = () => {
    if (this.props.audioOutputDeviceList?.length === 0) {
      return AudioSource.noDevice;
    } if (this.props.selectedAudioOutputDevice) {
      return this.props.selectedAudioOutputDevice;
    }
    return this.props.audioOutputDeviceList[0];
  }

  handleDropdown = (option, isAudioOutput = false) => {
    this.props.changeAudioDevice(option, isAudioOutput);
  }

  renderMic = () => (
    <>
      <Row className={`sub-header audio-header-pane  p-3 py-1 ${!this.props.audioInputDeviceList?.length && 'disabled'}`}>
        {translate('audio.microphone')}
      </Row>
      <Row className={`audio-header-pane p-1 ${!this.props.audioInputDeviceList?.length && 'disabled'}`}>
        <Col lg={10} md={8} sm={8} xs={9}>
          <CustomDropdown
            id='service-type'
            type='select'
            className='cursor-pointer'
            options={this.setInputOptions()}
            selectedOption={this.setSelectedAudioInput()}
            handleChange={(option) => this.handleDropdown(option)}
          />
        </Col>
      </Row>
    </>
  )

  renderSpeaker = () => (
    <>
      <Row className={`sub-header audio-header-pane  p-3 py-1  ${!this.props.audioOutputDeviceList?.length && 'disabled'}`}>
        {translate('audio.speakers')}
      </Row>
      <Row className={`audio-header-pane p-1 ${!this.props.audioOutputDeviceList?.length && 'disabled'}`}>
        <Col lg={10} md={8} sm={8} xs={9}>
          <CustomDropdown
            id='service-type'
            type='select'
            className='cursor-pointer'
            options={this.setOutputOptions()}
            selectedOption={this.setSelectedAudioOutput()}
            handleChange={(option) => this.handleDropdown(option, true)}
          />
        </Col>
      </Row>
    </>
  )

  renderRinger = () => (
    <>
      <Row className='sub-header audio-header-pane disabled p-3 py-1'>
        {translate('audio.ringer')}
      </Row>
      <Row className='audio-header-pane p-1 disabled'>
        <Col lg={10} md={8} sm={8} xs={9}>
          <CustomDropdown
            id='service-type'
            type='select'
            className='cursor-pointer'
            options={this.props.audioOutputDeviceList}
            selectedOption={this.setSelectedAudioOutput()}
            handleChange={(option) => console.info('Changing ringer is currently disabled', JSON.stringify(option))}
            disableDropdown
          />
        </Col>
      </Row>
    </>
  )

  render() {
    return (
      <div className='audio-source-panel pt-2'>
        <Row className='audio-header-pane audio-title'>
          <Col className='d-lg-none d-xl-none' xs='2'>
            <BackIcon
              className='back-icon'
              alt='back'
              role='presentation'
              onClick={this.props.goBack}
            />
          </Col>
          <Col className='audio-alignment px-3'>
            <span className='audio-header audio-alignment'>{translate('audio.audioSource')}</span>
          </Col>
          <Col className='d-flex justify-content-end' xs='3' />
        </Row>

        <Row className='divider-margin audio-divider'>
          <div className='divider' />
        </Row>
        <div className='scroll-panel'>
          {this.renderMic()}

          <Row className='audio-header-pane p-1'>
            <div className='divider' />
          </Row>

          {this.renderSpeaker()}

          <Row className='audio-header-pane p-1'>
            <div className='divider' />
          </Row>

          {this.renderRinger()}
          <br />
          <br />
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  audioInputDeviceList: getAudioInputDeviceList(state),
  audioOutputDeviceList: getAudioOutputDeviceList(state),
  selectedAudioInputDevice: getAudioInputDevice(state),
  selectedAudioOutputDevice: getAudioOutputDevice(state),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {},
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudioSource);
