import { ACTIONS } from 'UTILS/constants/ActionConstants';

const initialState = {
  calls: [],
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_STATISTICS_CALLS:
      return {
        ...state,
        calls: action.calls,
      };
    case ACTIONS.CLEAR_STATISTICS_CALLS:
      return {
        ...state,
        calls: [],
      };
    default:
      return state;
  }
};
