// Asset
import { ReactComponent as CallHistoryIcon } from 'ASSETS/icon_call_history.svg';
import { ReactComponent as ContactsIcon } from 'ASSETS/icon_contacts.svg';
import { ReactComponent as SearchIcon } from 'ASSETS/icon_header_search.svg';
import { ReactComponent as MenuIcon } from 'ASSETS/icon_menu.svg';
import { ReactComponent as SettingsIcon } from 'ASSETS/icon_settings.svg';
import { ReactComponent as LogoutIcon } from 'ASSETS/icon_logout.svg';
import { ReactComponent as FilesIcon } from 'ASSETS/icon_files.svg';
import { ReactComponent as HelpIcon } from 'ASSETS/icon_help.svg';

import { ReactComponent as ParticipantsIcon } from 'ASSETS/Icon_Participants.svg';
import { ReactComponent as ChevronDownLightIcon } from 'ASSETS/icon_chevron_down_light.svg';
import { ReactComponent as CallHangupIcon } from 'ASSETS/icon_hangup.svg';
import { ReactComponent as VideoOffIcon } from 'ASSETS/icon_video_off.svg';
import { ReactComponent as VideoOnIcon } from 'ASSETS/icon_video_on.svg';
import { ReactComponent as ScreenShareOnIcon } from 'ASSETS/icon_screen_share.svg';
import { ReactComponent as ScreenShareOffIcon } from 'ASSETS/icon_screen_share_stop.svg';
import { ReactComponent as MicrophoneOnIcon } from 'ASSETS/icon_microphone_on.svg';
import { ReactComponent as MicrophoneOffIcon } from 'ASSETS/icon_microphone_off.svg';

// Translations
import { translate } from 'SERVICES/i18n';

export const ICON_NAMES = {
  CALL_HISTORY: 'CALL_HISTORY',
  CONTACTS: 'CONTACTS',
  SEARCH: 'SEARCH',
  ICON_MENU: 'ICON_MENU',
  MORE_ACTIONS: 'MoreActions',
  PROFILE: translate('headerMenus.profile'),
  HELP: translate('headerMenus.help'),
  FILES: translate('headerMenus.files'),
  LOGOUT: translate('headerMenus.logout'),
  LOGOUT_ID: 'LogOut',
  SETTINGS: translate('headerMenus.settings'),
  PARTICIPANTS: 'Participants',
  SCREEN_SHARE: 'ScreenShareOn',
  SCREEN_SHARE_OFF: 'ScreenShareOff',
  DOWNCHEVRON: 'Down_Chevron',
  HANGUP: 'Hangup',
  VIDEOOFF: 'VideoOff',
  VIDEOON: 'VideoOn',
  MICROPHONE_ON: 'Microphone_on',
  MICROPHONE_OFF: 'Microphone_off',
  VIDEODEVICES: 'VideoDevices',
  AUDIODEVICES: 'AudioDevices',
};

// eslint-disable-next-line import/prefer-default-export
export const mediaType = {
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
  SCREEN: 'SCREEN',
};
export const loginIconSet = [
  {
    iconName: ICON_NAMES.ICON_MENU,
    icon: MenuIcon,
    iconType: 'dropdown',
    dropdownDetails: [
      {
        id: ICON_NAMES.MORE_ACTIONS,
        icon: SettingsIcon,
      },
    ],
    disabled: true,
    className: 'menu-icon',
  },
];

export const dashboardIconSet = [
  {
    iconName: ICON_NAMES.ICON_MENU,
    icon: MenuIcon,
    iconType: 'dropdown',
    dropdownDetails: [
      {
        id: ICON_NAMES.SETTINGS,
        name: ICON_NAMES.SETTINGS,
        icon: SettingsIcon,
        disabled: false,
        className: 'settings-icon',
        hidden: false,
      },
      {
        id: ICON_NAMES.FILES,
        name: ICON_NAMES.FILES,
        icon: FilesIcon,
        disabled: true,
        className: 'files-icon',
        hidden: true,
      },
      {
        id: ICON_NAMES.HELP,
        name: ICON_NAMES.HELP,
        icon: HelpIcon,
        disabled: true,
        className: 'help-icon',
        hidden: true,
      },
      {
        id: ICON_NAMES.LOGOUT_ID,
        name: ICON_NAMES.LOGOUT,
        icon: LogoutIcon,
        disabled: false,
        className: 'logout-icon',
        hidden: false,
      },
    ],
    className: 'menu-icon',
  },
  {
    iconName: ICON_NAMES.SEARCH,
    icon: SearchIcon,
    iconPath: 'search',
    isToggle: false,
    className: 'search-icon',
  },
  {
    iconName: ICON_NAMES.CONTACTS,
    icon: ContactsIcon,
    iconPath: 'contacts',
    isToggle: false,
    className: 'contacts-icon',
  },
  {
    iconName: ICON_NAMES.CALL_HISTORY,
    icon: CallHistoryIcon,
    iconPath: 'call-history',
    isToggle: false,
    className: 'call-history-icon',
  },
];

export const callDashboardIconSet = [
  {
    iconName: ICON_NAMES.ICON_MENU,
    icon: MenuIcon,
    iconType: 'dropdown',
    dropdownDetails: [
      {
        id: ICON_NAMES.SETTINGS,
        name: ICON_NAMES.SETTINGS,
        icon: SettingsIcon,
        disabled: false,
        className: 'settings-icon',
        hidden: false,
      },
      {
        id: ICON_NAMES.FILES,
        name: ICON_NAMES.FILES,
        icon: FilesIcon,
        disabled: true,
        className: 'files-icon',
        hidden: true,
      },
      {
        id: ICON_NAMES.HELP,
        name: ICON_NAMES.HELP,
        icon: HelpIcon,
        className: 'help-icon',
        disabled: true,
        hidden: true,
      },
      {
        id: ICON_NAMES.LOGOUT_ID,
        name: ICON_NAMES.LOGOUT,
        icon: LogoutIcon,
        disabled: true,
        className: 'logout-icon',
        hidden: false,
      },
    ],
    isToggle: false,
    className: 'menu-icon',
  },
  {
    iconName: ICON_NAMES.PARTICIPANTS,
    icon: ParticipantsIcon,
    iconPath: 'participants',
    isToggle: false,
    className: 'participants-icon',
  },
  {
    type: mediaType.SCREEN,
    iconName: ICON_NAMES.SCREEN_SHARE,
    icon: ScreenShareOnIcon,
    iconPath: 'screen-share',
    iconId: 'screenShareOn',
    iconGroup: false,
    toggleIcon: ScreenShareOffIcon,
    toggleIconId: 'screenShareOff',
    toggleIconName: ICON_NAMES.SCREEN_SHARE_OFF,
    isToggle: true,
    className: 'screen-share-on-icon',
  },
  {
    type: mediaType.VIDEO,
    iconName: ICON_NAMES.VIDEOON,
    icon: VideoOnIcon,
    iconId: 'videoOn',
    iconPath: 'video-source',
    toggleIcon: VideoOffIcon,
    toggleIconId: 'videoOff',
    toggleIconName: ICON_NAMES.VIDEOOFF,
    groupIcon: <ChevronDownLightIcon className='chevron-down-light-icon' />,
    iconGroup: true,
    iconGroupName: ICON_NAMES.VIDEODEVICES,
    isToggle: true,
    className: 'video-on-icon',
  },
  {
    type: mediaType.AUDIO,
    iconName: ICON_NAMES.MICROPHONE_ON,
    icon: MicrophoneOnIcon,
    iconId: 'microphoneOn',
    iconPath: 'audio-source',
    toggleIcon: MicrophoneOffIcon,
    toggleIconId: 'microphoneOff',
    toggleIconName: ICON_NAMES.MICROPHONE_OFF,
    groupIcon: <ChevronDownLightIcon className='chevron-down-light-icon' />,
    iconGroup: true,
    iconGroupName: ICON_NAMES.AUDIODEVICES,
    isToggle: true,
    paddingRight: true,
    className: 'microphone-on-icon',
  },
  {
    iconName: ICON_NAMES.HANGUP,
    icon: CallHangupIcon,
    leftBorder: true,
    isToggle: false,
    className: 'call-hangup-icon',
  },
];
