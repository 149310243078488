import { isFirefox } from 'react-device-detect';
import NlpClient from 'SERVICES/NLP/NlpClientHelper';
import RiffPcmEncoder from 'SERVICES/NLP/RiffPcmEncoder';
import {
  LIBOPUS_ENCODER_CONFIG,
  AUDIO_WORKLET_NODE_EVENT_TYPE,
  AUDIO_SAMPLE_RATE,
  AUDIO_WORKLET_NAME,
  RECORDER_WORKLET_PATH,
} from 'UTILS/constants/NlpConstants';

// Services
import { AppLogger, LOG_NAME } from 'SERVICES/Logging/AppLogger';

const logger = AppLogger(LOG_NAME.NLPManager);

class NlpManager {
  constructor() {
    logger.debug('NlpManager constructor');
    this.nlpClient = null;
    this.nlpAudioContext = null;
    this.nlpRecorderNode = null;
    this.nlpSourceNode = null;
    this.nlpAudioSamples = [];
    this.opusenc = null;
    this.libopus = window.libopus;
  }

  startSendingRemoteAudioToNlpServer(options) {
    logger.debug('NlpManager::startSendingRemoteAudioToNlpServer() options:', options);
    if (this.nlpClient) {
      logger.debug('NlpManager::startSendingRemoteAudioToNlpServer() nlpClient is there, returning');
      return;
    }
    this.nlpClient = new NlpClient(options);
    this.nlpClient.connect().then(async () => {
      try {
        this.nlpClient.startAudio(options.fromLang, options.toLang);

        if (this.nlpAudioContext) {
          this.nlpAudioContext.close();
          this.nlpAudioContext = null;
        }
        this.nlpAudioContext = new AudioContext(
          // Firefox is not allowing re-sampling
          // Bug-2410
          {
            sampleRate: isFirefox ? undefined : AUDIO_SAMPLE_RATE,
          },
        );

        if (this.nlpRecorderNode) {
          this.nlpRecorderNode.disconnect();
          this.nlpRecorderNode.port.close();
          this.nlpRecorderNode = null;
        }

        try {
          this.nlpRecorderNode = new window.AudioWorkletNode(
            this.nlpAudioContext,
            AUDIO_WORKLET_NAME,
          );
        } catch (error) {
          logger.log('NlpManager::startSendingRemoteAudioToNlpServer() need to addmodule to worklet:', error);
          await this.nlpAudioContext.audioWorklet.addModule(RECORDER_WORKLET_PATH);
          this.nlpRecorderNode = new window.AudioWorkletNode(
            this.nlpAudioContext,
            AUDIO_WORKLET_NAME,
          );
        }

        if (this.nlpSourceNode) {
          this.nlpSourceNode.disconnect();
          this.nlpSourceNode = null;
        }

        this.nlpSourceNode = this.nlpAudioContext.createMediaStreamSource(options.audioStream);

        this.nlpSourceNode.connect(this.nlpRecorderNode);
        this.nlpRecorderNode.connect(this.nlpAudioContext.destination);

        if (this.opusenc) {
          this.opusenc.destroy();
          this.opusenc = null;
        }

        try {
          this.opusenc = new this.libopus.Encoder(
            LIBOPUS_ENCODER_CONFIG.CHANNELS,
            LIBOPUS_ENCODER_CONFIG.SAMPLE_RATE,
            LIBOPUS_ENCODER_CONFIG.BITRATE,
            LIBOPUS_ENCODER_CONFIG.FRAME_SIZE,
            LIBOPUS_ENCODER_CONFIG.VOICE_OPTIMIZATION,
          );
        } catch (error) {
          logger.error('NlpManager::libopus encoder error:', error);
          if (typeof options.errorCallback === 'function') {
            options.errorCallback(error);
          }
          return;
        }

        this.nlpRecorderNode.port.onmessage = (e) => {
          if (e.data.eventType === AUDIO_WORKLET_NODE_EVENT_TYPE.DATA) {
            const pcmencoder = new RiffPcmEncoder(this.nlpAudioContext.sampleRate,
              AUDIO_SAMPLE_RATE);

            const audioData = pcmencoder.encode(e.data.audioBuffer);
            this.opusenc.input(new Uint16Array(audioData));

            const output = this.opusenc.output();
            if (output) {
              const base64 = window.btoa(String.fromCharCode.apply(null, output));
              this.nlpAudioSamples.push(base64);
              if (this.nlpAudioSamples.length === 5) {
                this.nlpClient.sendAudio(this.nlpAudioSamples);
                this.nlpAudioSamples = [];
              }
            }
          }

          if (e.data.eventType === AUDIO_WORKLET_NODE_EVENT_TYPE.STOP) {
            // recording has stopped
            logger.debug('NlpManager:: recording has stopped.');
          }
        };
      } catch (error) {
        logger.error('NlpManager::startSendingRemoteAudioToNlpServer audio start error:', error);
        if (typeof options.errorCallback === 'function') {
          options.errorCallback(error);
        }
      }
    }).catch((err) => {
      logger.warn('NlpManager::startSendingRemoteAudioToNlpServer error:', err);
      if (typeof options.errorCallback === 'function') {
        options.errorCallback(err);
      }
    });
  }

  stopSendingRemoteAudioToNlpServer() {
    if (this.nlpRecorderNode) {
      this.nlpRecorderNode.disconnect();
      this.nlpRecorderNode.port.close();
      this.nlpRecorderNode = null;
    }

    if (this.nlpSourceNode) {
      this.nlpSourceNode.disconnect();
      this.nlpSourceNode = null;
    }

    if (this.nlpAudioContext) {
      this.nlpAudioContext.close();
      this.nlpAudioContext = null;
    }

    if (this.opusenc) {
      this.opusenc.destroy();
      this.opusenc = null;
    }

    if (this.nlpClient) {
      this.nlpClient.disconnect();
      this.nlpClient = null;
    }
  }

  softStopSending() {
    if (this.nlpSourceNode) {
      this.nlpSourceNode.disconnect();
      this.nlpSourceNode = null;
    }
  }

  softStartSending(audioStream) {
    if (this.nlpSourceNode) {
      this.nlpSourceNode.disconnect();
      this.nlpSourceNode = null;
    }

    if (!this.nlpAudioContext) {
      return;
    }
    this.nlpSourceNode = this.nlpAudioContext.createMediaStreamSource(audioStream);
    try {
      this.nlpSourceNode.connect(this.nlpRecorderNode);
    } catch (error) {
      logger.warn('NlpManager::softStartSending() error in connecting source node to recorder node:', error);
    }
  }

  restartAudio(fromLang, toLang) {
    this.nlpClient?.stopAudio();
    this.nlpClient?.startAudio(fromLang, toLang);
  }
}

export default NlpManager;
