import { combineReducers } from 'redux';

import { ACTIONS } from 'UTILS/constants/ActionConstants';
import AuthReducer from './Auth/AuthReducer';
import UserReducer from './User/UserReducer';
import ThemeReducer from './Theme/ThemeReducer';
import LanguageReducer from './Language/LanguageReducer';
import CallControlReducer from './CallControl/CallControlReducer';
import ClientSettingsReducer from './ClientSettings/ClientSettingsReducer';
import ContactReducer from './Contacts/ContactReducer';
import StatisticsReducer from './Statistics/StatisticsReducer';

const appReducer = combineReducers({
  AuthReducer,
  UserReducer,
  ThemeReducer,
  LanguageReducer,
  CallControlReducer,
  ClientSettingsReducer,
  ContactReducer,
  StatisticsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === ACTIONS.CLEAR_STORE) {
    // We need to maintain branding data which is fetched only
    // when app initializes
    const themeReducer = state.ThemeReducer;
    state = { ThemeReducer: themeReducer };
  }
  return appReducer(state, action);
};

export default rootReducer;
