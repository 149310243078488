import React, { Component } from 'react';

// Custom Component
import LoadingSpinner from 'COMPONENTS/CustomComponents/LoadingSpinner/LoadingSpinner';

// Higher order component for managing spinner visibility
const withLoadingSpinner = (WrappedComponent, someProps) => class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false,
    };
  }

  showSpinner = () => {
    this.setState({ showSpinner: true });
  };

  hideSpinner = () => {
    this.setState({ showSpinner: false });
  };

  render() {
    const newProps = {
      ...this.props,
      showSpinner: this.showSpinner,
      hideSpinner: this.hideSpinner,
    };

    return (
      <>
        <LoadingSpinner
          showLoadingSpinner={this.state.showSpinner}
          loadingText={someProps.spinnerText}
        />
        <WrappedComponent {...newProps} />
      </>
    );
  }
};
export default withLoadingSpinner;
