import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';

// I18n
import { translate } from 'SERVICES/i18n';

// Style
import './CustomPopover.scss';

/**
 *
 * @param {bool} isPopoverOpen to hide/show the popover
 * @param {function} toggle to toggle the popover
 * @param {string} menuPlacement placement for the popover
 * @param {string} className class name of the popover
 * @param {string} id id of the popover
 * @param {string} target id of a component which we can use to display the popover
 * on that element
 * @param {function} closeCustomPopover cleanup method which is to be called
 * when popover is closes on outside click
 * @param {string} title title to be given for the popover
 * @param {jsx} child display/ render for popover
 * @returns jsx for custom popover
 */

function CustomPopover({ isPopoverOpen, togglePopover, menuPlacement, className, id, target,
  closeCustomPopover, title, child, showHeader }) {
  /**
* listen to on outside click of popover event in did mount
* work as componentDidMount and componentWillUnmount
* clears everything on component unmount
*/
  useEffect(() => () => {
    const targetElement = document.getElementById('root');

    targetElement.addEventListener(
      'click',
      closeCustomPopover,
    );
    return () => {
      targetElement.removeEventListener(
        'click',
        closeCustomPopover,
      );
    };
  }, [isPopoverOpen]);

  return (
    <Popover
      placement={menuPlacement}
      className={'custom-popover ' + className}
      isOpen={isPopoverOpen}
      target={target}
      toggle={() => togglePopover()}
      hideArrow
      id={id}
      modifiers={{
        preventOverflow: {
          boundariesElement: 'viewport',
        },
      }}
    >
      {showHeader && <PopoverHeader>{translate(title)}</PopoverHeader>}
      <PopoverBody>
        {child}
      </PopoverBody>
    </Popover>

  );
}
CustomPopover.defaultProps = {
  showHeader: true,
};

CustomPopover.propTypes = {
  isPopoverOpen: PropTypes.bool.isRequired,
  togglePopover: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  closeCustomPopover: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  child: PropTypes.object.isRequired,
  showHeader: PropTypes.bool,
};

export default compose(CustomPopover);
