// Constants
import {
  API_RESPONSE_CODE,
  OPERATION_STATUS,
} from 'UTILS/constants/ApiConstants';

// Translation
import { translate } from 'SERVICES/i18n';

const defaultErrorInfo = {
  code: API_RESPONSE_CODE.INTERNAL_SERVER_ERROR,
  message: translate('errors.internalServerError'),
  title: 'Error',
};

export function getErrorInfo(errorCode) {
  let errorInfo = {};
  errorInfo.code = errorCode;

  switch (errorCode) {
    case OPERATION_STATUS.NETWORK_NOT_REACHABLE:
      errorInfo.message = translate('errors.serverConnection');
      errorInfo.title = 'ConnectionError';
      break;

    case OPERATION_STATUS.SOMETHING_WENT_WRONG:
      if (!navigator.onLine) {
        errorInfo.message = translate('errors.serverConnection');
      } else errorInfo.message = translate('errors.someThingWentWrong');
      errorInfo.title = 'someThingWentWrong';
      break;

    default:
      errorInfo = defaultErrorInfo;
  }

  return errorInfo;
}

// Error look-up function for error returned from APIs
export function getAPIErrorInfo(errorCode, data) {
  const errorInfo = {};
  errorInfo.code = errorCode;
  errorInfo.title = 'Error';

  switch (errorCode) {
    case API_RESPONSE_CODE.BAD_GATEWAY:
      errorInfo.message = translate('errors.badGateway');
      break;
    case API_RESPONSE_CODE.UNAUTHORIZED_USER:
      if (data.attemptsRemaining > 0) {
        errorInfo.message = translate('errors.invalidCredentials', {
          number: data.attemptsRemaining,
        });
      } else if (data.attemptsRemaining < 0 && data.resultNumber === 15) {
        errorInfo.message = translate(
          'errors.invalidUserNameOrPassword',
        );
      } else {
        errorInfo.message = translate(
          'errors.accountTemporarilyLocked',
        );
      }

      break;
    case API_RESPONSE_CODE.NOT_FOUND:
      errorInfo.message = translate('errors.urlNotFound');
      break;
    case API_RESPONSE_CODE.INTERNAL_SERVER_ERROR:
      if (data.attemptsRemaining < 0) {
        errorInfo.message = translate('errors.accountLocked');
      } else {
        errorInfo.message = translate('errors.internalServerError');
      }
      break;
    default:
      errorInfo.message = translate('errors.someThingWentWrong');
  }

  return errorInfo;
}
