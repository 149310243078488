// import axiosHelper from 'SERVICES/AxiosHelper';
import { ACTIONS } from 'UTILS/constants/ActionConstants';

export const setDeviceList = (
  actionType,
  audioInputDeviceList,
  audioOutputDeviceList,
  videoInputDeviceList,
) =>
  (dispatch) => {
    dispatch({
      type: actionType,
      audioInputDeviceList,
      audioOutputDeviceList,
      videoInputDeviceList,
    });
    return Promise.resolve();
  };

export function setVideoIconAction(isVideoOn) {
  return (dispatch) => {
    dispatch({ type: ACTIONS.SET_VIDEO_ON_OFF, isVideoOn });
  };
}

export function setAudioIconAction(isAudioOn) {
  return (dispatch) => {
    dispatch({ type: ACTIONS.SET_AUDIO_ON_OFF, isAudioOn });
  };
}

export function setAudioInputDeviceAction(selectedAudioInputDevice) {
  return (dispatch) => {
    dispatch({ type: ACTIONS.SET_AUDIO_INPUT_DEVICE, selectedAudioInputDevice });
  };
}

export function setAudioOutputDeviceAction(selectedAudioOutputDevice) {
  return (dispatch) => {
    dispatch({ type: ACTIONS.SET_AUDIO_OUTPUT_DEVICE, selectedAudioOutputDevice });
  };
}

export function setVideoDeviceAction(selectedVideoDevice) {
  return (dispatch) => {
    dispatch({ type: ACTIONS.SET_VIDEO_DEVICE, selectedVideoDevice });
  };
}

export function setIncomingCall(isIncomingCall) {
  return {
    type: ACTIONS.SET_IS_INCOMING_CALL,
    isIncomingCall,
  };
}

export function setShowVideoFallbackUI(showVideoFallback) {
  return {
    type: ACTIONS.SET_SHOW_VIDEO_FALLBACK,
    showVideoFallback,
  };
}

export function setVideoDeviceListAction(videoDevices) {
  return (dispatch) => {
    dispatch({
      type: ACTIONS.SET_VIDEO_DEVICES,
      videoDevices,
    });
  };
}

export function setScreenShareAction(isScreenShareOn) {
  return (dispatch) => {
    dispatch({
      type: ACTIONS.SET_SCREEN_SHARE_ON,
      isScreenShareOn,
    });
  };
}

export function setVideoPauseAction(isVideoPaused) {
  return (dispatch) => {
    dispatch({
      type: ACTIONS.SET_VIDEO_PAUSE,
      isVideoPaused,
    });
  };
}

export function setStreamShareStateAction(streamShareState) {
  return (dispatch) => {
    dispatch({
      type: ACTIONS.SET_STREAM_SHARE_STATE,
      streamShareState,
    });
  };
}

export function setImageShareModeAction(mode) {
  return (dispatch) => {
    dispatch({ type: ACTIONS.SET_IMAGE_SHARE_MODE,
      imageMode: mode,
    });
  };
}

export function setCallStartAction() {
  return (dispatch) => {
    dispatch({
      type: ACTIONS.SET_CALL_STATUS,
      callStatus: true,
    });
  };
}

export function setCallEndAction() {
  return (dispatch) => {
    dispatch({
      type: ACTIONS.SET_CALL_STATUS,
      callStatus: false,
    });
  };
}
