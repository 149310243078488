/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';

// Translation
import { translate } from 'SERVICES/i18n';

// Custom component
import ErrorModal from 'COMPONENTS/CustomComponents/ErrorDialogModal/ErrorModal';

// Action
import { logoutAction } from 'STORE/Auth/AuthAction';

// Style
import './ErrorBoundary.scss';
import { connect } from 'react-redux';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  logout = () => {
    console.log('logout');
    this.setState({
      hasError: false,
    }, () => {
      logoutAction();
    });
  };

  render() {
    if (this.state.hasError) {
    // You can render any custom fallback UI
      return (
      // <div className='error-boundary'>
      //   <div className='error-container'>
      //     <div className='error-message'>
      //       { translate('errors.runtimeErrorMessage')}
      //     </div>
      //   </div>
      // </div>

        <ErrorModal
          open={this.state.hasError}
          header={translate('errors.serverErrorHeading')}
          errorMessage={translate('errors.runtimeErrorMessage')}
          onClose={this.logout}
          rightButton={[
            {
              text: translate('login.okButton'),
              onClick: this.logout,
            },
          ]}
        />
      );
    }

    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}

// eslint-disable-next-line no-unused-vars
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {},
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(ErrorBoundary);
