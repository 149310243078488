import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';

import _ from 'lodash';
import { translate } from 'SERVICES/i18n';
import PropTypes from 'prop-types';

import { CUSTOM_MESSAGE_DATA_FIELDS } from 'UTILS/constants/UtilityConstants';
import { LOCAL_STORAGE_KEY, CUSTOM_MESSAGE } from 'UTILS/constants/DOMElementConstants';

// Utility
import CommonUtility from 'UTILS/CommonUtility';
import Popup from 'COMPONENTS/CustomComponents/CustomPopup/Popup';

import { AppLogger, LOG_NAME } from 'SERVICES/Logging/AppLogger';
import { setCustomMessageDisable } from 'STORE/User/UserAction';

const logger = AppLogger(LOG_NAME.CustomMessageHelper);

function CustomMessageHelper({ onCustomMessageCancel }) {
  const dispatch = useDispatch();
  const customMessageDataFromStore = useSelector(
    (state) => state.AuthReducer.customMessageData,
  );
  const loggedInUserName = useSelector((state) => state.AuthReducer.loggedInUserName);
  const isCustomMessageDisable = useSelector((state) => state.UserReducer.custoMessagedisable);

  const [showPopup, setShowPopup] = useState(false);
  const [customMessagePopupData, setCustomMessagePopupData] = useState();

  /** This will return a custom message based on the OPM custom message xml using store
   */
  const getCustomMessageFromCustomLocalStorage = () => (CommonUtility.localStorage.getItem(`${loggedInUserName}${LOCAL_STORAGE_KEY.CUSTOM_MESSAGE}`));

  const getCustomMessage = () => {
    const customMessage = {
      isDataAvialable: false,
      header: null,
      message: null,
      checkBox: {
        value: '',
        checked: false,
      },
      displayCheckboxForUserPref: false,
    };
    if (customMessageDataFromStore && customMessageDataFromStore.json) {
      const customMessageData = JSON.parse(customMessageDataFromStore?.json);
      Object.keys(customMessageData).forEach((key) => {
        const customMessageKeyValue = customMessageData[key];
        switch (customMessageKeyValue.name) {
          case CUSTOM_MESSAGE_DATA_FIELDS.TITLE_TEXT_FIELD:
            customMessage.header = customMessageKeyValue.value;
            break;
          case CUSTOM_MESSAGE_DATA_FIELDS.MESSAGE_TEXT_AREA:
            customMessage.message = customMessageKeyValue.value;
            break;
          case CUSTOM_MESSAGE_DATA_FIELDS.MESSAGE_CHECKBOX_OPTION: {
            let checkboxData = null;
            const customMessageFromLocalStorage = CommonUtility.localStorage.getItem(`${loggedInUserName}${LOCAL_STORAGE_KEY.CUSTOM_MESSAGE}`);
            if (customMessageFromLocalStorage) {
              checkboxData = JSON.parse(customMessageFromLocalStorage).checkBox;
              if (checkboxData && checkboxData.checked
                && !customMessageKeyValue.values[0].selected) {
                checkboxData.checked = false;
              }
            }
            customMessage.checkBox.value = customMessageKeyValue.values[0].value.match(/([A-Z]?[^A-Z]*)/g).slice(0, -1).join(' ');
            customMessage.checkBox.checked = checkboxData ?
              checkboxData.checked :
              false;
            customMessage.displayCheckboxForUserPref =
              customMessageKeyValue.values[0].selected || false;
            break;
          }
          default:
            break;
        }
        customMessage.isDataAvialable = true;
      });
    } else {
      logger.warn('AppManager:: No custom is set yet!');
    }
    return customMessage;
  };

  const storeCustomMessageInLocalStorage = (customMessage) => {
    CommonUtility.localStorage.setItem(`${loggedInUserName}${LOCAL_STORAGE_KEY.CUSTOM_MESSAGE}`, customMessage);
  };

  const hasCustomMessageChanged = (customMessage, prevCustomMessage) =>
    !_.isEqual(customMessage, prevCustomMessage);

  const updateCustomMessageInLocalStorage = (customMessageToUpdate) => {
    const customMessageFromLocalStorage = JSON.parse(getCustomMessageFromCustomLocalStorage());
    customMessageFromLocalStorage.header = customMessageToUpdate.header ?
      customMessageToUpdate.header : customMessageFromLocalStorage.header;
    customMessageFromLocalStorage.message = customMessageToUpdate.message ?
      customMessageToUpdate.message : customMessageFromLocalStorage.message;
    customMessageFromLocalStorage.checkBox = customMessageToUpdate.checkBox ? {
      value: customMessageToUpdate.checkBox.value ? customMessageToUpdate.checkBox.value.match(/([A-Z]?[^A-Z]*)/g).slice(0, -1).join(' ')
        : customMessageFromLocalStorage.checkBox.value,
      checked: customMessageToUpdate.checkBox.checked,
    } : customMessageFromLocalStorage;
    storeCustomMessageInLocalStorage(JSON.stringify(customMessageFromLocalStorage));
  };

  const closeCustomMessage = () => {
    setShowPopup(false);
    dispatch(setCustomMessageDisable(true));
  };

  const onCustomMessageCheckboxChange = (event) => {
    updateCustomMessageInLocalStorage({ checkBox: { checked: event.target.checked } });
  };

  /**
   * This will display the custom message.
   */

  const displayCustomMessage = () => {
    const customMessage = getCustomMessage();
    const customMessageFromLocalStorage = getCustomMessageFromCustomLocalStorage();
    if (customMessage && customMessage.isDataAvialable) {
      const popup = {
        header: customMessage.header,
        message: customMessage.message,
        displayDontshowMessage: customMessage.checkBox.checked,
        checkbox: customMessage.displayCheckboxForUserPref ? [
          {
            text: translate('customMessage.dontAskMe'),
            checked: customMessageFromLocalStorage?.checkBox?.checked,
            onCheckboxChange: onCustomMessageCheckboxChange,
          },
        ] : null,
        rightButton: [
          {
            text: translate('connect.ok'),
            onClick: closeCustomMessage,
          },
        ],
        leftButton: [
          {
            text: translate('connect.cancel'),
            onClick: onCustomMessageCancel,
          },
        ],
        centerContent: true,
        checked: customMessageFromLocalStorage?.checkBox?.checked || false,
        onClose: onCustomMessageCancel,
        id: CUSTOM_MESSAGE.POPUP_ID,
        onCheckboxChange: onCustomMessageCheckboxChange,
      };
      storeCustomMessageInLocalStorage(JSON.stringify(customMessage));
      setShowPopup(true);
      setCustomMessagePopupData(popup);
    }
  };
  /**
   * This will check if a custom message on first login and subsequent logins based on the
   *  user prefernce of dont show thismessage checkbox value and show the msg
   */

  const checkCustomMessage = () => {
    let customMessageFromLocalStorage = null;
    try {
      customMessageFromLocalStorage = JSON.parse(getCustomMessageFromCustomLocalStorage());
    } catch (error) {
      logger.warn('Failed to get custom message from local storage.', error);
    }
    const customMessage = getCustomMessage();
    if (!isCustomMessageDisable && customMessage && customMessage.isDataAvialable) {
      if (customMessageFromLocalStorage !== null) {
        // If user does not checked checkbox or there is change in custom msg.
        const isDontShowCheckBoxCheckedPrev = customMessageFromLocalStorage.checkBox?.checked;
        if (!isDontShowCheckBoxCheckedPrev ||
          hasCustomMessageChanged(customMessage, customMessageFromLocalStorage)) {
          displayCustomMessage();
        }
      } else {
        // Display form on the first login
        displayCustomMessage();
      }
    }
  };

  useEffect(() => {
    if (customMessageDataFromStore && !isCustomMessageDisable) {
      checkCustomMessage();
    }
  }, []);

  return (
    <Popup
      open={showPopup}
      header={customMessagePopupData?.header}
      messageText={customMessagePopupData?.message}
      onClose={customMessagePopupData?.onClose}
      rightButton={customMessagePopupData?.rightButton}
      leftButton={customMessagePopupData?.leftButton}
      centerContent={customMessagePopupData?.centerContent}
      id={customMessagePopupData?.id}
      checkbox={customMessagePopupData?.checkbox}
      checked={customMessagePopupData?.checkbox?.checked}
      justifyText
      backdrop
    />
  );
}

CustomMessageHelper.propTypes = {
  onCustomMessageCancel: PropTypes.func,
};

CustomMessageHelper.defaultProps = {
  onCustomMessageCancel: () => { },
};

export default compose(CustomMessageHelper);
