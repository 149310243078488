/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable array-callback-return */
import React from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import { translate } from 'SERVICES/i18n';
import './CustomSearch.scss';
import { ReactComponent as ClearIcon } from 'ASSETS/icon_close.svg';
import { ReactComponent as SearchIcon } from 'ASSETS/icon_search.svg';
import { POLICY } from 'UTILS/constants/UtilityConstants';

class CustomSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  ClearIndicator = (props) => this.props.inputValue?.length > 0 && (this.props.gotSearchResult && this.props.inputValue?.length && this.props.groupedOptions?.length >= 0) && (
    <components.ClearIndicator {...props}>
      <ClearIcon alt='clear' className='clear-icon' onPointerDown={this.props?.onClear} />
    </components.ClearIndicator>
  );

  Group = (props) => (
    <div>
      <components.Group {...props} />
    </div>
  );

  Control = ({ children, ...props }) => (
    <components.Control {...props}>
      <SearchIcon className='search-icon' />
      {children}
    </components.Control>
  );

  getOptions = () => {
    const groupedContacts = [];
    // eslint-disable-next-line no-unused-expressions
    this.props?.groupedOptions?.length > 0 && this.props?.groupedOptions?.map((group) => {
      let groupContact = { label: group.label };
      const options = [];
      // eslint-disable-next-line no-unused-expressions
      // eslint-disable-next-line max-len
      // eslint-disable-next-line no-unused-expressions
      group?.contacts?.length > 0 &&
        group?.contacts?.map((i) => {
          let opt = {};
          opt = {
            label: i.name,
            value: i.uniqueId,
            customAbbreviation: i.address,
            isPersonal: i.is_personal, // if 'yes' than its manually added contact
          };
          options.push(opt);
        });
      groupContact = {
        ...groupContact,
        options,
      };
      groupedContacts.push(groupContact);
    });
    return groupedContacts;
  }

  formatOptionLabel = ({ label, customAbbreviation, isPersonal }) => (
    <div>
      <div className='custom-option-title ellipsis' title={label}>
        {label}
      </div>
      <div className='custom-option-subtitle ellipsis' title={isPersonal === POLICY.YES ? customAbbreviation : this.props.parse(customAbbreviation)}>
        {isPersonal === POLICY.YES ? customAbbreviation : (customAbbreviation ? this.props.parse(customAbbreviation) : '')}
      </div>
    </div>
  );

  formatGroupLabel = ({ label }) => (
    <div>
      <div className='custom-group-title'>
        {label}
      </div>
    </div>
  );

  render() {
    return (
      <Select
        placeholder={translate('contacts.findPeople')}
        formatOptionLabel={this.formatOptionLabel}
        formatGroupLabel={this.formatGroupLabel}
        options={this.props?.groupedOptions ? this.getOptions() : []}
        isSearchable
        isClearable
        onChange={this.props.handleChange}
        onInputChange={this.props.handleInputChange}
        inputValue={this.props.inputValue}
        parse={this.props.parse}
        className='custom-select'
        classNamePrefix='custom-contact-search'
        value={this.props.value}
        onMenuClose={this.props.onMenuClose}
        onMenuOpen={this.props.onMenuOpen}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          ClearIndicator: this.ClearIndicator,
          Group: this.Group,
          Control: this.Control,
        }}
        filterOption={false} // This will display all options, (by default it applies the filter)
        noOptionsMessage={() =>
          (this.props.inputValue?.length === 0 || (this.props.gotSearchResult && this.props.inputValue?.length && this.props.groupedOptions?.length === 0)
          || this.props.inputValue === ' '
            ? translate('contacts.noContactAvailable') :
            translate('contacts.searchText'))
        }
      />
    );
  }
}

CustomSearch.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  groupedOptions: PropTypes.array,
  handleChange: PropTypes.func,
  inputValue: PropTypes.string,
  handleInputChange: PropTypes.func,
  parse: PropTypes.func,
  value: PropTypes.string,
  onMenuClose: PropTypes.func,
  onClear: PropTypes.func,
  onMenuOpen: PropTypes.func,
  gotSearchResult: PropTypes.bool,
};

CustomSearch.defaultProps = {
  groupedOptions: [],
  handleChange: () => { },
  handleInputChange: () => { },
  inputValue: '',
  parse: () => { },
  value: '',
  onMenuClose: () => { },
  onClear: () => { },
  onMenuOpen: () => { },
  gotSearchResult: false,
};

export default CustomSearch;
