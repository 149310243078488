module.exports = {
  themeColors: {
    headerFooterColor: '#00263a',
    sidebarColor: '#00121c',
    buttonColor: '#01578C',
    buttonTransparentColor: 'transparent',
    buttonTextColor: '#ffffff',
    borderColor: '#00A3E0',
    textboxColor: '#00121c',
    inputAutoFillTextColor: '#ffffff',
    customTextFieldColor: '#00263a',
    dropdownColor: '#00121c',
    loginLayoutColor: '#00263a',
    modalLayoutColor: '#00263a',
    modalBoxShadow: '#000000D6',
    dropdownHover: '#01426A',
    arrowColor: '#00416d',
    addContactTextField: '#00263a',
    sidebarBorderTop: '#161616',
    dividerBorderColor: '#A3B4BF',
    tabFocusColor: '#00A3E084',
    toastBorderColor: '#00A3E0',
    toastBackgroundColor: '#00263a',
    iconHoverColor: '#057ec9',
    iconActiveColor: '#01426a',
    settingsTextFieldColor: '#001c2b',
    telestrationPopoverColor: '#00263a',
    telestrationHeaderColor: '#00121c',
    radioButtonColor: '#00A3E0',
  },
};
