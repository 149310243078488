import React from 'react';
import PropTypes from 'prop-types';

import './CustomCheckbox.scss';
import { Row, Col } from 'react-bootstrap';

function CustomCheckbox(props) {
  return (
    <Row className='custom-checkbox form-check w-100 p-0'>
      <Row className='form-check-label m-0 p-0 d-flex'>
        <Col lg={11} sm={11} md={11} xs={11} className='p-0'>
          <span>{ props.text }</span>
        </Col>
        <Col className='p-0 d-flex align-center justify-content-center'>
          <input
            disabled={props.disabled}
            className={`form-check-input ${props.alignCheckbox ? 'align-checkbox' : ''}`}
            type='checkbox'
            id={props.id}
            name={props.text}
            defaultChecked={props.checked}
            value={props.checked}
            onChange={props.onCheckboxChange}
          />
        </Col>
      </Row>
    </Row>
  );
}

CustomCheckbox.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onCheckboxChange: PropTypes.func,
  alignCheckbox: PropTypes.bool,
};

CustomCheckbox.defaultProps = {
  id: '',
  text: '',
  disabled: PropTypes.bool,
  checked: false,
  onCheckboxChange: () => {
    // Do Nothing
  },
  alignCheckbox: true,
};

export default CustomCheckbox;
