/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { isMobileOnly, isTablet, isIOS } from 'react-device-detect';

// Component
import Sidebar from 'COMPONENTS/Dashboard/Sidebar';
import Popup from 'COMPONENTS/CustomComponents/CustomPopup/Popup';
import CustomSearch from 'COMPONENTS/CustomComponents/CustomSearch/CustomSearch';
import CustomButton from 'COMPONENTS/CustomComponents/CustomButton/CustomButton';
import ErrorModal from 'COMPONENTS/CustomComponents/ErrorDialogModal/ErrorModal';

// Constants
import { ROUTES } from 'UTILS/constants/RoutingPathConstants';
import { POLICY, GUEST_INVITE_STATUS, MESSAGE_STATUS, SMS_STATUS_CODE } from 'UTILS/constants/UtilityConstants';
import { TIMER_CONSTANTS } from 'UTILS/constants/MediaServerConstants';
import { translate } from 'SERVICES/i18n';

// Asset
import { ReactComponent as TrashIcon } from 'ASSETS/icon_trash.svg';
import { ReactComponent as BackIcon } from 'ASSETS/icon_back_arrow.svg';
import { ReactComponent as AddContactIcon } from 'ASSETS/icon_add_participants.svg';
import { ReactComponent as GuestSentIcon } from 'ASSETS/invite_sent.svg';
import { ReactComponent as GuestReceivedIcon } from 'ASSETS/invite_received.svg';
import { ReactComponent as GuestFailedIcon } from 'ASSETS/invite_failed.svg';

// Services
import { AppLogger, LOG_NAME } from 'SERVICES/Logging/AppLogger';

// Style
import '../Dashboard.scss';

const logger = AppLogger(LOG_NAME.Contacts);

export default class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: { label: '', value: '' },
      showRemoveModal: false,
      deleteId: null,
      deleteName: null,
      applyFilter: false,
      isSelected: false,
      myContacts: [],
      gotSearchResult: false,
      highlightId: null,
      isKeyBoardOpen: false,
      showMessageStatusPopup: false,
      messageStatusDetail: '',
      messageStatus: '',
      guestError: false,
    };
    this.checkUpdates = null;
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState((prevState) => ({
        isKeyBoardOpen: !prevState.isKeyBoardOpen,
      }));
    });
    // Fetch personal contact list when contacts panel is opened
    this.props.fetchPersonalContacts();
    // Fetch personal contacts after every 5 mins
    this.checkUpdates = setInterval(() => {
      if (this.props.authToken) {
        logger.info('Personal contacts directory API Call after 5 minutes');
        this.props.fetchPersonalContacts();
      }
    }, TIMER_CONSTANTS.FETCH_UPDATED_CONTACTS);
  }

  componentDidUpdate() {
    if (isIOS) {
      setTimeout(() => {
        document.getElementsByClassName('contact-title')[0]?.scrollIntoView(false);
      }, 50);
    }
  }

  componentWillUnmount() {
    clearInterval(this.checkUpdates);
    this.checkUpdates = null;
  }

  // Debounce function: Input as function which needs to be debounced.
  debounceFunction = (func) => {
    let timerId;
    return (...args) => {
      const context = this;
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        timerId = null;
        func.apply(context, args);
      }, 1300);
    };
  };

  // Show guest invitation status icon for the guest contact
  getGuestStatusIcon = (guestInviteStatus) => {
    let icon;
    if (parseInt(guestInviteStatus, 10) === GUEST_INVITE_STATUS.SENT) {
      icon = <GuestSentIcon alt='Guest invitation status' className='guest-sent-icon' />;
    } else if (parseInt(guestInviteStatus, 10) === GUEST_INVITE_STATUS.RECEIVED) {
      icon = <GuestReceivedIcon alt='Guest invitation status' className='guest-received-icon' />;
    } else if (parseInt(guestInviteStatus, 10) === GUEST_INVITE_STATUS.FAILED) {
      icon = <GuestFailedIcon alt='Guest invitation status' className='guest-failed-icon' />;
    }
    return icon;
  };

  // Show detailed message status for guest invite
  showMessageStatus = (messageStatusCode) => {
    let status = '';
    let detail = '';
    const code = parseInt(messageStatusCode, 10);
    switch (code) {
      case SMS_STATUS_CODE.SMS_ERROR_PROVIDER2: case SMS_STATUS_CODE.SMS_ERROR_PROVIDER1:
        status = MESSAGE_STATUS.STR_SMS_ERROR_PROVIDER;
        detail = MESSAGE_STATUS.STR_SMS_ERROR_PROVIDER_DETAIL;
        break;
      case SMS_STATUS_CODE.SMS_NOT_APPLICABLE:
        status = MESSAGE_STATUS.STR_SMS_NOT_APPLICABLE;
        detail = MESSAGE_STATUS.STR_SMS_NOT_APPLICABLE;
        // Use STR_SMS_NOT_APPLICABLE_DETAIL string here. For now using STR_SMS_NOT_APPLICABLE
        // TODO: need to check this string as its missing
        break;
      case SMS_STATUS_CODE.SMS_INITIATED:
        status = MESSAGE_STATUS.STR_SMS_INITIATED;
        detail = MESSAGE_STATUS.STR_SMS_INITIATED_DETAIL;
        break;
      case SMS_STATUS_CODE.SMS_DELIVERED:
        status = MESSAGE_STATUS.STR_SMS_DELIVERED_GATEWAY;
        detail = MESSAGE_STATUS.STR_SMS_DELIVERED_GATEWAY_DETAIL;
        break;
      case SMS_STATUS_CODE.SMS_RECEIVED_RECIPIENT:
        status = MESSAGE_STATUS.STR_SMS_RECEIVED_RECIPIENT;
        detail = MESSAGE_STATUS.STR_SMS_RECEIVED_RECIPIENT_DETAIL;
        break;
      case SMS_STATUS_CODE.SMS_ERROR_CONTENT:
        status = MESSAGE_STATUS.STR_SMS_ERROR_CONTENT;
        detail = MESSAGE_STATUS.STR_SMS_ERROR_CONTENT_DETAIL;
        break;
      case SMS_STATUS_CODE.SMS_ERROR_DELIVERING:
        status = MESSAGE_STATUS.STR_SMS_ERROR_DELIVERING;
        detail = MESSAGE_STATUS.STR_SMS_ERROR_DELIVERING_DETAIL;
        break;
      case SMS_STATUS_CODE.SMS_ERROR_ROUTING:
        status = MESSAGE_STATUS.STR_SMS_ERROR_ROUTING;
        detail = MESSAGE_STATUS.STR_SMS_ERROR_ROUTING_DETAIL;
        break;
      case SMS_STATUS_CODE.SMS_EXPIRED:
        status = MESSAGE_STATUS.STR_SMS_EXPIRED;
        detail = MESSAGE_STATUS.STR_SMS_EXPIRED_DETAIL;
        break;
      case SMS_STATUS_CODE.SMS_OUT_OF_CREDIT:
        status = MESSAGE_STATUS.STR_SMS_OUT_OF_CREDIT;
        detail = MESSAGE_STATUS.STR_SMS_OUT_OF_CREDIT_DETAIL;
        break;
      case SMS_STATUS_CODE.SMS_OPENED:
        status = MESSAGE_STATUS.STR_SMS_OPENED;
        detail = MESSAGE_STATUS.STR_SMS_OPENED_DETAIL;
        break;
      case SMS_STATUS_CODE.SMS_LOGGED_IN:
        status = MESSAGE_STATUS.STR_SMS_LOGGED_IN;
        detail = MESSAGE_STATUS.STR_SMS_LOGGED_IN_DETAIL;
        break;
      case SMS_STATUS_CODE.SMS_SPAM:
        status = MESSAGE_STATUS.STR_SMS_SPAM;
        detail = MESSAGE_STATUS.STR_SMS_SPAM_DETAIL;
        break;
      case SMS_STATUS_CODE.SMS_USER_EXPIRED:
        status = MESSAGE_STATUS.STR_SMS_USER_EXPIRED;
        detail = MESSAGE_STATUS.STR_SMS_USER_EXPIRED_DETAIL;
        break;
      default: logger.warn('Invitation status not found.');
    }
    if (status?.length > 0 || detail.length > 0) {
      this.setState({
        showMessageStatusPopup: true,
        messageStatus: status,
        messageStatusDetail: detail,
      });
    } else {
      console.warn('Invitation status not found');
    }
  };

  closeStatusPopup = () => {
    this.setState({
      showMessageStatusPopup: false,
      messageStatus: '',
      messageStatusDetail: '',
    });
  };

  renderContacts = () => {
    const renderList = this.props?.personalList &&
      this.props?.personalList?.length > 0 &&
      this.props.personalList?.map((contact) => (
        <React.Fragment key={contact.uniqueId}>
          <Row
            lg={12}
            md={12}
            sm={12}
            id={contact.uniqueId}
            className={'d-flex justify-content-between margin ms-0 ' +
              ((this.state?.highlightId === contact?.uniqueId ? 'highlight-contact' : ''))
            }
          >
            <Col
              lg={contact.is_guest === POLICY.YES ? 9 : 10}
              md={contact.is_guest === POLICY.YES ? 9 : 10}
              sm={contact.is_guest === POLICY.YES ? 9 : 10}
              xs={contact.is_guest === POLICY.YES ? 8 : 10}
              className='contact-name-list ellipsis landscape-horizontal-padding'
            >
              <span
                role='presentation'
                className='cursor-pointer contact-name no-select ellipsis p-0'
                onClick={() =>
                  this.handleSelectedContact(contact.name, contact.address,
                    contact.is_guest, contact.message_status)
                }
                title={contact.name}
              >
                <Row className={isMobileOnly ? 'px-4 mx-1' : isTablet ? ' px-3 contact-tablet' : 'px-3'}>
                  <div className='no-select p-0 ellipsis' title={contact.name}>
                    {contact.name}
                  </div>
                </Row>
                <Row className={isMobileOnly ? 'p-0 px-4 mx-1' : isTablet ? 'p-0 px-3 contact-tablet' : 'p-0 px-3'}>
                  <div className='no-select sipaddr p-0 ellipsis' title={contact.is_personal === POLICY.YES ? contact.address : this.parse(contact.address)}>
                    {contact.is_personal === POLICY.YES
                      ? contact.address : this.parse(contact.address)}
                  </div>
                </Row>
              </span>
            </Col>
            {contact.is_guest === POLICY.YES && (
              <Col xs={2} md={1} sm={1} lg={1} className={isTablet ? 'px-0 align-self-center' : 'align-self-center'}>
                <div role='presentation' className='cursor-pointer' onClick={() => this.showMessageStatus(contact.message_status)}>
                  {this.getGuestStatusIcon(contact.guest_invite_status)}
                </div>
              </Col>
            )}
            <Col xs={2} md={2} lg={2} sm={2} className='d-flex justify-content-center align-items-center'>
              <TrashIcon alt='delete' role='presentation' className='trash-icon cursor-pointer' onClick={() => this.handleDeleteContact(contact.uniqueId, contact.name)} />
            </Col>
          </Row>
          <Row className={isMobileOnly ? 'margin-mobile landscape-horizontal-padding px-4' : 'margin landscape-horizontal-padding px-4'}>
            <div className='contact-divider' />
          </Row>
        </React.Fragment>
      )).sort();
    return renderList;
  };

  // eslint-disable-next-line react/sort-comp
  debounce = this.debounceFunction((e) => {
    this.props.fetchContacts(e);
  });

  debounceLocalFilter = this.debounceFunction((e) => {
    this.setState({ myContacts: this.getFilteredContacts(e), gotSearchResult: true });
    // gotSearchResult =  true means searching is finished
  });

  handleSelectedContact = (name, sipAddress, isGuest, messageStatusCode) => {
    if (isGuest === POLICY.YES && parseInt(messageStatusCode, 10) === 16) {
      this.setState({ guestError: true, guestErrorMessage: translate('messageStatus.guestCallError') });
    } else {
      this.props.placeCall(name, sipAddress);
    }
  };

  handleAddContact = () => {
    this.props.history.push(ROUTES.DASHBOARD + ROUTES.ADD_CONTACTS);
  };

  handleGuestInvite = () => {
    if ((this.props.guestInviteAvailable) &&
    (this.props.guestInviteAvailable === POLICY.NO)) {
      this.setState({ guestError: true, guestErrorMessage: translate('guestInvite.cannotInviteGuest') });
    } else {
      this.props.history.push(ROUTES.DASHBOARD + ROUTES.GUEST_INVITE);
    }
  }

  // Function to hide SIP registrar and display only username and domain in search list
  parse = (sipAddr) => (
    `${sipAddr?.split('@', 2)[0]}@${this.props.loggedInUserName?.split('@')[1]}`
  );

  handleDeleteContact = (id, name) => {
    this.setState({ showRemoveModal: true, deleteId: id, deleteName: name });
  }

  onCancel = () => {
    this.setState({ showRemoveModal: false });
  }

  onDelete = () => {
    this.props.deleteContact(this.state.deleteId);
    this.setState({ showRemoveModal: false });
  }

  // Function to handle when user types in search bar
  handleInputChange = (event, action = 'input-change') => {
    this.resetOptions(); // Reset options when a new character is entered
    if (action === 'input-change') {
      // Here set searchText value as obj to make the Clear icon visible while typing
      this.setState(
        {
          searchText: event !== '' ? { value: event, label: event } : event, // #541 fix
        },
      );
      // Check for filter
      // eslint-disable-next-line no-unused-expressions
      this.props?.personalList && this.props?.personalList?.forEach((contact) => {
        if (contact.name?.toLowerCase().includes(event?.toLowerCase()) ||
          contact.address?.split('@')[0]?.toLowerCase().includes(event?.toLowerCase())) {
          this.setState({ applyFilter: true });
        }
      });

      if (event !== ' ' && event.length > 0) {
        this.debounce(event);
        this.setState({ myContacts: this.debounceLocalFilter(event) });
      }
    } else {
      this.setState(((prevState) => ({
        searchText: prevState.isSelected ?
          prevState.searchText : { label: '', value: '' },
      })));
    }
  }

  // Called when user selects an option (opt.value has uniqueId of the selected user)
  handleSelect = (opt) => {
    this.setState(
      {
        searchText: {
          label: opt?.label,
          value: opt?.label,
          address: opt?.customAbbreviation,
          id: opt?.value,
        },
        isSelected: true,
        highlightId: opt?.value,
      },
    );

    // Send uniqueId of user to be added to the POST request
    this.props.addSearchedContact(opt?.value);
    this.resetOptions();

    // Scroll to the selected contact
    setTimeout(() => {
      document.getElementById(opt?.value)?.scrollIntoView(false, { behavior: 'smooth', block: 'nearest' });
    }, (1500));
  }

  resetOptions = () => {
    if (this.props?.searchList) {
      this.props.clearList();
    }
    this.setState(
      {
        gotSearchResult: false,
        searchText: { label: '', value: '' }, // #1109 fix
      },
    );
  }

  onClearClick = () => {
    this.setState({ applyFilter: false, searchText: { label: '', value: '' }, isSelected: false });
    this.resetOptions();
  }

  onContactSearchOpen = () => {
    // Reset the states
    this.setState(
      {
        // searchText: { label: '', value: '' },
        isSelected: false,
        highlightId: null,
      },
    );
  }

  getFilteredContacts = (event) => {
    let myContacts = [];
    if (this.state.applyFilter) {
      // eslint-disable-next-line array-callback-return
      myContacts = this.props?.personalList?.filter((contact) => (
        (contact.name?.toLowerCase().includes(event?.toLowerCase())) ||
        (contact.address?.split('@')[0]?.toLowerCase().includes(event?.toLowerCase()))));
    }
    return myContacts;
  }

  setupCustomSearch = () => {
    const groupedSearch = [];
    if (this.state.myContacts?.length > 0) {
      groupedSearch.push({
        label: translate('contacts.myContacts'),
        contacts: this.state.myContacts,
      });
    }

    if (this.props?.searchList && this.props?.searchList?.length > 0) {
      groupedSearch.push({
        label: translate('contacts.directory'),
        contacts: this.props?.searchList,
      });
    }
    return groupedSearch;
  }

  render() {
    const groupedSearch = this.setupCustomSearch();
    return (
      <>
        <Popup
          open={this.state.showRemoveModal}
          header={translate('contacts.delete')}
          subText1={this.state.deleteName}
          subText2='?'
          messageText={translate('contacts.deleteMessage')}
          onClose={this.onCancel}
          rightButton={[
            {
              text: translate('contacts.deleteButton'),
              onClick: this.onDelete,
            },
          ]}
          leftButton={[
            {
              text: translate('contacts.cancelAddContact'),
              onClick: this.onCancel,
            },
          ]}
        />
        {this.state.showMessageStatusPopup && (
          <Popup
            open={this.state.showMessageStatusPopup}
            header={this.state.messageStatus}
            messageText={this.state.messageStatusDetail}
            onClose={this.closeStatusPopup}
            rightButton={[
              {
                text: translate('consentDialogue.ok'),
                onClick: this.closeStatusPopup,
              },
            ]}
          />
        )}
        {this.state.guestError === true && (
          <ErrorModal
            open={this.state.guestError}
            header={translate('errors.serverErrorHeading')}
            errorMessage={this.state.guestErrorMessage}
            onClose={() => this.setState({ guestError: false })}
            rightButton={[
              {
                text: translate('login.okButton'),
                onClick: () => this.setState({ guestError: false }),
              },
            ]}
          />
        )}
        <Sidebar>
          <div className={`${this.state.isKeyBoardOpen && isMobileOnly ? 'contact-pane-mobile' : 'contact-pane'} `}>
            <Row className='sidebar-header contact-title px-3'>
              <Col className='d-lg-none d-xl-none' xs={1} sm={1} lg={1} md={1}>
                <BackIcon
                  className='back-icon'
                  alt='back'
                  role='presentation'
                  onClick={this.props.goBack}
                />
              </Col>
              <Col className='alignment'>
                <span className='ps-1 contacts-header'>
                  {translate('contacts.contactsPanelHeader')}
                </span>
              </Col>
            </Row>
            <div className='divider' />

            <Row className='mt-3 mb-3 px-3 sidebar-search '>
              <Col lg={12} md={12} sm={12} xs={12} className='search-bar'>
                <CustomSearch
                  groupedOptions={this.state.searchText?.label !== '' ? groupedSearch : []}
                  handleChange={(e) => this.handleSelect(e)}
                  handleInputChange={(p) => this.handleInputChange(p)}
                  inputValue={this.state.searchText.value}
                  value={this.state.searchText?.value === '' ? this.state.searchText?.value
                    : this.state.searchText}
                  parse={this.parse}
                  onMenuClose={this.resetOptions}
                  onMenuOpen={this.onContactSearchOpen}
                  onClear={this.onClearClick}
                  gotSearchResult={this.state.gotSearchResult}
                />
              </Col>
            </Row>

            <div className='h-100'>
              <div className='contact-list contacts no-horizontal-scroll'>
                {this.props?.personalList?.length === 0 ? (
                  <Row lg={8} md={8} sm={8} className='m-1 mt-2'>
                    <Col className='landscape-horizontal-padding-option mx-3'>
                      <span className='no-contacts'>
                        {translate('contacts.noContactsAdded')}
                      </span>
                    </Col>
                  </Row>
                ) : (
                  this.renderContacts()
                )}
              </div>

              <Row className={`${isMobileOnly ? 'px-4 ml-3 mr-3' : 'px-3'} add-contact mt-1 `}>
                {this.props?.addContactPermission &&
                  this.props?.addContactPermission === POLICY.YES && (
                    <Col
                      xs={this.props.allowGuestInvite ? 6 : 12}
                      sm={this.props.allowGuestInvite ? 6 : 12}
                      md={this.props.allowGuestInvite ? 6 : 12}
                      lg={this.props.allowGuestInvite ? 6 : 12}
                      xl={this.props.allowGuestInvite ? 6 : 12}
                      className='landscape-horizontal-padding-option p-0'
                      tabIndex='0'
                    >
                      <div
                        className='common-div m-0 p-0'
                        role='presentation'
                        onClick={this.handleAddContact}
                      >
                        <AddContactIcon alt='add contact' className='add-contact-icon' />
                        <span className='m-2 add-text'>
                          {translate('contacts.addContacts')}
                        </span>
                      </div>
                    </Col>
                )}
                {this.props.allowGuestInvite && (
                  <Col sm={6} md={6} lg={6} xs={6} xl={6}>
                    <CustomButton
                      className='w-100 rounded-0 guest-btn'
                      onClick={this.handleGuestInvite}
                      text='contacts.inviteGuestFromContactsPanel'
                    />
                  </Col>
                )}
              </Row>
            </div>
          </div>
        </Sidebar>
      </>

    );
  }
}
