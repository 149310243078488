/* eslint-disable no-unused-vars */
/* eslint-disable newline-per-chained-call */

import axiosHelper from 'SERVICES/AxiosHelper';
import { create } from 'xmlbuilder2';
import dateFormat from 'dateformat';
import getStore from 'STORE/Store';
import {
  getCalls,
} from 'STORE/Statistics/StatisticsSelector';
import { ACTIONS } from 'UTILS/constants/ActionConstants';

// Services
import { AppLogger, TraceFlags, LOG_NAME } from 'SERVICES/Logging/AppLogger';

import {
  API_METHODS,
  API_URIS,
} from '../../utils/constants/ApiConstants';

const STATISTICS_RETRY_INTERVAL = 60000; // 60s
const STATUS_DELAY_INTERVAL = 5000; // 5s
const logger = AppLogger(LOG_NAME.CallStatsMgr);

/*
  Singleton class for handling reporting of
  call statistics and call status to Onsight Platform Manager
*/
class _CallStatisticsManager {
  constructor() {
    this.calls = [];
    this.activeCall = null;
    this.activeStream = null;
    this.activeParticipants = [];
    this.localParticipant = null;
  }

  start(localParticipant) {
    const { store } = getStore();
    this.calls = getCalls(
      store.getState(),
    );

    logger.info('_CallStatisticsManager::start()', this.calls);
    if (!this.calls) {
      this.calls = [];
    }
    this.localParticipant = localParticipant;

    this.reportCalls();
    this.reportStatus();
  }

  stop() {
    if (this.reportCallsTimer) {
      clearTimeout(this.reportCallsTimer);
      this.reportCallsTimer = null;
    }
    if (this.reportStatusTimer) {
      clearTimeout(this.reportStatusTimer);
      this.reportStatusTimer = null;
    }
    this.calls = [];
    this.activeCall = null;
    this.activeStream = null;
    this.localParticipant = null;
  }

  beginCall(call) {
    this.endCall();
    call.startTime = new Date();
    call.streams = [];
    this.activeCall = call;
    this.localParticipant.pid = call.localPid; // update the local PID
    this.reportStatus();
  }

  updateCall(callUpdate) {
    if (this.activeCall) {
      if (callUpdate.remotePid) {
        this.activeCall.remotePid = callUpdate.remotePid;
      }
      if (callUpdate.callGuid) {
        this.activeCall.callGuid = callUpdate.callGuid;
      }
      if (callUpdate.remoteVersion) {
        this.activeCall.remoteVersion = callUpdate.remoteVersion;
      }
      this.reportStatus();
    }
  }

  beginStream(stream) {
    if (this.activeCall) {
      stream.startTime = new Date();
      this.activeStream = stream;
    }
    this.reportStatus();
  }

  endStream() {
    if (this.activeCall && this.activeStream) {
      this.activeStream.endTime = new Date();
      this.activeCall.streams.push(this.activeStream);
      this.activeStream = null;
    }
    this.reportStatus();
  }

  endCall(callAborted) {
    if (this.activeCall) {
      this.endStream();
      this.activeCall.callAborted = callAborted;
      this.activeCall.endTime = new Date();
      this.calls.push(this.activeCall);
      this.activeCall = null;
      this.activeParticipants = [];
    }

    // cache in session state
    const { store } = getStore();
    const self = this;
    store.dispatch({
      type: ACTIONS.SET_STATISTICS_CALLS,
      calls: self.calls,
    });

    // report new calls immediately
    this.reportCalls();
    this.reportStatus();
  }

  updateLocalParticipant(localParticipant) {
    this.localParticipant.sipRegistered = localParticipant.sipRegistered;
    this.reportStatus();
  }

  addConferenceParticipant(participant) {
    const idx = this.activeParticipants.findIndex((p) => participant.pid === p.pid);
    if (idx === -1) {
      this.activeParticipants.push(participant);
      this.reportStatus();
    }
  }

  removeConferenceParticipant(participant) {
    const idx = this.activeParticipants.findIndex((p) => participant.pid === p.pid);
    if (idx >= 0) {
      this.activeParticipants.splice(idx, 1);
      this.reportStatus();
    }
  }

  reportCalls() {
    // clear report calls timer
    if (this.reportCallsTimer) {
      clearTimeout(this.reportCallsTimer);
      this.reportCallsTimer = null;
    }

    if (this.calls.length === 0) {
      return;
    }

    // report all outstanding calls
    const callsXml = this.createCallsXml(this.calls);
    logger.debug('_CallStatisticsManager::reportCalls()', callsXml);
    axiosHelper
      .callAPI({
        httpMethod: API_METHODS.POST,
        uri: API_URIS.REPORT_STATISTICS,
        requestBody: {
          RemoteAgentStatisticsXml: callsXml,
        },
      })
      .then(() => {
        // clear session state
        const { store } = getStore();
        store.dispatch({
          type: ACTIONS.CLEAR_STATISTICS_CALLS,
        });
        this.calls = [];
      })
      .catch((error) => {
        // log the error and retry later
        logger.error('_CallStatisticsManager::reportCalls() failed - ', error);
        const self = this;
        this.reportCallsTimer = setTimeout(() => {
          self.reportCalls();
        }, STATISTICS_RETRY_INTERVAL);
      });
  }

  reportStatus() {
    // report status on a 5 second delay in case any other events come in
    if (!this.reportStatusTimer) {
      const self = this;
      this.reportStatusTimer = setTimeout(() => {
        self.reportStatusNow();
      }, STATUS_DELAY_INTERVAL);
    }
  }

  reportStatusNow() {
    // clear report status timer
    if (this.reportStatusTimer) {
      clearTimeout(this.reportStatusTimer);
      this.reportStatusTimer = null;
    }

    // report all outstanding calls
    const statusXml = this.createStatusXml();
    axiosHelper
      .callAPI({
        httpMethod: API_METHODS.POST,
        uri: API_URIS.REPORT_STATUS,
        requestBody: {
          ClientStatusXml: statusXml,
        },
      })
      .then(() => {
        logger.tracer({
          dirn: TraceFlags.DIRECTION.OUT,
          msgType: TraceFlags.MESSAGE.XML,
          message: statusXml,
        }); // Log success
      })
      .catch((error) => {
        // log the error and retry later
        logger.error('_CallStatisticsManager::reportStatus() failed - ', error);
        const self = this;
        this.reportStatusTimer = setTimeout(() => {
          self.reportStatusNow();
        }, STATISTICS_RETRY_INTERVAL);
      });
  }

  createCallsXml(calls) {
    // start the XML document
    const callsXml = create({ version: '1.0', encoding: 'UTF-8', standalone: 'yes' })
      .ele('RASTATS')
      .ele('CS');

    // add the call records
    calls.forEach((call) => {
      if (call && call.remoteVersion && call.localVersion) {
        const callXml = callsXml.ele('C')
          .ele('CLG').txt(call.callGuid).up()
          .ele('OCG').txt('').up() // original call guid (not used)
          .ele('DIR').txt(call.direction).up()
          .ele('PID').txt(call.localPid).up()
          .ele('RPID').txt(call.remotePid).up()
          .ele('LIP').txt('').up() // local IP (not reported by web app, as we don't route media on the local network)
          .ele('RIP').txt('').up() // remote IP (we don't report this, since it will always be the janus server)
          .ele('IVO').txt('false').up() // voice only
          .ele('ISM').txt('false').up() // image sharing mode
          .ele('ENC').txt('true').up() // always encrypted
          .ele('RET').txt((call.remoteVersion.appVer.type === 1 ? '2' : '4')).up() // if type is MCD (1), endpoint type is 2 (ET_LibreStreamMCD), otherwise 4 (ET_LibreStreamMCA)
          .ele('RN').txt(call.remoteName).up()
          .ele('RS').txt(call.remoteAddress).up()
          .ele('REM').txt(call.remoteVersion.appVer.hwInfo.model).up()
          .ele('REH').txt(call.remoteVersion.appVer.hwInfo.hw).up()
          .ele('REPID').txt(call.remoteVersion.appVer.product).up()
          .ele('REPV').txt(`${call.remoteVersion.appVer.major}.${call.remoteVersion.appVer.minor}.${call.remoteVersion.appVer.build}.${call.remoteVersion.appVer.rev}`).up()
          .ele('ROV').txt(call.remoteVersion.appVer.osVersion).up()
          .ele('REC').txt(call.remoteVersion.appVer.carrier).up()
          .ele('SR').txt('true').up() // always sip registered
          .ele('SSV').txt(call.sipServer).up()
          .ele('SID').txt(call.sipSessionId).up()
          .ele('STT').txt(call.sipTransportType).up()
          .ele('SUN').txt(call.sipUserName).up()
          .ele('SURI').txt(call.localAddress.replace('sip:', '')).up()
          .ele('TR').txt(call.aborted ? 'NETWORK_LOSS' : 'NORMAL').up()
          .ele('AT').txt(dateFormat(call.startTime, 'mm/dd/yyyy HH:MM:ss', true)).up()
          .ele('ST').txt(dateFormat(call.startTime, 'mm/dd/yyyy HH:MM:ss', true)).up()
          .ele('ET').txt(dateFormat(call.endTime, 'mm/dd/yyyy HH:MM:ss', true)).up()
          .ele('UN').txt(call.userName).up() // user name
          .ele('LLAT').txt('').up() // TODO: gps coordinates
          .ele('LLONG').txt('').up()
          .ele('LLAT').txt('').up()
          .ele('RLAT').txt('').up()
          .ele('RLONG').txt('').up()
          .ele('RLONG').txt('').up()
          .ele('LPN').txt(call.localName).up()
          .ele('LPA').txt(call.localAddress).up()
          .ele('LM').txt(call.localVersion.appVer.hwInfo.model).up()
          .ele('LH').txt(call.localVersion.appVer.hwInfo.hw).up()
          .ele('LSPID').txt(call.localVersion.appVer.product).up()
          .ele('LSPV').txt(`${call.localVersion.appVer.major}.${call.localVersion.appVer.minor}.${call.localVersion.appVer.build}.${call.localVersion.appVer.rev}`).up()
          .ele('LOV').txt(call.localVersion.appVer.hwInfo.osVersion).up()
          .ele('LCN').txt(call.localVersion.appVer.hwInfo.carrier).up()
          .ele('LTLR').txt('false').up() // no teamlink
          .ele('LTLS').txt('').up() // no teamlink server
          .ele('LTLUID').txt('').up() // no teamlink user
          .ele('LTLGID').txt('').up() // no teamlink group
          .ele('VC').txt('OPUS_WB').up() // voice codec
          .ele('NI').txt('').up(); // network interface (leave as blank, or unknown)

        // streams
        const streamsXml = callXml.ele('SS');
        call.streams.forEach((stream) => {
          streamsXml.ele('S')
            .ele('AC').txt('OPUS_WB').up() // subject audio (not supported, but fill it in anyways)
            .ele('AR').txt('8').up() // audio resolution
            .ele('AS').txt('Mono').up() // audio separation
            .ele('ASR').txt('8000').up() // audio sample rate
            .ele('VC').txt(stream.video.codec).up()
            .ele('VS').txt('InternalCamera').up() // TODO video source should be 'File' when we support recordings
            .ele('VSS').txt('VideoStandard_NTSC').up()
            .ele('VW').txt(stream.video.width).up()
            .ele('VH').txt(stream.video.height).up()
            .ele('VFR').txt(stream.video.frameRate).up()
            .ele('VTB').txt(stream.video.bitRate).up()
            .ele('VPB').txt(stream.video.peakBitRate).up()
            .ele('VGOP').txt(stream.video.gopSize).up()
            .ele('ST').txt(dateFormat(stream.startTime, 'mm/dd/yyyy HH:MM:ss', true)).up()
            .ele('ET').txt(dateFormat(stream.endTime, 'mm/dd/yyyy HH:MM:ss', true)).up()
            .ele('PBR').txt('').up() // peak bitrate (stream stats not currently supported)
            .ele('ABR').txt('').up() // averate bitrate
            .ele('PJ').txt('').up() // peak jitter
            .ele('AJ').txt('').up() // average jitter
            .ele('DIR').txt(stream.direction).up()
            .ele('Q').txt(stream.video.streamQuality).up()
            .ele('MC').txt('2').up() // VideoAndAudio
            .ele('PID').txt(stream.sourcePid).up();
        });
      }
    });

    // close the document
    return callsXml
      .up() // CS
      .up() // RASTATS
      .end({ prettyPrint: false });
  }

  createDurationString(date1, date2) {
    const s = (date2 - date1) / 1000;
    const hours = Math.floor(s / 60 / 60);
    const minutes = Math.floor(s / 60) % 60;
    const seconds = Math.floor(s % 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  createStatusXml() {
    // start the XML document
    const statusXml = create({ version: '1.0', encoding: 'UTF-8', standalone: 'yes' })
      .ele('RAS')
      .ele('ET').txt('Unknown').up() // endpoint type
      .ele('AR').txt('yes').up() // app running
      .ele('SW').ele('VS').ele('V') // software
      .ele('D').txt('Software_Application').up()
      .ele('N').txt(this.localParticipant.version).up()
      .up().up().up();

    // session
    const sessionXml = statusXml.ele('S');
    if (this.activeCall && this.activeCall.remoteVersion) {
      sessionXml.ele('S').txt('Connected').up()
        .ele('ST').txt(dateFormat(this.activeCall.startTime, 'mm/dd/yyyy HH:MM:ss', true)).up()
        .ele('DU').txt(this.createDurationString(this.activeCall.startTime, new Date())).up()
        .ele('RN').txt(this.activeCall.remoteName).up()
        .ele('RS').txt(this.activeCall.remoteAddress).up()
        .ele('DI').txt(this.activeCall.direction).up()
        .ele('EN').txt('true').up() // encrypted
        .ele('P').txt(this.activeCall.remotePid).up()
        .ele('ET').txt((this.activeCall.remoteVersion.appVer.type === 1 ? '2' : '4')).up()
        .ele('VC').txt('OPUS_WB').up()
        .ele('PS').txt('Unknown').up();
    } else {
      sessionXml.ele('S').txt('Disconnected').up();
    }

    // participants
    const participantsXml = statusXml.ele('PS');

    // add local participant
    participantsXml.ele('P')
      .ele('S').txt('This device').up()
      .ele('RN').txt(this.localParticipant.name).up()
      .ele('RS').txt(this.localParticipant.sipUri).up()
      .ele('P').txt(this.localParticipant.pid).up()
      .ele('PS').txt('Unknown').up();

    // add remote participants
    if (this.activeCall != null && this.activeCall.remoteVersion) {
      participantsXml.ele('P')
        .ele('S').txt('Connected').up()
        .ele('ST').txt(dateFormat(this.activeCall.startTime, 'mm/dd/yyyy HH:MM:ss', true)).up()
        .ele('DU').txt(this.createDurationString(this.activeCall.startTime, new Date())).up()
        .ele('RN').txt(this.activeCall.remoteName).up()
        .ele('RS').txt(this.activeCall.remoteAddress).up()
        .ele('DI').txt(this.activeCall.direction).up()
        .ele('EN').txt('true').up() // encrypted
        .ele('P').txt(this.activeCall.remotePid).up()
        .ele('ET').txt((this.activeCall.remoteVersion.appVer.type === 1 ? '2' : '4')).up()
        .ele('VC').txt('OPUS_WB').up()
        .ele('PS').txt('Unknown').up(); // picture sharing mode

      // add indirect participants
      this.activeParticipants.forEach((participant) => {
        participantsXml.ele('P')
          .ele('S').txt('Connected').up()
          .ele('ST').txt(dateFormat(this.activeCall.startTime, 'mm/dd/yyyy HH:MM:ss', true)).up()
          .ele('DU').txt('').up() // duration
          .ele('RN').txt(participant.name).up()
          .ele('RS').txt(participant.addr).up()
          .ele('DI').txt('').up() // direction
          .ele('EN').txt(participant.isEncrypted === 1 ? 'true' : 'false').up() // encrypted
          .ele('P').txt(participant.pid).up()
          .ele('ET').txt(participant.endpointType).up()
          .ele('VC').txt(participant.voiceCodec).up()
          .ele('PS').txt('Unknown').up(); // picture sharing mode
      });
    }

    // conference
    const conferenceXml = statusXml.ele('C');
    if (this.activeCall != null && this.activeCall.remoteVersion) {
      conferenceXml
        .ele('HP').txt(this.activeCall.remotePid).up() // for web app, host PID is always the direct participant
        .ele('PR').txt('0').up() // privacy not yet supported
        .ele('MB').txt('6 Mbps').up() // bandwidth control not yet supported
        .ele('PS').txt('Unknown').up(); // picture sharing mode
    }

    // streams
    const streamsXml = statusXml.ele('SS');
    if (this.activeStream != null) {
      streamsXml.ele('S')
        .ele('T').txt('Media').up()
        .ele('A') // audio
        .ele('C').txt('OPUS_WB').up() // subject audio (not supported, but fill it in anyways)
        .ele('R').txt('8').up() // audio resolution
        .ele('S').txt('Mono').up() // audio separation
        .ele('SR').txt('8000').up() // audio sample rate
        .up() // end audio
        .ele('V') // video
        .ele('C').txt(this.activeStream.video.codec).up()
        .ele('S').txt('InternalCamera').up() // TODO video source should be 'File' when we support recordings
        .ele('SS').txt('VideoStandard_NTSC').up()
        .ele('R').txt(this.activeStream.video.width + 'x' + this.activeStream.video.height).up()
        .ele('FR').txt(Math.floor((this.activeStream.video.frameRate * 10) / 1001) / 10).up()
        .ele('TB').txt(this.activeStream.video.bitRate / 1000 + ' Kbps').up()
        .ele('PB').txt(this.activeStream.video.peakBitRate / 1000 + ' Kbps').up()
        .ele('GOP').txt(this.activeStream.video.gopSize).up()
        .up() // end video
        .up();
    }

    // sip
    const sipXml = statusXml.ele('SIP');
    sipXml.ele('E').txt('1').up() // enabled
      .ele('R').txt(this.localParticipant.sipRegistered ? '1' : '0').up() // registered
      .ele('ST').txt(this.localParticipant.sipRegistered ? 'Registered' : 'Unregistered').up()
      .ele('S').txt(this.localParticipant.sipServer).up() // server
      .ele('URI').txt(this.localParticipant.sipUri).up()
      .ele('T').txt(this.localParticipant.sipTransportType).up() // transport type
      .up(); // end sip

    // close the document
    return statusXml
      .up() // RAS
      .end({ prettyPrint: false });
  }
}

// export singleton
const CallStatisticsManager = new _CallStatisticsManager();
export default CallStatisticsManager;
