import { ACTIONS } from 'UTILS/constants/ActionConstants';
import { STREAM_SHARE_STATE } from 'UTILS/constants/UtilityConstants';
import { IMAGE_SHARE_MODE } from '../../utils/constants/DatastreamConstant';

const INITIAL_STATE = {
  audioInputDeviceList: [],
  audioOutputDeviceList: [],
  videoInputDeviceList: [],
  videoOn: false,
  audioOn: true,
  selectedAudioInputDevice: null,
  selectedAudioOutputDevice: null,
  selectedVideoDevice: null,
  isIncomingCall: null,
  showVideoFallback: false,
  screenShareOn: false,
  isVideoPaused: false,
  callActive: false,
  streamShareState: STREAM_SHARE_STATE.UNKNOWN,
  imageShareMode: IMAGE_SHARE_MODE.NONE,
};

// eslint-disable-next-line func-names
export default function (state = INITIAL_STATE, action = {}) {
  // console.log('CallControlReducer:: actions::', JSON.stringify(action));
  switch (action.type) {
    case ACTIONS.SET_DEVICE_LIST:
      return {
        ...state,
        audioInputDeviceList: action.audioInputDeviceList,
        audioOutputDeviceList: action.audioOutputDeviceList,
        videoInputDeviceList: action.videoInputDeviceList,
      };
    case ACTIONS.CLEAR_DEVICE_LIST:
      return {
        ...state,
        audioInputDeviceList: INITIAL_STATE.audioInputDeviceList,
        audioOutputDeviceList: INITIAL_STATE.audioOutputDeviceList,
        videoInputDeviceList: INITIAL_STATE.videoInputDeviceList,
        selectedVideoDevice: INITIAL_STATE.selectedVideoDevice,
        selectedAudioInputDevice: INITIAL_STATE.selectedAudioInputDevice,
        selectedAudioOutputDevice: INITIAL_STATE.selectedAudioOutputDevice,
      };
    case ACTIONS.SET_VIDEO_ON_OFF:
      return {
        ...state,
        videoOn: action.isVideoOn,
      };
    case ACTIONS.SET_AUDIO_ON_OFF:
      return {
        ...state,
        audioOn: action.isAudioOn,
      };
    case ACTIONS.SET_AUDIO_INPUT_DEVICE:
      return {
        ...state,
        selectedAudioInputDevice: action.selectedAudioInputDevice,
      };
    case ACTIONS.SET_AUDIO_OUTPUT_DEVICE:
      return {
        ...state,
        selectedAudioOutputDevice: action.selectedAudioOutputDevice,
      };
    case ACTIONS.SET_VIDEO_DEVICE:
      return {
        ...state,
        selectedVideoDevice: action.selectedVideoDevice,
      };
    case ACTIONS.SET_VIDEO_DEVICES:
      return {
        ...state,
        videoInputDeviceList: action.videoDevices,
      };
    case ACTIONS.SET_IS_INCOMING_CALL:
      return {
        ...state,
        isIncomingCall: action.isIncomingCall,
      };
    case ACTIONS.SET_SHOW_VIDEO_FALLBACK:
      return {
        ...state,
        showVideoFallback: action.showVideoFallback,
      };
    case ACTIONS.SET_SCREEN_SHARE_ON:
      return {
        ...state,
        screenShareOn: action.isScreenShareOn,
      };
    case ACTIONS.SET_VIDEO_PAUSE:
      return {
        ...state,
        isVideoPaused: action.isVideoPaused,
      };

    case ACTIONS.SET_STREAM_SHARE_STATE:
      return {
        ...state,
        streamShareState: action.streamShareState,
      };

    case ACTIONS.SET_IMAGE_SHARE_MODE:
      return {
        ...state,
        imageShareMode: action.imageMode,
      };
    case ACTIONS.SET_CALL_STATUS:
      return {
        ...state,
        callActive: action.callStatus,
      };
    default:
      return state;
  }
}
