import PropTypes from 'prop-types';

import './CustomRadioButton.scss';

function CustomRadioButton(props) {
  return (
    <input
      className={`form-radio-input + ${props.className}`}
      type='radio'
      id={props.id}
      name={props.name}
      defaultChecked={props.checked}
      value={props.checked}
      onChange={props.onChange}
    />
  );
}

CustomRadioButton.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

CustomRadioButton.defaultProps = {
  id: '',
  name: '',
  checked: false,
  onChange: () => {
    // Do Nothing
  },
  className: '',
};

export default CustomRadioButton;
