/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

// Modal
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

// React bootstrap
import { Row, Col, ProgressBar } from 'react-bootstrap';

import CustomButton from 'COMPONENTS/CustomComponents/CustomButton/CustomButton';

// CSS
import './ImageCaptureConsentModal.scss';

// Translation
import { translate } from 'SERVICES/i18n';

// Constant
import { BUTTON_TYPES } from 'UTILS/constants/UtilityConstants';

// eslint-disable-next-line react/prefer-stateless-function
class ImageCaptureConsentModal extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.imageDialogueRef = React.createRef(null);
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      isOpen: nextProps.open,
    };
  }

  render() {
    // const now = 60;
    const messageText = this.props.interpolationText
      ? translate(this.props.messageText, {
        interpolationText: this.props.interpolationText,
      })
      : translate(this.props.messageText);
    return (
      // eslint-disable-next-line no-nested-ternary
      <div className={`${this.state.isOpen ? this.props.overlayClass : 'd-none'}`} ref={this.imageDialogueRef}>

        <Modal
          role='dialog'
          open={this.state.isOpen}
          center={false}
          showCloseIcon={false}
          classNames={{
            modal: ` image-capture-modal ${this.props.modalClass}`,
            overlay: 'custom-overlay',
          }}
          onClose={this.props.onClose}
          closeOnOverlayClick={false}
          container={this.imageDialogueRef?.current}
          onEscKeyDown={this.props.onClose}
          focusTrapped={false}
        >
          <div className='modal-body' tabIndex='-1'>
            <Col>
              <Row className='p-3 modal-header ellipsis'>
                <span className={`p-0 header ${this.props.headerClass}`}>
                  {translate(this.props.header)}
                </span>
              </Row>
              {this.props.isProgressBar && (
                <Row className='p-1'>
                  <Col>
                    <Row className='message-text pt-1 px-2'>
                      {translate(messageText) + ' ' + this.props.percent + '%'}
                    </Row>
                    <Row className='pt-2 px-2'>
                      <ProgressBar now={this.props.percent} />
                    </Row>
                  </Col>
                </Row>
              )}
              <Row className={`pt-3 ${this.props.buttonClass ? this.props.buttonClass : 'align-items-end-center'}`}>
                {this.props.leftButton &&
                  this.props.leftButton.map((button, index) => (
                    <CustomButton
                      key={index}
                      className={`align-center action-buttons cancel-button ${button.buttonClass}`}
                      onClick={button.onClick}
                      text={button.text}
                      variant={BUTTON_TYPES.SECONDARY}
                    />
                  ))}
                {this.props.rightButton &&
                  this.props.rightButton.map((button, index) => (
                    <CustomButton
                      key={index}
                      className={`align-center action-buttons ${button.buttonClass}`}
                      onClick={button.onClick}
                      disabled={button.disabled}
                      type={button.type}
                      text={button.text}
                    />
                  ))}
              </Row>
            </Col>
          </div>
        </Modal>
      </div>
    );
  }
}

ImageCaptureConsentModal.propTypes = {
  header: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  // okButton: PropTypes.objectOf,
  // cancelButton: PropTypes.objectOf,
  modalClass: PropTypes.string,
  percent: PropTypes.number,
  isProgressBar: PropTypes.bool,
  headerClass: PropTypes.string,
  buttonClass: PropTypes.string,
};

ImageCaptureConsentModal.defaultProps = {
  header: '',
  onClose: () => { },
  open: false,
  // okButton: {},
  // cancelButton: {},
  modalClass: '',
  percent: 0,
  isProgressBar: false,
  headerClass: '',
  buttonClass: '',
};

export default ImageCaptureConsentModal;
