/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  withOrientationChange,
} from 'react-device-detect';

// Style
import './Dashboard.scss';

class Sidebar extends PureComponent {
  render() {
    return (
      <Row className={`h-100 ${this.props.className} ${this.props.isFromCallDashboard ? 'position-absolute' : ''}`}>
        <Col lg={9} className='d-lg-block d-none' />
        <Col
          lg={3}
          sm={12}
          className='pt-3 p-0 m-0 sidebar h-100'
        >
          {this.props.children}
        </Col>
      </Row>
    );
  }
}

export default withOrientationChange(Sidebar);
