import { createGlobalStyle } from 'styled-components';

// To support multi-branding, add respective classes here acc. to theme
// eslint-disable-next-line no-undef
// Added classes - actual-icon:hover, .chevron-arrow:hover for bug #650
const GlobalStyles = createGlobalStyle`

 .active-icon {
   background-color: ${({ theme }) => theme.activeIcon.background} !important;
  }

  .popover, .popover-body {
    background-color: ${({ theme }) => theme.telestration.background} !important;
  }

  .popover-header, .nlp__control, .nlp__option, .nlp__control--is-focused, .nlp__menu{
    background-color: ${({ theme }) => theme.telestration.headerBackground} !important;
  }

  .nlp__option:hover{
    background-color: ${({ theme }) => theme.iconBtn.background};
  }
  .nlp-divider{
    border-bottom: 0.06rem solid ${({ theme }) => theme.border.dividerBorderColor} !important;
  }

.header-container{
  .header{
   background-color: ${({ theme }) => theme.headerFooter.background} !important;
  }
  
  .icons-width:hover, .actual-icon:hover, .chevron-arrow:hover{
   background-color: ${({ theme }) => theme.iconBtn.background};
  }
  .dropdown-container{
  .dropdown-menu {
    background-color: ${({ theme }) => theme.dropdown.background} !important;
      }
      .dropdown-item:hover {
    background-color: ${({ theme }) => theme.iconBtn.background} !important;
    }
  } 
}
.footer-container{
  .footer{
   background-color: ${({ theme }) => theme.headerFooter.background} !important;
  }
  .arrow{
    background-color: ${({ theme }) => theme.arrowColor.background} !important;
   }
}
.mobile-landscape-view {
  .landscape-footer {
    background-color: ${({ theme }) => theme.arrowColor.background} !important;
  }
}
.primary-button{
   background-color: ${({ theme }) => theme.button.background} !important;
   border-color: ${({ theme }) => theme.button.background} !important;
   color: ${({ theme }) => theme.button.color} !important;
   border-radius: 0!important;
   box-shadow: none !important;
}
.secondary-button{
   background-color: ${({ theme }) => theme.secondaryButton.background} !important;
   border: 0.0625rem solid ${({ theme }) => theme.secondaryButton.color} !important;
   color: ${({ theme }) => theme.secondaryButton.color} !important;
   border-radius: 0!important;
   box-shadow: none !important;
}

.login-layout {
  background-color: ${({ theme }) => theme.loginLayout.background} !important;
  .sub-component .login-container-layout .login-container .checked-button .react-switch-bg {
    background-color: ${({ theme }) => theme.button.background} !important;
}
}

.text-field-component, .profile-fields{
  .form-control, :focus{
  background-color: ${({ theme }) => theme.textbox.background} !important;
  }
  .form-control:disabled,
  .form-control[readonly] {
    background-color: ${({ theme }) => theme.textbox.background} !important;
    border: 1px solid ${({ theme }) => theme.textbox.background};
  }
}
 

input[type="text"]:focus, input[type="password"]:focus, input[type="tel"]:focus, input[type="email"]:focus {
    border: 2px solid ${({ theme }) => theme.border.borderColor} !important;
  }

#email:-webkit-autofill,
  #password:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 62.5rem ${({ theme }) =>
    theme.textbox.background} inset;
    box-shadow: 0 0 0px 62.5rem ${({ theme }) =>
    theme.textbox.background} inset;
    -webkit-text-fill-color: ${({ theme }) => theme.textbox.textColor};
  }

.search:-webkit-autofill, search:focus {
    -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) =>
    theme.sidebar.background} inset;
    box-shadow: 0 0 0px 1000px ${({ theme }) =>
    theme.sidebar.background} inset;
    -webkit-text-fill-color: ${({ theme }) => theme.textbox.textColor};
    border: 1px solid #ffffff50;
 }

#name:-webkit-autofill, #webAddr:-webkit-autofill,
#guestInvite-firstname:-webkit-autofill, #guestInvite-lastname:-webkit-autofill,
#guestInvite-email:-webkit-autofill, #guestInvite-phone:-webkit-autofill, #expiry:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) =>
    theme.addContactTextField.background} inset;
    box-shadow: 0 0 0px 1000px ${({ theme }) =>
    theme.addContactTextField.background} inset;
    -webkit-text-fill-color: ${({ theme }) => theme.textbox.textColor};
  }

#guest-email:-webkit-autofill,
  #guest-name:-webkit-autofill,
  #company-domain:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 62.5rem ${({ theme }) =>
    theme.textbox.background} inset;
    box-shadow: 0 0 0px 62.5rem ${({ theme }) =>
    theme.textbox.background} inset;
    -webkit-text-fill-color: ${({ theme }) => theme.textbox.textColor};
  }


  .custom-searchable-dropdown {
    .custom-searchable-dropdown__control {
      background-color: ${({ theme }) => theme.dropdown.background};
    }
    .custom-searchable-dropdown__menu {
      background-color: ${({ theme }) => theme.dropdown.background};
      .custom-searchable-dropdown__option:hover,
      .custom-searchable-dropdown__menu-notice--no-options:hover {
          background-color: ${({ theme }) => theme.iconBtn.background};
        }
    }
  }
  
  // Dropdown for audio/video panels
.custom-dropdown{
    .dropdown-toggle {
      background-color: ${({ theme }) => theme.dropdown.background} !important;
    }
    .dropdown-menu.show {
      background-color: ${({ theme }) => theme.dropdown.background} !important;
    }
    .dropdown-toggle:disabled {
      background-color: ${({ theme }) => theme.dropdown.background} !important;
    }
    .dropdown-menu > .dropdown-item:hover {
      background-color: ${({ theme }) => theme.iconBtn.background} !important;
    }
}

//Dropdown for settings/videoquality
.videoQuality{
  .custom-dropdown{
      .dropdown-toggle {
        background-color: ${({ theme }) => theme.headerFooter.background} !important;
      }
      .dropdown-menu.show {
        background-color: ${({ theme }) => theme.headerFooter.background} !important;
      }
      .dropdown-toggle:disabled {
        background-color: ${({ theme }) => theme.headerFooter.background} !important;
      }
      .dropdown-menu > .dropdown-item:hover {
        background-color: ${({ theme }) => theme.iconBtn.background} !important;
      }
  }
}

.sidebar{
    background-color: ${({ theme }) => theme.sidebar.background} !important;
    border-top: 0.06rem solid ${({ theme }) => theme.border.sidebarBorderTop} !important;
    .field {
      background-color: ${({ theme }) => theme.customTextbox.background} !important;
    }
}

.divider{
    border-bottom: 0.06rem solid ${({ theme }) => theme.border.dividerBorderColor} !important;
}

  // Settings and its related sidebars for mobile
.settings-sidebar, .my-profile, .general, .videoQuality, .calls, .bandwidth-control{
    .sidebar{
      background-color: ${({ theme }) => theme.headerFooter.background} !important;
      border-top: 0.06rem solid ${({ theme }) => theme.border.sidebarBorderTop} !important;
      .field {
        background-color: ${({ theme }) => theme.settingsTextField.background} !important;
      }
    
    } 
}

.custom-modal,
.image-capture-modal {
  background:  ${({ theme }) =>
    theme.modal.background} 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 15px ${({ theme }) => theme.modal.boxShadow};
}

.custom-select {
  .custom-contact-search__menu, .custom-contact-search__option, 
  .custom-contact-search__control, .custom-contact-search__control--is-focused{
    background-color: ${({ theme }) => theme.sidebar.background};
  }
}

//Custom search and NLP search hover
.custom-contact-search__option:hover, .nlp__option:hover{
  background-color: ${({ theme }) => theme.iconBtn.background} !important;
}

.highlight-contact{
  background: ${({ theme }) => theme.iconBtn.background} !important;
}

.custom-modal-settings{
  background-color: ${({ theme }) => theme.headerFooter.background} !important;
}

.checked-button .react-switch-bg {
  background-color: ${({ theme }) => theme.button.background} !important; 
}

.Toastify__toast-theme--colored.Toastify__toast--default,
.Toastify__toast-theme--colored.Toastify__toast--info,
.Toastify__toast-theme--colored.Toastify__toast--success,
.Toastify__toast-theme--colored.Toastify__toast--warning,
.Toastify__toast-theme--colored.Toastify__toast--error{
  background:  ${({ theme }) => theme.headerFooter.background} !important;
}

// Banner theme
.Toastify__toast-theme--banner.Toastify__toast--default,
.Toastify__toast-theme--banner.Toastify__toast--info {
  color: red;
}

// Radio buttons
.form-radio-input[type=radio]:checked {
  background-color: ${({ theme }) => theme.radioButton.backgroundColor} !important;
  border-color: ${({ theme }) => theme.radioButton.borderColor} !important;
}
`;
export default GlobalStyles;
