/* eslint-disable no-bitwise */
import {
  LS_DATASTREAM_EVENTS,
  LS_DATASTREAM_EVENTSEVERITY,
  LS_DATASTREAM_CORE_EVENTS,
  LS_DATASTREAM_LIVE_VIDEO_TOKEN_ACTION,
  STREAM_STATUS,
  LSDS_EVENT,
  DATAPACKET_FLAGS,
  TELEPOINT_TYPE_FLAGS,
  STILL_IMAGE_CAPTURE_REQUEST_MASK,
  STILL_IMAGE_CAPTURE_STATUS,
  STILL_IMAGE_SHARE_MODE_STATUS,
} from 'UTILS/constants/DatastreamConstant';

import {
  VIDEO_MEDIA_PROFILE,
} from 'UTILS/constants/MediaServerConstants';

// Utility
import CommonUtility from 'UTILS/CommonUtility';

// Services
import {
  AppLogger,
  TraceFlags,
  LOG_LEVEL,
  LOG_NAME,
} from 'SERVICES/Logging/AppLogger';

/* Set this to true, to view ALL LS RX/TX exchange,
Setting this to false, shows only the ones listed in loggers
*/
const logger = AppLogger(LOG_NAME.LSDSEvents,
  window.dynamicEnv.REACT_APP_LSDS_DEBUG_MODE ? LOG_LEVEL.TRACER : undefined);

export const EVENT_RX = 'RX';
export const EVENT_TX = 'TX';

export const evtIdToName = (id) => CommonUtility.mapName(LS_DATASTREAM_EVENTS, id)
  .split('_')
  .slice(1)
  .join('_');

export const teleTypes = (val) => {
  let flagNames = '';
  // eslint-disable-next-line no-restricted-syntax
  for (const [name, flag] of Object.entries(TELEPOINT_TYPE_FLAGS)) {
    flagNames += val & flag ? name : '';
  }
  return flagNames;
};

export const imageCaptureRequestValue = (val) => ({
  quality: val & STILL_IMAGE_CAPTURE_REQUEST_MASK.QUALITY_MASK,
  mode: (val & STILL_IMAGE_CAPTURE_REQUEST_MASK.CAPTURE_MODE_MASK)
    >> STILL_IMAGE_CAPTURE_REQUEST_MASK.CAPTURE_MODE_SHIFT,
  flag: val >> STILL_IMAGE_CAPTURE_REQUEST_MASK.REMOTE_SHIFT,
});

export const logFlags = (flagDefObj, value) => {
  const flags = [];
  Object.entries(flagDefObj).forEach(([name, mask]) => {
    // eslint-disable-next-line no-bitwise
    if (mask & value) {
      flags.push(name);
    }
  });
  return (flags.join(' & '));
};

export function argbToHexStr(val) {
  let colorVal = val;
  if (val < 0) {
    colorVal = (val + 0xFFFFFFFF + 1);
  }
  return `${val} (0x${colorVal.toString(16).toUpperCase()})`;
}

/**
 * Performs pretty logger.logging for the in/out DS event
 * @param {bool} dir EVENT_TX / EVENT_RX
 * @param {object} event Event data
 * @param {string} evtLabel Label for event
 * @returns none
 */
export const eventLog = (dir, event, evtLabel = '') => {
  let evtName = null;
  let evtData = null;
  let evtId = null;
  const genericLog = (args) => {
    // If args are present then it means we have created handler for the event
    // we are interested in hence we add it to log
    if (args) {
      logger.log(`EVENT-${dir} => [${evtLabel}.${evtName}] `, args ?? '');
    }
    /* Detailed (duplicate) logs for extensive debugging */
    logger.tracer({
      dirn: dir === EVENT_TX ? TraceFlags.DIRECTION.OUT : TraceFlags.DIRECTION.IN,
      msgType: TraceFlags.MESSAGE.LSDATA,
      message: `[${evtLabel}.${evtName}] ` + JSON.stringify(evtData),
    });
  };

  const teleLogger = () => {
    Object.keys(TELEPOINT_TYPE_FLAGS);
    const evtTypes = [];
    Object.entries(TELEPOINT_TYPE_FLAGS).forEach(([key, value]) => {
      // eslint-disable-next-line no-bitwise
      if (evtData.type & value) {
        evtTypes.push(key);
      }
    });

    let tlogStr = null;
    if (evtData.colorArgb && (evtData.colorArgb !== -1)) {
      tlogStr = `Color: ${argbToHexStr(evtData.colorArgb)}`;
    }

    genericLog(`Type: ${evtData.type} => ${evtTypes.join(' & ')} ${tlogStr ?? ''}`);
  };

  const evtLoggers = {
    [LS_DATASTREAM_EVENTS.ID_NETWORK_OPEN]: () => {
      genericLog(`Severity:${CommonUtility.mapName(LS_DATASTREAM_EVENTSEVERITY, evtData.severity)}:${evtData.severity}`);
    },
    [LS_DATASTREAM_EVENTS.ID_STILL_IMAGE_RECV]: () => {
      genericLog(`Severity:${CommonUtility.mapName(LS_DATASTREAM_EVENTSEVERITY, evtData.severity)}:${evtData.severity}`);
      if (evtData.severity === LS_DATASTREAM_EVENTSEVERITY.INFO) {
        genericLog(`Image Details: Name: ${evtData.image.name} Size: ${evtData.image.fileSize}`);
      }
    },
    [LS_DATASTREAM_CORE_EVENTS.EventLiveVideoToken]: () => {
      genericLog(`Action:${CommonUtility.mapName(LS_DATASTREAM_LIVE_VIDEO_TOKEN_ACTION,
        evtData.action)}(${evtData.action})`);
    },
    [LS_DATASTREAM_CORE_EVENTS.SysEventInitComplete]: () => {
      genericLog(`Status:${evtData.value ? 'Complete' : 'Initiated'}`);
    },
    [LS_DATASTREAM_CORE_EVENTS.RemoteMicMuteStatusChanged]: () => {
      genericLog(`Mute-Status:${evtData.mute ? 'Muted' : 'UnMuted'}`);
    },
    [LS_DATASTREAM_CORE_EVENTS.StatusEventActiveMCDChange]: () => {
      genericLog(`PId:${evtData.pid}`);
    },
    [LS_DATASTREAM_CORE_EVENTS.StatusEventStreamQuality]: () => {
      genericLog(`StreamQuality:${CommonUtility.mapName(VIDEO_MEDIA_PROFILE,
        evtData.value)}(${evtData.value})`);
    },
    [LS_DATASTREAM_CORE_EVENTS.CfgEventMediaVideoConfigChanged]: () => {
      genericLog(`StreamQuality:${evtData.streamQuality}`);
    },
    [LS_DATASTREAM_CORE_EVENTS.CfgEventCurrentMediaVideoConfigChanged]: () => {
      genericLog(`StreamQuality:${evtData.streamQuality}`);
    },
    [LS_DATASTREAM_CORE_EVENTS.CfgEventVideoConfigChanged]: () => {
      genericLog(`AvailableSubSources:${JSON.stringify(evtData.availableSubSources)}
        CurrentSource: ${evtData.currentSubSource}`);
    },
    [LS_DATASTREAM_CORE_EVENTS.CfgEventMediaAudioConfigChanged]: () => {
      genericLog(`StreamQuality:${evtData.streamQuality}`);
    },
    [LS_DATASTREAM_CORE_EVENTS.CfgEventCurrentMediaAudioConfigChanged]: () => {
      genericLog(`StreamQuality:${evtData.streamQuality}`);
    },
    [LS_DATASTREAM_CORE_EVENTS.CfgEventMediaInfoChanged]: () => {
      genericLog(`Title:${evtData.title}`);
    },
    [LS_DATASTREAM_CORE_EVENTS.StatusEventEncLimits]: () => {
      genericLog(`DefinedLimit:${evtData.definedLimit}`);
    },
    [LS_DATASTREAM_CORE_EVENTS.StatusEventDecLimits]: () => {
      genericLog(`DefinedLimit:${evtData.definedLimit}`);
    },
    [LS_DATASTREAM_CORE_EVENTS.StillImageRemoteCaptureRequest]: () => {
      genericLog(`Value:${evtData.value} => ${JSON.stringify(imageCaptureRequestValue(evtData.value))}`);
    },
    [LS_DATASTREAM_CORE_EVENTS.StatusEventStillImageCaptureState]: () => {
      genericLog(`State:${CommonUtility.mapName(STILL_IMAGE_CAPTURE_STATUS, evtData.value)}(${evtData.value})`);
    },
    [LS_DATASTREAM_CORE_EVENTS.StatusEventStreamState]: () => {
      genericLog(`State:${CommonUtility.mapName(STREAM_STATUS, evtData.value)}(${evtData.value})`);
    },
    [LS_DATASTREAM_CORE_EVENTS.EventVideoSourcesForParticipant]: () => {
      genericLog(`CurrentSubSource:${evtData.currentSubSource} 
        AvailableVideoSources:${evtData.videoSources.availableVideoSubSources}`);
    },
    [LS_DATASTREAM_CORE_EVENTS.CfgEventTelestrationColours]: () => {
      const logData = {};
      if (evtData.colour) {
        if (Array.isArray(evtData.colour)) {
          // Temporary till issue fixed with color change
          Object.values(evtData.colour).forEach((colorData) => {
            logData[colorData.participantId] = argbToHexStr(colorData.colourCode);
          });
        } else {
          const colorData = evtData.color;
          logData[colorData.participantId] = argbToHexStr(colorData.colourCode);
          genericLog(`Colorchange: ${JSON.stringify(logData)}`);
        }
      }
    },
    [LS_DATASTREAM_CORE_EVENTS.StatusEventStreamFreeze]: () => {
      genericLog(`Stream State:${evtData.value ? 'Frozen' : 'UnFrozen'}`);
    },
    [LS_DATASTREAM_CORE_EVENTS.StatusEventStillImageShareModeState]: () => {
      genericLog(
        `Stream State:${CommonUtility.mapName(STILL_IMAGE_SHARE_MODE_STATUS, evtData.value)}(${evtData.value})`,
      );
    },
  };

  const ignoreEvents = [
    LS_DATASTREAM_EVENTS.ID_DATA_STREAM_RX_STATS,
    LS_DATASTREAM_EVENTS.ID_DATA_STREAM_TX_STATS,
    LS_DATASTREAM_CORE_EVENTS.StatusEventVideoLinkInfo,
    LS_DATASTREAM_CORE_EVENTS.StatusEventRemoteVideoRxLinkInfo,
    LS_DATASTREAM_CORE_EVENTS.StatusEventRtcpRR,
    LS_DATASTREAM_CORE_EVENTS.StatusEventGpsInfo,
  ];
  if (event.id && ignoreEvents.includes(event.id)) {
    // Ignoring frequent messages to avoid log clutter
    // If required add a flag to enable log for debugging
    return;
  }
  if (dir === EVENT_RX) {
    if (event.id === LS_DATASTREAM_EVENTS.ID_CONFIGCTRL_RECV) {
      if (ignoreEvents.includes(event.cfgctrl.type)) {
        return;
      }
      evtLabel = LSDS_EVENT.CFGCTRL;
      evtId = event.cfgctrl.type;
      evtName =
        CommonUtility.mapName(LS_DATASTREAM_CORE_EVENTS, event.cfgctrl.type) +
        ':' +
        event.cfgctrl.type.toString();

      evtData = event.cfgctrl.data;
    } else if (event.id === LS_DATASTREAM_EVENTS.ID_TELEPOINT_RECV) {
      evtLabel = LSDS_EVENT.TELEPOINT;
      evtId = event.id;
      evtName = evtIdToName(event.id) + ':' + event.id;
      evtData = event.telepoint;
    } else {
      evtId = event.id;
      evtName = evtIdToName(event.id) + ':' + event.id;
      evtData = event;
    }
  } else {
    // EVENT_TX
    /* eslint-disable no-lonely-if */
    if (evtLabel === LSDS_EVENT.CFGCTRL) {
      evtId = event.type;
      evtName =
        CommonUtility.mapName(LS_DATASTREAM_CORE_EVENTS, event.type) +
        ':' +
        event.type.toString();
      evtData = event.data;
    } else if (evtLabel === LSDS_EVENT.TELEPOINT) {
      evtId = event.id;
      evtName = '';
      evtData = event;
    } else {
      evtId = event.id;
      evtName = '';
      evtData = JSON.stringify(event);
    }
  }
  if (evtLabel === LSDS_EVENT.TELEPOINT) {
    teleLogger();
  } else if (evtLoggers[evtId]) evtLoggers[evtId]();
  else {
    /* Avoiding logging details for non-specific messages;
     just the header shall be logged */
    genericLog();
  }
};

/**
 * Abstration class for handling LSDS event sending and logging
 */
class LSDSEvent {
  constructor() {
    this.sendCB = null;
    this.CfgCtrl = {};
    this.registerSenders(LS_DATASTREAM_CORE_EVENTS);
  }

  setup(sendCB) {
    this.sendCB = sendCB;
  }

  setRemotePid(pid) {
    logger.debug('LSDS:: Remote PID set: ', pid);
    this.remotePid = pid;
  }

  registerSenders(events) {
    const self = this;
    // eslint-disable-next-line no-restricted-syntax
    for (const [eventName, id] of Object.entries(events)) {
      this.CfgCtrl[eventName] = (evtData) => {
        self.sendCfgCtrl(id, [this.remotePid], evtData);
      };
    }
  }

  send(event, evtLabel) {
    if (this.sendCB && this.sendCB(event, evtLabel)) {
      eventLog(EVENT_TX, event, evtLabel); // Logged earlier before sending to maintain order
    } else {
      logger.error('Failure in Send');
    }
  }

  // Send configuration to LS Datastream protocol
  sendCfgCtrl = (type, pids, data) => {
    const cfgctrl = {
      type,
      pids,
      flags: DATAPACKET_FLAGS.DPF_SET,
      data,
    };
    this.send(cfgctrl, LSDS_EVENT.CFGCTRL);
  };

  sendTelepoint = (data) => {
    this.send(data, LSDS_EVENT.TELEPOINT);
  }
}

export const LSDS = new LSDSEvent();
