import { ACTIONS } from 'UTILS/constants/ActionConstants';

const initialState = {
  globalContacts: [],
  personalContacts: [],
  inviterUniqueId: null,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_GLOBAL_CONTACTS:
      return {
        ...state,
        globalContacts: action.globalContacts,
      };
    case ACTIONS.SET_PERSONAL_CONTACTS:
      return {
        ...state,
        personalContacts: action.personalContacts ? action.personalContacts : [],
      };
    case ACTIONS.CLEAR_GLOBAL_CONTACTS:
      return {
        ...state,
        globalContacts: [],
      };
    case ACTIONS.SET_INVITER_ID:
      return {
        ...state,
        inviterUniqueId: action.inviterUniqueId && action.inviterUniqueId,
      };
    default:
      return state;
  }
};
