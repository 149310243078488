/* eslint-disable no-lonely-if */
/* eslint-disable no-bitwise */
/* eslint-disable array-callback-return */

import _ from 'lodash';
// Constants
import {
  TELESTRATION_ITEM_TYPE,
  EXTENDED_FUNCTIONS,
  DEFAULT_FONT_SIZE,
  DEFAULT_TEXT_INPUT_SIZE,
  DEFAULT_FONT_ID,
  FREE_HAND_COORDS_BATCH,
  TELESTRATION_MOUSE_EVENTS,
  TELEV3_CHAR_CODE_PID,
  TELESTRATION_COLORS,
  TELEV3_REMOTE_COLOR_BIN,
  UNDO_TELE_COORDS_LEN,
} from 'UTILS/constants/TelestrationConstant';
import { TELEPOINT_TYPE_FLAGS, LS_DATASTREAM_PARTICIPANTID } from 'UTILS/constants/DatastreamConstant';
import { VIDEO_LOW_RES_DEFAULTS } from 'UTILS/constants/MediaServerConstants';
import { CALL_DASHBOARD, LOCAL_STORAGE_KEY } from 'UTILS/constants/DOMElementConstants';
import { TelePointConstants, hexToArgb } from 'SERVICES/lsexif/telestration';

// Services
import { AppLogger, LOG_NAME } from 'SERVICES/Logging/AppLogger';
import {
  isLoggedInUserActiveVideoSharer, getLocalPId,
  getCurrentVideoConfig,
} from '../LSDatastream/LSDSCommon';

import { CanvasManager } from './CanvasManager';

// LOCAL CONSTANTS
const MOUSE_DOWN = true;
const MOUSE_UP = true;
const MOUSE_MOVE = true;

const logger = AppLogger(LOG_NAME.TeleHelper);

export default class TelestrationHelper {
  /*
  *  Structure of array :
  *  videoTelestrations = [{pid : pid, telestrations: [{}, {}, .....]}, { }, ....]
  */
  videoTelestrations;

  imageTelestrations;

  constructor(loggedInUserName) {
    const instance = this;
    this.loggedInUserName = loggedInUserName;
    this.videoTelestrations = [];
    this.imageTelestrations = [];
    // Defines the interface for calls from from LSDS layer here
    this.api = {
      onColorChange: instance.onColorChange,
      setData: instance.setData,
      reset: instance.reset,
      redraw: instance.redraw,
    };
    // Lower layer API for transport of messages
    this.dsAPI = null;
    // MERGE CHECK
    this.selectedStrokeWidth = 0;
    this.hasTextInput = false;
    this.selectedVideoConfig = {};
    this.cm = null;
    this.isImageShared = false;
    //  Free hand telestration data
    this.fhData = {
      isMouseDown: false,
      isMouseMove: false,
      isMouseUp: false,
      mousedownData: [],
      mousemoveData: [],
      mouseupData: [],
      // eslint-disable-next-line
      reset: function () {
        this.mousedownData = [];
        this.mousemoveData = [];
        this.mouseupData = [];
        this.isMouseDown = false;
        this.isMouseMove = false;
        this.isMouseUp = false;
      },
      // eslint-disable-next-line
      setFlagsForMouseEvent: function (event) {
        this.isMouseMove = false;
        this.isMouseUp = false;
        this.isMouseDown = false;
        switch (event) {
          case TELESTRATION_MOUSE_EVENTS.MOUSE_MOVE:
            this.isMouseMove = true;
            break;
          case TELESTRATION_MOUSE_EVENTS.MOUSE_UP:
            this.isMouseUp = true;
            break;
          case TELESTRATION_MOUSE_EVENTS.MOUSE_DOWN:
            this.isMouseDown = true;
            break;
          default:
            logger.warn('Tele::Case not handled', event);
            break;
        }
      },
    };
  }

  initializeCanvasManager = (videoConfig, delay = 0) => {
    setTimeout(() => {
      const telestrationCanvas = document.getElementById(CALL_DASHBOARD.TELESTRATION_CANVAS_ID);
      this.isImageShared = false;
      // Erase image telestrtaions
      if (this.doImageTelestrationsExist()) {
        this.eraseTelestrations(true);
      }
      if (telestrationCanvas) {
        this.cm = new CanvasManager(telestrationCanvas, false);
        this.canvasResize(videoConfig);
        this.attachEventListnersBasedOnTeleType();
        logger.debug('TelestrationHelper::Canvas initialized for video');
      } else {
        logger.warn('TelestrationHelper::initializeCanvas:: DOM element for canvas missing with id:',
          CALL_DASHBOARD.TELESTRATION_CANVAS_ID);
      }
    }, delay);
  }

  /**
   * Initializes the lower layer transport functions to be called from here
   * @param {object} dsAPI Callbacks for performing transport functions over DS using
   * LSDSTelestration
   */
  initDS(dsAPI) {
    this.dsAPI = dsAPI;
  }

  /* ?? Do we need to add check for userTelestration > 0 before performing reset?
  Ref Context. when called on orientationChange ?? */
  reset = (selectedVideoConfig = null, sendErase = false, removeText = false) => {
    logger.info('TelestrationHelper::Resetting Telestrations');
    this.eraseTelestrations();
    this.eraseTelestrations(true);
    this.canvasResize(selectedVideoConfig);
    if (sendErase) {
      this.dsAPI.sendErase();
    }

    if (removeText) {
      // Remove text area elements from telestration if any
      const textEle = document.getElementById(CALL_DASHBOARD.TELE_TEXT_INPUT);
      textEle?.remove();
    }
  }

  reInitializeCanvasManagerForImage = (videoConfig, delay = 0, imageData = null) => {
    setTimeout(() => {
      logger.info(`TelestrationHelper::Reinitialize canvas manager for image with video config:${JSON.stringify(videoConfig)}`);

      const telestrationCanvas = document.getElementById(CALL_DASHBOARD.TELESTRATION_CANVAS_ID_IMG);
      this.cm = new CanvasManager(telestrationCanvas, true);
      this.canvasResize(videoConfig, true);
      this.attachEventListnersBasedOnTeleType();
      if (imageData) {
        this.handleImageData(
          {
            TeleV3: _.cloneDeep(imageData.TeleV3),
            mediaConfig: imageData.config,
          },
        );
      }
    }, delay);
  }
  /**
   * @param  {} isVideoShared To clear and reset telestration
   *  and to send clear to remote user as well
   * @param  {} forVideOnly To clear video telestrations only.
   * The actions here are video bandwidth change, video stream started.
   */

  handleTelestrationsOnActions = (profile, isVideoShared, forVideOnly, sendErase = true) => {
    if (isVideoShared) {
      this.reset(profile, sendErase);
    } else {
      // Clear video telestrations only
      this.clearTSArray(forVideOnly);
    }
  }

  /**
  * This function will only deal with orientation change
  * @param  {} isImageShared to check if image is shared
  * @param  {} forVideOnly will be true only if video telestrations need to be cleared
  */

  handleTelestrationsOnOrientationChange = (isImageShared, forVideOnly) => {
    this.dsAPI.sendClearAll(false);
    this.clearTSArray(forVideOnly);
    if (!isImageShared) {
      this.resetCanvas();
    }
  }

  /**
   * Reset/Clear the canvas for further drawings
   */
  resetCanvas = () => {
    logger.info('TelestrationHelper::Clearing canvas manager');
    if (this.cm) {
      this.cm.context.clearRect(0, 0, this.cm.canvas.width, this.cm.canvas.height);
    }
  }

  /**
   * @param  {} data data required to draw the shape like cords, color, shape type etc
   * @param  {} isImage=false if the telestrations are for image
   * @param  {} isVideoTeleOnImage=false if telestrations are present on video whenimage is captured
   * @param  {} isCloseContext=false To close the context to avoid the
   *  line joining two telestrations
   */
  addShapeToCanvas = (
    data,
    isImage = false,
    isVideoTeleOnImage = false,
    isCloseContext = false,
    isFhRedraw = false,
  ) => {
    logger.debug('TelestrationHelper::LSDSTelestration addShapeToCanvas videoTelestrations data:', data, isImage, isFhRedraw, isCloseContext);
    const isSelfVideo = isLoggedInUserActiveVideoSharer();
    const isLocalTelestration = false;
    if ((
      data?.telestrationExtendedType !== TELESTRATION_ITEM_TYPE.FREE_HAND.type
      || isCloseContext)
      && this.cm) {
      this.cm.context.beginPath();
    }
    // eslint-disable-next-line no-nested-ternary
    const mediaConfig = data.config;
    switch (data?.telestrationExtendedType) {
      case TELESTRATION_ITEM_TYPE.ARROW_LINE.type:
        this.cm?.drawLines(data?.coords, 0, 1, true, data?.colorArgb,
          isSelfVideo,
          mediaConfig,
          this.selectedStrokeWidth,
          isLocalTelestration,
          isVideoTeleOnImage);
        break;
      case TELESTRATION_ITEM_TYPE.LINE.type:
        this.cm?.drawLines(data?.coords, 0, 1, false, data?.colorArgb,
          isSelfVideo,
          mediaConfig, this.selectedStrokeWidth,
          isLocalTelestration, isVideoTeleOnImage);
        break;
      case TELESTRATION_ITEM_TYPE.ELLIPSE.type:
        this.cm?.drawEllipse(data?.coords, 0, 1, data?.colorArgb,
          isSelfVideo,
          mediaConfig, this.selectedStrokeWidth,
          isLocalTelestration, isVideoTeleOnImage);
        break;
      case TELESTRATION_ITEM_TYPE.RECTANGLE.type:
        this.cm?.drawRectangle(data.coords, 0, 1, data?.colorArgb,
          isSelfVideo,
          mediaConfig, this.selectedStrokeWidth,
          isLocalTelestration, isVideoTeleOnImage);
        break;
      case TELESTRATION_ITEM_TYPE.TEXT.type:
        this.cm?.drawText({
          text: data.text,
          fontId: data.fontid,
          fontSize: DEFAULT_FONT_SIZE,
        },
        data.coords, data?.colorArgb,
        isSelfVideo,
        mediaConfig, this.selectedStrokeWidth,
        isLocalTelestration, isVideoTeleOnImage);
        break;
      case TELESTRATION_ITEM_TYPE.FREE_HAND.type:
        if (data && data.coords) {
          const isMouseDown = (data.type & TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_PENDOWN) !== 0;
          const isMouseMove = ((data.type & TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_PENDOWN) === 0
            && (data.type & TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_PENUP) === 0);
          const isMouseUp = (data.type & TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_PENUP) !== 0;
          this.cm?.drawFreeHandTelestration(
            data.coords,
            0,
            (data.coords.length - 1),
            false,
            data.colorArgb,
            isSelfVideo,
            isMouseDown,
            isMouseMove,
            isMouseUp,
            mediaConfig,
            this.selectedStrokeWidth,
            isLocalTelestration,
            isVideoTeleOnImage,
            isFhRedraw,
          );
        }
        break;
      default: break;
    }
    // Added isCloseContext to close the cuurent canvas
    // context in case of freehand telestrations of old image
    if (
      (data?.telestrationExtendedType !== TELESTRATION_ITEM_TYPE.FREE_HAND.type
        || isCloseContext)
      && this.cm) {
      this.cm.context.closePath();
    }
  }

  /*
    Function to store the data received from datastream
  */
  setData = (data, isForImage) => {
    logger.info(`TelestrationHelper::Set telestration data for users::${data} is for image :${isForImage}`);
    this.isImageShared = isForImage;
    let telestrationsToModify;
    if (this.isImageShared) {
      telestrationsToModify = this.imageTelestrations;
    } else {
      telestrationsToModify = this.videoTelestrations;
    }
    if ((data.type & TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_CLEAR_PIDS) !== 0) {
      const isImageKind = (data.type & TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_STILL_IMAGE_KIND) !== 0;
      this.handleEraseTelestrationsEvents(data, isImageKind);
    } else if ((data.type & TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_REMOVE) !== 0) {
      this.undoDrawnTelestration(data?.pid, data.telestrationExtendedType);
    } else {
      const indexOfPid = telestrationsToModify.findIndex((user) => user.pid === data?.pid);
      const IS_LOCAL_TELESTRATION = false;
      this.addTelestrationShapeData(
        data,
        telestrationsToModify,
        indexOfPid,
        isForImage,
        IS_LOCAL_TELESTRATION,
      );
    }
    logger.info('LSDSTelestration All the received telestrations:', this.videoTelestrations, this.imageTelestrations);
  }

  /*
    Colour received change for a particular participant
  */
  onColorChange = (colourData) => {
    if (this.doImageTelestrationsExist()) {
      this.updateTelestartionsOnColourChangeEvent(colourData, this.imageTelestrations);
      this.updateTelestartionsOnColourChangeEvent(colourData, this.videoTelestrations);
    } else {
      this.updateTelestartionsOnColourChangeEvent(colourData, this.videoTelestrations);
    }
  }

  updateTelestartionsOnColourChangeEvent = (colourData, telestrationsToModify) => {
    const UNCHANGED = 0;
    const CHANGED = 1;
    let flag = UNCHANGED;
    let selectedTelestration;
    colourData.colour.map((colors) => {
      selectedTelestration = telestrationsToModify.find((user) => (
        (colors.participantId === user.pid)
        && (colors.colourCode !== user.colourCode)
        && ((colors.participantId & TELEV3_CHAR_CODE_PID) === 0)
      ));
      // Update color codes for the user in telestration array whose color is changed
      if (selectedTelestration) {
        selectedTelestration.colourCode = colors.colourCode;
        selectedTelestration.telestrations.map((shape) => {
          shape.colorArgb = colors.colourCode[0] === '#' ? hexToArgb(colors.colourCode) : colors.colourCode;
        });
        flag = CHANGED;
      }
    });
    if (flag === CHANGED) {
      this.resetCanvas();
      this.redraw(this.doImageTelestrationsExist() || this.isImageShared);
    }
  }

  /**
   * @param  {boolean} isImage=false , decides which set of telestrations to erase
   * @param  {boolean} sendDSMsg=false , decides whether a datastream notification
   * is to be sent to other participants
   */
  eraseTelestrations = (
    isImage = false,
    sendDSMsg = false,
    retainExifTS = false,
    clearPidData = undefined,
  ) => {
    // reset user telestration array
    logger.debug(`TelestrationHelper::Erase ${isImage ? 'Image' : 'Video'} telestrations`, clearPidData);
    if (isImage) {
      if (!this.doImageTelestrationsExist()) {
        logger.warn('TelestrationHelper::No image telestrations are found to undo!');
        return;
      }
      if (retainExifTS) {
        this.imageTelestrations = this.imageTelestrations.filter((user) =>
          (user.pid & TELEV3_CHAR_CODE_PID));
      } else {
        this.imageTelestrations = this.eraseTelestrationsByPid(
          clearPidData,
          this.imageTelestrations,
        );
      }
    } else {
      this.videoTelestrations = this.eraseTelestrationsByPid(
        clearPidData,
        this.videoTelestrations,
      );
    }
    this.resetCanvas();
    this.redraw(isImage);
    if (sendDSMsg) {
      logger.info('TelestrationHelper:: Erasing all image telestration of remote user');
      // send DS message to other user/s
      this.dsAPI.sendClearAll(isImage);
    }
  }
  /**
   * @param  {} clearPid , Will be an array of participants pid, of which
   * telestrations should get cleared.
   * @param  {} telestrationToUpdate The user video/image telestrations
   * Will return an telstrations of filtered users than clear pid if
   * present else return empty array to erase all
   */

  eraseTelestrationsByPid = (clearPid, telestrationToUpdate) => {
    let updatedTelestrationData = [];
    if (clearPid && (
      clearPid.length > 0
      && clearPid[0] !== LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID)) {
      updatedTelestrationData = telestrationToUpdate.filter((telestration) =>
        !clearPid.includes(telestration.pid));
    }
    return updatedTelestrationData;
  }

  /**
 *  Undo previous telestration
 * @param {number} pid id of a user to undo the action
 */
  undoDrawnTelestration = (pid) => {
    // clear the canvas and redraw remaining shapes
    this.resetCanvas();
    let telestrationToModify;
    // delete last drawn telestration from array where id is same
    if (this.doImageTelestrationsExist()) {
      telestrationToModify = this.imageTelestrations;
      logger.debug(`TelestrationHelper::Undo performed for image telestrations for pid : ${pid}`);
    } else if (this.doVideoTelestrationsExist() && !this.isImageShared) {
      logger.debug(`TelestrationHelper::Undo performed for video telestrations for pid : ${pid}`);
      telestrationToModify = this.videoTelestrations;
    }
    if (telestrationToModify && telestrationToModify.length > 0) {
      telestrationToModify.forEach((user) => {
        if (user.pid === pid) {
          user.telestrations.pop();
        }
      });
      // Extra check to handle the empty image telestrations data
      this.clearTelestrationsArray();
      // Redraw telestrations on canvas as videoTelestrations are updated
      this.redraw(this.doImageTelestrationsExist());
    } else {
      logger.warn('No shape is drawn to undo telestration locally');
    }
  }

  /**
*  Undo previous telestration
* @param {number} pid id of a user to undo the action
* @param {string} teleType type of telestration to undo the action

*/
  undoTelestration = (pid) => {
    let elementToDlt = {};
    let telestrationsToModify;
    if (this.doImageTelestrationsExist()) {
      telestrationsToModify = this.imageTelestrations;
    } else if (!this.isImageShared) {
      telestrationsToModify = this.videoTelestrations;
    }
    const telestrationObj = telestrationsToModify.find((user) => user.pid === pid);
    // eslint-disable-next-line no-unsafe-optional-chaining
    elementToDlt = telestrationObj?.telestrations[telestrationObj?.telestrations?.length - 1];
    if (elementToDlt) {
      // undo the last drawn telestration
      this.undoDrawnTelestration(pid);
      // TODO - check if teleDetails is required or elementToDlt can be used

      // create data obj to send the DS message
      const teleDetails = {
        colorArgb: elementToDlt.colorArgb,
        coords: [elementToDlt.coords[0], elementToDlt.coords.slice(-1)[0]],
        num: UNDO_TELE_COORDS_LEN,
        telestrationExtendedType: elementToDlt.telestrationExtendedType,
        text: elementToDlt.text ?? '',
      };
      logger.info(`TelestrationHelper::The element::${JSON.stringify(elementToDlt)} to be undone 
      of pid::${pid} with data::${JSON.stringify(teleDetails)}`);

      // send DS message to other user/s
      this.dsAPI.sendTelepointExt(teleDetails, this.isImageShared);
    } else {
      logger.warn('TelestrationHelper::No shape is drawn to undo');
    }
  }

  /*
    Redraw the existing telestrations
  */
  redraw = (isImage = false) => {
    if (this.doImageTelestrationsExist() && this.isImageShared) {
      this.processTelestrationsToRedraw(this.imageTelestrations, isImage);
    } else if (this.doVideoTelestrationsExist() && !isImage) {
      this.processTelestrationsToRedraw(this.videoTelestrations, isImage);
    }
  }

  /**
   * @param  {Array} telestrationsToRedraw
   * @param  {boolean} isImage
   */
  processTelestrationsToRedraw = (telestrationsToRedraw, isImage) => {
    telestrationsToRedraw.map((user) => {
      if (user.telestrations && user.telestrations.length > 0) {
        user.telestrations.map((shapes) => {
          const IS_FH_REDRAW = true;
          this.addShapeToCanvas(shapes, isImage, false, false, IS_FH_REDRAW);
          // To draw the old image telestrations if present in the telestration array
          if (this.isImageShared || isImage) {
            this.drawTelestrationsOnImageFromData([shapes], user.colourCode, null, true);
          }
        });
      }
    });
  }

  /**
   * This function resizes the canvas as per the video stream
   * @param  {} isSelfVideo - identifies which is the active video element - self/remote
   *
   */
  canvasResize = (selectedVideoConfig, isImage = false) => {
    let videoElement = null;
    let aspectRatio = 0;
    let canvasHeight = 0;
    let canvasWidth = 0;
    this.selectedVideoConfig = selectedVideoConfig;
    if (this.cm && this.cm.canvas) {
      if (isImage) {
        videoElement = document.getElementById(CALL_DASHBOARD.PHOTO_ID);
        canvasWidth = videoElement.width;
        canvasHeight = videoElement.height;
      } else {
        if (isLoggedInUserActiveVideoSharer()) {
          videoElement = document.getElementById(CALL_DASHBOARD.SELF_VIDEO_ID);
        } else {
          videoElement = document.getElementById(CALL_DASHBOARD.REMOTE_VIDEO_ID);
        }
        if (videoElement) {
          const videoWidth = selectedVideoConfig ?
            selectedVideoConfig?.width : VIDEO_LOW_RES_DEFAULTS.width;
          const videoHeight = selectedVideoConfig ?
            selectedVideoConfig.height : VIDEO_LOW_RES_DEFAULTS.height;
          aspectRatio = videoWidth / videoHeight;
          if (window.innerHeight > window.innerWidth) { // portrait mode
            canvasWidth = videoElement.clientWidth;
            canvasHeight = videoElement.clientWidth / aspectRatio;
          } else if (window.innerHeight < window.innerWidth) { // landscape mode
            canvasHeight = videoElement.clientHeight;
            canvasWidth = videoElement.clientHeight * aspectRatio;
          }
        }
      }

      this.cm.canvas.width = canvasWidth;
      this.cm.canvas.height = canvasHeight;
      this.cm.canvasOffset = this.cm.canvas.getBoundingClientRect();
      this.redraw(isImage);
    }
  }

  getLocalUsersTelestrationLength = (isForImage = false) => {
    let length = 0;
    let localUserTelestrations;
    if (!isForImage) {
      localUserTelestrations = this.videoTelestrations?.find((user) => user?.pid === getLocalPId());
      length = localUserTelestrations?.telestrations?.length;
    } else if (this.doImageTelestrationsExist()) {
      localUserTelestrations = this.imageTelestrations?.find((user) => user?.pid === getLocalPId());
      length = localUserTelestrations?.telestrations?.length;
    }
    return length || 0;
  }

  // TODO: Check if this implementation needs to be changed  after process meta data implementation
  getAllUserTelestrationLength = (isForImage = false) => {
    let length = 0;
    if (this.doImageTelestrationsExist()) {
      this.imageTelestrations.map((user) => {
        // eslint-disable-next-line no-unsafe-optional-chaining
        length += user?.telestrations?.length;
      });
    }
    if (!isForImage) {
      this.videoTelestrations?.map((user) => {
        // eslint-disable-next-line no-unsafe-optional-chaining
        length += user?.telestrations?.length;
      });
    }
    return length || 0;
  }
  /**
   * @param  {} iconId : The id on icon which to be selected
   * @param  {} selectedColor : Local user telestration color
   * @param  {} strokeWidth : The width of telestartion
   * @param  {} selectedVideoConfig : Selcted video config
   * @param  {} isImage: To check if image is shared
   * This function will do a job of initilization of veriable that
   * are required to start local telestration.
   */

  initLocalTelestration = (iconId, selectedColor, strokeWidth, selectedVideoConfig, isImage) => {
    this.selectedTelestration = iconId;
    this.selectedVideoConfig = selectedVideoConfig;
    this.isImageShared = isImage;
    this.removeTextInput();
    this.selectedColor = selectedColor;
    this.selectedStrokeWidth = strokeWidth;
    this.attachEventListnersBasedOnTeleType();
  }
  /**
   * @param  {} iconId: The id on icon which to be selected
   * @param  {} selectedColor: Local user telestration color
   * @param  {} strokeWidth: The width of telestartion
   * @param  {} selectedVideoConfig: Selcted video config
   * @param  {} isImageTo: check if image is shared
   * This function will do a job of starting local telestrations
   * based on user interaction for draw/erase/undo.
   */

  startLocalTelestration = (iconId, selectedColor, strokeWidth, selectedVideoConfig, isImage) => {
    // Need to set this icon as if not done here ,
    // the undo / erase action will not trigger for next subsequent undo.
    this.selectedTelestration = iconId;
    this.selectedVideoConfig = selectedVideoConfig;
    this.isImageShared = isImage;
    this.removeTextInput();
    if (!this.cm) {
      logger.warn('TelestrationHelper::Canv is not initialized yet!');
      return;
    }
    if (this.selectedTelestration === EXTENDED_FUNCTIONS?.UNDO?.name) {
      this.undoTelestration(getLocalPId());
      this.removeAllEventListeners();
    } else if (this.selectedTelestration === EXTENDED_FUNCTIONS?.ERASE?.name) {
      if (this.doImageTelestrationsExist()) {
        this.eraseTelestrations(true, true);
      } else {
        this.eraseTelestrations(false, true);
      }
      this.removeAllEventListeners();
    } else {
      this.selectedColor = selectedColor;
      this.selectedStrokeWidth = strokeWidth;
      this.attachEventListnersBasedOnTeleType();
    }
  }

  removeAllEventListeners = () => {
    this.cm.canvas.removeEventListener(TELESTRATION_MOUSE_EVENTS.CLICK, this.onMouseClick);
    this.cm.canvas.removeEventListener(TELESTRATION_MOUSE_EVENTS.MOUSE_DOWN, this.onMouseDown);
    this.cm.canvas.removeEventListener(TELESTRATION_MOUSE_EVENTS.MOUSE_UP, this.onMouseUp);
    this.cm.canvas.removeEventListener(
      TELESTRATION_MOUSE_EVENTS.TOUCH_START,
      this.onTouchStart,
    );
    this.cm.canvas.removeEventListener(
      TELESTRATION_MOUSE_EVENTS.MOUSE_MOVE,
      this.onMouseMove,
    );
    this.cm.canvas.removeEventListener(TELESTRATION_MOUSE_EVENTS.TOUCH_END, this.onTouchEnd);
    this.cm.canvas.removeEventListener(TELESTRATION_MOUSE_EVENTS.TOUCH_MOVE, this.onTouchMove);
  }

  removeTextInput = () => {
    const textInput = document.getElementById(CALL_DASHBOARD.TELE_TEXT_INPUT);
    const textTeleSingleCharElt = document.getElementById('text-tele-len');
    textTeleSingleCharElt?.remove();
    textInput?.remove();
    this.hasTextInput = false;
  }

  checkIfEventIsForCanvas = () => {
    window.addEventListener('click', (event) => {
      if (this.cm?.canvas) {
        // if the source element doesn't have id (in this case- slider of a popover),
        // remove listeners as we don't need them for this component
        if (event.srcElement?.id === '') {
          this.removeAllEventListeners();
        } else {
          this.attachEventListnersBasedOnTeleType();
        }
      }
    });
  }

  onClickOfOffCanvas = () => {
    window.addEventListener('click', (event) => {
      const textInput = document.getElementById(CALL_DASHBOARD.TELE_TEXT_INPUT);
      if (textInput && event.srcElement?.id &&
        event.srcElement?.id !== CALL_DASHBOARD.TELESTRATION_CANVAS_ID &&
        event.srcElement?.id !== CALL_DASHBOARD.TELESTRATION_CANVAS_ID_IMG) {
        logger.info('TelestrationHelper:: Text box should get cleared if present on outside click', event, this.hasTextInput);
        if (textInput.value === '') {
          this.removeTextInput();
        } else {
          this.onMouseClick(event);
        }
      }
    });
  }

  attachEventListnersBasedOnTeleType = () => {
    if (!this.cm) {
      logger.warn('TelestrationHelper::Canv is not initialized yet!');
      return;
    }
    if (this.selectedTelestration === TELESTRATION_ITEM_TYPE.TEXT.name) {
      this.cm.canvas.addEventListener(TELESTRATION_MOUSE_EVENTS.CLICK, this.onMouseClick);
      this.cm.canvas.removeEventListener(
        TELESTRATION_MOUSE_EVENTS.MOUSE_DOWN,
        this.onMouseDown,
      );
      this.cm.canvas.removeEventListener(
        TELESTRATION_MOUSE_EVENTS.MOUSE_UP,
        this.onMouseUp,
      );
      this.cm.canvas.removeEventListener(
        TELESTRATION_MOUSE_EVENTS.MOUSE_MOVE,
        this.onMouseMove,
      );
      this.cm.canvas.removeEventListener(
        TELESTRATION_MOUSE_EVENTS.TOUCH_START,
        this.onTouchStart,
      );
      this.cm.canvas.removeEventListener(
        TELESTRATION_MOUSE_EVENTS.TOUCH_END,
        this.onTouchEnd,
      );
      this.cm.canvas.removeEventListener(
        TELESTRATION_MOUSE_EVENTS.TOUCH_MOVE,
        this.onTouchMove,
      );
    } else {
      this.cm.canvas.removeEventListener(
        TELESTRATION_MOUSE_EVENTS.CLICK,
        this.onMouseClick,
      );
      this.cm.canvas.addEventListener(
        TELESTRATION_MOUSE_EVENTS.MOUSE_DOWN,
        this.onMouseDown,
      );
      this.cm.canvas.addEventListener(
        TELESTRATION_MOUSE_EVENTS.MOUSE_UP,
        this.onMouseUp,
      );
      this.cm.canvas.addEventListener(
        TELESTRATION_MOUSE_EVENTS.TOUCH_START,
        this.onTouchStart,
      );
      this.cm.canvas.addEventListener(
        TELESTRATION_MOUSE_EVENTS.TOUCH_END,
        this.onTouchEnd,
      );
    }
  }

  clearListners = () => {
    logger.info('TelestrationHelper::Event listener for click is removed');
    window.onClickOfOffCanvas = null;
  }

  onMouseClick = (event) => {
    this.cm.preservedOffSetTop = this.cm.canvasOffset.top;
    if (!this.hasTextInput) {
      this.startX = event.clientX;
      this.startY = event.clientY;
    }
    this.toggleInputElement();
  }

  onMouseDown = (event) => {
    this.startX = event.clientX;
    this.startY = event.clientY;
    this.fhData.reset();
    this.cm.canvas.addEventListener(TELESTRATION_MOUSE_EVENTS.MOUSE_MOVE, this.onMouseMove);
    if (this.selectedTelestration === TELESTRATION_ITEM_TYPE.FREE_HAND.name) {
      this.fhData.mousedownData = this.setMouseEventDataForFreeHand(
        TELESTRATION_MOUSE_EVENTS.MOUSE_DOWN,
        { x: this.startX, y: this.startY },
      );
      this.fhData.setFlagsForMouseEvent(TELESTRATION_MOUSE_EVENTS.MOUSE_DOWN);
      this.cm.context.beginPath();
      this.drawLocalTelestration();
    }
  }

  onMouseUp = (event) => {
    this.endX = event.clientX;
    this.endY = event.clientY;
    this.fhData.reset();
    this.fhData.mouseupData = this.setMouseEventDataForFreeHand(
      TELESTRATION_MOUSE_EVENTS.MOUSE_UP,
      { x: this.endX, y: this.endY },
    );
    this.fhData.setFlagsForMouseEvent(TELESTRATION_MOUSE_EVENTS.MOUSE_UP);
    this.drawLocalTelestration();
    this.cm.context.closePath();
    this.cm.canvas.removeEventListener(TELESTRATION_MOUSE_EVENTS.MOUSE_MOVE, this.onMouseMove);
  }

  onMouseMove = (event) => {
    if (this.selectedTelestration === TELESTRATION_ITEM_TYPE.FREE_HAND.name) {
      this.startX = event.clientX;
      this.startY = event.clientY;
      this.fhData.reset();
      this.fhData.mousemoveData = this.setMouseEventDataForFreeHand(
        TELESTRATION_MOUSE_EVENTS.MOUSE_MOVE,
        { x: this.startX, y: this.startY },
      );
    }
    this.fhData.setFlagsForMouseEvent(TELESTRATION_MOUSE_EVENTS.MOUSE_MOVE);
    if (this.selectedTelestration !== TELESTRATION_ITEM_TYPE.FREE_HAND.name) {
      this.endX = event.clientX;
      this.endY = event.clientY;
      this.resetCanvas();
      this.redraw(this.doImageTelestrationsExist() || this.isImageShared);
    }
    this.drawLocalTelestration('', this.selectedTelestration === TELESTRATION_ITEM_TYPE.FREE_HAND.name);
  }

  onTouchStart = (event) => {
    this.startX = event.touches[0].clientX;
    this.startY = event.touches[0].clientY;
    this.fhData.reset();
    this.cm.canvas.addEventListener(TELESTRATION_MOUSE_EVENTS.TOUCH_MOVE, this.onTouchMove);
    if (this.selectedTelestration === TELESTRATION_ITEM_TYPE.FREE_HAND.name) {
      this.fhData.mousedownData = this.setMouseEventDataForFreeHand(
        TELESTRATION_MOUSE_EVENTS.MOUSE_DOWN,
        { x: this.startX, y: this.startY },
      );
      this.fhData.setFlagsForMouseEvent(TELESTRATION_MOUSE_EVENTS.MOUSE_DOWN);
      this.drawLocalTelestration();
    }
  }

  onTouchMove = (event) => {
    if (this.selectedTelestration === TELESTRATION_ITEM_TYPE.FREE_HAND.name) {
      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
      this.fhData.reset();
      this.fhData.mousemoveData = this.setMouseEventDataForFreeHand(
        TELESTRATION_MOUSE_EVENTS.MOUSE_MOVE,
        { x: this.startX, y: this.startY },
      );
    }
    this.fhData.setFlagsForMouseEvent(TELESTRATION_MOUSE_EVENTS.MOUSE_MOVE);
    if (this.selectedTelestration !== TELESTRATION_ITEM_TYPE.FREE_HAND.name) {
      this.endX = event.touches[0].clientX;
      this.endY = event.touches[0].clientY;
      this.resetCanvas();
      this.redraw(this.doImageTelestrationsExist());
    }
    this.drawLocalTelestration('', this.selectedTelestration === TELESTRATION_ITEM_TYPE.FREE_HAND.name);
  }

  onTouchEnd = (event) => {
    this.endX = event.changedTouches[0].clientX;
    this.endY = event.changedTouches[0].clientY;
    logger.debug(`TelestrationHelper::Touch ended coords start - [x:${this.startX},y:${this.startY}]  end - [x:${this.endX}, y:${this.endY}]`);
    this.fhData.reset();
    this.fhData.mouseupData = this.setMouseEventDataForFreeHand(
      TELESTRATION_MOUSE_EVENTS.MOUSE_UP,
      { x: this.endX, y: this.endY },
    );
    this.fhData.setFlagsForMouseEvent(TELESTRATION_MOUSE_EVENTS.MOUSE_UP);
    this.drawLocalTelestration();
    this.cm.canvas.removeEventListener(TELESTRATION_MOUSE_EVENTS.TOUCH_MOVE, this.onTouchMove);
  }

  /**
 *
 * @param {*} telestrationCoords co-ordinates to draw the shape
 * @param {*} telestrationType the telestration type to draw the shape
 */
  setAndShareTelestrationData = (telestrationCoords, telestrationType,
    isSelfVideo, config, textData) => {
    const localPid = getLocalPId();
    const localColorArgb = this.selectedColor ?? -1;
    const teleFlag = (TelePointConstants.TELESTRATION_TYPE_TELESTRATION |
      TelePointConstants.TELESTRATION_TYPE_EXTENDED |
      (this.isImageShared ? TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_STILL_IMAGE_KIND : 0));
    const isTextTelestration = telestrationType === TELESTRATION_ITEM_TYPE.TEXT.type;
    const scaledData = this.cm?.reverseScale(isSelfVideo, telestrationCoords,
      config, isTextTelestration);
    let scaledCoords;
    if (telestrationType !== TELESTRATION_ITEM_TYPE.FREE_HAND.type) {
      scaledCoords = [
        { x: Math.floor(scaledData.startPointX), y: Math.floor(scaledData.startPointY) },
        { x: Math.floor(scaledData.endPointX), y: Math.floor(scaledData.endPointY) }];
    } else {
      scaledCoords = [{
        x: Math.floor(scaledData.startPointX),
        y: Math.floor(scaledData.startPointY),
      }];
    }
    const teleDetails = {
      clearPids: [],
      colorArgb: hexToArgb(this.selectedColor) ?? -1,
      coords: _.cloneDeep(scaledCoords),
      fontid: DEFAULT_FONT_ID,
      fontsize: textData?.fontSize || DEFAULT_FONT_SIZE,
      isColorSet: true,
      num: UNDO_TELE_COORDS_LEN,
      pid: localPid,
      telestrationExtendedType: telestrationType,
      text: textData?.text ?? '',
      thickness: this.selectedStrokeWidth ?? 0, // get thickness value from store
      type: teleFlag,
      config: this.selectedVideoConfig,
    };
    this.setLocalTelestrationsData(
      telestrationType,
      localPid,
      teleDetails,
      localColorArgb,
      telestrationCoords,
    );
    logger.info(`TelestrationHelper::Telestrations receieved:: ${JSON.stringify(this.videoTelestrations)}`);
    if (teleDetails.telestrationExtendedType !== TELESTRATION_ITEM_TYPE.FREE_HAND.type) {
      this.dsAPI.sendTelepoint(teleDetails);
    } else {
      this.sendFreeHandTelestrationData(teleDetails, config, isSelfVideo);
    }
  }

  toggleInputElement = () => {
    if (!this.hasTextInput) {
      const isSelfVideo = isLoggedInUserActiveVideoSharer();
      const scaleAndOffset = this.cm.getScaleAndOffset(isSelfVideo, this.selectedVideoConfig);
      const fontSize = this.cm.calculateFontSize(this.cm.canvas);
      const calculatedWidth = Math.floor(this.cm.canvas.width -
        (this.startX - scaleAndOffset.offSetX));
      const widthToApply = DEFAULT_TEXT_INPUT_SIZE > calculatedWidth ?
        calculatedWidth : DEFAULT_TEXT_INPUT_SIZE;
      let inputEleTop = this.startY;
      logger.debug(`TelestrationHelper::Text input element width : ${DEFAULT_TEXT_INPUT_SIZE} and diff : ${calculatedWidth} apply : ${widthToApply}`);
      const input = document.createElement('textarea');
      input.id = CALL_DASHBOARD.TELE_TEXT_INPUT;
      input.style.background = 'transparent';
      input.style.border = '2px solid blue';
      input.style.position = 'fixed';
      input.style.overflow = 'hidden';
      input.style.resize = 'none';
      input.style.fontSize = fontSize + 'px';
      input.style.color = this.selectedColor;
      input.style.left = this.startX + 'px';
      if (this.cm.canvas.height < this.startY) {
        inputEleTop = this.startY - (this.startY - this.cm.canvas.height);
        logger.debug('Canvas height is less than startY, need to modify the startY by ', inputEleTop, this.cm.canvas.height);
      }
      input.style.top = inputEleTop + 'px';
      input.style.width = widthToApply + 'px';
      document.body.appendChild(input);
      input.focus();
      this.hasTextInput = true;
    } else {
      this.getLocalText();
    }
  }

  getLocalText = () => {
    const textInput = document.getElementById(CALL_DASHBOARD.TELE_TEXT_INPUT);
    if (textInput?.value !== '') {
      this.drawLocalTelestration(textInput.value);
    }
    this.removeTextInput();
  }

  doStartAndEndCoordsDiffer = () => {
    if (this.selectedTelestration !== TELESTRATION_ITEM_TYPE.FREE_HAND?.name) {
      return (this.startX !== this.endX && this.startY !== this.endY);
    }
    return true;
  }

  drawLocalTelestration = (localText = '', sendDSMsg = true) => {
    let telestrationCoords = [{ x: this.startX, y: this.startY },
      { x: this.endX ?? 0, y: this.endY ?? 0 }];
    const isLocalTelestration = true;
    const config = getCurrentVideoConfig();
    const isSelfVideo = isLoggedInUserActiveVideoSharer();
    let telestrationType;
    logger.info(`TelestrationHelper::Selected telestration type for webapp : ${this.selectedTelestration} coordinates : ${JSON.stringify(telestrationCoords)} diff coords? ${this.doStartAndEndCoordsDiffer()}`);
    if (this.cm && this.doStartAndEndCoordsDiffer()) {
      switch (this.selectedTelestration) {
        case TELESTRATION_ITEM_TYPE.ARROW_LINE.name:
          telestrationType = TELESTRATION_ITEM_TYPE.ARROW_LINE?.type;
          this.cm.drawLines(telestrationCoords, 0, 1, true, this.selectedColor,
            isSelfVideo, config,
            this.selectedStrokeWidth, isLocalTelestration);
          break;
        case TELESTRATION_ITEM_TYPE.LINE.name:
          telestrationType = TELESTRATION_ITEM_TYPE.LINE?.type;
          this.cm.drawLines(telestrationCoords, 0, 1, false, this.selectedColor,
            isSelfVideo, config,
            this.selectedStrokeWidth,
            isLocalTelestration);
          break;
        case TELESTRATION_ITEM_TYPE.ELLIPSE.name:
          telestrationType = TELESTRATION_ITEM_TYPE.ELLIPSE?.type;
          this.cm.drawEllipse(telestrationCoords, 0, 1, this.selectedColor,
            isSelfVideo, config,
            this.selectedStrokeWidth,
            isLocalTelestration);
          break;
        case TELESTRATION_ITEM_TYPE.RECTANGLE.name:
          telestrationType = TELESTRATION_ITEM_TYPE.RECTANGLE?.type;
          this.cm.drawRectangle(telestrationCoords, 0, 1, this.selectedColor,
            isSelfVideo, config,
            this.selectedStrokeWidth,
            isLocalTelestration);
          break;
        case TELESTRATION_ITEM_TYPE.TEXT.name:
          telestrationType = TELESTRATION_ITEM_TYPE.TEXT?.type;
          this.cm.drawText(
            { text: localText, fontId: DEFAULT_FONT_ID, fontSize: DEFAULT_FONT_SIZE },
            telestrationCoords, this.selectedColor,
            isSelfVideo, config,
            this.selectedStrokeWidth,
            isLocalTelestration,
          );
          break;
        case TELESTRATION_ITEM_TYPE.FREE_HAND.name: {
          telestrationType = TELESTRATION_ITEM_TYPE.FREE_HAND?.type;
          // eslint-disable-next-line no-nested-ternary
          const telestrationCoordsFh = [this.getTeleDetailsCoordForfh()];
          telestrationCoords = telestrationCoordsFh;
          break;
        }
        default: break;
      }
    }
    if (sendDSMsg && this.doStartAndEndCoordsDiffer()) {
      const fontSize = this.getFontSizeForImageTele(config);
      this.setAndShareTelestrationData(
        telestrationCoords,
        telestrationType,
        isSelfVideo,
        config,
        { text: localText, fontSize },
      );
    }
  }

  getTeleDetailsCoordForfh = () => {
    if (this.fhData.isMouseDown) {
      return this.fhData.mousedownData.eventData;
    } if (this.fhData.isMouseMove) {
      return this.fhData.mousemoveData.eventData;
    }
    return this.fhData.mouseupData.eventData;
  }

  /**
   * @param  {} config The canvas config to scale text
   * Will return the font size based on the consfig and media type
   */

  getFontSizeForImageTele = (config) => {
    let fontSize;
    if (this.isImageShared) {
      const imageElement = document.getElementById(CALL_DASHBOARD.PHOTO_ID);
      const imageConfig = {
        height: imageElement.naturalHeight,
        width: imageElement.naturalWidth,
      };
      fontSize = this.cm.calculateFontSize(imageConfig);
    } else {
      fontSize = this.cm.calculateFontSize(config);
    }
    return fontSize;
  }

  // Telestration webapp functions
  /**
 * Color change from webapp
 * @param  {string} colorCode
 *
 */
  changeUserTeleColor = (colorCode, isForImage = false) => {
    if (this.selectedColor !== colorCode) {
      this.selectedColor = colorCode;
      const localPid = getLocalPId();
      const localVideoTelestrations =
        this.videoTelestrations?.find((user) => user?.pid === localPid);
      const localImageTelestrations =
        this.imageTelestrations?.find((user) => user?.pid === localPid);
      const colorArrayOfImage =
        this.getColorArray(localPid, colorCode, this.imageTelestrations);
      const colorArrayOfVideo =
        this.getColorArray(localPid, colorCode, this.videoTelestrations);
      this.updateTelestrations(localImageTelestrations, colorCode);
      this.updateTelestrations(localVideoTelestrations, colorCode);
      if ((colorArrayOfImage.length > 0) || (colorArrayOfVideo.length > 0)) {
        // When image is captured , send color change for both.
        // As video telestrations on image are from image data ,
        // only video telestrations's color should change at background
        if (colorArrayOfImage && isForImage) {
          this.sendLocalColorChange(colorArrayOfImage);
          this.sendLocalColorChange(colorArrayOfVideo);
        } else if (colorArrayOfVideo && !isForImage) {
          this.sendLocalColorChange(colorArrayOfVideo);
        }
        this.redraw(this.doImageTelestrationsExist() || isForImage);
      } else {
        this.sendLocalColorChange([{
          participantId: localPid,
          colourCode: hexToArgb(colorCode),
        }]);
      }
    }
    // Change color of viewfinder and save it to local storage
    this.saveTSColor(colorCode);
  };

  updateTelestrations = (ts, colorCode) => {
    if (ts) {
      this.resetCanvas();
      ts.colourCode = hexToArgb(colorCode);
      ts.telestrations.map((shape) => {
        shape.colorArgb = hexToArgb(colorCode);
      });
    }
    return ts;
  }

  sendLocalColorChange = (colorArray) => {
    this.dsAPI.sendColorChange(
      colorArray,
    );
  }

  /**
 * Thickness change from webapp
 * @param  {integer} thickness thickness value in digit
 *
 */
  changeThickness = (thickness) => {
    logger.info(`TelestrationHelper::Thickness value received ${this.selectedStrokeWidth}, ${thickness}`);
    if (this.selectedStrokeWidth !== thickness) {
      this.selectedStrokeWidth = thickness;
      this.resetCanvas();
      this.redraw(this.doImageTelestrationsExist());
    }
  };

  clearTSArray = (isClearForVideoOnly = false) => {
    logger.info(`TelestrationHelper::Clearing TS Array  - ${isClearForVideoOnly}`);
    if (isClearForVideoOnly) {
      this.videoTelestrations = [];
      return;
    }
    this.videoTelestrations = [];
    this.imageTelestrations = [];
  };

  clearImageTelestrationsOnly = () => {
    this.imageTelestrations = [];
    this.redraw(this.doImageTelestrationsExist());
  }

  // Send this array to the color change event
  getColorArray = (pid, code, telestrationToModify) => {
    const colorArray = [];
    // For the existing telestrations
    telestrationToModify?.forEach((telestration) => {
      if (telestration.pid === pid) {
        // local user changes color and local TS are already present
        colorArray.push({ participantId: pid, colourCode: hexToArgb(code) });
      } else if (telestration.pid !== pid) {
        // local user changes color and remote TS are present/local ts are absent.
        colorArray.push({ participantId: pid, colourCode: hexToArgb(code) });
      } else {
        if (telestration.pid) {
          colorArray.push({
            participantId: telestration.pid,
            colourCode: (telestration.colourCode),
          });
        }
      }
    });
    return colorArray;
  }
  /**
   * @param  {The type of mouse event} eventType
   * @param  {The data of mouse event} eventData
   */

  setMouseEventDataForFreeHand = (eventType, eventData) => ({ type: eventType, eventData })

  /**
   * This will return the flag of free hand telestration
   */

  getTelestrationFlagForFreeHand = () => {
    let teleDetailType = '';
    if (this.fhData.isMouseDown) {
      teleDetailType = (TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_TELESTRATION
        | TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_PENDOWN
        | TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_REMOTE);
    } else if (this.fhData.isMouseMove) {
      teleDetailType = (TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_TELESTRATION
        | TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_REMOTE);
    } else if (this.fhData.isMouseUp) {
      teleDetailType = (TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_TELESTRATION
        | TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_PENUP
        | TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_REMOTE);
    }
    return teleDetailType;
  }

  /**
   * @param  {The type of telestration} telestrationType
   * @param  {pid of a user who is drawing} pid
   * @param  {The details of telestration} telestrationDetails
   * @param  {The color of telestration} localColorArgb
   * @param  {The coordinates of telestration} telestrationCoords
   */

  setLocalTelestrationsData = (
    telestrationType,
    pid,
    telestrationData,
  ) => {
    let telestrationsToModify;
    if (this.isImageShared) {
      telestrationsToModify = this.imageTelestrations;
    } else {
      telestrationsToModify = this.videoTelestrations;
    }
    if (telestrationType === TELESTRATION_ITEM_TYPE.FREE_HAND.type) {
      telestrationData.type = this.getTelestrationFlagForFreeHand();
    }
    const userAt = telestrationsToModify?.findIndex(
      (user) => user?.pid === pid,
    );
    const IS_LOCAL_TELESTRATION = true;
    this.addTelestrationShapeData(
      telestrationData,
      telestrationsToModify,
      userAt,
      this.isImageShared,
      IS_LOCAL_TELESTRATION,
    );
    logger.info(`TelestrationHelper::Local telestration data:: ${this.videoTelestrations} ${this.imageTelestrations}`);
    logger.info(`Set local telestration data complete for type:${telestrationType} and for pid:${pid} with data:${JSON.stringify(telestrationData)}`);
  }

  /**
   * @param  {The telestration data that need to be send to remote user} dataToSend
   * @param  {The media config dimension for canvas dimension} config
   * @param  {To check if local user is active video sharer} isSelfVideo
   */

  sendFreeHandTelestrationData = (dataToSend, config, isSelfVideo, sendDSMsg = true) => {
    // Get last object from freehand data
    const freeHandData = dataToSend;
    let lastFreeHandData;
    let telestrationsToModify;
    const IS_FH_REDRAW = false;

    if (this.isImageShared) {
      if (this.doImageTelestrationsExist()) {
        telestrationsToModify = this.imageTelestrations;
      }
    } else {
      telestrationsToModify = this.videoTelestrations;
    }
    // Used sendDSMsg to avoid extra datastream events
    // Should always be false in case of old image telestrations
    const localUserTelestrations = telestrationsToModify.find((user) =>
      (user.pid === getLocalPId() || !sendDSMsg));
    if (localUserTelestrations) {
      const freeHandDataAt = localUserTelestrations.telestrations.length - 1;
      if (freeHandDataAt !== -1) {
        lastFreeHandData = localUserTelestrations.telestrations[freeHandDataAt].coords;
      }
    }

    if (lastFreeHandData) {
      const teleFlag = (TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_TELESTRATION |
        (this.isImageShared ? TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_STILL_IMAGE_KIND : 0));

      if (lastFreeHandData.length === FREE_HAND_COORDS_BATCH.INIT) {
        freeHandData.type = (teleFlag | TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_PENDOWN);
        freeHandData.num = FREE_HAND_COORDS_BATCH.INIT;
        freeHandData.coords = _.cloneDeep(lastFreeHandData);
        if (this.cm) {
          this.cm.drawFreeHandTelestration(
            freeHandData.coords,
            0,
            FREE_HAND_COORDS_BATCH.INIT - 1,
            false,
            freeHandData.colorArgb,
            isSelfVideo,
            MOUSE_DOWN,
            false,
            false,
            config,
            this.selectedStrokeWidth,
            true,
            IS_FH_REDRAW,
          );
        }
        if (sendDSMsg) {
          this.dsAPI.sendTelepoint(freeHandData);
        }
      } else if (lastFreeHandData.length > FREE_HAND_COORDS_BATCH.INIT) {
        const freeHandDataForMove = lastFreeHandData.slice(FREE_HAND_COORDS_BATCH.INIT);
        if (freeHandDataForMove
          && (freeHandDataForMove.length % FREE_HAND_COORDS_BATCH.SUBSEQUENT) === 0) {
          freeHandData.type = teleFlag;
          freeHandData.num = FREE_HAND_COORDS_BATCH.SUBSEQUENT;
          const freeHandDataToSend = _.cloneDeep(
            lastFreeHandData.slice(-FREE_HAND_COORDS_BATCH.SUBSEQUENT),
          );
          freeHandData.coords = freeHandDataToSend;
          if (this.cm) {
            this.cm.drawFreeHandTelestration(
              freeHandData.coords,
              0,
              FREE_HAND_COORDS_BATCH.SUBSEQUENT - 1,
              false,
              freeHandData.colorArgb,
              isSelfVideo,
              false,
              MOUSE_MOVE,
              false,
              config,
              this.selectedStrokeWidth,
              true,
              IS_FH_REDRAW,
            );
          }
          if (sendDSMsg) {
            this.dsAPI.sendTelepoint(freeHandData);
          }
        } else {
          if (this.fhData.isMouseUp) {
            const freeHandDataForPenUpLen = freeHandDataForMove.length
              % FREE_HAND_COORDS_BATCH.SUBSEQUENT;
            freeHandData.type = (teleFlag | TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_PENUP);
            freeHandData.num = freeHandDataForPenUpLen;
            if (freeHandDataForPenUpLen !== 0) {
              const freeHandDataToSend = _.cloneDeep(lastFreeHandData
                .slice(-freeHandDataForPenUpLen));
              freeHandData.coords = freeHandDataToSend;
            } else {
              freeHandData.coords = [];
            }
            if (this.cm) {
              this.cm.drawFreeHandTelestration(
                freeHandData.coords,
                0,
                freeHandDataForPenUpLen,
                false,
                freeHandData.colorArgb,
                isSelfVideo,
                false,
                false,
                MOUSE_UP,
                config,
                this.selectedStrokeWidth,
                true,
                IS_FH_REDRAW,
              );
            }
            if (sendDSMsg) {
              this.dsAPI.sendTelepoint(freeHandData);
            }
          }
        }
      }
    }
  }

  /**
   * @param  {} data : The telestration data
   * @param  {} telestrationsToModify : Video / Image telestrations array.
   * @param  {} indexOfPid : Index of array of for a user where user's telestrations ara stored.
   * @param  {} isForImage : Is the telestration is for image.
   * This function will add shapes's / telestration's data for a user if already present or
   * will create a telestrations array for a user if already not prsent.
   */
  addTelestrationShapeData = (
    data,
    telestrationsToModify,
    indexOfPid,
    isForImage,
    isLocalTelestration = false,
  ) => {
    if ((indexOfPid !== -1)) {
      this.addTelestrationDataInUserArray(data,
        telestrationsToModify,
        indexOfPid,
        isForImage,
        isLocalTelestration);
    } else {
      this.intializeTelestrationArrayForUser(data,
        telestrationsToModify,
        isForImage,
        isLocalTelestration);
    }
  }

  /**
   * @param  {} data : The telestration data
   * @param  {} telestrationsToModify Video / Image telestrations array.
   * Will initilize telestrations array for a user if already not prsent.
   */

  intializeTelestrationArrayForUser = (
    data,
    telestrationsToModify,
    isForImage,
    isLocalTelestration,
  ) => {
    if (data?.telestrationExtendedType !== TELESTRATION_ITEM_TYPE.FREE_HAND.type) {
      telestrationsToModify.push(
        {
          pid: data?.pid,
          colourCode: data?.colorArgb,
          telestrations: [data],
          config: data?.config,
        },
      );
    } else {
      if ((data.type & TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_PENDOWN) !== 0) {
        telestrationsToModify.push({
          pid: data?.pid,
          colourCode: data?.colorArgb,
          telestrations: [data],
          config: data?.config,
        });
      }
    }
    if (!isLocalTelestration) {
      this.addShapeToCanvas(data, isForImage);
    }
  }

  /**
   * @param  {} data: Telestrations data.
   * @param  {} telestrationsToModify: Video / Image telestrations array.
   * @param  {} indexOfPid
   * This function will add shapes's / telestration's data for a user if already present.
   * Index of array of for a user where user's telestrations ara stored.
   */

  addTelestrationDataInUserArray = (
    data,
    telestrationsToModify,
    indexOfPid,
    isForImage,
    isLocalTelestration,
  ) => {
    if (data?.telestrationExtendedType !== TELESTRATION_ITEM_TYPE.FREE_HAND.type) {
      telestrationsToModify[indexOfPid].telestrations?.push(data);
    } else {
      this.addFHData(data.type, data, indexOfPid, telestrationsToModify);
    }
    if (!isLocalTelestration) {
      this.addShapeToCanvas(data, isForImage);
    }
  }

  /**
   * @param  {} teleStrationType : TYpe of telestration to check the mouse events.
   * @param  {} fhData: The free hand telestration details.
   * @param  {} isPresentAt: Telestration present at this index.
   * @param  {} telestrationsToModify : The array of telestrations image/video
   * This function will add the freehand telestration data for a user.
   */
  addFHData = (teleStrationType, fhData, isPresentAt, telestrationsToModify) => {
    if (isPresentAt !== -1) {
      // Free hand Telestrations for user is already exist.
      if ((teleStrationType & TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_PENDOWN) !== 0) {
        telestrationsToModify[isPresentAt].telestrations?.push(fhData);
      } else {
        // If freehand telestration events are still coming than mouse down event,
        // update the in progress freehand telestratin which will be at last of array.
        const fhInProgressAt = telestrationsToModify[isPresentAt].telestrations.length - 1;
        fhData?.coords.forEach((coord) => {
          telestrationsToModify[isPresentAt].telestrations[fhInProgressAt]
            .coords.push(coord);
        });
      }
    } else {
      // Telestrations are not present for user and
      // free hand is the first telestration for the user.
      if ((teleStrationType & TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_PENDOWN) !== 0) {
        telestrationsToModify.push({
          pid: fhData?.pid,
          colourCode: fhData?.colorArgb,
          telestrations: [fhData],
          config: fhData?.config,
        });
      }
    }
  }

  /**
   * @param  {} eventData: Telestration event's data
   */

  handleEraseTelestrationsEvents(eventData, isImageKind) {
    const retainExifTS = this.doImageTelestrationsExist() ? !(eventData.clearPids[0]
      === LS_DATASTREAM_PARTICIPANTID.ALL_PARTICIPANTID) : false;
    this.eraseTelestrations(
      isImageKind,
      false,
      retainExifTS,
      eventData.clearPids,
    );
  }

  clearTelestrationsArray(isImageTeleDetails = false) {
    if (isImageTeleDetails) {
      if ((this.doImageTelestrationsExist())
        && (this.imageTelestrations.telestrations &&
          this.imageTelestrations?.telestrations?.length === 0)
      ) {
        this.imageTelestrations = [];
      }
    } else {
      if ((this.doVideoTelestrationsExist())
        && (this.videoTelestrations.telestrations &&
          this.videoTelestrations?.telestrations?.length === 0)
      ) {
        this.videoTelestrations = [];
      }
    }
  }
  /**
   * @param  {} imageData The image meta data
   * Will invoke teh functionlaity to store the old image telestrations
   * from TeleR3 of image meta data and draw the telestrations on image
   */

  handleImageData = (imageData) => {
    const imageMetaData = imageData;
    const imageTelestrationData = imageMetaData.TeleV3;
    if (imageTelestrationData
      && imageTelestrationData.sets
      && imageTelestrationData.sets.length > 0
    ) {
      this.setTeleDetailsFromImageData(imageTelestrationData);
      imageTelestrationData.sets.forEach((user) => {
        this.drawTelestrationsOnImageFromData(
          user.items,
          user.strokeStyle,
          imageData.mediaConfig,
        );
      });
    }
  }
  /**
   * @param  {} telestrationData the data of image meta data.
   * This function will store the telestrations from TeleR3 of
   * image data into imageTelestrations data
   */

  setTeleDetailsFromImageData = (telestrationData) => {
    logger.debug(`TelestrationHelper::Set old image telestrations from image meta data ${telestrationData}`);
    this.isImageShared = true;
    let pid = null;
    if (telestrationData) {
      telestrationData.sets?.forEach((telestration) => {
        // eslint-disable-next-line no-nested-ternary
        const colorArgb = telestration.strokeStyle
          ? telestration.strokeStyle.includes
            && telestration.strokeStyle.includes('rgba')
            ? hexToArgb(telestration.strokeStyle)
            : telestration.strokeStyle
          : telestration.colorArgb;
        if (pid === null || (pid && pid !== telestration.pid)) {
          pid = telestration.pid;
        }
        telestration.items?.forEach((item) => {
          if (pid === telestration.pid) {
            if (this.doImageTelestrationsExist()) {
              const indexOfPid = this.imageTelestrations.findIndex((user) => user.pid === pid);
              if (indexOfPid !== -1) {
                if (this.imageTelestrations[indexOfPid].telestrations
                  && this.imageTelestrations[indexOfPid].telestrations.length > 0) {
                  this.imageTelestrations[indexOfPid].telestrations.push(item);
                }
              } else {
                this.addImageTelestrationFromMetadata(pid, item, colorArgb);
              }
            } else {
              this.addImageTelestrationFromMetadata(pid, item, colorArgb);
            }
          } else {
            this.addImageTelestrationFromMetadata(pid, item, colorArgb);
          }
        });
      });
      logger.info('TelestrationHelper:: setTeleDetailsFromImageData:: success');
    } else {
      logger.warn('TelestrationHelper:: setTeleDetailsFromImageData : Not received image data');
    }
  }
  /**
   * @param  {} pid the pid of telestration, must be different than participants of call
   * @param  {} teleDetails the telestrations details containing type, coords etc
   * @param  {} colorArgb the color of telestration
   */

  addImageTelestrationFromMetadata = (pid, teleDetails, colorArgb) => {
    this.imageTelestrations.push({
      pid,
      colourCode: colorArgb,
      telestrations: [teleDetails],
    });
  }

  drawTelestrationsOnImageFromData = (
    telestrationData,
    strokeStyle,
    mediaConfig,
  ) => {
    logger.debug(`TelestrationHelper::Draw telestrations on old image: ${telestrationData} ${strokeStyle}`);
    const colorStyle = strokeStyle && strokeStyle.includes && strokeStyle.includes('rgba')
      ? hexToArgb(strokeStyle)
      : strokeStyle;
    if (mediaConfig) {
      this.selectedVideoConfig = mediaConfig;
    }
    const IS_IMG_TELE_ON_IMG = false;
    const IS_IMAGE = true;
    const IS_CLOSE_CONTEXT = true;
    const IS_FH_REDRAW = true;
    telestrationData?.forEach((telestration) => {
      const teleDetails = {
        coords: telestration.coords,
        telestrationExtendedType: telestration.type,
        config: this.selectedVideoConfig,
        colorArgb: colorStyle,
        fontid: telestration.fontId,
        fontsize: telestration.fontSize,
        text: telestration.text,
      };
      this.addShapeToCanvas(
        teleDetails,
        IS_IMAGE,
        IS_IMG_TELE_ON_IMG,
        IS_CLOSE_CONTEXT,
        IS_FH_REDRAW,
      );
    });
  }

  /** This function will return the telestration data to set lsExif TeleV3
   * with the help of video telestrations while capturing an image locally
   * to share with remote user.
   */

  setTeleV3DataForImage = (imageRes, isScreenShare = false) => {
    logger.info(`TelestrationHelper::setTeleV3DataForImage: ${this.videoTelestrations}`);
    const teleV3 = { sets: [], kind: 0 };
    let pid = null;
    if (this.doVideoTelestrationsExist()) {
      this.videoTelestrations.forEach((user) => {
        if (pid === null || (pid && pid !== user.pid)) {
          pid = user.pid;
        }
        const pidTostore = pid | TELEV3_CHAR_CODE_PID;
        if (user.telestrations && user.telestrations.length > 0) {
          user.telestrations.forEach((telestration) => {
            const coordsData = telestration.coords;
            const colorCode = telestration.colorArgb;
            const scaledCoords = this.cm?.getScaleDifference(
              isLoggedInUserActiveVideoSharer(),
              this.selectedVideoConfig,
              imageRes,
              coordsData,
              isScreenShare,
            );
            const telestrationType = telestration.telestrationExtendedType ?
              telestration.telestrationExtendedType :
              TELESTRATION_ITEM_TYPE.FREE_HAND.type;
            // OC does not understand the string integer value for color code.
            const teleDetails = {
              clr: colorCode | TELEV3_REMOTE_COLOR_BIN,
              currItem: {},
              items: [{
                c: null,
                coords: scaledCoords,
                tn: 0,
                type: telestrationType,
              }],
              pendown: false,
              pid: pidTostore,
              strokeStyle: colorCode,
              type: 0,
            };
            // Get the index of user if already performed telestrations are present for that user
            // There will be delay between actual html elt of image and setting image data
            // before getting it, hence 3 * DEFAULT_FONT_SIZE
            const userExistAt = teleV3.sets.findIndex((userSet) =>
              userSet.pid === pidTostore);
            teleDetails.items[0] = telestration.telestrationExtendedType
              === TELESTRATION_ITEM_TYPE.TEXT.type ?
              {
                ...teleDetails.items[0],
                fontId: telestration.fontid,
                fontSize: this.cm.calculateFontSize(imageRes),
                text: telestration.text,
              }
              : teleDetails.items[0];
            // Check if the items for user is present
            if (userExistAt !== -1) {
              // Update the existing user's items if present, if not push the details for new user
              if (teleV3.sets[userExistAt].items && teleV3.sets[userExistAt].items.length > 0) {
                teleV3.sets[userExistAt].items.push(teleDetails.items[0]);
              }
            } else {
              // If user's items are not present , add the details for the new user.
              teleV3.sets.push(teleDetails);
            }
          });
        }
      });
    }
    if (teleV3.sets && teleV3.sets.length > 0) {
      logger.info(`TelestrationHelper:: TeleV3 details are set: ${teleV3}`);
    } else {
      logger.warn(`TelestrationHelper::No telestrations are found for Telev3: ${teleV3}`);
    }
    return teleV3;
  }

  // Save the TS color of local user in localstorage
  saveTSColor = (col) => {
    const defaultTsIndex = Math.floor(Math.random() * TELESTRATION_COLORS.length);
    const defaultColor = TELESTRATION_COLORS[defaultTsIndex].colorCode;
    let tsColor;
    if (!col) {
      tsColor = JSON.parse(localStorage.getItem(
        `${this.loggedInUserName}${LOCAL_STORAGE_KEY.TS_COLOR}`,
      )) || defaultColor;
    } else {
      tsColor = col;
    }

    localStorage.setItem(
      `${this.loggedInUserName}${LOCAL_STORAGE_KEY.TS_COLOR}`,
      JSON.stringify(tsColor),
    );
    this.selectedColor = tsColor;
    logger.info(`TelestrationHelper::saved telestration's color for local user ${tsColor}`);
    return tsColor;
  }

  doImageTelestrationsExist = () => this.imageTelestrations && this.imageTelestrations.length > 0

  doVideoTelestrationsExist = () => this.videoTelestrations && this.videoTelestrations.length > 0

  handleTeleCanvasOnComponentToggle = (isImageCaptured, selectedVideoProfile) => {
    setTimeout(() => {
      if (isImageCaptured) {
        this.reInitializeCanvasManagerForImage(
          selectedVideoProfile,
        );
      } else {
        this.canvasResize(selectedVideoProfile);
      }
    }, 50);
  }
}
