/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

// Component
import LoginContainer from 'COMPONENTS/Login/LoginContainer';
import LandingPage from 'COMPONENTS/LandingPage/LandingPage';
import AppManager from 'COMPONENTS/AppManager/AppManager';
import ChangePassword from 'COMPONENTS/Login/ChangePassword';

// Action
import { setLaunchParametersAction } from 'STORE/Auth/AuthAction';

// Constants
import {
  ROUTES,
  LANDING_PAGE_ROUTE,
} from 'UTILS/constants/RoutingPathConstants';
import {
  LAUNCH_PARAMETER_ACTION,
  LAUNCH_PARAMETER_QUERY_STRING,
} from 'UTILS/constants/LaunchParameterConstants';

// eslint-disable-next-line react/prop-types
function PublicRoute({
  component: PublicComponent,
  restricted,
  ...restProps
}) {
  // restricted = false meaning public route
  // restricted = true meaning restricted route
  return (
    <Route
      {...restProps}
      render={(props) =>
        restProps.isAuthenticated() && restricted ? (
          <Redirect to={LANDING_PAGE_ROUTE} />
        ) : (
          <PublicComponent {...props} />
        )
      }
    />
  )
}

class Router extends Component {
  isAuthenticated = () => !!this.props.authToken;

  render() {
    return (
      <Switch>
        <Route
          path={[ROUTES.DEEPLINK.LAUNCH, ROUTES.DEEPLINK.MEETING, ROUTES.DEEPLINK.SSO]}
          render={(props) => {
            // handle deep link / launch parameters
            const query = new URLSearchParams(props?.location?.search);
            let launchParameters = null;
            switch (props?.location?.pathname) {
              case ROUTES.DEEPLINK.LAUNCH:
                launchParameters = {
                  action: LAUNCH_PARAMETER_ACTION.LAUNCH,
                  username: query?.get(LAUNCH_PARAMETER_QUERY_STRING.USERNAME),
                  password: query?.get(LAUNCH_PARAMETER_QUERY_STRING.PASSWORD),
                };
                break;
              case ROUTES.DEEPLINK.MEETING:
                launchParameters = {
                  action: LAUNCH_PARAMETER_ACTION.MEETING,
                  meetingId: query?.get(LAUNCH_PARAMETER_QUERY_STRING.MEETING_ID),
                };
                break;
              case ROUTES.DEEPLINK.SSO:
                launchParameters = {
                  action: LAUNCH_PARAMETER_ACTION.SSO,
                  username: query?.get(LAUNCH_PARAMETER_QUERY_STRING.SSO_USERNAME),
                  password: query?.get(LAUNCH_PARAMETER_QUERY_STRING.SSO_TOKEN),
                  ssoLoginResult: query?.get(LAUNCH_PARAMETER_QUERY_STRING.SSO_LOGIN_RESULT),
                  ssoRegisterResult: query?.get(
                    LAUNCH_PARAMETER_QUERY_STRING.SSO_REGISTER_RESULT,
                  ),
                };
                break;
              default:
                launchParameters = null;
                break;
            }
            this.props.setLaunchParametersAction(launchParameters);
            return (<Redirect to={`${ROUTES.LOGIN}`} />);
          }}
          exact
        />
        <PublicRoute
          restricted={false}
          component={LandingPage}
          isAuthenticated={this.isAuthenticated}
          path={ROUTES.LANDING_PAGE}
          exact
        />
        <PublicRoute
          restricted
          component={LoginContainer}
          path={ROUTES.LOGIN}
          isAuthenticated={this.isAuthenticated}
          exact
        />
        <PublicRoute
          restricted
          component={ChangePassword}
          path={ROUTES.CHANGE_PASSWORD}
          isAuthenticated={this.isAuthenticated}
          exact
        />

        <AppManager />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.AuthReducer.authToken,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setLaunchParametersAction,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Router);
