/* eslint-disable react/sort-comp */
/** @module Presentation */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/order */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { withOrientationChange, isMobileOnly } from 'react-device-detect';
// Component
import CallHistory from 'COMPONENTS/Dashboard/CallHistory/CallHistory';
import MeetingsPopover from 'COMPONENTS/Dashboard/MeetingsPopover/MeetingsPopover';
import Header from 'COMPONENTS/CustomComponents/Header/Header';
import Search from './Search/Search';
import Contacts from './Contacts/Contacts';
import AddContact from './Contacts/AddContact';
import MoreActions from 'COMPONENTS/CustomComponents/Header/MoreActions';
import Settings from 'COMPONENTS/Settings/Settings';
import MyProfile from 'COMPONENTS/Settings/MyProfile';
import General from 'COMPONENTS/Settings/General';
import Calls from 'COMPONENTS/Settings/Calls';
import BandwidthControl from 'COMPONENTS/Settings/BandwidthControl';
import VideoQuality from 'COMPONENTS/Settings/VideoQuality';
import GuestInvite from './Contacts/GuestInvite';

// Custom component
import SidebarSwitch from 'COMPONENTS/CustomComponents/SidebarSwitch/SidebarSwitch';
import ErrorModal from 'COMPONENTS/CustomComponents/ErrorDialogModal/ErrorModal';
import Popup from 'COMPONENTS/CustomComponents/CustomPopup/Popup';
import CustomButton from 'COMPONENTS/CustomComponents/CustomButton/CustomButton';
import CustomMessageHelper from './CustomMessageHelper';

// Utility
import CommonUtility from 'UTILS/CommonUtility';

// Constant
import { dashboardIconSet, ICON_NAMES } from 'UTILS/constants/HeaderConstants';
import { ROUTES } from 'UTILS/constants/RoutingPathConstants';
import { SETTINGS_MENU, SETTINGS, DASHBOARD_SIDEBAR_ROUTES, POLICY, X_PATHS } from 'UTILS/constants/UtilityConstants';
import { LANGUAGE } from 'UTILS/constants/LanguageConstants';
import { GUEST_INVITE_RESPONSES } from 'UTILS/constants/LoginConstants';
import { LOCAL_STORAGE_KEY, GUEST_INVITE } from 'UTILS/constants/DOMElementConstants';

// Action
import { saveAuthInfoAction, logoutAction } from 'STORE/Auth/AuthAction';
import { addContactAction } from 'STORE/User/UserAction';
import { setSelectedVideoProfileAction } from 'STORE/ClientSettings/ClientSettingsAction';
import setLanguageAction from 'STORE/Language/LanguageAction';

// Selector
import { getLanguage } from 'STORE/Language/LanguageSelector';
import { getSearchedContacts, getPersonalContacts, getInviterID } from 'STORE/Contacts/ContactSelector';
import { searchGlobalContacts, fetchPersonalContacts, addSearchedContact, addNewContact, deleteContact, clearGlobalContacts, inviteGuestAction } from 'STORE/Contacts/ContactAction';
import { getPolicyUISettings, getClientSettingsData, getUserData, getClientPermissions, getVideoMediaConfigs, getSelectedVideoProfile, getNetworkSettings, getSecuritySettings, getAskAndSearchParams, getClientSessionConfigInfo, getGuestInvitePolicy, getGuestCustomFields } from 'STORE/ClientSettings/ClientSettingsSelector';
import { getIsSessionExpiredFlag } from 'STORE/Auth/AuthSelector';

// I18n
import { translate } from 'SERVICES/i18n';

// eslint-disable-next-line react/prefer-stateless-function
/**
 * @component
 */
class DashboardContainer extends Component {
  callHistoryRecordsList = [];

  constructor(props) {
    super(props);
    this.state = {
      contactList: this.props?.personalContactList ? this.props.personalContactList : [],
      showSettingsPopup: false,
      showPopup: false,
      showGuestErrorPopup: false,
      guestErrorMessage: '',
      existingUsersArray: [],
    };
    this.popup = null;
  }

  componentDidMount() {
    CommonUtility.disableBrowserNavigation();
    this.fetchPersonalContacts();
    this.setState({ contactList: this.props?.personalContactList });
    this.callHistoryRecordsList = this.props.getCallHistoryRecords();
    // Set language
    const lang = navigator.language || navigator.userLanguage;
    if (this.props.language !== lang) {
      if (Object.values(LANGUAGE).includes(lang)) {
        this.props.setLanguageAction(lang);
      } else {
        this.props.setLanguageAction(LANGUAGE.ENGLISH);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.personalContactList !== this.props?.personalContactList) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ contactList: this.props.personalContactList });
    }
    if (this.props.isSessionExpired) {
      localStorage.removeItem(`${this.props.loggedInUserName}${LOCAL_STORAGE_KEY.IV}`);
      localStorage.removeItem(this.props.loggedInUserName);
    }
    this.getCallHistoryRecords();
    this.callHistoryRecordsList = this.props.getCallHistoryRecords();

    // Clear existing user's array if the route is changed from '/invite-guest' to any other
    if (this.state.existingUsersArray.length > 0 &&
      this.props?.location.pathname !== ROUTES.DASHBOARD + ROUTES.GUEST_INVITE) {
      this.setState({ existingUsersArray: [] });
    }
  }

  componentWillUnmount() {
    CommonUtility.enableBrowserNavigation();
  }

  handleSettingsMenu(item) {
    if (item) {
      switch (item) {
        case SETTINGS_MENU[0].id:
          if (
            this.props?.location.pathname !==
            ROUTES.DASHBOARD + ROUTES.MY_PROFILE
          ) {
            this.props.history.push(ROUTES.DASHBOARD + ROUTES.MY_PROFILE);
          }
          break;
        case SETTINGS_MENU[1].id:
          if (
            this.props?.location.pathname !==
            ROUTES.DASHBOARD + ROUTES.GENERAL
          ) {
            this.props.history.push(ROUTES.DASHBOARD + ROUTES.GENERAL);
          }
          break;
        case SETTINGS_MENU[2].id:
          if (
            this.props?.location.pathname !==
            ROUTES.DASHBOARD + ROUTES.CALLS
          ) {
            this.props.history.push(ROUTES.DASHBOARD + ROUTES.CALLS);
          }
          break;
        case SETTINGS_MENU[3].id:
          if (
            this.props?.location.pathname !==
            ROUTES.DASHBOARD + ROUTES.VIDEO_QUALITY
          ) {
            this.props.history.push(ROUTES.DASHBOARD + ROUTES.VIDEO_QUALITY);
          }
          break;
        case SETTINGS_MENU[4].id:
          if (
            this.props?.location.pathname !==
            ROUTES.DASHBOARD + ROUTES.BANDWIDTH_CONTROL
          ) {
            this.props.history.push(ROUTES.DASHBOARD + ROUTES.BANDWIDTH_CONTROL);
          }
          break;
        default:
          // Routing done only for mobile
          if (isMobileOnly) {
            if (
              this.props?.location.pathname !==
              ROUTES.DASHBOARD + ROUTES.SETTINGS
            ) {
              this.props.history.push(ROUTES.DASHBOARD + ROUTES.SETTINGS);
            }
          } else {
            this.setState({ showSettingsPopup: true });
          }
          break;
      }
    }
  }

  getDashboardIconSet() {
    if (this.props.askAndSearchParams?.azureServices?.length > 0) {
      return dashboardIconSet;
    }

    // only include the search icon if we have parameters for ask and search
    const newDashboardIconSet = [];
    dashboardIconSet.forEach((icon) => {
      if (icon.iconName !== ICON_NAMES.SEARCH) {
        newDashboardIconSet.push(icon);
      }
    });
    return newDashboardIconSet;
  }

  onCustomMessageCancel() {
    logoutAction();
  }

  checkForEnterKeyPress = (e, item) => {
    if (e.key === 'Enter' && e.target.value !== '') {
      this.headerHandler(item);
    }
  }

  headerHandler = (item) => {
    if (item.mainMenu === SETTINGS) {
      this.handleSettingsMenu(item.selectedMenu);
    } else if (item === ICON_NAMES.CONTACTS) {
      if (
        this.props?.location.pathname !==
          ROUTES.DASHBOARD + ROUTES.CONTACTS
      ) {
        this.props.history.push(ROUTES.DASHBOARD + ROUTES.CONTACTS);
      } else {
        this.props.history.push(ROUTES.DASHBOARD);
      }
    } else if (item === ICON_NAMES.SEARCH) {
      if (
        this.props?.location.pathname !==
        ROUTES.DASHBOARD + ROUTES.SEARCH
      ) {
        this.props.history.push(ROUTES.DASHBOARD + ROUTES.SEARCH);
      } else {
        this.props.history.push(ROUTES.DASHBOARD);
      }
    } else if (item === ICON_NAMES.CALL_HISTORY) {
      if (
        this.props?.location.pathname !==
          ROUTES.DASHBOARD + ROUTES.CALL_HISTORY
      ) {
        this.props.history.push(ROUTES.DASHBOARD + ROUTES.CALL_HISTORY);
      } else {
        this.props.history.push(ROUTES.DASHBOARD);
      }
    } else if (item === ICON_NAMES.ICON_MENU) {
      if (
        this.props?.location.pathname !==
          ROUTES.DASHBOARD + ROUTES.MORE_ACTIONS
      ) {
        this.props.history.push(ROUTES.DASHBOARD + ROUTES.MORE_ACTIONS);
      } else {
        this.props.history.push(ROUTES.DASHBOARD);
      }
    } else if (item === translate('headerMenus.settings')) {
      // Routing done only for mobile
      if (isMobileOnly) {
        if (
          this.props?.location.pathname !==
            ROUTES.DASHBOARD + ROUTES.SETTINGS
        ) {
          this.props.history.push(ROUTES.DASHBOARD + ROUTES.SETTINGS);
        } else {
          this.props.history.push(ROUTES.DASHBOARD);
        }
      } else {
        this.setState({ showSettingsPopup: true });
      }
    }
  }

  goBack = () => {
    if (this.state.showSettingsPopup) {
      this.setState({ showSettingsPopup: false });
    } else {
      this.props.history.push(ROUTES.DASHBOARD);
    }
  };

  goBackToSettings = () => {
    this.props.history.push(ROUTES.DASHBOARD + ROUTES.SETTINGS);
  };

  goBackToMoreActions = () => {
    this.props.history.push(ROUTES.DASHBOARD + ROUTES.MORE_ACTIONS);
  }

  getCallHistoryRecords = () => {
    this.callHistoryRecordsList = this.props.getCallHistoryRecords();
    return this.callHistoryRecordsList;
  }

  fetchContacts = (searchText) => {
    this.props.searchGlobalContacts(searchText);
  }

  fetchPersonalContacts = (searchText) => {
    this.props.fetchPersonalContacts(searchText);
  }

  addSearchedContact = (id, count = 0) => {
    if (count > 0) { // Show success popup
      this.props.addSearchedContact(id).then((res) => {
        if (res.data.resultNumber === GUEST_INVITE_RESPONSES.SUCCESS) {
          this.popup = {
            header: translate('guestInvite.added'),
            message: count > 1 ? translate('guestInvite.addMessageMultiple', { count }) : translate('guestInvite.addMessageSingle'),
            onClose: () => this.handlePopupClose(true),
            rightButton: [
              {
                text: translate('consentDialogue.ok'),
                onClick: () => this.handlePopupClose(true),
              },
            ],
          };
          this.setState({ showPopup: true });
        }
      });
    } else {
      this.props.addSearchedContact(id);
    }
  }

  deleteContact = (id) => {
    this.props.deleteContact(id);
  }

  addNewContact = (name, addr) => {
    this.props.addNewContact(name, addr);
  }

  handleSessionExpiration = () => {
    logoutAction();
  }

  updateVideoProfiles = (profileToSelect) => {
    // eslint-disable-next-line max-len
    const profileToSelectAt = this.props?.videoProfiles?.findIndex((videoProfile) => videoProfile?.name === profileToSelect);
    const selectedProfile = this.props.videoProfiles[profileToSelectAt];
    this.props.setSelectedVideoProfileAction(selectedProfile);
  }

  // Get the inviter details so that guest user can call him
  getInviter = () => {
    let guestInviter;
    // eslint-disable-next-line array-callback-return
    this.props?.personalContactList?.map((i) => {
      if (this.props?.inviterId === i.uniqueId) {
        guestInviter = i;
      } else {
        guestInviter = null;
      }
    });
    return guestInviter;
  }

  toggleMeetingPopover = () => {
    this.setState((prevState) =>
      ({
        joinMeetingModal: !prevState.joinMeetingModal,
      }));
  };

  // Guest invite success popup close
  handlePopupClose = () => {
    this.popup = {};
    this.setState({ showPopup: false, existingUsersArray: [] });
    this.props.history.push(ROUTES.DASHBOARD + ROUTES.CONTACTS);
  };

  // Guest invite error popup close
  handleGuestErrorClose = () => {
    this.setState({ showGuestErrorPopup: false, guestErrorMessage: '' });
  };

  // Need to update based on clientpolicy
  isGuestInviteAllowed = () => ((window.dynamicEnv.REACT_APP_ALLOW_GUEST_INVITE === 'true') && (this.props.guestInvitePolicy.allow_invites === POLICY.YES));

  // Send guest invite action
  sendGuestInvite = (data) => {
    let usersArray = [];
    let existingUsersXmlObj = {};
    const reqData = { ...data, fromLanguage: this.props.language };
    this.props.inviteGuestAction(reqData).then((res) => {
      switch (res.data.resultNumber) {
        case GUEST_INVITE_RESPONSES.SUCCESS:
          this.popup = {
            header: translate('guestInvite.successTitle'),
            message: translate('guestInvite.successMsg',
              { firstName: data.firstName,
                lastName: data.lastName,
                contact: data.emailAddress ? data.emailAddress : data.phoneNumber }),
            onClose: this.handlePopupClose,
            rightButton: [
              {
                text: translate('consentDialogue.ok'),
                onClick: this.handlePopupClose,
              },
            ],
            id: GUEST_INVITE.POPUP_ID,
          };
          this.setState({ showPopup: true });
          // Get the updated personal contact list
          this.fetchPersonalContacts();
          break;
        case GUEST_INVITE_RESPONSES.EMAIL_EXISTS:
        // Existing users UI will be displayed for this case
          existingUsersXmlObj = CommonUtility.convertXmlToJSONGeneric(
            res.data.existingUsersXml,
            X_PATHS.EXISTING_USERS_XML,
          );

          if (existingUsersXmlObj) {
            usersArray = Object.values(existingUsersXmlObj).map((user) => user);
          }
          this.setState({
            existingUsersArray: usersArray,
          });
          break;
        case GUEST_INVITE_RESPONSES.NO_USER_LICENSE:
        case GUEST_INVITE_RESPONSES.SIP_ACCOUNT_POOL_EMPTY:
        case GUEST_INVITE_RESPONSES.SIP_SERVER_TEMP_UNAVAILABLE:
        case GUEST_INVITE_RESPONSES.INVITES_NOT_ALLOWED:
          this.setState({
            showGuestErrorPopup: true,
            guestErrorMessage: translate('errors.noLicensesAvailable'),
          });
          break;
        case GUEST_INVITE_RESPONSES.INVALID_EXPIRY:
          this.setState({
            showGuestErrorPopup: true,
            guestErrorMessage: translate('guestInvite.invalidExpiryHours',
              { min: this.props.guestSettings?.min_expiry_hours, max: 23 }),
          });
          break;
        case GUEST_INVITE_RESPONSES.SMS_INVITES_NOT_ALLOWED:
          this.setState({
            showGuestErrorPopup: true,
            guestErrorMessage: translate('errors.smsInvitesNotAllowed'),
          });
          break;
        case GUEST_INVITE_RESPONSES.INVALID_PHONE:
          this.setState({
            showGuestErrorPopup: true,
            guestErrorMessage: translate('guestInvite.invalidPhone'),
          });
          break;
        case GUEST_INVITE_RESPONSES.MAX_MESSAGE_LENGTH_EXCEEDED:
          this.setState({
            showGuestErrorPopup: true,
            guestErrorMessage: translate('guestInvite.invalidMessage'),
          });
          break;
        case GUEST_INVITE_RESPONSES.INVITER_EMAIL_UNAVAILABLE:
          this.setState({
            showGuestErrorPopup: true,
            guestErrorMessage: translate('guestInvite.noInviter'),
          });
          break;
        case GUEST_INVITE_RESPONSES.INVALID_EMAIL:
          this.setState({
            showGuestErrorPopup: true,
            guestErrorMessage: translate('guestInvite.invalidEmail'),
          });
          break;
        case GUEST_INVITE_RESPONSES.UNKNOWN: default:
          this.setState({
            showGuestErrorPopup: true,
            guestErrorMessage: translate('errors.guestUnknown'),
          });
          break;
      }
    });
  }

  // On cancel button click from the existing users UI
  onCancelExistingUsers = () => {
    this.setState({ existingUsersArray: [] });
  };

  isSidebarPanelOpen() {
    return (DASHBOARD_SIDEBAR_ROUTES.includes(
      this.props?.location.pathname,
    ));
  }

  getLoginMessages = (guestUser, guestDetails) => {
    if (!guestUser) return translate('dashboard.welcomeUser');
    if (!guestDetails) return translate('dashboard.welcomeGuestNoContacts');
    return (
      <>
        {translate('dashboard.welcomeGuest')}
        <b className='px-1'>
          {guestDetails?.name}
        </b>
      </>
    );
  }

  renderWelcome = () => {
    let welcomeMsg = '';
    let guestDetails = null;
    const guestUser = !!this.props?.inviterId;
    if (guestUser) {
      guestDetails = this.getInviter();
    }
    if (this.props.location.pathname === ROUTES.DASHBOARD && !this.props.joinMeetingModal) {
      welcomeMsg = (
        <div className='welcome-container'>
          <Col className='d-flex align-center justify-content-center flex-column'>
            <Row className={`${isMobileOnly ? 'welcome-text1-mob' : 'welcome-text1'} align-center p-0`}>
              {translate('dashboard.welcome', { app: translate('APPNAME') })}
            </Row>
            <Row className={`${isMobileOnly ? 'welcome-text2-mob' : 'welcome-text2'} align-center pt-3 d-flex`}>
              {
                (this.getLoginMessages(guestUser, guestDetails))
              }
            </Row>
            <Row className='pt-4'>
              {(!guestUser || guestDetails) ?
                (
                  <Col className='align-center'>
                    <CustomButton
                      className={`d-flex align-center action-buttons btn welcome-buttons p-2
                        ${this.props.isWebappReady ? '' : ' disabled'}`}
                      onClick={
                        !guestDetails ?
                          () => this.props.history.push(ROUTES.DASHBOARD + ROUTES.CONTACTS)
                          : () =>
                            this.props.placeCall(guestDetails?.name, guestDetails?.address)
                      }
                      id='dashboard-contacts'
                      text={!guestDetails ? 'contacts.contactsPanelHeader' : 'dashboard.welcomeCallButton'}
                    />
                  </Col>
                ) : ''
              }
            </Row>
          </Col>
        </div>
      );
    }
    return welcomeMsg;
  }

  render() {
    const { isLandscape, isPortrait, guestInvitePolicy } = this.props;
    const guestSettings = {
      minExpiry: this.props.guestSettings?.min_expiry_hours,
      maxExpiry: this.props.guestSettings?.max_expiry_hours,
    };
    return (
      <div className='dashboard-layout'>
        <div
          className={
            this.props?.location.pathname !== ROUTES.DASHBOARD
              ? 'd-lg-block d-none'
              : 'd-block'
          }
        >
          <Header
            iconSet={this.getDashboardIconSet()}
            activePath={window.location.pathname}
            clickHandler={this.headerHandler}
            checkForEnterKeyPress={this.checkForEnterKeyPress}
            isRemoteUserVideoSharer={this.props.isRemoteUserVideoSharer}
            isSettingClosed={this.state.showSettingsPopup}
            isWebappReady={this.props.isWebappReady}
            captureImage={this.props.captureImage}
          />
        </div>
        <MeetingsPopover
          isPopoverOpen={this.props.joinMeetingModal}
          togglePopover={this.toggleMeetingPopover}
          cancelMeeting={this.props.cancelMeeting}
        />
        <ErrorModal
          open={this.props.isSessionExpired}
          header={translate('login.sessionExpiredHeader')}
          errorMessage={translate('errors.sessionExpiredError')}
          onClose={this.handleSessionExpiration}
          rightButton={[
            {
              text: translate('login.okButton'),
              onClick: this.handleSessionExpiration,
            },
          ]}
        />
        {/* Guest invite error modal */}
        {this.state.showGuestErrorPopup && (
        <ErrorModal
          open={this.state.showGuestErrorPopup}
          header={translate('errors.serverErrorHeading')}
          errorMessage={this.state.guestErrorMessage}
          onClose={this.handleGuestErrorClose}
          rightButton={[
            {
              text: translate('login.okButton'),
              onClick: this.handleGuestErrorClose,
            },
          ]}
        />
        )}
        <Popup
          open={this.state.showPopup}
          header={this.popup?.header}
          messageText={this.popup?.message}
          onClose={this.popup?.onClose}
          rightButton={this.popup?.rightButton}
          leftButton={this.popup?.leftButton}
          id={this.popup?.id}
        />
        <CustomMessageHelper
          onCustomMessageCancel={this.onCustomMessageCancel}
        />

        <div
          id='subcomponent'
          // eslint-disable-next-line no-nested-ternary
          className={`dashboard-sub-component ${this.isSidebarPanelOpen() ?
            isPortrait || (isLandscape && window.innerWidth < 1024)
              ? 'h-100 top-0'
              : ''
            : ''
          }`
          }
        >
          <Container fluid className='dashboard-container'>
            {this.renderWelcome()}
            <SidebarSwitch className='h-100'>
              <Route
                path={this.props.match.path + ROUTES.CONTACTS}
                render={(props) => (
                  <Contacts
                    className='h-100'
                    {...props}
                    goBack={this.goBack}
                    placeCall={this.props.placeCall}
                    fetchContacts={(searchText) => this.fetchContacts(searchText)}
                    searchList={this.props.searchList}
                    selectAndAdd={this.addContact}
                    fetchPersonalContacts={(searchText) => this.fetchPersonalContacts(searchText)}
                    addSearchedContact={(id) => this.addSearchedContact(id)}
                    personalList={this.state.contactList}
                    deleteContact={(id) => this.deleteContact(id)}
                    loggedInUserName={this.props.loggedInUserName}
                    clearList={this.props.clearGlobalContacts}
                    addContactPermission={this.props.policyUISettings &&
                      this.props.policyUISettings.allow_new_contacts_enabled}
                    allowGuestInvite={this.isGuestInviteAllowed()}
                    authToken={this.props.authToken}
                    guestInviteAvailable={guestInvitePolicy?.is_connect_license_available}
                  />
                )}
              />
              <Route
                path={this.props.match.path + ROUTES.ADD_CONTACTS}
                render={(props) => (
                  <AddContact
                    {...props}
                    onCancelClick={this.goBack}
                    onAddClick={(name, addr) => this.addNewContact(name, addr)}
                    registrar={this.props.sipRegistrar}
                  />
                )}
              />
              <Route
                path={this.props.match.path + ROUTES.SEARCH}
                render={(props) => (

                  <Search
                    {...props}
                    placeCall={this.props.placeCall}
                    goBack={this.goBack}
                    personalList={this.state.contactList}
                    addSearchedContact={(id) => this.addSearchedContact(id)}
                  />
                )}
              />
              <Route
                path={this.props.match.path + ROUTES.GUEST_INVITE}
                render={(props) => (
                  <GuestInvite
                    {...props}
                    onCancelClick={this.goBack}
                    sendGuestInvite={this.sendGuestInvite}
                    addToContacts={this.addSearchedContact}
                    onCancelExistingUsers={this.onCancelExistingUsers}
                    guestSettings={guestSettings}
                    policy={guestInvitePolicy}
                    personalContacts={this.props.personalContactList}
                    existingUsers={this.state.existingUsersArray}
                    customFields={this.props.guestCustomFields}
                  />
                )}
              />
              <Route
                path={this.props.match.path + ROUTES.CALL_HISTORY}
                render={(props) => (
                  <CallHistory
                    className='h-100 overflow-y'
                    {...props}
                    placeCall={this.props.placeCall}
                    callRecords={this.props.callRecords}
                    goBack={this.goBack}
                    getCallHistory={this.getCallHistoryRecords}
                  />
                )}
              />
              <Route
                path={this.props.match.path + ROUTES.MORE_ACTIONS}
                render={(props) => (
                  <MoreActions
                    {...props}
                    iconSet={dashboardIconSet[0]}
                    clickHandler={this.headerHandler}
                    goBack={this.goBack}
                  />
                )}
              />
              {isMobileOnly && ( // Settings side panel route for mobile devices
                <Route
                  path={this.props.match.path + ROUTES.SETTINGS}
                  render={(props) => (
                    <Settings
                      {...props}
                      header={translate('headerMenus.settings')}
                      iconSet={SETTINGS_MENU}
                      isOpen
                      clickHandler={this.headerHandler}
                      goBack={isMobileOnly ? this.goBackToMoreActions : this.goBack}
                      userData={this.props.userData}
                      clientSettingsData={this.props.clientSettingsData}
                      clientPolicyPermissionsXmlData={this.props.clientPolicyPermissionsXmlData}
                      selectedVideoProfile={this.props.selectedVideoProfile}
                      setSelectedProfile={(p) => this.updateVideoProfiles(p)}
                      networkSettings={this.props.networkSettings}
                      securitySettings={this.props.securitySettings}
                      policyUISettings={this.props.policyUISettings}
                      videoProfiles={this.props.videoProfiles}
                    />
                  )}
                />
              )}

              <Route
                path={this.props.match.path + ROUTES.MY_PROFILE}
                render={(props) => (
                  <MyProfile
                    {...props}
                    iconSet={SETTINGS_MENU}
                    isOpen
                    clickHandler={this.headerHandler}
                    goBack={this.goBackToSettings}
                    userData={this.props.userData}
                    clientSettingsData={this.props.clientSettingsData}
                  />
                )}
              />
              <Route
                path={this.props.match.path + ROUTES.GENERAL}
                render={(props) => (
                  <General
                    {...props}
                    isOpen
                    clickHandler={this.headerHandler}
                    goBack={this.goBackToSettings}
                    userData={this.props.userData}
                    clientSettingsData={this.props.clientSettingsData}
                    securitySettings={this.props.securitySettings}
                  />
                )}
              />
              <Route
                path={this.props.match.path + ROUTES.CALLS}
                render={(props) => (
                  <Calls
                    {...props}
                    isOpen
                    clickHandler={this.headerHandler}
                    goBack={this.goBackToSettings}
                    userData={this.props.userData}
                    clientSettingsData={this.props.clientSettingsData}
                    securitySettings={this.props.securitySettings}
                    policyUISettings={this.props.policyUISettings}
                  />
                )}
              />
              <Route
                path={this.props.match.path + ROUTES.BANDWIDTH_CONTROL}
                render={(props) => (
                  <BandwidthControl
                    {...props}
                    isOpen
                    clickHandler={this.headerHandler}
                    goBack={this.goBackToSettings}
                    userData={this.props.userData}
                    clientSettingsData={this.props.clientSettingsData}
                    networkSettings={this.props.networkSettings}
                  />
                )}
              />
              <Route
                path={this.props.match.path + ROUTES.VIDEO_QUALITY}
                render={(props) => (
                  <VideoQuality
                    {...props}
                    isOpen
                    clickHandler={this.headerHandler}
                    goBack={this.goBackToSettings}
                    selectedVideoProfile={this.props.selectedVideoProfile}
                    setSelectedProfile={(p) => this.updateVideoProfiles(p)}
                    videoProfiles={this.props.videoProfiles}
                    clientPolicyPermissionsXmlData={this.props.clientPolicyPermissionsXmlData}
                  />
                )}
              />
            </SidebarSwitch>

            { // Show settings popup in desktop mode(no sidebar routing here)
              this.state.showSettingsPopup && !isMobileOnly && (
                <Settings
                  header={translate('headerMenus.settings')}
                  iconSet={SETTINGS_MENU}
                  isOpen={this.state.showSettingsPopup}
                  clickHandler={this.headerHandler}
                  goBack={isMobileOnly ? this.goBackToMoreActions : this.goBack}
                  userData={this.props.userData}
                  clientSettingsData={this.props.clientSettingsData}
                  clientPolicyPermissionsXmlData={this.props.clientPolicyPermissionsXmlData}
                  selectedVideoProfile={this.props.selectedVideoProfile}
                  setSelectedProfile={(p) => this.updateVideoProfiles(p)}
                  networkSettings={this.props.networkSettings}
                  securitySettings={this.props.securitySettings}
                  policyUISettings={this.props.policyUISettings}
                  videoProfiles={this.props.videoProfiles}
                />
              )}
          </Container>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.AuthReducer.authToken,
  loggedInUserName: state.AuthReducer.loggedInUserName,
  searchList: getSearchedContacts(state),
  personalContactList: getPersonalContacts(state),
  // temporary code for sipregistrar
  // eslint-disable-next-line max-len
  sipRegistrar: state.ClientSettingsReducer.clientSettingsData &&
    state.ClientSettingsReducer.clientSettingsData.sip_registrar,
  language: getLanguage(state),
  policyUISettings: getPolicyUISettings(state),
  isSessionExpired: getIsSessionExpiredFlag(state),
  clientSettingsData: getClientSettingsData(state),
  userData: getUserData(state),
  clientPolicyPermissionsXmlData: getClientPermissions(state),
  videoProfiles: getVideoMediaConfigs(state),
  selectedVideoProfile: getSelectedVideoProfile(state),
  networkSettings: getNetworkSettings(state),
  securitySettings: getSecuritySettings(state),
  inviterId: getInviterID(state),
  askAndSearchParams: getAskAndSearchParams(state),
  guestSettings: getClientSessionConfigInfo(state)?.guest_users,
  guestInvitePolicy: getGuestInvitePolicy(state),
  guestCustomFields: getGuestCustomFields(state),
});

// eslint-disable-next-line no-unused-vars
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveAuthInfoAction,
      addContactAction,
      searchGlobalContacts,
      fetchPersonalContacts,
      addSearchedContact,
      addNewContact,
      deleteContact,
      clearGlobalContacts,
      setSelectedVideoProfileAction,
      inviteGuestAction,
      setLanguageAction,
    },
    dispatch,
  );
}

export default withOrientationChange(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DashboardContainer),
  ),
);
