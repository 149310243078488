import { ACTIONS } from 'UTILS/constants/ActionConstants';

const initialState = {
  authToken: null,
  loggedInUserName: null,
  clientSessionConfigInfoXml: null,
  isNetworkLost: false,
  isSessionExpired: false,
  loginRequestTimestamp: 0,
  customerUniqueId: null,
  customerName: null,
  webRtcToken: null,
  launchParameters: null,
  customMessageData: {},
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_AUTH_TOKEN:
      return {
        ...state,
        authToken: action.authToken,
      };
    case ACTIONS.SET_USER_NAME:
      return {
        ...state,
        loggedInUserName: action.loggedInUserName,
      };
    case ACTIONS.SET_CUSTOMER_INFO:
      return {
        ...state,
        customerUniqueId: action.customerUniqueId,
        customerName: action.customerName,
      };
    case ACTIONS.NETWORK_CONNECTION_LOST:
      return { ...state, isNetworkLost: action.isNetworkLost };
    case ACTIONS.SET_SESSION_EXPIRED_FLAG:
      return { ...state, isSessionExpired: action.isSessionExpired };
    case ACTIONS.SET_LOGIN_REQUEST_TIMESTAMP:
      return { ...state, loginRequestTimestamp: action.loginRequestTimestamp };
    case ACTIONS.SET_WEBRTC_TOKEN:
      return {
        ...state,
        webRtcToken: action.webRtcToken,
      };
    case ACTIONS.SET_LAUNCH_PARAMETERS:
      return { ...state, launchParameters: action.launchParameters };
    case ACTIONS.SET_CUSTOM_MESSAGE:
      return { ...state, customMessageData: action.cutsomMessageData };
    default:
      return state;
  }
};
