// Translation
import { translate } from 'SERVICES/i18n';

// Constants
import { ROUTES } from 'UTILS/constants/RoutingPathConstants';
// eslint-disable-next-line import/prefer-default-export
export const X_PATHS = {
  USER_XML: '/user/child::*',
  CLIENT_SETTING: '/client_settings/child::*',
  CONTACTS: '/contacts/child::*',
  CLIENT_POLICY_UI_SETTINGS: '/onsight_client_policy/configuration/ui/child::*',
  // client permissions(not used anywhere right now)
  CLIENT_POLICY_PERMISSIONS_XML: '/onsight_client_policy/configuration/ui/permissions', // we can use this path for other permissions
  CLIENT_PERMISSIONS: '/client_permissions/deny/permission/attribute::id',

  CLIENT_SESSION_CONFIG: '/session_config_info',
  CLIENT_POLICY: '/onsight_client_policy/child::*',
  // video profiles
  CLIENT_POLICY_VIDEO_PROFILES: '/onsight_client_policy/configuration/video/profiles',
  VIDEO_PROFILES: '/Profiles/child::Profile',

  CLIENT_POLICY_NETWORK: '/onsight_client_policy/configuration/network/child::*',
  CLIENT_POLICY_SECURITY: '/onsight_client_policy/configuration/security/child::*',

  // AI Settings
  CLIENT_POLICY_AI_SETTINGS: '/onsight_client_policy/configuration/AISettings',
  CLIENT_POLICY_NLP_URL: '/AISettings/NLPEndpoint',
  CLIENT_POLICY_ASK_AND_SEARCH_PARAMS: '/AISettings/AskAndSearchParams',

  // Workspace settings
  CLIENT_POLICY_WORKSPACE: '/onsight_client_policy/configuration/workspace/child::*',

  // Meetings
  CLIENT_POLICY_MEETINGS: '/onsight_client_policy/configuration/meetings/child::*',

  // Overlay Settings
  CLIENT_POLICY_OVERLAY_POSITION: '/onsight_client_policy/configuration/video/overlay_position',
  CLIENT_POLICY_OVERLAY_TEXT_SIZE: '/onsight_client_policy/configuration/video/overlay_text_size',
  CLIENT_POLICY_OVERLAY_SHOW_DATE_TIME: '/onsight_client_policy/configuration/video/overlay_show_datetime',
  CLIENT_POLICY_OVERLAY_SHOW_LOCATION: '/onsight_client_policy/configuration/video/overlay_show_location',

  // Guest invite policy
  CLIENT_POLICY_GUEST_INVITE: '/onsight_client_policy/configuration/guests/child::*',
  GUEST_CUSTOM_FIELDS: '/CustomFieldValueInfo',
  EXISTING_USERS_XML: '/users/child::user',
  // Custom message
  CUSTOM_MESSAGE: '/custom_forms/custom_form/json',
  // Client policy for image capture
  CLIENT_POLICY_IMAGES: '/onsight_client_policy/configuration/images/child::*',
};

export const SIDEBAR_ROUTES = [
  ROUTES.CALL_DASHBOARD + ROUTES.CALL_PARTICIPANTS,
  ROUTES.CALL_DASHBOARD + ROUTES.AUDIO_SOURCE,
  ROUTES.CALL_DASHBOARD + ROUTES.VIDEO_SOURCE,
  ROUTES.CALL_DASHBOARD + ROUTES.MORE_ACTIONS,
  ROUTES.CALL_DASHBOARD + ROUTES.SETTINGS,
  ROUTES.CALL_DASHBOARD + ROUTES.MY_PROFILE,
  ROUTES.CALL_DASHBOARD + ROUTES.GENERAL,
  ROUTES.CALL_DASHBOARD + ROUTES.CALLS,
  ROUTES.CALL_DASHBOARD + ROUTES.VIDEO_QUALITY,
  ROUTES.CALL_DASHBOARD + ROUTES.BANDWIDTH_CONTROL,
];

export const DASHBOARD_SIDEBAR_ROUTES = [
  ROUTES.DASHBOARD + ROUTES.CONTACTS,
  ROUTES.DASHBOARD + ROUTES.SEARCH,
  ROUTES.DASHBOARD + ROUTES.ADD_CONTACTS,
  ROUTES.DASHBOARD + ROUTES.CALL_HISTORY,
  ROUTES.DASHBOARD + ROUTES.MORE_ACTIONS,
  ROUTES.DASHBOARD + ROUTES.SETTINGS,
  ROUTES.DASHBOARD + ROUTES.MY_PROFILE,
  ROUTES.DASHBOARD + ROUTES.GENERAL,
  ROUTES.DASHBOARD + ROUTES.CALLS,
  ROUTES.DASHBOARD + ROUTES.VIDEO_QUALITY,
  ROUTES.DASHBOARD + ROUTES.BANDWIDTH_CONTROL,
  ROUTES.DASHBOARD + ROUTES.GUEST_INVITE,
];

export const POLICY = {
  YES: 'yes',
  NO: 'no',
};

export const AES = {
  GCM: 'AES-GCM',
};

export const REMOTE_VIDEO_CONSENT = { YES: 'yes' };

export const KEY_CONSTANT = {
  algorithm: { PBKDF2: 'PBKDF2' },
  type: { raw: 'raw' },
  purpose: { deriveBits: 'deriveBits', deriveKey: 'deriveKey', encrypt: 'encrypt', decrypt: 'decrypt' },
  hash: { sha_256: 'SHA-256' },
};

/**
 * To create new entry in SETTINGS_MENU:
 * id must be same as the file name and must start with Uppercase
 * name is the label to be displayed
 */
export const SETTINGS_MENU = [{
  id: 'MyProfile',
  name: translate('settings.myProfile'),
  disabled: false,
  path: ROUTES.MY_PROFILE,
}, {
  id: 'General',
  name: translate('settings.general'),
  disabled: false,
  path: ROUTES.GENERAL,
}, {
  id: 'Calls',
  name: translate('settings.calls'),
  disabled: false,
  path: ROUTES.CALLS,
}, {
  id: 'VideoQuality',
  name: translate('settings.videoQuality'),
  disabled: false,
  path: ROUTES.VIDEO_QUALITY,
}, {
  id: 'BandwidthControl',
  name: translate('settings.bandwidthControl'),
  disabled: false,
  path: ROUTES.BANDWIDTH_CONTROL,
},
];

export const VIDEO_PROFILES = [
  { label: 'Low', value: 'Low', streamQuality: 0, OPMvalue: 'Low Bandwidth' },
  { label: 'Medium', value: 'Medium', streamQuality: 1, OPMvalue: 'Medium Bandwidth' },
  { label: 'High', value: 'High', streamQuality: 2, OPMvalue: 'High Bandwidth' },
  { label: '720p', value: '720p', streamQuality: 4, OPMvalue: '720p' },
  { label: '1080p', value: '1080p', streamQuality: 5, OPMvalue: '1080p' },
];

export const UNITS = {
  FPS: 'FPS',
  KBPS: 'Kbps',
  MIN_BIT_RATE_KBPS: 8,
  MAX_BIT_RATE_KBPS: 6144, // 6 megabits * 1024
  CONVERSION_VALUE: 1000,
};

export const SETTINGS = 'SETTINGS_MENU';

export const RANGE_SLIDER_SIZES = {
  SMALL: 'sm',
  LARGE: 'lg',
};

export const RANGE_SLIDER_VALUES = {
  MIN: 0.25,
  MAX: 2,
  STEP: 0.25,
  HORIZONTAL: 'horizontal',
};

export const ZOOM_SLIDER_VALUES = {
  MIN: 1,
  MAX: 3,
  STEP: 0.1,
};

export const RANGE_SLIDER_TOOLTIP = {
  ON: 'on',
  OFF: 'off',
};

export const BROWSER_RESOURCE = {
  CAMERA: 'camera',
};

export const BROWSER_PERMISSIONS_STATUS = {
  DENIED: 'denied',
};

export const OTHER_PARTICIPANT = 'From Other Participant';

export const ERROR_TYPES = {
  userNotAvailable: 'userNotAvailable',
  userNotResponding: 'userNotResponding',
  lineBusy: 'lineBusy',
  connectErr: 'connectErr',
  inCallNetworkErr: 'inCallNetworkErr',
  inCallConnectFail: 'inCallConnectFail',
  connectFatal: 'connectFatal',
  callEnded: 'callEnded',
  sessionUp: 'sessionUp',
  callUp: 'callUp',
  registrationErr: 'registrationErr',
  callDeclined: 'callDeclined',
  callCancelled: 'callCancelled',
  errorInGettingStream: 'errorInGettingStream',
  permissionsError: 'permissionsError',
  missingSDP: 'missingSDP',
  invalidRequest: 'invalidRequest',
  unknownSIPError: 'unknownSIPError',
  selfCalling: 'selfCalling',
  noCameraDevice: 'noCameraDevice',
  noAudioDevice: 'noAudioDevice',
  imageCaptureFailed: 'imageCaptureFailed',
  screenShareDeclined: 'screenShareDeclined',
  callDuringMeeting: 'callDuringMeeting',
  meetingNotFound: 'meetingNotFound',
  meetingDeleted: 'meetingDeleted',
  meetingError: 'meetingError',
  meetingNotHost: 'meetingNotHost',
  meetingFull: 'meetingFull',
};

export const INCOMING_CALL_TAG = 'incoming_call_notification';

export const MUTE_ACTION = {
  NONE: 0,
  LOCAL: 1,
  LISTED: 2,
  ALL_EXCEPT_LOCAL: 3,
  ALL: 4,
};

export const STREAM_SHARE_STATE = {
  UNKNOWN: 0,
  READY: 1,
  TOKEN_REQUESTED: 2,
  PREPARE_TO_SHARE: 3,
  READY_TO_SHARE: 4,
  STREAMING_STARTED: 5,
  STOP_SHARE: 6,
  SHARER_CHANGED: 7,
  STREAMING_STOPPED: 8,
};

export const STREAM_SHARE_ACTION = {
  NOT_SET: 0,
  START_SHARE: 1,
  STOP_SHARE: 2,
  TOGGLE: 3,
};

export const TABLET_WIDTH = 1023;

export const POPOVER_PLACEMENT = {
  TOP_START: 'top-start',
  TOP_END: 'top-end',
  TOP: 'top',
  AUTO_END: 'auto-end',
  RIGHT_START: 'right-start',
  BOTTOM: 'bottom',
};

export const SWITCH_COMPONENT = {
  SELECT_COLOR: '#00A3E0',
  BACKGROUND_COLOR: '#ffffff',
  DIAMETER: 18,
  HEIGHT: 12,
  WIDTH: 25,
};

export const STREAMER = {
  NONE: 'none',
  LOCAL: 'local',
  REMOTE: 'remote',
};

export const CLIENT_PERMISSIONS = [
  { name: 'Illumination', id: 1010 },
  { name: 'Flash', id: 1014 },
  { name: 'SQLow', id: 15000 },
  { name: 'SQMed', id: 15001 },
  { name: 'SQHigh', id: 15002 },
  { name: 'SQHD', id: 15006 },
  { name: 'SQFULLHD', id: 15007 },
];

export const VID_IMG_OVERLAY_TEXT_SIZE = {
  SMALL: { fontSize: 20, lineWidth: 2.5 },
  MEDIUM: { fontSize: 25, lineWidth: 3 },
  LARGE: { fontSize: 30, lineWidth: 3.5 },
};

export const VID_IMG_OVERLAY_TEXT_LOC = {
  TOP_RIGHT: { X: 34, Y: 50 },
  TOP_LEFT: { X: 34, Y: 50 },
  BOTTOM_RIGHT: { X: 34, Y: 50 },
  BOTTOM_LEFT: { X: 34, Y: 50 },
};

export const GEO_LOCATION_POSITION_ERROR = {
  PERMISSION_DENIED: 1,
  POSITION_UNAVAILABLE: 2,
  TIMEOUT: 3,
};

export const VIDEO_EVENT = {
  RESIZE: 'resize',
  PLAYING: 'playing',
};

export const PERMISSION = {
  ACCELEROMETER: 'accelerometer',
  CAMERA: 'camera',
  CLIPBOARD: 'clipboard',
  CLIPBOARD_RD: 'clipboard-read',
  CLIPBOARD_WR: 'clipboard-write',
  GEOLOCATION: 'geolocation',
  BACKGROUND_SYNC: 'background-sync',
  MAGNETOMETER: 'magnetometer',
  MIC: 'microphone',
  MIDI: 'midi',
  NOTIF: 'notifications',
  PAYMENT: 'payment-handler',
  STORAGE: 'persistent-storage',
};
export const OPM_VIDEO_OVERLAY_POSITION = {
  0: 'TOP_LEFT',
  1: 'BOTTOM_LEFT',
  2: 'TOP_RIGHT',
  3: 'BOTTOM_RIGHT',

};

export const OPM_VIDEO_OVERLAY_TEXT_SIZE = {
  20: 'SMALL',
  25: 'MEDIUM',
  30: 'LARGE',
};

export const OVERLAY_POSITION = 'overlay_position';
export const OVERLAY_TEXT_SIZE = 'overlay_text_size';
export const OVERLAY_SHOW_LOCATION = 'overlay_show_location';
export const OVERLAY_SHOW_DATE_TIME = 'overlay_show_datetime';

export const BUTTON_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};
export const VIDEO_CONSENT_MODAL_TIMEOUT = 10000;

export const CUSTOM_MESSAGE_DATA_FIELDS = {
  TITLE_TEXT_FIELD: 'TitleTextField',
  MESSAGE_TEXT_AREA: 'MessageTextArea',
  MESSAGE_CHECKBOX_OPTION: 'MessageOptionsCheckboxGroup',
};

export const ALLOWED_FROM_OPM = 'yes';

export const GUEST_INVITE_STATUS = {
  SENT: 0,
  RECEIVED: 1,
  FAILED: 2,
};

export const MESSAGE_STATUS = {
  STR_SMS_DELIVERED_GATEWAY: translate('messageStatus.smsDeliveredGateway'),
  STR_SMS_DELIVERED_GATEWAY_DETAIL: translate('messageStatus.smsDeliveredGatewayDetail'),
  STR_SMS_ERROR_CONTENT: translate('messageStatus.smsErrorContent'),
  STR_SMS_ERROR_CONTENT_DETAIL: translate('messageStatus.smsErrorContentDetail'),
  STR_SMS_ERROR_DELIVERING: translate('messageStatus.smsErrorDelivering'),
  STR_SMS_ERROR_DELIVERING_DETAIL: translate('messageStatus.smsErrorDeliveringDetail'),
  STR_SMS_ERROR_PROVIDER: translate('messageStatus.smsErrorProvider'),
  STR_SMS_ERROR_PROVIDER_DETAIL: translate('messageStatus.smsErrorProviderDetail'),
  STR_SMS_ERROR_ROUTING: translate('messageStatus.smsErrorRouting'),
  STR_SMS_ERROR_ROUTING_DETAIL: translate('messageStatus.smsErrorRoutingDetail'),
  STR_SMS_EXPIRED: translate('messageStatus.smsExpired'),
  STR_SMS_EXPIRED_DETAIL: translate('messageStatus.smsExpiredDetail'),
  STR_SMS_INITIATED: translate('messageStatus.smsInitiated'),
  STR_SMS_INITIATED_DETAIL: translate('messageStatus.smsInitiatedDetail'),
  STR_SMS_LOGGED_IN: translate('messageStatus.smsLoggedIn'),
  STR_SMS_LOGGED_IN_DETAIL: translate('messageStatus.smsLoggedInDetail'),
  STR_SMS_OPENED: translate('messageStatus.smsOpened'),
  STR_SMS_OPENED_DETAIL: translate('messageStatus.smsOpenedDetail'),
  STR_SMS_OUT_OF_CREDIT: translate('messageStatus.smsOutOfCredit'),
  STR_SMS_OUT_OF_CREDIT_DETAIL: translate('messageStatus.smsOutOfCreditDetail'),
  STR_SMS_RECEIVED_RECIPIENT: translate('messageStatus.smsReceivedRecipient'),
  STR_SMS_RECEIVED_RECIPIENT_DETAIL: translate('messageStatus.smsReceivedRecipientDetail'),
  STR_SMS_SPAM: translate('messageStatus.smsSpam'),
  STR_SMS_SPAM_DETAIL: translate('messageStatus.smsSpamDetail'),
  STR_SMS_USER_EXPIRED: translate('messageStatus.smsGuestAccExpired'),
  STR_SMS_USER_EXPIRED_DETAIL: translate('messageStatus.smsGuestAccExpiredDetail'),
  STR_SMS_NOT_APPLICABLE: translate('messageStatus.smsNotApplicable'),
  STR_SMS_NOT_APPLICABLE_DETAIL: translate('messageStatus.smsNotApplicableDetail'),
};

export const SMS_STATUS_CODE = {
  SMS_ERROR_PROVIDER1: -1,
  SMS_ERROR_PROVIDER2: -2,
  SMS_NOT_APPLICABLE: 0,
  SMS_INITIATED: 1,
  SMS_DELIVERED: 3,
  SMS_RECEIVED_RECIPIENT: 4,
  SMS_ERROR_CONTENT: 5,
  SMS_ERROR_DELIVERING: 7,
  SMS_ERROR_ROUTING: 9,
  SMS_EXPIRED: 10,
  SMS_OUT_OF_CREDIT: 12,
  SMS_OPENED: 13,
  SMS_LOGGED_IN: 14,
  SMS_SPAM: 15,
  SMS_USER_EXPIRED: 16,
};
