/* eslint-disable import/order */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { AppLogger, LOG_NAME } from 'SERVICES/Logging/AppLogger';

// Component
import withLoadingSpinner from 'COMPONENTS/HOC/withLoadingSpinner';
import ErrorModal from 'COMPONENTS/CustomComponents/ErrorDialogModal/ErrorModal';
import Login from './Login';

// Utility
import CommonUtility from 'UTILS/CommonUtility';

import {
  LOGIN_RESPONSES,
  GUEST_LOGIN_RESPONSES,
  LOGIN_OPTIONS,
  SSO_LOGIN_OPM_URL,
  SSO_LOGIN_RELAY_STATE,
  SSO_LOGIN_URL_FIELDS,
  SSO_LOGIN_RESULT,
  SSO_LOGIN_REGISTER_RESULT,
} from 'UTILS/constants/LoginConstants';
import { LAUNCH_PARAMETER_ACTION } from 'UTILS/constants/LaunchParameterConstants';
import { ROUTES } from 'UTILS/constants/RoutingPathConstants';
import { API_RESPONSE_CODE } from 'UTILS/constants/ApiConstants';
import { LOCAL_STORAGE_KEY } from 'UTILS/constants/DOMElementConstants';

// translation
import { translate } from 'SERVICES/i18n';

// Action
import { loginAction, setLaunchParametersAction, createGuestUser } from 'STORE/Auth/AuthAction';

// Selector
import { getAuthToken, getIsSessionExpiredFlag, getLaunchParameters } from 'STORE/Auth/AuthSelector';
import { setCustomMessageDisable } from 'STORE/User/UserAction';
import { getBrandUrl } from 'STORE/Theme/ThemeSelector';
import {
  getUserData,
} from 'STORE/ClientSettings/ClientSettingsSelector';

import './Login.scss';
// services
import * as browserNotification from 'SERVICES/BrowserNotification/BrowserNotificationHelper';
import { initializePendo } from '../../utils/pendoUtils';

const logger = AppLogger(LOG_NAME.Login);

// eslint-disable-next-line react/prefer-stateless-function
class LoginContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showErrorPopup: false,
      serverErrorMessage: '',
      loading: true,
      guestLogin: false,
      cpPassword: null,
      cpUsername: null,
      meetingId: null,
      signInOption: this.setSignInOptionOnLoad(),
      ssoDomain: this.getSSODomainOnLoad(),
    };
  }

  componentDidMount() {
    // handle pending launch parameters
    this.setPrevSignInOption();
    const { launchParameters } = this.props;
    if (launchParameters) {
      if (launchParameters.action !== LAUNCH_PARAMETER_ACTION.SSO) {
        this.setState({ meetingId: launchParameters.meetingId });
        if (launchParameters.action === LAUNCH_PARAMETER_ACTION.LAUNCH) {
          // only clear launch parameters for launch (other actions will be handled after login)
          this.props.setLaunchParametersAction(null);
          if (launchParameters.username && launchParameters.password) {
            this.login(launchParameters.username, launchParameters.password);
            this.setState({ guestLogin: true });
          }
        }
      } else if (launchParameters.action === LAUNCH_PARAMETER_ACTION.SSO) {
        this.props.setLaunchParametersAction(null);
        this.setState({ signInOption: LOGIN_OPTIONS.SSO });
        this.setSignInOptionInLocalStorage(true);
        this.handleSsoOpmPageResponse(launchParameters);
      } else {
        console.info('LoginContainer::componentDidupdate:No deeplink action to perform from launch parameter');
      }
    } else {
      console.info('LoginContainer::componentDidupdate:No launch parameter found to take action');
    }
    // Came here on cancel of change password
    if (this.props?.history?.location?.state?.cpUsername &&
      this.props?.history?.location?.state?.cpPassword) {
      const cpUsername = this.props?.history?.location?.state?.cpUsername;
      const cpPassword = this.props?.history?.location?.state?.cpPassword;
      this.setState({ cpUsername, cpPassword });
    }
  }

  componentWillUnmount() {
    this.props.setCustomMessageDisable(false);
  }

  handleLoginResponse(responseData) {
    if (responseData && responseData.resultNumber) {
      switch (responseData.resultNumber) {
        case LOGIN_RESPONSES.SUCCESS: {
          // Do some action on successful login!
          initializePendo();
          break;
        }
        case LOGIN_RESPONSES.FAILURE:
          // Do some action on login failure!
          this.setError(
            'errors.runtimeErrorMessage',
            true,
            'errors.serverErrorHeading',
          );
          break;
        case LOGIN_RESPONSES.MAX_SESSION_EXCEED:
          // Displaying error message for maximum session number exceeded!
          this.setError(
            'errors.sessionExcceded',
            true,
            'errors.serverErrorHeading',
          );
          break;
        default:
          break;
      }
    }
  }

  /** This will set the initila login option on load on webapp at first time
   */
  setSignInOptionOnLoad() {
    return this.getSignInOptionFromLocalStorage() ?
      this.getSignInOptionFromLocalStorage()
      : LOGIN_OPTIONS.USERNAME;
  }

  getSSODomainOnLoad() {
    return this.getSSODomainFromLocalStorage() ?? '';
  }

  setPrevSignInOption() {
    const signInOPtionFromLocalStorage = this.getSignInOptionFromLocalStorage();
    if (signInOPtionFromLocalStorage === null) {
      // At initial level set signin option as username
      const IS_SSO = false;
      this.setSignInOptionInLocalStorage(IS_SSO);
    } else {
      this.setState({ signInOption: signInOPtionFromLocalStorage });
    }
  }

  login = (username, password, isSSO = false) => {
    if (!isSSO) {
      this.setSignInOptionInLocalStorage(isSSO);
    }
    this.props.showSpinner();
    this.props
      .loginAction(username, password, isSSO)
      .then((res) => {
        this.props.hideSpinner();
        // Check for login attempts and whether the acc is locked, otherwise redirect to dashboard
        logger.debug('IsOPMSessionExpired?::', this.props.isSessionExpired);
        if (res.status === API_RESPONSE_CODE.SUCCESS) {
          if (res.data.attemptsRemaining > LOGIN_RESPONSES.MIN_ATTEMPTS_REMAINING) {
            this.setError(
              translate('errors.invalidCredentials', {
                number: res.data.attemptsRemaining,
              }),
              true,
              'login.invalidUsernameOrPasswordModalHeader',
            );
          } else if (res.data.resultNumber === LOGIN_RESPONSES.NOT_LICENSED_FOR_PRODUCT
          ) {
            // valid creds but not licensed for this product/version
            this.setError(
              translate('errors.notLicensed'),
              true,
              'login.notLicensedHeder',
            );
          } else if ((res.data.attemptsRemaining === LOGIN_RESPONSES.MIN_ATTEMPTS_REMAINING
            && res.data.resultNumber === LOGIN_RESPONSES.INVALID_CREDENTIALS)
            // incorrect username or passwd and acc locked
            || res.data.resultNumber === LOGIN_RESPONSES.ACCOUNT_LOCKED) {
            // valid creds but acc is already locked
            this.setError(
              translate('errors.accountTemporarilyLocked'),
              true,
              'login.accountLockedHeader',
            );
          } else if (
            res.data.attemptsRemaining === LOGIN_RESPONSES.INVALID_ATTEMPTS
            && res.data.resultNumber === LOGIN_RESPONSES.INVALID_CREDENTIALS // Invalid username
          ) {
            this.setError(
              translate('errors.invalidUserNameOrPassword'),
              true,
              'login.invalidUsernameOrPasswordModalHeader',
            );
          } else if (res.data.resultNumber === LOGIN_RESPONSES.PASSWORD_EXPIRED ||
            res.data.resultNumber === LOGIN_RESPONSES.TEMP_PASSWORD) {
            // 13-PasswordExpired  14-PasswordTemporary
            this.props.history.push({
              pathname: ROUTES.CHANGE_PASSWORD,
              state: { userName: username, password },
            });
          } else {
            this.handleLoginResponse(res.data);
          }
        }
      })
      .catch((error) => {
        logger.error('error inside :---->', error);
        if (error?.error?.code === 401) {
          this.setError(
            error?.error?.message,
            true,
            'login.invalidUsernameOrPasswordModalHeader',
          );
        } else {
          this.setError(
            error?.error?.message,
            true,
            'errors.serverErrorHeading',
          );
        }
        this.props.hideSpinner();
      });
    browserNotification.askNotificationPermission();
  };

  loginAsGuest = (firstName, lastName, guestEmailId) => {
    this.props
      .createGuestUser(this.state.meetingId, firstName, lastName, guestEmailId)
      .then((res) => {
        this.handleGuestLoginResults(res.data);
      })
      .catch((error) => {
        logger.error(`LoginContainer:: Error while login as guest:: Not able to create guest user ${JSON.stringify(error)}`);
      });
  }
  /**
   * @param  {} companyDomain: The domain of the company for which SSO will get performed.
   * This fucntion will opean the URL generated by the OPM for SSO
   */

  SSOLogin = (companyDomain) => {
    this.setState({ ssoDomain: companyDomain });
    const encodedDomainName = encodeURIComponent(companyDomain);
    window.open(this.getRelayStateURL(encodedDomainName), '_self');
  }
  /**
   * @param  {} encodedDomainName: The encoded complany domain.
   * This function will generate the URL for SSO login using server base and domain.
   */

  getRelayStateURL = (encodedDomainName) => `${window.dynamicEnv.REACT_APP_SERVER_BASE_URL}/${SSO_LOGIN_OPM_URL}=${encodedDomainName}${this.generateRelayState()}`

  /**
   * This functin will return the relay state and the launch page where user will get logged in.
   */
  generateRelayState = () => `${SSO_LOGIN_RELAY_STATE}${SSO_LOGIN_URL_FIELDS.SSO_SOURCE}_${CommonUtility.getUniqueUniversalID()}_${SSO_LOGIN_URL_FIELDS.SSO_LANGUAGE}_${SSO_LOGIN_URL_FIELDS.SSO_METADATA_TOKEN}_${window.location.origin}${SSO_LOGIN_URL_FIELDS.SSO}`;

  handleSsoOpmPageResponse = (response) => {
    const { username, password, ssoLoginResult, ssoRegisterResult } = response;
    if (ssoLoginResult === SSO_LOGIN_RESULT.SUCCESS) {
      const domainName = username.split('@', 2)[1];
      this.setDomainForSSOInLocalStorage(domainName);
      this.login(username, password, true);
    } else if (ssoLoginResult === SSO_LOGIN_RESULT.SAML_ACCOUNT_PROVISIONIG_FAILED) {
      this.handleSSORegisterResults(ssoRegisterResult);
    } else {
      this.handleSSOLoginResults(ssoLoginResult);
    }
  }

  handleGuestLoginResults = (data) => {
    switch (data.resultNumber) {
      case GUEST_LOGIN_RESPONSES.SUCCESS:
        // Make login api call with the obtained guest user credentials
        this.setSignInOptionInLocalStorage(this.state.signInOption);
        this.login(data.username, data.password);
        break;
      case GUEST_LOGIN_RESPONSES.NO_USER_LICENSE:
      case GUEST_LOGIN_RESPONSES.SIP_ACCOUNT_POOL_EMPTY:
      case GUEST_LOGIN_RESPONSES.SIP_SERVER_TEMP_UNAVAILABLE:
      case GUEST_LOGIN_RESPONSES.INVITES_NOT_ALLOWED:
        this.setError(
          translate('errors.guestAccountForMeetingCannotBeCreated'),
          true,
          'errors.serverErrorHeading',
        );
        break;
      case GUEST_LOGIN_RESPONSES.MEETING_GUESTS_NOT_ALLOWED:
        // the meeting is configured not to allow guests
        this.setError(
          translate('errors.meetingGuestsNotAllowed'),
          true,
          'errors.serverErrorHeading',
        );
        break;
      case GUEST_LOGIN_RESPONSES.UNKNOWN:
      default: // Generic - same as Unknown case
        this.setError(
          translate('errors.guestUnknown'),
          true,
          'errors.serverErrorHeading',
        );
    }
  }

  handleSSORegisterResults = (result) => {
    let errorMessage = 'errors.samlAccountProvisioningFailed';
    switch (result) {
      case SSO_LOGIN_REGISTER_RESULT.CUSTOMER_DOES_NOT_EXIST:
        errorMessage = 'errors.customerDoesNotExist';
        break;
      case SSO_LOGIN_REGISTER_RESULT.EMAIL_DOMAIN_NOT_ALLOWED:
        errorMessage = 'errors.emailDomainNotAllowed';
        break;
      case SSO_LOGIN_REGISTER_RESULT.USER_ALREADY_EXIST:
        errorMessage = 'errors.userAlreadyExists';
        break;
      case SSO_LOGIN_REGISTER_RESULT.PASSWORD_POLICY_VIOLATION:
        errorMessage = 'errors.passwordPolicyViolation';
        break;
      case SSO_LOGIN_REGISTER_RESULT.NO_USER_LICENCE_AVAIALABLE:
        errorMessage = 'errors.noUserLicensesAvailable';
        break;
      case SSO_LOGIN_REGISTER_RESULT.INVALID_USER_NAME:
        errorMessage = 'errors.invalidUserName';
        break;
      case SSO_LOGIN_REGISTER_RESULT.INVALID_FIRST_NAME:
        errorMessage = 'errors.invalidFirstName';
        break;
      case SSO_LOGIN_REGISTER_RESULT.INVALID_EMAIL_ADDRESS:
      case SSO_LOGIN_REGISTER_RESULT.REGISTRATION_UNKNOWN:
      case SSO_LOGIN_REGISTER_RESULT.INCORRECT_CREATION_KEY:
      case SSO_LOGIN_REGISTER_RESULT.REGISTRATION_DISABLED:
        errorMessage = 'errors.registrationFailed';
        break;
      default:
        break;
    }
    const IS_SERVER_ERROR = true;
    this.setError(
      errorMessage,
      IS_SERVER_ERROR,
      translate('APPNAME'),
    );
  }

  handleSSOLoginResults = (result) => {
    let errorMessage = '';
    switch (result) {
      case SSO_LOGIN_RESULT.SAML_GENERAL_FAILURE:
        errorMessage = 'errors.samlGeneralFailure';
        break;
      case SSO_LOGIN_RESULT.SAML_FEDERATION_ATRIBUTE_MSSING:
        errorMessage = 'errors.samlFederationAttributeMissing';
        break;
      case SSO_LOGIN_RESULT.SAML_IDP_NAME_MISMATCH:
        errorMessage = 'errors.samlIdpNameMismatch';
        break;
      case SSO_LOGIN_RESULT.SAML_NOT_ENABLED:
        errorMessage = 'errors.samlNotEnabled';
        break;
      default:
        break;
    }
    const IS_SERVER_ERROR = true;
    this.setError(
      errorMessage,
      IS_SERVER_ERROR,
      translate('APPNAME'),
    );
  }

  setError = (message, isServerError = false, heading = '') => {
    logger.log(message, 'err message');
    this.setState({
      showErrorPopup: true,
      serverErrorMessage: isServerError ? message : '',
      errorHeading: isServerError ? heading : '',
    });
  };

  handleDismissError = () => {
    // Clear the URL if any creds are present related to guest login
    if (this.state.guestLogin) {
      this.props.history.push(ROUTES.LOGIN);
    }
    this.setState({
      showErrorPopup: false,
      guestLogin: false,
    });
  };

  toggleSignInOptions = (option) => {
    this.setState({ signInOption: option });
  }

  setSignInOptionInLocalStorage = (isSSO = false) => {
    CommonUtility.localStorage.setItem(`${LOCAL_STORAGE_KEY.LOGIN_OPTION}`, isSSO ? LOGIN_OPTIONS.SSO : LOGIN_OPTIONS.USERNAME);
  }

  setDomainForSSOInLocalStorage = (domainName) => {
    CommonUtility.localStorage.setItem(`${LOCAL_STORAGE_KEY.SSO_DOMAIN}`, domainName);
  }

  getSignInOptionFromLocalStorage = () => {
    const loginOptionFromLocalStorage = CommonUtility.localStorage.getItem(`${LOCAL_STORAGE_KEY.LOGIN_OPTION}`);
    if (loginOptionFromLocalStorage) {
      const PAGE_RENDER_TIMEOUT = 50;
      setTimeout(() => {
        this.setState({ signInOption: loginOptionFromLocalStorage });
      }, PAGE_RENDER_TIMEOUT);
    }
    return loginOptionFromLocalStorage;
  }

  getSSODomainFromLocalStorage = () => CommonUtility.localStorage.getItem(`${LOCAL_STORAGE_KEY.SSO_DOMAIN}`);

  render() {
    return (
      <div className='login-layout'>
        <div id='subcomponent' className='sub-component'>
          <Login
            authToken={this.props.authToken}
            handleLoginClick={this.login}
            serverErrorMessage={this.state.serverErrorMessage}
            loading={this.state.loading}
            setError={this.setError}
            brand={this.props.brandUrl}
            cpUsername={this.state.cpUsername}
            cpPassword={this.state.cpPassword}
            meetingId={this.props.launchParameters?.meetingId}
            handleGuestLogin={this.loginAsGuest}
            handleSSOLogin={this.SSOLogin}
            signInOption={this.state.signInOption}
            ssoDomain={this.state.ssoDomain}
            toggleSignInOptions={this.toggleSignInOptions}
          />

          <ErrorModal
            open={this.state.showErrorPopup}
            header={this.state.errorHeading}
            errorMessage={this.state.serverErrorMessage}
            onClose={this.handleDismissError}
            rightButton={[
              {
                text: translate('login.okButton'),
                onClick: this.handleDismissError,
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: getAuthToken(state),
  brandUrl: getBrandUrl(state),
  isSessionExpired: getIsSessionExpiredFlag(state),
  userData: getUserData(state),
  launchParameters: getLaunchParameters(state),

});

// eslint-disable-next-line no-unused-vars
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginAction,
      setLaunchParametersAction,
      createGuestUser,
      setCustomMessageDisable,
    },
    dispatch,
  );
}

export default withLoadingSpinner(
  connect(mapStateToProps, mapDispatchToProps)(LoginContainer),
  { spinnerText: translate('login.signingIn') },
);
