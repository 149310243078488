/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { clone } from 'lodash';

// Custom component
import CustomDropdown from 'COMPONENTS/CustomComponents/CustomDropdown/CustomDropdown';
import VideoQuality from 'COMPONENTS/Settings/VideoQuality';

// Translation
import { translate } from 'SERVICES/i18n';

// Utility
import Utils from 'UTILS/CommonUtility';

// Selector
import {
  getVideoInputDeviceList,
  getSelectedVideoDevice,
} from 'STORE/CallControl/CallControlSelector';
import {
  MEDIA_TYPE,
} from 'UTILS/constants/MediaServerConstants';
import {
  getClientPermissions,
} from 'STORE/ClientSettings/ClientSettingsSelector';

// Asset
import { ReactComponent as BackIcon } from 'ASSETS/icon_back_arrow.svg';

// Style
import './VideoSource.scss';

// eslint-disable-next-line react/prefer-stateless-function
class VideoSource extends Component {
  // Added this to retrieve updated list of devices when panel loads
  componentDidMount() {
    // Update devices list in app/system
    this.props.getDeviceList(MEDIA_TYPE.VIDEO);
  }

  handleDropdown = (option) => {
    // Invoke AppManager handler for changing device
    console.debug('VideoSource::Source selected ', option);
    this.props.changeVideoDevice(option);
  }

  /* Setting id for the usage in custom drop down
  For remote devices we get a list of strings so we convert into objects with id
   */
  setOptions = () => {
    // FIXME return this.props.videoDeviceList?.map((item) => { id: item.deviceId, ...item });
    const options = this.props.videoDeviceList?.map((item) => {
      let option = null;
      if (typeof item === 'object') {
        option = clone(item);
        option.value = item.deviceId;
      } else {
        option = { label: item, value: Utils.getRandomId() };
      }
      return option;
    });
    console.debug('VideoSource::Source options are ', JSON.stringify(options));
    return options;
  }

  setSelectedVideoInput = () => {
    if (this.props.videoDeviceList?.length === 0) {
      return { label: translate('noDeviceFound') };
    } if (typeof this.props.selectedVideoDevice === 'object') {
      return this.props.selectedVideoDevice;
    }
    // For remote sharer this may just be a label
    // generating random id
    return { label: this.props.selectedVideoDevice, id: Utils.getRandomId() };
  }

  render() {
    return (
      <div className='video-source-panel pt-2'>
        <Row className='video-header-pane align-items-center video-title'>
          <Col className='d-lg-none d-xl-none' xs='2'>
            <BackIcon
              className='back-icon'
              alt='back'
              role='presentation'
              onClick={this.props.goBack}
            />
          </Col>
          <Col className='alignment px-3'>
            <span className='video-header'>{translate('video.videoSource')}</span>
          </Col>
          <Col className='d-flex justify-content-end' xs='3' />
        </Row>
        <Row className='divider-margin'>
          <div className='divider' />
        </Row>

        <div className='scroll-panel-video'>
          <Row className={`camera video-header-pane p-3 py-1 ${!this.props.videoDeviceList?.length && 'disabled'}`}>
            {translate('video.camera')}
          </Row>

          <Row className={`video-header-pane p-1 ${!this.props.videoDeviceList?.length && 'disabled'}`}>
            <Col lg={10} md={8} sm={8} xs={9}>
              <CustomDropdown
                id='service-type'
                type='select'
                className='cursor-pointer'
                options={this.setOptions()}
                selectedOption={this.setSelectedVideoInput()}
                handleChange={(option) => this.handleDropdown(option)}
              />
            </Col>
          </Row>
          <Row className='video-header-pane p-1'>
            <div className='divider' />
          </Row>
          <Row className='camera video-header-pane p-3 py-1'>
            {translate('settings.videoQuality')}
          </Row>
          <Row className='video-header-pane p-1'>
            <Col lg={12} md={12} sm={12} xs={12}>
              <VideoQuality
                isOpen
                selectedVideoProfile={this.props.selectedVideoProfile}
                setSelectedProfile={this.props.setSelectedProfile}
                videoProfiles={this.props.videoProfiles}
                clientPolicyPermissionsXmlData={this.props.clientPolicyPermissionsXmlData}
                inSourcePanel
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  videoDeviceList: getVideoInputDeviceList(state),
  selectedVideoDevice: getSelectedVideoDevice(state),
  clientPolicyPermissionsXmlData: getClientPermissions(state),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {},
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideoSource);
