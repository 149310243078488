import {
  Subject,
  HubConnectionState,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';

import { NLP_HUB_METHODS, NLP_CONNETION_EVENTS, NLP_LANGUAGES } from 'UTILS/constants/NlpConstants';

import { AppLogger, LOG_NAME } from 'SERVICES/Logging/AppLogger';

const logger = AppLogger(LOG_NAME.NLPClient);

class NlpClient {
  constructor(options) {
    logger.debug('NlpClient constructor');
    // create connection
    this.connection = new HubConnectionBuilder()
      .withUrl(options.serverUrl, {
        headers: { 'X-Session-Token': options.hubToken },
        accessTokenFactory: () => options.hubToken,
        // TODO: cookies?
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Debug)
      .build();

    this.subject = null;

    // hookup event handlers
    this.connection.onclose(async (error) => {
      logger.info('NlpClient:onclose', error);
    });

    this.connection.onreconnected(async (error) => {
      logger.info('NlpClient:onreconnected', error);
    });

    this.connection.onreconnecting(async (connectionId) => {
      logger.info('NlpClient:onreconnecting', connectionId);
    });

    this.connection.on(NLP_CONNETION_EVENTS.RECEIVE_RECOGNIZING_RESULT, (result) => {
      logger.info('NlpClient:ReceiveRecognizingResult', result);
      if (typeof options.wordCallback === 'function') {
        options.wordCallback(result);
      }
    });
    this.connection.on(NLP_CONNETION_EVENTS.RECEIVE_RECOGNITION_RESULT, (result) => {
      logger.info('NlpClient:ReceiveRecognitionResult', result);
      if (typeof options.sentenceCallback === 'function') {
        options.sentenceCallback(result);
      }
    });
    this.connection.on(NLP_CONNETION_EVENTS.RECEIVE_SYNTH_RESULT, (synth) => {
      logger.info('NlpClient:ReceiveSynthResult', synth);
    });
    this.connection.on(NLP_CONNETION_EVENTS.STREAM_FAILED, () => {
      logger.error('NlpClient:StreamFailed');
      this.stopAudio();
      // Need to start again, since it has failed, reason could be audio was not ready
      setTimeout(() => {
        this.startAudio();
      }, 1000);
    });
  }

  static initNlpClient(url, sessionToken) {
    if (!NlpClient.instance) {
      NlpClient.instance = new NlpClient(url, sessionToken);
    }
    return NlpClient.instance;
  }

  isHubConnected() {
    return this.connection &&
      this.connection.state === HubConnectionState.Connected;
  }

  connect() {
    return new Promise((resolve, reject) => {
      if (!this.connection || this.connection.state !== HubConnectionState.Disconnected) {
        logger.warn('NlpClientHelper::connect() Hub is not in disconnected state cant connect');
        resolve();
      }
      this.connection.start().then(() => {
        logger.info('NlpClient:start - Connected');
        resolve();
      }).catch((reason) => {
        logger.info('NlpClient:start - Connection Error', reason);
        reject(reason);
      });
    });
  }

  async disconnect() {
    try {
      await this.connection.stop();
      logger.info('NlpClient:stop - Disconnected');
    } catch (err) {
      logger.info('NlpClient:stop - Disconnected Error', err);
    }
  }

  startAudio(fromLang = NLP_LANGUAGES[12].value, toLang = NLP_LANGUAGES[12].value) {
    logger.info('NlpClient:startAudio+');
    this.subject = new Subject();
    try {
      if (this.isHubConnected()) {
        this.connection.send(NLP_HUB_METHODS.STREAM_AUDIO, this.subject, fromLang, toLang, 'None');
      }
    } catch (error) {
      logger.error('NlpClientHelper::startAudio() failed, error:', error);
    }
  }

  sendAudio(buffer) {
    if (this.connection?.state === HubConnectionState?.Connected && this.subject != null) {
      this.subject.next(buffer);
    }
  }

  stopAudio() {
    logger.info('NlpClient:stopAudio+');
    this.subject?.complete();
    this.subject = null;
  }
}

export default NlpClient;
