import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

// Style
import './LoadingSpinner.scss';

export default class LoadingSpinner extends PureComponent {
  render() {
    return (
      <div>
        <Modal
          className='loading-spinner'
          show={this.props.showLoadingSpinner}
          animation={false}
          backdropClassName='loading-backdrop'
        >
          <Modal.Body className='loading-spinner-panel no-padding'>
            <div className='loading-spinner-container'>
              <div className='container-spin'>
                <div className='lds-default'>
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
                {this.props.loadingText && (
                  <div className='pt-2 loader-text'>
                    {this.props.loadingText}
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

LoadingSpinner.propTypes = {
  showLoadingSpinner: PropTypes.bool,
  loadingText: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  showLoadingSpinner: false,
  loadingText: '',
};
