/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';

// component
import Sidebar from 'COMPONENTS/Dashboard/Sidebar';
// import CustomButton from 'COMPONENTS/CustomComponents/CustomButton/CustomButton';

// Translation
import { translate } from 'SERVICES/i18n';

// Asset
import { ReactComponent as BackIcon } from 'ASSETS/icon_back_arrow.svg';
import { ReactComponent as IncomingCallIcon } from 'ASSETS/icon_call_incomming.svg';
import { ReactComponent as OutgoingCallIcon } from 'ASSETS/icon_call_outgoing.svg';
import { ReactComponent as RightChevronIcon } from 'ASSETS/icon_right_chevron.svg';
import { ReactComponent as MissedCallIcon } from 'ASSETS/icon_call_incomming_missed.svg';

// Constants
import {
  CALL_DIRECTION,
} from 'UTILS/constants/MediaServerConstants';

import { isDesktop, isIOS, isMobileOnly, isTablet } from 'react-device-detect';

export default class CallHistory extends Component {
  recordsList = [];

  componentDidMount() {
    this.recordsList = this.setCallHistoryRecordsList();
  }

  componentDidUpdate() {
    this.recordsList = this.setCallHistoryRecordsList();
    if (isIOS) {
      setTimeout(() => {
        document.getElementsByClassName('history-header')[0]?.scrollIntoView(false);
      }, 50);
    }
  }

  handleSelectedHistory(record) {
    console.debug(`CallHistory::handleSelectedHistory() ${JSON.stringify(record)}`);
    const calleeName = record.direction === CALL_DIRECTION.OUTGOING ?
      record.callee : record.caller;
    let remoteSipUri = record?.remoteSipUri;
    if (record?.remoteSipUri?.includes('sip:')) {
      // eslint-disable-next-line prefer-destructuring
      remoteSipUri = record.remoteSipUri.split('sip:')[1];
    }
    this.props.placeCall(calleeName, remoteSipUri);
  }

  setCallHistoryRecordsList = () => {
    const callHistoryListToRender = this.props.callRecords.map((record) => (
      <React.Fragment key={record.id}>
        <Row key={record.id} lg={12} md={12} sm={12} xs={12} className='margin align-center px-3'>
          <Row className='p-0 m-0'>
            <Col lg={2} md={2} sm={2} xs={2} className='align-center'>
              {record.direction === CALL_DIRECTION.INCOMING && !record.isMissCall && (
                <IncomingCallIcon className='incoming-call-icon' alt='direction' />
              )}
              {record.isMissCall && (<MissedCallIcon className='missed-call-icon' alt='direction' />)}
              {record.direction === CALL_DIRECTION.OUTGOING && !record.isMissCall && (
                <OutgoingCallIcon className='outgoing-call-icon' alt='direction' />
              )}
            </Col>
            <Col className='callee-name' lg={8} md={8} sm={8} xs={6}>
              <Row>
                <span
                  role='presentation'
                  className='cursor-pointer contact-name no-select'
                  onClick={() =>
                    this.handleSelectedHistory(record)
                  }
                >
                  <div className='ellipsis p-0'>
                    {
                      record.direction === CALL_DIRECTION.OUTGOING ?
                        record.callee : record.caller}
                  </div>
                  <div className='date-time'>
                    <span>{moment(record.date).format('YYYY/MM/DD')}</span>
                    <span className='pl-3'>
                      {moment(record.date).format('h:mmA')}
                    </span>
                  </div>
                </span>
              </Row>
            </Col>
            <Col className='align-items-end-center' lg={2} md={2} sm={2} xs={4}>
              <RightChevronIcon alt='details' className={`${isMobileOnly && 'callhistory-arrow-mob'} ${(isTablet || isDesktop) && 'callhistory-arrow-tab'} landscape-arrow`} />
            </Col>
          </Row>
        </Row>
        <Row className='margin px-4'>
          <div className='contact-divider' />
        </Row>
      </React.Fragment>
    )).reverse();
    return callHistoryListToRender;
  }

  render() {
    // Extra check to set the call history
    this.recordsList = this.setCallHistoryRecordsList();
    return (
      <Sidebar className='h-100 overflow-y call-history-sidebar'>
        <Row lg={12} md={8} sm={12} xs={12} className='sidebar-header m-0 p-0 contact-title call-history-sidebar-header pt-2'>
          <Col lg={12} md={10} sm={8} xs={12} className='d-flex m-0 p-0 align-center justify-content-lg-center justify-content-md-center justify-content-around'>
            <Col className='d-lg-none d-xl-none p-0 ' xs={1} sm={1} lg={1} md={1}>
              <BackIcon
                className='back-icon'
                alt='back'
                role='presentation'
                onClick={this.props.goBack}
              />
            </Col>
            <Col
              className='d-flex justify-content-center justify-content-lg-start history-header ps-3'
              xs={5}
              sm={5}
              md={5}
              lg={8}
            >
              <span className='call-history-header'>
                {translate('callHistory.callHistoryHeader')}
              </span>
            </Col>
            {/* TODO: Need to uncomment in next phase */}
            <Col
              className='d-flex justify-content-lg-center justify-content-xl-end justify-content-end pe-xl-3 padding-right-ipad-landscape'
              lg={4}
              xs={2}
              sm={2}
              md={2}
            >
              {/* <CustomButton id='delete_all' className={this.props.Language === 'en' ? 'delete-button' : 'delete-button-fr'}
                  variant={BUTTON_TYPES.SECONDARY}
              >
                <span className='delete-text'>
                  {translate('callHistory.deleteCallHistoryButton')}
                </span>
              </CustomButton> */}
            </Col>
          </Col>
        </Row>

        <div className='divider' />

        {this.recordsList && this.recordsList.length > 0 ? (
          <div className='call-history contact-list no-horizontal-scroll'>{this.recordsList}</div>
        ) : (
          <Row lg={8} md={8} sm={8} className='m-1 mt-2'>
            <Col className='callee-name contact-list landscape-horizontal-padding-option mx-3'>
              {translate('callHistory.noCallHistory')}
            </Col>
          </Row>
        )}

      </Sidebar>
    );
  }
}

CallHistory.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  callRecords: PropTypes.array,
};

CallHistory.defaultProps = {
  callRecords: [],
};
