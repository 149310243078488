/* eslint-disable default-param-last */
import MediaHandler from 'SERVICES/MediaServerService/MediaHandler';

/**
 * Provides Media Handling interfaces, to perform
 * media actions and setStates : Overlay, PauseLiveVideo,
 * ImageCapture, WebRTCPeer, MediaDevice, MediaConfig,
 * Canvas, Illumination, CallState, ZoomLiveVideo,
 */
export default class MediaManager {
  constructor(callbacks) {
    this.mediaHandler = new MediaHandler(callbacks);
  }

  /**
   * Handles Overlays on Video, SS and Image
   */
  Overlay = {
    toggleGpsOverlayVisibility:
      (state, callback) => this.mediaHandler.Overlay.toggleGpsOverlayVisibility(state, callback),
    toggleTimeStampOverlayVisibility: (state) =>
      this.mediaHandler.Overlay.toggleTimeStampOverlayVisibility(state),
    setVideoImgOverlayTextSize: (textSize) =>
      this.mediaHandler.Overlay.setVideoImgOverlayTextSize(textSize),
    setVideoImgOverlayTextlocation: (textLocation) =>
      this.mediaHandler.Overlay.setVideoImgOverlayTextlocation(textLocation),
  };

  /**
   * Pause/resumes live video
   */
  PauseLiveVideo = {
    togglePauseVideo: (state) => this.mediaHandler.PauseLiveVideo.togglePauseVideo(state),
  };

  /**
   * Handles Zoom on live video
   */
  ZoomLiveVideo = {
    zoomCanvas: (zoomfactor) => this.mediaHandler.ZoomLiveVideo.zoomCanvas(zoomfactor),
  };

  /**
   * Handles Image Capture related requests
   */
  ImageCapture = {
    captureImage: (
      videoStream,
      torchMode,
      isTorchForVideoEnabled,
      illumInfo,
      isScreenShare = false,
      permissions = {},
      isVideoPaused = false,
      imgMaxHeight,
    ) =>
      this.mediaHandler.ImageCapture.captureImage(
        videoStream,
        torchMode,
        isTorchForVideoEnabled,
        illumInfo,
        isScreenShare,
        permissions,
        isVideoPaused,
        imgMaxHeight,
      ),
  };

  /**
   * Performs WebRTCPeer related actions
   */
  WebRTCPeer = {
    setPeerConnection: (pc) => this.mediaHandler.WebRTCPeer.setPeerConnection(pc),
    clearPeerConnection: () => this.mediaHandler.ClearState.clearPeerConnection(),
    getRtcRtpSender: (mediaType) => this.mediaHandler.WebRTCPeer.getRtcRtpSender(mediaType),
    setMyStream: (stream) => this.mediaHandler.WebRTCPeer.setMyStream(stream),
  };

  /**
   * Handles Media Device change requests
   */
  MediaDevice = {
    changeDeviceByReplacingTrack: (mediaType,
      device,
      setShowVideoFallbackUI = null, isAudioOutput = false) =>
      this.mediaHandler.MediaDevice.changeDeviceByReplacingTrack(mediaType, device,
        setShowVideoFallbackUI, isAudioOutput),
    setCurrentVideoDeviceId:
      (deviceId) => this.mediaHandler.MediaDevice.setCurrentVideoDeviceId(deviceId),
    updateSharedVideoType:
      (mediaType) => this.mediaHandler.MediaDevice.updateSharedVideoType(mediaType),
  };

  /**
   * Handles MediaConfig change requests
   */
  MediaConfig = {
    applyAudioConfigsToStream: (audioMediaConfigs, isUserSharing) =>
      this.mediaHandler.MediaConfig.applyAudioConfigsToStream(audioMediaConfigs, isUserSharing),
    applyVideoConfigsToStream: (videoConfig, isUserSharing = false,
      isDesktopStream = false, isVideoPaused = false) =>
      this.mediaHandler.MediaConfig.applyVideoConfigsToStream(videoConfig, isUserSharing,
        isDesktopStream, isVideoPaused),
    setVideoConfigs: (videoConfig, isScreenShare, isUserSharing) =>
      this.mediaHandler.MediaConfig.setVideoConfigs(videoConfig, isScreenShare, isUserSharing),
    applyMediaConstraintsOnVideoTrack: (constraints, track, isScreenShare) =>
      this.mediaHandler.MediaConfig.applyMediaConstraintsOnVideoTrack(
        constraints, track, isScreenShare,
      ),
  };

  /**
   * Handles Canvas specific ops
   */
  Canvas = {
    getCanvas: (canvasId) => this.mediaHandler.Canvas.getCanvas(canvasId),
    updateCanvas: (videoConfig, videoDeviceId, isVideoPaused = false) =>
      this.mediaHandler.Canvas.updateCanvas(videoConfig, videoDeviceId, isVideoPaused),
    getStreamWithMicAudioAndFakeVideo:
      () => this.mediaHandler.Canvas.getStreamWithMicAudioAndFakeVideo(),
  };

  /**
   * Performs video stream actions
   */
  VideoStream = {
    stopStreamDisplay: () => this.mediaHandler.VideoStream.stopStreamDisplay(),
    displayStream: (videoMConfig,
      selectedVideoDeviceId,
      updateCB) => this.mediaHandler.VideoStream.displayStream(videoMConfig,
      selectedVideoDeviceId,
      updateCB),
    pauseVideo: (videoConfig) => this.mediaHandler.VideoStream.pauseVideo(videoConfig),
    resumeVideo: (videoConfig, isScreenShare, isVideoPaused = false, isUserSharing = true) =>
      this.mediaHandler.VideoStream.resumeVideo(
        videoConfig, isScreenShare, isVideoPaused, isUserSharing,
      ),
    setLocalVideoStream: (stream) => this.mediaHandler.VideoStream.setLocalVideoStream(stream),
    captureAndStoreStreamFromDesktop:
      () => this.mediaHandler.VideoStream.captureAndStoreStreamFromDesktop(),
  };

  /**
   * Handles location related requests
   */
  Location = {
    getGeoLocationData: () => this.mediaHandler.Location.getGeoLocationData(),
    setLocationState: (state, callback) =>
      this.mediaHandler.Location.setLocationState(state, callback),
  };

  /**
   * Manages CallFunctions States
   */
  CallFunctionState = {
    clearState: () => this.mediaHandler.ClearState.clearAllState(),
    setPauseVideoState: (state) => this.mediaHandler.SetState.setPauseVideoState(state),
    setTorchStateForVideo: (state) => this.mediaHandler.SetState.setTorchStateForVideo(state),
  };

  /**
   * Handles illumination toggle requests
   */
  Illumination = {
    toggleIllum:
      (videoTrack, torch) => this.mediaHandler.Illumination.toggleIllumination(videoTrack, torch),
  };
}
