/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

// Modal
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

// React bootstrap
import { Row, Col } from 'react-bootstrap';

import CustomButton from 'COMPONENTS/CustomComponents/CustomButton/CustomButton';
import CustomCheckbox from 'COMPONENTS/CustomComponents/CustomCheckbox/CustomCheckbox';

// CSS
import './Popup.scss';

// Translation
import { translate } from 'SERVICES/i18n';

// Constant
import { BUTTON_TYPES } from 'UTILS/constants/UtilityConstants';

// DOM const
import { CUSTOM_MESSAGE } from 'UTILS/constants/DOMElementConstants';

/**
 * @component
 */
// eslint-disable-next-line react/prefer-stateless-function
class Popup extends React.Component {
  closeTimeout = null;

  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.modalRef = React.createRef(null);

    this.state = {
      isOpen: false,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      isOpen: nextProps.open,
    };
  }

  componentDidUpdate() {
    if (this.modalRef.current && this.props.id === CUSTOM_MESSAGE.POPUP_ID) {
      this.modalRef.current.offsetParent.style = 'z-index : 1500';
    }
  }

  componentWillUnmount() {
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
      this.closeTimeout = null;
    }
  }

  render() {
    const messageText = this.props.interpolationText
      ? translate(this.props.messageText, {
        interpolationText: this.props.interpolationText,
      })
      : translate(this.props.messageText);
    if (this.props.timeout) {
      this.closeTimeout = setTimeout(() => {
        if (this.props.open) {
          this.props.onClose();
        }
      }, this.props.timeout);
    }

    return (
      <Modal
        ref={this.modalRef}
        role='dialog'
        open={this.state.isOpen}
        center
        showCloseIcon={false}
        classNames={{
          modal: `custom-modal  ${this.props.className}`,
          overlay: 'custom-popup-overlay',
        }}
        onClose={this.props.onClose}
        onEscKeyDown={this.props.onClose}
        focusTrapped={false}
        closeOnOverlayClick={false}
        modalId={this.props.id ?? 'custom-popup'}
        backdrop={this.props.backdrop ?? 'static'}
      >
        <Row className='modal-body' tabIndex='-1'>
          <Col>
            <Row className='p-2 modal-header ellipsis'>
              <span className='p-0 header1'>
                {translate(this.props.header)}
                <span className='sub-text1'>
                  {translate(this.props.subText1)}
                </span>
                {this.props.subText2}
              </span>
            </Row>
            <Row className={`modal-body-contents text-wrap ${this.props.buttonClass ? '' : 'p-2'} ${this.props.centerContent && ' center-content align-center'} `}>
              <span className={`message-text p-0  ${this.props.justifyText ? 'justify-message-text' : ''}`}>
                {translate(messageText)}
              </span>
            </Row>
            <Row className={`modal-body-contents text-wrap ${this.props.buttonClass ? '' : 'p-2'} ${this.props.centerContent && ' align-center'} `}>
              { this.props.checkbox &&
                this.props.checkbox.map((checkbox, index) => (
                  <CustomCheckbox
                    key={index}
                    id='custom-message'
                    className='d-flex'
                    onCheckboxChange={checkbox.onCheckboxChange}
                    checked={checkbox.checked}
                    text={checkbox.text}
                    disabled={false}
                    alignCheckbox
                  />
                ))
              }
            </Row>
            {/* eslint-disable-next-line no-nested-ternary */}
            <Row className={`pt-3 ${this.props.buttonClass ? this.props.buttonClass : 'align-items-end-center'}`}>
              {this.props.leftButton &&
                this.props.leftButton.map((button, index) => (
                  <CustomButton
                    key={index}
                    className={`align-center action-buttons cancel-button ${button.buttonClass}`}
                    onClick={button.onClick}
                    text={button.text}
                    variant={BUTTON_TYPES.SECONDARY}
                  />
                ))}
              {this.props.rightButton &&
                this.props.rightButton.map((button, index) => (
                  <CustomButton
                    key={index}
                    className={`align-center action-buttons ${button.buttonClass}`}
                    onClick={button.onClick}
                    disabled={button.disabled}
                    type={button.type}
                    text={button.text}
                  />
                ))
              }
            </Row>
          </Col>
        </Row>
      </Modal>
    );
  }
}

Popup.propTypes = {
  header: PropTypes.string,
  interpolationText: PropTypes.string,
  messageText: PropTypes.string,
  // okButton: PropTypes.objectOf,
  // cancelButton: PropTypes.objectOf,
  subText1: PropTypes.string,
  subText2: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  className: PropTypes.string,
  centerContent: PropTypes.bool,
  id: PropTypes.string,
};

Popup.defaultProps = {
  header: '',
  interpolationText: '',
  messageText: '',
  // okButton: {},
  // cancelButton: {},
  subText1: '',
  subText2: '',
  onClose: () => { },
  open: false,
  className: '',
  centerContent: false,
  id: '',
};

export default Popup;
