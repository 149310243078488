/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

// Components
import CustomPopover from 'COMPONENTS/CustomComponents/CustomPopover/CustomPopover';
import RangeSliderComponent from 'COMPONENTS/CustomComponents/RangeSlider/RangeSlider';

// Constant
import { ZOOM_SLIDER_VALUES, POPOVER_PLACEMENT } from 'UTILS/constants/UtilityConstants';

// Style
import './ZoomPopover.scss';

/**
 *
 * @param {bool} isColorPopoverOpen to hide/show color pallet popover
 * @param {function} toggleColorPopover to toggle the color pallet popover
 * @param {string} selectedColor selected color from color pallet
 * @param {function} colorIconClick onClick handler for color icon
 * @param {Number} zoomLevel selected stroke value that comes from ds event
 * @param {Number} zoomMinRange min value that comes from ds event
 * @param {Number} zoomMaxRange max value that comes from ds event
 * @param {string} id
 * @returns jsx for color pallet popover
 */
function ZoomPopover({ isPopoverOpen, togglePopover, closeZoomPopover, onChangeZoomRange,
  zoomLevel, zoomMinRange, zoomMaxRange, id }) {
  // State variable
  const [strokeValue, setStrokeValue] = useState(zoomLevel);
  const menuPlacement = isMobile && window.innerHeight > window.innerWidth
    ? POPOVER_PLACEMENT.TOP : POPOVER_PLACEMENT.TOP_START;

  useEffect(() => {
    // console.debug(`ZoomPopover::useEffect: strokeValue:${strokeValue} zoomLevel:${zoomLevel}`);
    setStrokeValue(zoomLevel);
  });

  const handleClickOutside = () => {
    closeZoomPopover();
  };

  /**
     * onChange handler for stroke width slider component
     * @param {number} number unit for zoom
     */
  const handleStrokeChange = (number, e) => {
    e.stopPropagation();
    setStrokeValue(number);
    onChangeZoomRange(number);
  };

  const displayZoomUI = () => (
    <RangeSliderComponent
      rangeValue={strokeValue}
      setRangeValue={(number, e) => handleStrokeChange(number, e)}
      minValue={zoomMinRange}
      maxValue={zoomMaxRange}
      sliderStep={ZOOM_SLIDER_VALUES.STEP}
      isStroke={false}
    />
  );
  return (
    <CustomPopover
      isPopoverOpen={isPopoverOpen}
      togglePopover={togglePopover}
      menuPlacement={menuPlacement}
      closeCustomPopover={handleClickOutside}
      title='zoom.Zoom'
      child={displayZoomUI()}
      target='zoom'
      id={id}
      className='zoom-popover'
    />
  );
}

ZoomPopover.propTypes = {
  isPopoverOpen: PropTypes.bool.isRequired,
  togglePopover: PropTypes.func.isRequired,
  closeZoomPopover: PropTypes.func.isRequired,
  onChangeZoomRange: PropTypes.func.isRequired,
  zoomLevel: PropTypes.number.isRequired,
  zoomMaxRange: PropTypes.number.isRequired,
  zoomMinRange: PropTypes.number.isRequired,
};

export default ZoomPopover;
