/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  withOrientationChange,
} from 'react-device-detect';

// Components
import TelestrationPopover from 'COMPONENTS/CallDashboard/TelestrationPopover/TelestrationPopover';
import IlluminationPopover from 'COMPONENTS/CallDashboard/IlluminationPopover/IlluminationPopover';
import NlpPopover from 'COMPONENTS/CallDashboard/NLP/NlpPopover';
import ZoomPopover from 'COMPONENTS/CallDashboard/ZoomPopover/ZoomPopover';

import {
  isStreamStarted,
} from 'COMPONENTS/AppManager/AppUtils';

import browserAPI from 'UTILS/BrowserAPI';

// Action
import { logoutAction } from 'STORE/Auth/AuthAction';
import { setActiveIlluminationItemAction } from 'STORE/User/UserAction';

// Selectors
import { getBrandTheme } from 'STORE/Theme/ThemeSelector';
import { getActiveIlluminationItem } from 'STORE/User/UserSelector';
import { getIsVideoPaused, getStreamShareState, isScreenShareOn } from 'STORE/CallControl/CallControlSelector';
import { isDemoRunning } from 'STORE/ClientSettings/ClientSettingsSelector';

// constants
import { ICON_NAMES } from 'UTILS/constants/FooterConstants';
import { STILL_IMAGE_SHARE_MODE_STATUS } from 'UTILS/constants/DatastreamConstant';

// Asset
import { ReactComponent as DownArrowIcon } from 'ASSETS/icon_down_arrow.svg';
import { ReactComponent as UpArrowIcon } from 'ASSETS/icon_up_arrow.svg';

// Style
import './Footer.scss';

const ICON_DISABLED_CLASS = 'disabled';
const ICON_ENABLED_CLASS = '';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileCallIconsVisible: true,
      isTelePopoverOpen: false,
      selectedIcon: '',
      isIlluminationPopUpOpen: false,
      isNlpPopUpOpen: false,
      isZoomPopUpOpen: false,
      pause: true,
      isKeyBoardOpen: false,
      isOrientationChanged: window?.innerHeight < window.innerWidth,
    };
  }

  componentDidMount() {
    browserAPI.onOrientationChange(() => this.handleOrientationChange());

    // TODO: Need to optimize this later to add to existing callback in browserAPI
    window.addEventListener('resize', () => {
      this.setState((prevState) => ({
        isKeyBoardOpen: !prevState.isKeyBoardOpen,
      }));
    });
  }

  componentDidUpdate(prevProps) {
    if ((this.state.selectedIcon === ICON_NAMES.SNAPSHOT_ENABLED) &&
      ((this.props.imageCaptureData !== null &&
        this.props.imageCaptureData !== prevProps.imageCaptureData) ||
        // Don't highlight if image capture is cancelled
        (this.props.imageCapCancelled === true))) {
      this.setState({ selectedIcon: '' });
    }

    if (prevProps.isVideoPaused !== this.props.isVideoPaused) {
      this.setState({ pause: !this.props.isVideoPaused });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', () => {
      console.info('Removing orientationchange listener');
    });
  }

  handleOrientationChange = () => {
    this.setState((prevState) => ({
      isTelePopoverOpen: false,
      selectedIcon: '',
      isIlluminationPopUpOpen: false,
      isNlpPopUpOpen: false,
      isZoomPopUpOpen: false,
      isKeyBoardOpen: false,
      isOrientationChanged: !prevState.isOrientationChanged,
    }), () => {
      this.mobileOrientations();
    });
  }

  // #region Telestration

  // Telestration enabled whenever a stream is being shared or image is being shared by
  // local or remote
  isTelestrationEnable = () => isStreamStarted(this.props.streamShareState) ||
    this.props.isImageShared;

  toggleTelestrationPopover = () => {
    this.setState((prevState) => ({
      isTelePopoverOpen: !prevState.isTelePopoverOpen,
      isIlluminationPopUpOpen: false,
      isNlpPopUpOpen: false,
      isZoomPopUpOpen: false,
      selectedIcon: ICON_NAMES.TELESTRATION,
    }), () => {
      if (!this.state.isTelePopoverOpen) {
        this.setState({ selectedIcon: '' });
      }
    });
  };

  closeTelestrationPopover = () => {
    if (this.state.isTelePopoverOpen) {
      this.setState({
        isTelePopoverOpen: false,
        selectedIcon: '',
      });
    }
  }

  // #endregion

  // #region Illumination

  closeIlluminationPopover = () => {
    if (this.state.isIlluminationPopUpOpen) {
      this.setState({
        isIlluminationPopUpOpen: false, selectedIcon: '',
      });
    }
  }

  toggleIlluminationPopover = () => {
    this.setState((prevState) =>
      ({
        isIlluminationPopUpOpen: !prevState.isIlluminationPopUpOpen,
        isNlpPopUpOpen: false,
        isTelePopoverOpen: false,
        isZoomPopUpOpen: false,
        selectedIcon: ICON_NAMES.ILLUMINATION,
      }), () => {
      if (!this.state.isIlluminationPopUpOpen) {
        this.setState({ selectedIcon: '' });
      } else {
        this.props.setIlluminationButtonState();
      }
    });
  };

  // check call for this
  toggleIllumination = (type) => {
    if (this.state.isIlluminationPopUpOpen) {
      this.props.toggleIllumination(type);
    }
  }

  // #endregion

  // #region zoom

  closeZoomPopover = () => {
    if (this.state.isZoomPopUpOpen) {
      this.setState({
        isZoomPopUpOpen: false, selectedIcon: '',
      });
    }
  }

  toggleZoomPopover = () => {
    this.setState((prevState) =>
      ({
        isZoomPopUpOpen: !prevState.isZoomPopUpOpen,
        isIlluminationPopUpOpen: false,
        isNlpPopUpOpen: false,
        isTelePopoverOpen: false,
        selectedIcon: ICON_NAMES.ZOOM,
      }), () => {
      if (!this.state.isZoomPopUpOpen) {
        this.setState({ selectedIcon: '' });
      } else {
        this.props.setIlluminationButtonState();
      }
    });
  };

  // #endregion

  // #region NLP

  toggleNLPPopover = () => {
    this.setState((prevState) => ({
      isNlpPopUpOpen: !prevState.isNlpPopUpOpen,
      isIlluminationPopUpOpen: false,
      isTelePopoverOpen: false,
      isZoomPopUpOpen: false,
      selectedIcon: ICON_NAMES.AI,
    }), () => {
      if (!this.state.isNlpPopUpOpen) {
        this.setState({ selectedIcon: '' });
      }
    });
  };

  closeNLPPopover = () => {
    this.setState({
      isNlpPopUpOpen: false,
      selectedIcon: '',
    });
  }
  // #endregion

  getIconState = (iconName) => {
    if (this.props.demoRunning) {
      return { toggled: false, enabled: true };
    }

    const iconState = { toggled: true, enabled: true, className: ICON_ENABLED_CLASS };
    switch (iconName) {
      case ICON_NAMES.SNAPSHOT_ENABLED:
        iconState.toggled = !(!this.props.imageCaptureInProgress &&
          this.props.stillImageShareModeState ===
          STILL_IMAGE_SHARE_MODE_STATUS.STILL_IMAGE_SHARE_MODE_EXIT &&
          isStreamStarted(this.props.streamShareState));
        iconState.enabled = !iconState.toggled;
        iconState.className = ICON_DISABLED_CLASS;
        break;

      case ICON_NAMES.PAUSE:
        iconState.enabled = isStreamStarted(this.props.streamShareState);
        iconState.toggled = !(iconState.enabled && (this.state.pause === true));
        break;

      default:
        break;
    }
    return iconState;
  }

  checkIconState = (iconName) => {
    const iconState = this.getIconState(iconName);
    const iconEnabledClass = 'cursor-pointer';
    const iconDisabledClass = 'cursor-pointer disabled';

    switch (iconName) {
      case ICON_NAMES.SNAPSHOT_ENABLED:
        iconState.enabled = this.props.isImageCaptureEnable() && !this.props.imageCaptureData;
        break;

      case ICON_NAMES.TELESTRATION:
        iconState.enabled = this.isTelestrationEnable();
        break;

      case ICON_NAMES.ZOOM:
        iconState.enabled = isStreamStarted(this.props.streamShareState) ||
          this.props.isImageShared;
        break;

      case ICON_NAMES.ILLUMINATION:
        iconState.enabled = (isStreamStarted(this.props.streamShareState) ||
          this.props.isImageShared) && !this.props.screenShareOn;
        break;

      case ICON_NAMES.ICON_AI:
        iconState.enabled = true;
        break;

      case ICON_NAMES.PAUSE:
        iconState.enabled = isStreamStarted(this.props.streamShareState) ||
          this.props.isImageShared;
        break;

      default:
        iconState.disabled = true;
        break;
    }
    iconState.enabled = this.props.demoRunning || iconState.enabled;
    iconState.className = iconState.enabled ? iconEnabledClass : iconDisabledClass;
    return iconState;
  }

  renderIcon = (item) => {
    const iconState = this.getIconState(item?.iconName);
    if (item?.isToggle) {
      switch (item?.iconName) {
        case ICON_NAMES.SNAPSHOT_ENABLED:
          return (!iconState.toggled ? (
            <span id={item?.id}>
              <item.icon className={item.className} />
            </span>
          ) : (
            <span id={item?.id}>
              <item.toggleIcon
                className={iconState.className}
              />
            </span>
          ));
        case ICON_NAMES.PAUSE:
          return (
            iconState.enabled ? (
              (!iconState.toggled ? <item.icon className={item.className} /> : <item.toggleIcon />)
            )
              : (
                <item.icon className={item.className} />
              ));
        default:
          (
            <span id={item?.id}>
              <item.icon className={item.className} />
            </span>
          );
      }
    } else {
      return (
        <span id={item?.id}>
          <item.icon className={item.className} />
        </span>

      );
    }
    return null;
  };

  checkForEnterKeyPress = (e, item) => {
    if (this.props.demoRunning) {
      return; // Do no action if demo running
    }
    if (e.key === 'Enter' && e.target.value !== '') {
      this.handleIconClick(item);
    }
  }

  handleIconClick = (item) => {
    if (this.props.demoRunning) {
      return; // Do no action if demo running
    }

    switch (item?.iconName) {
      case ICON_NAMES.SNAPSHOT_ENABLED:
        this.props.takePicture();
        this.setState({ selectedIcon: item?.iconName });
        break;
      case ICON_NAMES.PAUSE:
        this.props.togglePauseVideo();
        this.setState((prevState) => ({ pause: !prevState.pause }));
        break;
      default:
        this.props.clickHandler(item?.iconName);
    }
  };

  toggleIconSet = () => {
    const currentCallIconVisiblity = this.state.isMobileCallIconsVisible;
    this.setState({
      isMobileCallIconsVisible: !currentCallIconVisiblity,
      isTelePopoverOpen: false,
      isIlluminationPopUpOpen: false,
      isZoomPopUpOpen: false,
      isNlpPopUpOpen: false,
    }, () => {
      this.props.handleFooterToggleState(this.state.isMobileCallIconsVisible);
    });
  };

  togglePauseStyle = (value) => {
    if (value === true) {
      this.setState({ selectedIcon: ICON_NAMES.PAUSE });
    } else {
      this.setState({ selectedIcon: '' });
    }
  };

  showIcons = (item) => {
    if (item.iconName === ICON_NAMES.AI && (!this.props.nlpUrl || window.dynamicEnv.REACT_APP_ALLOW_NLP === 'false')) {
      return false
    }
    return true
  };

  mobileOrientations = () => {
    const { isLandscape, isPortrait } = this.props;
    if (isLandscape && this.state.isOrientationChanged) {
      return (
        this.state.isMobileCallIconsVisible ?
          (
            <Col lg={12} md={12} sm={12} xs={12} className='footer-container align-horizontal-center w-100 px-0' id='landscape'>
              <Row className='footer w-100 d-flex justify-content-between' lg={12} md={12} sm={12} xs={12}>
                <Col className=' align-center px-0 d-flex justify-content-end landscape-margin ms-lg-0 ms-xl-3' lg={11} md={10} sm={10} xs={10}>
                  <Row className='align-center icons-set p-0 h-100 py-1'>
                    {this.props.iconSet.map((item) => (
                      this.showIcons(item) && (
                      <Col
                        key={item?.iconName}
                        lg={1}
                        sm={1}
                        md={1}
                        xs={1}
                        tabIndex='0'
                        onKeyPress={(e) => {
                          this.checkForEnterKeyPress(e, item);
                        }}
                        onClick={
                          item?.iconName ===
                            ICON_NAMES.SNAPSHOT_ENABLED
                            || item?.iconName === ICON_NAMES.TELESTRATION
                            || item?.iconName === ICON_NAMES.ILLUMINATION
                            || item?.iconName === ICON_NAMES.ZOOM
                            || item?.iconName === ICON_NAMES.PAUSE
                            ? () => {
                              this.handleIconClick(
                                item,
                              );
                            }
                            : () => { }
                        }
                        onPointerDown={item?.iconName === ICON_NAMES.PAUSE ? (
                          () => this.togglePauseStyle(true)) : undefined}
                        onPointerUp={item?.iconName === ICON_NAMES.PAUSE ? (
                          () => this.togglePauseStyle(true)) : undefined}
                        className={`icons-width align-center ${this.state.selectedIcon === item?.iconName && 'active-icon'} ${item.disabled ? 'disabled' : 'cursor-pointer'
                        } ${this.checkIconState(item.iconName).className}
                          `
                        }
                      >
                        {this.renderIcon(item)}
                        {item.iconName === ICON_NAMES.ILLUMINATION && (
                          <IlluminationPopover
                            isPopoverOpen={this.state.isIlluminationPopUpOpen}
                            togglePopover={this.toggleIlluminationPopover}
                            toggleIllumination={this.toggleIllumination}
                            closeIlluminationPopover={this.closeIlluminationPopover}
                            isOnFlashLight={this.props.isOnFlashLight}
                            handleFlashLightChange={this.props.handleFlashLightChange}
                            activeIlluminationItem={this.props.activeIlluminationItem}
                            setActiveIlluminationItemAction={this
                              .props.setActiveIlluminationItemAction}
                          />
                        )}

                        {item?.iconName === ICON_NAMES.TELESTRATION && (
                          <TelestrationPopover
                            isPopoverOpen={this.state.isTelePopoverOpen}
                            togglePopover={this.toggleTelestrationPopover}
                            setApplyBackdrop={this.props.setApplyBackdrop}
                            closeTelestrationPopover={this.closeTelestrationPopover}
                            teleHelper={this.props.teleHelper}
                            isImageShared={this.props.isImageShared}
                            isTeleEnabled={this.isTelestrationEnable()}
                          />
                        )}
                        {item?.iconName === ICON_NAMES.AI && (
                          <NlpPopover
                            isNlpPopUpOpen={this.state.isNlpPopUpOpen}
                            toggle={this.toggleNLPPopover}
                            closeNLPPopover={this.closeNLPPopover}
                            captionsOn={this.props.captionsOn}
                            fromLanguage={this.props.fromLanguage}
                            toLanguage={this.props.toLanguage}
                            updateNLPData={this.props.updateNLPData}
                            isLandscape={isLandscape}
                            nlpUrl={this.props.nlpUrl}
                          />
                        )}
                        {item.iconName === ICON_NAMES.ZOOM && (
                          <ZoomPopover
                            isPopoverOpen={this.state.isZoomPopUpOpen}
                            togglePopover={this.toggleZoomPopover}
                            closeZoomPopover={this.closeZoomPopover}
                            onChangeZoomRange={this.props.onChangeZoomRange}
                            zoomLevel={this.props.zoomLevel}
                            zoomMinRange={this.props.zoomMinRange}
                            zoomMaxRange={this.props.zoomMaxRange}
                            id='zoom-landscape'
                          />
                        )}
                      </Col>
                      )
                    ))}
                  </Row>
                </Col>
                <Col className='h-100 align-center arrow active-icon' id='toggle_arrow' lg={1} md={2} sm={2} xs={2} onClick={this.toggleIconSet}>
                  {this.state.isMobileCallIconsVisible ? <DownArrowIcon id='down_arrow' className='down-arrow-icon' /> : <UpArrowIcon id='up_arrow' className='up-arrow-icon' />}
                </Col>
              </Row>
            </Col>
          ) : (
            <Col lg={12} md={12} sm={12} xs={12} className='align-items-end-center mobile-landscape-view px-0'>
              <Col className='landscape-footer-container' id='toggle_arrow' lg={2} md={2} sm={2} xs={2} onClick={this.toggleIconSet}>
                <Col className=' align-center landscape-footer active-icon'>
                  {this.state.isMobileCallIconsVisible ? <DownArrowIcon id='down_arrow' className='down-arrow-icon' /> : <UpArrowIcon id='up_arrow' className='up-arrow-icon' />}
                </Col>
              </Col>
            </Col>
          )
      );
    }

    if (isPortrait || !this.state.isOrientationChanged) {
      return (
        <Col lg={12} md={12} sm={12} xs={12} className='footer-container w-100 px-0' id='portrait'>
          <Row className=' w-100 align-center footer' lg={12} md={12} sm={12} xs={12}>
            <Row
              className='h-100 w-100 align-center icons-set justify-content-between py-1'
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <Col className='d-flex h-100 potrate-mode-icons' lg={9} md={11} sm={10} xs={9}>
                {this.state.isMobileCallIconsVisible
                  ? this.props.portraitIconSet.map((item, i) => (
                    i < 3 && this.showIcons(item) && (
                      <Col
                        key={item?.iconName}
                        lg={1}
                        sm={1}
                        md={1}
                        xs={1}
                        tabIndex='0'
                        onKeyPress={(e) => {
                          this.checkForEnterKeyPress(e, item);
                        }}
                        onClick={
                          item?.iconName ===
                            ICON_NAMES.SNAPSHOT_ENABLED ||
                            item?.iconName ===
                            ICON_NAMES.PAUSE
                            ? () => {
                              this.handleIconClick(
                                item,
                              );
                            }
                            : () => { }
                        }
                        onPointerDown={item?.iconName === ICON_NAMES.PAUSE ? (
                          () => this.togglePauseStyle(true)) : undefined}
                        onPointerUp={item?.iconName === ICON_NAMES.PAUSE ? (
                          () => this.togglePauseStyle(true)) : undefined}
                        className={`icons-width align-center ${item.disabled ? 'disabled' : 'cursor-pointer'
                        }
                    ${item?.iconName ===
                            ICON_NAMES.SNAPSHOT_ENABLED ||
                            (item?.iconName === ICON_NAMES.TELESTRATION
                              || item?.iconName === ICON_NAMES.PAUSE
                            )
                          ?
                          this.props.isImageCaptureEnable() && !this.props.imageCaptureData
                            ? 'cursor-pointer'
                            : (item?.iconName === ICON_NAMES.TELESTRATION
                              && this.isTelestrationEnable()) ?
                              'cursor-pointer' :
                              'disabled' :
                          ''}
                          ${this.props.imageCaptureData ? this.props.isImageShared &&
                            (item?.iconName === ICON_NAMES.AI)
                            ? 'cursor-pointer'
                            : (item?.iconName === ICON_NAMES.TELESTRATION
                              && this.isTelestrationEnable()) ?
                              'cursor-pointer' :
                              'disabled'
                            : ''}
                          ${(this.props.isVideoPaused && item?.iconName === ICON_NAMES.ZOOM) && 'disabled'}                            
                          ${(window.innerWidth < 712 && isPortrait) && this.state.selectedIcon === item?.iconName && ' active-icon'}  
                        `
                        }
                      >
                        {window.innerWidth >= 712 && isPortrait ? (
                          <span className={`footer-icon 
                          ${this.state.selectedIcon === item?.iconName && ' active-icon'}                                                   
                          `}
                          >
                            {this.renderIcon(item)}
                          </span>
                        )
                          : this.renderIcon(item)}
                        {item?.iconName === ICON_NAMES.TELESTRATION && (
                          <TelestrationPopover
                            isPopoverOpen={this.state.isTelePopoverOpen}
                            togglePopover={this.toggleTelestrationPopover}
                            setApplyBackdrop={this.props.setApplyBackdrop}
                            closeTelestrationPopover={this.closeTelestrationPopover}
                            teleHelper={this.props.teleHelper}
                            isImageShared={this.props.isImageShared}
                            isTeleEnabled={this.isTelestrationEnable()}
                          />
                        )}
                      </Col>
                    )

                  ))
                  : this.props.portraitIconSet.map((item, i) =>
                    (
                      i > 2 && this.showIcons(item) && (
                      <Col
                        key={item?.iconName}
                        lg={1}
                        sm={1}
                        md={1}
                        xs={1}
                        tabIndex='0'
                        onKeyPress={(e) => {
                          this.checkForEnterKeyPress(e, item);
                        }}
                        onPointerDown={item?.iconName === ICON_NAMES.PAUSE ? (
                          () => this.togglePauseStyle(true)) : undefined}
                        onPointerUp={item?.iconName === ICON_NAMES.PAUSE ? (
                          () => this.togglePauseStyle(true)) : undefined}
                        className={`icons-width align-center ${item.disabled ? 'disabled' : 'cursor-pointer'}
                            ${this.checkIconState(item.iconName).className}
                            ${(window.innerWidth < 712) && this.state.selectedIcon === item?.iconName && ' active-icon'}
                          `
                        }
                      >
                        {window.innerWidth >= 712 && isPortrait ? (
                          <span className={`footer-icon ${this.state.selectedIcon === item?.iconName && ' active-icon'}`}>
                            {this.renderIcon(item)}
                          </span>
                        )
                          :
                          this.renderIcon(item)}
                        {item.iconName === ICON_NAMES.ILLUMINATION
                          && !this.state.isMobileCallIconsVisible && (
                            <IlluminationPopover
                              isPopoverOpen={this.state.isIlluminationPopUpOpen}
                              togglePopover={this.toggleIlluminationPopover}
                              toggleIllumination={this.toggleIllumination}
                              closeIlluminationPopover={this.closeIlluminationPopover}
                              isOnFlashLight={this.props.isOnFlashLight}
                              handleFlashLightChange={this.props.handleFlashLightChange}
                              activeIlluminationItem={this.props.activeIlluminationItem}
                              setActiveIlluminationItemAction={this
                                .props.setActiveIlluminationItemAction}
                            />
                        )}
                        {item?.iconName === ICON_NAMES.AI
                          && !this.state.isMobileCallIconsVisible && (
                            <NlpPopover
                              isNlpPopUpOpen={this.state.isNlpPopUpOpen}
                              toggle={this.toggleNLPPopover}
                              closeNLPPopover={this.closeNLPPopover}
                              captionsOn={this.props.captionsOn}
                              fromLanguage={this.props.fromLanguage}
                              toLanguage={this.props.toLanguage}
                              updateNLPData={this.props.updateNLPData}
                              isLandscape={isLandscape}
                              nlpUrl={this.props.nlpUrl}
                            />
                        )}
                        {!this.state.isMobileCallIconsVisible &&
                          item.iconName === ICON_NAMES.ZOOM && (
                            <ZoomPopover
                              isPopoverOpen={this.state.isZoomPopUpOpen}
                              togglePopover={this.toggleZoomPopover}
                              closeZoomPopover={this.closeZoomPopover}
                              onChangeZoomRange={this.props.onChangeZoomRange}
                              zoomLevel={this.props.zoomLevel}
                              zoomMinRange={this.props.zoomMinRange}
                              zoomMaxRange={this.props.zoomMaxRange}
                              id='zoom-portrait'
                            />
                        )}
                      </Col>
                      )))}
              </Col>
              <Col className='h-100 align-center arrow active-icon' id='toggle_arrow' lg={3} md={1} sm={3} xs={2} onClick={this.toggleIconSet}>
                {this.state.isMobileCallIconsVisible ? <DownArrowIcon id='down_arrow' className='down-arrow-icon' /> : <UpArrowIcon id='up_arrow' className='up-arrow-icon' />}
              </Col>
            </Row>
          </Row>
        </Col>
      );
    }
    // }
    return null;
  };

  desktopOrientations = () => {
    const { isLandscape } = this.props;
    const isDesktopMode = browserAPI.isDesktopMode(isLandscape);
    if (isDesktopMode) {
      return (
        <div className='w-100' id='desktop'>
          <Col lg={12} md={12} sm={12} xs={12} className='footer-container w-100 px-0'>
            <Row className='footer' lg={12} md={12} sm={12} xs={12}>
              <Col lg={12} md={12} sm={12} xs={12} className='footer-container w-100 px-0'>
                <Row className='footer w-100' lg={12} md={12} sm={12} xs={12}>
                  <Row className='align-center icons-set p-0 py-1'>
                    {this.props.iconSet.map((item) => (
                      this.showIcons(item) && (
                      <Col
                        key={item.iconName}
                        lg={1}
                        sm={1}
                        md={1}
                        xs={1}
                        tabIndex='0'
                        onKeyPress={(e) => {
                          this.checkForEnterKeyPress(e, item);
                        }}
                        onClick={
                          item?.iconName ===
                            ICON_NAMES.SNAPSHOT_ENABLED ||
                            item?.iconName ===
                            ICON_NAMES.PAUSE
                            ? () => {
                              this.handleIconClick(
                                item,
                              );
                            }
                            : () => { }
                        }
                        onPointerDown={item?.iconName === ICON_NAMES.PAUSE ? (
                          () => this.togglePauseStyle(true)) : undefined}
                        onPointerUp={item?.iconName === ICON_NAMES.PAUSE ? (
                          () => this.togglePauseStyle(true)) : undefined}
                        className={`icons-width align-center ${this.state.selectedIcon === item?.iconName && 'active-icon'} ${item.disabled ? 'disabled' : 'cursor-pointer'
                        } ${this.checkIconState(item.iconName).className} 
                      `
                        }
                      >
                        {this.renderIcon(item)}
                        {item?.iconName === ICON_NAMES.TELESTRATION && (
                          <TelestrationPopover
                            isPopoverOpen={this.state.isTelePopoverOpen}
                            togglePopover={this.toggleTelestrationPopover}
                            setApplyBackdrop={this.props.setApplyBackdrop}
                            closeTelestrationPopover={this.closeTelestrationPopover}
                            teleHelper={this.props.teleHelper}
                            isImageShared={this.props.isImageShared}
                            isTeleEnabled={this.isTelestrationEnable()}
                          />
                        )}
                        {item?.iconName === ICON_NAMES.ILLUMINATION && (
                          <IlluminationPopover
                            isPopoverOpen={this.state.isIlluminationPopUpOpen}
                            togglePopover={this.toggleIlluminationPopover}
                            toggleIllumination={this.toggleIllumination}
                            isOnFlashLight={this.props.isOnFlashLight}
                            handleFlashLightChange={this.props.handleFlashLightChange}
                            closeIlluminationPopover={this.closeIlluminationPopover}
                            activeIlluminationItem={this.props.activeIlluminationItem}
                            setActiveIlluminationItemAction={this
                              .props.setActiveIlluminationItemAction}
                          />
                        )}
                        {item?.iconName === ICON_NAMES.ZOOM && (
                          <ZoomPopover
                            isPopoverOpen={this.state.isZoomPopUpOpen}
                            togglePopover={this.toggleZoomPopover}
                            closeZoomPopover={this.closeZoomPopover}
                            onChangeZoomRange={this.props.onChangeZoomRange}
                            zoomLevel={this.props.zoomLevel}
                            zoomMinRange={this.props.zoomMinRange}
                            zoomMaxRange={this.props.zoomMaxRange}
                            id='zoom-desktop'
                          />
                        )}
                        {item?.iconName === ICON_NAMES.AI && (
                          <NlpPopover
                            isNlpPopUpOpen={this.state.isNlpPopUpOpen}
                            toggle={this.toggleNLPPopover}
                            closeNLPPopover={this.closeNLPPopover}
                            captionsOn={this.props.captionsOn}
                            fromLanguage={this.props.fromLanguage}
                            toLanguage={this.props.toLanguage}
                            updateNLPData={this.props.updateNLPData}
                            isLandscape={isLandscape}
                            nlpUrl={this.props.nlpUrl}
                          />
                        )}
                      </Col>
                      )
                    ))}
                  </Row>
                </Row>
              </Col>
            </Row>
          </Col>
        </div>
      )
    }
    return null;
  };

  render() {
    return (
      (this.desktopOrientations() || this.mobileOrientations())
    );
  }
}

Footer.propTypes = {
  iconSet: PropTypes.arrayOf(
    PropTypes.shape({
      iconName: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ),
  portraitIconSet: PropTypes.arrayOf(
    PropTypes.shape({
      iconName: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ),
  isLandscape: PropTypes.bool,
  isPortrait: PropTypes.bool,
  clickHandler: PropTypes.func,
};
Footer.defaultProps = {
  iconSet: [],
  portraitIconSet: [],
  isLandscape: false,
  isPortrait: false,
  clickHandler: () => { },
};

const mapStateToProps = (state) => ({
  theme: getBrandTheme(state),
  activeIlluminationItem: getActiveIlluminationItem(state),
  isVideoPaused: getIsVideoPaused(state),
  demoRunning: isDemoRunning(state),
  streamShareState: getStreamShareState(state),
  screenShareOn: isScreenShareOn(state),
});

// eslint-disable-next-line no-unused-vars
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logoutAction,
      setActiveIlluminationItemAction,
    },
    dispatch,
  );
}

export default withOrientationChange(
  connect(mapStateToProps, mapDispatchToProps)(Footer),
);
