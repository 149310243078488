/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, Row, Col } from 'react-bootstrap';
import Text from 'react-texty';

// Translation
import { translate } from 'SERVICES/i18n';

// Asset
import { ReactComponent as ErrorIcon } from 'ASSETS/icon_error.svg';

// Style
import 'react-texty/styles.css';
import './TextFieldComponent.scss';

class TextFieldComponent extends React.Component {
  // prettier-ignore
  defineProps = () => { // NOSONAR
    let classes = this.props.disabled ? 'disabled' : '';
    if (this.props.isWarning) {
      classes += ' warning-border';
    }
    if (this.props.textAlignCenter) {
      classes += ' center-align';
    }
    if (this.props.readOnly) {
      classes += ' read-only';
    }
    if (this.props.type === 'number') {
      classes += ' remove-stepper';
    }

    if (!this.props.halfWidth) classes += ' full-width';
    if (this.props.className) classes += ' ' + this.props.className;
    const handleChange = (event) => {
      if (this.props.tooltipType === 'password') {
        const { value } = event.target;
        this.checkPasswordInvalidity(value);
      }
      this.props.handleChange(event);
    };
    const componentProps = {
      id: this.props.id ? this.props.id : '',
      readOnly: this.props.readOnly,
      value: this.props.value,
      placeholder: this.props.placeholder,
      minLength: this.props.minLength,
      maxLength: this.props.maxLength,
      pattern: this.props.pattern,
      required: this.props.required,
      'aria-invalid': this.props.invalid,
      ref: this.props.inputRef,
      onChange: handleChange,
      onClick: this.props.onClick,
      onInput: this.props.onInput,
      onKeyUp: this.props.onKeyUp,
      onKeyDown: this.props.onKeyDown,
      onKeyPress: this.props.onKeyPress,
      onFocus: this.props.onFocus,
      onBlur: this.props.onBlur,
      disabled: this.props.disabled,
      className: classes,
      'data-required': this.props.dataRequired,
      'data-left-icon': !!this.props.leftIcon,
      'data-right-icon': !!this.props.rightIcon,
      'data-right-icon-2': !!this.props.rightIcon2,
      defaultValue: this.props.defaultValue,
      rows: this.props.rows,
      title: this.props.title,
      autoComplete: this.props.autoComplete,
      list: this.props.list,
    };
    if (this.props.type === 'textarea') {
      componentProps.as = this.props.type;
    }
    if (this.props.children) {
      componentProps.children = this.props.children;
    }
    if (this.props.type) {
      componentProps.type = this.props.type;
    } else {
      componentProps.type = 'text';
    }
    return componentProps;
  };

  checkPasswordInvalidity = () => {
    //   const password = value.toLowerCase();
    // const passwordLength = !/.{8,}/.test(password);
    // const caseRegex = !/^(?=.*[a-z])(?=.*[A-Z]).*$/.test(value);
    // const numberCheckRegex = !/[0-9]/.test(password);
  };

  // prettier-ignore
  render() {
    const componentProps = this.defineProps();
    const { disabled } = this.props;

    return (
      <div
        className={`text-field-component ${this.props.tooltipContainer} ${
          this.props.parentClass
        } ${this.props.halfWidth ? 'half-width' : 'full-width'} ${this.props.errorMessage && 'error-text'}`}
      >
        {this.props.placeholderTop && (
          <span
            className={`top-place-holder ${this.props.placeholderTopClass}`}
          >
            {this.props.placeholderTop}
            {this.props.subtext && (
              <span
                className={
                  this.props.isNotOptionalText ? 'dark-subtext' : 'subtext'
                }
              >
                {this.props.subtext}
              </span>
            )}
          </span>
        )}
        {this.props.leftIcon && (
          <this.props.leftIcon
            className={`left-icon${disabled ? ' disabled' : ''}`}
            alt='left-icon'
            tabIndex='0'
          />
        )}
        <FormControl {...componentProps} />
        {this.props.rightIcon && (
          <this.props.rightIcon
            className={`right-icon${disabled ? ' disabled' : ''}`}
            // role='button'
            tabIndex='0'
            alt='right-icon'
            onKeyPress={(e) => {
              this.props.checkForEnterKeyPress(e);
            }}
            onClick={
              this.props.onIconClick
                ? this.props.onIconClick
                : () => {
                  // do nothing.
                }
            }
            onPointerUp={
              this.props.onPointerUp
                ? this.props.onPointerUp
                : () => {
                  // do nothing.
                }
            }
            onPointerDown={
              this.props.onPointerDown
                ? this.props.onPointerDown
                : () => {
                  // do nothing.
                }
            }
            onPointerLeave={
              this.props.onPointerLeave
                ? this.props.onPointerLeave
                : () => {
                  // do nothing.
                }
            }
          />
        )}
        {this.props.rightIcon2 && (
          <this.props.rightIcon2
            className='right-icon-2'
            alt='cross-icon'
            onClick={this.props.onSearchClose}
          />
        )}

        {/* Display local error messages and add ellipses and tooltip for long strings */}
        {this.props.errorMessage && (
          <Row className='msg-align'>
            <Col className='d-flex align-items-center py-1 w-100'>
              <Text
                className='error-message'
                tooltipClassName='error-tooltip'
                placement='bottom'
                tooltip={translate(this.props.errorMessage)}
              >
                <span>
                  <ErrorIcon width='22.005' height='22.005' className='error-icon' />
                </span>
                <span className='padd-left-1'>
                  {translate(this.props.errorMessage)}
                </span>
              </Text>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

TextFieldComponent.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  rows: PropTypes.number,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onInput: PropTypes.func,
  disabled: PropTypes.bool,
  onKeyDown: PropTypes.func,
  halfWidth: PropTypes.bool,
  isWarning: PropTypes.bool,
  textAlignCenter: PropTypes.bool,
  readOnly: PropTypes.bool,
  onKeyPress: PropTypes.func,
  showTooltip: PropTypes.bool,
  handleChange: PropTypes.func,
  dataRequired: PropTypes.bool,
  toggleToolTip: PropTypes.func,
  tooltipType: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  disableDropdown: PropTypes.bool,
  leftIcon: PropTypes.elementType,
  rightIcon: PropTypes.elementType,
  rightIcon2: PropTypes.elementType,
  tooltipPosition: PropTypes.string,
  selectedOptionId: PropTypes.string,
  tooltipContainer: PropTypes.string,
  setPasswordValidaity: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TextFieldComponent.defaultProps = {
  id: '',
  rows: 0,
  type: '',
  className: '',
  value: '',
  leftIcon: '',
  rightIcon: '',
  rightIcon2: '',
  placeholder: '',
  disabled: false,
  tooltipType: '',
  halfWidth: false,
  isWarning: false,
  textAlignCenter: false,
  readOnly: false,
  autoComplete: 'on',
  showTooltip: false,
  dataRequired: false,
  selectedOptionId: '',
  disableDropdown: false,
  tooltipContainer: 'body',
  tooltipPosition: 'bottom-start',
  onBlur: () => {
    /* do nothing */
  },
  onInput: () => {
    /* do nothing */
  },
  onClick: () => {
    /* do nothing */
  },
  onFocus: () => {
    /* do nothing */
  },
  onKeyUp: () => {
    /* do nothing */
  },
  onKeyDown: () => {
    /* do nothing */
  },
  onKeyPress: () => {
    /* do nothing */
  },
  handleChange: () => {
    /* do nothing */
  },
  toggleToolTip: () => {
    /* do nothing */
  },
  setPasswordValidaity: () => {
    /* do nothing */
  },
};

export default TextFieldComponent;
