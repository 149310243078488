/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import '../Settings/Settings.scss';

class SideNav extends PureComponent {
  render() {
    return (
      <Row className='inherit-width-tabs'>
        <Col
          lg={9}
          sm={12}
          className='p-0 m-4 mt-0 inherit-width-tabs'
        >
          {this.props.children}
        </Col>
      </Row>
    );
  }
}

export default SideNav;
