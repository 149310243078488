// style
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { themeColors } from 'STYLES/Branding/themeColors';
import getTheme, { themeVersion } from 'STYLES/Branding/themes';
import i18n from 'i18next';
import axios from 'axios';

// translation
import { translate } from 'SERVICES/i18n';

// Store
import {
  setThemeAction,
  setBrandAction,
  setBrandUrlAction,
  setBrandResourceUrlAction,
  THEME_STORAGE_KEY,
} from 'STORE/Theme/ThemeAction';

import {
  getBrandUrl,
  getBrandName,
  getBrandResourceUrl,
  getBrandTitle,
  getBrandTheme,
} from 'STORE/Theme/ThemeSelector';

import { ROOT } from 'UTILS/constants/DOMElementConstants';
import { AXIOS_CONSTANTS } from 'UTILS/constants/ApiConstants';
import Utils from 'UTILS/CommonUtility';

// Services
import { AppLogger, LOG_NAME } from 'SERVICES/Logging/AppLogger';

// Asset
import AppLogo from 'ASSETS/Logo.png';

const BRANDING_URL = window.dynamicEnv.REACT_APP_BRANDING_BASE_URL;
const BRAND_DATA_URI_PREFIX = 'Branding';
const BRANDING_DEF = 'branding.json';
const BRAND_TRANSLATIONS_PATH = 'translations';
// Defaults
const DEFAULT_LOGO = 'logo.png';
const DEFAULT_THEME = 'themeColors.js';
const DEFAULT_APPNAME = 'Onsight Connect';
const DEFAULT_NS = 'translation';
const KEY_APPNAME = 'APPNAME';

// Query param for theme
const THEME_PARAM = 'theme';
const DEFAULT_THEME_NAME = 'default';
const logger = AppLogger(LOG_NAME.Branding);

const BRANDING_RESOURCE = {
  DATA_DEFS: 'dataDefs',
  LOGO: 'logo',
  THEME: 'theme',
  TRANSLATIONS: 'translation',
  DATA_BASE_URL: 'baseUrl',
  CUSTOM_CSS: 'customCSS',
};

/*
* Generic API to perform GET; can be moved to AxiosHelper
* Error handled by caller
*/
const getAPI = (resourceUrl) => axios.get(resourceUrl, {
  timeout: AXIOS_CONSTANTS.REQUEST_TIMEOUT,
  withCredentials: false,
});

/**
 * Top level API which start brand data initialization to set the theme,
 * brand images as well as translations
 * The sequence is -
 * 1. Gets BRANDING_DEF (json) from URL
 * 2. If branding data found, match the hostname, sub-domain or theme param
 * in the order of selection to find a set matching brand
 * 3. For translations - do further get calls to get required translations
 * 4. Initialize - brand image, translations, theme with the data for the brand
 * 5. For favicon - TBD
 */
export default function ThemeManager({ query }) {
  const dispatch = useDispatch();
  const [brandData, setBrandData] = useState(undefined); // @type {BrandDataDef}
  const initialized = useRef(false);
  let translationData = {};
  // Active theme settings
  const currentTheme = {
    theme: useSelector(getBrandTheme),
    brandName: useSelector(getBrandName),
    brandUrl: useSelector(getBrandUrl),
    title: useSelector(getBrandTitle),
    resourceUrl: useSelector(getBrandResourceUrl),
  };

  /**
   * Derive the URL for the required resource
   * @param {BRANDING_RESOURCE} resourceId - id of resource whose URL to be derived
   * @param {string} resource - optional value of resource per the context
   */
  const getResourceUrl = (resourceId, resource = null) => {
    let baseUrl = (brandData != null) ? `${BRANDING_URL}` : window.location.origin;
    const resourceBase = (brandData != null) ? `${BRAND_DATA_URI_PREFIX}/${brandData?.dataPath}` : '';
    let resourcePath = null;

    switch (resourceId) {
      case BRANDING_RESOURCE.DATA_DEFS:
        baseUrl = BRANDING_URL;
        resourcePath = BRANDING_URL ? BRANDING_DEF : null;
        break;

      case BRANDING_RESOURCE.LOGO:
        resourcePath = '/' + (brandData?.logoName ?? DEFAULT_LOGO);
        break;

      case BRANDING_RESOURCE.THEME:
        resourcePath = '/' + (brandData?.themeName ?? DEFAULT_THEME);
        break;

      case BRANDING_RESOURCE.TRANSLATIONS:
        resourcePath = brandData?.translations ? `/${BRAND_TRANSLATIONS_PATH}/${resource}` : null;
        break;

      case BRANDING_RESOURCE.CUSTOM_CSS:
        /* eslint-disable no-unsafe-optional-chaining */
        resourcePath = '/' + brandData?.customCSS;
        break;

      case BRANDING_RESOURCE.DATA_BASE_URL:
        // Do nothing ; returns the base path
        break;

      default:
        logger.warn('Resource not supported', resourceId);
        break;
    }
    const resUrl = resourcePath ? new URL(resourceBase + resourcePath, baseUrl).href :
      new URL(resourceBase, baseUrl).href;
    logger.debug(`Path for - ${Utils.mapName(BRANDING_RESOURCE, resourceId)} =>`, resUrl);
    return resUrl;
  };

  /**
   * Handles setting of logo image for the brand or default
   * @param {string} imageName Name of image resource
   */
  const setBrandImage = () => {
    const defaultLogo = currentTheme?.brandUrl ?? AppLogo;
    const img = new Image();
    img.src = getResourceUrl(BRANDING_RESOURCE.LOGO);
    // Required for getting asset from branding website
    img.setAttribute('crossorigin', 'anonymous');

    // eslint-disable-next-line func-names
    img.onload = function () {
      // eslint-disable-next-line  react/no-this-in-sfc
      if (!this.width || !this.height) {
        dispatch(setBrandUrlAction(defaultLogo));
        return;
      }
      // png to base64 conversion
      // Create canvas
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      // Set width and height
      canvas.width = img.width;
      canvas.height = img.height;
      // Draw the image
      ctx.drawImage(img, 0, 0);
      try {
        const dataUrl = canvas.toDataURL('image/png');
        dispatch(setBrandUrlAction(dataUrl));
      } catch (err) {
        logger.warn('Got error =>', err);
        dispatch(setBrandUrlAction(defaultLogo));
      }
    };
    img.onerror = () => {
      dispatch(setBrandUrlAction(defaultLogo));
    };
  };

  /**
   * Updates brand name, title - the basic identity of the brand
   */
  const setupBrand = () => {
    /*
    * Use title and set window title in the order of fall back
    1. From banding.json -> title
    2. As defined in themeColors.js - Should this attribute be obsolete?
    3. APPNAME from local translations
    4. Hard coded DEFAULT_APPNAME
    */

    const defaultAppName = currentTheme?.brandName ?? DEFAULT_APPNAME;
    const defaultTitle = currentTheme?.title ?? defaultAppName;
    const newBrandName = brandData?.appName ?? defaultAppName ?? translate(KEY_APPNAME);
    // Chain of fallbacks
    const newTitle = brandData?.title ??
      (window.title ?? defaultTitle ?? newBrandName);
    const fallbackUrl = currentTheme?.resourceUrl ?? window.location.origin;
    const newResourceUrl = brandData ?
      getResourceUrl(BRANDING_RESOURCE.DATA_BASE_URL) : fallbackUrl;

    dispatch(setBrandResourceUrlAction(newResourceUrl));
    dispatch(setBrandAction(newBrandName, newTitle));
    logger.info('Current brand setup to => ',
      { name: newBrandName, title: newTitle, resourceUrl: newResourceUrl });
  };

  const setBrandTheme = () => {
    const head = document.getElementsByTagName(ROOT.HEAD)[0];
    // Load theme script only if we have a brandData or we do
    // not have currentTheme
    if (brandData || !currentTheme?.theme) {
      const script = document.createElement(ROOT.SCRIPT_TAG);
      script.type = 'text/javascript';
      // eslint-disable-next-line func-names
      script.onload = function () {
        const colors = window.themeColors ?? themeColors;
        const theme = getTheme(colors);
        dispatch(setThemeAction(theme, colors, themeVersion));
        console.log('Branding::Theme loaded ');
      };
      script.src = getResourceUrl(BRANDING_RESOURCE.THEME);
      logger.debug('Adding theme script', script.src);
      head.appendChild(script);
    }
  };

  const loadCustomCSS = () => {
    const head = document.getElementsByTagName('HEAD')[0];
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = getResourceUrl(BRANDING_RESOURCE.CUSTOM_CSS);
    head.appendChild(link);
  };

  /**
   * Checks if the brandData matches any one of the selectors from
   * hostname, domain, theme in the given order
   * The brandDef name may be a string or an array which may have one or more of
   * any of the above attributes to allow for multiple ways of selecting brand data
   * based on deployment
   *
   * @param {BrandDataDef} brandDef
   * @param {Array} selectors
   * @returns {Bool}
   */
  function checkBrand(brandDef, selectors) {
    if (!brandDef.name) {
      logger.warn('Error in branding definition. Mapping name not defined');
      return false;
    }
    if (typeof brandDef.name === 'string') {
      return selectors.find((val) => (val.toLowerCase() === brandDef.name.toLowerCase()));
    }

    if (Array.isArray(brandDef.name)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const sel of brandDef.name) {
        if (selectors.find((val) =>
          (val.toLowerCase() === sel.toLowerCase()))) return true;
      }
      return false;
    }
    logger.warn('Data type not supported or missing name', brandDef.name);
    return false;
  }

  /**
   * Adds resource from translation json from branding data
   * TODO: This may be optimized further with use of 'addResources'
   * @param {string} lang Language for translation
   * @param {string} key key for the translation resource
   * @param {string} value parameterized string
   */
  const addTranslationKey = (lang, key, value) => {
    if (typeof value === 'string') {
      i18n.addResource(lang, DEFAULT_NS, key, value);
    } else if (typeof value === 'object') {
      // eslint-disable-next-line no-restricted-syntax
      for (const [k, v] of Object.entries(value)) {
        const nk = [key, k].join('.');
        addTranslationKey(lang, nk, v);
      }
    }
  };

  // Apply translation(s)
  const applyTranslation = (trData, lang = null) => {
    let langData = null;
    const setTranslation = () => {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(langData)) {
        addTranslationKey(lang, key, value);
      }
    };
    if (lang) {
      langData = trData[lang];
      setTranslation();
    } else {
      // eslint-disable-next-line no-restricted-syntax,guard-for-in
      for ([lang, langData] of Object.entries(trData)) {
        setTranslation();
      }
    }
  };

  /**
   * Sets APPNAME for all supported languages.
   * It may get overwritten if defined in translation files but thats ok.
   */
  const setAppName = (appName) => {
    if (appName) {
      // eslint-disable-next-line
      for (const lang of i18n.languages) {
        i18n.addResource(lang, DEFAULT_NS, KEY_APPNAME, appName);
      }
    }
  };

  /**
   * Initiates translation get and and updates local language resource bundles
   */
  const updateTranslations = () => {
    if (brandData == null) return; // nothing to be done

    setAppName(brandData.appName);

    if (!brandData.translations) return;

    // eslint-disable-next-line no-restricted-syntax
    for (const lang of brandData.translations) {
      const resourceUrl = getResourceUrl(BRANDING_RESOURCE.TRANSLATIONS, lang + '.json');
      getAPI(resourceUrl)
        // eslint-disable-next-line no-loop-func
        .then((response) => {
          translationData[lang] = response.data;
          applyTranslation(translationData, lang);
          logger.info('Translation updated for -', lang);
          Utils.localStorage.setItem(THEME_STORAGE_KEY.TRANSLATION,
            translationData);
        })
        .catch((err) => {
          logger.info('Got error in get translation', err);
        });
    }
  };

  /**
   * Performs initializations
   * 1. Setup brand name, title - the basics first
   * 2. Set brand image if one available
   * 3. Setup to get and apply color theme
   * 4. Get the translation files and update on top of default ones.
   */
  const initializeBranding = () => {
    setupBrand();
    setBrandImage();
    setBrandTheme();
    loadCustomCSS();
    updateTranslations();
  };

  /**
   * Validates for mandatory parameters and logically mandatory params
   */
  const validateBrandData = (brandDef) => {
    if (brandDef) {
      if (!brandDef.dataPath) {
        logger.error('Data missing \'dataPath\' attribute');
        // Can not proceed with branding data
        return false;
      }
      if (!brandDef.title) {
        // Good to have, though
        logger.warn('Title attribute not found');
      }
      if (!brandDef.appName) {
        // Good to have, though
        logger.warn('AppName attribute not found');
      }
      return true;
    }
    return false;
  };

  /**
   * Initiates a get for the branding data definition set and if found, gets the data def
   * matching one of the selectors from hostname, domain, queryParam...
   *
   * @param {Array} selectors - the strings used to perform the brand mapping
   */
  const getBrandingData = (selectors) => {
    if (!BRANDING_URL) {
      logger.warn('URL not defined for branding data');
      setBrandData(null);
      return;
    }
    getAPI(getResourceUrl(BRANDING_RESOURCE.DATA_DEFS))
      .then((response) => {
        const bData = response.data?.brandingData?.find((bd) =>
          checkBrand(bd, selectors));
        // Validate data and use if valid , else set to null
        if (validateBrandData(bData)) {
          logger.info(`Using brand ${bData.appName}`,
            `with assets from path: ${bData.dataPath}`);
          setBrandData(bData);
        } else {
          logger.info('No matching brand data for selectors:', JSON.stringify(selectors));
          // Initialize with default
          setBrandData(null);
        }
      })
      .catch((err) => {
        /* We proceed to branding initialization for both - found / not-found cases
        For the case where no branding data found, would lead to default theme initialization
        */
        logger.warn('Error getting branding data', err);
        setBrandData(null);
      });
  };

  /**
   * Picks up theme data from local storage to initialize to
   * avoid initial flicker
   */
  const setInitialTheme = () => {
    const themeData = Utils.localStorage.getItem(THEME_STORAGE_KEY.THEME_DEF);
    if (themeData) { // themedata is in a set of colors, as provided thrugh branding resources
      const theme = getTheme(themeData);
      dispatch(setThemeAction(theme, themeData, themeVersion));
    }

    const brandInfo = Utils.localStorage.getItem(THEME_STORAGE_KEY.BRAND_DATA);
    if (brandInfo) {
      setAppName(brandInfo.brandName);
      dispatch(setBrandAction(brandInfo.brandName, brandInfo.title));
    }

    const brandUrl = Utils.localStorage.getItem(THEME_STORAGE_KEY.LOGO);
    if (brandUrl) {
      dispatch(setBrandUrlAction(brandUrl));
    }
    const resourceUrl = Utils.localStorage.getItem(THEME_STORAGE_KEY.BRAND_RESOURCE_URL);
    if (resourceUrl) {
      dispatch(setBrandResourceUrlAction(resourceUrl));
    }

    translationData = Utils.localStorage.getItem(THEME_STORAGE_KEY.TRANSLATION);
    if (translationData) {
      applyTranslation(translationData);
      logger.debug('Branding::Translations restored from storage for: ', Object.keys(translationData));
    }
    logger.debug('Theme restored from storage for: ', JSON.stringify(brandInfo));
  };

  useEffect(() => {
    logger.debug('UseEffect - ',
      initialized.current ? 'initialized' : 'not initialized');
    if (!initialized.current) {
      // Start of with getting branding definition file
      // Branding data selectors
      const selectors = [];
      selectors.push(window.location.hostname);
      selectors.push(window.location.hostname?.split('.')[0]);
      const themeParam = query?.get(THEME_PARAM);
      if (themeParam) selectors.push(themeParam);
      if (themeParam !== DEFAULT_THEME_NAME) setInitialTheme();
      initialized.current = true;
      getBrandingData(selectors);
    } else {
      logger.debug('Current Brandname', JSON.stringify(currentTheme.brandName));
      initializeBranding();
    }
  }, [brandData]);
  return null;
}

ThemeManager.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  query: PropTypes.object.isRequired,
};

/**
 * Branding data set - for each brand as present in branding.json
 *
 * @typedef {Object} BrandDataDef
 * @property {string|list} name - Used to perform the mapping of branding data with
 * either of hostname,
 * sub-domain i.e. first token of the hostname or optionally value of query param 'theme'.
 * This is a mandatory param for branding to work.
 * @property {string} dataPath - provides an folder name containing the brand data.
 * This is a mandatory parameter.
 * @property {string} comment - optional comment by operator - not used by webapp
 * but for operator reference only.
 * @property {string} title - Used for setting browser window title
 * Optional - default ''
 * @property {string} appName - Name of app
 * Optional - default 'APPNAME' from language files
 * @property {string} logoName - Name of the brand logo file
 * Optional - default is DEFAULT_LOGO
 * @property {string} themeName - Name of color theme definition file
 * Optional - default is DEFAULT_THEME
 * @property {list} translations - List of supported translation languages (
 * this applies on top of the existing bundled translations) e.g. ['en','fr']
 * Optional -
 * The format of the translation file name is <lang>.json - where lang is any of the
 * supported languages.
 * Format of the filename is important
 * Future - Multiple versions of the translation files may be required for multiple
 * webapp versions.
 */
