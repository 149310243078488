// eslint-disable-next-line import/prefer-default-export
export const getAudioInputDeviceList = (state) => state.CallControlReducer.audioInputDeviceList;
export const getAudioOutputDeviceList = (state) => state.CallControlReducer.audioOutputDeviceList;
export const getVideoInputDeviceList = (state) => state.CallControlReducer.videoInputDeviceList;
export const isVideoOn = (state) => state.CallControlReducer.videoOn;
export const isAudioOn = (state) => state.CallControlReducer.audioOn;
// Temporary to get the sip user from query parameter
export const getSipUserName = (state) => state.CallControlReducer.sipUserName;

export const getAudioInputDevice = (state) => state.CallControlReducer.selectedAudioInputDevice;
export const getAudioOutputDevice = (state) => state.CallControlReducer.selectedAudioOutputDevice;
export const getSelectedVideoDevice = (state) => state.CallControlReducer.selectedVideoDevice;
export const getIsIncomingCall = (state) => state.CallControlReducer.isIncomingCall;
export const getShowVideoFallback = (state) => state.CallControlReducer.showVideoFallback;
export const isScreenShareOn = (state) => state.CallControlReducer.screenShareOn;
export const getIsVideoPaused = (state) => state.CallControlReducer.isVideoPaused;

export const getStreamShareState = (state) => state.CallControlReducer.streamShareState;
export const getImageShareState = (state) => state.CallControlReducer.imageShareMode;
export const getWebAppState = (state) => ({
  streamShareState: state.CallControlReducer.streamShareState,
  screenShare: state.CallControlReducer.screenShareOn,
  imageMode: state.CallControlReducer.imageShareMode,
  callInProgress: state.CallControlReducer.callActive,
});
