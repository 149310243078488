/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

// Style
import './CustomForm.scss';

/**
 * @class
 * @component
 */
function CustomForm(props) {
  const { validated, onSubmit, className, autoComplete, formId } = props;

  const formProps = {
    onSubmit,
    validated,
    className,
    autoComplete,
    noValidate: true,
    id: `${formId}-form`,
  };
  return (
    <div className='custom-form' tabIndex='-1'>
      <Form {...formProps} tabIndex='-1'>{props.children}</Form>
    </div>
  );
}

CustomForm.propTypes = {
  onSubmit: PropTypes.func,
  validated: PropTypes.bool,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  formId: PropTypes.string,
};

CustomForm.defaultProps = {
  className: '',
  validated: false,
  autoComplete: 'on',
  onSubmit: () => {},
  formId: '',
};

export default CustomForm;
