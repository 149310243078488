// import axiosHelper from 'SERVICES/AxiosHelper';
import { ACTIONS } from 'UTILS/constants/ActionConstants';
// import { API_METHODS, API_URIS } from '../../utils/constants/ApiConstants';

// will be replaced by personal contact in contact reducer
export function addContactAction(Name, WebRTCAddress) {
  return (dispatch) => {
    dispatch({ type: ACTIONS.ADD_CONTACT, payload: { Name, WebRTCAddress } });
  };
}

export function setParticipantsAction(participants) {
  return (dispatch) => {
    dispatch({
      type: ACTIONS.ADD_PARTICIPANTS,
      participants,
    });
  };
}

export function clearParticipantsAction() {
  return (dispatch) => {
    dispatch({
      type: ACTIONS.CLEAR_PARTICIPANTS,
    });
  };
}

/**
 *
 * @param {object} selectedTelestrationDetails selected telestration details
 *  like color, stroke and item
 * @returns stores selected telestration information into redux
 */
export function setTelestrationIconDetails(selectedTelestrationDetails) {
  return (dispatch) => {
    dispatch({
      type: ACTIONS.SET_SELECTED_ICON,
      selectedTelestrationDetails,
    });
  };
}

export function setActiveIlluminationItemAction(activeIlluminationItem) {
  return (dispatch) => {
    dispatch({
      type: ACTIONS.SET_SELECTED_ILLUMINATION_ITEM,
      activeIlluminationItem,
    });
  };
}

export function setCustomMessageDisable(disable) {
  return (dispatch) => {
    dispatch({
      type: ACTIONS.SET_CUSTOM_MESSAGE_DISABLE,
      disable,
    });
  };
}
