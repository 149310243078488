import { JANUS_ERROR_CODE, JANUS_CONNECTION } from 'UTILS/constants/JanusConstants';
import { IlluminationModeFlags } from 'UTILS/constants/DatastreamConstant';

export const MEDIA_SERVER_CONNECTION = {
  DEFAULT_MEDIA_SERVER_URL: JANUS_CONNECTION.DEFAULT_JANUS_URL,
  OPAQUE_ID_PREFIX: 'webapp-',
  SIP_PLUGIN: JANUS_CONNECTION.JANUS_SIP_PLUGIN,
  SIP: 'sip:',
  SIP_PLUGIN_SECRETE: JANUS_CONNECTION.SIP_PLUGIN_SECRETE,
  // Temporary to get the sipUser from query paramter
  SRINGCT_DOMAIN: '@springct.',
  AUTO_ACCEPT_REINVITE: false,
  TRANSPORT: ';transport=tls',
};

export const MEDIA_TYPE = {
  NOT_SET: null,
  ANY: '',
  VIDEO: 'video',
  AUDIO: 'audio',
  SCREEN: 'screen',
};

export const DEVICE_CAMERA_FACING = {
  FRONT_FACING: 'facing front',
  BACK_FACING: 'facing back',
};

export const CALL_DIRECTION = {
  OUTGOING: 'outgoing',
  INCOMING: 'incoming',
};

export const MEDIA_SERVER_ERROR_CODE = {
  MEDIA_PERMISSION_DENIED: 0,
  SESSION_TERMINATED: 200,
  NOT_FOUND_ERROR: 404,
  REQUEST_TIMEOUT: 408,
  BAD_EXTENSION: 420,
  SIP_ERROR_NO_MESSAGE: JANUS_ERROR_CODE.JANUS_SIP_ERROR_NO_MESSAGE,
  SIP_ERROR_INVALID_JSON: JANUS_ERROR_CODE.JANUS_SIP_ERROR_INVALID_JSON,
  SIP_ERROR_INVALID_REQUEST: JANUS_ERROR_CODE.JANUS_SIP_ERROR_INVALID_REQUEST,
  SIP_ERROR_MISSING_ELEMENT: JANUS_ERROR_CODE.JANUS_SIP_ERROR_INVALID_ELEMENT,
  SIP_ERROR_INVALID_ELEMENT: JANUS_ERROR_CODE.JANUS_SIP_ERROR_INVALID_ELEMENT,
  SIP_ERROR_ALREADY_REGISTERED: JANUS_ERROR_CODE.JANUS_SIP_ERROR_ALREADY_REGISTERED,
  SIP_ERROR_INVALID_ADDRESS: JANUS_ERROR_CODE.JANUS_SIP_ERROR_INVALID_ADDRESS,
  SIP_ERROR_WRONG_STATE: JANUS_ERROR_CODE.JANUS_SIP_ERROR_WRONG_STATE,
  SIP_ERROR_MISSING_SDP: JANUS_ERROR_CODE.JANUS_SIP_ERROR_MISSING_SDP,
  SIP_ERROR_LIBSOFIA_ERROR: JANUS_ERROR_CODE.JANUS_SIP_ERROR_LIBSOFIA_ERROR,
  SIP_ERROR_IO_ERROR: JANUS_ERROR_CODE.JANUS_SIP_ERROR_IO_ERROR,
  SIP_ERROR_RECORDING_ERROR: JANUS_ERROR_CODE.JANUS_SIP_ERROR_RECORDING_ERROR,
  SIP_ERROR_TOO_STRICT: JANUS_ERROR_CODE.JANUS_SIP_ERROR_TOO_STRICT,
  SIP_ERROR_HELPER_ERROR: JANUS_ERROR_CODE.JANUS_SIP_ERROR_HELPER_ERROR,
  SIP_ERROR_NO_SUCH_CALLID: JANUS_ERROR_CODE.JANUS_SIP_ERROR_NO_SUCH_CALLID,
  CLIENT_NOT_AVAILABLE: 478,
  SELF_CALLING: 479,
  CALLEE_BUSY: 486,
  CALLEE_UNAVAILABLE: 480,
  REQUEST_TERMINATED: 487,
  SIP_UNKNOWN_ERROR: JANUS_ERROR_CODE.JANUS_SIP_UNKNOWN_ERROR,
  INTERNAL_SERVER_ERROR: 500,
  DNS_ERROR: 503,
  CALL_DECLINED: 603,
};

export const EXCLUDED_VIDEO_SOURCES = [
  '', // Under observation
  'Logi Capture',
];

export const EXCLUDED_DEVICE_IDS = ['default', 'communications'];

export const DEVICE_NOT_FOUND = 'Requested device not found';

export const SUPPORTED_CODECS = {
  VIDEO: [
    'vp8/90000',
    'vp9/90000',
    'h264/90000',
    'rtx/90000',
  ],
};

export const TIMER_CONSTANTS = {
  INITIAL_DELAY: 1000,
  MAX_RECONNECTION_ATTEMPTS: 5,
  UPDATES_CHECK_MILLISECONDS: 600000,
  REINIT_START_DELAY: 5 * 1000,
  DATASTREAM_HANGUP_TIMEOUT: 3000,
  MEDIA_PATH_GUARD_TIMEOUT: 20 * 1000, /* 20s */
  EVENT_LOOP_BREAKER: 1 * 100,
  WEBRTC_TOKEN_MIN_UPDATE_REQUEST_PERIOD: 30,
  FETCH_UPDATED_CONTACTS: 300000, /* 5 mins */
};
export const CAMERA_FACING_MODE = {
  USER: 'user',
  ENVIRONMENT: 'environment',
  LEFT: 'left',
  RIGHT: 'right',
};
export const MEDIA_SERVER_NO_SESSION = 'No such session';
export const ICE_STATE = {
  NEW: 'new',
  CONNECTING: 'connecting',
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
  FAILED: 'failed',
  CLOSED: 'closed',
};

export const VIDEO_PROFILES = [
  { label: 'Low', value: 'Low' },
  { label: 'Medium', value: 'Medium' },
  { label: 'High', value: 'High' },
  { label: '720p', value: '720p' },
  { label: '1080p', value: '1080p' },
];

export const MEDIA_QUALITY = [
  'SQLow',
  'SQMed',
  'SQHigh',
  'SQCustom',
  'SQHD',
  'SQFULLHD',
];

export const VIDEO_CONFIGS_FIELDS = {
  ASS_ENABLED: 'VideoAdaptiveStreamEnabled',
  ASS_FACTOR: 'VideoAdaptiveStreamBitRateAdjustmentFactor',
  ASS_MINIMUM_BITRATE: 'VideoAdaptiveStreamMinimumBitRate',
  GOP_SIZE: 'VideoGOP',
  WIDTH: 'VideoWidth',
  HEIGHT: 'VideoHeight',
  SOFT_START_ENABLED: 'VideoSoftStartEnabled',
  FRAME_RATE: 'VideoFrameRate',
  PEAK_BITRATE: 'VideoPeakBitRate',
  STREAM_QUALITY: 'StreamQuality',
  CODEC: 'VideoCodec',
  BITRATE: 'VideoTargetBitRate',
};

export const AUDIO_CONFIG_RESOLUTION_CONVERTED = 16;
export const AUDIO_CONFIG_SAMPLE_RATE_CONVERTED = 8000;
export const AUDIO_CONFIG_RESOLUTION = 'ar16bit';
export const AUDIO_CONFIG_SAMPLE_RATE = 'asr8kHz';
export const AUDIO_SOURCE = 1;
export const AUDIO_CHANNEL = 1;
export const AUDIO_CODEC = 1;

export const VIDEO_ASS_ENABLED = false;
export const VIDEO_ASS_FACTOR = 1;
export const VIDEO_CODEC = 65;
export const VIDEO_ASS_MINIMUM_BITRATE = 1;
export const VIDEO_MEDIA_CONTENTS = 1;
export const VIDEO_SEQ_MODE = 2;
export const VIDEO_AUTO_SKIP = false;
export const VIDEO_SOFT_START_ENABLED = false;

export const AUDIO_CONFIGS_FIELDS = {
  AUDIO_SOURCE: 'AudioSource',
  BITRATE: 'AudioBitRate',
  CHANNELS: 'AudioSeperation',
  CODEC: 'AudioCodec',
  GAIN_PERCENT: 'AudioGain',
  RESOLUTION: 'AudioSampleSize',
  STREAM_QUALITY: 'StreamQuality',
  AUDIO_SAMPLE_RATE: 'AudioSampleRate',
};

/* eslint-disable max-len */
export const VIDEO_MEDIA_CONFIGS = [
  { assEnabled: false, assFactor: 1, assMinimumBitRate: 1, autoSkip: false, bitRate: 250000, codec: 65, frameRate: 10000, gopSize: 5, height: 240, mediaContents: 1, peakBitRate: 300000, seqMode: 2, softStartEnabled: false, streamQuality: 0, width: 320 },
  { assEnabled: false, assFactor: 1, assMinimumBitRate: 1, autoSkip: false, bitRate: 400000, codec: 65, frameRate: 10000, gopSize: 5, height: 368, mediaContents: 1, peakBitRate: 500000, seqMode: 2, softStartEnabled: false, streamQuality: 1, width: 528 },
  { assEnabled: false, assFactor: 1, assMinimumBitRate: 1, autoSkip: false, bitRate: 1000000, codec: 65, frameRate: 10000, gopSize: 5, height: 480, mediaContents: 1, peakBitRate: 1200000, seqMode: 2, softStartEnabled: false, streamQuality: 2, width: 720 },
  // { assEnabled: false, assFactor: 1, assMinimumBitRate: 1, autoSkip: false, bitRate: 1500000, codec: 65, frameRate: 15000, gopSize: 5, height: 480, mediaContents: 1, peakBitRate: 1700000, seqMode: 2, softStartEnabled: false, streamQuality: 3, width: 720 },
  { assEnabled: false, assFactor: 1, assMinimumBitRate: 1, autoSkip: false, bitRate: 1500000, codec: 65, frameRate: 30000, gopSize: 10, height: 480, mediaContents: 1, peakBitRate: 1700000, seqMode: 2, softStartEnabled: false, streamQuality: 3, width: 640 },
  { assEnabled: false, assFactor: 1, assMinimumBitRate: 1, autoSkip: false, bitRate: 1000000, codec: 65, frameRate: 10000, gopSize: 10, height: 720, mediaContents: 1, peakBitRate: 1200000, seqMode: 2, softStartEnabled: false, streamQuality: 4, width: 1280 },
  { assEnabled: false, assFactor: 1, assMinimumBitRate: 1, autoSkip: false, bitRate: 1500000, codec: 65, frameRate: 10000, gopSize: 10, height: 1080, mediaContents: 1, peakBitRate: 1700000, seqMode: 2, softStartEnabled: false, streamQuality: 5, width: 1920 },
  // { assEnabled: false, assFactor: 1, assMinimumBitRate: 1, autoSkip: false, bitRate: 1500000, codec: 65, frameRate: 30000, gopSize: 10, height: 480, mediaContents: 1, peakBitRate: 1700000, seqMode: 2, softStartEnabled: false, streamQuality: 5, width: 640 },
];

export const AUDIO_MEDIA_CONFIGS = [
  { audioSource: 1, bitRate: 128000, channels: 1, codec: 1, gainPercent: 20, resolution: 16, sampleRate: 8000, streamQuality: 0 },
  { audioSource: 1, bitRate: 128000, channels: 1, codec: 1, gainPercent: 20, resolution: 16, sampleRate: 8000, streamQuality: 1 },
  { audioSource: 1, bitRate: 128000, channels: 1, codec: 1, gainPercent: 20, resolution: 16, sampleRate: 8000, streamQuality: 2 },
  { audioSource: 1, bitRate: 128000, channels: 1, codec: 1, gainPercent: 20, resolution: 16, sampleRate: 8000, streamQuality: 3 },
  { audioSource: 1, bitRate: 128000, channels: 1, codec: 1, gainPercent: 20, resolution: 16, sampleRate: 8000, streamQuality: 4 },
  { audioSource: 1, bitRate: 128000, channels: 1, codec: 1, gainPercent: 20, resolution: 16, sampleRate: 8000, streamQuality: 5 },
  // { audioSource: 1, bitRate: 128000, channels: 1, codec: 1, gainPercent: 20, resolution: 16, sampleRate: 8000, streamQuality: 6 },
];

export const SS_MEDIA_CONFIG = { name: 'Screen Share', assEnabled: false, assFactor: 1, assMinimumBitRate: 1, autoSkip: false, bitRate: 1000000, codec: 65, frameRate: 5000, gopSize: 10, height: 1080, mediaContents: 1, peakBitRate: 1000000, seqMode: 2, softStartEnabled: false, streamQuality: 3, width: 1920 };
/* eslint-enable max-len */

export const DEFAULT_MEDIA_CONFIG = { value: 0, name: 'SQLow' };

export const CALL_ERROR = {
  ...MEDIA_SERVER_ERROR_CODE,
  /* Add error codes specifically identified in CallManager
  Should be high enough than Media Server error codes */
  // Session specific errors
  INIT_ERR: 1001,
  NETWORK_ERR: 1002,
  REG_FAILED: 1003,
  SESSION_DISCONNECTED: 1004,
  SESSION_RECONNECTED: 1005, /* Not a error */
  MAX_SESSION_ERROR: 2000,
  // Session specific errors end above

  // Call signalling terminal errors

  /* Call declined by either parties */
  // CALL_DECLINED: 2001,
  /* Call Cancelled by either parties */
  CALL_CANCELLED: 2002,
  /* Call end from Media Server hangup */
  CALL_ENDED: 2003,
  /* Error detected from DS through network close */
  CALL_DISCONNECT_ERR: 2004,
  /* No response / timeout */
  NO_RESPONSE: 2005,
  AUDIO_PERMISSION_DENIED: 2011,
  GET_USER_MEDIA_API_EXCEPTION: 2012,
  CALL_NO_ANSWER: 2013,

  TERMINAL_ERROR_LAST: 2050,
  /* non-terminal errors */
  CAMERA_PERMISSION_DENIED: 2050,
  MEDIA_PATH_DOWN: 2051,
  CAMERA_ERROR: 2052,
  SCREEN_SHARE_DECLINED: 2053,
  MAX_CALL_ERROR: 2999,
  // Call specific errors end above

  NONE: 3000,
};

export const MEDIA_SERVER_FSM_STATE = {
  NOT_READY: 'not_ready',
  INITIALIZING: 'initializing',
  CONNECTING: 'connecting',
  CONNECTED: 'connected',
  ATTACHING: 'attaching',
  ATTACHED: 'attached',
  REGISTERING: 'registering',
  RECONNECTING: 'reconnecting',
  REINITING: 'reiniting',
  CALL_DISCONNECTING: 'disconnecting',
  DISCONNECTED: 'disconnected',
  DESTROYING: 'destroying',
  READY: 'ready',
};

/* Call states */
export const MEDIA_SERVER_CALL_STATE = {
  CALL_IDLE: 'idle', // Initial
  CALL_INITIATED: 'callInitiated',
  CALL_IN_ACCEPTING: 'callInAccepting',
  CALL_IN_RCVD: 'callInRcvd',
  CALL_IN_PROGRESS: 'inProgress',
  CALL_DISCONNECTING: 'disconnecting',
  CALL_DISCONNECTED: 'disconnected',
  CALL_HANGINGUP: 'hangingup',
  CALL_HUNGUP: 'hungup',
};

export const WEB_CALL_EVENT = {
  ON_CONSENT_DIALOG: 'onConsentDialog',
  CALL_INITIATED: 'onCallInitiated',
  CALL_INCOMING: 'onIncomingCall',
  CALL_MISSED: 'onMissedCall',
  CALL_ACCEPTED: 'onCallAccepted',
  HANGUP: 'onHangup',
  DATA_RCVD: 'onData',
  ERROR: 'onError',
  SESSION_EVENT: 'onSessionEvent',
  MEDIA_PATH_UPDATE: 'onMediaPathChange',
  CALL_DISCONNECTED: 'onCallDisconnect',
  RECEIVED_LOCAL_PID: 'receivedLocalPID',
  DATA_STREAM_HANGUP_MSG_RECV: 'datastreamHangupMessageReceived',
};

export const WEB_CALL_STATE = {
  NONE: 'none',
  ...MEDIA_SERVER_CALL_STATE, // Call specific states
  /* Session specific states */
  INITIALIZING: 'initializing',
  INITIALIZED: 'initialized',
  RECONNECTING: 'reconnecting',
  RECONNECTED: 'reconnected',
  DISCONNECTED: 'disconnected', // Special when session is disconnecting
};

export const MEDIA_ACTION = {
  DEVICE_CHANGE: 'onMediaDeviceChange',
  LOCAL_STREAM_RCVD: 'onLocalStreamReceived',
  REMOTE_STREAM_RCVD: 'onRemoteStreamReceived',
  STREAM_FREEZE: 'onStreamFreeze',
  SCREEN_SHARE_STOPPED: 'onScreenShareStopped',
  SCREEN_SHARE_ERROR: 'handleErrorForScreenSharing',
  RESET_DEVICE_ON_DEVICE_CHANGE_FAIL: 'resetSelectedDeviceOnDeviceChangeFailure',
  CONFIG_UPDATE: 'onConfigUpdate',
  STREAM_QUALITY_CHANGE: 'onStreamQualityChange',
  SCREEN_SHARE_TOGGLED: 'onScreenShareToggle',
};

export const VIDEO_MEDIA_PROFILE = {
  LOW: 0,
  MEDIUM: 1,
  HIGH: 2,
  CUSTOM: 3,
  HD: 4,
  FHD: 5,
  FROM_OTHER_PARTICIPANT: 6,
};

// GUM supports max 4k res
export const MAX_CAMERA_RES = {
  WIDTH: 1920 * 2,
  HEIGHT: 1080 * 2,
};

export const MEDIA_PATH_STATE = {
  DOWN: -1,
  UNKNOWN: 0,
  UP: 1,
};

export const MEDIA_SERVER_HANGUP_REASON = {
  NO_RESPONSE: {
    reason: 'Request Timeout',
    code: MEDIA_SERVER_ERROR_CODE.REQUEST_TIMEOUT,
    error: CALL_ERROR.NO_RESPONSE,
  },
  CALL_ENDED: {
    reason: ['Session Terminated', 'to BYE'],
    code: MEDIA_SERVER_ERROR_CODE.SESSION_TERMINATED,
    error: CALL_ERROR.CALL_ENDED,
  },
  ENDED_BY_SELF: {
    reason: 'to BYE',
    code: MEDIA_SERVER_ERROR_CODE.SESSION_TERMINATED,
    error: CALL_ERROR.NONE,
  },
  NOT_FOUND: {
    reason: 'Not Found',
    code: MEDIA_SERVER_ERROR_CODE.NOT_FOUND_ERROR,
    error: CALL_ERROR.NOT_FOUND_ERROR,
  },
  CALL_CANCELLED: {
    reason: 'Request Terminated',
    code: MEDIA_SERVER_ERROR_CODE.REQUEST_TERMINATED,
    error: CALL_ERROR.CALL_CANCELLED,
    reasonToErrMap: {
      ORIGINATOR_CANCEL: CALL_ERROR.CALL_CANCELLED,
      NO_ANSWER: CALL_ERROR.CALL_NO_ANSWER,
    },
  },
  CALL_DECLINED: {
    reason: 'Decline',
    code: MEDIA_SERVER_ERROR_CODE.CALL_DECLINED,
    error: CALL_ERROR.CALL_DECLINED,
  },
  CALLEE_UNAVAILABLE: {
    reason: 'Temporarily Unavailable',
    code: MEDIA_SERVER_ERROR_CODE.CALLEE_UNAVAILABLE,
    error: CALL_ERROR.NOT_FOUND_ERROR,
  },
  CALLEE_BUSY: {
    reason: 'Busy Here',
    code: MEDIA_SERVER_ERROR_CODE.CALLEE_BUSY,
    error: 0,
  },
};

export const JPEG_COMPRESSION_QUALITY_DEFAULT = 80;

export const GUM_EXCEPTION = {
  ABORT_ERROR: 'AbortError',
  NOT_ALLOWED_ERROR: 'NotAllowedError',
  NOT_FOUND_ERROR: 'NotFoundError',
  NOT_READABLE_ERROR: 'NotReadableError',
  OVER_CONSTRAINED_ERROR: 'OverconstrainedError',
  SECURITY_ERROR: 'SecurityError',
  TYPE_ERROR: 'TypeError',
};

export const VIDEO_CONTENT_HINT = {
  MOTION: 'motion',
  DETAIL: 'detail',
  TEXT: 'text',
};

export const AUDIO_CONTENT_HINT = {
  SPEECH: 'speech',
  SPEECH_RECOGNITION: 'speech-recognition',
  MUSIC: 'music',
};

export const SCREEN_SHARE_DEVICE = { deviceId: 'screenShare', label: 'Screen', kind: 'videoinput' };

export const MEDIA_DEVICE_TYPE = {
  AUDIO_INPUT: 'audioinput',
  VIDEO_INPUT: 'videoinput',
  AUDIO_OUTPUT: 'audiooutput',
};

export const CANVAS_MAX_PIXEL_LIMIT = 16777216; // current limit

export const VIDEO_LOW_RES_DEFAULTS = {
  width: 320,
  height: 240,
};

export const IMAGE_MIME_TYPE = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
};

export const IMAGE_CAPTURE_ENCODER_OPTION = 0.1;

export const FILL_LIGHT_MODE = {
  ON: 'on',
  AUTO: 'auto',
  OFF: 'off',
  FLASH: 'flash',
};

export const DEFAULT_MEDIA_ID = 'default';

export const DEFAULT_ZOOM_LEVEL = 1;

export const DEFAULT_ZOOM_INFO = {
  levelDigitalMax: 30, levelMin: 10, levelOpticalMax: 30, rateMax: 7, rateMin: 0,
};

export const SCREEN_SHARE_STATE = {
  STOPPED: 0,
  ABOUT_TO_START: 1,
  STARTED: 2,
  ABOUT_TO_STOP: 3,
};

export const SCREEN_SHARE_ON = true;

export const GUM_CONSTRAINTS_KEY = {
  ADVANCED: 'advanced',
};

export const MEDIA_SERVER_EVENT = {
  SERVER_CONNECTED: 'SERVER_CONNECTED',
  SERVER_CONN_DESTROYED: 'SERVER_CONN_DESTROYED',
  SIP_SESSION_ATTACHED: 'SIP_SESSION_ATTACHED',
  ON_CONSENT_DIALOG: 'ON_CONSENT_DIALOG',
  ON_WEBRTC_STATE_DOWN: 'ON_WEBRTC_STATE_DOWN',
  REGISTATION_FAILED: 'REGISTATION_FAILED',
  REGISTERED: 'REGISTERED',
  CALLING: 'CALLING',
  INCOMING_CALL: 'INCOMING_CALL',
  MISSED_CALL: 'MISSED_CALL',
  CALL_ACCEPTED: 'CALL_ACCEPTED',
  CALL_HANGUP: 'CALL_HANGUP',
  CALL_HANGUP_BY_SERVER: 'CALL_HANGUP_BY_SERVER',
  ON_LOCAL_STREAM: 'ON_LOCAL_STREAM',
  ON_REMOTE_STREAM: 'ON_REMOTE_STREAM',
  ON_DATA_CLOSE: 'ON_DATA_CLOSE',
  ON_DATA: 'ON_DATA',
  ON_WEBRTC_PEER_CLEANUP: 'ON_WEBRTC_PEER_CLEANUP',
  SERVER_SESSION_RECONNECTED: 'SERVER_SESSION_RECONNECTED',
  REINIT_SERVER_SESSION: 'REINIT_SERVER_SESSION',
  CALL_ACCEPTED_BY_REMOTE: 'CALL_ACCEPTED_BY_REMOTE',
  RECEIVED_UID: 'RECEIVED_UID',
};

export const MEDIA_SERVER_ERROR_EVENT = {
  SERVER_CONNECTION_FAILED: 'SERVER_CONNECTION_FAILED',
  SIP_SESSION_ATTACH_FAILED: 'SIP_SESSION_ATTACH_FAILED',
  SIP_SESSION_ERROR: 'SIP_SESSION_ERROR',
  REMOTE_JSEP_ACCEPTANCE_ERROR: 'REMOTE_JSEP_ACCEPTANCE_ERROR',
  SIP_UPDATE_ERROR: 'SIP_UPDATE_ERROR',
  SERVER_SESSION_RECONNECT_ERROR: 'SERVER_SESSION_RECONNECT_ERROR',
  MAKE_CALL_FAILED: 'MAKE_CALL_FAILED',
  CALL_ACCEPT_FAILED: 'CALL_ACCEPT_FAILED',
};

export const TRANSCEIVER_TYPE = {
  SENDER: 'SENDER',
  RECEIVER: 'RECEIVER',
  BOTH: 'BOTH',
};

export const DEFAULT_ILLUM_INFO = {
  levelMax: 0,
  levelMin: 0,
  perVideoSourceIllumInfo: [
    {
      levelMax: 0,
      levelMin: 0,
      sourceName: 'default',
      stillImageSupportedModes: IlluminationModeFlags.IllumModeOff,
      videoSupportedModes: IlluminationModeFlags.IllumModeOff,
    },
  ],
};

export const FF_CAMERA_BUSY_MSG = 'Starting videoinput failed';

export const IMG_CAP_LANDSCAPE_ASP_RATIO = 4 / 3;
