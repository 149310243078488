export const JANUS_ERROR_CODE = {
  // ERRORS - from janus_sip.c
  JANUS_SIP_ERROR_NO_MESSAGE: 440,
  JANUS_SIP_ERROR_INVALID_JSON: 441,
  JANUS_SIP_ERROR_INVALID_REQUEST: 442,
  JANUS_SIP_ERROR_MISSING_ELEMENT: 443,
  JANUS_SIP_ERROR_INVALID_ELEMENT: 444,
  JANUS_SIP_ERROR_ALREADY_REGISTERED: 445,
  JANUS_SIP_ERROR_INVALID_ADDRESS: 446,
  JANUS_SIP_ERROR_WRONG_STATE: 447,
  JANUS_SIP_ERROR_MISSING_SDP: 448,
  JANUS_SIP_ERROR_LIBSOFIA_ERROR: 449,
  JANUS_SIP_ERROR_IO_ERROR: 450,
  JANUS_SIP_ERROR_RECORDING_ERROR: 451,
  JANUS_SIP_ERROR_TOO_STRICT: 452,
  JANUS_SIP_ERROR_HELPER_ERROR: 453,
  JANUS_SIP_ERROR_NO_SUCH_CALLID: 454,
  JANUS_SIP_UNKNOWN_ERROR: 499,
};

export const JANUS_CONNECTION = {
  DEFAULT_JANUS_URL: '/janus',
  JANUS_SIP_PLUGIN: 'janus.plugin.sip',
  SIP_PLUGIN_SECRETE: 'YGhfgos0yURX7EyYbuWQ',
};

export const JANUS_MESSAGE_KEYS = {
  REQUESTS: {
    REGISTER: 'register',
    ACCEPT: 'accept',
    SET: 'set',
    CALL: 'call',
    HANGUP: 'hangup',
    UPDATE: 'update',
    DECLINE: 'decline',
  },
  EVENTS: {
    REGISTERING: 'registering',
    REGISTERED: 'registered',
    REGISTATION_FAILED: 'registration_failed',
    CALLING: 'calling',
    ACCEPTING: 'accepting',
    PROGRESS: 'progress',
    INCOMING_CALL: 'incomingcall',
    MISSED_CALL: 'missed_call',
    ACCEPTED: 'accepted',
    UPDATING_CALL: 'updatingcall',
    MESSAGE: 'message',
    INFO: 'info',
    NOTIFY: 'notify',
    TRANSFER: 'transfer',
    HANGUP: 'hangup',
    RINGING: 'ringing',
    PROCEEDING: 'proceeding',
    HANGINGUP: 'hangingup',
    DECLINING: 'declining',
    UPDATED: 'updated',
  },
  RESULT: 'result',
  LIST: 'list',
  EVENT: 'event',
  USERNAME: 'username',
  CALLER: 'caller',
  ANSWER: 'answer',
  REASON: 'reason',
  REASON_HEADER: 'reason_header',
  DISPLAY_NAME: 'displayname',
  SENDER: 'sender',
  NOTIFY: 'notify',
  CONTENT: 'content',
  REFFER_TO: 'refer_to',
  REFFER_BY: 'referred_by',
  UNKNOWN_PARTY: 'an unknown party',
  REFER_ID: 'refer_id',
  REPLACE: 'replaces',
  HANGUP_CODE: 'code',
  // LS_DATASTREAM
  LSDS_PID: 'lsds_pid',
  SRTP: 'srtp',
  CALL_ID: 'call_id',
  ERROR: 'error',
};
