/** DOM element ids used across the app */
export const ROOT = {
  HEAD: 'head',
  SCRIPT_TAG: 'script',
};

export const CALL_DASHBOARD = {
  REMOTE_VIDEO_ID: 'remoteVideo',
  LOCAL_VIDEO_ID: 'localVideo',
  SELF_VIDEO_ID: 'selfVideo',
  REMOTE_AUDIO_ID: 'remoteAudio',
  CANVAS_ID: 'canvas',
  TELESTRATION_CANVAS_ID: 'canvas_for_telestration',
  TELESTRATION_CANVAS_ID_IMG: 'canvas_for_img_telestration',
  PHOTO_ID: 'photo',
  TELE_TEXT_INPUT: 'tele-text',
  VIDEO_CANVAS_ID: 'video-canvas',
  CANVAS_FOR_SCALE_UP: 'scale-up-canvas',
};

export const LOGIN = {
  FORM_ID: 'login',
  EMAIL_ID: 'email',
  PASSWORD_ID: 'password',
};

export const GUEST_LOGIN = {
  FORM_ID: 'guest-login',
  NAME_ID: 'guest-name',
  EMAILADDR_ID: 'guest-email',
};

export const GUEST_INVITE = {
  POPUP_ID: 'guest-invite-success',
  EMAIL_FIELD: 'guestInvite-email',
  PHONE_FIELD: 'guestInvite-phone',
  EXPIRY_TIME_FIELD: 'expiry',
};
export const SSO_LOGIN = {
  FORM_ID: 'sso-login',
};
export const CUSTOM_MESSAGE = {
  POPUP_ID: 'custom-message',
};

export const LOCAL_STORAGE_KEY = {
  CUSTOM_MESSAGE: 'customMessage',
  CALL_HISTORY_RECORD: 'CallHistory',
  NLP_DATA: 'nlpData',
  IV: '_iv',
  LOGIN_OPTION: 'loginOption',
  TS_COLOR: 'tsColor',
  SSO_DOMAIN: 'ssoDomain',
};
