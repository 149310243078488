/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Row, Col, Form } from 'react-bootstrap';

// Translation
import { translate } from 'SERVICES/i18n';

// Custom component
import TextFieldComponent from 'COMPONENTS/CustomComponents/TextFieldComponent/TextFieldComponent';
import CustomForm from 'COMPONENTS/CustomComponents/CustomForm/CustomForm';

import { isDesktop, isMobileOnly, isTablet } from 'react-device-detect';
// Style
import './Settings.scss';

// component
import Sidebar from 'COMPONENTS/Dashboard/Sidebar';
// Asset
import { ReactComponent as BackIcon } from 'ASSETS/icon_back_arrow.svg';

export default class MyProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  renderContent = () => {
    const profile = (this.props && this.props.data) ?
      this.props.data.userData :
      this.props.userData;
    const sipData = (this.props && this.props.data) ?
      this.props.data.clientSettingsData :
      this.props.clientSettingsData;
    return (
      <Row className={`${isMobileOnly && 'landscape-side-margins'} p-2`}>
        <CustomForm
          formId='myProfile'
        >
          <Form.Group className={`${isMobileOnly ? 'mb-4 mt-3' : 'mb-2'}`}>
            <Form.Label className='form-label mx-2 mb-1'>
              <span className='p-0 font-regular'>
                {translate('settings.username')}
              </span>
            </Form.Label>
            <Row className='pl-2 px-2'>
              <TextFieldComponent
                type='text'
                id='username'
                value={profile?.username}
                placeholder=''
                maxLength={100}
                className={`profile-fields field disabled-field ${isMobileOnly ? 'mob-profile-textbox' : 'textbox-profile'}`}
                disabled
              />
            </Row>
          </Form.Group>

          <Form.Group className={`${isMobileOnly ? 'mb-4' : 'mb-2'}`}>
            <Form.Label className='form-label mx-2 mb-1'>
              <span className='p-0 font-regular'>
                {translate('settings.firstname')}
              </span>
            </Form.Label>
            <Row className='px-2'>
              <TextFieldComponent
                type='text'
                id='firstname'
                value={profile?.firstname}
                placeholder=''
                maxLength={100}
                className={`profile-fields field disabled-field ${isMobileOnly ? 'mob-profile-textbox' : 'textbox-profile'}`}
                disabled
              />
            </Row>
          </Form.Group>

          <Form.Group className={`${isMobileOnly ? 'mb-4' : 'mb-2'}`}>
            <Form.Label className='form-label mx-2 mb-1'>
              <span className='p-0 font-regular'>
                {translate('settings.lastname')}
              </span>
            </Form.Label>
            <Row className='px-2'>
              <TextFieldComponent
                type='text'
                id='lastname'
                value={profile?.lastname}
                placeholder=''
                maxLength={100}
                className={`profile-fields field disabled-field ${isMobileOnly ? 'mob-profile-textbox' : 'textbox-profile'}`}
                disabled
              />
            </Row>
          </Form.Group>

          <Form.Group className='pt-3 pb-1'>
            <Row>
              <Form.Label className='mx-2'>
                <span className='p-0 uri-header'>
                  {translate('settings.sip')}
                </span>
              </Form.Label>
            </Row>
          </Form.Group>

          <Form.Group className='mb-1'>
            <Row>
              <Col md={!isMobileOnly && 4} sm={isTablet && 3}>
                <Form.Label className='form-label mx-2'>
                  <span className='p-0 font-regular'>
                    {translate('settings.serveraddress')}
                  </span>
                </Form.Label>
              </Col>
              <Col md={!isMobileOnly && 8} sm={isTablet && 9} tabIndex='-1' className={`${!isMobileOnly ? 'p-0 align-start' : 'align-right'}`}>
                <Form.Label className={`${isTablet && 'tab-uri'} form-label mx-2`}>
                  <span className='p-0 font-regular'>
                    {sipData?.sip_registrar}
                  </span>
                </Form.Label>
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className='mb-2'>
            <Row className={`${isMobileOnly && 'pb-4'}`}>
              <Col md={!isMobileOnly && 4} sm={isTablet ? 3 : 2} xs={1}>
                <Form.Label className='form-label mx-2'>
                  <span className='p-0 font-regular'>
                    {translate('settings.uri')}
                  </span>
                </Form.Label>
              </Col>
              <Col md={!isMobileOnly && 8} sm={isTablet ? 9 : 10} xs={11} className={`${!isMobileOnly ? 'align-start p-0' : 'align-right'}`}>
                <Form.Label className='form-label mx-2'>
                  <p className={`p-0 font-regular ellipsis ${isDesktop ? ' uri-width-desktop' : (isMobileOnly ? 'uri-mobile' : 'uri-width')}`} title={profile?.uri}>
                    {profile?.uri}
                  </p>
                </Form.Label>
              </Col>
            </Row>
          </Form.Group>
        </CustomForm>
      </Row>
    );
  }

  render() {
    return (
      <div className={`my-profile h-100 ${this.props.isCallDashboard ? 'position-absolute' : ''}`}>
        {(isMobileOnly) ?
          (
            <Sidebar>
              <Row lg={8} md={8} sm={8} xs={8} className='px-3 landscape-side-margins pt-2'>
                <Col className='d-lg-none d-xl-none' xs={1} sm={1} lg={1} md={1}>
                  <BackIcon
                    className='back-icon'
                    alt='back'
                    role='presentation'
                    onClick={this.props.goBack}
                  />
                </Col>
                <Col className='alignment'>
                  <span className='header-label ps-1'>
                    {translate('settings.myProfile')}
                  </span>
                </Col>
              </Row>
              <Row className='px-2'>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className='divider' />
                </Col>
              </Row>
              <div className='sidebar-scroll'>
                {this.renderContent()}
              </div>
            </Sidebar>
          )
          : (
            /** WEB VIEW */
            <div>
              {this.renderContent()}
            </div>
          )
        }
      </div>
    );
  }
}
