import { themeColors } from 'STYLES/Branding/themeColors';
import { ACTIONS } from 'UTILS/constants/ActionConstants';
import getTheme from 'STYLES/Branding/themes';

const INITIAL_STATE = {
  theme: getTheme(themeColors),
  brandName: null,
  brandUrl: null,
  title: null,
  brandResourceUrl: null,
};

// eslint-disable-next-line func-names
export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case ACTIONS.SET_THEME:
      return { ...state, theme: action.theme };
    case ACTIONS.CLEAR_THEME:
      return { ...state, theme: INITIAL_STATE.theme };
    case ACTIONS.SET_BRAND:
      return {
        ...state,
        brandName: action.brandName,
        title: action.title,
      };
    case ACTIONS.SET_BRAND_URL:
      return { ...state, brandUrl: action.brandUrl };
    case ACTIONS.SET_BRAND_RESOURCE_URL:
      return { ...state, resourceUrl: action.resourceUrl };
    default:
      return state;
  }
}
