import Popup from 'reactjs-popup';
import React from 'react';
import { ReactComponent as FilterIcon } from 'ASSETS/icon_filter.svg';
import './DocumentFilters.scss';
import PropTypes from 'prop-types';
import { Button, Col } from 'react-bootstrap';

// Translation
import { translate } from 'SERVICES/i18n';
import FilterSelect from './FilterSelect';

class DocumentFilters extends React.Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
    this.state = {
      entries: this.props.config,
    };
  }

  onUpdate(groupIndex, entryIndex, value) {
    this.setState((previousState) => {
      if (value === undefined) {
        const item = previousState.entries[groupIndex].entries[entryIndex];
        item.value = !item.value;
        previousState.entries[groupIndex].used = previousState
          .entries[groupIndex].entries.some((e) => e.value);
      } else {
        const item = previousState.entries[groupIndex];
        item.value = value;
        previousState.entries[groupIndex].used = value.length > 0;
      }
      return { entries: previousState.entries };
    });
  }

  close = () => this.ref.current.close();

  render() {
    return (
      <Popup
        ref={this.ref}
        trigger={(<FilterIcon className='icon filter-icon' />)}
        position='left top'
        on='click'
        closeOnDocumentClick
        mouseLeaveDelay={300}
        mouseEnterDelay={0}
        onClose={() => this.props.onApply(this.state.entries)}
        arrow={false}
      >
        <Col className='menu'>
          <h3>{ translate('search.filter')}</h3>
          {this.state.entries.map((entry) => {
            const groupIndex = this.state.entries.indexOf(entry);
            return (entry.entries !== undefined)
              ? (
                <div key={entry.name} className='filter-group'>
                  <p>{entry.name}</p>
                  <div>
                    {entry.entries.map((item) => {
                      const itemIndex = entry.entries.indexOf(item);
                      return (
                        <div key={item.name} className='checkbox tab'>
                          <input type='checkbox' checked={item.value} onChange={() => { this.onUpdate(groupIndex, itemIndex); }} />
                          <p>{item.name}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )
              : (
                <div key={entry.name} className='filter-group'>
                  <FilterSelect
                    onChange={(selected) => this.onUpdate(groupIndex, undefined, selected)}
                    selected={entry.value}
                    key={entry.name}
                    placeholder={entry.name}
                    suggest={entry.suggestion}
                  />
                </div>
              );
          })}

          <div className='buttons'>
            <Button
              className='align-center action-buttons cancel-button secondary-transparent-button btn btn-primary'
              onClick={() => {
                const clearEntries = this.props.getConfig();
                this.setState(() =>
                  ({ entries: clearEntries }));
                this.props.onApply(clearEntries);
                this.close();
              }}
            >
              { translate('search.clear')}
            </Button>
            <Button
              className='align-center action-buttons theme-button btn btn-primary'
              onClick={() => {
                this.props.onApply(this.state.entries);
                this.close();
              }}
            >
              { translate('search.ok')}
            </Button>
          </div>
        </Col>
      </Popup>
    );
  }
}

DocumentFilters.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  config: PropTypes.array.isRequired,
  onApply: PropTypes.func.isRequired,
  getConfig: PropTypes.func.isRequired,
};
export default DocumentFilters;
