import {
  HubConnectionState,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';

// The state of a meeting subscriber
export const MeetingSubscriberState = {
  Disconnected: 0,
  Reconnecting: 1,
  Connected: 2,
  Error: 3,
};

// Status of client meeting operations.
export const ClientMeetingResult = {
  Success: 0,
  InvalidMeeting: 1,
  ParticipantNotFound: 2,
  NotHost: 3,
  NotConnected: 4,
  InternalError: 5,
  DeletedMeeting: 6,
  MeetingFull: 7,
};

export const ParticipantState = {
  NotRegistered: 0,
  Waiting: 1,
  Active: 2,
  InLobby: 3,
  Reconnecting: 4,
};

class MeetingSubscriber {
  get state() {
    return this._state;
  }

  set state(value) {
    if (value !== this._state) {
      console.info(`MeetingSubscriber:state_set: old: ${this._state} -> new: ${value}`);
      this._state = value;
      this.options.meetingSubscriberStateChangedEvent(this._state);
    }
  }

  constructor(options) {
    this.meeting = options.meeting;
    this.pingTimer = null;
    this.options = options;
    this._state = MeetingSubscriberState.Disconnected;

    this.retryPolicy = {
      timeout: null,
      nextRetryDelayInMilliseconds(retryContext) {
        if (this.timeout && retryContext.elapsedMilliseconds > this.timeout) {
          return null;
        }
        return 5000;
      },
    };

    // create connection
    this.connection = new HubConnectionBuilder()
      .withUrl(`${options.meetingsUrl}/meetinghub`, {
        headers: { 'X-Session-Token': options.sessionToken },
        accessTokenFactory: () => options.sessionToken,
      })
      .withAutomaticReconnect(this.retryPolicy)
      .configureLogging(LogLevel.Debug)
      .build();

    // hookup event handlers
    this.connection.onclose(async (error) => {
      this.meetingHubConnectionClosed(error);
    });

    this.connection.onreconnected(async (error) => {
      await this.meetingHubConnectionReconnected(error);
    });

    this.connection.onreconnecting(async (connectionId) => {
      this.meetingHubConnectionReconnecting(connectionId);
    });

    this.connection.on('ParticipantStateUpdated', (participant, oldState) => {
      this.handleParticipantStateUpdated(participant, oldState);
    });

    this.connection.on('MeetingJoined', (meeting) => {
      this.handleMeetingJoined(meeting);
    });

    this.connection.on('MeetingParticipantsUpdated', (participant, added) => {
      this.handleMeetingParticipantsUpdated(participant, added);
    });

    this.connection.on('MeetingFull', () => {
      this.handleMeetingFull();
    });
  }

  meetingHubConnectionReconnecting(connectionId) {
    console.info('MeetingSubscriber:meetingHubConnectionReconnecting', connectionId);
    this.state = MeetingSubscriberState.Reconnecting;
  }

  meetingHubConnectionClosed(error) {
    console.info('MeetingSubscriber:meetingHubConnectionClosed', error);
    if (this.pingTimer) {
      clearInterval(this.pingTimer);
      this.pingTimer = null;
    }
    this.state = error ? MeetingSubscriberState.Error : MeetingSubscriberState.Disconnected;
  }

  async meetingHubConnectionReconnected(error) {
    console.info('MeetingSubscriber:meetingHubConnectionReconnected', error);
    this.state = MeetingSubscriberState.Connected;
    let reconnectResult = ClientMeetingResult.NotConnected;
    const oldMeeting = this.meeting;
    this.meeting = await this.connection.invoke('GetMeeting', this.meeting.meetingId);
    if (this.meeting) {
      if (this.meeting.participants) {
        this.meeting.participants.forEach((participant) => {
          const oldParticipant = oldMeeting?.participants.find(
            (p) => p.userId === participant.userId,
          );
          if (!oldParticipant) {
            this.options.meetingParticipantsChangedEvent(participant, true);
          }

          if (participant.state !== oldParticipant.state) {
            const oldState = oldParticipant.state ?? ParticipantState.NotRegistered;
            this.options.participantStateChangedEvent(participant, oldState);
          }

          if (oldMeeting) {
            oldMeeting.participants =
              oldMeeting.participants.filter((p) => p.userId !== oldParticipant.userId);
          }
        });

        if (oldMeeting.participants.length > 0) {
          oldMeeting.participants.forEach((oldParticipant) => {
            this.options.meetingParticipantsChangedEvent(oldParticipant, false);
          });
        }
      }

      reconnectResult = await this.connection.invoke('Reconnect', this.meeting.meetingId);
    }

    if (reconnectResult !== ClientMeetingResult.Success) {
      console.error(`meetingHubConnectionReconnected: Failed to reconnect to the hub with result ${reconnectResult} and meeting ${this.meeting}`);
      this.state = MeetingSubscriberState.Error;
    }
  }

  async connect() {
    let joinResult = ClientMeetingResult.NotConnected;
    console.debug('MeetingSubscriber:connect');
    try {
      if (this.connection?.state === HubConnectionState.Disconnected) {
        await this.connection.start();
        this.state = MeetingSubscriberState.Connected;
        const inactivityTimeout = await this.connection.invoke('GetClientKeepAliveTimeoutSeconds');
        this.retryPolicy.timeout = inactivityTimeout * 3000;
        const self = this;
        this.pingTimer = setInterval(async () => {
          try {
            const pingResult = await self.connection.invoke('Ping', self.meeting.meetingId);
            if (pingResult !== ClientMeetingResult.Success) {
              console.error(`MeetingSubscriber:PingTimer: failed to ping meeting service: ${pingResult}`);
            }
          } catch (ex) {
            console.error(`MeetingSubscriber:PingTimer: error pinging meeting hub: ${ex}`);
          }
        }, inactivityTimeout * 1000);

        joinResult = await this.connection.invoke('Join', this.meeting.meetingId);
      }
    } catch (error) {
      console.error(`MeetingSubscriber:connect: error: ${error}`);
    }
    return joinResult;
  }

  async disconnect() {
    try {
      if (this.pingTimer) {
        clearInterval(this.pingTimer);
        this.pingTimer = null;
      }
      await this.connection.stop();
      this.state = MeetingSubscriberState.Disconnected;
    } catch (err) {
      console.info('MeetingSubscriber:disconnect', err);
    }
  }

  async register(calledInParticipant, uninvitedParticipant) {
    let registerResult = ClientMeetingResult.NotConnected;
    console.debug(`MeetingSubscriber:register: state: ${this.state}, meeting: ${this.meeting}`);
    try {
      const registrationParameters = {
        MeetingId: this.meeting.meetingId,
        CalledInParticipant: calledInParticipant,
        UninvitedParticipant: uninvitedParticipant,
      };
      if (this.state === MeetingSubscriberState.Connected) {
        registerResult = await this.connection.invoke('Register', registrationParameters);
      }
    } catch (ex) {
      console.error(`MeetingSubscriber:register: error: ${ex}`);
    }
    return registerResult;
  }

  async deregister() {
    let deregisterResult = ClientMeetingResult.NotConnected;
    console.debug(`MeetingSubscriber:deregister: state: ${this.state}, meeting: ${this.meeting}`);
    try {
      if (this.state === MeetingSubscriberState.Connected) {
        deregisterResult = await this.connection.invoke('Deregister', this.meeting.meetingId);
      }
    } catch (ex) {
      console.error(`MeetingSubscriber:deregister: error: ${ex}`);
    }
    return deregisterResult;
  }

  handleMeetingJoined(meeting) {
    this.meeting = meeting;
    if (typeof this.options.meetingJoinedEvent === 'function') {
      this.options.meetingJoinedEvent(meeting);
    }
  }

  handleParticipantStateUpdated(participant, oldState) {
    const participantIndex = this.meeting.participants.findIndex((p) =>
      p.userId === participant.userId);
    if (participantIndex >= 0) {
      this.meeting.participants[participantIndex] = participant;
      if (participant.userId === this.meeting.host.userId) {
        this.meeting.host = participant;
      }
    }

    if (participant.state !== oldState) {
      if (typeof this.options.participantStateChangedEvent === 'function') {
        this.options.participantStateChangedEvent(participant, oldState);
      }
    }
  }

  handleMeetingParticipantsUpdated(participant, added) {
    if (participant) {
      if (added) {
        this.meeting.participants.push(participant);
      } else {
        const participantIndex = this.meeting.participants.findIndex((p) =>
          p.userId === participant.userId);
        if (participantIndex >= 0) {
          this.meeting.Participants?.splice(participantIndex, 1);
        }
      }

      if (typeof this.options.meetingParticipantsChangedEvent === 'function') {
        this.options.meetingParticipantsChangedEvent(participant, added);
      }
    }
  }

  handleMeetingFull() {
    if (typeof this.options.meetingFullEvent === 'function') {
      this.options.meetingFullEvent();
    }
  }
}

export default MeetingSubscriber;
