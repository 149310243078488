import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

// Selector
import { getLanguage } from 'STORE/Language/LanguageSelector';

// Translation
import { translate } from 'SERVICES/i18n';

// Asset
import { ReactComponent as DownArrowIcon } from 'ASSETS/icon_chevron_down.svg';

// Style
import './CustomDropdown.scss';

/**
 * @component
 */
class CustomDropdown extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    return (
      <div className='custom-dropdown'>
        <Dropdown
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          className='custom-dropdown'
          direction='down'
        >
          <DropdownToggle
            className={
              this.props.isWarning
                ? 'warning-border w-100 dropdown-toggle'
                : 'w-100 dropdown-toggle'
            }
            disabled={this.props.disableDropdown}
            aria-invalid={this.props.invalid}
          >
            <div
              id={this.props.selectedOptionId}
              className='selected-value ellipsis'
              // eslint-disable-next-line react/prop-types
              ref={this.props.selectRef}
              data-label={this.props.selectedOption?.label || this.props.selectedOption}
              data-value={this.props.selectedOption?.value}
            >
              {this.props.selectedOption?.label || this.props.selectedOption ?
                (this.props.selectedOption?.label || this.props.selectedOption) :
                (<div className='select-text'>{translate('select')}</div>)}
            </div>
            <DownArrowIcon className='down-arrow-icon' />
          </DropdownToggle>
          {this.props.options.length > 0 ? (
            <DropdownMenu>
              {this.props.options &&
                this.props.options.map((option) => (
                  <DropdownItem
                    key={option.value}
                    onClick={() => this.props.handleChange(option)}
                    title={translate(option.label)}
                  >
                    {option.label || option}
                  </DropdownItem>
                ))}
            </DropdownMenu>
          ) : (
            <DropdownMenu>
              <DropdownItem key='no_value' className='no-value'>
                {translate('noResultsFound')}
              </DropdownItem>
            </DropdownMenu>
          )}
        </Dropdown>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: getLanguage(state),
});

CustomDropdown.propTypes = {
  invalid: PropTypes.bool,
  isWarning: PropTypes.bool,
  disableDropdown: PropTypes.bool,
  selectedOptionId: PropTypes.string,
  handleChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  selectedOption: PropTypes.object,
};

CustomDropdown.defaultProps = {
  invalid: false,
  /**
   * Popup is a warning kind
   */
  isWarning: false,
  /**
   *  Disable dropdown action
   */
  disableDropdown: false,
  /**
   *  Id of option selected in drop down
   */
  selectedOptionId: '',
  handleChange: () => { },
  /**
   *  Drop down options
   */
  options: [],
  /**
   *  Selected option
   */
  selectedOption: {},
};

export default connect(mapStateToProps)(CustomDropdown);
