/* eslint-disable array-callback-return */
import _ from 'lodash';
import {
  LS_DATASTREAM_PARTICIPANTID,
  ParticipantChangeReasons,
} from 'UTILS/constants/DatastreamConstant';
import { getRemotePId, getLocalPId } from 'SERVICES/LSDatastream/LSDSCommon';
import { argbToRGB } from 'SERVICES/lsexif/telestration';
import { ParticipantState } from 'SERVICES/Meetings/MeetingSubscriber';
import CommonUtility from 'UTILS/CommonUtility';

// Services
import { AppLogger, LOG_NAME } from 'SERVICES/Logging/AppLogger';

const logger = AppLogger(LOG_NAME.Participant);

class ParticipantHelper {
  constructor(setCB, clearCB) {
    this.wrapperAPI = {
      set: setCB,
      clear: clearCB,
    };
  }

  initAPI(options, setCB, clearCB) {
    this.wrapperAPI = {
      set: setCB,
      clear: clearCB,
    };
    this.loggedInUserName = options?.loggedInUserName;
    this.localTSColor = options?.localTSColor;
    this.defaultRemoteTSColor = options?.defaultRemoteTSColor;
  }

  set = (participants, forceUpdate = false) => {
    this.wrapperAPI.set(participants, forceUpdate);
  }

  clear = () => {
    /* TODO: Enable this if map to states works
    this.props.clearParticipantsAction(participants);
    */
    this.wrapperAPI.clear();
  }

  add(participantsProp, participantId, muteStatus) {
    const participants = _.clone(participantsProp);
    if (participants && participants.length > 0) {
      const participantIndex = participants.findIndex(
        (participant) => participant.pId === participantId,
      );
      if (participantIndex > -1) {
        const participantToUpdate = participants[participantIndex];
        if (participantToUpdate) {
          participantToUpdate.isMuted = muteStatus;
        }
        participants.splice(participantIndex, 1, participantToUpdate);
      }
    }
    this.set(participants);
  }

  /**
   * Updates the audio state as mute/unmute (true/false) in global
   * participants list
   * @param {participantId} id of participant in the call
   * @param {muteStatus} true - on, false - off
   */
  updateMuteState = (participantsProp, participantId, muteStatus) => {
    const participants = _.clone(participantsProp);
    if (participants && participants.length > 0) {
      const participantIndex = participants.findIndex(
        (participant) => participant.pId === participantId,
      );
      if (participantIndex > -1) {
        const participantToUpdate = participants[participantIndex];
        if (participantToUpdate) {
          participantToUpdate.isMuted = muteStatus;
        }
        participants.splice(participantIndex, 1, participantToUpdate);
      }
    }
    /* FIXME: Workardoun to force update since else participants
     are not getting updated */
    this.set(participants, true);
  }

  /**
   * @param {id} activeSharerId when set to
   * LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID means no participant is sharing
   */
  updateVideoState = (participantsProp, activeSharerId) => {
    // Set all others to not sharing
    logger.debug('Participants::Updating video state, Sharer Id:', activeSharerId);
    const participants = participantsProp.map((ogParticipant) => {
      const participant = _.clone(ogParticipant); // TODO: Is this required?
      participant.isVideoOn = (activeSharerId === participant.pId);
      return participant;
    });
    this.set(participants);
  }

  /**
   * @param {id} pId when set to the owner of state change
   * LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID means no participant is sharing
   */
  updateVideoStateById = (participantsProp, pId) => {
    logger.debug('Participants::Updating video state of others to not sharing, Sharer Id:', participantsProp, pId);
    // Set all others to not sharing
    const participants = participantsProp.map((ogParticipant) => {
      const participant = _.clone(ogParticipant); // TODO: Is this required?
      participant.isVideoOn = (pId === participant.pId);
      return participant;
    });
    this.set(participants);
  }

  /**
   * Sets remote id when available after the LS handshake is done.
   */
  setRemotePid = (participantsProp) => {
    const participants = _.clone(participantsProp);
    if (participants && participants.length > 0) {
      const remotePid = getRemotePId();
      const unknownIdIdx = participants.findIndex(
        (participant) => participant.pId === LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID,
      );
      if (unknownIdIdx > -1) {
        const participantToUpdate = participants[unknownIdIdx];
        if (participantToUpdate) {
          participantToUpdate.pId = remotePid;
        }
        participants.splice(unknownIdIdx, 1, participantToUpdate);
      }
    }
    this.set(participants);
  };

  /**
   *  Update participant array with updated colours (local participant)
   */
  updateColours = (participants, tsColourCode) => {
    const updatedParticipants = participants;
    const localIndex = participants.findIndex((participant) =>
      participant.pId === getLocalPId());
    if (updatedParticipants[localIndex]) {
      updatedParticipants[localIndex].colorCode = tsColourCode;
    }
    return updatedParticipants;
  };

  /**
   *  Update remote participant colours in participants array
   */
  updateRemoteColour = (participantList, colourData) => {
    participantList.map((participant) => {
      colourData?.map((color) => {
        if (participant.pId === color.participantId) {
          participant.colorCode = (color.colourCode[0] === '#' ? color.colourCode : argbToRGB(color.colourCode));
        }
      });
    });
    return participantList;
  };

  /**
   * @param {Array} participantsList participants array from reducer
   * @param {Array} allInvitedParticipants all invited meeting participants
   * @param {Object} attendee updated participant's data
   */
  createMeetingParticipants = (participantsList, allInvitedParticipants, attendee) => {
    // Set the meeting participants data containing all the invited participants.
    if (participantsList.length === 0) {
      this.setMeetingParticipants(
        allInvitedParticipants,
        participantsList,
      );
    } else {
      // Update the existing participant's data or add a new participant.
      this.updateMeetingParticipants(
        participantsList,
        attendee,
      );
    }
  }

  /**
   * Add a new participant in the meeting
   */
  addMeetingParticipants = (
    allParticipants, participantToAdd,
  ) => {
    allParticipants.push({
      name: participantToAdd.fullName,
      address: participantToAdd.userName,
      sipAddress: participantToAdd.address,
      isLoggedInUser: participantToAdd.userName === this.loggedInUserName,
      isVideoOn: false,
      isMuted: false,
      pId: participantToAdd.userId, // temporarily assign pId to userId so react doesn't complain.
      colorCode: participantToAdd.userName === this.loggedInUserName ?
        this.localTSColor : (this.defaultRemoteTSColor),
      meetingState: participantToAdd.state,
    });
    this.set(allParticipants, true);
    console.debug('addMeetingParticipants::All the meeting participants - \n', allParticipants);
  }

  /**
  *  Set the meeting participants array initially in the reducer
  */
  setMeetingParticipants = (
    allMeetingParticipants,
    meetingParticipants,
  ) => {
    if (meetingParticipants.length === 0) {
      // Set the meeting participants array coming from getMeetingById() for the first time.
      allMeetingParticipants?.forEach((participant) => {
        this.addMeetingParticipants(
          meetingParticipants, participant,
        );
      });
      logger.debug('setMeetingParticipants::All the meeting participants - \n', meetingParticipants);
    }
  }

  /**
   *  Update the meeting participants array in the reducer
   */
  updateMeetingParticipants = (
    meetingParticipants,
    updatedParticipant,
  ) => {
    logger.debug('updateMeetingParticipants:: Updated participant:', updatedParticipant);
    if (updatedParticipant) {
      // Check for newly joined invited participant coming from onParticipantChange() & get its pid
      /* If the updated participant comes from the onParticipantChange()
      event, compare the 'addr' value with the 'mp.sipAddress'.
      Otherwise compare 'userName' with 'mp.address'. */
      const existingParticipant = (meetingParticipants.filter((mp) =>
        ((updatedParticipant.userName === mp.address) ||
        (updatedParticipant.addr && (updatedParticipant.addr?.split(':')[1] === mp.sipAddress)))));
      if ((existingParticipant.length === 0)) {
        // Participant not found -> uninvited participant joins the meeting -> Add this participant
        logger.debug('updateMeetingParticipants::New uninvited participant has joined the meeting...\n', updatedParticipant);
        if (updatedParticipant?.userId) {
          this.addMeetingParticipants(
            meetingParticipants, updatedParticipant,
          );
        }
      } else {
        logger.debug('updateMeetingParticipants::Update the array with the pid/state of existing participant - \n', updatedParticipant);
        meetingParticipants?.forEach((participant) => {
          /* If the updated participant comes from the onParticipantChange()
          event, retrieve 'pid' from it and set 'pId'. Otherwise only update the
          meetingstate of that participant */
          if ((updatedParticipant.userName === participant.address) ||
            (updatedParticipant.addr && (updatedParticipant.addr?.split(':')[1] === participant.sipAddress))) {
            if ((participant.meetingState === ParticipantState.Active) &&
              (updatedParticipant.state === ParticipantState.Waiting)) {
              // if same incall participant is accessing meeting link on other device/tab(#)
              // do nothing
            } else if (updatedParticipant.state >= 0) { // Comes from meetingStateChangedEvent()
              participant.meetingState = updatedParticipant.state;
            } else if (updatedParticipant.pid &&
              updatedParticipant.reason === ParticipantChangeReasons.PCR_Removal) {
              // Got the removed participant (comes from onParticipantChange())
              participant.meetingState = ParticipantState.NotRegistered;
            } else {
              // Set the pid of the newly joined participant (comes from onParticipantChange())
              logger.debug('updateMeetingParticipants::Set the pid for the participant', updatedParticipant, participant);
              participant.pId = updatedParticipant.pid;
              participant.meetingState = ParticipantState.Active;
            }
          }
        });
      }
      this.set(meetingParticipants, true);
      logger.debug('updateMeetingParticipants::All the meeting participants - \n', meetingParticipants);
    }
  }

  // Set initial in-call meeting participants on acceptcall(), onCallAccepted() events
  setInitialMeetingParticipants = (attendees, remoteSipAddress) => {
    attendees?.forEach((p) => {
      // Local user
      if (p.address === CommonUtility.getUserNameWithDomainFromUri(
        this.loggedInUserName,
        false,
      )) {
        p.pId = getLocalPId();
        p.meetingState = ParticipantState.Active;
      } else if (p.sipAddress === CommonUtility.getUserNameWithDomainFromUri(
        remoteSipAddress,
        false,
      )) {
        // Remote user
        p.pId = getRemotePId();
        p.meetingState = ParticipantState.Active;
      }
    });
    this.set(attendees, true);
    console.debug('setInitialMeetingParticipants::Initial attendees set - \n', attendees, remoteSipAddress);
  }
}

/* TODO: Can this work?
const mapStateToProps = (state) => ({
  participants: getParticipants(state),
});

// eslint-disable-next-line no-unused-vars
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setParticipantsAction,
      clearParticipantsAction,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantHelper));
*/

const Participants = new ParticipantHelper(null, null, null);
export default Participants;
