/* eslint-disable react/prop-types */
import React from 'react';
import { Slide, toast } from 'react-toastify';

// Icons
import { ReactComponent as CloseIcon } from 'ASSETS/icon_close.svg';
import { ReactComponent as WarnIcon } from 'ASSETS/icon_warning.svg';
// import { ReactComponent as InfoIcon } from 'ASSETS/icon_info.svg';

// Styles
import './Toast.scss';
import 'react-toastify/dist/ReactToastify.css';

export const ToastType = {
  INFO: 'info',
  WARN: 'warning',
};

function CloseButton({ closeToast }) {
  return (
    <CloseIcon
      onClick={closeToast}
      alt='close'
      role='presentation'
      className='toast-close align-center close-icon'
    />
  )
}

const renderWarnIcon = () => (
  <WarnIcon
    className='warn-icon'
    alt='warn'
    role='presentation'
  />
);

/*
const InfoIcon = () => (
  <WarnIcon
    src={warn}
    alt='info'
    role='presentation'
  />
);
*/

export const showToast = (message, type = 'info', toastOptions = {}) => {
  let toastId = 0;
  const defaultOptions = {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    closeButton: CloseButton,
    transition: Slide,
    theme: 'colored',
    limit: 1,
    icon: type === ToastType.WARN ? renderWarnIcon : false,
  };
  // Combine
  const options = { ...defaultOptions, ...toastOptions };

  if (type === ToastType.WARN) {
    toastId = toast.warning(message, options);
  } else if (type === ToastType.INFO) {
    toastId = toast.info(message, options);
  } else {
    console.info('TOAST: options', options);
    toastId = toast(message, options);
  }
  return toastId;
};

export const showWarningToast = (message) => {
  showToast(message, ToastType.WARN);
};

export const showBannerToast = (message) => {
  const options = {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: false,
    closeButton: false,
    transition: Slide,
    theme: 'banner',
    limit: 1,
    icon: true,
  };
  return showToast(message, ToastType.INFO, options);
};

export const dismissToast = (toastId) => {
  if (toastId && toast.isActive(toastId)) toast.dismiss(toastId);
  return 0;
};
