/* eslint-disable */ 
export const LS_DATASTREAM_EVENTS = {
  ID_FILE_OPEN : 0,
  ID_FILE_WRITE : 1,
  ID_FILE_READ : 2,
  ID_NETWORK_OPEN : 3,
  ID_NETWORK_SEND : 4,
  ID_NETWORK_RECV : 5,
  ID_FILTER_STATE_CHANGE : 6,
  ID_FILTER_AUDIOENC : 7,		
  ID_STREAM_STATE_CHANGE : 8,
  ID_RTC_VOICE_EVENT : 9,
  ID_SIP_SESSION_STATE_CHANGE : 10,
  ID_SIP_INITIALIZE_FAIL : 11,
  ID_SIP_SERVER_REGISTER : 12,
  ID_FILE_TRANSFER_SEND : 13,
  ID_FILE_TRANSFER_RECV : 14,
  ID_STILL_IMAGE_SEND : 15,
  ID_STILL_IMAGE_RECV : 16,
  ID_TELEPOINT_RECV : 17,
  ID_CONFIGCTRL_RECV : 18,
  ID_DATASTREAM_CORRUPT : 19,
  ID_VIDEO_SOURCE : 20,
  ID_AUDIO_SOURCE : 21,
  ID_AUDIO_DEVICE_SINK : 22,
  ID_POWER_EVENT : 23,
  ID_DSHEARTBEAT : 24,
  ID_VIDEO_DECODE : 25,
  ID_DATASTREAM_VERSION_MISMATCH : 26,
  ID_NETWORK_CLOSE : 27,
  ID_AUDIO_DEVICE_SOURCE : 28,
  ID_VIDEO_STREAM_STATS : 29,
  ID_AUDIO_STREAM_STATS : 30,
  ID_AUDIO_RENDER_BUFFER_ALARM : 31,
  ID_MP4_PLAY_TIME : 32,
  ID_SIP_REINVITE : 33,
  ID_AUDIO_SOURCE_CODEC_CHANGE : 34,
  ID_AUDIO_DEVICE_SINK_REMOVED : 35,
  ID_AUDIO_DEVICE_SOURCE_REMOVED : 36,
  ID_REF_CLOCK_ADJUST : 37,
  ID_VIDEO_PLAYBACK_STATS : 38,
  ID_STILL_IMAGE_RECV_PROGRESS : 39,
  ID_STILL_IMAGE_SEND_PROGRESS : 40,
  ID_STILL_IMAGE_TRANSFER_CANCEL : 41,
  ID_RECORD_UPDATE : 42,
  ID_SESSION_BANDWIDTH_UPDATE : 43,
  ID_ENDPOINT_REQUESTS_I_FRAME : 44,
  ID_VIDEO_SOURCE_CODEC_CHANGE : 45,
  ID_VIDEO_SINK_CODEC_CHANGE : 46,
  ID_BANDWIDTH_TEST_STARTED : 47,
  ID_BANDWIDTH_TEST_STATUS : 48,
  ID_BANDWIDTH_TEST_COMPLETE : 49,
  ID_BANDWIDTH_TEST_ERROR : 50,
  ID_BANDWIDTH_TEST_RUNNING : 51,
  ID_BANDWIDTH_TEST_PAUSE_REQUEST : 52,
  ID_OUTGOING_RTCP_RR : 53,
  ID_AUDIO_KEEP_ALIVE : 54,
  ID_VIDEO_KEEP_ALIVE : 55,
  ID_RUDP_RETRY_ALARM : 56,
  ID_RTPSINK_ALT_RTP_DETECTED : 57,
  ID_REMOTE_SDP_UPDATE : 58,
  ID_SIP_SESSION_TRANSFER : 59,
  ID_SIP_SESSION_REDIRECT : 60,
  ID_VIDEO_STREAM_TX_STATS : 61,
  ID_AUDIO_STREAM_TX_STATS : 62,
  ID_VIDEO_STREAM_RX_STATS : 63,
  ID_AUDIO_STREAM_RX_STATS : 64,
  ID_DATA_STREAM_RX_STATS : 65,
  ID_DATA_STREAM_TX_STATS : 66,
  ID_OHTS_SERVER_REGISTER : 67,
  ID_VIDEO_SINK : 68,
  ID_IOD_ATTACH : 69,
  ID_IOD_NEW_VIDEO_SOURCE : 70,
  ID_IOD_BATTERY : 71,
  ID_IOD_ACC_FAILED_TO_ATTACH : 72,
  ID_IOD_EVENT : 73,
  ID_IOD_RESTART_MEDIA : 74,
  ID_OHTS_SERVER_SUSPDEND_STATE : 75,
  ID_ASYNC_SEND : 76,
  ID_ASYNC_RECV : 77,
  ID_IOD_FILE_TRANSFER_EVENT : 78,
  ID_FILE_RECORD_GOT_FIRST_AUDIO_TRACK : 79,
  ID_CUBE_ATTACH : 80,
  ID_CUBE_BATTERY : 81,
  ID_CUBE_RSSI : 82,
  ID_CUBE_EVENT : 83,
  ID_CUBE_BLE_INITIALIZE : 84,
  ID_CUBE_BLE_CONNECT : 85,
  ID_CUBE_BLE_DISCOVERY : 86,
  ID_CUBE_FORGET_ILINK_PAIRING : 87,
  ID_CUBE_PICTURE_LOSS : 88,
  ID_CUBE_LOCAL_CONTROL : 89,
  ID_CUBE_P2P_STATUS_CHANGE : 90,
  ID_CUBE_CONNECTION : 91,
  ID_CUBE_FUSION_UPDATE : 92,
  ID_CUBE_OPERATOR_STATUS_UPDATE : 93,
  ID_AUDIO_MIXER_DATA : 94,
  ID_SESSION_HEALTH_UPDATE : 95,
  ID_STREAM_RTP_SEQUENCE_UPDATE : 96,
  ID_RUDP_MTU_UPDATE : 97,
  ID_AUDIO_TRANSCRIPT_DATA : 98,
  ID_RUDP_LIBDS_SEND_DATA : 99
};

export const LSDS_CALL_BANDWIDTH_CONFIG = {
  BW: 0,
  BW_OMAX: 0xFFFFFFFF,
  BW_IMAX: 0xFFFFFFFF,
  PRIV: 0,
};

export const LS_DISCONNECT_ID = '00000000-0000-0000-0000-000000000000';

export const LS_DATASTREAM_EVENTSEVERITY = {
  FATAL: 0,
  ERROR: 2,
  WARN: 3,
  INFO: 4,
  SYSTEM: 5,
  DEBUG: 6,
};

export const DATAPACKET_FLAGS = {
  DPF_ZERO: 0x0000,
  DPF_SET: 0x0001,
  DPF_XML: 0x0002,
};

export const LS_DATASTREAM_CORE_EVENTS = {
  // session ev1ents
  SessEventVoiceCallIncoming        : 0x00009000,
  SessEventVoiceCallDisconnected    : 0x00009001,
  SessEventVoiceCallInprogress      : 0x00009002,
  SessEventVoiceCallAnswering       : 0x00009003,
  SessEventVoiceCallConnected       : 0x00009004,

  SessEventMediaCallIncoming        : 0x00009005,
  SessEventMediaCallDisconnected    : 0x00009006,
  SessEventMediaCallInprogress      : 0x00009007,
  SessEventMediaCallAnswering       : 0x00009008,
  SessEventMediaCallConnected       : 0x00009009,

  SessEventVoiceOnlyCall            : 0x0000900a,
  SessEventBandwidthUpdate		  : 0x00009010,

  SessEventCallGuidUpdate			  : 0x00009011,
  SessEventRemoteSdpUpdate		  : 0x00009012,
  SessEventCallRedirect			  : 0x00009013,

  SessEventCallDisconnectMsg		  : 0x00009014,

  SessEventLocalSdpUpdate			  : 0x00009015,

  SessEventReinviteConnected		  : 0x00009016,
  SessEventCallRetry				  : 0x00009017,

  SessEventMeetingIdUpdate          :0x00009018,

  // system events
  SysEventOkToDeinitialize          : 0x00009100,
  SysEventSipInitializeFailure	  : 0x00009101,
  SysEventNetworkError			  : 0x00009102,
  SysEventInitComplete			  : 0x00009103,
  SysEventPower					  : 0x00009104,
  SysEventFileError				  : 0x00009105,
  SysEventBattery					  : 0x00009106,
  SysEventRemoteParticipantGoodToGo : 0x00009107,
  SysEventAudioInitEvent			  : 0x00009108,		// from Core to Main only; it is NOT sent over data stream
  SysEventVideoInitEvent		  	  : 0x00009109,		// from Core to Main only; it is NOT sent over data stream

  // status events
  StatusEventStreamState            : 0x00009200,
  StatusEventRecordState            : 0x00009203,
  StatusEventRemoteRecordState      : 0x00009204,
  StatusEventZoomLevel              : 0x00009206,
  StatusEventFocusValue             : 0x00009207,
  StatusEventFocusMode              : 0x00009208,
  StatusEventStreamFreeze           : 0x00009209,
  StatusEventIllumState             : 0x0000920a,
  StatusEventIllumLevel             : 0x0000920b,
  StatusEventWirelessNetworkLinkUp  : 0x0000920c,
  StatusEventWirelessNetworkSignalStrength  : 0x0000920d,

  StatusEventZoomStarted            : 0x0000920e,
  StatusEventZoomStopped            : 0x0000920f,

  StatusEventFocusStarted           : 0x00009210,
  StatusEventFocusStopped           : 0x00009211,

  StatusEventBatteryPercentRemaining: 0x00009212, 
  StatusEventACIn					  : 0x00009213, 

  StatusEventWiredNetworkLinkUp	  : 0x00009214,

  StatusEventStreamQuality		  : 0x00009215,

  StatusEventZoomInfo				  : 0x00009216,
  StatusEventFocusInfo			  : 0x00009217,
  StatusEventIllumInfo			  : 0x00009218,
  StatusEventHeadsetPresence		  : 0x00009219,

  StatusEventRenderingState		  : 0x0000921a,
  StatusEventMp4PlayTime			  : 0x0000921b,

  StatusEventSysVersion			  : 0x0000921c,
  StatusEventSysCapabilities		  : 0x0000921d,

  StatusEventFilePlayState		  : 0x0000921e,
  StatusEventMp4FileSelected		  : 0x0000921f,
  StatusEventMp4Seek				  : 0x00009220,

  StatusEventStillImageCaptureState : 0x00009221,
  StatusEventStillImageShareModeState : 0x00009222,
  StillImageRemoteCaptureRequest    : 0x00009223,

  StatusEventRemoteRenderingState	  : 0x00009224,
  StatusEventVideoLinkInfo				: 0x00009225, 
  StatusEventStillImageShareRequestState  : 0x00009226,
  StatusEventRtcpRR				  : 0x00009227,
  StatusEventIllumEnable            : 0x00009228,
  StatusEventLaserState			  : 0x00009229,
  StatusEventCameraCapabilities	  : 0x0000922A,
  StatusEventImagingMode			  : 0x0000922B,
  StatusEventLaserEnable            : 0x0000922C,

  StatusEventLocalPictureSharingModeChanged	: 0x00009230,
  StatusEventRemotePictureSharingModeChanged	: 0x00009231,
  StatusEventStreamingOperationDenied			: 0x00009232,

  StatusEventCameraStillResolutionProfiles          : 0x00009233,
  StatusEventSetCameraStillResolution               : 0x00009234,

  StatusEventMp4TrackState		  : 0x00009235,
  StatusEventRecordInfo			  : 0x00009236,

  StatusEventStillImageCaptureProgress : 0x00009237,
  StatusEventStillImageCaptureError : 0x00009238,

  StatusEventRemotePrivacyState     : 0x00009240,
  StatusEventSessionPrivacyState     : 0x00009241,
  StatusEventLocalPrivacyModeState : 0x00009242,

  StatusEventParticipantChange      : 0x00009250,
  StatusEventActiveMCDChange        : 0x00009251,
  StatusEventMediaStreamSourceChange: 0x00009252,
  StatusEventStreamMute             : 0x00009253,
  HostNegotiation					   : 0x00009254,
  StatusEventMediaAudioStreamEnableDeprecated : 0x00009255,
  EventLiveVideoToken					: 0x00009256,
  EventVideoSourcesForParticipant		: 0x00009257,
  EventVoiceAudioLost				  : 0x00009258,

  StatusEventBacklightTimer		  : 0x00009260,

  StatusEventVideoTxLinkInfo		: 0x00009265,
  StatusEventAudioTxLinkInfo		: 0x00009266,
  StatusEventVoiceTxLinkInfo		: 0x00009267,
  StatusEventVideoRxLinkInfo		: 0x00009268,
  StatusEventAudioRxLinkInfo		: 0x00009269,
  StatusEventVoiceRxLinkInfo		: 0x00009270,
  StatusEventDataTxLinkInfo		: 0x00009271,
  StatusEventDataRxLinkInfo		: 0x00009272,
  StatusEventRemoteVideoRxLinkInfo : 0x00009273,
  StatusEventVideoEnabled         : 0x00009274,

  StatusEventGpsInfo              : 0x00009280,
  StatusEventEncLimits            : 0x00009281,
  StatusEventDecLimits            : 0x00009282,
  StatusEventLimitedVideoState    : 0x00009283,
  StatusEventMeasurementSupport	: 0x00009284,
  StatusEventMeasurementInfo		: 0x00009285,

  StatusEventNetworkError           : 0x00009290,

  StatusEventFileRecordGotFirstAudioTrack : 0x00009291,

  StatusEventAudioMixerDataAvailable : 0x00009292,
  StatusEventScreenShareEnded : 0x00009293,
  StatusEventScreenShareItemSelected : 0x00009294,

  StatusEventSessionHealthUpdate : 0x00009295,
  StatusEventAudioTranscriptDataAvailable : 0x00009296,
  StatusEventProxyClientSessionHealthUpdate : 0x00009297,

  // configuration
  CfgEventMediaAudioConfigChanged   : 0x00009300,
  CfgEventMediaVideoConfigChanged   : 0x00009301,
  CfgEventVideoConfigChanged        : 0x00009302,
  CfgEventMediaInfoChanged          : 0x00009303,
  CfgEventOverlayInfoChanged        : 0x00009304,
  CfgEventAudioConfigChanged        : 0x00009305,
  CfgEventCurrentMediaAudioConfigChanged : 0x00009306,
  CfgEventCurrentMediaVideoConfigChanged : 0x00009307,
  CfgEventInternalCameraStartupComplete :  0x00009308,
  CfgEventTelestrationColours       : 0x0009309,
  CfgEventDefaultMediaVideoConfigChanged : 0x0009310,
  CfgEventDefaultMediaAudioConfigChanged : 0x0009311,
  CfgEventMeasurementOverlayConfigChanged : 0x0009312,

  StatusEventThermalConfig 	: 0x00009320,
  StatusEventFusionState		 : 0x00009321,

  // telestration/pointer events
  TpEventNewTelePointData			  : 0x00009400,
  TpEventNewTeleJsonData          : 0x00009401,

  // file send/receive events
  FileSendOk						: 0x00009500,
  FileSendFail					: 0x00009501,
  FileRecvOk						: 0x00009502,
  FileRecvFail					: 0x00009503,
  StillImageSendOk				: 0x00009504,
  StillImageSendFail				: 0x00009505,
  StillImageRecvOk				: 0x00009506,
  StillImageRecvFail				: 0x00009507,
  StillImageShareRecvOk			: 0x00009508,
  StatusStillImageTransferProgress : 0x00009509,
  StillImageTransferCancel		: 0x0000950a,
  StatusEventStillImageGlobalTransferProgress : 0x0000950b,
  StillImageCaptureMessage		: 0x0000950c,
  StillImageHighResShareEvent     : 0x0000950d,

  // Asynchronous File transfer
  AsyncFileTransferEvent			: 0x00009550,

  // SIP messages
  SipServerRegister				: 0x00009600,

  // OHTS messages
  OhtsServerRegister				: 0x00009680,
  OhtsServerSuspendState			: 0x00009681,

  // file system related events
  FileSystemError					: 0x00009700,
  FileSystemFormatProgress		: 0x00009701,
  FileSystemStorageCard		    : 0x00009702,

  // Network connection events
  NetworkConnectionsChanged		: 0x00009800,

  // Bandwidth test events
  BandwidthTestStarted			: 0x00009900,
  BandwidthTestComplete			: 0x00009901,
  BandwidthTestError				: 0x00009902,
  BandwidthTestStatus				: 0x00009903,
  BandwidthTestRunning			: 0x00009904,

  // iOD events
  IODConnectionState              : 0x0000A000,
  IODNewVideoSource               : 0x0000A001,
  IODBatteryUpdate                : 0x0000A002,
  IODAccFailedToAttach            : 0x0000A003,
  IODFileTransferUpdate           : 0x0000A004,

  // Cube events
  CubeConnectionState           : 0x0000A100,
  CubeBatteryUpdate             : 0x0000A101,
  CubeWifiSignalEvent           : 0x0000A102,
  CubeLocalControlEvent		  : 0x0000A103,
  CubeForgetPairingEvent		  : 0x0000A104,
  CubeOperatorStatusEvent       : 0x0000A105,

  // Cube BLE events
  CubeBleInitialization         : 0x0000A200,
  CubeBleConnectionState        : 0x0000A201,
  CubeBleDiscoveryState         : 0x0000A202,
  CubeBleRssiUpdate             : 0x0000A203,
  CubeBleWifiScanResults        : 0x0000A204,
  CubeBleWifiNetworkResults     : 0x0000A205,
  CubeBleDeviceInfo             : 0x0000A206,
  CubeBleWifiNetworkForget      : 0x0000A207,
  CubeBleWifiAddNetwork			: 0x0000A208,
  CubeBleWifiJoinNetwork		: 0x0000A209,
  CubeBleBatteryUpdate			: 0x0000A20A,
  P2PStateChanged					: 0x0000A20B,
  CubeBleP2pJoinNetwork			: 0x0000A20C,
  CubeBleLegacyWiFiDirectEvent    : 0x0000A20D,
  ConfiguredSSIDsEvent            : 0x0000A20E,
  CubeBleRemoveAllCertificatesEvent	: 0x0000A20F,
  CubeBleInstallCertificateEvent	: 0x0000A210,
  CubeBleListCertificateEvents	: 0x0000A211,

  // Remote microphone mute events
  RemoteMicMuteRequest			: 0x0000A300,
  RemoteMicMuteStatusChanged		: 0x0000A301,

  // Maximum ECoreEvent value
  ECoreEventMax					  : 0xC000
};

export const LS_DATASTREAM_PARTICIPANTID = {
  UNKNOWN_PARTICIPANTID: 0xffffffff,
  ALL_PARTICIPANTID: 0xffffffff,
  AUDIO_TRANSCRIPT_LISTENER_PARTICIPANTID: 0xfffcfffb,
  DEFAULT_LOCAL_PARTICIPANTID: 0xfffcfffc,
  AUDIO_MIXER_LISTENER_PARTICIPANTID: 0xfffcfffd,
  AUDIO_MIXER_OUTPUT_PARTICIPANTID: 0xfffcfffe,
  RECORDING_PARTICIPANTID: 0xfffefffe,
  PLAYBACK_PARTICIPANTID: 0xfffdfffd,
  PROXY_SERVER_PARTICIPANTID: 0xfffdfffc,
  PROXY_CLIENT_PARTICIPANTID: 0xfffdfffb,
};

export const LS_DATASTREAM_SYS_CAPABILITY = {
  CAPABILITY_FIRST: 0,
  CAPABILITY_FILE_PLAYBACK: 1,
  CAPABILITY_FILE_PLAYBACK_EXT: 2,
  CAPABILITY_SNAPSHOT: 3,
  CAPABILITY_THIRD_PARTY_ENDPOINT: 4,
  CAPABILITY_REINVITE: 5,
  CAPABILITY_SNAPSHOT_SHARE_COORDINATION: 6,
  CAPABILITY_EFFICIENT_SHARING: 7,
  CAPABILITY_MACRO_ILLUMINATION: 8,
  CAPABILITY_POCKET_PC_CAMERA_CONTROLS: 9,
  CAPABILITY_ONE_TO_MANY: 10,
  CAPABILITY_BANDWIDTH_TEST: 11,
  CAPABILITY_CALL_GUID: 12,
  CAPABILITY_EFFICIENT_TELESTRATION: 13,
  CAPABILITY_DYNAMIC_LIVE_VIDEO: 14,
  CAPABILITY_ILLUMINATION_TOGGLE: 15,
  CAPABILITY_FOCUS: 16,
  CAPABILITY_NEW_SHARE_SCHEME: 17,
  CAPABILITY_ASYNC_FILE_TRANSFER: 18,
  CAPABILITY_NETWORK_ERROR_EVENT: 19,
  CAPABILITY_LS_DATASTREAM_SESSION_DISCONNECT: 20,
  CAPABILITY_ADDITIONAL_VIDEO_SOURCES: 21,
  CAPABILITY_ADDITIONAL_VIDEO_RESOLUTIONS: 22,
  CAPABILITY_HIGH_RES_SHARE: 23,
  CAPABILITY_TELESTRATION_EXT: 24,
  CAPABILITY_REMOTE_MICROPHONE_MUTE: 25,
  CAPABILITY_RECONNECT_REINVITE: 26,
  CAPABILITY_SESSION_HEALTH_UPDATE: 27,
  CAPABILITY_INVALID: 0xFFFFFFFF,
};

export const TELEPOINT_TYPE_FLAGS = {
  TELEPOINT_TYPE_EXTENDED: 0x0001,
  TELEPOINT_TYPE_TELESTRATION: 0x0002,
  TELEPOINT_TYPE_CLEAR: 0x0004,
  TELEPOINT_TYPE_PENUP: 0x0008,
  TELEPOINT_TYPE_PENDOWN: 0x0010,
  TELEPOINT_TYPE_REMOTE: 0x0020,
  TELEPOINT_TYPE_STREAM_START_COMPLETE: 0x0040,
  TELEPOINT_TYPE_CLEAR_PIDS: 0x0080,
  TELEPOINT_TYPE_STILL_IMAGE_KIND: 0x0100,
  TELEPOINT_TYPE_REMOVE: 0x0200,
  TELEPOINT_TYPE_RECORDED: 0x4000,
  TELEPOINT_TYPE_DATAVALID: 0x8000,
};

export const LS_DATASTREAM_LIVE_VIDEO_TOKEN_ACTION = {
	// =========================================================
	// old LiveVideoToken stuff
	
	// action requests
	LVT_RequestToken : 0,			// asynchronous...wait for Accepted or Rejected message
	LVT_LockToken : 1,				// synchronous...immediately returns true (got the lock) or false (could not get lock)
	LVT_UnlockToken : 2,			// synchronous...return value irrelevant
	LVT_GetTokenHolder : 3, 		// synchronous...returns pid of token holder

	// action responses
	LVT_Accepted : 4,				// accepted event returned in response to RequestToken
	LVT_Rejected : 5,				// rejected event returned in response to RequestToken
	
	// remote request
	LVT_RemoteRequest : 6,			// someone else is requesting that a specified endpoint try to obtain the token

	// =========================================================
	// new share scheme
	NSS_ShareRequest : 7,			// asynchronous...sent to request the right to share something
	NSS_Accepted : 8,				// accepted event returned in response to ShareRequest
	NSS_Rejected : 9,				// rejected event returned in response to ShareRequest
	NSS_ShareAboutToStart : 10,		// sent (with pid) up to Main and out to others indicating whose about to share
	NSS_ShareIntent : 11,			// sent just prior to ShareAboutToStart to indicate what the sharer's intent is

	// =========================================================
	// more LVT - enhancement for LVT_RemoteRequest
	LVT_RemoteRequestRejected : 12  // the remote request was rejected
};

export const STREAM_STATUS = {
  STREAM_STOPPED: 0,
  STREAM_STOP_REQUESTED: 1,
  STREAM_START_REQUESTED: 2,
  STREAM_STARTED: 3,
  STREAM_ERROR: 4,
};

export const QUEUED_SHARE_ACTION = {
  NONE: 0,
  LIVE_VIDEO: 1,
  PLAYBACK_VIDEO: 2,
  STILL_IMAGE: 3,
};

export const QUEUED_SHARE_ACTION_WAITING_FLAGS = {
  None: 0x00,
  RendererStopped: 0x01,
  PlaybackExit: 0x02,
  ActiveMcdCleared: 0x04,
  StreamStopped: 0x08,
};

export const STILL_IMAGE_CAPTURE_REQUEST_MESSAGE = {
  REQUEST_REMOTE_CAPTURE: 0,
  ACCEPTED: 1,
  REJECTED: 2,

  // local commands
  GET_LAST_REMOTE_REQUEST: 10,
  INITIATE_CAPTURE: 11,
};

export const STILL_IMAGE_CAPTURE_REQUEST_MASK = {
  CAPTURE_MODE_SHIFT: 16,
  REMOTE_SHIFT: 24,

  CAPTURE_MODE_MASK: 0x00FF0000,
  QUALITY_MASK: 0x0000FFFF,
};

export const STILL_IMAGE_CAPTURE_MODE = {
  FIELD: 0,
  FRAME: 1,
  AUTO: 2,
  MOTION_ONLY: 3,
};

export const STILL_IMAGE_CAPTURE_STATUS = {
  STILL_IMAGE_CAPTURE_INITIATED: 0,
  STILL_IMAGE_CAPTURE_COMPLETE: 1,
  STILL_IMAGE_CAPTURE_REQUESTED_LOCAL: 2,
  STILL_IMAGE_CAPTURE_REQUESTED_REMOTE: 3,
  STILL_IMAGE_CAPTURE_CHANGE_REQUESTED: 4,
  STILL_IMAGE_CAPTURE_ERROR: 5,
  STILL_IMAGE_CAPTURE_NONCUSTOM: 100,
};

export const STILL_IMAGE_SHARE_MODE_STATUS = {
  STILL_IMAGE_SHARE_MODE_CHANGE_INITIATED: 0,
  STILL_IMAGE_SHARE_MODE_EXIT: 1,
  STILL_IMAGE_SHARE_MODE_REQUESTED_LOCAL: 2,
  STILL_IMAGE_SHARE_MODE_REQUESTED_REMOTE: 3,
  STILL_IMAGE_SHARE_MODE_ENTERED: 4,
  STILL_IMAGE_SHARE_MODE_ERROR: 5,
};

export const STILL_IMAGE_SHARE_REQUEST_STATUS = {
	STILL_IMAGE_SHARE_REQUEST_COMPLETE: 0,
	STILL_IMAGE_SHARE_REQUEST_ACCEPTED_LOCAL: 1,
	STILL_IMAGE_SHARE_REQUEST_ACCEPTED_REMOTE: 2,
	STILL_IMAGE_SHARE_REQUEST_REQUESTED_LOCAL: 3,
	STILL_IMAGE_SHARE_REQUEST_REQUESTED_REMOTE: 4,
	STILL_IMAGE_SHARE_REQUEST_REJECTED_LOCAL: 5,
	STILL_IMAGE_SHARE_REQUEST_REJECTED_REMOTE: 6,
};

export const HOST_NEGOTIATION = {
  HOST_REQUEST: 0,
  HOST_REQUEST_ACCEPTED: 1,
  HOST_REQUEST_REJECTED: 2,
};

export const FILE_TRANSFER_FLAG = {
	FILE_TRANSFER_FLAG_SHARE		: 0x00000001,
	FILE_TRANSFER_FLAG_FAKE_SEND	: 0x00000002,
	FILE_TRANSFER_FLAG_FAKE_RECV	: 0x00000004,
	FILE_TRANSFER_FLAG_SIZE_INFO	: 0x00000008,
	FILE_TRANSFER_FLAG_RAM_BUFFER	: 0x00000010,
	FILE_TRANSFER_FLAG_FIRST		: 0x00000020,
	FILE_TRANSFER_FLAG_LAST			: 0x00000040,	
};

export const FocusMode = {
	AutomaticFocus					: 0,
	ManualFocus						: 1
};

export const FocusDirection = { 
	FocusNear						: 1,
	FocusFar						: 2,
	FocusUnchanged					: 3
};

export const IllumState = { 
	Off								: 0,
	On								: 1,
	MacroOn							: 2
};

export const IlluminationModeFlags = {
	IllumModeOff 					: 0x0001,	// flash/torch off
	IllumModeOn 					: 0x0002,	// flash on
	IllumModeAuto 					: 0x0004,	// flash auto
	IllumModeRedeye 				: 0x0008,	// flash auto with red eye reduction
	IllumModeTorchOn 				: 0x0010,	// torch on
	IllumModeTorchAuto 				: 0x0020,
	IllumModeMacro 					: 0x0040,
	IllumModeLaser 					: 0x0080
};

export const HostNegotiation = {
	HostRequest: 0,
	HostRequestAccepted: 1,
	HostRequestRejected: 2,
};

export const ParticipantChangeReasons = {
  PCR_Addition : 0,
  PCR_Removal : 1,
  PCR_Update : 2,
  PCR_Addition_AlreadyInConference : 3
}
export const BITRATE_INFO_FLAGS = {
  RenegotiateRequest    : 0x00000001,
  RenegotiateReject   : 0x00000002
};

export const IMAGE_SHARE_MODE = {
  SEND: 0,
  RECEIVE: 1,
  NONE:2,
};

export const LSDS_EVENT = {
  CFGCTRL: 'cfgctrl',
  TELEPOINT: 'telepoint',
};

export const EZoomDirection = {
  ZoomTele: 1,
  ZoomWide: 2,
  ZoomUnchanged: 3,
};

export const EXIF_CLR_CODE = '#FF8080'
