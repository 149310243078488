export const ROUTES = {
  LANDING_PAGE: '/',
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  CALL_DASHBOARD: '/call-dashboard',
  CONTACTS: '/contacts',
  SEARCH: '/search',
  ADD_CONTACTS: '/add-contact',
  CALL_HISTORY: '/call-history',
  CALL_PARTICIPANTS: '/participants',
  VIDEO: '/calling',
  AUDIO_SOURCE: '/audio-source',
  VIDEO_SOURCE: '/video-source',
  MORE_ACTIONS: '/more-actions',
  SETTINGS: '/settings',
  MY_PROFILE: '/my-profile',
  GENERAL: '/general',
  CALLS: '/calls',
  VIDEO_QUALITY: '/video-quality',
  BANDWIDTH_CONTROL: '/bandwidth-control',
  CHANGE_PASSWORD: '/change-password',
  GUEST_INVITE: '/invite-guest',
  DEEPLINK: {
    LAUNCH: '/launch',
    MEETING: '/meeting',
    SSO: '/sso/',
  },
  CALL_DASHBOARD_DEMO: '/call-dashboard-demo',
};

export const LANDING_PAGE_ROUTE = ROUTES.DASHBOARD;
