/* eslint-disable react/prop-types */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import Switch from 'react-switch';
import { Row, Col, Form } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';

// component
import Sidebar from 'COMPONENTS/Dashboard/Sidebar';

// Custom component
import CustomForm from 'COMPONENTS/CustomComponents/CustomForm/CustomForm';

// Translation
import { translate } from 'SERVICES/i18n';

// Constant
import { SWITCH_COMPONENT } from 'UTILS/constants/UtilityConstants';

// Services
import { onsightProductVersion } from 'SERVICES/LSDatastream/Onsight';

// Asset
import { ReactComponent as BackIcon } from 'ASSETS/icon_back_arrow.svg';

// Style
import './Settings.scss';

export default class General extends Component {
  constructor(props) {
    super(props);

    const PERMISSION_NO = 'no';

    this.state = {
      gpsEnabled: this.props?.data ?
        (this.props.data?.securitySettings?.allow_gps_location !== PERMISSION_NO)
        : (this.props?.securitySettings?.allow_gps_location !== PERMISSION_NO),
    };
  }

  toggleSwitch = () => {
    this.setState({
      gpsEnabled: !this.state.gpsEnabled,
    });
  }

  renderContent = () => (
    <Row className={`px-3 ${isMobileOnly && 'landscape-side-margins mt-3'} ${this.props.isPortrait && 'm-2'}`}>
      <CustomForm
        formId='general'
      >
        <Form.Group className='p-1'>
          <Row>
            <Col md={8} lg={8} sm={9} xs={10} className='pt-1'>
              <Form.Label>
                <span className='p-0 font-regular'>
                  {translate('settings.generalText')}
                </span>
              </Form.Label>
            </Col>
            <Col className='align-horizontal-end self-align-center disabled-state '>
              <Switch
                onChange={this.toggleSwitch}
                checked={this.state.gpsEnabled}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor={SWITCH_COMPONENT.SELECT_COLOR}
                onHandleColor={SWITCH_COMPONENT.BACKGROUND_COLOR}
                className={
                  this.state.gpsEnabled
                    ? 'checked-button'
                    : 'unchecked-button'
                }
                handleDiameter={SWITCH_COMPONENT.DIAMETER}
                height={SWITCH_COMPONENT.HEIGHT}
                width={SWITCH_COMPONENT.WIDTH}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className='p-2'>
          <Row className='about p-2'>
            {translate('settings.about')}
          </Row>
          <Row className='version p-2'>
            {` ${translate('settings.version')} ${onsightProductVersion()} `}
          </Row>
          <Row className='p-2'>
            <a
              href={window.dynamicEnv.REACT_APP_EULA_LINK}
              target='_blank'
              className='eula-link p-0'
              rel='noopener noreferrer external'
            >
              {translate('settings.eula')}
            </a>
          </Row>
        </Form.Group>
      </CustomForm>
    </Row>
  )

  render() {
    return (
      <div className={`h-100 general ${this.props.isCallDashboard ? 'position-absolute' : ''}`}>
        {(isMobileOnly) ? (
          <Sidebar>
            <Row lg={8} md={8} sm={8} xs={8} className={`pt-2 px-3 ${isMobileOnly && 'landscape-side-margins'} ${this.props.isPortrait && 'm-2'}`}>
              <Col className='d-lg-none d-xl-none' xs={1} sm={1} lg={1} md={1}>
                <BackIcon
                  className='back-icon'
                  alt='back'
                  role='presentation'
                  onClick={this.props.goBack}
                />
              </Col>
              <Col className='alignment'>
                <span className='header-label ps-1'>
                  {translate('settings.general')}
                </span>
              </Col>

            </Row>
            <Row className='px-2'>
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className='divider' />
              </Col>
            </Row>
            {this.renderContent()}
          </Sidebar>
        ) : (
          <div>
            {this.renderContent()}
          </div>
        )}
      </div>
    );
  }
}
