import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import RangeSlider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';

// Constants
import { RANGE_SLIDER_VALUES } from 'UTILS/constants/UtilityConstants';

// Assets
import { ReactComponent as StrokeIcon } from 'ASSETS/icon_stroke.svg';

// Style
import './RangeSlider.scss';

/**
 *
 * @param {number} rangeValue value of the range slider
 * @param {function} setRangeValue onChange handler to set the rangeWidth
 * @returns jsx for range width slider
 */
function RangeSliderComponent({ rangeValue, setRangeValue, minValue,
  maxValue, sliderStep, isStroke }) {
  return (
    <Col md={12} xs={12} lg={12} sm={12} className='range-slider-component'>
      <Row className='range-component'>
        {isStroke && (
          <Col
            md={4}
            xs={4}
            lg={4}
            sm={4}
          >
            <StrokeIcon className='stoke-icon' />
          </Col>
        )}
        {!isStroke && (
          <span className='range-value'>
            {Math.round(rangeValue * 10) / 10}
            x
          </span>
        )}
        <Col
          md={isStroke ? 8 : 12}
          xs={isStroke ? 8 : 12}
          className={`d-flex ${isStroke && 'px-0'}`}
        >
          {!isStroke && (
            <Col md={1} xs={1}>
              <span className='label align-center'>
                {minValue}
                x
              </span>
            </Col>
          )}
          <Col md={10} xs={10} className={`slider ${!isStroke && 'px-2'}`}>
            <div className='slider-horizontal'>
              <RangeSlider
                min={minValue}
                max={maxValue}
                value={rangeValue}
                orientation={RANGE_SLIDER_VALUES.HORIZONTAL}
                onChange={setRangeValue}
                step={sliderStep}
                tooltip={false}
              />
            </div>
          </Col>

          {!isStroke && (
            <Col md={1} xs={1}>
              <span className='label align-center'>
                {maxValue}
                x
              </span>
            </Col>
          )}
        </Col>
      </Row>
    </Col>
  );
}
RangeSliderComponent.propTypes = {
  rangeValue: PropTypes.number.isRequired,
  setRangeValue: PropTypes.func.isRequired,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  sliderStep: PropTypes.number.isRequired,
  isStroke: PropTypes.bool,
};
RangeSliderComponent.defaultProps = {
  isStroke: true,
};
export default RangeSliderComponent;
