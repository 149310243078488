import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

// Translation
import { translate } from 'SERVICES/i18n';

// Constants
import { BUTTON_TYPES } from 'UTILS/constants/UtilityConstants';

function CustomButton(props) {
  const getClasses = () => {
    let className = '';
    if (props.variant === BUTTON_TYPES.PRIMARY) {
      className += 'primary-button';
    } else if (props.variant === BUTTON_TYPES.SECONDARY) {
      className += 'secondary-button';
    }

    if (props.className) {
      className += ` ${props.className}`;
    }
    return className;
  };

  return (
    <Button
      id={props.id}
      type={props.type}
      key={props.buttonKey}
      hidden={props.hidden}
      variant={props.variant}
      onClick={props.onClick}
      className={getClasses()}
      disabled={props.disabled}
      tabIndex={props.tabIndex}
      onKeyPress={props.onKeyPress}
    >
      {translate(props.text)}
      {props.children}
    </Button>
  );
}

CustomButton.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
  children: PropTypes.object,
  onKeyPress: PropTypes.func,
  className: PropTypes.string,
  hidden: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  buttonKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf([BUTTON_TYPES.PRIMARY, BUTTON_TYPES.SECONDARY]),
};

CustomButton.defaultProps = {
  id: '',
  text: '',
  tabIndex: '',
  buttonKey: '',
  type: 'button',
  className: '',
  hidden: false,
  disabled: false,
  onClick: () => null,
  onKeyPress: () => null,
  variant: BUTTON_TYPES.PRIMARY,
};
export default CustomButton;
