/**
 * Contains a generic Finite State Machine implementation;
 * to be used for call specific components
 * A few pending TODOs:
 * 1. Add a delayed action handler with a intrinsic expiry timer
 * 2. Add an optional expiry timer for action
 *
 * Note: For FSM documentation, added logs to help generate FSM using DOT notation.
 * e.g: http://magjac.com/graphviz-visual-editor/
 */

import _ from 'lodash';
import { AppLogger } from 'SERVICES/Logging/AppLogger';

const FSM_NO_HANDLER = null;
const FSM_NO_CHANGE = null;
const FSM_ALLOW_ALL = null;
const FSM_DISALLOW_NONE = null;

/** Generic FSM
 * @param   {string}          fsmid               Unique Id mainly used for logging purposes
 *                                                to distinguish specific FSM activities
 * @param   {string|integer}  initialState        Initial or starting state after FSM is
 * initialized.
 * @param   {object}          config              Additional configuration for FSM; currently only
 *                                                debug {true|false}
 *
 * @returns {object}                              { fsm, activateFunction, FSM_EVENT, FSM_ACTION }
 *                                                fsm: state machine using which action/event
 *  handling triggers
 *                                                activateFunction: The function that triggers
 *  the activation of the machine
 *                                                FSM_EVENT: Convenience object that stores all the
 * registered  events with their ids. e.g. testEvent has id of TEST_EVENT;
 * and FSM_EVENT = { TEST_EVENT: testEvent }
 *                                                FSM_ACTION: Convenience object that stores all the
 * registered  actions with their ids. e.g. testAction has id of TEST_ACTION;
 * and FSM_ACTION = { TEST_ACTION: testAction }
 *
 */
export const FSM = (fsmid, initialState, config = { debug: false, logger: null }) => {
  const FSM_EVENT = {};
  const FSM_ACTION = {};
  let debugFlag = false;
  if (config.debug === true) {
    debugFlag = true;
  }
  const logger = config.logger ?? AppLogger(fsmid);

  /**
   * The state machine object that manages the transitions flow
   */
  const machine = {
    state: initialState,
    context: '',

    /**
     * Specify callback function which will be called after the state change.
     * Optionally, you could pass the trigger to call the callback only if the
     * state changed in response to the trigger
     */

    currentState() {
      return this.state;
    },

    setState(nextState) {
      if (this.state !== nextState) {
        this.state = nextState;
      }
    },

    setContext(contextData) {
      this.context = contextData;
    },

    isState(state) {
      return state === this.state;
    },

    /**
     * Dispatches the event handler and updates the machine state as required
     * @param {string} event      The event received
     * @param {object} evtData    Any additional data that needs to be provided
     * for event processing
     * @returns none
     */
    /* eslint-disable-next-line no-unused-vars */
    process(event, evtData) {
      if (Object.values(FSM_EVENT).includes(event) === false) {
        logger.error(
          `Error: Event ${event} not registered.`,
        );
        return;
      }
      const handlerName = 'process' + _.capitalize(event);
      this[handlerName](evtData);
    },

    /**
     * Triggers the registered action handler. Also updates the state as defined through transitions
     * data.
     *
     * @param {string} action           The action being triggered either as user initiated
     * or initiated through some other events processing
     * @param {object} actionData       Data being passed to action handler from the caller
     * @returns none
     */
    /* eslint-disable-next-line no-unused-vars */
    trigger(action, actionData) {
      if (Object.values(FSM_ACTION).includes(action) === false) {
        logger.error(`Error: Action ${action} not registered.`);
        return;
      }
      const handlerName = action;
      this[handlerName](actionData);
    },
  };

  /**
   * Creates a special handler function in the machine specific to each action registered.
   * Also, prepares FSM_ACTION object to provide a map of registered action Ids and names.
   *
   * @param {string} action
   * @param {object} actionDef      Details of action with fields:
   *  { startingState, handler,  allowed, notAllowed }
   * allowed, and notAllowed states is an optional list of which is being checked
   * if present before triggering the action.
   * }
   */
  // eslint-disable-next-line no-unused-vars
  const addAction = (action, actionDef) => {
    logger.assert(action !== null && actionDef !== null);
    logger.assert(actionDef.startingState !== undefined,
      `Error! Starting state value undefined for ${action}`);

    FSM_ACTION[_.snakeCase(action).toUpperCase()] = action;

    machine[action] = (actionData = null) => {
      let rtn = null;
      const prevState = machine.state;
      if ((actionDef.allowed !== null) &&
        (actionDef.allowed.length > 0) &&
        (!actionDef.allowed.includes(machine.state))) {
        logger.error(`Action: ${action} not allowed in state: ${machine.state}`);
        return rtn;
      }
      if (actionDef.startingState !== FSM_NO_CHANGE) {
        machine.setState(actionDef.startingState);
      }
      /* Note: DO not change the log below.
      This log created for FSM documentation follows dot notation for
      */
      logger.info(
        machine.context ? `[${machine.context}]` : '',
        `${prevState} -> ${machine.state} [ "Act:${action}" ] `,
        (actionData !== null) ? JSON.stringify(actionData) : '',
      );
      if (actionDef.handler) {
        rtn = actionDef.handler();
      }
      return rtn;
    };
  };

  /**
   * Creates a special handler function in the machine specific to each event registered.
   * Also, prepares FSM_EVENT object to provide a map of registered event Ids and names.
   *
   * @param {string} action
   * @param {object} actionDef      Details of action with fields:
   *  { nextState, handler,  allowed, notAllowed }
   * allowed, and notAllowed states is an optional list of which is being checked
   * if present before processing the event. Correspondingly an error shall be logged in case
   * the state is not according to the allowed/not allowed list.
   * Both nextState and handler can not be null for the event.
   * }
   */
  // eslint-disable-next-line no-unused-vars
  const addEvent = (event, eventDef) => {
    const handlerName = 'process' + _.capitalize(event);
    FSM_EVENT[_.snakeCase(event).toUpperCase()] = event;

    logger.assert(eventDef.nextState !== undefined,
      `Error! Next state value undefined for ${event}`);

    if ((eventDef.handler === FSM_NO_HANDLER) &&
      (eventDef.nextState === FSM_NO_CHANGE)) {
      logger.error(`Error! Can not register event ${event} with No handler`,
        ' and No Next State');
      return;
    }

    /* Creates a specific handler for the event
    e.g. for event testEvent handler created as
    machne.processTestevent
    The handler can be called directly or through process function call
    */
    machine[handlerName] = (eventData = null) => {
      let rtn = null;
      const prevState = machine.state;

      if (eventDef.nextState) {
        machine.setState(eventDef.nextState);
      }
      /* Note: DO not change the log below.
      This log created for FSM documentation follows dot notation for
      */
      logger.info(
        machine.context ? `[${machine.context}]` : '',
        `${prevState} -> ${machine.state} [ "Evt:${event}" ]`,
        eventData !== null ? JSON.stringify(eventData) : '',
      );
      if (eventDef.handler !== FSM_NO_HANDLER) {
        rtn = eventDef.handler(eventData);
      }
      return rtn;
    };
    if (debugFlag) logger.debug(`Added Event hander: ${handlerName} for ${event}`);
  };

  /**
   * Readies the action handlers for registered actions
   * @param {object} actions    An object containing all actions for the machine
   * @returns none
   */
  // eslint-disable-next-line no-unused-vars
  const assignActionHandlers = (actions) => {
    if (actions === null) return;
    // eslint-disable-next-line no-restricted-syntax
    for (const [actionName, action] of Object.entries(actions)) {
      const sanitizedAction = {
        startingState: action.startingState,
        successEvent: action.successEvent ? action.successEvent : null,
        handler: action.handler ? action.handler : null,
        allowed: action.allowed ? action.allowed : null,
      };
      addAction(actionName, sanitizedAction);
    }
  };

  /**
   * Readies the event handlers for registered actions
   * @param {object} actions    An object containing all events for the machine
   * @returns none
   */
  // eslint-disable-next-line no-unused-vars
  const assignEventHandlers = (events) => {
    if (events === null) return;
    // eslint-disable-next-line no-restricted-syntax
    for (const [eventName, event] of Object.entries(events)) {
      const sanitizedEvent = {
        nextState: event.nextState,
        handler: event.handler ? event.handler : null,
        allowed: event.allowed ? event.allowed : null,
      };
      addEvent(eventName, sanitizedEvent);
    }
  };

  /**
   * Readies the event handlers for registered actions
   * @param {object} fsm    The object returned in initial call to FSM
   * @returns none
   */
  const activate = (fsm) => {
    assignActionHandlers(fsm.actions);
    assignEventHandlers(fsm.events);
    logger.debug('Active');
  };

  return { fsm: machine, activate, state: machine.currentState, FSM_EVENT, FSM_ACTION, logger };
};

export default FSM;
export { FSM_NO_CHANGE, FSM_NO_HANDLER, FSM_ALLOW_ALL, FSM_DISALLOW_NONE };
