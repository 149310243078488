import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import rootReducer from './RootReducer';

//
let store = null;
let persistor = null;
//
function configureStore() {
  const persistConfig = {
    key: 'ocRoot',
    storage: storageSession,
  };

  const enhancers = [];
  const middlewares = [thunk];

  if (process.env.NODE_ENV === 'development') {
    // add this enhancer to show our Redux state in Redux dev tool
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  const composedEnhancers = compose(
    applyMiddleware(...middlewares),
    ...enhancers,
  );

  const persistedReducer = persistReducer(persistConfig, rootReducer);
  store = createStore(persistedReducer, composedEnhancers);
  persistor = persistStore(store);
}

export default function getStore() {
  if (store === null) {
    configureStore();
  }
  return { store, persistor };
}
