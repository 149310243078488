import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslations from 'LANGUAGES/en.json';
import frTranslations from 'LANGUAGES/fr.json';
import jaTranslations from 'LANGUAGES/ja.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: enTranslations,
  },
  fr: {
    translation: frTranslations,
  },
  ja: {
    translation: jaTranslations,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector) // pass browser language detector
  .init({
    detection: { order: ['navigator'] },
    fallbackLng: 'en',
    resources,
    // lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export function changeLanguage(language) {
  i18n.changeLanguage(language);
}

export function translate(key, interpolation) {
  return i18n.t(key, interpolation);
}

export default i18n;
