/* eslint-disable react/prop-types */
import React from 'react';
import { Row, Col } from 'reactstrap';
import Switch from 'react-switch';
import { translate } from 'SERVICES/i18n';
import { isMobile } from 'react-device-detect';
import Select from 'react-select';

// Components
import CustomPopover from 'COMPONENTS/CustomComponents/CustomPopover/CustomPopover';

// Constants
import { NLP_LANGUAGES } from 'UTILS/constants/NlpConstants';
import { SWITCH_COMPONENT, POPOVER_PLACEMENT } from 'UTILS/constants/UtilityConstants';

// Styles
import './NlpPopover.scss';

function NlpPopover({ isNlpPopUpOpen, toggle, closeNLPPopover,
  captionsOn, fromLanguage, toLanguage, isLandscape, updateNLPData, nlpUrl }) {
  const isLandscapeNLP = window.screen?.width > window.screen.height && isLandscape;
  const menuPlacement = isMobile && isLandscapeNLP
    ? POPOVER_PLACEMENT.TOP_END : POPOVER_PLACEMENT.TOP_START;
  const enableCaptions = captionsOn;
  const selectedToLanguage = NLP_LANGUAGES.find((i) => i.value === toLanguage);
  const selectedFromLanguage = NLP_LANGUAGES.find((i) => i.value === fromLanguage);

  const handleClickOutside = () => {
    closeNLPPopover();
  };

  const toggleSwitch = () => {
    updateNLPData(!enableCaptions, selectedFromLanguage.value, selectedToLanguage.value);
  };

  const changeToLang = (e) => {
    updateNLPData(enableCaptions, selectedFromLanguage?.value, e?.value);
  };

  const changeFromLang = (e) => {
    updateNLPData(enableCaptions, e?.value, selectedToLanguage?.value);
  };

  // For desktop and mobile portrait mode
  const popoverUI = () => (
    <>
      <Row md={12} lg={12} sm={12} className='w-100 p-2 m-0 captions'>{translate('nlp.translateFrom')}</Row>
      <Row md={12} lg={12} sm={12} className='w-100 align-center m-0'>
        <Select
          options={NLP_LANGUAGES}
          isSearchable
          onChange={(e) => changeFromLang(e)}
          value={selectedFromLanguage}
          className='custom-select'
          classNamePrefix='nlp'
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </Row>
      <Row md={12} lg={12} sm={12} className='w-100 p-2 m-0 captions'>{translate('nlp.translateTo')}</Row>
      <Row md={12} lg={12} sm={12} className='w-100 align-center m-0'>
        <Select
          options={NLP_LANGUAGES}
          isSearchable
          onChange={(e) => changeToLang(e)}
          value={selectedToLanguage}
          className='custom-select'
          classNamePrefix='nlp'
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </Row>
    </>
  );

  // For devices 'landscape mode' only
  const landscapePopoverUI = () => (
    <div className='p-0 m-0'>
      <Row md={12} sm={12} className='w-100 align-center p-0 py-1 m-0'>
        <Col md={6} sm={6} className='p-2 m-0 captions'>{translate('nlp.translateFrom')}</Col>
        <Col md={6} sm={6} className='p-2 m-0 captions'>{translate('nlp.translateTo')}</Col>
      </Row>
      <Row md={12} sm={12} className='w-100 align-center p-0 py-1 m-0'>
        <Col md={6} sm={6} className='m-0 align-center'>
          <Select
            options={NLP_LANGUAGES}
            isSearchable
            onChange={(e) => changeFromLang(e)}
            value={selectedFromLanguage}
            className='custom-select p-0'
            classNamePrefix='nlp'
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </Col>
        <Col md={6} sm={6} className='align-center m-0'>
          <Select
            options={NLP_LANGUAGES}
            isSearchable
            onChange={(e) => changeToLang(e)}
            value={selectedToLanguage}
            className='custom-select p-0'
            classNamePrefix='nlp'
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </Col>
      </Row>
    </div>
  );

  const displayNLPUI = () => (
    <div className={!nlpUrl ? 'disable-ui' : ''}>
      <Row className='m-0 w-100 space-between'>
        <Col className='captions px-2'>{translate('nlp.captions')}</Col>
        <Col className='pr-0 align-horizontal-end'>
          <Switch
            onChange={toggleSwitch}
            checked={captionsOn}
            uncheckedIcon={false}
            checkedIcon={false}
            onColor={SWITCH_COMPONENT.SELECT_COLOR}
            onHandleColor={SWITCH_COMPONENT.BACKGROUND_COLOR}
            className={
              enableCaptions
                ? 'checked-button'
                : 'unchecked-button'
            }
            handleDiameter={SWITCH_COMPONENT.DIAMETER}
            height={SWITCH_COMPONENT.HEIGHT}
            width={SWITCH_COMPONENT.WIDTH}
          />
        </Col>
      </Row>

      <Row className='m-0 w-100'>
        <div className='nlp-divider' />
      </Row>

      {(isMobile && isLandscapeNLP) ? landscapePopoverUI() : popoverUI()}
    </div>
  );

  return (
    <CustomPopover
      isPopoverOpen={isNlpPopUpOpen}
      togglePopover={toggle}
      menuPlacement={menuPlacement}
      closeCustomPopover={handleClickOutside}
      title='nlp.header'
      child={displayNLPUI()}
      target='brain'
      id='nlp-popover'
      className={(isMobile && isLandscapeNLP) ? 'landscape-nlp' : 'nlp-popover'}
    />
  );
}
export default NlpPopover;
