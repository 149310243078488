import axiosHelper from 'SERVICES/AxiosHelper';
import { API_METHODS } from 'UTILS/constants/ApiConstants';
import MeetingSubscriber, { MeetingSubscriberState, ClientMeetingResult, ParticipantState } from 'SERVICES/Meetings/MeetingSubscriber';

export const MeetingJoinError = {
  GenericError: 0,
  MeetingNotFound: 1,
  MeetingDeleted: 2,
  NotHost: 3,
  MeetingDisconnected: 4,
};

const MeetingErrorCode = {
  NoMeetingId: 1,
  NotFound: 2,
  Deleted: 3,
  NotInSameDomain: 4,
  BadQueryParameters: 5,
  NonHostUpdatingMeeting: 6,
  NonHostDeletingMeeting: 7,
  NotDeleted: 8,
  NoParticipantFound: 9,
  NoParticipantsFound: 10,
  NotCreated: 11,
  NotUpdated: 12,
};

class MeetingsManager {
  constructor(options) {
    this.meetingSubscriber = null;
    this.meetingsUrl = options.meetingsUrl;
    this.sessionToken = options.sessionToken;
    this.userUniqueId = options.userUniqueId;
    this.userCalledInAsNewParticipant = false;
    this.userAddedThemselvesAsNewParticipant = false;
    this.allParticipants = [];
    this.callbacks = options.callbacks;
  }

  getMeetingId() {
    return this.meetingSubscriber?.meeting?.meetingId;
  }

  isInMeeting() {
    const participant = (this.meetingSubscriber?.meeting?.participants?.find((p) =>
      p.userId === this.userUniqueId));
    return (participant && participant.state !== ParticipantState.NotRegistered);
  }

  isActiveInMeeting() {
    const participant = (this.meetingSubscriber?.meeting?.participants?.find((p) =>
      p.userId === this.userUniqueId));
    return (participant && participant.state === ParticipantState.Active);
  }

  isMeetingHostActive() {
    return this.meetingSubscriber?.meeting?.host?.state === ParticipantState.Active;
  }

  isMeetingHostAddress(address) {
    return address && (this.meetingSubscriber?.meeting?.host?.address === address.replace('sip:', ''));
  }

  getAllParticipants() {
    return this.allParticipants;
  }

  async joinMeeting(meetingId) {
    const meeting = await this.getMeetingById(meetingId);
    if (meeting) {
      // All the invited participants list
      this.allParticipants = meeting.participants;
      if (meeting.participants.find((p) => p.userId === this.userUniqueId)) {
        if (meeting.host.userId === this.userUniqueId) {
          if (typeof this.callbacks.meetingErrorEvent === 'function') {
            this.callbacks.meetingErrorEvent(MeetingJoinError.NotHost);
          }
          return;
        }
        await this.exitMeeting();
        await this.subscribeToMeeting(meeting);
      } else {
        this.userAddedThemselvesAsNewParticipant = true;
        this.addCurrentUserAsNewMeetingParticipant(meeting.meetingId, false);
      }
    }
  }

  async getMeetingById(meetingId) {
    const requestUri = `${this.meetingsUrl}/meetings/${meetingId}`;
    let meeting = null;
    try {
      const res = await axiosHelper
        .callAPI({
          httpMethod: API_METHODS.GET,
          uri: requestUri,
          requestHeaders: {
            'X-Session-Token': this.sessionToken,
          },
        });
      console.debug('_MeetingsManager::getMeetingById', JSON.stringify(res));
      meeting = res.data;
    } catch (error) {
      console.error('_MeetingsManager::getMeetingById', JSON.stringify(error));
      this.handleMeetingAPIError(error?.data);
    }
    return meeting;
  }

  async addNewUserToMeeting(meetingId, participantId) {
    const requestUri = `${this.meetingsUrl}/meetings/${meetingId}/participants/${participantId}`;
    let meeting = null;
    try {
      const res = await axiosHelper
        .callAPI({
          httpMethod: API_METHODS.POST,
          uri: requestUri,
          requestHeaders: {
            'X-Session-Token': this.sessionToken,
          },
        });
      console.debug('_MeetingsManager::addNewUserToMeeting', JSON.stringify(res));
      meeting = res.data;
    } catch (error) {
      console.error('_MeetingsManager::addNewUserToMeeting', JSON.stringify(error));
    }
    return meeting;
  }

  async subscribeToMeeting(meeting) {
    this.meetingSubscriber = new MeetingSubscriber(
      {
        meeting,
        meetingsUrl: this.meetingsUrl,
        sessionToken: this.sessionToken,
        participantStateChangedEvent: this.handleMeetingParticipantStateChange.bind(this),
        meetingParticipantsChangedEvent: this.handleMeetingParticipantsChanged.bind(this),
        meetingJoinedEvent: this.handleJoinMeeting.bind(this),
        meetingSubscriberStateChangedEvent: this.handleMeetingSubscriberStateChanged.bind(this),
        meetingFullEvent: this.handleMeetingFull.bind(this),
      },
    );
    if (await this.meetingSubscriber.connect() !== ClientMeetingResult.Success) {
      await this.meetingSubscriber.disconnect();
      this.meetingSubscriber = null;
    }
  }

  async exitMeeting() {
    if (typeof this.callbacks.meetingStateChangedEvent === 'function') {
      this.callbacks.meetingStateChangedEvent(ParticipantState.NotRegistered, true, null);
    }

    this.userCalledInAsNewParticipant = false;
    this.userAddedThemselvesAsNewParticipant = false;

    if (this.meetingSubscriber) {
      const disconnectingSubscriber = this.meetingSubscriber;
      this.meetingSubscriber = null;
      await disconnectingSubscriber.deregister();
      await disconnectingSubscriber.disconnect();
    }
    console.info('End meeting.');
  }

  async addCurrentUserAsNewMeetingParticipant(meetingId, calledIn) {
    try {
      const meeting = await this.addNewUserToMeeting(meetingId, this.userUniqueId);
      if (meeting) {
        this.userCalledInAsNewParticipant = calledIn;
        await this.subscribeToMeeting(meeting);
      }
    } catch (error) {
      console.error('_MeetingsManager::addCurrentUserAsNewMeetingParticipant', JSON.stringify(error));
      this.handleMeetingAPIError(error?.data);
    }
  }

  handleMeetingParticipantStateChange(participant, oldState) {
    console.debug('_MeetingsManager::handleMeetingParticipantStateChange', JSON.stringify(participant), oldState);
    if (!this.userCalledInAsNewParticipant) {
      if (typeof this.callbacks.meetingStateChangedEvent === 'function') {
        this.callbacks.meetingStateChangedEvent(participant.state, false, participant);
      }
    }
  }

  handleMeetingParticipantsChanged(participant, isNewParticipant) {
    console.debug('_MeetingsManager::handleMeetingParticipantsChanged', JSON.stringify(participant), isNewParticipant);
  }

  async handleJoinMeeting(meeting) {
    console.debug('_MeetingsManager::handleJoinMeeting', this.sessionToken, this, JSON.stringify(meeting));
    const result = await this.meetingSubscriber.register(
      this.userCalledInAsNewParticipant,
      this.userAddedThemselvesAsNewParticipant,
    );
    this.handleJoinMeetingError(result);
  }

  handleMeetingSubscriberStateChanged(state) {
    console.debug('_MeetingsManager::handleMeetingSubscriberStateChanged', state);
    if (state === MeetingSubscriberState.Error && this.meetingSubscriber) {
      this.handleJoinMeetingError(MeetingJoinError.MeetingDisconnected);
      this.exitMeeting();
    }
  }

  firstTimeConnectingForCurrentUser(participant, oldState) {
    return (participant.state !== ParticipantState.NotRegistered &&
      oldState === ParticipantState.NotRegistered &&
      participant.userId === this.userUniqueId);
  }

  firstTimeConnectingForCurrentUserWhoWasInLobby(participant, oldState) {
    return (participant.State === ParticipantState.Active &&
      oldState === ParticipantState.InLobby &&
      participant.userId === this.userUniqueId);
  }

  handleJoinMeetingError(result) {
    if (result !== ClientMeetingResult.Success) {
      console.error(`_MeetingsManager:handleJoinMeetingError | Participant unable to register. Error: ${result}`);
      this.exitMeeting();
      if (result === ClientMeetingResult.MeetingFull &&
        typeof this.callbacks.meetingFullEvent === 'function') {
        this.callbacks.meetingFullEvent();
      } else if (typeof this.callbacks.meetingErrorEvent === 'function') {
        this.callbacks.meetingErrorEvent(MeetingJoinError.GenericError);
      }
    }
  }

  handleMeetingAPIError(error) {
    console.error(`_MeetingsManager:handleMeetingAPIError | Error: ${JSON.stringify(error)}`);

    let meetingError = MeetingJoinError.GenericError;
    switch (parseInt(error?.code, 10)) {
      case MeetingErrorCode.NotFound:
        meetingError = MeetingJoinError.MeetingNotFound;
        break;
      case MeetingErrorCode.Deleted:
        meetingError = MeetingJoinError.MeetingDeleted;
        break;
      default:
        meetingError = MeetingJoinError.GenericError;
        break;
    }
    if (typeof this.callbacks.meetingErrorEvent === 'function') {
      this.callbacks.meetingErrorEvent(meetingError);
    }
  }

  handleMeetingFull() {
    console.error('_MeetingsManager:handleMeetingFull | Error: The meeting is full and you are unable to join.');
    if (typeof this.callbacks.meetingFullEvent === 'function') {
      this.callbacks.meetingFullEvent();
    }
    this.exitMeeting();
  }
}

export default MeetingsManager;
