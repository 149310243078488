/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

// Custom component
import TextFieldComponent from 'COMPONENTS/CustomComponents/TextFieldComponent/TextFieldComponent';
import CustomButton from 'COMPONENTS/CustomComponents/CustomButton/CustomButton';

// Utility
import CommonUtility from 'UTILS/CommonUtility';

// Translation
import { translate } from 'SERVICES/i18n';

// Constant
import { BUTTON_TYPES, MUTE_ACTION } from 'UTILS/constants/UtilityConstants';
import { ParticipantState } from 'SERVICES/Meetings/MeetingSubscriber';

// Asset
import { ReactComponent as BackIcon } from 'ASSETS/icon_back_arrow.svg';
import { ReactComponent as OnVideoIcon } from 'ASSETS/icon_video_on_green.svg';
import { ReactComponent as OffVideoIcon } from 'ASSETS/icon_video_off.svg';
import { ReactComponent as MikeOnIcon } from 'ASSETS/icon_microphone_on.svg';
import { ReactComponent as MikeOffIcon } from 'ASSETS/icon_microphone_off.svg';
import { ReactComponent as SearchIcon } from 'ASSETS/icon_search.svg';

// Style
import './Participants.scss';

export default class Participants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
    };
  }

  componentDidUpdate() {
    this.renderList();
  }

  handleChange = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  };

  muteParticipant = (muteAll = false, participantId = null) => {
    if (muteAll) {
      this.props.muteParticipant(MUTE_ACTION.ALL_EXCEPT_LOCAL);
      console.debug('Mute ALL participants');
    } else {
      this.props.muteParticipant(MUTE_ACTION.LISTED, [participantId]);
    }
  };

  checkForEnterKeyPress = (e) => {
    if (e.key === 'Enter' && e.target.value !== '') {
      this.muteParticipant(true);
    }
  }

  renderList = (state) => {
    let inMeetingParticipants = null;
    switch (state) {
      case ParticipantState.NotRegistered:
        inMeetingParticipants =
          this.props.participants.filter((p) => p.meetingState === ParticipantState.NotRegistered);
        break;
      case ParticipantState.Active:
        inMeetingParticipants =
          this.props.participants.filter((p) => p.meetingState === ParticipantState.Active);
        break;
      default:
        inMeetingParticipants = this.props.participants;
    }
    return (
      <div className='participant-list-panel px-1'>
        {this.props.participants?.length &&
          inMeetingParticipants
            .map((participant, index) => (
              <Row
                key={participant.pId}
                lg={12}
                md={12}
                sm={12}
                className='ms-2 ps-1 me-0 me-lg-1 pe-lg-1 mb-1 participant-list-padding'
              >
                <Col
                  className='pt-2 col-xxl-9'
                  xl={8} // {7} Previous values when eraser icon was present
                  lg={8} // 6}
                  sm={9} // 8}
                  md={9} // 8}
                  xs={9} // 7}
                >
                  <Row className='align-center'>
                    <Col
                      xl={3}
                      lg={3}
                      md={2}
                      sm={2}
                      xs={2}
                      className='p-0 col-xxl-2 align-center'
                    >
                      <div
                        className='short-name align-center cursor-default'
                        style={{
                          backgroundColor:
                            participant?.meetingState === ParticipantState.NotRegistered
                              ? 'grey' : participant.colorCode,
                        }}
                      >
                        <span className='short-name-text align-center'>
                          {CommonUtility.findFirstChar(
                            participant?.name,
                          )?.substring(0, 2)}
                        </span>
                      </div>
                    </Col>
                    <Col
                      xl={9}
                      lg={9}
                      md={10}
                      sm={10}
                      xs={10}
                      className='pe-0 ps-4 ps-sm-3 ps-md-3 ps-lg-2 ps-xxl-2  col-xxl-10'
                    >
                      <div
                        role='presentation'
                        className='cursor-default participant ellipsis'
                        title={participant?.name}
                        id={'participant-name-' + index}
                      >
                        {`${participant?.name}`}
                      </div>
                    </Col>
                  </Row>
                </Col>

                {(participant.meetingState !== ParticipantState.NotRegistered) && (
                  <Col
                    xl={4} // {5}Previous values when eraser icon was present
                    lg={4} // 6}
                    sm={3} // 4}
                    md={3} // 4}
                    xs={3} // 5}
                    className='d-flex justify-content-start p-0 col-xxl-2 iconPositionForBigScreen'
                  >
                    <Row className='iconPositionForBigScreen mt-1'>
                      <Col
                        className='enabled-icons'
                        lg={4}// {3}
                        sm={3}
                        md={4}// {3}
                        xs={3}
                        onClick={() =>
                          this.muteParticipant(
                            false,
                            participant.pId,
                          )
                        }
                      >
                        {!participant.isMuted ? (
                          <MikeOnIcon
                            className='mike-on-icon'
                            alt='microphone'
                            id={'micOn_' + index}
                          />
                        ) : (
                          <MikeOffIcon
                            className='mike-off-icon'
                            alt='microphone'
                            id={'micOff_' + index}
                          />
                        )}
                      </Col>
                      <Col
                        className='enabled-icons'
                        lg={4} // {3}
                        sm={3}
                        md={4} // {3}
                        xs={3}
                        onClick={() => {
                          this.props.startOrRequestVideoStream(participant.pId);
                        }}
                      >
                        {
                          (participant.isVideoOn) ? (
                            <OnVideoIcon
                              alt='video on'
                              className='video-on-icon m-0'
                              id={'videoOn_' + index}
                            />
                          ) : (
                            <OffVideoIcon
                              alt='video off'
                              className='video-off-icon m-0'
                              id={'videoOff_' + index}
                            />
                          )
                        }
                      </Col>
                      {/* 2456 */}
                    </Row>
                  </Col>
                )}
              </Row>
            ))}
      </div>
    );
  };

  render() {
    return (
      <div className='participant-list-container'>
        <Row className='header-pane participant-title align-items-center mx-lg-0 pt-2'>
          <Col className='d-lg-none d-xl-none' xs='2'>
            <BackIcon
              className='back-icon'
              alt='back'
              role='presentation'
              onClick={this.props.goBack}
            />
          </Col>
          <Col className='alignment'>
            <span className='participants-header'>
              {translate('participants.Participants')}
            </span>
          </Col>
          <Col
            className='d-flex justify-content-end'
            xs='3'
            sm='3'
            md='3'
          >
            <CustomButton
              id='mute-button'
              onKeyPress={(e) =>
                this.checkForEnterKeyPress(e)
              }
              onClick={() =>
                this.muteParticipant(true)
              }
              variant={BUTTON_TYPES.SECONDARY}
            >
              <span className='mute-text'>
                {translate('participants.muteAll')}
              </span>
            </CustomButton>
          </Col>
        </Row>

        <Row className='divider-margin'>
          <div className='divider' />
        </Row>

        <div className='participant-list-pane'>
          <Row className='align-items-center participant-list mt-3 mb-2 mx-lg-0'>
            <Col lg={12} md={12} sm={12} xs={12} className='search-bar'>
              <TextFieldComponent
                type='text'
                id='search'
                value={this.state.searchText}
                placeholder={translate('participants.findPeople')}
                maxLength={100}
                className='search'
                handleChange={(event) => this.handleChange(event)}
                leftIcon={SearchIcon}
              />
            </Col>
          </Row>

          {/* In call meeting participants */}
          {this.props.isMeeting && (
            <Row className='participant-list'>
              <Col lg={12} md={12} sm={12} xs={12} className='sub-header m-2'>
                {translate('participants.meetingInCall')}
              </Col>
            </Row>
          )}

          <Row className='participant-list'>
            {this.props.participants.length === 0 ? (
              <Row
                lg={8}
                md={8}
                sm={8}
                className='margin-participant'
              >
                <Col className='c-name m-1'>
                  {translate('participants.noParticipants')}
                </Col>
              </Row>
            ) : (this.props.isMeeting ?
              this.renderList(ParticipantState.Active) :
              this.renderList()
            )
            }
          </Row>
          <Row className='participant-list mt-1 p-2'>
            <div className='participant-divider' />
          </Row>

          {/* Invited meeting participants */}
          {this.props.isMeeting && (
            <Row className='participant-list'>
              <Col lg={12} md={12} sm={12} xs={12} className='sub-header m-2'>
                {translate('participants.meetingInvited')}
              </Col>
            </Row>
          )}
          <Row className='participant-list'>
            {this.props.isMeeting && this.renderList(ParticipantState.NotRegistered)}
          </Row>
        </div>
      </div>
    );
  }
}
