import React from 'react';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import { getWorkspaceSettings } from 'STORE/ClientSettings/ClientSettingsSelector';
import { bindActionCreators } from 'redux';
import { withOrientationChange } from 'react-device-detect';
import { connect } from 'react-redux';
import { getAuthToken } from 'STORE/Auth/AuthSelector';
import './FilterSelect.scss';
import PropTypes from 'prop-types';

const animatedComponents = makeAnimated();

class FilterSelect extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      searchText: '',
      options: [],
      requestController: null,
      selected: this.props.selected,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleInputChange(event, action = 'input-change') {
    const searchTerm = event;
    if (action.action === 'input-change') {
      this.setState(() => ({
        searchText: searchTerm,
      }));
    }

    // cancel previous token if available
    const newAbortController = new AbortController();
    if (this.state.requestController) {
      this.state.requestController.abort();
    }

    this.getOptions(searchTerm, newAbortController);
  }

  getOptions(searchTerm, abortController) {
    this.props
      .suggest(
        searchTerm,
        this.props.authToken,
        this.props.workspaceSettings,
        abortController,
      )
      .then((res) => {
        if (!this._isMounted) return;
        this.setState(() => ({ options: res }));
      })
      .catch((error) => {
        console.error('FilterSelect::getOptions() failed - ', error);
      });
  }

  // eslint-disable-next-line no-unused-vars
  formatOptionLabel = ({ value, label, customAbbreviation }) => (
    <div>
      <div className=' ellipsis' title={label}>
        {value}
      </div>
    </div>
  );

  // eslint-disable-next-line no-unused-vars
  onChange = (opt) => {
    this.setState({ searchText: '', selected: opt });
    this.props.onChange(opt);
  }

  MultiValueComponent(props) {
    return (
      <components.MultiValue {...props}>
        {props.data.label}
      </components.MultiValue>
    );
  }

  clearText() {
    this.setState({ searchText: '' });
  }

  render() {
    return (
      <Select
        className='filter-select'
        classNamePrefix='filter-search'
        isSearchable
        placeholder={this.props.placeholder}
        // eslint-disable-next-line react/jsx-no-bind
        onMenuClose={this.clearText.bind(this)}
        closeMenuOnSelect
        // eslint-disable-next-line react/jsx-no-bind
        onChange={this.onChange.bind(this)}
        // eslint-disable-next-line react/jsx-no-bind
        formatOptionLabel={this.formatOptionLabel.bind(this)}
        inputValue={this.state.searchText}
        // eslint-disable-next-line react/jsx-no-bind
        onClose={this.clearText.bind(this)}
        escapeClearsValue
        hideSelectedOptions
        defaultValue={this.state.selected}
        // eslint-disable-next-line react/jsx-no-bind
        onInputChange={this.handleInputChange.bind(this)}
        isMulti
        options={this.state.options}
        components={{
          animatedComponents,
          MultiValue: this.MultiValueComponent,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
      />
    );
  }
}

FilterSelect.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  suggest: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  workspaceSettings: PropTypes.object,
  authToken: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selected: PropTypes.array.isRequired,
};

FilterSelect.defaultProps = {
  workspaceSettings: {},
  authToken: '',
};

const mapStateToProps = (state) => ({
  workspaceSettings: getWorkspaceSettings(state),
  authToken: getAuthToken(state),
});

// eslint-disable-next-line no-unused-vars
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getWorkspaceSettings,
      getAuthToken,
    },
    dispatch,
  );
}

export default withOrientationChange(
  connect(mapStateToProps, mapDispatchToProps)(FilterSelect),
);
