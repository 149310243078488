export const getUserProfile = (state) => state.UserReducer.userProfile;
export const getContactList = (state) => state.UserReducer.contactList;

// Call history
export const getCallHistoryRecords = (state) => state.UserReducer.callHistoryRecordsList;

// participants
export const getParticipants = (state) => state.UserReducer.participants;

// telestrations data
export const getTSData = (state) => state.UserReducer.selectedTelestrationDetails;

// illumination
export const getActiveIlluminationItem = (state) => state.UserReducer.activeIlluminationItem;
