/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

// Component
import ErrorBoundary from 'COMPONENTS/CustomComponents/ErrorBoundary/ErrorBoundary';
import ThemeManager from 'COMPONENTS/Login/Branding';

// Services
import { AppLogger, LOG_NAME } from 'SERVICES/Logging/AppLogger';

import {
  getBrandTheme,
  getBrandTitle,
  getBrandResourceUrl,
} from 'STORE/Theme/ThemeSelector';

// Route
import Router from 'ROUTES/Router';

// Style
import GlobalStyles from 'STYLES/Branding/globalThemes';
import './App.scss';
import { initializePendo } from './utils/pendoUtils';

function OurFallbackComponent({
  error,
  // eslint-disable-next-line no-unused-vars
  componentStack,
  resetErrorBoundary,
}) {
  return (
    <div>
      <h1>
        An error occurred:
        {error.message}
      </h1>
      <button type='button' onClick={resetErrorBoundary}>
        Try again
      </button>
    </div>
  );
}

function App() {
  const theme = useSelector(getBrandTheme);
  const title = useSelector(getBrandTitle);
  const brandResourceUrl = useSelector(getBrandResourceUrl);

  useEffect(() => {
    const head = document.getElementsByTagName('head')[0];
    const link1 = document.createElement('link');
    link1.rel = 'icon';
    const link2 = document.createElement('link');
    link2.rel = 'apple-touch-icon';
    // Wait for branding data to be loaded before updating favicons
    // to avoid multiple additions
    if (brandResourceUrl) {
      const brandingLogger = AppLogger(LOG_NAME.Branding);
      brandingLogger.log('Resource URL updated to', brandResourceUrl);
      document.title = title;
      link1.href = `${brandResourceUrl}/favicon.ico`;
      link2.href = link1.href;
      head.appendChild(link1);
      head.appendChild(link2);
    }

    initializePendo();
  }, [brandResourceUrl]);

  const query = new URLSearchParams(window.location?.search);
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <ErrorBoundary FallbackComponent={OurFallbackComponent}>
        <div className='App'>
          <Router />
        </div>
        <ThemeManager query={query} />
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
