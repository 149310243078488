/* eslint-disable no-use-before-define */
/* eslint-disable no-var */
// Services
import { AppLogger, LOG_NAME } from 'SERVICES/Logging/AppLogger';

const logger = AppLogger(LOG_NAME.WebAPI);

var isNotificationSupported = false;
var notification = null;
export const checkNotificationPromise = () => {
  try {
    Notification.requestPermission().then();
  } catch (e) {
    return false;
  }

  return true;
};

export const askNotificationPermission = () => {
  try {
    logger.info('Checking if the browser supports notifications');
    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      logger.info('This browser does not support notifications.');
    } else if (checkNotificationPromise()) {
      logger.info('Requesting permission for browser notifications');
      Notification.requestPermission()
        .then((permission) => {
          // eslint-disable-next-line no-use-before-define
          handleNotificationPermission(permission);
        });
    } else {
      logger.info('Requesting permission for browser notifications');
      Notification.requestPermission((permission) => {
        // eslint-disable-next-line no-use-before-define
        handleNotificationPermission(permission);
      });
    }
  } catch (error) {
    logger.error('Error requesting permission for browser notification', error);
  }
};

// eslint-disable-next-line no-unused-vars
export const handleNotificationPermission = (permission) => {
  try {
    if (Notification.permission === 'denied' || Notification.permission === 'default') {
      logger.info('Browser notification permission denied');
    } else {
      logger.info('Browser notification permission granted');
      isNotificationSupported = true;
    }
  } catch (error) {
    logger.error('Error handling browser notification permission', error);
  }
};

export const createNotification = (notificationTitle, notificationText,
  notificationIcon, notificationTag) => {
  // const webappURL = window.location.href;
  try {
    handleNotificationPermission();
    if (isNotificationSupported) {
      // eslint-disable-next-line no-unused-vars
      notification = new Notification(notificationTitle,
        { body: notificationText, icon: notificationIcon, tag: notificationTag });
      logger.info('New browser notification created');

      // navigate to the webapp
      notification.addEventListener('click', () => {
        // window.open(webappURL);
        window.focus();
      });

      // close the notification after 20 seconds
      setTimeout(() => {
        notification.close();
      }, 20 * 1000);
    }
  } catch (error) {
    showNotificationWithServiceWorker(notificationTitle, notificationText,
      notificationIcon, notificationTag);
    logger.warn('Error showing browser notification, trying now with service worker', error);
  }
};
const showNotificationWithServiceWorker = (notificationTitle, notificationText,
  notificationIcon, notificationTag) => {
  logger.log('showing notification - is serviceWorker.ready?');
  try {
    navigator.serviceWorker.ready.then((registration) => {
      logger.log('showing notification - serviceWorker.ready');
      registration.showNotification(notificationTitle, {
        body: notificationText,
        icon: notificationIcon,
        tag: notificationTag,
        actions: [{ action: 'Tap here', title: 'Title' }],
      });
    });
  } catch (error) {
    logger.error('Error showing browser notification using service worker', error);
  }
};

// eslint-disable-next-line no-unused-vars
export const closeNotification = () => {
  try {
    logger.debug('Closing browser notification');
    setTimeout(() => {
      notification?.close();
    }, 1000);

    navigator.serviceWorker.ready.then((registration) => {
      try {
        registration.getNotifications().then((notifications) => {
          // do something with your notifications
          notifications.forEach((swNotification) => {
            swNotification.close();
          });
        });
      } catch (error) {
        logger.warn('Error in getNotifications:', error);
      }
    });
  } catch (error) {
    logger.error('Error closing browser notification');
  }
};
