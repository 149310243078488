/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import Switch from 'react-switch';
import { Row, Col, Form } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';

// component
import Sidebar from 'COMPONENTS/Dashboard/Sidebar';

// Custom component
import CustomForm from 'COMPONENTS/CustomComponents/CustomForm/CustomForm';

// Translation
import { translate } from 'SERVICES/i18n';

// Constant
import { SWITCH_COMPONENT } from 'UTILS/constants/UtilityConstants';

// Asset
import { ReactComponent as BackIcon } from 'ASSETS/icon_back_arrow.svg';

// Style
import './Settings.scss';
import '../../resources/styles/globalStyles.scss';

export default class Calls extends Component {
  SWITCH1 = 'switch1';

  SWITCH2 = 'switch2';

  constructor(props) {
    super(props);

    const PERMISSION_NO = 'no';
    const DISABLE_PERMISSION = '0'; // 1-true(disable recording) 0-false(enable recording)

    this.state = {
      // For share images we receive yes/no value from OPM
      shareImages: this.props?.data ?
        (this.props.data?.policyUISettings?.prompt_to_share_images !== PERMISSION_NO)
        : (this.props?.policyUISettings?.prompt_to_share_images !== PERMISSION_NO),

      // For disableRecordingAndSavingSnapshots we receive 0/1 value from OPM
      disableRecordingAndSavingSnapshots: this.props?.data ?
        (this.props.data?.securitySettings?.privacy_mode !== DISABLE_PERMISSION)
        : (this.props?.securitySettings?.privacy_mode !== DISABLE_PERMISSION),
    };
  }

  toggleSwitch = (stateName) => {
    if (stateName === this.SWITCH1) {
      this.setState((prevstate) => ({
        shareImages: !prevstate.shareImages,
      }));
    } else if (stateName === this.SWITCH2) {
      this.setState((prevstate) => ({
        disableRecordingAndSavingSnapshots: !prevstate.disableRecordingAndSavingSnapshots,
      }));
    }
  }

  renderContent = () => (
    <Row className={`px-3 ${isMobileOnly && 'landscape-side-margins mt-3'}`}>
      <CustomForm
        formId='calls'
      >
        <Form.Group className='p-2'>
          <Row>
            <Col md={8} lg={8} sm={9} xs={9}>
              <Form.Label>
                <span className='p-0 font-regular'>
                  {translate('settings.callsText1')}
                </span>
              </Form.Label>
            </Col>
            <Col className='align-horizontal-end self-align-center disabled-state '>
              <Switch
                onChange={() => this.toggleSwitch(this.SWITCH1)}
                checked={this.state.shareImages}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor={SWITCH_COMPONENT.SELECT_COLOR}
                onHandleColor={SWITCH_COMPONENT.BACKGROUND_COLOR}
                className={
                  this.state.shareImages
                    ? 'checked-button'
                    : 'unchecked-button'
                }
                handleDiameter={SWITCH_COMPONENT.DIAMETER}
                height={SWITCH_COMPONENT.HEIGHT}
                width={SWITCH_COMPONENT.WIDTH}
              />
            </Col>
          </Row>
        </Form.Group>

        <Form.Group className='p-2'>
          <Row>
            <Form.Label>
              <span className='p-0 uri-header'>
                {translate('settings.privacy')}
              </span>
            </Form.Label>
          </Row>
        </Form.Group>

        <Form.Group className='p-2'>
          <Row>
            <Col md={10} lg={10} sm={8} xs={9}>
              <Form.Label className='d-flex'>
                <span className='p-0 font-regular'>
                  {translate('settings.callsText2')}
                </span>
              </Form.Label>
            </Col>
            <Col className='align-horizontal-end self-align-center disabled-state switch-calls'>
              <Switch
                onChange={() => this.toggleSwitch(this.SWITCH2)}
                checked={this.state.disableRecordingAndSavingSnapshots}
                uncheckedIcon={false}
                checkedIcon={false}
                handleDiameter={18}
                onColor={SWITCH_COMPONENT.SELECT_COLOR}
                onHandleColor={SWITCH_COMPONENT.BACKGROUND_COLOR}
                className={
                  this.state.disableRecordingAndSavingSnapshots
                    ? 'checked-button'
                    : 'unchecked-button'
                }
                height={12}
                width={25}
              />
            </Col>
          </Row>
        </Form.Group>
      </CustomForm>
    </Row>
  )

  render() {
    return (
      <div className={`calls h-100 ${this.props.isCallDashboard ? 'position-absolute' : ''}`}>
        {(isMobileOnly) ?
          /** Mobile view */
          (
            <Sidebar>
              <Row lg={8} md={8} sm={8} xs={8} className={`pt-2 px-3 ${isMobileOnly && 'landscape-side-margins'}`}>
                <Col className='d-lg-none d-xl-none' xs={1} sm={1} lg={1} md={1}>
                  <BackIcon
                    className='back-icon'
                    alt='back'
                    role='presentation'
                    onClick={this.props.goBack}
                  />
                </Col>
                <Col className='alignment'>
                  <span className='header-label ps-1'>
                    {translate('settings.calls')}
                  </span>
                </Col>

              </Row>
              <Row className='px-2'>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className='divider' />
                </Col>
              </Row>
              {this.renderContent()}
            </Sidebar>
          ) :
          /** Web view */
          (
            <div>
              {this.renderContent()}
            </div>
          )}
      </div>
    );
  }
}
