import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { Row, Col } from 'reactstrap';

// I18n
import { translate } from 'SERVICES/i18n';

// Selectors
import { isDemoRunning } from 'STORE/ClientSettings/ClientSettingsSelector';

// Components
import CustomPopover from 'COMPONENTS/CustomComponents/CustomPopover/CustomPopover';

// Constants
import { ILLUMINATION_DROP_UP_DETAILS } from 'UTILS/constants/FooterConstants';
import { POPOVER_PLACEMENT, SWITCH_COMPONENT } from 'UTILS/constants/UtilityConstants';
import { FILL_LIGHT_MODE } from 'UTILS/constants/MediaServerConstants';

// Style
import './IlluminationPopover.scss';

function Illumination({ isOnFlashLight, handleFlashLightChange, isPopoverOpen,
  togglePopover, closeIlluminationPopover, toggleIllumination,
  activeIlluminationItem, setActiveIlluminationItemAction }) {
  // State variables
  const [activeItem, setActiveItem] = useState(activeIlluminationItem ?? 'off');
  const demoRunning = useSelector(isDemoRunning);

  const handleClickOutside = () => {
    closeIlluminationPopover();
  };

  const toggleSwitch = (e, event) => {
    if (demoRunning) return;
    event.stopPropagation();
    handleFlashLightChange(!isOnFlashLight);
    toggleIllumination(FILL_LIGHT_MODE.ON);
  };

  const handleOnClickOfItem = (data) => {
    setActiveItem(data?.id);
    setActiveIlluminationItemAction(data?.id);
    toggleIllumination(data?.id);
  };

  const displayIlluminationUI = () => (
    <Col md={12}>
      <Col md={12}>
        <Row
          className='flashlight-container'
          id='on'
        >
          <Col
            md={7}
            xs={7}
            lg={7}
            className='item-label align-vertical-center'
          >
            {translate('illumination.flashlight')}
          </Col>
          <Col
            md={5}
            xs={5}
            lg={5}
            className='align-center'
          >
            <Switch
              onChange={toggleSwitch}
              checked={isOnFlashLight}
              uncheckedIcon={false}
              checkedIcon={false}
              onColor={SWITCH_COMPONENT.SELECT_COLOR}
              onHandleColor={SWITCH_COMPONENT.BACKGROUND_COLOR}
              className={
                isOnFlashLight
                  ? 'checked-button'
                  : 'unchecked-button'
              }
              handleDiameter={SWITCH_COMPONENT.DIAMETER}
              height={SWITCH_COMPONENT.HEIGHT}
              width={SWITCH_COMPONENT.WIDTH}
            />
          </Col>
        </Row>
      </Col>
      <Col md={12} xs={12} lg={12} className='illumination-divider-margin'>
        <div className='illumination-divider' />
      </Col>
      <Col md={12} className='item-container'>
        {ILLUMINATION_DROP_UP_DETAILS.map(
          (data) => (
            <div
              id={data.id}
              key={
                data.name
              }
              role='presentation'
              onClick={() => {
                handleOnClickOfItem(
                  data,
                );
              }}
              className={`p-1 item ${activeItem === data.id && 'active-icon'}`}
            >
              <span className='svg-icon align-center pe-1'>
                <data.icon className={data.className} />
              </span>
              <span className='ps-2 item-label align-center'>
                {
                  translate(data.name)
                }
              </span>
            </div>
          ),
        )}
      </Col>
    </Col>
  );

  return (
    <CustomPopover
      isPopoverOpen={isPopoverOpen}
      togglePopover={togglePopover}
      menuPlacement={POPOVER_PLACEMENT.TOP_START}
      closeCustomPopover={handleClickOutside}
      title='illumination.illumination'
      child={displayIlluminationUI()}
      target='illumination'
      id='illumination-popover'
      className='illumination-popover'
    />
  );
}

Illumination.propTypes = {
  isPopoverOpen: PropTypes.bool.isRequired,
  togglePopover: PropTypes.func.isRequired,
  handleFlashLightChange: PropTypes.func.isRequired,
  closeIlluminationPopover: PropTypes.func.isRequired,
  toggleIllumination: PropTypes.func.isRequired,
  isOnFlashLight: PropTypes.bool.isRequired,
  setActiveIlluminationItemAction: PropTypes.func.isRequired,
  activeIlluminationItem: PropTypes.string,
};

Illumination.defaultProps = {
  activeIlluminationItem: '',
};

export default Illumination;
