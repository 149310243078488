/* eslint-disable no-bitwise */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-else-return */
import { isMobile, deviceType, mobileModel, osVersion,
  isAndroid, isIOS, isWindows, isMacOs, osName } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import {
  LS_DATASTREAM_SYS_CAPABILITY,
  LS_DATASTREAM_PARTICIPANTID,
  STREAM_STATUS,
  STILL_IMAGE_SHARE_MODE_STATUS,
  STILL_IMAGE_SHARE_REQUEST_STATUS,
  QUEUED_SHARE_ACTION,
  QUEUED_SHARE_ACTION_WAITING_FLAGS,
  IlluminationModeFlags,
  IllumState,
} from 'UTILS/constants/DatastreamConstant';
import {
  AUDIO_MEDIA_CONFIGS,
  VIDEO_MEDIA_CONFIGS,
  DEFAULT_MEDIA_CONFIG,
} from '../../utils/constants/MediaServerConstants';

export default function Onsight() {
  this.resetAvConfigs = resetAvConfigs.bind(this);
  this.onStreamStop = onStreamStop.bind(this);
  this.onDisconnect = onDisconnect.bind(this);

  this.capabilities = [
    LS_DATASTREAM_SYS_CAPABILITY.CAPABILITY_FILE_PLAYBACK,
    LS_DATASTREAM_SYS_CAPABILITY.CAPABILITY_FILE_PLAYBACK_EXT,
    LS_DATASTREAM_SYS_CAPABILITY.CAPABILITY_SNAPSHOT,
    LS_DATASTREAM_SYS_CAPABILITY.CAPABILITY_REINVITE,
    LS_DATASTREAM_SYS_CAPABILITY.CAPABILITY_SNAPSHOT_SHARE_COORDINATION,
    LS_DATASTREAM_SYS_CAPABILITY.CAPABILITY_EFFICIENT_SHARING,
    LS_DATASTREAM_SYS_CAPABILITY.CAPABILITY_ONE_TO_MANY,
    LS_DATASTREAM_SYS_CAPABILITY.CAPABILITY_CALL_GUID,
    LS_DATASTREAM_SYS_CAPABILITY.CAPABILITY_EFFICIENT_TELESTRATION,
    LS_DATASTREAM_SYS_CAPABILITY.CAPABILITY_DYNAMIC_LIVE_VIDEO,
    LS_DATASTREAM_SYS_CAPABILITY.CAPABILITY_NEW_SHARE_SCHEME,
    LS_DATASTREAM_SYS_CAPABILITY.CAPABILITY_NETWORK_ERROR_EVENT,
    LS_DATASTREAM_SYS_CAPABILITY.CAPABILITY_LS_DATASTREAM_SESSION_DISCONNECT,
    LS_DATASTREAM_SYS_CAPABILITY.CAPABILITY_ADDITIONAL_VIDEO_SOURCES,
    LS_DATASTREAM_SYS_CAPABILITY.CAPABILITY_ADDITIONAL_VIDEO_RESOLUTIONS,
    LS_DATASTREAM_SYS_CAPABILITY.CAPABILITY_TELESTRATION_EXT,
    LS_DATASTREAM_SYS_CAPABILITY.CAPABILITY_REMOTE_MICROPHONE_MUTE,
    LS_DATASTREAM_SYS_CAPABILITY.CAPABILITY_RECONNECT_REINVITE,
    LS_DATASTREAM_SYS_CAPABILITY.CAPABILITY_SESSION_HEALTH_UPDATE,
    LS_DATASTREAM_SYS_CAPABILITY.CAPABILITY_ILLUMINATION_TOGGLE,
  ];

  this.sysVer = onsightSysVersion();

  this.encLimits = {
    definedLimit: 1,
    maxBitRate: 6000000,
    maxHeight: 1080,
    maxWidth: 1920,
  };

  this.decLimits = {
    definedLimit: 1,
    maxBitRate: 6000000,
    maxHeight: 1080,
    maxWidth: 1920,
  };

  this.lastRecvImage = null;
  this.cancelSendImage = true;
  this.sendImageBytesAcknowledged = 0;
  this.micMute = false;
  this.teleColor = -1;
  this.localPid = LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID;
  this.remotePid = LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID;
  this.remoteVersion = null;
  this.callGuid = uuidv4().replace(/-/g, '').toUpperCase();
  this.activeSharerPid = LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID;
  this.streamState = STREAM_STATUS.STREAM_STOPPED;
  this.stillImageShareState = STILL_IMAGE_SHARE_MODE_STATUS.STILL_IMAGE_SHARE_MODE_EXIT;
  this.lastIntentRequest = QUEUED_SHARE_ACTION.NONE;
  this.stillImageShareRequestState = STILL_IMAGE_SHARE_REQUEST_STATUS.STILL_IMAGE_SHARE_REQUEST_COMPLETE;
  this.queuedShareAction = QUEUED_SHARE_ACTION.NONE;
  this.queuedShareActionWaiting = QUEUED_SHARE_ACTION_WAITING_FLAGS.None;
  this.illumState = IllumState.Off;
  this.resetAvConfigs();
}

function resetAvConfigs() {
  // these values define the audio and video configs locally available
  // they get propogated to the remote side when we start streaming

  // available video configurations
  // 0 - Low, 1 - Medium, 2 - High, 3 - Custom, 4 - HD, 5 - FullHD
  this.videoMediaConfigs = VIDEO_MEDIA_CONFIGS;

  // available audio configurations
  // these values aren't used anymore and can be hardcoded
  this.audioMediaConfigs = AUDIO_MEDIA_CONFIGS;

  // currently selected media and audio configurations
  // by default the siptest plugin is using 640x480
  // this.streamQuality = 3;
  // this.currentMediaVideoConfig = this.videoMediaConfigs[this.streamQuality];
  // this.currentMediaAudioConfig = this.audioMediaConfigs[this.streamQuality];
  // For issue #630
  if (!this.streamQuality) {
    this.streamQuality = DEFAULT_MEDIA_CONFIG.value;
  }
  this.currentMediaVideoConfig = this.videoMediaConfigs[this.streamQuality];
  this.currentMediaAudioConfig = this.audioMediaConfigs[this.streamQuality];

  // local video device configuration
  this.videoConfig = {
    availableSubSources: {
      availableVideoSubSources: [
        'Back Camera',
        'Front Camera',
      ],
    },
    currentSubSource: 'Back Camera',
    videoSource: 1,
    brightness: 128,
    colourSaturation: 128,
    contrast: 128,
    digitalZoom: false,
    externalVideoStandard: 0,
    hue: 0,
    internalVideoStandard: 0,
    sharpness: 0,
    whiteBalance: 127,
    whiteBalanceMode: 0,
  };

  // local audio device configuration
  this.audioConfig = {
    codec: 0,
    volume: 0xFFFF,
  };

  // TODO: not sure about this yet, likely used for recordings
  this.mediaInfo = {
    author: 'Unknown author',
    description: 'Librestream Technologies Inc.',
    title: 'No Title',
  };

  // local illum info
  this.illumInfo = {
    levelMin: 0, // provided for backwards compatibility, just set to the same as the first source
    levelMax: 1, // provided for backwards compatibility, just set to the same as the first source
    perVideoSourceIllumInfo: [ // per-source illumination info
      {
        levelMin: 0,
        levelMax: 1,
        stillImageSupportedModes: (IlluminationModeFlags.IllumModeOff | IlluminationModeFlags.IllumModeTorchOn),
        videoSupportedModes: (IlluminationModeFlags.IllumModeOff | IlluminationModeFlags.IllumModeTorchOn),
        sourceName: 'Back Camera',
      },
      {
        levelMin: 0,
        levelMax: 1,
        stillImageSupportedModes: (IlluminationModeFlags.IllumModeOff | IlluminationModeFlags.IllumModeTorchOn),
        videoSupportedModes: (IlluminationModeFlags.IllumModeOff | IlluminationModeFlags.IllumModeTorchOn),
        sourceName: 'Front Camera',
      },
    ],
  };
  this.activeSharerIllumInfo = this.illumInfo;
}

function onStreamStop() {
  console.log('Onsight::Video stream stopped!: Reset Audio Video Config!;');
  this.resetAvConfigs();
}

function onDisconnect() {
  this.onStreamStop();
  this.micMute = false;
  this.activeSharerPid = LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID;
  this.localPid = LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID;
  this.remotePid = LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID;
  this.remoteVersion = null;
  this.callGuid = uuidv4().replace(/-/g, '').toUpperCase();
  this.lastRecvImage = null;
  this.cancelSendImage = true;
  this.sendImageBytesAcknowledged = 0;
  this.lastIntentRequest = QUEUED_SHARE_ACTION.NONE;
  this.queuedShareAction = QUEUED_SHARE_ACTION.NONE;
  this.queuedShareActionWaiting = QUEUED_SHARE_ACTION_WAITING_FLAGS.None;
  this.stillImageShareState = STILL_IMAGE_SHARE_MODE_STATUS.STILL_IMAGE_SHARE_MODE_EXIT;
  this.stillImageShareRequestState = STILL_IMAGE_SHARE_REQUEST_STATUS.STILL_IMAGE_SHARE_REQUEST_COMPLETE;
  this.illumState = IllumState.Off;
  console.log('Onsight:: state::Disconnected!!');
}

export function onsightProductVersion() {
  return (`${window.dynamicEnv.REACT_APP_WEBAPP_VERSION_MAJOR}.${window.dynamicEnv.REACT_APP_WEBAPP_VERSION_MINOR}.` +
    `${window.dynamicEnv.REACT_APP_WEBAPP_VERSION_BUILD}.${window.dynamicEnv.REACT_APP_WEBAPP_VERSION_REVISION}`
  );
}

export function onsightSysVersion() {
  const osExt = parseInt(osVersion, 10);
  return {
    appVer: {
      major: parseInt(window.dynamicEnv.REACT_APP_WEBAPP_VERSION_MAJOR, 10),
      minor: parseInt(window.dynamicEnv.REACT_APP_WEBAPP_VERSION_MINOR, 10),
      build: parseInt(window.dynamicEnv.REACT_APP_WEBAPP_VERSION_BUILD, 10),
      rev: parseInt(window.dynamicEnv.REACT_APP_WEBAPP_VERSION_REVISION, 10),
      product: 9,
      type: 1,
      hwInfo: {
        carrier: '',
        hw: 0,
        model: isMobile ? mobileModel : deviceType,
        numCpu: navigator.hardwareConcurrency ? navigator.hardwareConcurrency : 1,
        os: osType(),
        osExt: Number.isNaN(osExt) ? 0 : osExt,
        osVersion: osName + ' ' + osVersion,
      },
    },
    coreVer: {
      major: 0,
      minor: 13,
    },
    datastreamVer: 1,
  };
}

function osType() {
  if (isIOS) {
    return 7;
  } else if (isMacOs) {
    return 8;
  } else if (isAndroid) {
    return 9;
  } else if (isWindows) {
    return 12;
  }
  return -1;
}
