/* eslint-disable react/prop-types */
import {
  MEDIA_SERVER_CONNECTION,
  MEDIA_TYPE,
  TIMER_CONSTANTS,
  ICE_STATE,
  MEDIA_QUALITY,
  DEFAULT_MEDIA_CONFIG,
  MEDIA_SERVER_FSM_STATE,
  MEDIA_SERVER_CALL_STATE,
  CALL_ERROR,
  WEB_CALL_STATE,
  WEB_CALL_EVENT,
  MEDIA_SERVER_HANGUP_REASON,
  VIDEO_MEDIA_PROFILE,
  MEDIA_PATH_STATE,
  GUM_EXCEPTION,
  SCREEN_SHARE_DEVICE,
  MEDIA_ACTION,
  DEFAULT_ZOOM_LEVEL,
  IMAGE_MIME_TYPE,
  MEDIA_SERVER_ERROR_EVENT,
  MEDIA_SERVER_EVENT,
  DEFAULT_ILLUM_INFO,
} from 'UTILS/constants/MediaServerConstants';
import { LSDS_CALL_BANDWIDTH_CONFIG, EXIF_CLR_CODE } from 'UTILS/constants/DatastreamConstant';
import { CALL_DASHBOARD } from 'UTILS/constants/DOMElementConstants';
import { VID_IMG_OVERLAY_TEXT_LOC, VID_IMG_OVERLAY_TEXT_SIZE } from 'UTILS/constants/UtilityConstants';

import LsExif from 'SERVICES/lsexif/lsexif';
import JanusCallMgr from 'SERVICES/MediaServerService/JanusCallManager';
import MediaManager from 'SERVICES/MediaServerService/MediaManager';

import _ from 'lodash';

// Utility
import CommonUtility from 'UTILS/CommonUtility';
import browserAPI from 'UTILS/BrowserAPI';
import { AppLogger, LOG_NAME } from 'SERVICES/Logging/AppLogger';

// Translation
import { translate } from 'SERVICES/i18n';

import {
  FSM,
  FSM_NO_HANDLER,
  FSM_NO_CHANGE,
} from 'SERVICES/MediaServerService/FSM';
import WebrtcUtils from 'SERVICES/MediaServerService/WebRtcUtils';

const SESSION_FSM = LOG_NAME.SessionFSM;
const CALL_FSM = LOG_NAME.CallFSM;
const APP_FSM = LOG_NAME.APPFSM;
const USE_CANVAS_FOR_SCALING = window.dynamicEnv.REACT_APP_SCALE_VIDEO_ENABLE === 'true' && CommonUtility.BrowserSupportsCanvasScaling();
const logger = AppLogger(LOG_NAME.CallManager);

/**
 * @class
 */
class CallManager {
  static instance = null;

  /**
   * @returns Singleton instance of SIP Call Manager
   */
  static getCallManager() {
    if (!CallManager.instance) {
      CallManager.instance = new CallManager();
    }
    return CallManager.instance;
  }

  constructor() {
    this.server = [
      window.dynamicEnv.REACT_APP_MEDIA_SERVER_WS_URL,
      window.dynamicEnv.REACT_APP_MEDIA_SERVER_URL,
      MEDIA_SERVER_CONNECTION.DEFAULT_MEDIA_SERVER_URL,
    ];
    this.withCredentials =
      window.dynamicEnv.REACT_APP_MEDIA_SERVER_WITH_CREDENTIALS === 'true';
    this.iceServers = [
      {
        url: 'stun:' + window.dynamicEnv.REACT_APP_TURN_SERVER_ADDR + ':3478',
        username: window.dynamicEnv.REACT_APP_TURN_SERVER_USER,
        credential: window.dynamicEnv.REACT_APP_TURN_SERVER_CREDS,
      },
      {
        url:
          'turn:' +
          window.dynamicEnv.REACT_APP_TURN_SERVER_ADDR +
          ':3478?transport=udp',
        username: window.dynamicEnv.REACT_APP_TURN_SERVER_USER,
        credential: window.dynamicEnv.REACT_APP_TURN_SERVER_CREDS,
      },
      {
        url:
          'turn:' +
          window.dynamicEnv.REACT_APP_TURN_SERVER_ADDR +
          ':3478?transport=tcp',
        username: window.dynamicEnv.REACT_APP_TURN_SERVER_USER,
        credential: window.dynamicEnv.REACT_APP_TURN_SERVER_CREDS,
      },
      {
        url:
          'turns:' +
          window.dynamicEnv.REACT_APP_TURN_SERVER_ADDR +
          ':443?transport=tcp',
        username: window.dynamicEnv.REACT_APP_TURN_SERVER_USER,
        credential: window.dynamicEnv.REACT_APP_TURN_SERVER_CREDS,
      },
    ];
    logger.info(
      'CallManager_constructor::Set MediaServer URL from env to this.server::',
      JSON.stringify(this.server),
    );
    logger.info(
      'CallManager_constructor::Set TURN servers:',
      window.dynamicEnv.REACT_APP_TURN_SERVER_ADDR,
    );

    this.session = null;
    this.initialSession = true;
    this.webRtcToken = null;
    this.media = null;

    this.callData = {
      calleeUserName: null,
      callerUserName: null,
      jsepOffer: null,
      localVideoStream: null,
      srtp: null,
      localStreamReady: false,
      callAborted: false,
      hangupCodeRcvd: false,
      mediaPathState: MEDIA_PATH_STATE.UNKNOWN,
      lastErrNotified: CALL_ERROR.NONE,
      videoDeviceId: null,
      currentZoomLevel: DEFAULT_ZOOM_LEVEL,
      /* If new flags added, add default state in reset below */
      hangupCodeDone() {
        this.hangupCodeRcvd = true;
      },
      mediaUp(flag) {
        this.mediaPathState = (flag === true) ?
          MEDIA_PATH_STATE.UP : MEDIA_PATH_STATE.DOWN;
      },
      reset() {
        this.calleeUserName = null;
        this.callerUserName = null;
        this.jsepOffer = null;
        this.srtp = null;
        this.localStreamReady = false;
        this.callAborted = false;
        this.hangupCodeRcvd = false;
        this.mediaPathState = MEDIA_PATH_STATE.UNKNOWN;
        this.localVideoStream = null;
        this.lastErrNotified = CALL_ERROR.NONE;
        this.videoDeviceId = null;
        this.currentZoomLevel = DEFAULT_ZOOM_LEVEL;
      },
    };

    /* Reconnection management */
    this.reconnectionAttemptCnt = 0;
    this.reconnectionTimeout = null;

    /* Misc call states  */
    this.bitrateTimer = null;
    this.videoResTimer = null;
    this.datastreamHangupTimer = null;
    this.regData = null;
    this.canvasForScaleUp = null;
    this.mediaPathGuardTimer = null;
    this.localStream = null;
    this.isTorchForVideoEnabled = false;

    /* initialize Session FSM */
    this.initSessionFSM();
    this.callFSM = null;

    /* pic states */
    this.nextPictureId = 0;

    /* SipCallManager */
    this.sipCallManager = null;
  }

  initSipCallManager(webRtcToken) {
    this.sipCallManager = JanusCallMgr;
    this.sipCallManager.Init.initialize(this.server, this.iceServers, {
      event: this.mediaServerEventCallback,
      error: this.mediaServerErrorCallback,
      ice: this.handleICEEvent,
    }, webRtcToken);
  }

  updateVideoDeviceId(newId, isScreenShare = false) {
    if (!newId) {
      logger.warn('CallManager::Bad video device id for call', newId);
    }
    logger.info('CallManager::Video device id for call modified from',
      this.callData.videoDeviceId ? CommonUtility.printableId(this.callData.videoDeviceId) : '-',
      `to ${CommonUtility.printableId(newId)} isScreenShare:${isScreenShare}`);
    if (isScreenShare) {
      // Device id for screen sharing tracks can be varied based
      // on the source of screen share; just set to a constant
      this.callData.videoDeviceId = SCREEN_SHARE_DEVICE.deviceId;
    } else {
      this.callData.videoDeviceId = newId;
    }
    this.media.MediaDevice.setCurrentVideoDeviceId(this.callData.videoDeviceId);
  }

  setTorchStateForVideo = (state) => {
    this.isTorchForVideoEnabled = state;
    this.media?.CallFunctionState.setTorchStateForVideo(state);
  }

  /* This will return true, if the MediaServer session is fully ready to take a call */
  isHelperReady = () =>
    this.sipCallManager?.Session.isMediaServerPreparedForCall() && this.registered;

  initCallFSM = () => {
    if (this.callFSM !== null) {
      /* A call may be left abruptly when network reconnects,
      do cleanup ;
      In case the call is active, no cleanup done */
      this.performCallCleanup(true);
      return;
    }

    const instance = this;
    this.call = FSM(CALL_FSM, MEDIA_SERVER_CALL_STATE.CALL_IDLE);
    this.call.actions = {
      startCall: {
        startingState: MEDIA_SERVER_CALL_STATE.CALL_INITIATED,
        handler: instance.initiateCall,
        allowed: [MEDIA_SERVER_CALL_STATE.CALL_IDLE],
      },
      endCall: {
        startingState: FSM_NO_CHANGE,
        handler: instance.endCall,
      },
      abortCall: {
        startingState: FSM_NO_CHANGE,
        handler: instance.abortCall,
      },
      startDisconnect: {
        startingState: MEDIA_SERVER_CALL_STATE.CALL_DISCONNECTING,
        handler: () => instance.appCallback(WEB_CALL_EVENT.CALL_DISCONNECTED),
        allowed: [MEDIA_SERVER_CALL_STATE.CALL_IN_PROGRESS],
      },
      mediaServerDecline: {
        startingState: MEDIA_SERVER_CALL_STATE.CALL_DISCONNECTED,
        handler: () => instance.declineCall(CALL_ERROR.CALL_DECLINED),
        notAllowed: [MEDIA_SERVER_CALL_STATE.IDLE],
      },
      mediaServerHangup: {
        startingState: MEDIA_SERVER_CALL_STATE.CALL_DISCONNECTED,
        handler: instance.doHangup,
        notAllowed: [MEDIA_SERVER_CALL_STATE.IDLE],
      },
    };

    this.call.events = {
      calling: {
        handler: FSM_NO_HANDLER,
        nextState: MEDIA_SERVER_CALL_STATE.CALL_INITIATED,
      },
      callInitFailed: {
        handler: () => {
          instance.callData.hangupCodeDone();
          instance.endCall();
        },
        nextState: FSM_NO_CHANGE,
      },
      accepting: {
        handler: FSM_NO_HANDLER,
        nextState: MEDIA_SERVER_CALL_STATE.CALL_IN_ACCEPTING,
      },
      incomingCall: {
        handler: FSM_NO_HANDLER,
        nextState: MEDIA_SERVER_CALL_STATE.CALL_IN_RCVD,
      },
      accepted: {
        handler: FSM_NO_HANDLER,
        nextState: MEDIA_SERVER_CALL_STATE.CALL_IN_PROGRESS,
      },
      callAcceptFailed: {
        handler: instance.endCall,
        nextState: FSM_NO_CHANGE,
      },
      callHangup: {
        handler: this.handleHangup,
        nextState: FSM_NO_CHANGE,
      },
      callDisconnected: {
        handler: () => {
          instance.callFSM.trigger(this.call.FSM_ACTION.MEDIA_SERVER_HANGUP);
        },
        nextState: MEDIA_SERVER_CALL_STATE.CALL_DISCONNECTED,
        allowedState: [MEDIA_SERVER_CALL_STATE.CALL_DISCONNECTING,
          MEDIA_SERVER_CALL_STATE.CALL_IN_PROGRESS],
      },
      hangupComplete: {
        handler: instance.postHangupCleanup,
        nextState: MEDIA_SERVER_CALL_STATE.CALL_HUNGUP,
      },
      mediaPathDisconnected: {
        handler: this.abortCall,
        nextState: FSM_NO_CHANGE,
      },
      callEnded: {
        nextState: MEDIA_SERVER_CALL_STATE.CALL_IDLE,
        handler: () => instance.callData.reset(),
        allowed: [MEDIA_SERVER_CALL_STATE.CALL_HUNGUP],
      },
    };
    this.call.activate(this.call);
    this.callFSM = this.call.fsm;
  };

  initSessionFSM = () => {
    const instance = this;
    this.session = FSM(SESSION_FSM, MEDIA_SERVER_FSM_STATE.NOT_READY);
    this.session.actions = {
      startSession: {
        startingState: MEDIA_SERVER_FSM_STATE.CONNECTING,
        handler: instance.initMediaServer,
        allowed: [MEDIA_SERVER_FSM_STATE.NOT_READY],
      },
      startAttach: {
        startingState: MEDIA_SERVER_FSM_STATE.ATTACHING,
        handler: instance.attachSipSession,
        allowed: [MEDIA_SERVER_FSM_STATE.CONNECTED],
      },
      startRegister: {
        startingState: MEDIA_SERVER_FSM_STATE.REGISTERING,
        handler: instance.registerUser,
        allowed: [MEDIA_SERVER_FSM_STATE.ATTACHED],
      },
      startReconnect: {
        startingState: MEDIA_SERVER_FSM_STATE.RECONNECTING,
        handler: () => {
          clearTimeout(instance.reconnectionTimeout);
          instance.session.reconnectionAttemptCnt = 0;
          instance.reconnectMediaServerSession();
        },
        allowed: [MEDIA_SERVER_FSM_STATE.DISCONNECTED],
      },
      performReconnect: {
        startingState: MEDIA_SERVER_FSM_STATE.RECONNECTING,
        handler: instance.reconnectMediaServerSession,
        allowed: [MEDIA_SERVER_FSM_STATE.RECONNECTING],
      },
      startReinit: {
        startingState: MEDIA_SERVER_FSM_STATE.REINITING,
        handler: instance.reInitSession,
        allowed: [MEDIA_SERVER_FSM_STATE.RECONNECTING],
      },
      startDestroy: {
        startingState: MEDIA_SERVER_FSM_STATE.DESTROYING,
        handler: instance.endMediaServerSession,
      },
    };

    this.session.events = {
      connected: {
        handler: () => this.sessionFSM.trigger(this.session.FSM_ACTION.START_ATTACH),
        nextState: MEDIA_SERVER_FSM_STATE.CONNECTED,
      },
      attached: {
        handler: FSM_NO_HANDLER,
        nextState: MEDIA_SERVER_FSM_STATE.ATTACHED,
      },
      attachFailed: {
        handler: FSM_NO_HANDLER,
        nextState: MEDIA_SERVER_FSM_STATE.NOT_READY,
      },
      registered: {
        handler: this.initDone,
        nextState: MEDIA_SERVER_FSM_STATE.READY,
      },
      registerFailed: {
        handler: () => {
          instance.appNotifyError(CALL_ERROR.REG_FAILED);
          instance.sessionFSM.trigger(this.session.FSM_ACTION.START_DESTROY);
        },
        nextState: MEDIA_SERVER_FSM_STATE.NOT_READY,
      },
      connectError: {
        handler: this.handleNetworkError,
        nextState: FSM_NO_CHANGE,
      },
      reconnected: {
        handler: this.initDone,
        nextState: MEDIA_SERVER_FSM_STATE.READY,
      },
      disconnected: {
        handler: FSM_NO_HANDLER,
        nextState: MEDIA_SERVER_FSM_STATE.DISCONNECTED,
      },
      destroyed: {
        handler: this.handleCleanup,
        nextState: MEDIA_SERVER_FSM_STATE.NOT_READY,
      },
    };
    this.session.activate(this.session);
    this.sessionFSM = this.session.fsm;
  };

  /**
   * WebApp API : from CallManager to initialize WebRTC + SIP stack
   * @param {object} events     Defines all callbacks from Helper to Wrapper
   * @param {string} userName   SIP URI used for registering
   * @param {object} clientSettingsData
   * @param {object} webRtcToken
   * @param {string} displayName The display name of user
   */
  initialize = (events, userName, clientSettingsData, webRtcToken, displayName) => {
    this.session.logger.info(`${APP_FSM} -> ${this.sessionFSM.state} Evt:initialize`);
    this.callbacks = events;

    this.initSipCallManager(webRtcToken);

    /* Global callbacks from media handler */
    this.mediaHandlerCB = {
      screenShareEnded: () => this.appMediaCallback(MEDIA_ACTION.SCREEN_SHARE_STOPPED),
      updateLocalStream: (isScreenShare) => this.updateLocalStream(isScreenShare),
      appNotifyError: (error) => this.appNotifyError(error),
      setLocalVideoStream: (stream) => this.setLocalVideoStream(stream),
      appCallback: (callEvent, ...cbData) => this.appCallback(callEvent, ...cbData),
    };
    this.media = new MediaManager(this.mediaHandlerCB);
    this.userName = userName;
    this.displayName = displayName;
    this.clientSettingsData = clientSettingsData;
    this.webRtcToken = webRtcToken;

    this.regData = {
      proxy: `${MEDIA_SERVER_CONNECTION.SIP}${clientSettingsData?.sip_registrar}${MEDIA_SERVER_CONNECTION.TRANSPORT}`,
      outbound_proxy: `${MEDIA_SERVER_CONNECTION.SIP}${clientSettingsData?.sip_registrar}${MEDIA_SERVER_CONNECTION.TRANSPORT}`,
      username: this.userName,
      display_name: displayName || userName,
      onsight_secret: clientSettingsData?.sip_password,
    };
    logger.debug('Display name of user while regisetering', this.regData.display_name);
    this.initialSession = true;
    this.sessionFSM.trigger(this.session.FSM_ACTION.START_SESSION);
  };

  /* Step-1 Initialize Media Server */
  initMediaServer = () => {
    logger.info(
      'Initiate Media Server session with server::',
      JSON.stringify(this.server),
    );
    const mediaServerSession = this.sipCallManager.Session.getSessionId();

    if (!mediaServerSession) {
      this.sipCallManager.Session.initMediaServerSession();
    } else {
      this.sessionFSM.trigger(this.session.FSM_ACTION.START_ATTACH);
    }
    return true;
  };

  attachSipSession = () => {
    // Attach to SIPcall plugin
    logger.info(
      'onMediaServerSessionSuccess:: Media Server session started with ID:',
      this.sipCallManager.Session.getSessionId(),
    );
    this.sessionFSM.setContext(this.sipCallManager.Session.getSessionId());
    this.sipCallManager.Session.attachSipSession(this.opaqueId);
  };

  /* Performs cleanup on end of WebRTC call based on the state of call FSM
  Based on the call state, the webrtc cleanup as well as the hangup messages
  from Media Server appear in different order. We can clean up call FSM only after
  the app has been appropriately notified. In case of call ending from
  local end, the WebRTC cleanup happens earlier and then the hangup with
  event with appropriate code/reason are received.
  We need to delay the cleanup for the hangup event thus.
  */
  performCallCleanup = (forceCleanup = false) => {
    if (forceCleanup && (this.callFSM.state === MEDIA_SERVER_CALL_STATE.CALL_HUNGUP)) {
      this.callFSM.process(this.call.FSM_EVENT.CALL_ENDED);
    } else if (this.callFSM.state !== MEDIA_SERVER_CALL_STATE.CALL_IDLE) {
      if (this.callData.hangupCodeRcvd) {
        if ((this.callFSM.state !== MEDIA_SERVER_CALL_STATE.CALL_DISCONNECTING) ||
          (this.datastreamHangupTimer === null)) {
          this.callFSM.process(this.call.FSM_EVENT.CALL_ENDED);
        }
      } else if (this.callData.callAborted) {
        this.appNotifyError(CALL_ERROR.CALL_ENDED, { aborted: true });
        this.callFSM.process(this.call.FSM_EVENT.CALL_ENDED);
      }
    }
  }

  muteUnmute = (mediaType, muteFlag) => {
    logger.debug(
      `muteUnmute() mediaType:${mediaType} muteFlag:${muteFlag}`,
    );
    this.sipCallManager.CallFunction.muteUnmuteAudioVideo(mediaType, muteFlag);
  };

  toggleGeoLocationFetcher = (state, callback) => {
    this.media.Overlay.toggleGpsOverlayVisibility(state, callback);
    this.media.Location.setLocationState(state, callback);
  }

  setOverlaySettings = (overlaySettings, callback) => {
    this.media.Overlay.toggleTimeStampOverlayVisibility(
      overlaySettings?.isEnabledForDateTime ?? false,
    );
    this.toggleGeoLocationFetcher(overlaySettings?.isEnabledForLocation ?? false, callback);
    logger.log('overlaySettings?.position:', overlaySettings?.position, VID_IMG_OVERLAY_TEXT_LOC[overlaySettings?.position]);
    this.media.Overlay.setVideoImgOverlayTextSize(
      VID_IMG_OVERLAY_TEXT_SIZE[overlaySettings?.textSize] ?? VID_IMG_OVERLAY_TEXT_SIZE.LARGE,
    );
    this.media.Overlay.setVideoImgOverlayTextlocation(
      VID_IMG_OVERLAY_TEXT_LOC[overlaySettings?.position] ?? VID_IMG_OVERLAY_TEXT_LOC.TOP_RIGHT,
    );
  }

  setLocationStateForMedia = (state, callback) => {
    this.media.Location.setLocationState(state, callback);
  }

  registerUser = (updateRegistration = false, userName = null) => {
    if ((updateRegistration === true) &&
      (this.sessionFSM.state === MEDIA_SERVER_FSM_STATE.READY) &&
      (this.callFSM?.state === MEDIA_SERVER_CALL_STATE.CALL_IDLE)) {
      this.userName = userName;
      this.sessionFSM.setState(MEDIA_SERVER_FSM_STATE.REGISTERING);
    }

    logger.info(`Registering: ${this.regData.username}`);
    this.sipCallManager.Register.registerUser(updateRegistration, this.regData);
  };

  updateWebRtcToken = (token) => {
    if (token) {
      this.webRtcToken = token;
      this.sipCallManager.Token.updateWebRTCToken(token);
    }
  };

  /* ========== Event handlers for session FSM Start here ========== */
  /*
  All initialization done; Call functionality enabled
  */
  initDone = () => {
    /* We initialize only when ready to take call finally */
    logger.info('CallManager::%cWebApp Ready for Calls!', 'color:green');
    /* Inform webapp that we are ready to serve calls */
    this.initCallFSM();
    if (this.initialSession) {
      this.appCallback(WEB_CALL_EVENT.SESSION_EVENT, WEB_CALL_STATE.INITIALIZED);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (this.callFSM.state === MEDIA_SERVER_CALL_STATE.CALL_IDLE) {
        /* Notify only if no call in progress; if call in progress,
        when media path comes up, notification will be sent */
        this.appCallback(WEB_CALL_EVENT.SESSION_EVENT, WEB_CALL_STATE.RECONNECTED);
      }
    }
  }

  /* Session disconnect handler */
  performLSDisconnect = (delayDisconnect = false) => {
    if (delayDisconnect) {
      logger.info('performLSDisconnect() sent datastream hangup request');
      const self = this;
      this.datastreamHangupTimer = setTimeout(() => {
        self.datastreamHangupTimer = null;
        logger.info('performLSDisconnect::datastreamHangupTimer() hangup timer expired!');
        this.callFSM.process(this.call.FSM_EVENT.CALL_DISCONNECTED);
      }, TIMER_CONSTANTS.DATASTREAM_HANGUP_TIMEOUT);
    } else {
      /* Immediate hangup */
      this.callFSM.process(this.call.FSM_EVENT.CALL_DISCONNECTED);
    }
  }

  /* Media Server session cleanup */
  handleCleanup = () => {
    this.callFSM = null;
    this.reconnectionAttemptCnt = 0;
    this.sipCallManager?.Session.cleanUp();
  }

  /* Initialization failures */
  handleInitFailure = () => {
    switch (this.sessionFSM.state) {
      case MEDIA_SERVER_FSM_STATE.NOT_READY:
      case MEDIA_SERVER_FSM_STATE.INITIALIZING:
      case MEDIA_SERVER_FSM_STATE.CONNECTING:
      case MEDIA_SERVER_FSM_STATE.ATTACHING:
      case MEDIA_SERVER_FSM_STATE.REGISTERING:
        // Simplified since no other errors are known and its not possible to
        // distinguish the error at this stage.
        this.appNotifyError(CALL_ERROR.NETWORK_ERR);
        this.sessionFSM.trigger(this.session.FSM_ACTION.START_DESTROY);
        break;

      default:
        logger.assert(false, `Invalid error for the current state ${this.sessionFSM.state}`);
        break;
    }
  }

  /* Backend/network connection error handler */
  handleNetworkError = () => {
    switch (this.sessionFSM.state) {
      case MEDIA_SERVER_FSM_STATE.INITIALIZING:
      case MEDIA_SERVER_FSM_STATE.CONNECTING:
      case MEDIA_SERVER_FSM_STATE.ATTACHING:
      case MEDIA_SERVER_FSM_STATE.REGISTERING:
        // Simplified since no other errors are known and its not possible to
        // distinguish the error at this stage.
        this.appNotifyError(CALL_ERROR.NETWORK_ERR);
        this.sessionFSM.trigger(this.session.FSM_ACTION.START_DESTROY);
        break;

      case MEDIA_SERVER_FSM_STATE.READY:
        logger.warn('We are encountering errors connecting to Media Server');
        if (this.callFSM.state === MEDIA_SERVER_CALL_STATE.CALL_IDLE) {
          this.appNotifyError(CALL_ERROR.NETWORK_ERR);
        } else if ((this.callFSM.state === MEDIA_SERVER_CALL_STATE.CALL_INITIATED) ||
          (this.callFSM.state === MEDIA_SERVER_CALL_STATE.CALL_IN_RCVD)) {
          /* Cleanup partial / yet to be setup calls */
          this.call.logger.warn(`[${this.callFSM.context}]`,
            'Aborting call due to network error!');
          this.callFSM.trigger(this.call.FSM_ACTION.ABORT_CALL, { cause: 'Network err' });
        }
        this.sessionFSM.process(this.session.FSM_EVENT.DISCONNECTED);
        this.sessionFSM.trigger(this.session.FSM_ACTION.START_RECONNECT);
        break;

      case MEDIA_SERVER_FSM_STATE.RECONNECTING:
        if (!this.isCallInProgress()) {
          /* Notify if call not in progress */
          this.appNotifyError(CALL_ERROR.NETWORK_ERR);
        }
        this.reconnectionAttemptCnt += 1;
        if (this.reconnectionAttemptCnt < TIMER_CONSTANTS.MAX_RECONNECTION_ATTEMPTS) {
          const timeDelay = TIMER_CONSTANTS.INITIAL_DELAY * 2 ** this.reconnectionAttemptCnt;
          const self = this;
          self.reconnectionTimeout = setTimeout(() => {
            /* Continue to repeat feedback notification to indicate activity in webapp */
            self.sessionFSM.trigger(this.session.FSM_ACTION.PERFORM_RECONNECT);
          }, timeDelay);
        } else {
          logger.warn('Reconnect attempts exhausted, giving up!');
          this.sessionFSM.trigger(this.session.FSM_ACTION.START_REINIT);
        }
        break;

      default:
        logger.assert(false, 'NETWORK ERR!', this.sessionFSM.state);
        break;
    }
  }

  /* #region FSM action handlers
  ========== Session FSM actions start here ==========
  */
  /**
   * API from App to initiate call from local end
   * @returns {void}
   */
  initiateCall = async () => {
    const self = this;
    self.freeLocalStream();
    logger.info('initiateCall');
    try {
      // We manage the initial streams
      this.localStream = await self.media.Canvas.getStreamWithMicAudioAndFakeVideo();
      // self.media.WebRTCPeer.setPeerConnection(self.sipCallManager.RTCPeer.getPeerConnection());
    } catch (error) {
      logger.error('initiateCall() error:', error);
      self.callFSM.process(this.call.FSM_EVENT.CALL_INIT_FAILED);
      return;
    }

    this.sipCallManager.Call.makeCall({
      data: window.dynamicEnv.REACT_APP_MEDIA_CONSTRAINT_DATA === 'true',
      localStream: this.localStream,
      lsdsConfigs: {
        lsds: {
          bw: LSDS_CALL_BANDWIDTH_CONFIG.BW,
          bwomax: LSDS_CALL_BANDWIDTH_CONFIG.BW_OMAX,
          bwimax: LSDS_CALL_BANDWIDTH_CONFIG.BW_IMAX,
          priv: LSDS_CALL_BANDWIDTH_CONFIG.PRIV,
        },
      },
      calleeUserName: this.callData.calleeUserName,
    });
  }

  /** #region CallManager API
   * WebApp API: Call to be initiated from webapp
   * @param {string} calleeUserName     URI to call to
   * @category API
   */
  doCall = (calleeUserName) => {
    if (!this.isSysReady()) {
      this.session.logger.error('Not ready yet!');
      return false;
    }

    /* Note: Do not change the format of the log below, as it is specific to documentation */
    logger.info(`[${this.callFSM.context}] ${APP_FSM} -> ${CALL_FSM}:${this.callFSM.state} [ label = "Act:doCall" ]`);
    /* Check for valid call states */
    if ((this.sessionFSM.state === MEDIA_SERVER_FSM_STATE.READY) &&
      (this.callFSM.state === MEDIA_SERVER_CALL_STATE.CALL_IDLE)) {
      logger.debug('doCall:: calling', calleeUserName);
      this.callData.calleeUserName = calleeUserName;
      this.callData.callerUserName = this.userName;
      this.callData.isIncoming = false;
      this.callFSM.startCall();
      return true;
    }
    this.call.logger.error(`[${this.callFSM.context}] ${this.callFSM.state}: Invalid state for starting a call`);
    return false;
  };

  /**
   * WebApp API : User accepted call
   * @category [API]
   */
  acceptCall = async () => {
    /* Note: Do not change the format of the log below, as it is specific to documentation */
    this.call.logger.info(`[${this.callFSM.context}] ${APP_FSM} -> ${this.callFSM.state} [ label = "Act:acceptCall" ]`);
    const self = this;
    let doAudio = true;
    let doVideo = true;
    let offerlessInvite = false;
    if (this.callData.jsepOffer) {
      doAudio = this.callData.jsepOffer.sdp.indexOf('m=audio ') > -1;
      doVideo = this.callData.jsepOffer.sdp.indexOf('m=video ') > -1;
      logger.debug(
        '*Audio ' + (doAudio ? 'has' : 'has NOT') + ' been negotiated',
      );
      logger.debug(
        '*Video ' + (doVideo ? 'has' : 'has NOT') + ' been negotiated',
      );
    } else {
      logger.debug(
        "This call doesn't contain an offer... we'll need to provide one ourselves",
      );
      offerlessInvite = true;
      // In case you want to offer video when reacting to an offerless call, set this to true
      doVideo = false;
    }

    // Any security offered? A missing "srtp" attribute means plain RTP
    let rtpType = '';
    if (this.callData.srtp === 'sdes_optional') {
      rtpType = ' (SDES-SRTP offered)';
    } else if (this.callData.srtp === 'sdes_mandatory') {
      rtpType = ' (SDES-SRTP mandatory)';
    }
    let extra;
    if (offerlessInvite) {
      extra = ' (no SDP offer provided)';
    }
    logger.debug(
      'onincoming call::rtpType , offerlessInvite',
      rtpType,
      offerlessInvite,
      extra,
    );

    self.freeLocalStream();
    try {
      this.localStream = await self.media.Canvas.getStreamWithMicAudioAndFakeVideo();
    } catch (err) {
      logger.warn('Error in getting initial stream', err);
    }

    if (!this.localStream) {
      logger.error('Error intiailziing stream with fake video');
      self.callFSM.process(this.call.FSM_EVENT.CALL_ACCEPT_FAILED);
      return;
    }
    this.sipCallManager.Call.acceptCall({
      offerlessInvite, jsep: this.callData.jsepOffer, doAudio, localStream: this.localStream,
    });
  };

  /**
   * WebAPI - to end Media Server session
   * Also used internally in reInit case.
   * @category API
   */
  endMediaServerSession = () => {
    if (this.callFSM?.state === MEDIA_SERVER_CALL_STATE.CALL_IN_PROGRESS) {
      /* Abort call in case one active, in case abrupt reload , logout etc */
      this.callFSM.trigger(this.call.FSM_ACTION.ABORT_CALL, { cause: 'Media Server Session End' });
    }

    if (this.sessionFSM.state !== MEDIA_SERVER_FSM_STATE.NOT_READY) {
      logger.info(
        'endMediaServerSession::End Media server session',
      );
      this.sipCallManager.Session.endMediaServerSession();
      this.appCallback(WEB_CALL_EVENT.SESSION_EVENT, WEB_CALL_STATE.DISCONNECTED);
    }
  };

  /** WebApp API
   * @param  {intger} acknowledgementCode   Code for call rejection
   * @category API
  */
  declineCall = (acknowledgementCode) => {
    // code 480: Callee currently unavailable
    // code 486: Callee is busy
    this.sipCallManager.Call.declineCall(acknowledgementCode);
    this.appCallback(WEB_CALL_EVENT.HANGUP);
  };

  /**
   * @param {ICE_STATE} state
   */
  handleICEEvent = (state) => {
    switch (state) {
      case ICE_STATE.CHECKING:
        logger.debug('MEDIAPATH::ICE state changed to', state);
        this.mediaState = MEDIA_PATH_STATE.UNKNOWN;
        break;

      case ICE_STATE.CONNECTED:
        logger.info('MEDIAPATH::ICE state changed to', state);
        this.sipCallManager.Session.sendKeepAlive();
        /* Update first and then notify */
        this.callData.mediaUp(true);
        if (this.callData.mediaPathState !== MEDIA_PATH_STATE.UNKNOWN) {
          this.call.logger.debug(`[${this.callFSM.context}] Media path state updated to UP `);
          this.appCallback(WEB_CALL_EVENT.MEDIA_PATH_UPDATE, MEDIA_PATH_STATE.UP);
        }
        if (this.mediaPathGuardTimer) {
          // Clear timer since we recovered media path
          logger.info('MEDIAPATH: Stopping guard timer');
          clearTimeout(this.mediaPathGuardTimer);
          this.mediaPathGuardTimer = null;
        }
        break;

      case ICE_STATE.CLOSED:
        break;

      case ICE_STATE.FAILED:
        logger.warn('MEDIAPATH::ICE state changed to', state);
        // This will not restart ICE negotiation on its own and must be restarted/
        this.callFSM.hangupCall(false); /* Need to inform APP */
        break;

      case ICE_STATE.DISCONNECTED:
        logger.warn('MEDIAPATH::ICE state changed to', state);
        this.callData.mediaUp(false);
        this.appNotifyError(CALL_ERROR.MEDIA_PATH_DOWN);
        if (window.dynamicEnv.REACT_APP_MEDIA_PATH_GUARD === 'true') {
          logger.info('MEDIAPATH:: Starting guard timer');
          this.handleMediaPathError();
        }
        break;

      default:
        break;
    }
  }

  /**
   * Update local stream state after the track changes
   */
  updateLocalStream(isScreenShare = false) {
    const localVideoEle = browserAPI.getLocalHtmlVideoEle(USE_CANVAS_FOR_SCALING, isScreenShare);
    if (localVideoEle) {
      logger.info('updateLocalStream::');
      const myStream = this.sipCallManager.RTCPeer.getRtcPeerStream();
      this.media.WebRTCPeer.setMyStream(this.sipCallManager.RTCPeer.getRtcPeerStream());
      if (myStream.getTracks().length > 2) {
        logger.warn('updateLocalStream',
          myStream.getTracks());
        // logger.assert(myStream.getVideoTracks().length < 2);
      }
      const localVideoStream = new MediaStream();
      const newTrack = WebrtcUtils.getVideoTrack(myStream);
      localVideoStream.addTrack(newTrack);
      this.setLocalVideoStream(localVideoStream);
      browserAPI.attachStreamToMediaElement(
        localVideoEle,
        localVideoStream,
      );
      const self = this;
      this.updateVideoDeviceId(newTrack?.getSettings()?.deviceId, isScreenShare);
      this.media.MediaDevice.updateSharedVideoType(isScreenShare ?
        MEDIA_TYPE.SCREEN : MEDIA_TYPE.VIDEO);
      this.appMediaCallback(MEDIA_ACTION.LOCAL_STREAM_RCVD,
        {
          stream: self.callData.localVideoStream,
          isScreenShare,
        });
    }
  }

  setLocalVideoStream(stream) {
    this.callData.localVideoStream = stream;
    this.media.VideoStream.setLocalVideoStream(this.callData.localVideoStream);
  }

  /**
   * Enforces change of source of media by replacing the track in the stream
   *
   * @param {enum} mediaType  AUDIO/VIDEO
   * @param {MediaDevice} device Selected device
   * @param {bool} desktopMode
   * @param {bool} audioOn
   * @param {*} setShowVideoFallbackUI Callback to display fallback UI when non-null
   * @param {bool} isAudioOutput
   * @returns n/a
   * @category API
   * @throws err on failure to change device
   */
  changeDevice = async (mediaType, device, audioOn,
    setShowVideoFallbackUI = null,
    isAudioOutput = false, mediaConfig = null, isIllumDisabled = false) => {
    if (!device || !device.label) {
      // FIXME: Should not happen though - This needs a fix
      logger.warn(`changeDevice null or invalid device ignoring for ${mediaType}`);
      return;
    }
    logger.info('changeDevice::Updating for',
      `${isAudioOutput ? 'output' : ''}`,
      `${mediaType} with ${device.label}`);

    if (!this.media) {
      logger.assert(this.media, 'Error!!! MediaHandler not initialized');
      return;
    }

    if (isAudioOutput) {
      // Note: Check the setSinkId API support
      // here: https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/setSinkId
      // As a fallback, replaceTrack will be used.
      // Safari support can be checked here: https://bugs.webkit.org/show_bug.cgi?id=216641
      const remoteAudio = document.getElementById(CALL_DASHBOARD.REMOTE_AUDIO_ID);
      if (remoteAudio && remoteAudio.setSinkId) {
        logger.info('Get the remoteAudio.sinkId:', remoteAudio.sinkId);
        logger.info('Audio output device set to',
          device?.label);
        remoteAudio.setSinkId(device?.deviceId)
          .then(() => {
            logger.info('Set the remoteAudio.sinkId to ', remoteAudio.sinkId);
          }).catch((err) => {
            logger.warn('Failed to set sink for Audio, got error-', err);
          });
        return;
      }
      logger.debug('Sinkid not supported. Proceeding to change audio output with replace track');
    }
    try {
      await this.media.MediaDevice.changeDeviceByReplacingTrack(mediaType, device,
        setShowVideoFallbackUI, isAudioOutput, mediaConfig);
    } catch (err) {
      logger.warn(`${err} while attempting device change to ${JSON.stringify(device)}`);
      throw (err);
    }

    // #region To be confirmed with Ayan
    if (mediaType === MEDIA_TYPE.VIDEO ||
      mediaType === MEDIA_TYPE.SCREEN) {
      // Need to mute ?
      this.updateLocalStream(mediaType === MEDIA_TYPE.SCREEN);
      this.callData.currentZoomLevel = DEFAULT_ZOOM_LEVEL;
      // reset zoom level to default 1
      this.media.ZoomLiveVideo.zoomCanvas(DEFAULT_ZOOM_LEVEL);
      this.media.Canvas.updateCanvas(mediaConfig);
    } else if ((mediaType === MEDIA_TYPE.AUDIO) &&
      (!audioOn && !isAudioOutput)) {
      logger.info('MEDIA::Muting', mediaType);
      this.muteUnmute(mediaType, true); // Mute audio
    }
    // #endregion
    let illumInfo = {};
    if (mediaType === MEDIA_TYPE.VIDEO) {
      illumInfo = await this.setIlluminfo([device],
        this.callData?.localVideoStream?.getVideoTracks()[0], isIllumDisabled);
    }

    this.appMediaCallback(MEDIA_ACTION.DEVICE_CHANGE,
      {
        changedMedia: {
          audio: mediaType === MEDIA_TYPE.AUDIO,
          video: mediaType === MEDIA_TYPE.VIDEO,
          screen: mediaType === MEDIA_TYPE.SCREEN,
        },
        illumInfo,
        device,
      });

    this.setTorchStateForVideo(false);
  };

  /**
   * WebAPP API
   * Enforces changes to media configuration for audio/video
   * @param {object} videomediaConfigs
   * @param {object} audioMediaConfigs
   * @param {object} selectedVideoConfig
   * @param {bool} isUserSharing
   * @param {MediaDevice} device
   * @param {object} changedMedia { audio: boolean , video: boolean }
   */
  changeMediaConfigs = (
    audioMediaConfigs,
    selectedVideoConfig,
    isUserSharing,
    isVideoPaused = false,
    changedMedia = { audio: true, video: true },
  ) => {
    if (!selectedVideoConfig) {
      // FIXME: Happens for call initiated from webapp to webapp
      logger.warn('Video profile null');
      return;
    }
    const mediaQualityIndex =
      typeof selectedVideoConfig.streamQuality === 'number'
        ? selectedVideoConfig.streamQuality
        : MEDIA_QUALITY.indexOf(selectedVideoConfig.streamQuality);
    logger.debug(`changeMediaConfigs()  mediaConfigs:video
     mediaQualityIndex:${mediaQualityIndex});
     audio ${JSON.stringify(audioMediaConfigs)} 
     selectedVideoConfig : ${JSON.stringify(selectedVideoConfig)} are we sharing? 
     ${isUserSharing} isVideoPaused:${isVideoPaused}`);

    logger.info('changeMediaConfigs for -', changedMedia,
      'Video config - ', selectedVideoConfig?.name);
    const isDesktopStream = this.callData.videoDeviceId === SCREEN_SHARE_DEVICE.deviceId &&
      !!navigator.mediaDevices.getDisplayMedia;
    let audioMediaConfig = audioMediaConfigs.find(
      (mediaConfig) =>
        mediaConfig.streamQuality ===
        (mediaQualityIndex > -1
          ? mediaQualityIndex
          : DEFAULT_MEDIA_CONFIG.value),
    );

    // from other participant
    if (mediaQualityIndex === VIDEO_MEDIA_PROFILE.FROM_OTHER_PARTICIPANT) {
      audioMediaConfig = audioMediaConfigs.find(
        (mediaConfig) =>
          mediaConfig.streamQuality === VIDEO_MEDIA_PROFILE.CUSTOM,
      );
    }

    try {
      if ((changedMedia.video || changedMedia.screen) && selectedVideoConfig) {
        this.media.MediaConfig.applyVideoConfigsToStream(selectedVideoConfig, isUserSharing,
          isDesktopStream, isVideoPaused);
        logger.info('Video config applied :', JSON.stringify(selectedVideoConfig));
        this.appMediaCallback(MEDIA_ACTION.CONFIG_UPDATE,
          {
            mediaType: MEDIA_TYPE.VIDEO,
            configData: selectedVideoConfig,
            mediaQualityIndex,
          });
      }
      if (changedMedia.audio && audioMediaConfig) {
        this.media.MediaConfig.applyAudioConfigsToStream(audioMediaConfig, isUserSharing);
        logger.info('Audio config applied :', JSON.stringify(audioMediaConfig),
          'Media quality index', mediaQualityIndex);
        this.appMediaCallback(MEDIA_ACTION.CONFIG_UPDATE,
          {
            mediaType: MEDIA_TYPE.AUDIO,
            configData: audioMediaConfig,
            mediaQualityIndex,
          });
      }
      if (!isUserSharing) {
        this.appMediaCallback(MEDIA_ACTION.STREAM_QUALITY_CHANGE,
          mediaQualityIndex);
      }
    } catch (err) {
      logger.warn(
        'changeMediaConfigs() error in applying mediaConfigs:',
        JSON.stringify(err),
      );
    }
  };

  isIlluminationSupportedByCurrentVideoTrack = () => browserAPI.isIlluminationSupportedByTrack(
    this.callData.localVideoStream?.getVideoTracks()[0],
  )

  toggleTorch = (torch, isIlluminationSupportedByCurrentMediaDevice) => {
    this.toggleIllumination(
      this.sipCallManager?.RTCPeer.getRtcPeerStream()?.getVideoTracks()[0], torch,
      isIlluminationSupportedByCurrentMediaDevice,
      // this.callData.localVideoStream?.getVideoTracks()[0], torch,
    );
  }

  toggleIllumination = (videoTrack, torch, isIlluminationSupportedByCurrentMediaDevice) => {
    if (!videoTrack && videoTrack.kind !== MEDIA_TYPE.VIDEO) {
      logger.warn('toggleIllumination() invalid track, returning.');
      throw (new Error('invalid track'));
    }

    logger.log('toggleIllumination() videotrack:', videoTrack, ' torch:', torch);

    if (!isIlluminationSupportedByCurrentMediaDevice) {
      logger.warn('toggleIllumination() illumination is not supported by current media device');
      throw (new Error('Illumination is not supported by current media device'));
    }
    this.setTorchStateForVideo(true);
    this.media.Illumination.toggleIllum(videoTrack, torch);
  }

  freeLocalStream = () => {
    if (this.localStream) {
      this.localStream.getTracks().forEach((track) => track.stop());
      this.localStream = null;
    }
  }

  setIlluminfo = async (devices, videoTrack, isIllumDisabled) => {
    let illumInfo = {};
    try {
      if (isIllumDisabled) {
        // default values
        illumInfo = DEFAULT_ILLUM_INFO;
        illumInfo.perVideoSourceIllumInfo[0].sourceName = devices[0].label;
      } else {
        // Get illum caps and set to LSDS
        illumInfo = await browserAPI.getIllumInfoOfActiveVideoTrack(devices, videoTrack);
      }
    } catch (error) {
      logger.warn('setIlluminfo error:', error);
    }
    return illumInfo;
  }

  /**
   * Was 'interchangeWebCamAndCanvas' earlier
   * This method replace webcam video with dummy canvas video
   * and vice-versa, based on isVideoSharingStarted
   * @ayan - rename as appropriate
   * @param {bool} isVideoSharingStarted
   * @param {MediaConfig} videoMediaConfig
   * @returns none
   * @category API
   * @throws err
   */
  async displayVideo(isVideoSharingStarted = false,
    selectedVideoDevice = null,
    videoMediaConfig = null, isIllumDisabled = false) {
    const isScreenShare = (selectedVideoDevice === SCREEN_SHARE_DEVICE) &&
      !!navigator.mediaDevices.getDisplayMedia;

    if (isVideoSharingStarted === false) {
      // We need to stop stream display
      const localVideoStream = await this.media.VideoStream.stopStreamDisplay();
      this.setLocalVideoStream(localVideoStream);
      this.appMediaCallback(MEDIA_ACTION.LOCAL_STREAM_RCVD,
        { stream: this.callData.localVideoStream, isScreenShare });
      logger.debug('displayVideo() deviceId:', this.callData.videoDeviceId);
      if (this.callData.videoDeviceId === SCREEN_SHARE_DEVICE.deviceId) {
        this.appMediaCallback(MEDIA_ACTION.SCREEN_SHARE_TOGGLED,
          { isStarted: false, setDevice: true });
      }
      this.callData.videoDeviceId = null;
      logger.info('Video display OFF');
      return Promise.resolve();
    }

    // Select the device provided else use the one from call data
    const videoDeviceId = selectedVideoDevice?.deviceId ??
      this.callData.videoDeviceId;

    logger.info('displayVideo with device:', CommonUtility.printableId(videoDeviceId));
    try {
      await this.media.VideoStream.displayStream(videoMediaConfig,
        videoDeviceId,
        async (gumStream, deviceId) => {
          this.setLocalVideoStream(gumStream);
          this.updateVideoDeviceId(deviceId, isScreenShare);
          this.media.MediaDevice.updateSharedVideoType(isScreenShare ?
            MEDIA_TYPE.SCREEN : MEDIA_TYPE.VIDEO);
          this.callData.localVideoStream = gumStream;
          const self = this;
          let illumInfo = {};
          if (!isScreenShare) {
            illumInfo = await this.setIlluminfo(
              [selectedVideoDevice ?? this.callData],
              gumStream?.getVideoTracks()[0], isIllumDisabled,
            );
          }
          this.appMediaCallback(MEDIA_ACTION.LOCAL_STREAM_RCVD,
            { stream: self.callData.localVideoStream, isScreenShare, illumInfo });
          if (isScreenShare) {
            try {
              await this.media.MediaConfig.applyMediaConstraintsOnVideoTrack(
                gumStream?.getVideoTracks()[0],
                {
                  width: { ideal: videoMediaConfig?.width },
                  height: { ideal: videoMediaConfig?.height },
                  frameRate: { ideal: parseInt(videoMediaConfig?.frameRate, 10) || undefined },
                },
                isScreenShare,
              );
            } catch (error) {
              logger.warn('displayStream::Failed to apply constraints on screen share, error:', error);
              throw (error);
            }

            logger.debug('displayStream() calling SS started callback');
            this.appMediaCallback(MEDIA_ACTION.SCREEN_SHARE_TOGGLED,
              { isStarted: true, setDevice: false },
              illumInfo);
          }
        });
      logger.info('Video display ON from', this.callData.videoDeviceId);
      return Promise.resolve();
    } catch (err) {
      logger.error('Error in displayStream', err);
      return Promise.reject(err);
    }
  }

  togglePauseVideo = (videoState) => {
    logger.debug('togglePauseVideo() state:', videoState);
    this.media.PauseLiveVideo.togglePauseVideo(videoState);
  }

  setPauseVideoState = (state) => {
    this.media.CallFunctionState.setPauseVideoState(state);
  }

  changeVideoZoomLevel = (value) => {
    logger.debug('changeVideoZoomLevel() value:', value);
    if (value === this.callData.currentZoomLevel) {
      return;
    }

    this.media.ZoomLiveVideo.zoomCanvas(value);
    this.callData.currentZoomLevel = value;
  }

  captureAndStoreStreamFromDesktop = () =>
    this.media.VideoStream.captureAndStoreStreamFromDesktop();

  /**  WebApp API:
   * Callback to webapp
   * @param  {WEB_CALL_EVENT}   callEvent   Indicates the event for which callback is triggered
   * @param  {varargs}          cbData      Multiple arguments as required by the callback to
   *  provide context of event
   * @category API
   */
  appCallback = (callEvent, ...cbData) => {
    if (Object.keys(this.callbacks).includes(callEvent)) {
      /* Note: Do not change the format of the log below, as it is specific to documentation */
      if (this.call) {
        let args = [''];
        args = cbData.map((arg) => {
          if (typeof arg === 'object') return JSON.stringify(arg);
          return arg;
        });
        const callState = this.webCallState();
        this.call.logger.info(`[${this.callFSM?.context}] ${callState} -> ${APP_FSM} [ label = "Evt:${callEvent}"]`,
          JSON.stringify(args));
      }
      if (this.callbacks[callEvent]) {
        this.callbacks[callEvent](...cbData);
      } else {
        logger.warn(`No callback defined for ${callEvent}`);
      }
    } else {
      logger.warn(`No App callback defined for Call Event:${callEvent}`);
    }
  }

  /**  WebApp API:
   * Callback to webapp
   * @param  {WEB_CALL_STATE}   callState   Prevailing state of call
   * @param  {CALL_ERROR}       callError   Error code
   * @param  {object}           errorData   Additional data regarding error
   * where ever applicable.
   * @category API
   */
  appNotifyError = (callError = CALL_ERROR.NONE, errorData = {}) => {
    /* Note: Do not change the format of the log below, as it is specific to documentation */
    const callState = this.webCallState();
    if (_.inRange(callError, CALL_ERROR.MAX_SESSION_ERROR,
      CALL_ERROR.TERMINAL_ERROR_LAST) &&
      ((this.callFSM?.state === MEDIA_SERVER_CALL_STATE.CALL_IDLE) ||
        (this.callData.lastErrNotified !== CALL_ERROR.NONE))) {
      // Suppress error reporting when call is idle
      // OR to avoid multiple errors
      this.session.logger.warn(`[${this.callFSM?.context}] ${callState} -> ${APP_FSM} Not notifying call error with no call in progress`);
      return;
    }
    logger.assert(callState !== undefined, 'FSM: Error! Call state is undefined');
    const callErrStr = CommonUtility.mapName(CALL_ERROR, callError);
    const callStateLog = `[${this.callFSM?.context}] ${APP_FSM} <- ${callState} [ label = "Evt:${callErrStr}(${callError})" ]`;
    if (this.call) {
      this.call.logger.info(callStateLog);
    } else {
      this.session.logger.info(callStateLog);
    }
    this.callbacks.onError(callState, callError, errorData);
    this.callData.lastErrNotified = callError;
  }

  mediaServerEventCallback = async (mediaServerEvent, cbData) => {
    switch (mediaServerEvent) {
      case MEDIA_SERVER_EVENT.SERVER_CONNECTED:
        this.sessionFSM.process(this.session.FSM_EVENT.CONNECTED);
        break;
      case MEDIA_SERVER_EVENT.SERVER_CONN_DESTROYED:
        this.sessionFSM.process(this.session.FSM_EVENT.DESTROYED);
        break;
      case MEDIA_SERVER_EVENT.SIP_SESSION_ATTACHED:
        this.sessionFSM.process(this.session.FSM_EVENT.ATTACHED);
        this.sessionFSM.trigger(this.session.FSM_ACTION.START_REGISTER);
        break;
      case MEDIA_SERVER_EVENT.ON_CONSENT_DIALOG:
        if (this.callbacks.onConsentDialog) {
          this.callbacks.onConsentDialog();
        }
        break;
      case MEDIA_SERVER_EVENT.ON_WEBRTC_STATE_DOWN:
        this.media.WebRTCPeer.clearPeerConnection();
        break;
      case MEDIA_SERVER_EVENT.REGISTATION_FAILED:
        this.sessionFSM.process(this.session.FSM_EVENT.REGISTER_FAILED);
        break;
      case MEDIA_SERVER_EVENT.REGISTERED:
        if (this.sessionFSM.state !== MEDIA_SERVER_FSM_STATE.READY) {
          this.sessionFSM.process(this.session.FSM_EVENT.REGISTERED);
        }
        break;
      case MEDIA_SERVER_EVENT.CALLING:
        this.callFSM.process(this.call.FSM_EVENT.CALLING);
        this.appCallback(WEB_CALL_EVENT.CALL_INITIATED, this.callData?.callId);
        break;
      case MEDIA_SERVER_EVENT.INCOMING_CALL:
        this.callData.callerUserName = cbData.callerName;
        this.callData.calleeUserName = this.userName;
        this.callData.isIncoming = true;
        this.callData.callId = cbData.callId;
        logger.debug(
          'Incoming call from::handleMediaServerMessage::Call from',
          `${cbData.callerName}`,
        );
        logger.debug('displayname: ', cbData.displayname);
        this.callData.callerName = cbData.callerName;
        this.callData.jsepOffer = cbData.jsep;
        this.callData.srtp = cbData.srtp;
        this.callFSM.setContext(this.callData.callId);
        this.callFSM.process(this.call.FSM_EVENT.INCOMING_CALL);
        this.appCallback(WEB_CALL_EVENT.CALL_INCOMING,
          cbData.callerName, this.callData.callId, cbData.displayname.replace(/"/g, ''));
        break;
      case MEDIA_SERVER_EVENT.MISSED_CALL:
        this.appCallback(WEB_CALL_EVENT.CALL_MISSED, cbData.rtnName, cbData.callId, cbData.caller);
        break;
      case MEDIA_SERVER_EVENT.CALL_ACCEPTED_BY_REMOTE:
        this.media.WebRTCPeer.setPeerConnection(cbData.pc);
        this.callFSM.setContext(this.callData.callId);
        this.callFSM.process(this.call.FSM_EVENT.ACCEPTED);
        this.appCallback(WEB_CALL_EVENT.CALL_ACCEPTED,
          cbData.acceptedUserName, !cbData.acceptedUserName, this.callData.callId);
        break;
      case MEDIA_SERVER_EVENT.CALL_HANGUP:
        // Handle DNS error(wrong sip addr) and NOT found error, Bad extension error
        this.callFSM.process(this.call.FSM_EVENT.CALL_HANGUP,
          {
            hangupReason: cbData.hangupReason,
            hangupCode: cbData.hangupCode,
            reasonHeader: cbData.reasonHeader,
            hangupBy: cbData.hangupBy,
          });
        break;
      case MEDIA_SERVER_EVENT.CALL_HANGUP_BY_SERVER:
        this.callFSM.trigger(this.call.FSM_ACTION.MEDIA_SERVER_HANGUP);
        break;
      case MEDIA_SERVER_EVENT.ON_LOCAL_STREAM:
        logger.debug(
          'onMediaServerSessionSuccess:: Got a local stream ::',
          this.userName,
          this.callData.callerUserName,
        );
        {
          const localVideoStream = new MediaStream();
          localVideoStream.addTrack(WebrtcUtils.getVideoTrack(cbData.stream));

          this.callData.localVideoStream = localVideoStream;
          if (
            !this.canvasForScaleUp &&
            USE_CANVAS_FOR_SCALING
          ) {
            this.canvasForScaleUp = this.media?.Canvas.getCanvas(
              CALL_DASHBOARD.CANVAS_FOR_SCALE_UP,
            );
          }
          this.callData.localStreamReady = true;
          this.appMediaCallback(MEDIA_ACTION.LOCAL_STREAM_RCVD,
            {
              stream: localVideoStream,
              isScreenShare: this.callData.videoDeviceId &&
                this.callData.videoDeviceId === SCREEN_SHARE_DEVICE.deviceId &&
                !!navigator.mediaDevices.getDisplayMedia,
            });
          this.media.WebRTCPeer.setMyStream(this.sipCallManager.RTCPeer.getRtcPeerStream());
        }
        break;
      case MEDIA_SERVER_EVENT.ON_REMOTE_STREAM:
        logger.debug(
          'onMediaServerSessionSuccess::Got a remote stream::',
          cbData.stream,
          this.calleeUserName,
        );

        if (window.dynamicEnv.REACT_APP_MEDIA_DEBUG === 'true') {
          if (!this.bitrateTimer) {
            this.bitrateTimer = setInterval(() => {
              logger.debug(
                `MEDIA-DEBUG::Audio::Remote:: Bitrate:${this.sipCallManager.RTCPeer.getBitrate()}`,
              );
              this.runDebugger();
            }, 5000);
          }
          if (!this.videoResTimer) {
            this.videoResTimer = setInterval(() => {
              const videoSender = this.media.WebRTCPeer.getRtcRtpSender(MEDIA_TYPE.VIDEO);
              const videoTrackSettings = videoSender?.track?.getSettings();
              logger.debug(`MEDIA-DEBUG::Video::Res ${videoTrackSettings?.width} x ${videoTrackSettings?.height}`);
            }, 5000);
          }
        }
        logger.debug(
          'onMediaServerSessionSuccess::Got a remote stream, isStreamApplied',
        );
        this.appMediaCallback(MEDIA_ACTION.REMOTE_STREAM_RCVD, cbData.stream);
        break;
      case MEDIA_SERVER_EVENT.ON_DATA_CLOSE:
        if (this.isCallInProgress() && this.callFSM.state !== MEDIA_SERVER_CALL_STATE.CALL_HUNGUP) {
          logger.error(`Abnormal DATA close for ${cbData.protocol} label ${cbData.label}`);
          this.handleMediaPathError();
        } else {
          logger.info(`DATA closed for ${cbData.protocol} label ${cbData.label}`);
        }
        break;
      case MEDIA_SERVER_EVENT.ON_DATA:
        {
          const { data, label } = cbData;

          if (data && label === 'event') {
            this.appCallback(
              WEB_CALL_EVENT.DATA_RCVD,
              JSON.parse(data),
              {
                sendData: this.sipCallManager.DATA.sendDataMessageToMediaServer,
                endCall: (error) => {
                  if (error) {
                    /* This is being triggered internally by LSDS protocol error */
                    this.callFSM.trigger(this.call.FSM_ACTION.ABORT_CALL, { cause: 'LS Network Close' });
                  } else {
                    this.callFSM.trigger(this.call.FSM_ACTION.END_CALL);
                  }
                },
                datastreamHangupMessageReceived:
                  this.datastreamHangupMessageReceived,
              },
            );
          } else {
            logger.debug(
              'onMediaServerSessionSuccess::Got datastream event::',
              data,
              label,
            );
          }
        }
        break;
      case MEDIA_SERVER_EVENT.ON_WEBRTC_PEER_CLEANUP:
        if (this.callFSM.state === MEDIA_SERVER_CALL_STATE.CALL_DISCONNECTED) {
          this.callFSM.process(this.call.FSM_EVENT.HANGUP_COMPLETE);
        }
        this.performCallCleanup();
        break;
      case MEDIA_SERVER_EVENT.REINIT_SERVER_SESSION:
        this.sessionFSM.trigger(this.session.FSM_ACTION.START_REINIT);
        break;
      case MEDIA_SERVER_EVENT.CALL_ACCEPTED:
        logger.debug('CALL_ACCEPTED locally pc:', cbData.pc);
        this.media.WebRTCPeer.setPeerConnection(cbData.pc);
        this.callFSM.process(this.call.FSM_EVENT.ACCEPTED);
        break;
      case MEDIA_SERVER_EVENT.RECEIVED_UID:
        this.appCallback(WEB_CALL_EVENT.RECEIVED_LOCAL_PID, cbData.pid);
        if (cbData.callId) {
          this.callData.callId = cbData.callId;
        }
        break;
      case MEDIA_SERVER_EVENT.SERVER_SESSION_RECONNECTED:
        this.initialSession = false;
        this.sessionFSM.process(this.session.FSM_EVENT.RECONNECTED);
        break;
      default:
        break;
    }
  }

  mediaServerErrorCallback = (mediaServerError, errorData) => {
    switch (mediaServerError) {
      case MEDIA_SERVER_ERROR_EVENT.SERVER_CONNECTION_FAILED:
        /* Note! At this stage we are not able to distinguish between
              network or other issues */
        this.sessionFSM.process(this.session.FSM_EVENT.CONNECT_ERROR);
        break;
      case MEDIA_SERVER_ERROR_EVENT.SIP_SESSION_ERROR:
        if (this.callFSM) {
          this.callFSM.trigger(this.call.FSM_ACTION.ABORT_CALL, { cause: errorData?.error });
        } else {
          logger.warn(`${SESSION_FSM}:${this.sessionFSM.state} Setup Error`);
          switch (this.sessionFSM.state) {
            case MEDIA_SERVER_FSM_STATE.REGISTERING:
              logger.error('Error during registration, can not proceed.');
              this.sessionFSM.process(this.session.FSM_EVENT.REGISTER_FAILED);
              break;
            default:
              // May need to address other states in future
              break;
          }
        }
        break;
      case MEDIA_SERVER_ERROR_EVENT.SIP_SESSION_ATTACH_FAILED:
        this.sessionFSM.process(this.session.FSM_EVENT.ATTACH_FAILED);
        break;
      case MEDIA_SERVER_ERROR_EVENT.REMOTE_JSEP_ACCEPTANCE_ERROR:
        this.callFSM.trigger(this.call.FSM_ACTION.END_CALL, errorData.updateState);
        break;
      case MEDIA_SERVER_ERROR_EVENT.SIP_UPDATE_ERROR:
        this.appNotifyError(CALL_ERROR.UPDATE_ERROR, { error: errorData.error });
        break;
      case MEDIA_SERVER_ERROR_EVENT.SERVER_SESSION_RECONNECT_ERROR:
        this.sessionFSM.process(this.session.FSM_EVENT.CONNECT_ERROR);
        break;
      case MEDIA_SERVER_ERROR_EVENT.MAKE_CALL_FAILED:
        {
          const { error } = errorData;
          if (error.name === GUM_EXCEPTION.NOT_READABLE_ERROR) {
            logger.error(error.message);
          } else {
            logger.error('Got error while placing call', error);
          }
          this.appNotifyError(CALL_ERROR.AUDIO_PERMISSION_DENIED,
            { error: { name: error.name, message: error.message } });
          this.callFSM.process(this.call.FSM_EVENT.CALL_INIT_FAILED);
          this.freeLocalStream();
        }
        break;
      case MEDIA_SERVER_ERROR_EVENT.CALL_ACCEPT_FAILED:
        this.appNotifyError(CALL_ERROR.AUDIO_PERMISSION_DENIED,
          { error: { name: errorData.error.name, message: errorData.error.message } });
        this.callFSM.process(this.call.FSM_EVENT.CALL_ACCEPT_FAILED);
        this.freeLocalStream();
        break;
      default:
        break;
    }
  }

  /**  WebApp API:
   * Callback to webapp for media events
   * @param  {MEDIA_EVENT}   mediaEvent   Indicates the event for which callback is triggered
   * @param  {varargs}       cbData      Multiple arguments as required by the callback to
   *  provide context of event
   */
  appMediaCallback = (mediaEvent, cbData) => {
    // const callState = this.webCallState();
    if (Object.keys(this.callbacks.mediaAction).includes(mediaEvent)) {
      /* Note: Do not change the format of the log below, as it is specific to documentation */
      this.callbacks.mediaAction[mediaEvent](cbData);
    } else {
      logger.warn(`No Media callback for Call Event:${mediaEvent}`);
    }
  }

  getNextImageName = () => {
    // gets an image name with incrementing id
    const zeroPad = (num, places) => String(num).padStart(places, '0');
    const name = 'IMG_' + zeroPad(this.nextPictureId, 5) + '_A.jpg';
    this.nextPictureId += 1;
    return name;
  }

  async base64ToFile(dataURL) {
    return (fetch(dataURL)
      .then((result) => result.arrayBuffer()));
  }

  /**
   * Asynchronous processing for capture image request from App.
   * Pauases video, takes image capture at highest possible resolution and
   *  then restores video play
   * @param {*} captureOptions  Provides of video config object and
   *  flag indicating screen share in progress
   * @returns Promise
   * on resolve - returns image data
   * on error - error description
   */
  takePicture = async (captureOptions, imageMetaDataCB, allowGpsLocation = false) => {
    const { videoConfig, imgCapTorchModeState,
      isVideoPaused, activeDeviceIllumInfo, productVersion, imgMaxHeight } = captureOptions;
    const isScreenShare = this.callData.videoDeviceId === SCREEN_SHARE_DEVICE.deviceId;
    logger.debug('takePicture() called, capture options:', captureOptions, 'device id', this.callData.videoDeviceId);
    /* Get device id before pausing video */
    if (!isVideoPaused && USE_CANVAS_FOR_SCALING) {
      await this.media.VideoStream.pauseVideo(videoConfig);
    }
    return new Promise((resolve, reject) => {
      try {
        this.media.ImageCapture.captureImage(
          this.callData.localVideoStream, imgCapTorchModeState, this.isTorchForVideoEnabled,
          activeDeviceIllumInfo,
          isScreenShare,
          captureOptions.permissions,
          isVideoPaused,
          imgMaxHeight,
        ).then(async (imageInfo) => {
          // Image capture success; continue video share
          this.media.VideoStream.resumeVideo(videoConfig, isScreenShare, isVideoPaused);
          try {
            const processedImageData = await this.processImageData(
              imageInfo, imageMetaDataCB, productVersion, isScreenShare, allowGpsLocation,
            );
            const myDate = new Date(Date.now());
            logger.info('Image got processed image data: ', myDate.getHours() + ':' + ('0' + (myDate.getMinutes())).slice(-2) + ':' + myDate.getSeconds() + ':' + myDate.getMilliseconds());
            resolve(processedImageData);
          } catch (err) {
            reject(err);
          }
        }).catch(async (err) => {
          // Image capture failed; continue video share
          this.media.VideoStream.resumeVideo(videoConfig, isScreenShare, isVideoPaused);
          reject(err);
        });
      } catch (err) {
        logger.error('Error in capture image', err);
        reject(err);
      }
    });
  };

  /**
   * Processes the raw image data to add required EXIF tags and load lsExif
   * @param {*} imageData Image captured from video stream
   */
  /**   * Processes the raw image data to add required EXIF tags
   * @param  {} imageData the data of image as url
   * @param  {} imageMetaData the telestrations meta data to share
   *Return an object which contains the whole image data
   */
  processImageData = (imageInfo, imageMetaDataCB, productVersion,
    isScreenShare = false, allowGpsLocation = false) =>
    new Promise((resolve, reject) => {
      const exif = new LsExif();
      exif.software = translate('APPNAME') + ' ' + productVersion;
      exif.author = this.regData.display_name;
      exif.TeleV3 = imageMetaDataCB(imageInfo.imageRes, isScreenShare);
      const geolocationdata = this.media.Location.getGeoLocationData();
      if (allowGpsLocation && geolocationdata) {
        exif.gpsLatitude = Math.abs(geolocationdata.coords.latitude);
        exif.gpsLongitude = Math.abs(geolocationdata.coords.longitude);
        exif.gpsAltitude = geolocationdata.coords.altitude ?
          parseFloat(geolocationdata.coords.altitude.toString()).toFixed(2) : 0;

        exif.gpsLongitudeRef = Math.abs(geolocationdata.coords.longitude) < 0 ? 'W' : 'E';
        exif.gpsLatitudeRef = Math.abs(geolocationdata.coords.longitude) < 0 ? 'S' : 'N';
        exif.gpsAltitudeRef = geolocationdata.coords.altitude &&
          Math.abs(geolocationdata.coords.altitude) < 0 ? 1 : 0;
      }
      exif.saveFromDataUrl(imageInfo.imageData, null, null).then((imageFromDataURL) => {
        this.base64ToFile(imageFromDataURL).then((arrayBuffer) => {
          try {
            exif.onLoadCallback = () => {
              resolve({
                name: this.getNextImageName(),
                complete: true,
                data: new Uint8Array(arrayBuffer),
                hash: Array.from(exif.generateHash()),
                TeleV3: _.cloneDeep(exif.TeleV3),
                config: imageInfo.imageRes,
              });
            };
            // initialize exif tags
            exif.load(
              new Blob([new Uint8Array(arrayBuffer)],
                { type: IMAGE_MIME_TYPE.JPEG }),
              // eslint-disable-next-line no-unused-vars
              (_pid) => EXIF_CLR_CODE,
            );
          } catch (error) {
            logger.warn('Error in processing Image Data', error);
          }
        });
      }).catch((err) => {
        logger.warn('Error in processImageData', err);
        reject(err);
      });
    })

  /**
   * @category FSM
   */
  reconnectMediaServerSession = () => {
    logger.assert(this.sipCallManager.Session.getSessionId() !== null, 'Cant attempt reconnect without existing session');
    if (this.isCallInProgress()) {
      /* If call is in progress, we will perform frequent reconnect and continue till
      call either recovers or ends; hence not counting reconnectAttempts */
      this.reconnectionAttemptCnt = 0;
      logger.info(new Date().toLocaleTimeString(), 'RECONNECT ReconnectMediaServerSession ',
        this.reconnectionAttemptCnt, ' of ',
        TIMER_CONSTANTS.MAX_RECONNECTION_ATTEMPTS);
    } else {
      logger.info(new Date().toLocaleTimeString(), 'RECONNECT CallManager::reconnectMediaServerSession Attempt ',
        this.reconnectionAttemptCnt, ' of ',
        TIMER_CONSTANTS.MAX_RECONNECTION_ATTEMPTS);
    }

    this.sipCallManager.Session.reconnectSession();
  };

  /**
   * Action handler
   * @category Session-FSM
   */
  reInitSession = () => {
    if (this.callFSM.state === MEDIA_SERVER_CALL_STATE.CALL_IN_PROGRESS) {
      /* Abort previous call */
      this.callFSM.trigger(this.call.FSM_ACTION.ABORT_CALL, { cause: 'Session Reinit' });
    }
    logger.warn('RECONNECT: Ending previous session with MediaServer');
    /* Destroy previous session */
    this.sessionFSM.trigger(this.session.FSM_ACTION.START_DESTROY);
    const self = this;
    /* We wait for the initial session to be destroyed before the re-init */
    this.session.reInitTimer = setTimeout(() => {
      /* Indicate so that web app layer may need to use this for some special handling
      */
      self.initialSession = false;
      self.sessionFSM.trigger(this.session.FSM_ACTION.START_SESSION);
    }, TIMER_CONSTANTS.REINIT_START_DELAY);
  };

  /**
   * WebApp API
   * This function handles abnormal close due to some error condition
   * Most likely scenario is call failure triggered due to network errors
   * @category [API]
   */
  abortCall = () => {
    this.callData.callAborted = true;
    this.endCall(false);
  }

  /** WebApp API
   * This function handles ending the call from local side.
   * @param {boolean} appTriggered  Indicates if the End call is triggered by App layer
   * If WebApp triggers the call end then  flag appTriggered is true.
   * If the call is being disconnected due to an internal error e.g.
   * LSDS detecting error appTriggered is set to false
   * @category API
   */
  endCall = (appTriggered = true) => {
    /* Note: Do not change the format of the log below, as it is specific to documentation */
    logger.info(`[${this.callFSM.context}] ${APP_FSM} -> ${CALL_FSM}:${this.callFSM.state} [ label = "Act:endCall" ]`,
      `Triggered by App: ${appTriggered}`);
    switch (this.callFSM.state) {
      case MEDIA_SERVER_CALL_STATE.CALL_IDLE:
        this.call.logger.warn(`[${this.callFSM.context}] ${CALL_FSM}: Call already ended`,
          `current state ${this.callFSM.state} for endCall`);
        break;

      case MEDIA_SERVER_CALL_STATE.CALL_IN_PROGRESS:
        if (appTriggered === false) {
          /* Notify UI app */
          this.callFSM.trigger(this.call.FSM_ACTION.MEDIA_SERVER_HANGUP);
        } else {
          this.callFSM.trigger(this.call.FSM_ACTION.START_DISCONNECT);
        }
        break;

      case MEDIA_SERVER_CALL_STATE.CALL_IN_RCVD:
        this.callFSM.trigger(this.call.FSM_ACTION.MEDIA_SERVER_DECLINE);
        break;

      case MEDIA_SERVER_CALL_STATE.CALL_INITIATED:
        this.callFSM.trigger(this.call.FSM_ACTION.MEDIA_SERVER_HANGUP);
        break;

      case MEDIA_SERVER_CALL_STATE.CALL_DISCONNECTING:
      case MEDIA_SERVER_CALL_STATE.CALL_DISCONNECTED:
        this.callFSM.trigger(this.call.FSM_ACTION.MEDIA_SERVER_HANGUP);
        break;

      default:
        this.call.logger.warn(`NEED HANDLING endCall in state ${this.callFSM.state}`);
        break;
    }
  }

  /* ========== CALL specific events and handlers ========== */
  /* Called when hangup received from Media Server
  Hangup event has various cases based on what stage, and who initiates a call disconnect
  1. Peer declining call
  2. Peer not available
  3. Peer not picking up
  4. Confirmation to local hangup
  5. Confirmation to local cancel
  */
  handleHangup = (evtData) => {
    let callError = null;
    if (evtData.hangupCode) {
      Object.values(MEDIA_SERVER_HANGUP_REASON).every((v) => {
        if (v.code === evtData.hangupCode) {
          /* bug#1313 - MediaServer hangup reason is 487 both on Cancelling the outbound call
          and not responding to incoming call */
          if (evtData.reasonHeader && v.reasonToErrMap && v.reasonToErrMap[evtData.reasonHeader]) {
            callError = v.reasonToErrMap[evtData.reasonHeader];
          } else {
            callError = v.error;
          }
          if ((Array.isArray(v.reason)) &&
            !v.reason.includes(evtData.hangupReason)) {
            logger.assert(v.reason === evtData.hangupReason,
              'Mismatch in Reason str', v.reason, evtData.hangupReason);
          }
          const callErrName = CommonUtility.mapName(CALL_ERROR, callError);
          logger.info(`[${this.callFSM.context}] ${CALL_FSM}: ${this.callFSM.state} Event: Hangup`,
            `(Reason: ${evtData.hangupReason} Code: ${evtData.hangupCode})`,
            `Error: ${callErrName} (${callError})`);
        }
        /* Continue 'every' only if callError not set */
        return callError === null;
      });
    }
    if (this.callFSM.state === MEDIA_SERVER_CALL_STATE.CALL_IDLE) {
      this.call.logger.info(`[${this.callFSM.context}] ${CALL_FSM}: Not processing hangup in IDLE state!`,
        evtData.hangupCode, evtData.hangupReason,
        callError);
      return;
    }

    this.callData.hangupCodeDone();

    switch (callError) {
      case CALL_ERROR.NONE:
        if (this.callFSM.state === MEDIA_SERVER_CALL_STATE.CALL_IN_PROGRESS) {
          this.callFSM.trigger(this.call.FSM_ACTION.START_DISCONNECT);
        } else {
          this.callFSM.trigger(this.call.FSM_ACTION.MEDIA_SERVER_HANGUP);
        }
        break;

      case CALL_ERROR.CALL_DECLINED:
        this.appNotifyError(callError);
        if (this.callFSM.state === MEDIA_SERVER_CALL_STATE.CALL_HUNGUP) {
          /* A Decline from local is a hangup from local end;
           so additional hangup not required */
          this.callFSM.process(this.call.FSM_EVENT.CALL_ENDED);
        } else {
          this.callFSM.trigger(this.call.FSM_ACTION.MEDIA_SERVER_HANGUP);
        }
        break;

      case CALL_ERROR.CALL_CANCELLED:
        this.appNotifyError(callError);
        if (this.callFSM.state === MEDIA_SERVER_CALL_STATE.CALL_HUNGUP) {
          /* A cancel is a hangup from local end; so additional hangup
           not required */
          this.callFSM.process(this.call.FSM_EVENT.CALL_ENDED);
        } else {
          this.callFSM.trigger(this.call.FSM_ACTION.MEDIA_SERVER_HANGUP);
        }
        break;

      case CALL_ERROR.CALL_ENDED:
        if (this.callFSM.state === MEDIA_SERVER_CALL_STATE.CALL_IN_PROGRESS) {
          this.appNotifyError(callError);
          this.callFSM.trigger(this.call.FSM_ACTION.START_DISCONNECT);
          this.callFSM.trigger(this.call.FSM_ACTION.MEDIA_SERVER_HANGUP);
        } else if (this.callFSM.state === MEDIA_SERVER_CALL_STATE.CALL_HUNGUP) {
          this.appNotifyError(callError);
          this.callFSM.process(this.call.FSM_EVENT.CALL_ENDED);
        }
        break;

      case CALL_ERROR.NO_RESPONSE:
      case CALL_ERROR.NOT_FOUND_ERROR:
        if (this.callFSM.state === MEDIA_SERVER_CALL_STATE.CALL_HUNGUP) {
          this.appNotifyError(CALL_ERROR.CALL_ENDED);
          this.callFSM.process(this.call.FSM_EVENT.CALL_ENDED);
        } else {
          this.appNotifyError(callError);
          this.callFSM.trigger(this.call.FSM_ACTION.MEDIA_SERVER_HANGUP);
        }
        break;
      case CALL_ERROR.CALL_NO_ANSWER:
        this.call.logger.info(`[${this.callFSM.context}] Call missed locally!`, evtData.hangupCode, evtData.hangupReason, callError, this.callFSM.state);
        this.appNotifyError(CALL_ERROR.CALL_NO_ANSWER);
        this.callFSM.trigger(this.call.FSM_ACTION.MEDIA_SERVER_HANGUP);
        break;
      default:
        this.call.logger.info(`[${this.callFSM.context}] Generic call error!`, evtData.hangupCode,
          evtData.hangupReason, callError, this.callFSM.state);
        this.appNotifyError(evtData.hangupCode);
        this.callFSM.trigger(this.call.FSM_ACTION.MEDIA_SERVER_HANGUP);
        break;
    }
  }

  /**
   * Needs to be groomed to make use in troubleshooting
   * @param {number} interval value in mseconds for the timers
   */
  runDebugger = () => {
    const self = this;
    this.sipCallManager.RTCPeer.getPeerConnection()?.getStats()
      .then((stats) => {
        stats.forEach((res) => {
          if (!res) return;
          /*
          if (res.kind === 'video') {
            if (res.type === 'inbound-rtp') {
              // WORKS
              logger.info('MEDIA:: Inbound Video => Packets received:',
                res.packetsReceived);
            } else if (res.type === 'outbound-rtp') {
              // Not working as expected
              logger.info('MEDIA:: Outbound Video => Packets sent:', res.packetsSent);
            } else if (res.type === 'remote-inbound-rtp') {
              logger.info('MEDIA:: Remote Inbound Video => ', JSON.stringify(res), res);
            } else if (res.type === 'remote-outbound-rtp') {
              // TODO
            } else {
              logger.info('MEDIA:: Video => Id:', res.id, 'Type:', res.type);
            }
          }
          */
          if (res.kind === 'audio') {
            if (res.id.toLowerCase().indexOf('rtcinboundrtpaudiostream') > -1) {
              logger.debug('MEDIA-DEBUG::Audio::Remote => Level:', (res.audioLevel * 1000).toFixed(2), 'Energy:',
                (res.totalAudioEnergy * 1000).toFixed(2));
            } else if (res.type === 'inbound-rtp') {
              logger.debug('MEDIA-DEBUG::Audio::Remote => Packets received:',
                res.packetsReceived, 'Level: ', res.audioLevel, JSON.stringify(res));
            } else if (res.type === 'outbound-rtp') {
              /*
              logger.info('MEDIA:: Outbound Audio => Packets sent:', res.packetsSent,
                'Level: ', res.audioLevel);
              */
            } else if (res.type === 'media-source') {
              logger.debug('MEDIA-DEBUG::Audio::Local Level:', (res.audioLevel * 1000).toFixed(2), 'Energy:',
                (res.totalAudioEnergy * 1000).toFixed(2));
              self.localAudioLevel = res.audioLevel;
            } else if (res.type === 'remote-inbound-rtp') {
              // TODO
            } else if (res.type === 'remote-outbound-rtp') {
              // TODO
            } else {
              // logger.info('MEDIA:: Audio => Id:', res.id, 'Type:', res.type);
            }
          } else if (res.kind) {
            // logger.info('MEDIA:: Id:', res.id, 'Kind:', res.kind, 'Type:', res.type);
          }
          /*
          logger.log('MEDIA-DEBUG::Resource => Id', res.id, 'Type:', res.type, 'Kind:',
            res.kind);
          logger.log('MEDIA-DEBUG::Resource Details => Id', res.id, JSON.stringify(res));
          */
        });
      });
  }

  /* Perform call hangup with MediaServer */
  doHangup = () => {
    if ((this.callFSM.state !== MEDIA_SERVER_CALL_STATE.CALL_DISCONNECTING) ||
      (this.callFSM.state !== MEDIA_SERVER_CALL_STATE.CALL_HANGING) ||
      (this.callFSM.state !== MEDIA_SERVER_CALL_STATE.IDLE)) {
      this.appCallback(WEB_CALL_EVENT.HANGUP, true);
      this.sipCallManager.Call.hangup();
    } else {
      this.call.logger.warn(`[${this.callFSM.context}]: Ignoring action for HANGUP in state: ${this.callFSM.state}`);
    }
  };

  /* Cleans the call state after hangup */
  postHangupCleanup = () => {
    this.call.logger.debug(`postHangupCleanup for ${this.callData.callId}`);
    if (this.callData.callId === null) return;
    this.callFSM.setContext(null);

    this.sipCallManager.RTCPeer.clearPeer();

    /* Clear stale timers */
    if (this.bitrateTimer) {
      clearInterval(this.bitrateTimer);
    }
    this.bitrateTimer = null;
    if (this.mediaPathGuardTimer) {
      clearTimeout(this.mediaPathGuardTimer);
    }
    this.mediaPathGuardTimer = null;
    if (this.datastreamHangupTimer) {
      clearTimeout(this.datastreamHangupTimer);
    }
    this.datastreamHangupTimer = null;

    if (this.videoResTimer) {
      clearInterval(this.videoResTimer);
    }
    this.videoResTimer = null;

    this.media.CallFunctionState.clearState();
    this.canvasForScaleUp = null;

    this.freeLocalStream();

    this.setTorchStateForVideo(false);
  };

  datastreamHangupMessageReceived = (isAck) => {
    if (!isAck) {
      // this is a BYE message, send an ACK
      this.appCallback(WEB_CALL_EVENT.DATA_STREAM_HANGUP_MSG_RECV);
    }

    // hangup call now if this as an ACK, or we have an outstanding BYE
    if (isAck || this.datastreamHangupTimer) {
      if (this.datastreamHangupTimer) {
        clearTimeout(this.datastreamHangupTimer);
      }
      this.datastreamHangupTimer = null;
      this.callFSM.process(this.call.FSM_EVENT.CALL_DISCONNECTED);
    }
  }

  handleMediaPathError = () => {
    if (this.mediaPathGuardTimer) return;
    const self = this;

    this.mediaPathGuardTimer = setTimeout(() => {
      logger.warn('MEDIAPATH:: Guard timer expired. Disconnecting call');
      // setTimeout(() => {
      self.callFSM.process(self.call.FSM_EVENT.MEDIA_PATH_DISCONNECTED);
      // }, TIMER_CONSTANTS.EVENT_LOOP_BREAKER);
      // TODO Try iceRestart
    }, TIMER_CONSTANTS.MEDIA_PATH_GUARD_TIMEOUT);
  }

  /**
   * Converts from internal state to the web call state for application
   * @returns WEB_CALL_STATE
   */
  webCallState = () => {
    let callState;
    if (this.sessionFSM.state === MEDIA_SERVER_FSM_STATE.READY) {
      /* If session in active state, notify session state */
      callState = this.callFSM.state;
    } else {
      /* If session in not active/ready, use session state to notify errors */
      switch (this.sessionFSM.state) {
        case MEDIA_SERVER_FSM_STATE.NOT_READY:
        case MEDIA_SERVER_FSM_STATE.INITIALIZING:
        case MEDIA_SERVER_FSM_STATE.CONNECTING:
        case MEDIA_SERVER_FSM_STATE.ATTACHING:
        case MEDIA_SERVER_FSM_STATE.REGISTERING:
          callState = WEB_CALL_STATE.INITIALIZING;
          break;

        default:
          callState = this.sessionFSM.state;
          break;
      }
    }
    return callState;
  }

  /**
   * WebApp API
   */
  isSysReady = () => this.sessionFSM.state === MEDIA_SERVER_FSM_STATE.READY;

  /**
   * WebApp API
   * Used by application to get the current active call id.
   */
  getCallId = () => this.callData.callId;

  getCallData = () => ({
    callId: this.callData.callId,
    calleeUserName: this.callData.calleeUserName,
    callerUserName: this.callData.callerUserName,
    isIncoming: this.callData.isIncoming,
    displayName: this.regData.display_name,
    callAborted: this.callData.callAborted,
  });

  /**
   * Returns the video device thats currently active with WebRTC layer
   * @returns { id} Returns current video source id. In the case of no video
   * source available can return null
   */
  getCurrentVideoSourceId = () => {
    let videoDeviceId = null;
    let device = null;
    if (this.callData.videoDeviceId === SCREEN_SHARE_DEVICE.deviceId) {
      /* Screen share is on */
      device = SCREEN_SHARE_DEVICE;
      videoDeviceId = device.deviceId;
    } else {
      // eslint-disable-next-line max-len
      videoDeviceId = this.sipCallManager.RTCPeer.getRtcPeerStream()?.getVideoTracks()[0]?.getSettings()?.deviceId;
    }
    // Can be null, if no video source
    return { device, videoDeviceId };
  }

  /**
   * Returns the audio device thats currently active with WebRTC layer
   * @returns { id} Returns current audio source id active if any.
   */
  getCurrentAudioSourceId = () => {
    const audioTrack = WebrtcUtils.getAudioTrack(this.sipCallManager.RTCPeer.getRtcPeerStream());
    if (audioTrack) {
      return {
        deviceId: audioTrack.getSettings()?.deviceId,
        label: audioTrack.label,
      };
    }
    return { deviceId: null, label: null };
  }

  /**
   * Returns the audio device thats currently active with WebRTC layer
   * @returns { id} Returns current audio source id active if any.
   */
  getCurrentAudioSinkId = () => {
    const remoteAudio = document.getElementById(CALL_DASHBOARD.REMOTE_AUDIO_ID);
    if (remoteAudio) return remoteAudio.sinkId;
    return null;
  }

  setVideoConfigs = (videoConfig, isScreenShare, isUserSharing) => {
    this.media.MediaConfig.setVideoConfigs(videoConfig, isScreenShare, isUserSharing);
  }

  updateCanvas = (videoConfig) => {
    this.media.Canvas.updateCanvas(videoConfig);
  }

  /* WebApp API */
  isCallInProgress = () => ((this.sessionFSM.state === MEDIA_SERVER_FSM_STATE.READY) &&
    (this.callFSM?.state !== MEDIA_SERVER_CALL_STATE.CALL_IDLE) &&
    (this.callFSM?.state !== MEDIA_SERVER_CALL_STATE.CALL_DISCONNECTING) &&
    (this.callFSM?.state !== MEDIA_SERVER_CALL_STATE.CALL_DISCONNECTED));

  /* WebApp API */
  isMediaPathUp = () => (this.callData.mediaPathState === MEDIA_PATH_STATE.UP);

  /* Checks the conditions underwhich the call is deemed to be in good state;
  Failure of any of this condition mean, call is experiencing issues */
  /* WebApp API */
  isCallStatusOk = () => (this.sessionFSM.state === MEDIA_SERVER_FSM_STATE.READY &&
    this.callFSM.state === MEDIA_SERVER_CALL_STATE.CALL_IN_PROGRESS &&
    this.callData.mediaPathState === MEDIA_PATH_STATE.UP);

  /* WebApp API */
  isNetReconnecting = () => ((!this.initialSession &&
    (this.sessionFSM.state !== MEDIA_SERVER_FSM_STATE.READY)) ||
    (this.isCallInProgress() && (this.callData.mediaPathState === MEDIA_PATH_STATE.DOWN)));

  getGeoLocationData = () => this.media.Location.getGeoLocationData();
}

const CallMgr = CallManager.getCallManager();

export default CallMgr;
