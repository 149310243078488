import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// Constant
import { ROUTES } from 'UTILS/constants/RoutingPathConstants';

// eslint-disable-next-line react/prop-types
function PrivateRoute({ component: PrivateComponent, ...restProps }) {
  const isAuthenticated = () => !!restProps.authToken;
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...restProps}
      render={(props) =>
        isAuthenticated() ? (
          <PrivateComponent {...props} {...restProps} />
        ) : (
          <Redirect to={ROUTES.LOGIN} />
        )
      }
    />
  );
}

const mapStateToProps = (state) => ({
  authToken: state.AuthReducer.authToken,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
