import _ from 'lodash';
import {
  SUPPORTED_CODECS,
  MEDIA_TYPE,
  TRANSCEIVER_TYPE,
  MEDIA_DEVICE_TYPE,
  EXCLUDED_DEVICE_IDS,
  EXCLUDED_VIDEO_SOURCES,
  MAX_CAMERA_RES,
} from 'UTILS/constants/MediaServerConstants';

// Utility
import Utils from 'UTILS/CommonUtility';

const DEFAULT_DEVICE_ID = 'default';

export default class WebrtcUtils {
  // This will remove an extra H264 codec from the SDP if not supported by platform
  static getH264CodecIdsToRemove(sdp) {
    const h264Occurences = [];
    const codecIdsToRemove = [];
    let requiredCodecId = '';
    const splittedSDP = sdp.split('\n');
    splittedSDP.forEach((row) => {
      if (row.includes('H264/90000')) {
        h264Occurences.push(row);
      }
      if (
        row.includes(
          'profile-level-id=42e01f;level-asymmetry-allowed=1;packetization-mode=1',
        )
      ) {
        requiredCodecId = row.substring(
          row.indexOf(':') + 1,
          row.indexOf(' '),
        );
      }
    });
    h264Occurences.forEach((row) => {
      const codecId = row.substring(
        parseInt(row.indexOf(':'), 10) + 1,
        row.indexOf(' '),
      );
      if (codecId !== requiredCodecId) {
        codecIdsToRemove.push(codecId);
      }
    });
    console.debug(
      'MediaHandler::codec to remove::',
      h264Occurences,
      requiredCodecId,
      codecIdsToRemove,
    );
    return codecIdsToRemove;
  }

  static removeNonSupportedCodecs(sdp, isVideoCall) {
    let videoCodecsKept = [];
    const removed = [];
    const duplicateRTXids = [];
    const codecsToRemove = this.getH264CodecIdsToRemove(sdp);
    console.debug(
      'MediaHandler::removeNonSupportedCodecs: isVideoCall',
      isVideoCall,
    );
    const sdpParts = sdp.split('m=video');
    let videoPart = sdpParts[1];
    videoPart = 'm=video' + videoPart;
    const modifiedSdp = videoPart
      .split('\n')
    // remove codecs not in ['PCMA/8000', 'telephone-event/8000']
      .filter((row) => {
        const m = row.match(/^a=rtpmap:(\d+)\s(([\w-]+)(\/\d+)+)/);
        if (m) {
          const currentCodec = m[2].toLowerCase();
          // keep PCMA/8000 and telephone-event/8000
          if (
            isVideoCall &&
                    SUPPORTED_CODECS.VIDEO.includes(currentCodec)
          ) {
            videoCodecsKept.push(m[1]);
          } else {
            removed.push(m[1]);
            return false;
          }
        }
        return true;
      })
    // remove rtcp-fb, fmtp
      .filter((row) => {
        const m = row.match(/^a=(?:rtcp-fb|fmtp):(\d+)\s.+/);
        if (m) {
          if (removed.includes(m[1])) {
            return false;
          }
        }
        return true;
      })
      .filter((row) => {
        if (
          this.checkIfCodecExist(row, codecsToRemove) &&
                !row.includes('rtx')
        ) {
          return false;
        }
        if (
          this.checkIfCodecExist(row, codecsToRemove) &&
                row.includes('rtx')
        ) {
          duplicateRTXids.push(
            row.substring(row.indexOf(':') + 1, row.indexOf(' ')),
          );
        }
        return true;
      })
    // update audio codecs
      .map((row) => {
        const videoCodecMatch = row.match(
          /^(m=video \d+ [A-Z/]+)(?: \d+)+/,
        );
        if (videoCodecMatch) {
          console.debug(
            'MediaHandler:: removeNonSupportedCodecs::codec to remove::',
            codecsToRemove,
            videoCodecsKept,
          );
          videoCodecsKept = _.filter(
            videoCodecsKept,
            (c) => _.indexOf(codecsToRemove, c) === -1,
          );
          console.debug(
            'MediaHandler::codecs kept::',
            videoCodecsKept,
          );
          const codecPayloadInMLine = `${videoCodecMatch[1]
          } ${videoCodecsKept.join(' ')}`;
          return duplicateRTXids.length > 0
            ? `${codecPayloadInMLine} ${duplicateRTXids.join(' ')}`
            : codecPayloadInMLine;
        }
        return row;
      })
      .join('\n');
    // eslint-disable-next-line prefer-destructuring
    sdpParts[1] = modifiedSdp.split('m=video')[1];
    return sdpParts.join('m=video');
  }

  static checkIfCodecExist(row, codecIds) {
    // eslint-disable-next-line no-restricted-syntax
    for (const codecId of codecIds) {
      if (
        row.includes(`a=rtpmap:${codecId}`) ||
                row.includes(`a=fmtp:${codecId}`) ||
                row.includes(`a=rtcp-fb:${codecId}`)
      ) {
        return true;
      }
    }
    return false;
  }

  static getCompositeStream(audioStream, videoStream) {
    const combinedStream = new MediaStream();
    combinedStream.addTrack(audioStream.getAudioTracks()[0]);
    combinedStream.addTrack(videoStream.getVideoTracks()[0]);
    return combinedStream;
  }

  static limitPixelSizeToBeDrawnToCanvas(size, maximumPixels) {
    const { width, height } = size;

    const requiredPixels = width * height;
    if (requiredPixels <= maximumPixels) return { width, height };

    const scalar = Math.sqrt(maximumPixels) / Math.sqrt(requiredPixels);
    return {
      width: Math.floor(width * scalar),
      height: Math.floor(height * scalar),
    };
  }

  // Note: Following we need to perform to resolve 1527 issue
  // clearRect with canvas width and height (captured image's width height)
  // then setting canvas width and height to 1, and called clearRect
  // Based on this post https://pqina.nl/blog/total-canvas-memory-use-exceeds-the-maximum-limit/
  static releaseCanvas(canvas) {
    const ctx = canvas.getContext('2d');
    if (ctx) ctx.clearRect(0, 0, canvas.width, canvas.height);
    canvas.width = 1;
    canvas.height = 1;
    if (ctx) ctx.clearRect(0, 0, 1, 1);
    canvas = null;
  }

  static drawTextOverlayOnCanvas(context, text, x, y) {
    if (!context) {
      return;
    }
    context.strokeText(text, x, y);
    context.fillText(text, x, y);
  }

  /**
    * @param {*} stream
    * @param {*} track
    * @param {*} mediaType
    */
  static removeTrackFromStream(stream, track, mediaType = null) {
    // @ayan - need to check this issue
    // FIXME
    let mismatchHack = false;
    console.info('MediaHandler::removeTrackFromStream::',
      `Removing track: ${Utils.printableId(track?.id)} from stream:${Utils.printableId(stream?.id)} for ${mediaType}`);
    console.debug('MediaHandler::removeTrackFromStream::Existing tracks',
      stream?.getTracks());
    if (track) {
      // First remove track
      if (track.kind === MEDIA_TYPE.VIDEO) {
        if (Utils.hasVideo(stream) && (stream.getVideoTracks()[0]?.id !== track.id)) {
          console.warn('MediaHandler::Mismatch in video track id while removal',
            'Stream tracks', JSON.stringify(stream.getVideoTracks()),
            'Track being removed', track.id, 'Canvas Track ? -', track.canvas ? 'true' : 'false');
          mismatchHack = true;
        }
      } else if (stream.getAudioTracks()[0].id !== track.id) {
        console.warn('MediaHandler::Mismatch in audio track id while removal',
          'Stram tracks', stream.getAudioTracks(),
          'Track being replaced', track.id);
      }
      stream?.removeTrack(track);
      if (mismatchHack) {
        console.debug('MediaHandler::Stream tracks after removal attempt',
          stream.getTracks());
      }
      // Then stop
      track?.stop();
      console.debug(`MediaHandler::Track from ${track.label} stopped`);
      if (mediaType === null) mediaType = track.kind;
    }
    // TODO: Add comment for this logic
    let mediaTracks = [];
    if (mediaType !== null) {
      mediaTracks = mediaType === MEDIA_TYPE.VIDEO ?
        stream.getVideoTracks() :
        stream.getAudioTracks();
    } else {
      mediaTracks = stream.getTracks();
    }
    /* FIXME : Purpose of this is not clear */
    mediaTracks.forEach((mtrack) => {
      mtrack.stop();
      // Found an issue where the track id from stream does not match with the given track
      // Is this ok?
      if (mismatchHack) {
        stream?.removeTrack(mtrack);
        console.info('MediaHandler::Removed track despite mismatched id');
      }
    });
    console.debug(`MediaHandler::Media tracks for ${mediaType ?? 'all'} stopped`,
      `for stream: ${Utils.printableStreamId(stream?.id)}`);
    console.debug('MediaHandler::removeTrackFromStream::Now tracks',
      stream?.getTracks());
  }

  /**
    * @param {*} stream
    * @param {*} track
    * @param {*} mediaType
    */
  static removeTracksByType(stream, mediaType = null) {
    console.debug('MediaHandler::removeTrackFromStream::',
      `For ${mediaType}`);
    console.debug('MediaHandler::removeTrackFromStream::Existing tracks',
      stream?.getTracks());
    // TODO: Add comment for this logic
    let mediaTracks = [];
    if (mediaType !== null) {
      mediaTracks = mediaType === MEDIA_TYPE.VIDEO ?
        stream.getVideoTracks() :
        stream.getAudioTracks();
    } else {
      mediaTracks = stream.getTracks();
    }
    mediaTracks.forEach((mtrack) => {
      mtrack.stop();
      stream?.removeTrack(mtrack);
    });
    console.debug(`MediaHandler::Media tracks for ${mediaType ?? 'all'} stopped`,
      `for stream: ${Utils.printableStreamId(stream?.id)}`);
    console.debug('MediaHandler::removeTrackFromStream::Now tracks',
      stream?.getTracks());
  }

  /**
     * Convenience function - replace track in provided rtpSender
     * @param {*} rtpSender
     * @param {*} track
     * @returns none
     */
  static async replaceTrack(rtpSender, track) {
    try {
      await rtpSender?.replaceTrack(track);
      console.debug('MediaHandler:replaceTrack::Track replaced in RTP sender with',
        track.kind, 'track from device:', track.label);
      return true;
    } catch (error) {
      console.warn('MediaHandler::Error replacing track', error);
      return false;
    }
  }

  /**
     * Convenience function - wraps the getUserMedia API
     * @param {*} deviceConfig
     * @returns in async/await form returns the stream else error
     * @throw error while getting user media with the constraints
     */
  static async getUserMedia(mediaConfig, screenShareEndedCB, captureInHighResFirst = true) {
    let stream = null;
    let constraint = null;
    const { isVideo, isAudio, isAudioOutput, isDisplay,
      videoDeviceId, audioDeviceId, videoConfig } = mediaConfig;
    console.debug('WebRtcUtils::getUserMedia() mediaconfig:', JSON.stringify(mediaConfig));
    const videoCfg = videoConfig ?? (videoDeviceId ?
      { deviceId: { exact: videoDeviceId } } : undefined);
    try {
      if (!isDisplay) {
        constraint = {
          video: isVideo ? videoCfg : undefined,
          audio: isAudio ? {
            deviceId: isAudioOutput ?
              { ideal: audioDeviceId } : { exact: audioDeviceId },
          } : undefined,
        };
        console.debug('WebRtcUtils::getUserMedia constraints:', constraint);
        if (!captureInHighResFirst) {
          stream = await navigator.mediaDevices.getUserMedia(constraint);
          return stream;
        }

        // Capturing in highest res first and then scaling down to match actual mediaconfig,
        // require for the chrome bug https://bugs.chromium.org/p/chromium/issues/detail?id=768205
        let height = MAX_CAMERA_RES.HEIGHT;
        let width = MAX_CAMERA_RES.WIDTH;
        let aspectRatio = width / height;
        if (window.orientation === 0) {
          width = MAX_CAMERA_RES.HEIGHT;
          height = MAX_CAMERA_RES.WIDTH;
          aspectRatio = width / height;
        }
        stream = await navigator.mediaDevices.getUserMedia({
          video: isVideo ? {
            width,
            height,
            aspectRatio,
            facingMode: videoCfg?.facingMode,
            deviceId: { exact: videoConfig ? videoConfig.deviceId : videoDeviceId },
            frameRate: 30,
          } : undefined,
          audio: isAudio ? {
            deviceId: isAudioOutput ?
              { ideal: audioDeviceId } : { exact: audioDeviceId },
          } : undefined,
        });
        if (isVideo) {
          width = videoCfg?.width;
          height = videoCfg?.height;
          if (window.orientation === 0 && videoCfg?.width > videoCfg?.height) {
            width = videoCfg?.height;
            height = videoCfg?.width;
          }
          // eslint-disable-next-line no-unsafe-optional-chaining
          aspectRatio = width?.ideal / height?.ideal;
          if (typeof aspectRatio !== 'number' || !Number.isFinite(aspectRatio)) {
            aspectRatio = undefined;
          }
          await stream.getVideoTracks()[0]?.applyConstraints({
            width,
            height,
            aspectRatio,
            frameRate: videoCfg?.frameRate,
          });
        }
      } else {
        stream = await navigator.mediaDevices.getDisplayMedia();
        stream.getVideoTracks()[0].onended = () => {
          screenShareEndedCB();
        };
      }
    } catch (err) {
      console.warn(
        'MediaHandler::Error in getUserMedia: for constraint:',
        constraint,
        'Error:',
        err.message,
        err.name,
        err,
      );
      throw err;
    }
    return stream;
  }

  static setTrackContentHint(track, hint) {
    if ('contentHint' in track) {
      track.contentHint = hint;
      if (track.contentHint !== hint) {
        console.error(
          `MediaHandler::setTrackContentHint invalid ${track.content} track contentHint`,
          hint,
          track.contentHint,
        );
      } else {
        console.debug(
          `MediaHandler::setTrackContentHint ${track.kind} track contentHint set as:`,
          hint,
        );
      }
    } else {
      console.warn(
        'MediaHandler::setTrackContentHint contentHint attribute not supported',
      );
    }
  }

  /**
   * Convenience function to get track of a certain media type from stream
   * @param {*} stream
   * @param {*} mediaType type of media
   */
  static getMediaTrack = (stream, mediaType) =>
    ((mediaType === MEDIA_TYPE.VIDEO) ||
      (mediaType === MEDIA_TYPE.SCREEN))
      ? stream?.getVideoTracks()[0]
      : stream?.getAudioTracks()[0];

  static getAudioStream = async () => new Promise((resolve, reject) => {
    if (!navigator || !navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      reject(new Error('MediaHandler::getAudioStream() Gum is not suported, failed to capture audio'));
    }
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      console.debug(`MediaHandler::getAudioStream() stream:${JSON.stringify(stream)}`);
      resolve(stream);
    }).catch((err) => {
      console.error('MediaHandler::getAudioStream() error in capturing audio stream:', err);
      reject(err);
    });
  });

  static getTransceiver = (pc, mediaType, direction) => {
    const transceivers = pc?.getTransceivers();
    if (transceivers && transceivers.length > 0) {
      if (direction === TRANSCEIVER_TYPE.SENDER) {
        return transceivers.find((t) =>
          t.sender && t.sender.track && t.sender.track.kind === mediaType);
      } if (direction === TRANSCEIVER_TYPE.RECEIVER) {
        return transceivers.find((t) =>
          t.receiver && t.receiver.track && t.receiver.track.kind === mediaType);
      }
    }
    return null;
  }

  static getStreamOfPC = (pc) => {
    const audioTransceiver = WebrtcUtils.getTransceiver(pc,
      MEDIA_TYPE.AUDIO, TRANSCEIVER_TYPE.SENDER);
    const videoTransceiver = WebrtcUtils.getTransceiver(pc,
      MEDIA_TYPE.VIDEO, TRANSCEIVER_TYPE.SENDER);
    const mediaStream = new MediaStream();
    if (audioTransceiver && audioTransceiver.sender && audioTransceiver.sender.track) {
      mediaStream.addTrack(audioTransceiver.sender.track);
    }
    if (videoTransceiver && videoTransceiver.sender && videoTransceiver.sender.track) {
      mediaStream.addTrack(videoTransceiver.sender.track);
    }
    return mediaStream;
  }

  static processListOfDevices = (devices) => {
    console.debug(
      'ProcessListOfDevices::',
      JSON.stringify(devices),
    );
    let audioInputDevices = [];
    let audioOutputDevices = [];
    let videoInputDevices = [];
    let filteredDevices = [];
    let frontCameraCount = 0;
    let backCameraCount = 0;
    const defaults = {};
    // Intializes object for storing defaults
    Object.keys(MEDIA_DEVICE_TYPE).forEach((key) => {
      defaults[MEDIA_DEVICE_TYPE[key]] = null;
    });
    filteredDevices = devices.filter((device) => {
      let includeDevice = true;
      if (device.deviceId === DEFAULT_DEVICE_ID) {
        defaults[device.kind] = device;
      }
      if (EXCLUDED_DEVICE_IDS.includes(device.deviceId)
        || EXCLUDED_VIDEO_SOURCES.includes(device.label)) { includeDevice = false; }
      return includeDevice;
    });
    filteredDevices.forEach((device) => {
      console.debug(`Device: ${device.deviceId} Kind: ${device.kind}`);
      const deviceToAdd = {};
      deviceToAdd.deviceId = device.deviceId;
      deviceToAdd.groupId = device.groupId;
      // Mark the default device for application use
      deviceToAdd.isDefault = defaults[device.kind]?.label?.includes(device.label);
      if (device.kind === MEDIA_DEVICE_TYPE.VIDEO_INPUT) {
        if (device.label.includes('facing front')) {
          deviceToAdd.label = 'Front Camera';
          if (frontCameraCount !== 0) {
            deviceToAdd.label += `${frontCameraCount}`;
          }
          frontCameraCount += 1;
        } else if (device.label.includes('facing back')) {
          deviceToAdd.label = 'Back Camera';
          if (backCameraCount !== 0) {
            deviceToAdd.label += `${backCameraCount}`;
          }
          backCameraCount += 1;
        } else {
          deviceToAdd.label = device.label;
        }
      } else {
        deviceToAdd.label = device.label;
      }
      switch (device.kind) {
        case MEDIA_DEVICE_TYPE.AUDIO_INPUT:
          audioInputDevices.push(deviceToAdd);
          break;
        case MEDIA_DEVICE_TYPE.AUDIO_OUTPUT:
          audioOutputDevices.push(deviceToAdd);
          break;
        case MEDIA_DEVICE_TYPE.VIDEO_INPUT:
          if (deviceToAdd.label.includes('facing front')) {
            deviceToAdd.label = 'Front Camera';
          } else if (deviceToAdd.label.includes('facing back')) {
            deviceToAdd.label = 'Back Camera';
          }
          videoInputDevices.push(deviceToAdd);
          break;
        default:
          break;
      }
    });

    // To fix iOS audio issue : Add back camera to the top in the list
    // This is to avoid  executing 'changeDevice' once the call starts
    const backCamera = videoInputDevices.find(
      (videoDevice) => videoDevice.label === 'Back Camera',
    );
    const fileteredVideoInputs = videoInputDevices.filter(
      (videoDevice) => videoDevice.label !== 'Back Camera',
    );
    if (backCamera) {
      fileteredVideoInputs.unshift(backCamera);
      videoInputDevices = fileteredVideoInputs;
    }

    if (!Utils.isIOS()) {
      const groupedVideoDevices = _.uniqBy(videoInputDevices, 'groupId');
      const groupedAudioDevicesIn = _.uniqBy(audioInputDevices, 'groupId');
      const groupedAudioDevicesOut = _.uniqBy(audioOutputDevices, 'groupId');

      videoInputDevices = groupedVideoDevices;
      audioInputDevices = groupedAudioDevicesIn;
      audioOutputDevices = groupedAudioDevicesOut;
    }
    console.debug(
      'ProcessListOfDevices::updated list of video devices',
      JSON.stringify(videoInputDevices),
    );
    return { audioInputDevices, audioOutputDevices, videoInputDevices };
  };

  static getVideoTrack = (stream) => stream?.getVideoTracks()[0];

  static getAudioTrack = (stream) => stream?.getAudioTracks()[0];

  static hasVideo = (stream) => stream?.getVideoTracks().length ?? 0;

  static hasAudio = (stream) => stream?.getAudioTracks().length ?? 0;

  static hasTracks = (stream) => stream?.getTracks().length ?? 0;
}
