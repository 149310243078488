/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// import Switch from 'react-switch';
import { isMobileOnly, isDesktop } from 'react-device-detect';

// Custom component
import TextFieldComponent from 'COMPONENTS/CustomComponents/TextFieldComponent/TextFieldComponent';
import CustomForm from 'COMPONENTS/CustomComponents/CustomForm/CustomForm';
import CustomButton from 'COMPONENTS/CustomComponents/CustomButton/CustomButton';

// Utility
import { translate } from 'SERVICES/i18n';
import { LOGIN } from 'UTILS/constants/DOMElementConstants';

// Asset
import { ReactComponent as ProfileNameIcon } from 'ASSETS/icon_username.svg';
import { ReactComponent as PasswordIcon } from 'ASSETS/icon_password.svg';
import { ReactComponent as ShowPasswordIcon } from 'ASSETS/icon_show_password_on.svg';
import { ReactComponent as HidePasswordIcon } from 'ASSETS/icon_show_password_off.svg';

// Constant
import {
  FORGOT_PASSWORD_LINK, WEB_SERVICE,
  LOGIN_FORM_FIELDS,
  LOGIN_OPTIONS,
} from 'UTILS/constants/LoginConstants';
import CommonUtility from 'UTILS/CommonUtility';
import { BUTTON_TYPES } from 'UTILS/constants/UtilityConstants';
import GuestLogin from './GuestLogin';
import SSOLogin from './SSOLogin';

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.emailRef = React.createRef(null);
    this.passwordRef = React.createRef(null);

    this.state = {
      email: '',
      password: '',
      // forgetPassword: true,
      isPasswordVisible: true,
      isLoginDisabled: true,
      validated: false,
      errors: {},
      renderGuestLogin: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      if (this.props.cpUsername && this.props.cpPassword
         && !this.state.email && !this.state.password) {
        this.setState({ email: this.props.cpUsername, password: this.props.cpPassword },
          () => {
            this.checkSignInDisability();
          });
      }
    }
  }

  handleChange = (event, type) => {
    switch (type) {
      case LOGIN_FORM_FIELDS.EMAIL:
        this.setState(
          {
            email: event.target.value,
          },
          () => {
            // eslint-disable-next-line no-unused-expressions
            !this.state.email &&
              this.setState({
                password: '',
              });
            this.resetError();
            this.checkSignInDisability();
          },
        );
        break;
      case LOGIN_FORM_FIELDS.PASSWORD:
        this.setState(
          {
            password: event.target.value.trim(),
          },
          () => {
            this.resetError();
            this.checkSignInDisability();
          },
        );
        break;
      default:
        break;
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setValidated(true);
    const { email, password } = this.state;

    if (this.validateInputs() && this.props.signInOption === LOGIN_OPTIONS.USERNAME) {
      this.props.handleLoginClick(email, password);
    }
  };

  setValidated = (value) => {
    this.setState({ validated: value });
  };

  // Removed temporarily
  // handleRememberPasswordChange = (checked) => {
  //   this.setState({ forgetPassword: checked });
  // };

  resetError = () => {
    this.setState({ errors: {} });
    this.setValidated(false);
  };

  // This function is used for local validations
  validateInputs = () => {
    let isValid = true;

    // Validate only username (check for '@' only)
    if (this.props.signInOption === LOGIN_OPTIONS.USERNAME) {
      if (!CommonUtility.isValidUsername(this.state.email)) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            userName: translate('errors.invalidUserNameOrPassword'),
          },
        }));
        this.props.setError(
          translate('errors.invalidUserNameOrPassword'),
          true,
          'login.invalidUsernameOrPasswordModalHeader',
        );
        isValid = false;
      }

      if (!this.emailRef.current.checkValidity()) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            userName: translate('errors.invalidUserNameOrPassword'),
          },
        }));
        this.props.setError(
          translate('errors.invalidUserNameOrPassword'),
          true,
          'login.invalidUsernameOrPasswordModalHeader',
        );
        isValid = false;
      }
    }
    return isValid;
  };

  checkSignInDisability = () => {
    if (this.state.password.length === 0 || this.state.email.length === 0) {
      this.setState({ isLoginDisabled: true });
    } else {
      this.setState({ isLoginDisabled: false });
    }
  };

  /**
   * This method is used to toggle password on eye button
   */
  enablePassword = () => {
    // eslint-disable-next-line no-unused-expressions
    if (this.state.password.length > 0) {
      this.setState({
        isPasswordVisible: false,
      });
    }
  };

  disablePassword = () => {
    // eslint-disable-next-line no-unused-expressions
    if (this.state.password.length > 0) {
      this.setState({
        isPasswordVisible: true,
      });
    }
  };

  checkForEnterKeyPress = (e) => {
    if (e.key === 'Enter' && e.target.value !== '') {
      if (this.state.isPasswordVisible) {
        this.enablePassword();
      } else {
        this.disablePassword();
      }
    }
  }

  loginView = (fpLink = '') => (
    <Row>
      <Col className='align-center p-0'>
        <CustomForm
          formId={LOGIN.FORM_ID}
          onSubmit={this.handleSubmit}
          validated={this.state.validated}
        >
          <Row className='px-4 py-3'>
            {this.props.signInOption === LOGIN_OPTIONS.USERNAME && (
              <>
                <TextFieldComponent
                  type='text'
                  id={LOGIN.EMAIL_ID}
                  value={this.state.email}
                  placeholder={translate('login.enterEmail')}
                  maxLength={100}
                  leftIcon={ProfileNameIcon}
                  inputRef={this.emailRef}
                  handleChange={(event) => this.handleChange(event, 'email')}
                  className={`${isMobileOnly ? 'text-mob' : 'text-desktop'}`}
                />
                <Row className='m-2' />
                <TextFieldComponent
                  id={LOGIN.PASSWORD_ID}
                  type={this.state.isPasswordVisible ? 'password' : 'text'}
                  value={this.state.password}
                  placeholder={translate('login.enterPassword')}
                  maxLength={128}
                  inputRef={this.passwordRef}
                  leftIcon={PasswordIcon}
                  rightIcon={this.state.isPasswordVisible
                    ? HidePasswordIcon
                    : ShowPasswordIcon}
                  checkForEnterKeyPress={this.checkForEnterKeyPress}
                  onPointerDown={this.enablePassword}
                  onPointerUp={this.disablePassword}
                  onPointerLeave={this.disablePassword}
                  handleChange={(event) => this.handleChange(event, 'password')}
                  disabled={!this.state.email}
                  className={`${isMobileOnly ? 'text-mob' : 'text-desktop'}`}
                />

                <Col className='forget-password pt-2 pb-1'>
                  <a
                    href={fpLink}
                    target='_blank'
                    className='forgot-text'
                    rel='noopener noreferrer external'
                  >
                    {translate('login.forgotPassword')}
                  </a>
                </Col>
                <Row className='pb-3 mt-1 pr-0'>
                  {/* #1329 - Remember password removed temporarily
                <Row className='pb-3 mt-3 pr-0'>
                <Col
                lg={1}
                md={1}
                sm={1}
                xs={1}
                className='remember-password'
                >
                <span className='pt-1' id='remember_switch'>
                <Switch
                  onChange={this.handleRememberPasswordChange}
                  checked={this.state.forgetPassword}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  handleDiameter={18}
                  onColor='#00A3E0'
                  onHandleColor='#ffffff'
                  className={
                    this.state.forgetPassword
                      ? 'checked-button'
                      : 'unchecked-button'
                  }
                  height={12}
                  width={25}
                />
                </span>
                </Col>
                <Col
                md={11}
                lg={11}
                sm={11}
                xs={11}
                className='remember-password pr-0'
                >
                <span className='padding-left-rem' id='remember_password'>
                {translate('login.rememberPassword')}
                </span>
                </Col>
                </Row>
                */}
                </Row>
                <Col className={`${isMobileOnly ? 'login-btn-mob' : 'login-btn-desktop'}`} md={12} sm={12}>
                  <CustomButton
                    className='w-100 rounded-0'
                    type='submit'
                    text='login.loginButton'
                    disabled={this.state.isLoginDisabled}
                  />
                </Col>
              </>
            )
            }

            {/* Guest login to join a meeting as a guest */}
            {((window.dynamicEnv.REACT_APP_ALLOW_MEETINGS === 'true') &&
              this.props.signInOption !== LOGIN_OPTIONS.SSO &&
              (this.props?.meetingId)) && (
                <Col className={`${isMobileOnly ? 'login-btn-mob mt-3' : 'login-btn-desktop mt-3'}`} md={12} sm={12}>
                  <CustomButton
                    className='w-100 rounded-0'
                    text='login.guestLoginButton'
                    disabled={false}
                    variant={BUTTON_TYPES.SECONDARY}
                    onClick={() => this.setState({ renderGuestLogin: true })}
                  />
                </Col>
            )}
            {this.props.signInOption === LOGIN_OPTIONS.USERNAME && (
              <Col className='pt-3 pb-1'>
                <span
                  className={`sign-in-options ${(window.dynamicEnv.REACT_APP_ALLOW_SSO === 'false') ? 'disabled-state' : ''}`}
                  role='button'
                  tabIndex={0}
                  onClick={() => this.props.toggleSignInOptions(LOGIN_OPTIONS.SSO)}
                  onKeyDown={() => this.props.toggleSignInOptions(LOGIN_OPTIONS.SSO)}
                >
                  {translate('ssoLogin.signinWithSSO')}
                </span>
              </Col>
            )}
          </Row>
        </CustomForm>
      </Col>
    </Row>
  );

  render() {
    const fpBaseLink = `${window.dynamicEnv.REACT_APP_SERVER_BASE_URL}${WEB_SERVICE}${FORGOT_PASSWORD_LINK}`;
    const fpLink = this.state.email ? `${fpBaseLink}&username=${this.state.email}` : `${fpBaseLink}`;
    return (
      <Container
        fluid
        className={
          'login-container-layout ' +
          (isMobileOnly ? ' mt-5' : ' justify-content-center')
        }
      >
        <Col className='col-xxl-3' xl={isDesktop ? 3 : 4} lg={5} md={6} sm={6} xs={10}>
          <Row className='login-container'>
            <Col>
              <Row className='p-0'>
                <Col className='align-center py-1'>
                  <img
                    src={this.props.brand}
                    alt='ONsight'
                    // eslint-disable-next-line no-return-assign
                    onError={(event) => (event.target.style.display = 'none')}
                  />
                </Col>
              </Row>
              {this.state.renderGuestLogin === false ? (
                this.loginView(fpLink)
              ) : (
                <GuestLogin
                  meetingId={this.props.meetingId}
                  setErrorModal={this.props.setError}
                  handleGuestLoginAPI={this.props.handleGuestLogin}
                  onCancel={() => this.setState({ renderGuestLogin: false })}
                />
              )
              }
              {this.props.signInOption === LOGIN_OPTIONS.SSO
                && this.state.renderGuestLogin === false
                && (window.dynamicEnv.REACT_APP_ALLOW_SSO === 'true') && (
                  <SSOLogin
                    setErrorModal={this.props.setError}
                    toggleSignInOptions={this.props.toggleSignInOptions}
                    handleSSOLoginAPI={this.props.handleSSOLogin}
                    ssoDomain={this.props.ssoDomain}
                  />
              )}
            </Col>
          </Row>
        </Col>
      </Container>
    );
  }
}
