export const getUserData = (state) => state.ClientSettingsReducer.userData;
export const getClientSettingsData = (state) => state.ClientSettingsReducer.clientSettingsData;
export const getPolicyUISettings = (state) => state.ClientSettingsReducer.clientPolicyUISettings;
export const getClientPolicyData = (state) => state.ClientSettingsReducer.clientPolicyData;
export const getClientPolicyReqTimestamp = (state) =>
  state.ClientSettingsReducer.clientPolicyRequestTimestamp;
export const getProfileReqTimestamp = (state) =>
  state.ClientSettingsReducer.profileRequestTimestamp;
export const getClientSessionConfigInfo = (state) =>
  state.ClientSettingsReducer.clientSessionConfigInfo;
export const getClientPermissions = (state) => state.ClientSettingsReducer.clientPermissions;
export const getVideoMediaConfig = (state) => state.ClientSettingsReducer.videoMediaConfig;
export const getSelectedVideoProfile = (state) => state.ClientSettingsReducer.selectedVideoProfile;
export const getNetworkSettings = (state) => state.ClientSettingsReducer.networkSettings;
export const getVideoMediaConfigs = (state) => state.ClientSettingsReducer.videoMediaConfigs;
export const getAudioMediaConfigs = (state) => state.ClientSettingsReducer.audioMediaConfigs;
export const getSecuritySettings = (state) => state.ClientSettingsReducer.securitySettings;
export const getNLPUrl = (state) => state.ClientSettingsReducer.nlpUrl;
export const getAskAndSearchParams = (state) => state.ClientSettingsReducer.askAndSearchParams;
export const getWorkspaceSettings = (state) => state.ClientSettingsReducer.workspaceSettings;
export const isDemoRunning = (state) => state.ClientSettingsReducer.demoMode;
export const getMeetingSettings = (state) => state.ClientSettingsReducer.meetingSettings;
export const getOverlaySettings = (state) => state.ClientSettingsReducer.videoOverlaySettings;
export const getGuestInvitePolicy = (state) => state.ClientSettingsReducer.guestInvitePolicy;
export const getGuestCustomFields = (state) => state.ClientSettingsReducer.guestCustomFields;
export const getImageCaptureSettings = (state) => state.ClientSettingsReducer.imageCaptureSettings;
