/* eslint-disable no-bitwise */
// Services
import {
  TELEPOINT_TYPE_FLAGS,
  LS_DATASTREAM_PARTICIPANTID,
} from 'UTILS/constants/DatastreamConstant';
import { LSDS } from 'SERVICES/LSDatastream/LSDSUtils';
import {
  EXTENDED_FUNCTIONS,
  DEFAULT_FONT_SIZE,
  DEFAULT_FONT_ID,
  DEFAULT_COORDS,
  DEFAULT_ARGB_COLOR,
  DEFAULT_COLOR_FLAG,
  DEFAULT_TEXT,
  DEFAULT_NUM,
  DEFAULT_THICKNESS,
} from 'UTILS/constants/TelestrationConstant';

// Services
import { AppLogger, LOG_NAME } from 'SERVICES/Logging/AppLogger';

const logger = AppLogger(LOG_NAME.Telestration);

/**
 * @type
 * Template to use for sending the telepoint event
 * Overwrite with changed values
 */
const TeleEventTemplate = {
  clearPids: [],
  colorArgb: DEFAULT_ARGB_COLOR,
  coords: DEFAULT_COORDS,
  fontid: DEFAULT_FONT_ID,
  fontsize: DEFAULT_FONT_SIZE,
  isColorSet: DEFAULT_COLOR_FLAG,
  num: DEFAULT_NUM,
  pid: 0,
  telestrationExtendedType: 0,
  text: DEFAULT_TEXT,
  thickness: DEFAULT_THICKNESS,
  type: 0,
};

class LSDSTelestration {
  constructor(dsBase, callbacks) {
    // Defines the interface for calls from from TeleHelper layer
    this.api = {
      dsBase, // To make dsBase available in all callbacks
      onStreamStarted: this.onStreamStarted,
      onTelepointRecv: this.onTelepointRecv,
      onColourChangeRecv: this.onColourChangeRecv,
      sendTelepoint: this.sendTelepoint,
      sendTelepointExt: this.sendTelepointExt,
      sendErase: this.sendErase,
      sendColorChange: this.sendColorChange,
      sendClearAll: this.sendClearAll,
    };
    this.dsBase = dsBase;
    this.teleCallback = callbacks;
  }

  // Call this in telestrationhelper in future
  initTelestration = () => {
    // const lsCallbacks = {
    //   onStreamStarted: this.onStreamStarted,
    //   onTelepointRecv: this.onTelepointRecv,
    //   onColourChangeRecv: this.onColourChangeRecv,
    // };
  }

  sendTelepoint = (telepoint) => {
    LSDS.sendTelepoint(telepoint);
  }

  onTelepointRecv = (telepoint, config, isImage) => {
    logger.info(`Telestration event telepoint data::${telepoint}, config::${config}, for image? ${isImage}`);
    if (telepoint.type & (TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_STREAM_START_COMPLETE)
    ) {
      logger.debug('Telestrations should be redrawn when a new participant is added');
      const REDRAW_TELE_TIMEOUT = 1500;
      setTimeout(() =>
        this.teleCallback.redraw(isImage), REDRAW_TELE_TIMEOUT); // 1684 temp fix
    } else {
      // Need to work on this- When new participant joins the call,
      // sometimes old telestrations are not visible as configs are not loaded
      telepoint.config = config;
      telepoint.isImage = isImage;
      this.teleCallback.setData(telepoint, isImage);
    }
  }

  onColourChangeRecv = (colourData) => {
    logger.debug('LSDSTelestration::onColourChangeRecv::', colourData);
    this.teleCallback.onColorChange(colourData);
  }

  onStreamStarted = (args) => {
    this.sendTelepoint({
      clearPids: [],
      colorArgb: args.color,
      coords: [],
      fontid: 0,
      isColorSet: true,
      num: 0,
      pid: args.localPid,
      telestrationExtendedType: 0,
      text: '',
      thickness: 0.0,
      // eslint-disable-next-line no-bitwise
      type: TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_TELESTRATION |
        TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_STREAM_START_COMPLETE,
    });
  }

  /* TODO: Rename as appropriate */
  sendErase = () => {
    const self = this;
    this.sendTelepoint({
      ...TeleEventTemplate,
      clearPids: [LS_DATASTREAM_PARTICIPANTID.ALL_PARTICIPANTID],
      pid: self.dsBase.getLocalPId(),
      type: EXTENDED_FUNCTIONS.ERASE.value,
    });
  };

  sendClearAll = (isForImage = false) => {
    // eslint-disable-next-line no-bitwise
    const teleFlagForImg = (TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_TELESTRATION |
      TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_CLEAR_PIDS |
      TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_STILL_IMAGE_KIND);

    const teleFlagForVideo = (TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_TELESTRATION |
      TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_REMOTE
      | TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_CLEAR_PIDS);

    const teleFlag = isForImage ? teleFlagForImg : teleFlagForVideo;
    // create data obj to send the DS message
    const self = this;
    const teleDetails = {
      ...TeleEventTemplate,
      clearPids: [LS_DATASTREAM_PARTICIPANTID.ALL_PARTICIPANTID],
      pid: self.dsBase.getLocalPId(),
      type: teleFlag,
    };
    logger.log('LSDSTele: Erase all telestration ', teleDetails, teleFlag);
    this.sendTelepoint(teleDetails);
  }

  //
  sendTelepointExt(options, isImageShared = false) {
    const self = this;
    const flag = (TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_EXTENDED |
      TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_TELESTRATION |
      TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_REMOVE);
    const teleFlag = isImageShared ?
      flag | TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_STILL_IMAGE_KIND
      : flag;

    this.sendTelepoint({
      clearPids: [],
      colorArgb: options.colorArgb ?? -1,
      coords: options.coords ?? [],
      fontid: DEFAULT_FONT_ID,
      fontsize: DEFAULT_FONT_SIZE,
      isColorSet: true,
      num: options.num ?? 0,
      pid: self.dsBase.getLocalPId(),
      telestrationExtendedType: options.telestrationExtendedType,
      text: '',
      thickness: 0,
      type: teleFlag,
    });
  }

  sendColorChange(colorChangeData) {
    // Color change event
    const self = this;
    const ccEvent = {
      colour: colorChangeData,
      flags: TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_EXTENDED,
      pid: self.dsBase.getLocalPId(),
    };
    LSDS.CfgCtrl.CfgEventTelestrationColours(ccEvent);
  }
}

export default LSDSTelestration;
