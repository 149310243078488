/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { isMobileOnly, isDesktop } from 'react-device-detect';
import { ToastContainer } from 'react-toastify';

// Custom components
import TextFieldComponent from 'COMPONENTS/CustomComponents/TextFieldComponent/TextFieldComponent';
import CustomForm from 'COMPONENTS/CustomComponents/CustomForm/CustomForm';
import ErrorModal from 'COMPONENTS/CustomComponents/ErrorDialogModal/ErrorModal';
import withLoadingSpinner from 'COMPONENTS/HOC/withLoadingSpinner';
import { showToast } from 'COMPONENTS/CustomComponents/Toast/Toast';
import CustomButton from 'COMPONENTS/CustomComponents/CustomButton/CustomButton';

// Action
import { changePasswordAction, loginAction } from 'STORE/Auth/AuthAction';

// Utility
import { translate } from 'SERVICES/i18n';

// Asset
import { ReactComponent as ShowPasswordIcon } from 'ASSETS/icon_show_password_on.svg';
import { ReactComponent as HidePasswordIcon } from 'ASSETS/icon_show_password_off.svg';

// Constant
import { CHANGE_PASSWORD_RESULT_TYPES, LOGIN_RESPONSES } from 'UTILS/constants/LoginConstants';
import { ROUTES } from 'UTILS/constants/RoutingPathConstants';
import { BUTTON_TYPES } from 'UTILS/constants/UtilityConstants';

import './Login.scss';
import { initializePendo } from '../../utils/pendoUtils';

class ChangePassword extends Component {
  types = ['oldPassword', 'newPassword', 'confirmPassword'];

  constructor(props) {
    super(props);

    this.state = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      isOldPasswordVisible: true,
      isNewPasswordVisible: true,
      isConfirmPasswordVisible: true,
      showErrorPopup: false,
      serverErrorMessage: '',
      errorHeading: '',
    };
  }

  componentDidMount() {
    // Old password comes from login page that the user has entered
    this.setState({ oldPassword: this.props?.history?.location?.state?.password });
  }

  // *This function is repeated from login container
  // Need to revisit this in order to remove duplication
  handleLoginResponse(responseData) {
    if (responseData && responseData.resultNumber) {
      switch (responseData.resultNumber) {
        case LOGIN_RESPONSES.SUCCESS:
          // Do some action on successful login!
          initializePendo();
          break;
        case LOGIN_RESPONSES.FAILURE:
          // Do some action on login failure!
          this.setError(
            'errors.runtimeErrorMessage',
            true,
            'errors.serverErrorHeading',
          );
          break;
        case LOGIN_RESPONSES.MAX_SESSION_EXCEED:
          // Displaying error message for maximum session number exceeded!
          this.setError(
            'errors.sessionExcceded',
            true,
            'errors.serverErrorHeading',
          );
          break;
        default:
          break;
      }
    }
  }

  enablePassword = (type) => {
    switch (type) {
      case this.types[0]:
        if (this.state.oldPassword?.length > 0) {
          this.setState({
            isOldPasswordVisible: false,
          });
        }
        break;
      case this.types[1]:
        if (this.state.newPassword?.length > 0) {
          this.setState({
            isNewPasswordVisible: false,
          });
        }
        break;
      case this.types[2]:
        if (this.state.confirmPassword?.length > 0) {
          this.setState({
            isConfirmPasswordVisible: false,
          });
        }
        break;
      default: break;
    }
  };

  disablePassword = (type) => {
    switch (type) {
      case this.types[0]:
        if (this.state.oldPassword?.length > 0) {
          this.setState({
            isOldPasswordVisible: true,
          });
        }
        break;
      case this.types[1]:
        if (this.state.newPassword?.length > 0) {
          this.setState({
            isNewPasswordVisible: true,
          });
        }
        break;
      case this.types[2]:
        if (this.state.confirmPassword?.length > 0) {
          this.setState({
            isConfirmPasswordVisible: true,
          });
        }
        break;
      default: break;
    }
  };

  handleChange = (event, type) => {
    switch (type) {
      case this.types[0]:
        this.setState(
          {
            oldPassword: event.target.value,
          },
        );
        break;
      case this.types[1]:
        this.setState(
          {
            newPassword: event.target.value,
          },
        );
        break;
      case this.types[2]:
        this.setState(
          {
            confirmPassword: event.target.value,
          },
        );
        break;
      default: break;
    }
  }

  onCancel = () => {
    this.props.history.push({
      pathname: ROUTES.LOGIN,
      state: {
        cpUsername: this.props?.history?.location?.state?.userName,
        cpPassword: this.state.oldPassword,
      },
    });
  }

  onSubmit = () => {
    if (this.state.oldPassword && this.state.newPassword && this.state.confirmPassword) {
      if (this.state.newPassword !== this.state.confirmPassword) {
        // New password and confirm password should match (local validation)
        this.setError(
          translate('changePassword.passwordMismatchError'),
          true,
          translate('changePassword.invalidPasswordHeader'),
        );
      } else {
        const userName = this.props?.history?.location?.state?.userName;
        this.props.changePasswordAction(userName, this.state.oldPassword, this.state.newPassword)
          .then((res) => {
            if (res.data.resultNumber === CHANGE_PASSWORD_RESULT_TYPES.POLICY_VIOLATION) {
              // Password policy violation (validation)
              this.setError(
                translate('changePassword.passwordText2'),
                true,
                translate('changePassword.invalidPasswordHeader'),
              );
            } else if (res.data.resultNumber === CHANGE_PASSWORD_RESULT_TYPES.OLD_NEW_MATCH) {
              // Same old and new password
              this.setError(
                translate('changePassword.invalidNewPasswordError'),
                true,
                translate('changePassword.invalidPasswordHeader'),
              );
            } else if (res.data.resultNumber === CHANGE_PASSWORD_RESULT_TYPES.OLD_PASSWORD_WRONG) {
              // Old password incorrect
              this.setError(
                translate('changePassword.oldPasswordIncorrect'),
                true,
                translate('changePassword.invalidPasswordHeader'),
              );
            } else if (res.data.resultNumber === CHANGE_PASSWORD_RESULT_TYPES.SUCCESS) {
              // Show success toast
              showToast(`${translate('changePassword.successToast')}`);
              // Sign in the user with new password
              setTimeout(() => {
                this.props.showSpinner();
                // *This login() Code is repeated here(login function from login container)
                this.props.loginAction(userName, this.state.newPassword)
                  .then((response) => {
                    this.props.hideSpinner();
                    // Check for login attempts and whether the acc is locked, else go to dashboard
                    console.debug('IsOPMSessionExpired?::', this.props.isSessionExpired);
                    if (response.status === 200) {
                      if (response.data.attemptsRemaining > 0) {
                        this.setError(
                          translate('errors.invalidCredentials', {
                            number: response.data.attemptsRemaining,
                          }),
                          true,
                          'login.invalidUsernameOrPasswordModalHeader',
                        );
                      } else if (
                        (response.data.attemptsRemaining === 0 && response.data.resultNumber === 15)
                        // incorrect username or passwd and acc locked
                        || response.data.resultNumber === 4) {
                        // valid creds but acc is already locked
                        this.setError(
                          translate('errors.accountTemporarilyLocked'),
                          true,
                          'login.invalidUsernameOrPasswordModalHeader',
                        );
                      } else if (
                        response.data.attemptsRemaining === -1 && response.data.resultNumber === 15
                        // Invalid username
                      ) {
                        this.setError(
                          translate('errors.invalidUserNameOrPassword'),
                          true,
                          'login.invalidUsernameOrPasswordModalHeader',
                        );
                      } else if (
                        response.data.resultNumber === 13 || response.data.resultNumber === 14) {
                        // 13-PasswordExpired  14-PasswordTemporary
                        this.props.history.push({
                          pathname: ROUTES.CHANGE_PASSWORD,
                          state: { userName, password: this.state.oldPassword },
                        });
                      } else {
                        this.handleLoginResponse(res.data);
                      }
                    }
                  });
              }, 3000);
            } else {
              console.info(res.data, 'Change password response');
            }
          })
          .catch((error) => {
            console.error('error inside :---->', error);
            this.setError(
              error?.error?.message,
              true,
              'errors.serverErrorHeading',
            );
          });
      }
    }
  }

  // eslint-disable-next-line default-param-last
  setError = (message, isServerError = false, heading) => {
    console.log(message, 'err message');
    this.setState({
      showErrorPopup: true,
      serverErrorMessage: isServerError ? message : '',
      errorHeading: isServerError ? heading : '',
    });
  };

  handleDismissError = () => {
    this.setState({
      showErrorPopup: false,
    });
  };

  render() {
    return (
      <div className='login-layout'>
        <div id='subcomponent' className='sub-component'>
          <Container
            fluid
            className={
              'login-container-layout ' +
              (isMobileOnly ? ' mt-5' : ' justify-content-center')
            }
          >
            <Col className='col-xxl-3' xl={isDesktop ? 4 : 5} lg={5} md={6} sm={6} xs={10}>
              <Row className='login-container'>

                <Col className='align-center p-0'>
                  <CustomForm
                    formId='change-password'
                  >

                    <Row className={`${isMobileOnly ? 'py-2' : 'py-4'}`}>
                      <Col className='align-center py-1 change-password-text'>{translate('changePassword.changePassword')}</Col>
                    </Row>
                    <Row className='py-2'>
                      <Col className='align-center password-text1'>
                        {translate('changePassword.passwordText1')}
                      </Col>
                    </Row>
                    <Row className='py-1'>
                      <Col className='align-center py-1 password-text2'>
                        {translate('changePassword.passwordText2')}
                      </Col>
                    </Row>
                    <Row className={`align-horizontal-center ${isMobileOnly ? 'p-0 py-3' : 'p-3 py-3'}`}>

                      <Row className={`${isMobileOnly && 'p-0'}`}>
                        <TextFieldComponent
                          id='old-password'
                          type={
                            this.state.isOldPasswordVisible ? 'password' : 'text'
                          }
                          value={this.state.oldPassword}
                          placeholder={translate('login.enterPassword')}
                          maxLength={128}
                          rightIcon={
                            this.state.isOldPasswordVisible
                              ? HidePasswordIcon
                              : ShowPasswordIcon
                          }
                          onPointerDown={() => this.enablePassword(this.types[0])}
                          onPointerUp={() => this.disablePassword(this.types[0])}
                          onPointerLeave={() => this.disablePassword(this.types[0])}
                          handleChange={(event) =>
                            this.handleChange(event, this.types[0])
                          }
                          className={`${isMobileOnly ? 'password-field-mob' : 'password-field'}`}
                          disabled={!this.props?.history?.location?.state?.userName}
                        />
                      </Row>

                      <Row className={`${isMobileOnly && 'mt-2 p-0'}`}>
                        <TextFieldComponent
                          id='new-password'
                          type={
                            this.state.isNewPasswordVisible ? 'password' : 'text'
                          }
                          value={this.state.newPassword}
                          placeholder={translate('changePassword.newPassword')}
                          maxLength={128}
                          rightIcon={
                            this.state.isNewPasswordVisible
                              ? HidePasswordIcon
                              : ShowPasswordIcon
                          }
                          onPointerDown={() => this.enablePassword(this.types[1])}
                          onPointerUp={() => this.disablePassword(this.types[1])}
                          onPointerLeave={() => this.disablePassword(this.types[1])}
                          handleChange={(event) =>
                            this.handleChange(event, this.types[1])
                          }
                          disabled={!this.state.oldPassword?.length > 0}
                          className={`${isMobileOnly ? 'password-field-mob' : 'password-field'}`}
                        />
                      </Row>

                      <Row className={`${isMobileOnly && 'mt-2 p-0'}`}>
                        <TextFieldComponent
                          id='confirm-password'
                          type={
                            this.state.isConfirmPasswordVisible ? 'password' : 'text'
                          }
                          value={this.state.confirmPassword}
                          placeholder={translate('changePassword.confirmPassword')}
                          maxLength={128}
                          rightIcon={
                            this.state.isConfirmPasswordVisible
                              ? HidePasswordIcon
                              : ShowPasswordIcon
                          }
                          onPointerDown={() => this.enablePassword(this.types[2])}
                          onPointerUp={() => this.disablePassword(this.types[2])}
                          onPointerLeave={() => this.disablePassword(this.types[2])}
                          handleChange={(event) =>
                            this.handleChange(event, this.types[2])
                          }
                          disabled={!this.state.oldPassword?.length > 0
                            || !this.state.newPassword?.length > 0}
                          className={`${isMobileOnly ? 'password-field-mob' : 'password-field'}`}
                        />
                      </Row>

                      <Row className={`${isMobileOnly ? 'mt-4 p-0' : 'mt-4'}`}>
                        <Col className='align-horizontal-center'>
                          <CustomButton
                            className={`align-center action-buttons button-full-width${isMobileOnly ? ' mob-button' : ' btn'}`}
                            onClick={this.onCancel}
                            text='contacts.cancelAddContact'
                            variant={BUTTON_TYPES.SECONDARY}
                          />
                        </Col>
                        <Col className='align-horizontal-center'>
                          <CustomButton
                            className={`align-center action-buttons button-full-width${isMobileOnly ? ' mob-button' : ' btn'}`}
                            disabled={!this.state.oldPassword ||
                              !this.state.newPassword || !this.state.confirmPassword}
                            onClick={this.onSubmit}
                            text='changePassword.submit'
                          />
                        </Col>
                      </Row>

                    </Row>
                  </CustomForm>
                </Col>
              </Row>
            </Col>

            <ToastContainer />
          </Container>

          <ErrorModal
            open={this.state.showErrorPopup}
            header={this.state.errorHeading}
            errorMessage={this.state.serverErrorMessage}
            onClose={this.handleDismissError}
            rightButton={[
              {
                text: translate('login.okButton'),
                onClick: this.handleDismissError,
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changePasswordAction,
      loginAction,
    },
    dispatch,
  );
}
export default withLoadingSpinner(
  connect(
    null,
    mapDispatchToProps,
  )(ChangePassword),
  { spinnerText: translate('login.signingIn') },
);
