/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Popover, PopoverBody, Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';

// Components
import CustomPopover from 'COMPONENTS/CustomComponents/CustomPopover/CustomPopover';
import RangeSliderComponent from 'COMPONENTS/CustomComponents/RangeSlider/RangeSlider';

// Actions
import { setTelestrationIconDetails } from 'STORE/User/UserAction';

// Selectors
import { isDemoRunning } from 'STORE/ClientSettings/ClientSettingsSelector';

// Constants
import { TELESTRATION_ICON_SET, TELESTRATION_COLORS, TELESTRATION_ITEM_TYPE, EXTENDED_FUNCTIONS } from 'UTILS/constants/TelestrationConstant';
import { RANGE_SLIDER_VALUES, POPOVER_PLACEMENT } from 'UTILS/constants/UtilityConstants';

// Style
import './TelestrationPopover.scss';

const colorIcon = TELESTRATION_ICON_SET.find((icon) => icon?.id === 'color');
const freeHandIcon = TELESTRATION_ICON_SET.find((icon) => icon?.id === 'freehand');

/**
 *
 * @param {bool} isColorPopoverOpen to hide/show color pallet popover
 * @param {function} toggleColorPopover to toggle the color pallet popover
 * @param {string} selectedColor selected color from color pallet
 * @param {function} colorIconClick onClick handler for color icon
 * @returns jsx for color pallet popover
 */
function ColorPallet({ isColorPopoverOpen, toggleColorPopover, selectedColor, colorIconClick }) {
  const menuPlacement = isMobile && window.innerHeight > window.innerWidth
    ? POPOVER_PLACEMENT.AUTO_END : POPOVER_PLACEMENT.RIGHT_START;

  return (
    <Popover className='color-popover' placement={menuPlacement} isOpen={isColorPopoverOpen} target={colorIcon?.iconName} toggle={toggleColorPopover} hideArrow>
      <PopoverBody className='color-pallet'>
        {TELESTRATION_COLORS.map((colorObj) => (
          <div
            role='presentation'
            key={colorObj.colorCode}
            className={`color-box ${selectedColor === colorObj.colorCode && 'active-icon'}`}
            onClick={(e) => colorIconClick(e, colorObj.colorCode)}
          >
            <div
              className='color-drop'
              role='presentation'
              style={{ backgroundColor: colorObj.colorCode }}
            />
          </div>
        ))
        }
      </PopoverBody>
    </Popover>
  );
}

ColorPallet.propTypes = {
  isColorPopoverOpen: PropTypes.bool.isRequired,
  toggleColorPopover: PropTypes.func.isRequired,
  selectedColor: PropTypes.string.isRequired,
  colorIconClick: PropTypes.func.isRequired,
};

/**
 *
 * @param {bool} isPopoverOpen to hide/show telestration popover
 * @param {function} togglePopover to toggle the telestration popover
 * @returns jsx for telestration popover
 */
function TelestrationPopover({ isPopoverOpen, togglePopover, setApplyBackdrop,
  closeTelestrationPopover, teleHelper, isImageShared, isTeleEnabled }) {
  const dispatch = useDispatch();
  // Selectors
  const selectedTelestrationIcon = useSelector(
    (state) => state.UserReducer.selectedTelestrationDetails,
  );

  const selectedVideoConfig = useSelector(
    (state) => state.ClientSettingsReducer.selectedVideoProfile,
  );

  const demoRunning = useSelector(isDemoRunning);

  // State variables
  const [selectedIcon, setSelectedIcon] = useState(selectedTelestrationIcon?.item ?? freeHandIcon);
  const [isColorPopoverOpen, setColorPopoverOpen] = useState(false);
  const [strokeValue, setStrokeValue] = useState(selectedTelestrationIcon?.stroke ?? 0);
  const [selectedColor, setSelectedColor] = useState(selectedTelestrationIcon?.colorCode
    ?? TELESTRATION_COLORS[0]?.colorCode);

  // Update selected color on UI
  useEffect(() => {
    setSelectedColor(selectedTelestrationIcon.colorCode);
    if (isTeleEnabled) {
      teleHelper.initLocalTelestration(
        selectedIcon.id,
        selectedColor,
        strokeValue,
        selectedVideoConfig,
        isImageShared,
      );
    }
  }, [isTeleEnabled]);

  const handleClickOutside = () => {
    setColorPopoverOpen(false);
    setApplyBackdrop(false);
    closeTelestrationPopover();
  };

  /**
   * onChange handler for stroke width slider component
   * @param {number} thickness strokeWidth of a pen used to draw telestration
   */
  const handleStrokeChange = (thickness, e) => {
    e.stopPropagation();
    teleHelper.removeAllEventListeners();
    setStrokeValue(thickness);
    const itemInfo = { ...selectedTelestrationIcon };
    itemInfo.stroke = thickness;
    // set strokeWidth into redux
    dispatch(setTelestrationIconDetails(itemInfo));
    teleHelper.changeThickness(thickness);
  };

  /**
   * toggle the color popover to display color pallet
   */
  const toggleColorPopover = () => {
    setColorPopoverOpen(!isColorPopoverOpen);
    if (isColorPopoverOpen) {
      setApplyBackdrop(false);
    } else {
      setApplyBackdrop(true);
    }
  };

  /**
   * sets the selected color from color pallet
   * @param {object} e event for color icon click
   * @param {string} color selected color from color pallet
   */
  const colorIconClick = (e, color) => {
    if (demoRunning) return;
    e.stopPropagation();
    setSelectedColor(color);
    const itemInfo = { ...selectedTelestrationIcon };
    itemInfo.colorCode = color;
    // set color into redux
    dispatch(setTelestrationIconDetails(itemInfo));
    setColorPopoverOpen(false);
    setApplyBackdrop(false);
    teleHelper.changeUserTeleColor(color, isImageShared);
  };

  /**
   * onClick handler for icons in the telestration popover
   * @param {object} item selected icon from telestration popover
   */
  const iconClick = (e, item) => {
    if (demoRunning) return;
    e.stopPropagation();

    setSelectedIcon(item);
    const itemInfo = { ...selectedTelestrationIcon };
    itemInfo.item = item;

    // set item into redux
    dispatch(setTelestrationIconDetails(itemInfo));

    if (item?.iconName === colorIcon?.iconName) {
      toggleColorPopover();
    } else {
      teleHelper.startLocalTelestration(
        item.id,
        selectedColor,
        strokeValue,
        selectedVideoConfig,
        isImageShared,
      );
      setColorPopoverOpen(false);
      setApplyBackdrop(false);
      if (!(item?.iconName === EXTENDED_FUNCTIONS?.UNDO?.name
        || item?.iconName === EXTENDED_FUNCTIONS?.ERASE?.name)) {
        togglePopover();
      }
    }
  };

  const checkIfIconIsDisabled = (item) => {
    if (teleHelper?.getLocalUsersTelestrationLength(isImageShared) === 0
      && item?.iconName === EXTENDED_FUNCTIONS?.UNDO?.name) {
      return true;
    }

    if (teleHelper?.getAllUserTelestrationLength(isImageShared) === 0 &&
      item?.iconName === EXTENDED_FUNCTIONS?.ERASE?.name) {
      return true;
    }
    return false;
  };

  const displayTelestrationUI = () => (
    <>
      <Col md={12} xs={12} lg={12}>
        <Row className='px-1'>
          {TELESTRATION_ICON_SET.map((item) => (
            <React.Fragment key={item.id}>
              <Col
                xs={4}
                lg={4}
                md={4}
                className={`telestration-icon ${selectedIcon?.id === item?.id && 'active-icon'} ${checkIfIconIsDisabled(item) && 'disabled'}`}
                id={item.id}
                key={item.id}
                onClick={(e) => iconClick(e, item)}
              >
                {item?.iconName === colorIcon?.iconName ? (
                  <div
                    className='color-drop'
                    style={{ backgroundColor: selectedColor }}
                  />
                )
                  : (
                    <>
                      <item.icon className={item.className} />
                      {isPopoverOpen && isColorPopoverOpen && (
                        <ColorPallet
                          isColorPopoverOpen={isColorPopoverOpen}
                          toggleColorPopover={toggleColorPopover}
                          selectedColor={selectedColor}
                          colorIconClick={colorIconClick}
                        />
                      )}
                    </>
                  )}
              </Col>
              {item?.iconName === TELESTRATION_ITEM_TYPE?.ELLIPSE?.name && (
                <Col md={12} xs={12} lg={12} className='divider-margin px-1'>
                  <div className='divider' />
                </Col>
              )}
            </React.Fragment>
          ))
          }
        </Row>
      </Col>

      <Col md={12} xs={12} lg={12} className='divider-margin pb-2'>
        <div className='divider' />
      </Col>

      <RangeSliderComponent
        rangeValue={strokeValue}
        setRangeValue={(thickness, e) => handleStrokeChange(thickness, e)}
        minValue={RANGE_SLIDER_VALUES.MIN}
        maxValue={RANGE_SLIDER_VALUES.MAX}
        sliderStep={RANGE_SLIDER_VALUES.STEP}
        isStroke
      />
    </>
  );
  return (
    <CustomPopover
      isPopoverOpen={isPopoverOpen}
      togglePopover={togglePopover}
      menuPlacement={POPOVER_PLACEMENT.TOP_START}
      closeCustomPopover={handleClickOutside}
      title='telestration.Telestration'
      child={displayTelestrationUI()}
      target='telestration'
      id='telestration-popover'
      className='telestration-popover'
    />
  );
}
TelestrationPopover.propTypes = {
  isPopoverOpen: PropTypes.bool.isRequired,
  togglePopover: PropTypes.func.isRequired,
  setApplyBackdrop: PropTypes.func.isRequired,
  closeTelestrationPopover: PropTypes.func.isRequired,
  isImageShared: PropTypes.bool.isRequired,
  isTeleEnabled: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  teleHelper: PropTypes.object.isRequired,
};

export default compose(TelestrationPopover);
