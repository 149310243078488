import axios from 'axios';
import { API_URIS,
  API_METHODS,
  AXIOS_CONSTANTS,
  REST_SERVICE,
} from 'UTILS/constants/ApiConstants';
import CommonUtility from 'UTILS/CommonUtility';
import { X_PATHS } from 'UTILS/constants/UtilityConstants';

import { translate } from 'SERVICES/i18n';

const DELAY = 200;

// eslint-disable-next-line no-async-promise-executor
export const getAnswer = async (url, key, question, controller) => new Promise(async (resolve) => {
  if (question.length === 0) {
    resolve([]);
    return;
  }

  // wait to avoid aggressively calling the endpoint
  // eslint-disable-next-line no-promise-executor-return
  await new Promise((r) => setTimeout(r, DELAY));

  axios({
    method: API_METHODS.POST,
    url,
    headers: {
      'Ocp-Apim-Subscription-Key': key,
      'Content-Type': 'application/json',
    },
    data: {
      top: 5,
      question,
      includeUnstructuredSources: true,
    },
    signal: controller?.signal,
  })
    .then((res) => {
      if (res.status !== 200) throw Error(`KnowledgeDeliveryClient::getAnswer() res.status = ${res.status}`);
      const { answers } = res.data;
      resolve(answers);
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        console.debug(`KnowledgeDeliveryClient::getAnswer(${question}) canceled`, url);
      } else {
        console.error(`KnowledgeDeliveryClient::getAnswer(${question}) failed - `, JSON.stringify(error), url);
      }
      resolve([]);
    });
});

export const getWorkspaceTags = async (searchText, sessionToken, workspaceSettings, controller) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve) => {
    if (searchText.length === 0) {
      resolve([]);
      return;
    }

    // wait to avoid aggressively calling the endpoint
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((r) => setTimeout(r, DELAY));
    axios({
      method: API_METHODS.POST,
      url:
    `${workspaceSettings.url}/api/v1/automation/Tag.Suggestion`,
      headers: {
        'Content-Type': 'application/json+nxrequest',
        Accept: 'application/json',
        'X-Session-Token': sessionToken,
      },
      signal: controller?.signal,
      data: {
        params: { searchTerm: searchText },
        context: {},
      },
    })
      .then((res) => {
        if (res.status !== 200) throw Error(`KDClient::getWorkspaceTags() res.status = ${res.status}`);
        resolve((res.data ?? []).map((e) => ({ label: e.displayLabel, value: e.id })));
      })
      .catch((error) => {
        console.error(`KnowledgeDeliveryClient::getWorkspaceTags failed - ${searchText}) `, JSON.stringify(error));
        resolve(null);
      });
  });

export const getWorkspaceUsers = async (searchText, sessionToken, workspaceSettings, controller) =>
// eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve) => {
    if (searchText.length === 0) {
      resolve([]);
      return;
    }

    axios({
      method: API_METHODS.POST,
      url:
      `${workspaceSettings.url}/api/v1/automation/UserGroup.Suggestion`,
      headers: {
        'Content-Type': 'application/json+nxrequest',
        Accept: 'application/json',
        'X-Session-Token': sessionToken,
      },
      signal: controller?.signal,
      data: {
        params: { searchTerm: searchText },
        context: {},
      },
    })
      .then((res) => {
        if (res.status !== 200) throw Error(`KDClient::getWorkspaceUsers() res.status = ${res.status}`);
        resolve((res.data ?? []).map((e) => ({ label: e.displayLabel, value: e.id })));
      })
      .catch((error) => {
        console.error(`KnowledgeDeliveryClient::getWorkspaceUsers failed - ${searchText}) `, JSON.stringify(error));
        resolve([]);
      });
  });

export const getDefaultWorkspaceFilter = (isCall) => {
  let config = [
    {
      type: 'range',
      name: translate('search.dateModified'),
      docField: 'dc:modified',
      used: false,
      entries: [
        { name: translate('search.lastDay'), value: false, from: 'now-24H', to: 'now' },
        { name: translate('search.lastWeek'), value: false, from: 'now-7d', to: 'now' },
        { name: translate('search.lastMonth'), value: false, from: 'now-1M', to: 'now' },
        { name: translate('search.lastYear'), value: false, from: 'now-1y', to: 'now' },
        { name: translate('search.moreThanAYear'), value: false, from: null, to: 'now-1y' },
      ],
    },
    {
      type: 'range',
      name: translate('search.dateCreated'),
      used: false,
      docField: 'dc:created',
      entries: [
        { name: translate('search.lastDay'), value: false, from: 'now-24H', to: 'now' },
        { name: translate('search.lastWeek'), value: false, from: 'now-7d', to: 'now' },
        { name: translate('search.lastMonth'), value: false, from: 'now-1M', to: 'now' },
        { name: translate('search.lastYear'), value: false, from: 'now-1y', to: 'now' },
        { name: translate('search.moreThanAYear'), value: false, from: null, to: 'now-1y' },
      ],
    },
    {
      type: 'range',
      name: translate('search.size'),
      used: false,
      docField: 'file:content.length',
      entries: [
        { name: translate('search.lessThan100K'), value: false, from: null, to: 102400.0 },
        { name: '100 KB - 1MB', value: false, from: 102400.0, to: 1048576.0 },
        { name: '1 MB - 10MB', value: false, from: 1048577.0, to: 1.048576e7 },
        { name: '10 MB - 100 MB', value: false, from: 1.0485761e7, to: 1.048576e8 },
        { name: translate('search.moreThan100MB'), value: false, from: 1.04857601e8, to: null },
      ],
    },
    {
      type: 'multiple',
      name: translate('search.type'),
      used: false,
      docField: 'ecm:primaryType',
      entries: [
        { name: translate('search.audio'), value: false, docFieldValue: 'Audio' },
        { name: translate('search.picture'), value: false, docFieldValue: 'Picture' },
        { name: translate('search.video'), value: false, docFieldValue: 'Video' },
        { name: translate('search.file'), value: false, docFieldValue: 'File' },
      ],
    },
    {
      type: 'list',
      docField: 'ecm:tag',
      used: false,
      name: translate('search.tag'),
      suggestion: getWorkspaceTags,
      value: [],
    },
    {
      type: 'list',
      docField: 'dc:creator',
      used: false,
      name: translate('search.author'),
      suggestion: getWorkspaceUsers,
      value: [],
    },
  ];
  if (isCall) {
    config = config.filter((e) => e.name !== translate('search.type'));
  }

  return config;
};

export const clearFilter = (filter, index) => {
  const item = filter[index];
  if (item.type === 'list') {
    item.value = [];
  } else {
    item.entries.forEach((e) => { e.value = false; });
  }
  item.used = false;

  return filter;
};

const getPostFilter = (filters) => {
  const list = [];
  filters.forEach((filter) => {
    if (filter.type === 'range') {
      filter.entries
        .map((e) => {
          let condition = null;
          if (e.value) {
            condition = { range: {} };
            condition.range[`${filter.docField}`] =
            {
              from: e.from,
              to: e.to,
              include_lower: true,
              include_upper: false,
              boost: 1.0,
            };
          }
          return condition;
        })
        .filter((e) => e != null)
        .forEach(((e) => list.push(e)));
    }
  });

  if (list.length !== 0) {
    list.push({ match_none: { boost: 1.0 } });
  }

  return list.length === 0 ? undefined : {
    bool: {
      must: [
        {
          bool: {
            should: list,
            disable_coord: false,
            adjust_pure_negative: true,
            boost: 1.0,
          },
        },
      ],
      disable_coord: false,
      adjust_pure_negative: true,
      boost: 1.0,
    },
  };
};

const getFieldFilter = (filters) => {
  const getTemplate = () => ({
    constant_score: {
      filter: {
        terms: {
          boost: 1.0,
        },
      },
      boost: 1.0,
    },
  });
  const listFilter = filters
    .filter((filter) => filter.type === 'list' && filter.value.length > 0)
    .map((filter) => {
      const value = getTemplate();
      value.constant_score.filter.terms[filter.docField] = filter.value.map((e) => e.value);
      return value;
    });

  const multipleFilter = filters
    .filter((filter) => filter.type === 'multiple' && filter.entries.some((e) => e.value))
    .map((filter) => {
      const value = getTemplate();
      value.constant_score.filter.terms[filter.docField] = filter.entries
        .filter((e) => e.value)
        .map((e) => e.docFieldValue);
      return value;
    });

  return [...listFilter, ...multipleFilter];
};

const createQuery = (searchTerm) => (searchTerm && (searchTerm.includes('/') || searchTerm.includes('.') || searchTerm.includes('\''))) ? searchTerm : `${searchTerm}*`;

export const findWorkspace = async (
  sessionToken,
  searchTerm,
  page,
  workspaceSettings,
  controller,
  filters,
  isCall,
) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve) => {
    if (searchTerm.length === 0) {
      resolve([]);
      return;
    }

    // wait to avoid aggressively calling the endpoint
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((r) => setTimeout(r, DELAY));

    if (!filters) {
      filters = getDefaultWorkspaceFilter(isCall);
    }

    const pageSize = 5;
    const bodyData = {
      size: pageSize,
      from: page * pageSize,
      highlight: {
        require_field_match: false,
        fields: { },
      },

      post_filter: getPostFilter(filters),
      query: {
        bool: {
          must: [
            {
              simple_query_string: {
                query: createQuery(searchTerm),
                fields: ['all_field^1.0'],
                analyzer: 'fulltext',
                flags: -1,
                default_operator: 'and',
                lenient: false,
                analyze_wildcard: false,
                boost: 1.0,
              },
            },
            {
              term: { 'ecm:isVersion': false },
            },
            ...getFieldFilter(filters),
          ],
          must_not: [
            {
              term: { 'ecm:currentLifeCycleState': 'deleted' },
            },
          ],
        },
      },
      sort: [{ 'dc:created': { order: 'asc', unmapped_type: 'date' } }],
    };

    [
      'dc:title.fulltext',
      'ecm:binarytext',
      'dc:description.fulltext',
      'ecm:tag',
      'note:note.fulltext',
      'file:content.name',
      'ls-common:ls-metadata-list.full.fulltext',
    ].forEach((e) => {
      bodyData.highlight.fields[e] = { };
    });

    ((isCall)
      ? [
        { exists: { field: 'ls-transcript:start_time' } },
        { term: { 'ecm:primaryType': 'Audio' } },
      ]
      : [
        {
          bool: {
            should: [
              { term: { 'ecm:primaryType': 'File' } },
              { term: { 'ecm:primaryType': 'Picture' } },
              { term: { 'ecm:primaryType': 'Video' } },
              {
                bool: {
                  must: [
                    {
                      bool: {
                        must_not: {
                          exists: {
                            field: 'ls-transcript:start_time',
                          },
                        },
                      },
                    },
                    { term: { 'ecm:primaryType': 'Audio' } },
                  ],
                },

              },
            ],
          },
        },
      ]).forEach((e) => bodyData.query.bool.must.push(e));

    axios({
      method: API_METHODS.POST,
      url: `${workspaceSettings.url}/site/es/onsight/_search`,
      headers: {
        'X-Session-Token': sessionToken,
        'Content-Type': 'application/json',
      },
      signal: controller?.signal,
      data: bodyData,
    })
      .then((res) => {
        if (res.status !== 200) {
          throw Error(`KDClient::findWorkspace() res.status = ${res.status}`);
        }
        if (res.data.hits === undefined) {
          throw Error(`KDClient::findWorkspace() res.status = ${res.data.error.reason}`);
        }
        const count = res.data.hits.total;
        resolve({
          pages: Math.floor(count / pageSize) + ((count % pageSize) === 0 ? 0 : 1),
          current: page,
          count,
          entries: res.data.hits.hits.map((e) => ({
            name: e._source['ecm:title'],
            uniqueId: e._source['ecm:uuid'],
            lastModified: Date.parse(e._source['dc:modified']),
            author: e._source['dc:creator'],
            highlights: Object.entries(e.highlight ?? {})
              .map((item) => ({ field: item[0], segments: item[1] })),
            participants: e._source['ls-transcript:participants'],
            transcription: e._source['ls-transcript:content'],
            type: e._source['ecm:primaryType'],
            path: workspaceSettings.url + '/ui/#!/browse' + e._source['ecm:path'],
          })),
        });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(`KnowledgeDeliveryClient::findWorkspace(${searchTerm}) canceled`);
        } else {
          console.error(`KnowledgeDeliveryClient::findWorkspace(${searchTerm}) failed - `, JSON.stringify(error));
        }
        resolve(null);
      });
  });

export const getWorkspaceThumbnail = async (sessionToken, doc, workspaceSettings) => {
  if (doc.type === 'Audio' || doc.type === 'File') {
    return null;
  }

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    axios({
      method: API_METHODS.GET,
      url:
      `${workspaceSettings.url}/api/v1/repo/default/id/${doc.uniqueId}/@rendition/thumbnail`,
      headers: {
        Accept: '*/*',
        'X-Session-Token': sessionToken,
      },
      responseType: 'arraybuffer',
      withCredentials: false,
    })
      .then((res) => {
        if (res.status !== 200) throw Error(`KDClient::getWorkspaceThumbnail() res.status = ${res.status}`);
        const data = `data:${res.headers['content-type']};base64,${Buffer.from(res.data).toString('base64')}`;
        resolve(data);
      })
      .catch((error) => {
        console.error(`KnowledgeDeliveryClient::getWorkspaceThumbnail failed - ${doc.uniqueId})  ${doc.type}) `, JSON.stringify(error));
        resolve(null);
      });
  });
};

export function findContacts(searchText, authToken, controller) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    if (searchText.length === 0) {
      resolve([]);
      return;
    }

    // wait to avoid aggressively calling the endpoint
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((r) => setTimeout(r, DELAY));

    const headersValue = {};
    headersValue[AXIOS_CONSTANTS.REQUEST_HEADERS.AUTH_TOKEN] = `${AXIOS_CONSTANTS.REQUEST_HEADERS.SESSION_TOKEN} ${authToken}`;

    axios({
      method: API_METHODS.GET,
      url: `${window.dynamicEnv.REACT_APP_SERVER_BASE_URL}${REST_SERVICE}${API_URIS.GLOBAL_CONTACT}`,
      headers: headersValue,
      params: {
        UniqueId: '',
        NameSearchString: searchText,
        LocationType: 0,
        StartRecord: 0,
      },
      signal: controller?.signal,
    })
      .then((res) => {
        if (res.status !== 200) throw Error(`KDClient::findContacts() res.status = ${res.status}`);
        const contactsJson = CommonUtility.convertXmlToJSONGeneric(
          res.data.contactsXml, X_PATHS.CONTACTS,
        );
        resolve(Object.values(contactsJson).map((x) => x));
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(`KnowledgeDeliveryClient::findContacts(${searchText}) canceled`);
        } else {
          console.error(`KnowledgeDeliveryClient::findContacts(${searchText}) failed - `, JSON.stringify(error));
        }
        resolve(null);
      });
  });
}
