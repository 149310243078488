/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-bitwise */
import {
  LS_DATASTREAM_EVENTS,
  LS_DATASTREAM_EVENTSEVERITY,
  // DATAPACKET_FLAGS,
  LS_DATASTREAM_CORE_EVENTS,
  LS_DATASTREAM_PARTICIPANTID,
  TELEPOINT_TYPE_FLAGS,
  LS_DATASTREAM_LIVE_VIDEO_TOKEN_ACTION,
  LS_DISCONNECT_ID,
  STREAM_STATUS,
  QUEUED_SHARE_ACTION,
  STILL_IMAGE_CAPTURE_STATUS,
  STILL_IMAGE_CAPTURE_REQUEST_MESSAGE,
  STILL_IMAGE_CAPTURE_REQUEST_MASK,
  STILL_IMAGE_CAPTURE_MODE,
  STILL_IMAGE_SHARE_MODE_STATUS,
  STILL_IMAGE_SHARE_REQUEST_STATUS,
  FILE_TRANSFER_FLAG,
  QUEUED_SHARE_ACTION_WAITING_FLAGS,
  HOST_NEGOTIATION,
  BITRATE_INFO_FLAGS,
  // LSDS_EVENT,
  IlluminationModeFlags,
  EZoomDirection,
  DATAPACKET_FLAGS,
} from 'UTILS/constants/DatastreamConstant';
import { TELESTRATION_COLORS } from 'UTILS/constants/TelestrationConstant';
import { hexToArgb } from 'SERVICES/lsexif/telestration';
import OnSight from 'SERVICES/LSDatastream/Onsight';

import LsExif from 'SERVICES/lsexif/lsexif';

// Utility
import CommonUtility from 'UTILS/CommonUtility';

// Services
import { AppLogger, LOG_NAME } from 'SERVICES/Logging/AppLogger';

import moment from 'moment';
import _ from 'lodash';

// Constants
import {
  AUDIO_MEDIA_CONFIGS,
  DEFAULT_MEDIA_CONFIG,
  VIDEO_MEDIA_CONFIGS,
  VIDEO_MEDIA_PROFILE,
  IMAGE_MIME_TYPE,
  // SCREEN_SHARE_DEVICE,
  DEFAULT_ZOOM_LEVEL,
  DEFAULT_ZOOM_INFO,
} from 'UTILS/constants/MediaServerConstants';

// LS-Utils
import { eventLog, EVENT_RX, logFlags, LSDS } from './LSDSUtils';
import LSDSTelestration from '../Telestration/LSDSTelestration';
import { STREAM_SHARE_STATE, STREAM_SHARE_ACTION, STREAMER } from '../../utils/constants/UtilityConstants';

/* This timeout for handling bug#619 scenario where for a reason unknown, the
video share request on LSDS is not getting responded to by peer.
If the response is not received within the given timeout, the call will be terminated
with a feedback to user
*/
const IMAGE_SHARE_MAX_BYTES_OUTSTANDING = 24576; /* 6 * 4KB datastream packets */
const DEFAULT_VIDEO_PAUSE_STATE = false;
const logger = AppLogger(LOG_NAME.LSDS);
const DEFAULT_GPS_VALUE = '0';

class LSDatastreamProtocol {
  static onsight = null;

  static isHandshakeDone = false;

  static sipCallActions = null;

  static appCallback = null;

  changedVideoMediaConfig = {
    value: null,
  };

  static teleAPI = null;

  remoteParticipantCode = TELESTRATION_COLORS[0].colorCode;

  constructor() {
    this.onsight = new OnSight();
    LSDS.setup((event, evtLabel) => this.sendLSEvent(event, evtLabel));
  }

  initAPI(callbacks) {
    /* Making it explicit for clarity and reference , rather than
    absolute assignment to appCallback */
    this.appCallback = {
      endCall: callbacks.endCall,
      onStreamStateChange: callbacks.onStreamStateChange,
      onParticipantChange: callbacks.onParticipantChange,
      setRemotePid: callbacks.setRemotePid,
      onMuteStatusChange: callbacks.onMuteStatusChange,
      onMuteRequest: callbacks.onMuteRequest,
      onRemoteVideoRequest: callbacks.onRemoteVideoRequest,
      onRemoteVideoDeclineRequest: callbacks.onRemoteVideoDeclineRequest,
      onCallGuidUpdate: callbacks.onCallGuidUpdate,
      onSysVersionUpdate: callbacks.onSysVersionUpdate,
      onDelimitStream: callbacks.onDelimitStream,
      getCallIncoming: callbacks.getCallIncoming,
      onRemoteVideoSourceChangeRequest: callbacks.onRemoteVideoSourceChangeRequest,
      onStreamQualityChanged: callbacks.onStreamQualityChanged,
      onVideoConfigChanged: callbacks.onVideoConfigChanged,

      // Image handling
      captureImage: callbacks.captureImage,
      onStillImageShareModeStateChange: callbacks.onStillImageShareModeStateChange,
      onStillImageShareReceived: callbacks.onStillImageShareReceived,
      onReceiveImageTransferProgress: callbacks.onReceiveImageTransferProgress,
      onImageTransferCanceled: callbacks.onImageTransferCanceled,
      setImage: callbacks.setImage,
      onSendImageTransferProgress: callbacks.onSendImageTransferProgress,

      // Screen sharing
      stopScreenSharing: callbacks.stopScreenSharing,

      // Illumination
      onRemoteIllumForVideoStateChange: callbacks.onRemoteIllumForVideoStateChange,
      setIlluminationButtonState: callbacks.setIlluminationButtonState,
      // Image metadata
      processImageData: callbacks.processImageData,
      /* Add APP-LS Callbacks here */

      // zoom
      onReceivingZoomInfo: callbacks.onReceivingZoomInfo,
      onReceivingZoomLevel: callbacks.onReceivingZoomLevel,

      saveTSColor: callbacks.saveTSColor,
      updateRemoteParticipantColor: callbacks.updateRemoteParticipantColor,

      // pause video
      onReceivingVideoPause: callbacks.togglePauseVideo,

      // meeting id
      setMeetingId: callbacks.setMeetingId,
      getMeetingId: callbacks.getMeetingId,

      // GPS position
      eventGpsInfo: callbacks.eventGpsInfo,
    };
  }

  /**
   * Connects TelestrationHelper with LSDSTelestration through APIs
   * @param {*} callbacks Callbacks from TeleHelper to be used by LSDSTele
   * @returns API of functions being called from TeleHelper, LSDSBase etc
   */
  initTeleCallback(callbacks) {
    const dsBase = this;
    this.teleAPI = new LSDSTelestration(dsBase, callbacks).api;
    return this.teleAPI;
  }

  // SCT
  // This is will assign an localPId to onsight
  setLocalPid(localPid) {
    if (this.onsight) {
      this.onsight.localPid = localPid;
    }
  }

  lvtActionToStr(action) {
    return CommonUtility.mapName(LS_DATASTREAM_LIVE_VIDEO_TOKEN_ACTION, action);
  }

  lvtIntentToStr(action) {
    return CommonUtility.mapName(QUEUED_SHARE_ACTION, action);
  }

  sendLSEvent(event, evtLabel) {
    if (!this.sipCallActions) {
      logger.assert(this.sipCallActions, 'LSDatastream::not initialized', this);
    }
    this.sipCallActions.sendData({
      data: JSON.stringify(event),
      label: evtLabel,
      protocol: 'lsds',
      success: () => {
        /*
        logger.debug(
          ' Send LS-Data Success ::',
          response ? JSON.stringify(response) : '',
        );
        */
      },
      error: (error) => {
        logger.warn(
          ' Send LS-Data Error ::',
          JSON.stringify(error),
        );
        return false;
      },
    });
    return true;
  }

  /** Checks if given PId is that of local participant */
  isLocalPId = (pId) => pId === this.onsight.localPid;

  getLocalPId() {
    return this.onsight.localPid !==
      LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID
      ? this.onsight.localPid
      : LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID;
  }

  getRemotePId() {
    return this.onsight.remotePid !==
      LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID
      ? this.onsight.remotePid
      : LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID;
  }

  getActiveSharerPid() {
    return this.onsight.activeSharerPid !==
      LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID
      ? this.onsight.activeSharerPid
      : LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID;
  }

  getRemoteVersion() {
    return this.onsight.remoteVersion;
  }

  getCallGuid() {
    return this.onsight.callGuid;
  }

  getCurrentVideoConfig() {
    return this.onsight.currentMediaVideoConfig;
  }

  compareVersions(v1, v2) {
    const len = Math.min(v1.length, v2.length);
    for (let i = 0; i < len; i += 1) {
      if (v1[i] > v2[i]) return 1;
      if (v2[i] > v1[i]) return -1;
    }
    if (v1.length > v2.length) return 1;
    if (v2.length > v1.length) return -1;
    return 0;
  }

  isWebAppSupportedByRemoteParticipant() {
    const minAppVer = [11, 4, 8, 0];
    if (this.onsight.remoteVersion) {
      const remoteAppVer = [this.onsight.remoteVersion.appVer.major, this.onsight.remoteVersion.appVer.minor,
        this.onsight.remoteVersion.appVer.build, this.onsight.remoteVersion.appVer.rev];
      if (this.onsight.remoteVersion.appVer.product === 2) minAppVer[3] = 19237; // web app beta revision for windows
      return (this.compareVersions(remoteAppVer, minAppVer) >= 0);
    }
    return false;
  }

  /** TO BE DEPRECATED IN LIEU OF LSDSCommon */
  // It will return true if the logged in user is active video sharer
  isloggedInUserActiveVideoSharer() {
    return this.onsight.activeSharerPid === this.onsight.localPid
      && this.onsight.activeSharerPid !== LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID;
  }

  /** TO BE DEPRECATED IN LIEU OF LSDSCommon */
  isRemoteUserSharing() {
    return this.onsight.activeSharerPid !== this.onsight.localPid
      && this.onsight.activeSharerPid !== LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID;
  }

  isActiveSharerUnknown() {
    return (
      this.onsight.activeSharerPid &&
      this.onsight.activeSharerPid ===
      LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID
    );
  }

  whoIsStreaming() {
    if (this.isloggedInUserActiveVideoSharer()) return STREAMER.LOCAL;
    if (this.isRemoteUserSharing()) return STREAMER.REMOTE;
    return STREAMER.NONE;
  }

  getStreamState() {
    return this.onsight.streamState;
  }

  isStreamRequested() {
    return (
      this.onsight.streamState === STREAM_STATUS.STREAM_START_REQUESTED
    );
  }

  isStreamStopped() {
    return this.onsight.streamState === STREAM_STATUS.STREAM_STOPPED;
  }

  getStillImageShareState() {
    return this.onsight.stillImageShareState;
  }

  callTerminate = (error) => {
    this.appCallback.endCall(error);
  };

  /**
   *  Send decline video share request
   */
  declineRemoteVideoShareRequest = (pid) => {
    logger.debug('DeclineRemoteVideoShareRequest', this.onsight.remotePid, this.onsight.localPid);
    const requestData = {
      action: LS_DATASTREAM_LIVE_VIDEO_TOKEN_ACTION.LVT_RemoteRequestRejected,
      pid: this.onsight.localPid,
      requesterPid: pid,
    };
    LSDS.CfgCtrl.EventLiveVideoToken(requestData);
  }

  // request an image from remote
  /**
   * API from App
   * @param {number} quality quality of requested image
   * @returns
   */
  requestImageFromRemote(quality) {
    if (this.onsight.lastRecvImage) {
      logger.warn('Previous image not null, image capture in progress');
      return Promise.reject(new Error('^requestImageFromRemote: previous image capture not complete! ' + JSON.stringify(this.onsight.lastRecvImage)));
    }

    return new Promise((resolve, reject) => {
      this.imageCaptureRequest = {
        onStillImageRemoteCaptureComplete: (image) => {
          this.imageCaptureRequest = null;
          resolve(image);
        },
        onStillImageRemoteCaptureError: (error) => {
          if (this.onsight.cancelSendImage) {
            // If image transfer is canceled then there is no error
            // just resolve with empty data and handle at actual call
            this.onsight.lastRecvImage = null;
          } else {
            this.imageCaptureRequest = null;
            reject(new Error(error));
          }
        },
      };

      LSDS.CfgCtrl.StillImageRemoteCaptureRequest(
        {
          value: (1 << STILL_IMAGE_CAPTURE_REQUEST_MASK.REMOTE_SHIFT) | (STILL_IMAGE_CAPTURE_MODE.AUTO << STILL_IMAGE_CAPTURE_REQUEST_MASK.CAPTURE_MODE_SHIFT) | quality,
        },
      );
    });
  }

  // exit image share
  exitImageShare() {
    this.imageShareRequest = null;
    if (this.onsight.stillImageShareState !== STILL_IMAGE_SHARE_MODE_STATUS.STILL_IMAGE_SHARE_MODE_EXIT ||
        this.onsight.stillImageShareRequestState !== STILL_IMAGE_SHARE_REQUEST_STATUS.STILL_IMAGE_SHARE_REQUEST_COMPLETE) {
      logger.info('exitImageShare()', this.onsight);
      // make sure that the request is completed properly
      this.onStillImageShareRequestStatusChanged(STILL_IMAGE_SHARE_REQUEST_STATUS.STILL_IMAGE_SHARE_REQUEST_COMPLETE, null, true);

      // get out of share mode
      this.onStatusEventStillImageShareModeStateChanged(STILL_IMAGE_SHARE_MODE_STATUS.STILL_IMAGE_SHARE_MODE_EXIT);
    }
  }

  // cancel image transfer
  cancelImageTransfer(localRequest) {
    logger.info('cancelImageTransfer()', this.onsight, localRequest);
    if (localRequest) {
      // send cancel event to the other side
      LSDS.CfgCtrl.StillImageTransferCancel({ value: !this.onsight.cancelSendImage });
    }

    // cancel the transfer
    this.onsight.cancelSendImage = true;
    this.onsight.lastRecvImage = null;

    // get out of share mode
    this.exitImageShare();

    // reset the scheme sharer after cancelling
    if (this.isloggedInUserActiveVideoSharer()) {
      LSDS.CfgCtrl.EventLiveVideoToken(
        {
          action: LS_DATASTREAM_LIVE_VIDEO_TOKEN_ACTION.NSS_ShareAboutToStart,
          pid: LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID,
          requesterPid: LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID,
        },
      );
    }

    // inform the ui
    this.appCallback.onImageTransferCanceled();
  }

  // request image share
  requestImageShare(image) {
    logger.info('requestImageShare()', image, this.onsight);
    // steps:
    // 1. request share token with NSS_ShareRequest STILL_IMAGE intent
    // 2. Wait for action NSS_Accepted
    // 3. Send share mode state to STILL_IMAGE_SHARE_MODE_REQUESTED_REMOTE
    // 4. Send request state to STILL_IMAGE_SHARE_REQUEST_REQUESTED_REMOTE
    // 5. Wait for request state to STILL_IMAGE_SHARE_REQUEST_ACCEPTED_LOCAL
    // 6. Send image
    // 7. Wait for request state to STILL_IMAGE_SHARE_REQUEST_COMPLETE
    // 8. Send NSS_ShareAboutToStart
    return new Promise((resolve, reject) => {
      if (this.onsight.stillImageShareState === STILL_IMAGE_SHARE_MODE_STATUS.STILL_IMAGE_SHARE_MODE_EXIT &&
        this.onsight.stillImageShareRequestState === STILL_IMAGE_SHARE_REQUEST_STATUS.STILL_IMAGE_SHARE_REQUEST_COMPLETE) {
        this.imageShareRequest = {
          onShareIntentAccepted: () => {
            // our share intent was accepted,
            // request to enter still image share
            const dummyHash = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            this.onStatusEventStillImageShareModeStateChanged(STILL_IMAGE_SHARE_MODE_STATUS.STILL_IMAGE_SHARE_MODE_REQUESTED_REMOTE);
            this.onStillImageShareRequestStatusChanged(STILL_IMAGE_SHARE_REQUEST_STATUS.STILL_IMAGE_SHARE_REQUEST_REQUESTED_REMOTE, image.hash ?? dummyHash, true);
          },
          onShareIntentRejected: () => {
            this.imageShareRequest = null;
            reject(new Error('requestImageShare: still image shre intent rejected by remote'));
          },
          onShareModeExit: () => {
            this.imageShareRequest = null;
            reject(new Error('requestImageShare: exited share mode before completion'));
          },
          onRequestAccepted: (shouldSendImage) => {
            // our request to share an image is accepted
            if (!shouldSendImage) {
              // the remote side already has the image
              this.appCallback.onSendImageTransferProgress({ bytesReceived: image.data.length, fileSize: image.data.length });
            } else {
              // send the image
              this.sendImage(image, [this.onsight.remotePid], true);
            }
          },
          onRequestRejected: (state) => {
            reject(new Error('requestImageShare: share request rejected! ' + state));
          },
          onRequestComplete: () => {
            // image has been received on remote
            // send share about to start
            LSDS.CfgCtrl.EventLiveVideoToken(
              {
                action: LS_DATASTREAM_LIVE_VIDEO_TOKEN_ACTION.NSS_ShareAboutToStart,
                pid: LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID,
                requesterPid: LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID,
              },
            );
            this.imageShareRequest = null;
            resolve();
          },
          onStillImageTransferFailed: () => {
            this.imageShareRequest = null;
            reject(new Error('requestImageShare: still image transfer failed!'));
          },
        };

        // first step: request share token with STILL_IMAGE intent
        this.requestShareToken(QUEUED_SHARE_ACTION.STILL_IMAGE);
      } else {
        logger.warn('Expected state for stillImageShareState: (',
          `${STILL_IMAGE_SHARE_MODE_STATUS.STILL_IMAGE_SHARE_MODE_EXIT}) found in`,
          CommonUtility.mapName(STILL_IMAGE_SHARE_MODE_STATUS,
            this.onsight.stillImageShareState),
          `(${this.onsight.stillImageShareState})`);
        logger.warn('Expected state for stillImageShareRequestState: STILL_IMAGE_SHARE_REQUEST_COMPLETE (',
          `${STILL_IMAGE_SHARE_REQUEST_STATUS.STILL_IMAGE_SHARE_REQUEST_COMPLETE}) found in`,
          CommonUtility.mapName(STILL_IMAGE_SHARE_REQUEST_STATUS,
            this.onsight.stillImageShareRequestState),
          `(${this.onsight.stillImageShareRequestState})`);
        reject(new Error('requestImageShare: states are incorrect for image transfer!'));
      }
    });
  }

  // LS
  // On event recieved from ondata() callback to connect with LS Datastream
  onEventRecv(event, sipCallActions) {
    if (!this.sipCallActions && sipCallActions) {
      this.sipCallActions = sipCallActions;
      logger.log('SIP Call Plugin Actions initialized');
    }
    eventLog(EVENT_RX, event);
    switch (event.id) {
      case LS_DATASTREAM_EVENTS.ID_NETWORK_OPEN:
      case LS_DATASTREAM_EVENTS.ID_DSHEARTBEAT:
        if (
          event.id === LS_DATASTREAM_EVENTS.ID_NETWORK_OPEN &&
          event.severity === LS_DATASTREAM_EVENTSEVERITY.INFO
        ) {
          logger.info(
            'onEventRecv::Event received for handshake',
            JSON.stringify(event),
          );
          this.onsight.remotePid = event.pid;
          LSDS.setRemotePid(this.onsight.remotePid);
          this.makeHandshake(event);
        } else if (
          event.severity === LS_DATASTREAM_EVENTSEVERITY.ERROR
        ) {
          logger.warn(
            '::onEventRecv::failure for handshake, hanging up!',
            JSON.stringify(event),
          );
          this.callTerminate();
        }
        break;

      case LS_DATASTREAM_EVENTS.ID_CONFIGCTRL_RECV: {
        this.onCfgCtrlRecv(event.cfgctrl);
        break;
      }
      case LS_DATASTREAM_EVENTS.ID_TELEPOINT_RECV:
        if (window.dynamicEnv.REACT_APP_ALLOW_TELESTRATION === 'true') {
          this.teleAPI.onTelepointRecv(event.telepoint, {
            width: this.onsight?.currentMediaVideoConfig?.width,
            height: this.onsight?.currentMediaVideoConfig?.height,
          }, this.getStillImageShareState() === STILL_IMAGE_SHARE_MODE_STATUS.STILL_IMAGE_SHARE_MODE_REQUESTED_REMOTE
          || this.getStillImageShareState() === STILL_IMAGE_SHARE_MODE_STATUS.STILL_IMAGE_SHARE_MODE_ENTERED);
        }
        break;

      case LS_DATASTREAM_EVENTS.ID_STILL_IMAGE_RECV_PROGRESS:
        logger.debug('onImageRecv recv_progress ', event.file_progress);
        if (this.onsight.lastRecvImage || this.onsight.stillImageShareRequestState === STILL_IMAGE_SHARE_REQUEST_STATUS.STILL_IMAGE_SHARE_REQUEST_ACCEPTED_LOCAL) {
          this.appCallback.onReceiveImageTransferProgress(event.file_progress);
        }
        break;

      case LS_DATASTREAM_EVENTS.ID_STILL_IMAGE_SEND_PROGRESS:
        logger.debug('sendImage send_progress', event.file_progress);
        this.appCallback.onSendImageTransferProgress(event.file_progress);
        break;

      case LS_DATASTREAM_EVENTS.ID_STILL_IMAGE_RECV:
        this.onImageRecv(event);
        break;

      case LS_DATASTREAM_EVENTS.ID_STILL_IMAGE_SEND:
        break;

      case LS_DATASTREAM_EVENTS.ID_DATA_STREAM_TX_STATS:
      case LS_DATASTREAM_EVENTS.ID_DATA_STREAM_RX_STATS:
        // nothing to do here
        break;

      case LS_DATASTREAM_EVENTS.ID_NETWORK_CLOSE:
        logger.warn(
          'Got network close, Hanging up since other end closed abnormally',
        );
        this.callTerminate(true);
        break;

      case LS_DATASTREAM_EVENTS.ID_SESSION_HEALTH_UPDATE:
        logger.info(
          event.severity,
        );
        break;

      default:
        logger.warn(
          'onEventRecv::',
          'unknown event',
          event.id,
          event,
        );
        break;
    }
  }

  // Make handshake between participants by sharing capabilities and config
  makeHandshake = (dataStreamEvent) => {
    logger.debug(
      'Started handshake!::',
      dataStreamEvent,
      this.onsight,
    );

    this.onsight.remotePid = dataStreamEvent.pid;
    LSDS.setRemotePid(this.onsight.remotePid);
    logger.info(
      `Handshake started - PId exchanged:: Remote:${this.onsight.remotePid}`,
      `Local: ${this.onsight.localPid}`,
    );

    // send our capabilities
    LSDS.CfgCtrl.StatusEventSysCapabilities({
      values: this.onsight.capabilities,
    });

    // // send sys version
    LSDS.CfgCtrl.StatusEventSysVersion(
      this.onsight.sysVer,
    );

    // send meeting id
    if (this.appCallback?.getMeetingId()) {
      LSDS.CfgCtrl.SessEventMeetingIdUpdate(
        {
          meetingId: this.appCallback?.getMeetingId(),
        },
      );
    }

    // // // send our enc/dec limits
    LSDS.CfgCtrl.StatusEventEncLimits(this.onsight.encLimits);
    LSDS.CfgCtrl.StatusEventDecLimits(this.onsight.decLimits);

    // // // send initcomplete
    LSDS.CfgCtrl.SysEventInitComplete(
      {
        value: false,
      },
    );
    LSDS.CfgCtrl.SysEventInitComplete(
      {
        value: true,
      },
    );

    // send our video sources
    LSDS.CfgCtrl.EventVideoSourcesForParticipant(
      {
        currentSubSource: this.onsight.videoConfig.currentSubSource,
        pid: this.onsight.localPid,
        videoSources: {
          availableVideoSubSources:
            this.onsight.videoConfig.availableSubSources
              .availableVideoSubSources,
        },
      },
    );

    // // send our mic mute status
    LSDS.CfgCtrl.RemoteMicMuteStatusChanged(
      {
        mute: this.onsight.micMute,
        pid: this.onsight.localPid,
      },
    );

    // // send our telestration colour
    LSDS.CfgCtrl.CfgEventTelestrationColours(
      {
        colour: [
          {
            colourCode: this.appCallback?.saveTSColor() ? hexToArgb(this.appCallback?.saveTSColor()) : TELESTRATION_COLORS[0].colorCode,
            participantId: this.onsight.localPid,
          },
        ],
        flags: TELEPOINT_TYPE_FLAGS.TELEPOINT_TYPE_EXTENDED,
        pid: this.onsight.localPid,
      },
    );
    this.isHandshakeDone = true;
    logger.info('Handshake Completed!!',
      `RemotePID: ${this.getRemotePId()} LocalPId: ${this.getLocalPId()}`);

    this.appCallback.setRemotePid();
    this.appCallback.onStreamStateChange(STREAM_SHARE_STATE.READY);
  };

  // On config control recieved from LS Datastream
  async onCfgCtrlRecv(cfgctrl) {
    // This is existing code by LS team , will keep as it is
    switch (cfgctrl.type) {
      case LS_DATASTREAM_CORE_EVENTS.EventLiveVideoToken:
        await this.onLvtRecv(cfgctrl.data);
        break;

      case LS_DATASTREAM_CORE_EVENTS.StatusEventActiveMCDChange:
        // stream sharer has changed
        this.onsight.activeSharerPid = cfgctrl.data.pid;
        /* Update App on the change */
        // AD>FIXME
        this.appCallback.onStreamStateChange(STREAM_SHARE_STATE.SHARER_CHANGED);

        // clear all video and audio media these will be sent by the remote side
        this.onsight.videoMediaConfigs = [];
        this.onsight.audioMediaConfigs = [];
        this.checkQueuedShareAction(
          this.onsight.activeSharerPid ===
            LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID
            ? QUEUED_SHARE_ACTION_WAITING_FLAGS.ActiveMcdCleared
            : QUEUED_SHARE_ACTION_WAITING_FLAGS.None,
        );
        break;

      case LS_DATASTREAM_CORE_EVENTS.CfgEventMediaVideoConfigChanged:
        // Note: For custom profile, OC(as a viewer) first sends this event, and then
        // StatusEventStreamQuality event with value 3, hence storing
        // if (!this.isloggedInUserActiveVideoSharer()) {
        this.changedVideoMediaConfig.value = _.clone(cfgctrl.data);
        // }
        break;

      case LS_DATASTREAM_CORE_EVENTS.CfgEventMediaAudioConfigChanged:
        this.onsight.audioMediaConfigs.push(cfgctrl.data);
        break;

      case LS_DATASTREAM_CORE_EVENTS.StatusEventStreamQuality:
        // if we are the sharer, then remote side is
        //  requesting we change our media config(e.g.encoder parameters)
        logger.debug(
          'streamQualityChanged() ',
          this.changedVideoMediaConfig.value,
        );
        if (
          this.changedVideoMediaConfig.value &&
          cfgctrl.data.value === VIDEO_MEDIA_PROFILE.CUSTOM
        ) {
          this.appCallback.onVideoConfigChanged(this.changedVideoMediaConfig.value);
        } else if (
          cfgctrl.data.value !== VIDEO_MEDIA_PROFILE.CUSTOM &&
          this.onsight.streamQuality !== cfgctrl.data.value
        ) {
          logger.debug(
            'LSDatastreamProtocol::onCfgCtrlRecv() changing stream quality as per remote sharer requst to quality:',
            cfgctrl.data.value,
          );
          this.appCallback.onStreamQualityChanged(cfgctrl.data.value);
        }
        break;

      case LS_DATASTREAM_CORE_EVENTS.CfgEventCurrentMediaVideoConfigChanged:
        // if we are the sharer, then remote side is requesting we
        // change our media config(e.g.encoder parameters)
        // TODO
        // this.onsight.currentMediaVideoConfig = cfgctrl.data;
        if (!this.isloggedInUserActiveVideoSharer()) {
          this.changedVideoMediaConfig.value = cfgctrl.data;
          this.appCallback.onVideoConfigChanged(cfgctrl.data);
        }
        break;

      case LS_DATASTREAM_CORE_EVENTS.CfgEventCurrentMediaAudioConfigChanged:
        this.onsight.currentMediaAudioConfig = cfgctrl.data;
        break;

      case LS_DATASTREAM_CORE_EVENTS.CfgEventAudioConfigChanged:
        this.onsight.audioConfig = cfgctrl.data;
        break;

      case LS_DATASTREAM_CORE_EVENTS.CfgEventVideoConfigChanged:
        if (this.isloggedInUserActiveVideoSharer()) {
          // we are the sharer so remote side is requesting that we change our video config
          this.appCallback.onRemoteVideoSourceChangeRequest(cfgctrl.data.currentSubSource);
        } else {
          // someone else is the sharer, so this is just a video config update
          this.onsight.videoConfig = cfgctrl.data;
          this.appCallback.onRemoteVideoSourceChangeRequest(
            cfgctrl.data?.currentSubSource,
            cfgctrl.data?.availableSubSources?.availableVideoSubSources,
          );
        }
        break;

      case LS_DATASTREAM_CORE_EVENTS.CfgEventMediaInfoChanged:
        this.onsight.mediaInfo = cfgctrl.data;
        break;

      case LS_DATASTREAM_CORE_EVENTS.StatusEventStreamState:
        logger.log(
          'onCfgCtrlRecv::StatusEventStreamState',
          JSON.stringify(cfgctrl),
        );
        // NEW this.onStreamStateChanged(cfgctrl.data.value, cfgctrl.pids);
        if (this.onStreamStateChanged(cfgctrl.data.value, cfgctrl.pids)) {
          if (this.onsight.streamState === STREAM_STATUS.STREAM_STOP_REQUESTED) {
            if (this.isloggedInUserActiveVideoSharer()) {
              // stream state has changed to stop requested and we are the active sharer
              this.stopStream();
            } else if (this.isRemoteUserSharing()) {
              this.onStreamStateChanged(STREAM_STATUS.STREAM_STOPPED);
              this.appCallback.onStreamStateChange(STREAM_SHARE_STATE.STREAMING_STOPPED);
              this.onsight.onStreamStop();
            }
          } else if (this.onsight.streamState === STREAM_STATUS.STREAM_START_REQUESTED) {
            if (!this.isloggedInUserActiveVideoSharer()) {
              // someone else is sharing, immediately go to stream started locally
              this.onStreamStateChanged(STREAM_STATUS.STREAM_STARTED);
            }
          }
        }

        break;

      case LS_DATASTREAM_CORE_EVENTS.SessEventCallDisconnectMsg:
        /** AD>FIXME */
        this.sipCallActions.datastreamHangupMessageReceived(
          cfgctrl.data.ack,
        );
        break;

      case LS_DATASTREAM_CORE_EVENTS.StillImageCaptureMessage: {
        if (
          cfgctrl.data.message ===
          STILL_IMAGE_CAPTURE_REQUEST_MESSAGE.REQUEST_REMOTE_CAPTURE
        ) {
          // remote has requested that we take a picture
          // accept or reject depending if we are the active sharer
          cfgctrl.data.message =
            this.isloggedInUserActiveVideoSharer()
              ? STILL_IMAGE_CAPTURE_REQUEST_MESSAGE.ACCEPTED
              : STILL_IMAGE_CAPTURE_REQUEST_MESSAGE.REJECTED;
          LSDS.CfgCtrl.StillImageCaptureMessage(
            cfgctrl.data,
          );
        }
        break;
      }

      case LS_DATASTREAM_CORE_EVENTS.StillImageRemoteCaptureRequest: {
        logger.log('LS_DATASTREAM_CORE_EVENTS.StillImageRemoteCaptureRequest: ', LS_DATASTREAM_CORE_EVENTS.StillImageRemoteCaptureRequest);
        // remote has requested an image capture from us
        const remote = (cfgctrl.data.value >> STILL_IMAGE_CAPTURE_REQUEST_MASK.REMOTE_SHIFT) !== 0;
        const capMode = (cfgctrl.data.value & STILL_IMAGE_CAPTURE_REQUEST_MASK.CAPTURE_MODE_MASK) >> STILL_IMAGE_CAPTURE_REQUEST_MASK.CAPTURE_MODE_SHIFT; // always Auto
        const quality = cfgctrl.data.value & STILL_IMAGE_CAPTURE_REQUEST_MASK.QUALITY_MASK; // jpeg compression quality
        this.onStillImageRemoteCaptureRequest(cfgctrl.pids, remote, capMode, quality);
        break;
      }
      case LS_DATASTREAM_CORE_EVENTS.StatusEventStillImageCaptureState:
        logger.debug(
          'onCfgCtrlRecv:: ',
          moment(new Date().toISOString()).format('hh: mm: ss'),
          'lsds',
          'StatusEventStillImageCaptureState',
          CommonUtility.mapName(STILL_IMAGE_CAPTURE_STATUS, cfgctrl.data.value),
        );
        if (this.imageCaptureRequest && cfgctrl.data.value === STILL_IMAGE_CAPTURE_STATUS.STILL_IMAGE_CAPTURE_ERROR) {
          this.imageCaptureRequest.onStillImageRemoteCaptureError('still image capture failed');
        }
        break;

      case LS_DATASTREAM_CORE_EVENTS.StillImageTransferCancel:
        logger.debug('LS_DATASTREAM_CORE_EVENTS.StillImageTransferCancel: ', cfgctrl.data);
        this.cancelImageTransfer(false);
        break;

      case LS_DATASTREAM_CORE_EVENTS.StatusStillImageTransferProgress:
        if (!this.onsight.cancelSendImage || (cfgctrl.data.bytesReceived === cfgctrl.data.fileSize)) {
          logger.debug('sendImage transfer progress', cfgctrl.data);
          this.onsight.sendImageBytesAcknowledged = cfgctrl.data.bytesReceived;
          this.appCallback.onSendImageTransferProgress(cfgctrl.data);
        }
        break;

      case LS_DATASTREAM_CORE_EVENTS.StatusEventStillImageShareModeState:
        logger.log(
          'onCfgCtrlRecv:: ',
          'lsds',
          'StatusEventStillImageShareModeState',
          cfgctrl.data.value,
        );
        this.onStatusEventStillImageShareModeStateReceived(cfgctrl.data.value);
        break;

      case LS_DATASTREAM_CORE_EVENTS.StatusEventStillImageShareRequestState:
        logger.log(
          'onCfgCtrlRecv::',
          'lsds',
          'StatusEventStillImageShareRequestState',
          cfgctrl.data.values,
        );
        this.onStatusEventStillImageShareRequestStateReceived(cfgctrl.data.values);
        break;

      case LS_DATASTREAM_CORE_EVENTS.HostNegotiation:
        if (cfgctrl.data.value === HOST_NEGOTIATION.HOST_REQUEST) {
          // we are never a host, so just accept the request
          LSDS.CfgCtrl.HostNegotiation(
            {
              value: HOST_NEGOTIATION.HOST_REQUEST_ACCEPTED,
            },
          );
        }
        break;
      case LS_DATASTREAM_CORE_EVENTS.StatusEventParticipantChange:
        this.appCallback.onParticipantChange(cfgctrl);
        break;
      case LS_DATASTREAM_CORE_EVENTS.RemoteMicMuteRequest:
        this.appCallback.onMuteRequest(cfgctrl.data);
        break;
      case LS_DATASTREAM_CORE_EVENTS.RemoteMicMuteStatusChanged:
        this.appCallback.onMuteStatusChange(cfgctrl.data);
        break;
      case LS_DATASTREAM_CORE_EVENTS.SessEventBandwidthUpdate:
        if (
          cfgctrl.data.flags === BITRATE_INFO_FLAGS.RenegotiateRequest
        ) {
          // TODO: send the actual configured bitrate. For now, just echo the request back.
          cfgctrl.data.flags = 0;
          LSDS.CfgCtrl.SessEventBandwidthUpdate(
            cfgctrl.data,
          );
        }
        break;

      case LS_DATASTREAM_CORE_EVENTS.SessEventCallGuidUpdate:
        this.onsight.callGuid = cfgctrl.data.callGuid;
        this.appCallback.onCallGuidUpdate(this.onsight.callGuid);
        break;

      case LS_DATASTREAM_CORE_EVENTS.StatusEventSysVersion:
        this.onsight.remoteVersion = cfgctrl.data;
        if (!this.appCallback.getCallIncoming()) {
          // we are the caller, so send the other side our guid
          LSDS.CfgCtrl.SessEventCallGuidUpdate(
            {
              callGuid: this.onsight.callGuid,
              origCallGuid: '',
            },
          );
        }
        this.appCallback.onSysVersionUpdate(this.onsight.remoteVersion);
        break;

      case LS_DATASTREAM_CORE_EVENTS.SessEventMeetingIdUpdate:
        this.appCallback.setMeetingId(cfgctrl.data.meetingId);
        break;

      case LS_DATASTREAM_CORE_EVENTS.SysEventInitComplete:
      case LS_DATASTREAM_CORE_EVENTS.StatusEventCameraCapabilities:
      case LS_DATASTREAM_CORE_EVENTS.StatusEventSysCapabilities:
      case LS_DATASTREAM_CORE_EVENTS.EventVideoSourcesForParticipant:
        // OnChange of state in video source, stop screen sharing at local
        // Being reviewed --- this.appCallback.stopScreenSharing();
        break;
      case LS_DATASTREAM_CORE_EVENTS.StatusEventEncLimits:
      case LS_DATASTREAM_CORE_EVENTS.StatusEventDecLimits:
      case LS_DATASTREAM_CORE_EVENTS.StatusEventRemotePictureSharingModeChanged:
        break;
      case LS_DATASTREAM_CORE_EVENTS.StatusEventStreamFreeze:
        this.appCallback.onReceivingVideoPause(cfgctrl.data.value);
        break;
      case LS_DATASTREAM_CORE_EVENTS.StatusEventIllumState:
        this.onStatusEventIllumState(cfgctrl.data.value);
        break;
      case LS_DATASTREAM_CORE_EVENTS.StatusEventIllumLevel:
        logger.debug('lsds', 'StatusEventIllumLevel', cfgctrl.data.value);
        break;
      case LS_DATASTREAM_CORE_EVENTS.StatusEventIllumEnable:
        this.onStatusEventIllumEnable(cfgctrl.data.value);
        break;
      case LS_DATASTREAM_CORE_EVENTS.StatusEventIllumInfo:
        // this will come only once when the remote side starts streaming
        this.onStatusEventIllumInfo(cfgctrl.data);
        break;
      case LS_DATASTREAM_CORE_EVENTS.StatusEventVideoLinkInfo:
      case LS_DATASTREAM_CORE_EVENTS.EventVoiceAudioLost:
      case LS_DATASTREAM_CORE_EVENTS.CfgEventDefaultMediaVideoConfigChanged:
      case LS_DATASTREAM_CORE_EVENTS.CfgEventDefaultMediaAudioConfigChanged:
      case LS_DATASTREAM_CORE_EVENTS.StatusEventRemoteRecordState:
        break;
      case LS_DATASTREAM_CORE_EVENTS.CfgEventTelestrationColours:
        // Colour code of remote participant
        if (cfgctrl.data?.colour?.length > 0) {
          // eslint-disable-next-line array-callback-return
          cfgctrl.data.colour.map((c) => {
            if (c.participantId === this.getRemotePId()) {
              this.remoteParticipantCode = c.colourCode;
            }
          });
          this.appCallback.updateRemoteParticipantColor(this.remoteParticipantCode, cfgctrl.data.colour);
        }
        if (window.dynamicEnv.REACT_APP_ALLOW_TELESTRATION === 'true') {
          if (cfgctrl.data?.colour?.length > 0) {
            this.teleAPI.onColourChangeRecv(cfgctrl.data);
          }
        }
        break;
      case LS_DATASTREAM_CORE_EVENTS.StatusEventGpsInfo:
        this.appCallback.eventGpsInfo(cfgctrl.data,
          !Object.prototype.hasOwnProperty.call(cfgctrl, 'flags') || cfgctrl.flags !== DATAPACKET_FLAGS.DPF_SET);
        break;
      case LS_DATASTREAM_CORE_EVENTS.StatusEventRtcpRR:
      case LS_DATASTREAM_CORE_EVENTS.StatusEventRemoteVideoRxLinkInfo:
      case LS_DATASTREAM_CORE_EVENTS.StatusEventRemotePrivacyState:
      case LS_DATASTREAM_CORE_EVENTS.StatusEventStreamMute:
        // TODO support these later
        break;
      case LS_DATASTREAM_CORE_EVENTS.StatusEventZoomInfo:
        this.appCallback.onReceivingZoomInfo(cfgctrl.data);
        break;
      case LS_DATASTREAM_CORE_EVENTS.StatusEventZoomLevel:
        this.appCallback.onReceivingZoomLevel(cfgctrl.data.value);
        break;
      default:
        logger.warn(
          'onCfgCtrlRecv::',
          'lsds',
          'Cfgctrl event not handled',
          CommonUtility.mapName(LS_DATASTREAM_CORE_EVENTS, cfgctrl.type),
          '0x00' + cfgctrl.type.toString(16),
          cfgctrl,
        );
        break;
    }
  }

  // Live Video
  async onLvtRecv(request) {
    let response;
    const actionStr = this.lvtActionToStr(request.action);
    const intent = (request.pid >> 16);
    const pid = (request.pid & 0xffff);
    logger.info(
      'onLvtRecv::',
      `Got share token event [Action=>${actionStr}]`, this.lvtIntentToStr(intent), pid,
    );

    switch (request.action) {
      case LS_DATASTREAM_LIVE_VIDEO_TOKEN_ACTION.NSS_ShareRequest:
        response = {
          action: LS_DATASTREAM_LIVE_VIDEO_TOKEN_ACTION.NSS_Accepted,
          pid: LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID,
          requesterPid:
            LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID,
        };

        // accept the request
        LSDS.CfgCtrl.EventLiveVideoToken(
          response,
        );
        break;

      case LS_DATASTREAM_LIVE_VIDEO_TOKEN_ACTION.NSS_Accepted:
        logger.log('On LVT:: NSS_Accepted:: Onsight last intent request::', this.onsight.lastIntentRequest);
        switch (this.onsight.lastIntentRequest) {
          case QUEUED_SHARE_ACTION.LIVE_VIDEO:
            // request the live video token if the intent was to share live video
            response = {
              action: LS_DATASTREAM_LIVE_VIDEO_TOKEN_ACTION.LVT_RequestToken,
              pid: LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID,
              requesterPid: 0,
            };

            LSDS.CfgCtrl.EventLiveVideoToken(
              response,
            );
            break;

          case QUEUED_SHARE_ACTION.STILL_IMAGE:
            // tell the image requester that the still image share request was accepted
            if (this.imageShareRequest) {
              this.imageShareRequest.onShareIntentAccepted();
            }
            break;

          default:
            logger.error(
              'onLvtRecv:: ',
              `Received [Action=>${actionStr}] for unknown intent`, this.lvtIntentToStr(intent), pid,
            );
            break;
        }
        break;

      case LS_DATASTREAM_LIVE_VIDEO_TOKEN_ACTION.NSS_ShareAboutToStart:
        break;

      case LS_DATASTREAM_LIVE_VIDEO_TOKEN_ACTION.LVT_RequestToken: {
        // remote has requested the token, accept it
        response = {
          action: LS_DATASTREAM_LIVE_VIDEO_TOKEN_ACTION.LVT_Accepted,
          pid: LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID,
          requesterPid:
            LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID,
        };
        LSDS.CfgCtrl.EventLiveVideoToken(
          response,
        );
        break;
      }
      case LS_DATASTREAM_LIVE_VIDEO_TOKEN_ACTION.LVT_Accepted: {
        // we have been given the token, we can now start streaming
        await this.initiateStreamStart();

        // Send share about to start
        // this releases the token so that others can now start sharing
        response = {
          action: LS_DATASTREAM_LIVE_VIDEO_TOKEN_ACTION.NSS_ShareAboutToStart,
          pid: LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID,
          requesterPid:
            LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID,
        };
        LSDS.CfgCtrl.EventLiveVideoToken(
          response,
        );
        break;
      }
      case LS_DATASTREAM_LIVE_VIDEO_TOKEN_ACTION.LVT_RemoteRequest:
        if (this.onsight.stillImageShareState !== STILL_IMAGE_SHARE_MODE_STATUS.STILL_IMAGE_SHARE_MODE_EXIT) {
          // Exit image sharing
          this.exitImageShare();
        } else {
          // Handle remote video request from other user
          this.appCallback.onRemoteVideoRequest(request?.requesterPid);
          // Removing code of directly starting video stream if other participant request to share my video
          // Instead we are showing a consent dialog and if allowed then video stream will start.
        }
        break;

      case LS_DATASTREAM_LIVE_VIDEO_TOKEN_ACTION.NSS_Rejected:
      case LS_DATASTREAM_LIVE_VIDEO_TOKEN_ACTION.LVT_Rejected:
        // we've been rejected for whatever reason, cleanup streaming state
        logger.warn(
          'Video share rejected by host',
          request,
        );

        // cleanup
        this.onsight.lastIntentRequest = QUEUED_SHARE_ACTION.NONE;
        this.onsight.queuedShareAction = QUEUED_SHARE_ACTION.NONE;
        this.onsight.queuedShareActionWaiting =
          QUEUED_SHARE_ACTION_WAITING_FLAGS.None;
        this.stopStream();
        if (this.imageShareRequest) {
          this.imageShareRequest.onShareIntentRejected();
        }
        break;

      case LS_DATASTREAM_LIVE_VIDEO_TOKEN_ACTION.LVT_RemoteRequestRejected: {
        // Handled a case where user requested video share from remote participant
        // back to back by clicking on video icon from participant panel.
        // To handle multiple video share request same time at UI side
        if (request?.pid === this.onsight.activeSharerPid && this.onsight.streamState === STREAM_STATUS.STREAM_STARTED) {
          logger.warn('Streaming is already started from the requester', request.pid);
          return;
        }
        // show decline message to user on popup
        this.appCallback.onRemoteVideoDeclineRequest(request?.pid);
        break;
      }
      default:
        // Handle default case!
        break;
    }
  }

  // Video sharing
  // Request a token to share video either of host or from participant
  requestShareToken(intent) {
    // combine the intent and pid
    // the intent tells others what we are going to share
    logger.info(' Request share token,', intent);
    const intentAndPid =
      ((intent & 0xffff) << 16) | (this.onsight.localPid & 0xffff);
    const request = {
      action: LS_DATASTREAM_LIVE_VIDEO_TOKEN_ACTION.NSS_ShareRequest,
      pid: intentAndPid,
      requesterPid: LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID,
    };
    this.onsight.lastIntentRequest = intent;
    LSDS.CfgCtrl.EventLiveVideoToken(request);
  }

  requestRemoteStream(remotePid) {
    // To request other participant to start his video stream
    const request = {
      action: LS_DATASTREAM_LIVE_VIDEO_TOKEN_ACTION.LVT_RemoteRequest,
      pid: remotePid,
      requesterPid: this.onsight.localPid,
    };
    LSDS.CfgCtrl.EventLiveVideoToken(request);
  }

  /**
   * Handles changes to the stream state
   * @param {enum} newState New state
   * @param {list} pIds the Pids on which the state change applies,
   * if not provided it would mean for the local sharer
   * @returns none
   */
  onStreamStateChanged(newState, pIds = null) {
    if (this.onsight.streamState === newState) {
      /* No need to handle anything if already state change done */
      logger.info('No change in streamstate',
        CommonUtility.mapName(STREAM_STATUS, newState));
      return false;
    }
    const streamerId = pIds ? pIds[0] : this.getLocalPId();

    const streamStateStr = CommonUtility.mapName(
      STREAM_STATUS,
      newState,
    );
    logger.info('onStreamStateChanged: Updating stream state from',
      `${CommonUtility.mapName(STREAM_STATUS, this.onsight.streamState)}`,
      `to ${CommonUtility.mapName(STREAM_STATUS, newState)}`);

    /* Update local state */
    this.onsight.streamState = newState;

    switch (newState) {
      case STREAM_STATUS.STREAM_STOPPED:
        logger.info(' Stream stopped!!');
        this.appCallback.onDelimitStream(false, null);
        this.appCallback.onStreamStateChange(STREAM_SHARE_STATE.STREAMING_STOPPED);
        break;

      case STREAM_STATUS.STREAM_STARTED:
        logger.info(' Stream started!!');
        this.appCallback.onStreamStateChange(STREAM_SHARE_STATE.STREAMING_STARTED);
        this.appCallback.onDelimitStream(true, {
          sourcePid: streamerId,
          direction: this.isLocalPId(streamerId) ? 'Outgoing' : 'Incoming',
          video: this.onsight.currentMediaVideoConfig,
        });
        break;

      case STREAM_STATUS.STREAM_STOP_REQUESTED:
        logger.info(' Stream stop requested for pId:',
          this.isLocalPId(streamerId) ? 'Local-' : 'Remote-', streamerId);
        break;

      case STREAM_STATUS.STREAM_START_REQUESTED:
        logger.info(' Stream started for pId:',
          this.isLocalPId(streamerId) ? 'Local-' : 'Remote-', streamerId);
        break;

      case STREAM_STATUS.STREAM_ERROR:
        break;

      default:
        //  Need to take action if neccessary
        logger.warn(` Check new stream state: ${streamStateStr} (${newState})`);
        break;
    }
    if (this.isloggedInUserActiveVideoSharer()) {
      logger.info(
        'tell remote side of our stream state changes',
        CommonUtility.mapName(STREAM_STATUS, this.onsight.streamState),
      );
      LSDS.CfgCtrl.StatusEventStreamState({ value: this.onsight.streamState });
    }

    this.checkQueuedShareAction(
      this.onsight.streamState === STREAM_STATUS.STREAM_STOPPED
        ? QUEUED_SHARE_ACTION_WAITING_FLAGS.StreamStopped
        : QUEUED_SHARE_ACTION_WAITING_FLAGS.None,
    );
    return true;
  }

  setVideoAndAudioMediaConfigs(videoMediaConfigs, audioMediaConfigs) {
    this.onsight.videoMediaConfigs = videoMediaConfigs.map(
      ({ name, ...rest }) => ({ ...rest }),
    );
    this.onsight.audioMediaConfigs = _.clone(audioMediaConfigs);
  }

  notifyAboutSelfImageCapture(state) {
    LSDS.CfgCtrl.StatusEventStillImageCaptureState({ value: state });
  }

  notifyAboutStreamFreeze(state) {
    LSDS.CfgCtrl.StatusEventStreamFreeze({ value: state });
  }

  notifyAboutZoomLevelChange(level, direction) {
    LSDS.CfgCtrl.StatusEventZoomLevel({ value: parseInt(level * 10, 10), direction });
  }

  /**
   * Initiates start or stop local stream
   * @param {object} mediaConfig Provides media configuration along with
   * selected media devices from app if available
   * @param {*} streamShareState Optional arg providing the state app excepts
   * the stream to be in, currently only used for validating the states across
   * LSDS and App
   */
  streamButtonClicked(
    streamShareAction,
  ) {
    if ((streamShareAction === STREAM_SHARE_ACTION.STOP_SHARE) &&
        (this.onsight.streamState === STREAM_STATUS.STREAM_STOPPED)) {
      logger.warn(`streamButtonClicked::Mismatch detected in streaming states 
        Requested: ${CommonUtility.mapName(STREAM_SHARE_ACTION, streamShareAction)}
        Current state: ${CommonUtility.mapName(STREAM_STATUS, this.onsight.streamState)}`);
    }
    if (this.onsight.streamState === STREAM_STATUS.STREAM_STOPPED) {
      // start streaming locally
      if (streamShareAction !== STREAM_SHARE_ACTION.STOP_SHARE) {
        // We expect the stream share to be stopped if already running not other wise
        logger.info('streamButtonClicked::Initiating local streaming ...');
        this.requestShareToken(QUEUED_SHARE_ACTION.LIVE_VIDEO);
        /** Update STREAM_STATE */
        this.appCallback.onStreamStateChange(STREAM_SHARE_STATE.TOKEN_REQUESTED);
      } else {
        logger.warn('Not handled, streamAction', streamShareAction);
      }
    } else if (
      this.onsight.streamState === STREAM_STATUS.STREAM_STARTED &&
      !this.isloggedInUserActiveVideoSharer()
    ) {
      // Added this condition to resolve the issue of interchangible streaming between participants
      logger.info('streamButtonClicked::Stop remote streaming and start local stream');
      // someone else is streaming, we want to start streaming
      // after they've stopped, queue an action
      this.onsight.queuedShareAction = QUEUED_SHARE_ACTION.LIVE_VIDEO;
      this.onsight.queuedShareActionWaiting =
        QUEUED_SHARE_ACTION_WAITING_FLAGS.StreamStopped |
        QUEUED_SHARE_ACTION_WAITING_FLAGS.ActiveMcdCleared;
      this.stopStream();
    } else if (this.isloggedInUserActiveVideoSharer()) {
      logger.info('streamButtonClicked::Stopping local streaming');
      this.stopStream();
    } else {
      logger.warn('streamButtonCliecked::No action taken',
        'Current stream state', CommonUtility.mapName(STREAM_STATUS, this.onsight.streamState),
        'Active sharer PId', this.onsight.activeSharerPid);
    }
  }

  setAvailableVideoSubsources(videoDevices) {
    this.onsight.videoConfig.availableSubSources.availableVideoSubSources =
      _.clone(videoDevices);
  }

  setCurrentSubSource(sourceLabel) {
    // set current video currentSubSource
    logger.debug(`LSDatastreamProtocol::setCurrentSubSource() souce label:${sourceLabel}`);
    if (sourceLabel) {
      // if (sourceLabel !== SCREEN_SHARE_DEVICE.label) {
      this.onsight.videoConfig.currentSubSource = sourceLabel;
      // }
      /* @ayan - not sure the purpose of this - check with Ayan ?
      if (sourceLabel === SCREEN_SHARE_DEVICE.label &&
          !this.onsight.videoConfig.availableSubSources?.availableVideoSubSources?.includes(sourceLabel)) {
        // this.onsight.videoConfig.availableSubSources?.availableVideoSubSources?.push(sourceLabel);
        this.onsight.videoConfig.availableSubSources.availableVideoSubSources =
          this.onsight.videoConfig.availableSubSources.availableVideoSubSources.filter((label) => label !== '');
      } else if (sourceLabel !== SCREEN_SHARE_DEVICE.label &&
        this.onsight.videoConfig.availableSubSources?.availableVideoSubSources?.includes(SCREEN_SHARE_DEVICE.label)) {
        // const index = this.onsight.videoConfig.availableSubSources.availableVideoSubSources.indexOf(SCREEN_SHARE_DEVICE.label);
        // if (index > -1) {
        //   this.onsight.videoConfig.availableSubSources.availableVideoSubSources.splice(index, 1);
        // }
      }
      */
    }
  }

  /* Called by App to update current configuraion being used for video/audio */
  setCurrentMediaConfig(videoConfig, audioConfig) {
    this.setCurrentVideoMediaConfig(videoConfig);
    this.setCurrentAudioMediaConfig(
      audioConfig === null
        ? AUDIO_MEDIA_CONFIGS[
          videoConfig.streamQuality ===
          VIDEO_MEDIA_PROFILE.FROM_OTHER_PARTICIPANT
            ? VIDEO_MEDIA_PROFILE.CUSTOM
            : videoConfig.streamQuality
        ]
        : audioConfig,
    );
    this.setCurrentStreamQuality(videoConfig.streamQuality);
  }

  setCurrentVideoMediaConfig(videoMediaConfig = VIDEO_MEDIA_CONFIGS.at(DEFAULT_MEDIA_CONFIG.value)) {
    // Remove the 'name' property from the object and then send
    const { name, ...videoMediaProfileSelected } = videoMediaConfig;
    if (videoMediaProfileSelected?.streamQuality === VIDEO_MEDIA_PROFILE.FROM_OTHER_PARTICIPANT) {
      videoMediaProfileSelected.streamQuality = VIDEO_MEDIA_PROFILE.CUSTOM;
    }
    this.onsight.currentMediaVideoConfig = videoMediaProfileSelected;
  }

  setCurrentAudioMediaConfig(
    audioMediaConfig = AUDIO_MEDIA_CONFIGS[DEFAULT_MEDIA_CONFIG.value],
  ) {
    this.onsight.currentMediaAudioConfig = _.clone(audioMediaConfig);
  }

  setCurrentStreamQuality(streamQuality = DEFAULT_MEDIA_CONFIG.value) {
    this.onsight.streamQuality = _.clone(streamQuality);
    if (this.onsight.streamQuality === VIDEO_MEDIA_PROFILE.FROM_OTHER_PARTICIPANT) {
      this.onsight.streamQuality = VIDEO_MEDIA_PROFILE.CUSTOM;
    }
  }

  changeRemoteStreamQuality(streamQuality, videoMediaConfig) {
    logger.debug(
      'LSDatastreamProtocol::changeRemoteStreamQuality() streamQuality:',
      streamQuality,
    );
    this.setCurrentStreamQuality(streamQuality);
    const videoConfig = _.clone(videoMediaConfig);
    if (
      (streamQuality === VIDEO_MEDIA_PROFILE.FROM_OTHER_PARTICIPANT ||
        streamQuality === VIDEO_MEDIA_PROFILE.CUSTOM) &&
      videoMediaConfig
    ) {
      videoConfig.streamQuality = VIDEO_MEDIA_PROFILE.CUSTOM;
      LSDS.CfgCtrl.CfgEventMediaVideoConfigChanged(this.onsight.currentMediaVideoConfig);
    }
    this.sendStatusEventSreamQuality(videoConfig.streamQuality);
    this.changedVideoMediaConfig.value = videoConfig;
  }

  sendStatusEventSreamQuality(streamQuality) {
    if (streamQuality === VIDEO_MEDIA_PROFILE.FROM_OTHER_PARTICIPANT) {
      streamQuality = VIDEO_MEDIA_PROFILE.CUSTOM;
    }
    LSDS.CfgCtrl.StatusEventStreamQuality({ value: streamQuality });
  }

  changeRemoteVideoSource(device) {
    this.setCurrentSubSource(device);
    LSDS.CfgCtrl.CfgEventVideoConfigChanged(this.onsight.videoConfig);
  }

  sendEventCurrentMediaVideoConfigChanged(videoMediaConfig) {
    const videoConfig = _.clone(videoMediaConfig);
    if (
      videoMediaConfig?.streamQuality ===
      VIDEO_MEDIA_PROFILE.FROM_OTHER_PARTICIPANT
    ) {
      videoConfig.streamQuality = VIDEO_MEDIA_PROFILE.CUSTOM;
    }
    LSDS.CfgCtrl.CfgEventCurrentMediaVideoConfigChanged(videoConfig);
  }

  /**
   * Send LSDS control messages to remote party
   * Sets stream quality
   * Note: It uses the currentMediaVideoConfig and currentMediaAudioConfig
   * @param {MediaFlags} changedMedia media that is changed
   * @param streamQuality Current stream quality
   */
  async notifyAboutChangeInMediaConfig(
    changedMedia,
    streamQuality = DEFAULT_MEDIA_CONFIG.value,
  ) {
    logger.debug(
      'LSDatastreamProtocol::notifyAboutChangeInMediaConfig() media changed:',
      JSON.stringify(changedMedia),
      streamQuality,
    );
    // request stop stream
    // this.notifySoftStop();

    // TODO: this sleep hides a timing issue with the remote processing the previous stop request
    // and videoConfigChanged in the wrong order
    await this.sleep(1000);

    // send our video sources
    LSDS.CfgCtrl.EventVideoSourcesForParticipant(
      {
        currentSubSource: this.onsight.videoConfig.currentSubSource,
        pid: this.onsight.localPid,
        videoSources: {
          availableVideoSubSources:
            this.onsight.videoConfig.availableSubSources
              .availableVideoSubSources,
        },
      },
    );

    this.setCurrentStreamQuality(streamQuality);

    // this.notifySoftStart(changedMedia);

    // send current video Media config
    if (changedMedia.video) {
      this.sendEventCurrentMediaVideoConfigChanged(
        this.onsight.currentMediaVideoConfig,
      );
    }

    this.changedVideoMediaConfig.value = _.clone(
      this.onsight.currentMediaVideoConfig,
    );

    // send current audio media config
    if (changedMedia.audio) {
      LSDS.CfgCtrl.CfgEventCurrentMediaAudioConfigChanged(this.onsight.currentMediaAudioConfig);
    }

    // send current stream quality
    LSDS.CfgCtrl.StatusEventStreamQuality({ value: this.onsight.streamQuality });
  }

  notifySoftStop() {
    logger.info('notifySoftStop');
    // request stop stream
    LSDS.CfgCtrl.StatusEventStreamState(
      {
        value: STREAM_STATUS.STREAM_STOP_REQUESTED,
      },
    );

    // stopped
    LSDS.CfgCtrl.StatusEventStreamState({ value: STREAM_STATUS.STREAM_STOPPED });

    this.appCallback.onDelimitStream(false, null);
  }

  // EventVideoSourcesForParticipant: state 2
  // CfgEventCurrentMediaVideoConfigChanged
  // CfgEventCurrentMediaAudioConfigChanged
  // StatusEventStreamState : state 3
  // EventLiveVideoToken: action 10
  // CfgEventTelestrationColours
  notifySoftStart(changedMedia = { audio: true, video: true }) {
    // start request
    LSDS.CfgCtrl.StatusEventStreamState({ value: STREAM_STATUS.STREAM_START_REQUESTED });

    // send current video Media config
    if (changedMedia.video) {
      this.sendEventCurrentMediaVideoConfigChanged(
        this.onsight.currentMediaVideoConfig,
      );
    }

    this.changedVideoMediaConfig.value = _.clone(
      this.onsight.currentMediaVideoConfig,
    );

    // send current audio media config
    if (changedMedia.audio) {
      LSDS.CfgCtrl.CfgEventCurrentMediaAudioConfigChanged(this.onsight.currentMediaAudioConfig);
    }

    // send current stream quality
    LSDS.CfgCtrl.StatusEventStreamQuality({ value: this.onsight.streamQuality });

    // start request
    LSDS.CfgCtrl.StatusEventStreamState({ value: STREAM_STATUS.STREAM_START_REQUESTED });

    // started
    LSDS.CfgCtrl.StatusEventStreamState({ value: STREAM_STATUS.STREAM_STARTED });

    // send illum stuff because of #2321
    this.sendIllumEventsAtVideoStreamStart();

    // send our video sources
    LSDS.CfgCtrl.EventVideoSourcesForParticipant(
      {
        currentSubSource: this.onsight.videoConfig.currentSubSource,
        pid: this.onsight.localPid,
        videoSources: {
          availableVideoSubSources:
            this.onsight.videoConfig.availableSubSources
              .availableVideoSubSources,
        },
      },
    );

    // Notify that share about to start
    const request = {
      action: LS_DATASTREAM_LIVE_VIDEO_TOKEN_ACTION.NSS_ShareAboutToStart,
      pid: LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID,
      requesterPid: LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID,
    };

    LSDS.CfgCtrl.EventLiveVideoToken(request);

    // send a telepoint with the stream started flag
    // this is required for the onsight endpoint to declare streaming has started
    // this.sendTelepoint({
    this.teleAPI.onStreamStarted({ color: this.onsight.teleColor,
      localPid: this.onsight.localPid });

    this.appCallback.onDelimitStream(true, {
      sourcePid: this.onsight.localPid,
      direction: 'Outgoing',
      video: this.onsight.currentMediaVideoConfig,
    });
  }

  notifyAboutChangeInCurrentMediaVideoSource() {
    logger.debug('LSDataStreamProtocol::notifyAboutChangeInCurrentMediaVideoSource()');
    // Unfreeze the video
    LSDS.CfgCtrl.StatusEventStreamFreeze({ value: DEFAULT_VIDEO_PAUSE_STATE });
    // CfgEventVideoConfigChanged
    LSDS.CfgCtrl.CfgEventVideoConfigChanged(this.onsight.videoConfig);
    // StatusEventEncLimits
    // send our codec enc limits
    LSDS.CfgCtrl.StatusEventEncLimits(this.onsight.encLimits);

    // EventVideoSourcesForParticipant
    this.notifyAboutChangeInVideoSources();

    // StatusEventStreamState: state 1
    // StatusEventStreamState : state 0
    this.notifySoftStop();

    // EventVideoSourcesForParticipant
    LSDS.CfgCtrl.EventVideoSourcesForParticipant(
      {
        currentSubSource: this.onsight.videoConfig.currentSubSource,
        pid: this.onsight.localPid,
        videoSources:
        {
          availableVideoSubSources: this.onsight.videoConfig
            .availableSubSources.availableVideoSubSources,
        },
      },
    );

    this.notifySoftStart();
  }

  notifyAboutChangeInVideoSources() {
    logger.debug('LSDataStreamProtocol::notifyAboutChangeInVideoSources()');

    // EventVideoSourcesForParticipant
    LSDS.CfgCtrl.EventVideoSourcesForParticipant(
      {
        currentSubSource: this.onsight.videoConfig.currentSubSource,
        pid: this.onsight.localPid,
        videoSources:
        {
          availableVideoSubSources: this.onsight.videoConfig
            .availableSubSources.availableVideoSubSources,
        },
      },
    );

    // CfgEventVideoConfigChanged
    LSDS.CfgCtrl.CfgEventVideoConfigChanged(this.onsight.videoConfig);
  }

  /**
   * Performs necessary handling for stopping stream
   * Inform app of the same
   * @returns none
   */
  async stopStream() {
    if (this.onsight.streamState === STREAM_STATUS.STREAM_STOPPED) {
      // MERGE CHECK
      logger.info(
        'lsds',
        'stopStream',
        'Stream already stopping or stopped, ignoring',
        this.onsight,
      );
      return;
    }

    /* Update stream state if required */
    this.onStreamStateChanged(STREAM_STATUS.STREAM_STOP_REQUESTED);
    /* Notify app to stop the streaming */
    this.appCallback.onStreamStateChange(STREAM_SHARE_STATE.STOP_SHARE);

    // is the remote side streaming?
    const remote = this.isRemoteUserSharing();
    if (remote) {
      // request the other side stop streaming
      LSDS.CfgCtrl.StatusEventStreamState({ value: STREAM_STATUS.STREAM_STOP_REQUESTED });
    } else {
      // we're streaming, stop locally
      // send active sharer change informing all that there is no one sharing anymore
      this.onsight.activeSharerPid =
        LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID;
      LSDS.CfgCtrl.StatusEventActiveMCDChange(
        {
          caps: [],
          pid: this.onsight.activeSharerPid,
        },
      );
    }

    // reset streaming state
    this.onStreamStateChanged(STREAM_STATUS.STREAM_STOPPED);
    this.onsight.onStreamStop();
    logger.info('streaming stopped!');
  }

  /**
   * Initiates stream share start flow for local/remote stream
   * @returns none
   */
  async initiateStreamStart() {
    if (this.onsight.streamState === STREAM_STATUS.STREAM_STARTED) {
      logger.warn(
        '',
        'startStream',
        'Stream already starting or started, ignoring',
        this.onsight,
      );
      return;
    }
    this.onsight.activeSharerPid = this.onsight.localPid;
    this.appCallback.onStreamStateChange(STREAM_SHARE_STATE.PREPARE_TO_SHARE);
  }

  /**
   * Aborting stream share start on account of error
   */
  abortStreamStart() {
    logger.info('Local stream start aborted from app');
    this.onsight.activeSharerPid =
      LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID;
    this.appCallback.onStreamStateChange(STREAM_SHARE_STATE.READY);
  }

  setIllumInfo(illumInfo) {
    if (!illumInfo) {
      return;
    }
    this.onsight.illumInfo = illumInfo;
    this.onsight.activeSharerIllumInfo = illumInfo;
    console.debug('LSDatastreamProtocol::setIllumInfo() illuminfo:', illumInfo);
  }

  async sendIllumEventsAtVideoStreamStart() {
    // illum stuff
    logger.debug('LSDatastreamProtocol::sendIllumEventsAtVideoStreamStart() current illumInfo:', this.onsight.illumInfo);
    LSDS.sendCfgCtrl(LS_DATASTREAM_CORE_EVENTS.StatusEventIllumInfo, [this.onsight.remotePid], this.onsight.illumInfo);
    LSDS.sendCfgCtrl(LS_DATASTREAM_CORE_EVENTS.StatusEventIllumState, [this.onsight.remotePid], { value: this.onsight.illumState });
    LSDS.sendCfgCtrl(LS_DATASTREAM_CORE_EVENTS.StatusEventIllumLevel, [this.onsight.remotePid], { value: 1 }); // not supported, just set to 1
    LSDS.sendCfgCtrl(LS_DATASTREAM_CORE_EVENTS.StatusEventIllumEnable, [this.onsight.remotePid], { value: 1 }); // not supported, just set to 1
  }

  async startStream() {
    // callback done
    // we're starting share
    logger.info('About to start streaming...');

    // send active sharer change, this is to inform all endpoints that we will be the sharer
    // this could mean we are sharing live video, images, recordings, etc
    LSDS.CfgCtrl.StatusEventActiveMCDChange(
      {
        caps: this.onsight.capabilities,
        pid: this.onsight.activeSharerPid,
      },
    );

    // TODO: this sleep hides a timing issue with the remote processing activeMCD
    // and videoConfigChanged in the wrong order
    await this.sleep(1000);

    // send initcomplete false, this tells the remote end that a bunch of status is coming
    LSDS.CfgCtrl.SysEventInitComplete({ value: false });

    // send current stream quality
    this.sendStatusEventSreamQuality(this.onsight.streamQuality);

    // send zoom info
    LSDS.CfgCtrl.StatusEventZoomInfo(DEFAULT_ZOOM_INFO);

    // send zoom level
    LSDS.CfgCtrl.StatusEventZoomLevel({ value: DEFAULT_ZOOM_LEVEL * 10, direction: EZoomDirection.ZoomUnchanged });

    // send pause video state
    LSDS.CfgCtrl.StatusEventStreamFreeze({ value: DEFAULT_VIDEO_PAUSE_STATE });

    // send illum stuff
    this.sendIllumEventsAtVideoStreamStart();

    // send media video and audio configs
    // this informs all endpoint of the valid media configurations

    // send all available media video configurations
    for (const vmc of this.onsight.videoMediaConfigs) {
      if (vmc.streamQuality === VIDEO_MEDIA_PROFILE.FROM_OTHER_PARTICIPANT ||
          vmc.streamQuality === VIDEO_MEDIA_PROFILE.CUSTOM) {
      // eslint-disable-next-line no-continue
        continue;
      }
      LSDS.CfgCtrl.CfgEventMediaVideoConfigChanged(vmc);
    }
    if (this.onsight.currentMediaVideoConfig.streamQuality === VIDEO_MEDIA_PROFILE.FROM_OTHER_PARTICIPANT ||
        this.onsight.currentMediaVideoConfig.streamQuality === VIDEO_MEDIA_PROFILE.CUSTOM) {
      const videoConfig = _.clone(this.onsight.currentMediaVideoConfig);
      videoConfig.streamQuality = VIDEO_MEDIA_PROFILE.CUSTOM;
      LSDS.CfgCtrl.CfgEventMediaVideoConfigChanged(videoConfig);
    }

    // set the current video and media configs
    this.sendEventCurrentMediaVideoConfigChanged(this.onsight.currentMediaVideoConfig);
    LSDS.CfgCtrl.CfgEventVideoConfigChanged(this.onsight.videoConfig);

    // send all available media audio configurations
    for (const amc of this.onsight.audioMediaConfigs) {
      LSDS.CfgCtrl.CfgEventMediaAudioConfigChanged(amc);
    }
    // set the current audio and media configs
    LSDS.CfgCtrl.CfgEventCurrentMediaAudioConfigChanged(this.onsight.currentMediaAudioConfig);
    LSDS.CfgCtrl.CfgEventAudioConfigChanged(this.onsight.audioConfig);

    // send media info
    LSDS.CfgCtrl.CfgEventMediaInfoChanged(this.onsight.mediaInfo);

    // send our codec enc/dec limits
    LSDS.CfgCtrl.StatusEventEncLimits(this.onsight.encLimits);
    LSDS.CfgCtrl.StatusEventDecLimits(this.onsight.decLimits);

    // send our capture state
    LSDS.CfgCtrl.StatusEventStillImageCaptureState(
      {
        value: STILL_IMAGE_CAPTURE_STATUS.STILL_IMAGE_CAPTURE_COMPLETE,
      },
    );

    // send initcomplete true, this tells the remote end that a bunch of status has been sent
    LSDS.CfgCtrl.SysEventInitComplete({ value: true });

    this.onStreamStateChanged(STREAM_STATUS.STREAM_START_REQUESTED);

    // TODO: this sleep hides a timing issue with the remote processing streamStartRequest
    // and the telepoint in the wrong order
    await this.sleep(1000);

    // send a telepoint with the stream started flag
    // this is required for the onsight endpoint to declare streaming has started
    this.teleAPI.onStreamStarted({ color: this.onsight.teleColor,
      localPid: this.onsight.localPid });

    // send our video sources
    LSDS.CfgCtrl.EventVideoSourcesForParticipant(
      {
        currentSubSource: this.onsight.videoConfig.currentSubSource,
        pid: this.onsight.localPid,
        videoSources: {
          availableVideoSubSources:
          this.onsight.videoConfig.availableSubSources
            .availableVideoSubSources,
        },
      },
    );

    this.onStreamStateChanged(STREAM_STATUS.STREAM_STARTED);

    logger.log(' Started local streaming');
  }

  checkQueuedShareAction(clearFlags) {
    logger.debug(
      'LSDATASTREAM checkQueuedShareAction: ',
      clearFlags,
      this.onsight.queuedShareAction,
    );
    if (this.onsight.queuedShareAction !== QUEUED_SHARE_ACTION.NONE) {
      logger.info(
        'LSDATASTREAM lsds',
        'checkQueuedShareAction',
        clearFlags,
        logFlags(QUEUED_SHARE_ACTION_WAITING_FLAGS, this.onsight.queuedShareActionWaiting),
        CommonUtility.mapName(QUEUED_SHARE_ACTION, this.onsight.queuedShareAction),
        this.onsight,
      );
      this.onsight.queuedShareActionWaiting &= ~clearFlags;
      if (
        this.onsight.queuedShareActionWaiting ===
        QUEUED_SHARE_ACTION_WAITING_FLAGS.None
      ) {
        // not waiting for any more states
        switch (this.onsight.queuedShareAction) {
          case QUEUED_SHARE_ACTION.STILL_IMAGE:
          case QUEUED_SHARE_ACTION.PLAYBACK_VIDEO:
            break;
          case QUEUED_SHARE_ACTION.LIVE_VIDEO:
            this.requestShareToken(this.onsight.queuedShareAction);
            break;
          default:
            logger.error(
              'LSDATASTREAM lsds',
              'checkQueuedShareAction unknown queued shared action',
              this.onsight,
            );
            break;
        }

        this.onsight.queuedShareAction = QUEUED_SHARE_ACTION.NONE;
      }
    }
  }

  /**
   * Handles image capture from remote while local is sharing
   * @param {[number]} pids List of pids
   * @param {*} remote
   * @param {*} capMode
   * @param {*} quality
   */
  onStillImageRemoteCaptureRequest(pids, remote, capMode, quality) {
    logger.log('lsds', 'image', 'onStillImageRemoteCaptureRequest', remote, capMode, quality, this.onsight);

    // tell remote we're starting a still image capture
    LSDS.sendCfgCtrl(LS_DATASTREAM_CORE_EVENTS.StatusEventStillImageCaptureState, pids, {
      value: STILL_IMAGE_CAPTURE_STATUS.STILL_IMAGE_CAPTURE_REQUESTED_LOCAL,
    });

    // - capMode is always Auto, so this can be ignored
    // - quality is the jpeg compression quality, so when saving, use this value if possible
    logger.assert(this.isloggedInUserActiveVideoSharer(), 'Error! We are currently not sharing');

    this.appCallback.captureImage(true, (options) => {
      const image = options.imageData;
      if (!image) {
        logger.error('lsds', 'image', 'onStillImageRemoteCaptureRequest failed with error:',
          options.error,
          this.onsight);

        // tell remote our capture failed for any reason
        LSDS.sendCfgCtrl(LS_DATASTREAM_CORE_EVENTS.StatusEventStillImageCaptureState, pids, {
          value: STILL_IMAGE_CAPTURE_STATUS.STILL_IMAGE_CAPTURE_ERROR,
        });
        return;
      }
      // tell remote our capture was successful
      LSDS.sendCfgCtrl(LS_DATASTREAM_CORE_EVENTS.StatusEventStillImageCaptureState, pids, {
        value: STILL_IMAGE_CAPTURE_STATUS.STILL_IMAGE_CAPTURE_COMPLETE,
      });
      // send the image to remote if requested by remote
      if (remote) {
        this.sendImage(image, pids, false);
      }
    });
  }

  async sendImage(image, pids, share) {
    logger.log('lsds', 'image', 'sendImage', image, this.onsight);

    let bytesLeft = image.data.length;
    this.onsight.cancelSendImage = false;
    this.onsight.sendImageBytesAcknowledged = 0;

    if (bytesLeft) {
      while (bytesLeft && !this.onsight.cancelSendImage) {
        // send 8K bytes at a time, data channel seems to have a limit, so send the data in chunks
        const sendBytes = Math.min(8 * 1024, bytesLeft);

        // convert bytes to base64
        const start = image.data.length - bytesLeft;
        const base64 = Buffer.from(image.data.slice(start, start + sendBytes)).toString('base64');

        // send image chunk to lsds
        const payload = {
          name: image.name,
          flags: FILE_TRANSFER_FLAG.FILE_TRANSFER_FLAG_RAM_BUFFER,
          fileSize: image.data.length,
          data: base64,
          pids,
        };

        if (share) {
          payload.flags |= FILE_TRANSFER_FLAG.FILE_TRANSFER_FLAG_SHARE;
        }

        if (bytesLeft === image.data.length) {
          payload.flags |= FILE_TRANSFER_FLAG.FILE_TRANSFER_FLAG_FIRST;
        }

        bytesLeft -= sendBytes;
        if (bytesLeft === 0) {
          payload.flags |= FILE_TRANSFER_FLAG.FILE_TRANSFER_FLAG_LAST;
        }
        payload.bytesLeft = bytesLeft;

        logger.debug('lsds', 'image', 'sendImage', payload);
        /** AD>FIXME */
        this.sipCallActions.sendData({ data: JSON.stringify(payload), label: 'image', protocol: 'lsds' });

        // wait for progress
        while (((image.data.length - bytesLeft) - IMAGE_SHARE_MAX_BYTES_OUTSTANDING) > this.onsight.sendImageBytesAcknowledged && !this.onsight.cancelSendImage) {
          /* eslint-disable no-await-in-loop */
          await this.sleep(10);
          /* eslint-enable no-await-in-loop */
        }
      }
    } else {
      logger.error('lsds', 'image', 'sendImage called with 0 bytes');
    }

    // reset the cancelSendImage flag (indicates a send is no longer in progress)
    this.onsight.cancelSendImage = true;
  }

  onImageRecv(event) {
    if (event.severity === LS_DATASTREAM_EVENTSEVERITY.INFO) {
      logger.debug('lsds', 'image', 'onImageRecv', event.image.name, event.image.fileSize,
        event.image.source);

      const sharing = event.image.flags & FILE_TRANSFER_FLAG.FILE_TRANSFER_FLAG_SHARE;
      if (!this.onsight.lastRecvImage || this.onsight.lastRecvImage.name === event.image.name) {
        // start a new image if this is the first chunk
        if (!this.onsight.lastRecvImage && event.image.flags & FILE_TRANSFER_FLAG.FILE_TRANSFER_FLAG_FIRST) {
          this.onsight.lastRecvImage = {
            name: null,
            complete: false,
            data: [],
          };
          this.onsight.lastRecvImage.name = event.image.name;
          logger.info('Starting to receive image', this.onsight.lastRecvImage.name);
        }

        // this might be a leftover chunk from a previously cancelled image, just ignore it
        if (!this.onsight.lastRecvImage) {
          return;
        }

        // convert the base64 to a byte array
        const bc = atob(event.image.data);
        const bn = new Array(bc.length);
        for (let i = 0; i < bc.length; i += 1) {
          bn[i] = bc.charCodeAt(i);
        }
        logger.debug('lsds', 'image', 'onImageRecv data length', bc.length);
        this.onsight.lastRecvImage.data.push(new Uint8Array(bn));
        this.onsight.lastRecvImage.complete = event.image.flags & FILE_TRANSFER_FLAG.FILE_TRANSFER_FLAG_LAST;
        if (this.onsight.lastRecvImage.complete) {
          logger.log('lsds', 'image', 'onImageRecv image complete', event.image.name, this.onsight);

          // tell remote that we've received the image
          LSDS.sendCfgCtrl(sharing ? LS_DATASTREAM_CORE_EVENTS.StillImageShareRecvOk : LS_DATASTREAM_CORE_EVENTS.StillImageRecvOk, [this.onsight.remotePid], null);

          logger.log('lsds', 'image', 'onImageRecv start processing');
          // combine the data arrays into one
          let length = 0;
          this.onsight.lastRecvImage.data.forEach((item) => {
            length += item.length;
          });

          // create a new array with total length and merge all source arrays.
          const mergedArray = new Uint8Array(length);
          let offset = 0;
          this.onsight.lastRecvImage.data.forEach((item) => {
            mergedArray.set(item, offset);
            offset += item.length;
          });

          this.onsight.lastRecvImage.data = mergedArray;
          this.appCallback.setImage(this.onsight.lastRecvImage.data, sharing);
          const self = this;
          const postExifLoad = () => {
            if (sharing) {
              self.appCallback.onStillImageShareReceived(self.onsight.lastRecvImage);
              self.onStillImageShareRequestStatusChanged(STILL_IMAGE_SHARE_REQUEST_STATUS.STILL_IMAGE_SHARE_REQUEST_COMPLETE, null, true);
            }

            // tell whoever requested this image
            if (self.imageCaptureRequest) {
              self.imageCaptureRequest.onStillImageRemoteCaptureComplete(self.onsight.lastRecvImage);
            }
            self.onsight.lastRecvImage = null;
          };

          // read the exif tags
          const exif = new LsExif();
          exif.onLoadCallback = () => {
            self.onsight.lastRecvImage.hash = Array.from(exif.generateHash());
            postExifLoad();
            // Process image meta data to display image telestrations if present
            self.appCallback.processImageData(exif);
          };
          exif.onErrorCallback = () => {
            logger.error('lsds', 'image', 'onImageRecv error reading exif tags');
            postExifLoad();
          };

          // TODO return telestration colour for participant
          // eslint-disable-next-line no-unused-vars
          exif.load(new Blob([this.onsight.lastRecvImage.data], { type: IMAGE_MIME_TYPE.JPEG }), (_pid) => '#FF8080');
        }
      } else {
        const error = 'new image received while previous was incomplete this should never happen';
        logger.error('lsds', 'image', 'onImageRecv', error, event.image, this.onsight);
        this.onsight.lastRecvImage = null;

        // tell whoever requested this image
        if (this.imageCaptureRequest) {
          this.imageCaptureRequest.onStillImageRemoteCaptureError(error);
        }
      }
    } else if (event.severity === LS_DATASTREAM_EVENTSEVERITY.ERROR || event.severity === LS_DATASTREAM_EVENTSEVERITY.FATAL) {
      // tell remote something went wrong
      LSDS.CfgCtrl.StillImageRecvFail(null);
      this.onsight.lastRecvImage = null;

      // tell whoever requested this image
      if (this.imageCaptureRequest) {
        this.imageCaptureRequest.onStillImageRemoteCaptureError('still image recieve failed');
      }
    }
  }

  onStatusEventStillImageShareModeStateChanged(sms) {
    if (this.onsight.stillImageShareState !== sms) {
      logger.info('lsds', 'imageShare', 'onStatusEventStillImageShareModeStateChanged', sms, this.onsight);

      LSDS.CfgCtrl.StatusEventStillImageShareModeState({ value: sms });

      this.onsight.stillImageShareState = sms;
      this.appCallback.onStillImageShareModeStateChange(sms);
      if (this.onsight.stillImageShareState === STILL_IMAGE_SHARE_MODE_STATUS.STILL_IMAGE_SHARE_MODE_EXIT) {
        if (this.imageShareRequest) this.imageShareRequest.onShareModeExit();
        this.onsight.lastRecvImage = null;
      }
    }
  }

  onStatusEventStillImageShareModeStateReceived(sms) {
    if (sms === STILL_IMAGE_SHARE_MODE_STATUS.STILL_IMAGE_SHARE_MODE_REQUESTED_REMOTE) {
      // are we already in a share state?
      if (this.onsight.stillImageShareState === STILL_IMAGE_SHARE_MODE_STATUS.STILL_IMAGE_SHARE_MODE_EXIT) {
        // is image capture enabled?
        if (window.dynamicEnv.REACT_APP_IMAGE_CAPTURE_ENABLE === 'true') {
          // enter still image share state
          this.onStatusEventStillImageShareModeStateChanged(STILL_IMAGE_SHARE_MODE_STATUS.STILL_IMAGE_SHARE_MODE_ENTERED);
        } else {
          logger.warn(
            'LSDatastreamProtocol::onCfgCtrlRecv() rejecting still image share request because it is not supported:',
            this.onsight,
          );
          LSDS.CfgCtrl.StatusEventStillImageShareModeState({
            value: STILL_IMAGE_SHARE_MODE_STATUS.STILL_IMAGE_SHARE_MODE_EXIT });
        }
      } else {
        logger.warn(
          'LSDatastreamProtocol::onCfgCtrlRecv() rejecting still image share request because we are already in a share state:',
          this.onsight,
        );

        // we are in still image share mode already
        this.onStatusEventStillImageShareModeStateChanged(STILL_IMAGE_SHARE_MODE_STATUS.STILL_IMAGE_SHARE_MODE_EXIT);
      }
    } else if (sms === STILL_IMAGE_SHARE_MODE_STATUS.STILL_IMAGE_SHARE_MODE_EXIT) {
      this.onStatusEventStillImageShareModeStateChanged(STILL_IMAGE_SHARE_MODE_STATUS.STILL_IMAGE_SHARE_MODE_EXIT);
    }
  }

  onStillImageShareRequestStatusChanged(srs, imageHash, tellRemote) {
    if (this.onsight.stillImageShareRequestState !== srs) {
      logger.info('lsds', 'imageShare', 'onStillImageShareRequestStatusChanged', srs, tellRemote, this.onsight);
      let extra = [];

      switch (srs) {
        case STILL_IMAGE_SHARE_REQUEST_STATUS.STILL_IMAGE_SHARE_REQUEST_REQUESTED_REMOTE:
          if (imageHash != null) {
            extra = imageHash;
          }
          break;

        case STILL_IMAGE_SHARE_REQUEST_STATUS.STILL_IMAGE_SHARE_REQUEST_ACCEPTED_LOCAL:
          if (imageHash != null) {
            // TODO: compare remote hash to our local image store to see if we already have the image
          }
          // the '1' parameter is to tell the remote to send the file to share since we do not currently support efficient sharing, or we don't have the image
          extra = [1];
          break;

        default:
          break;
      }

      if (tellRemote) {
        // 4 bytes for the srs
        const responseView = new DataView(new Uint8Array(4).buffer);
        responseView.setUint32(0, srs, true);
        const values = extra ? Array.from(new Uint8Array(responseView.buffer)).concat(extra) : Array.from(new Uint8Array(responseView.buffer));

        LSDS.CfgCtrl.StatusEventStillImageShareRequestState({ values });
      }

      this.onsight.stillImageShareRequestState = srs;
    }
  }

  onStatusEventStillImageShareRequestStateReceived(data) {
    // get the still image request state, first 4 bytes in little endian
    const dataArray = new Uint8Array(data);
    const view = new DataView(dataArray.buffer);
    const srs = view.getUint32(0, true);

    logger.info('lsds', 'imageShare', 'onStatusEventStillImageShareRequestStateReceived: request state', srs, this.onsight);
    switch (srs) {
      case STILL_IMAGE_SHARE_REQUEST_STATUS.STILL_IMAGE_SHARE_REQUEST_COMPLETE:
        // remote still image share is complete
        this.onStillImageShareRequestStatusChanged(STILL_IMAGE_SHARE_REQUEST_STATUS.STILL_IMAGE_SHARE_REQUEST_COMPLETE, null, false);
        if (this.imageShareRequest) {
          this.imageShareRequest.onRequestComplete(srs);
        }
        break;

      case STILL_IMAGE_SHARE_REQUEST_STATUS.STILL_IMAGE_SHARE_REQUEST_ACCEPTED_LOCAL:
        // remote side has OK'd the request to share
        if (this.imageShareRequest) {
          // send image if the 5th byte is missing (efficient sharing not support by remote), or if it is '1'
          const shouldSendImage = dataArray.length < 5 || (dataArray[4] === 1);
          this.imageShareRequest.onRequestAccepted(shouldSendImage);
        }
        break;

      case STILL_IMAGE_SHARE_REQUEST_STATUS.STILL_IMAGE_SHARE_REQUEST_REQUESTED_REMOTE:
        // if nothing going on, or we just requested locally...
        if (this.onsight.stillImageShareRequestState === STILL_IMAGE_SHARE_REQUEST_STATUS.STILL_IMAGE_SHARE_REQUEST_COMPLETE) {
          // accept the share request
          let imageHash = null;
          if (dataArray.length > 4) {
            imageHash = dataArray.subarray(4);
          }
          this.onStillImageShareRequestStatusChanged(STILL_IMAGE_SHARE_REQUEST_STATUS.STILL_IMAGE_SHARE_REQUEST_ACCEPTED_LOCAL, imageHash, true);
        }
        break;

      case STILL_IMAGE_SHARE_REQUEST_STATUS.STILL_IMAGE_SHARE_REQUEST_REJECTED_LOCAL:
      case STILL_IMAGE_SHARE_REQUEST_STATUS.STILL_IMAGE_SHARE_REQUEST_REJECTED_REMOTE:
        if (this.imageShareRequest) {
          this.imageShareRequest.onRequestRejected(srs);
        }
        break;

      default:
        logger.error('lsds', 'imageShare', 'onStatusEventStillImageShareRequestStateReceived: Unknown still image request state', srs, this.onsight);
        break;
    }
  }

  initilizeAndLoadLSExif = (isSharing) => {
    const self = this;
    const postExifLoad = () => {
      if (isSharing) {
        self.appCallback.onStillImageShareReceived(self.onsight.lastRecvImage);
        self.onStillImageShareRequestStatusChanged(STILL_IMAGE_SHARE_REQUEST_STATUS.STILL_IMAGE_SHARE_REQUEST_COMPLETE, null, true);
      }

      // tell whoever requested this image
      if (self.imageCaptureRequest) {
        self.imageCaptureRequest.onStillImageRemoteCaptureComplete(self.onsight.lastRecvImage);
      }
      self.onsight.lastRecvImage = null;
    };
    // read the exif tags
    const exif = new LsExif();
    exif.onLoadCallback = () => {
      this.onsight.lastRecvImage.hash = Array.from(exif.generateHash());
      postExifLoad();
      // Process image meta data to display image telestrations if present
      self.appCallback.processImageData(exif);
    };
    exif.onErrorCallback = () => {
      logger.error('lsds', 'image', 'onImageRecv error reading exif tags');
      postExifLoad();
    };
    // TODO return telestration colour for participant
    // eslint-disable-next-line no-unused-vars
    exif.load(new Blob([this.onsight.lastRecvImage.data], { type: IMAGE_MIME_TYPE.JPEG }), (_pid) => '#FF8080');
  }

  // Illumination
  setIllumState(newState, isRemoteRequest = false) {
    // if (this.onsight.illumState === newState) {
    logger.debug(`LSDatastreamProtocol::setIllumState() current state:${this.onsight.illumState} and new state:${newState}
      is remote requet:${isRemoteRequest}`);
    //   return;
    // }
    this.onsight.illumState = newState;
    if (this.onsight.activeSharerPid === this.onsight.localPid && isRemoteRequest) {
      // Here is where we toggle the local torch
      this.appCallback.onRemoteIllumForVideoStateChange(newState, true);
    }
    LSDS.sendCfgCtrl(LS_DATASTREAM_CORE_EVENTS.StatusEventIllumState, [this.onsight.remotePid], {
      value: newState,
    });
  }

  isIllumSupportedForCurrentSource() {
    for (let i = 0; i < this.onsight.activeSharerIllumInfo.perVideoSourceIllumInfo.length; i += 1) {
      const sourceIllumInfo = this.onsight.activeSharerIllumInfo.perVideoSourceIllumInfo[i];
      if (sourceIllumInfo.sourceName === this.onsight.videoConfig.currentSubSource) {
        const torchSupported = (sourceIllumInfo.videoSupportedModes & LS_DATASTREAM_CORE_EVENTS.IlluminationModeFlags.IllumModeTorchOn) > 0;
        return torchSupported;
      }
    }
    return false;
  }

  onStatusEventIllumInfo(illumInfo) {
    if (this.onsight.activeSharerPid !== this.onsight.localPid) {
      logger.debug('lsds', 'Remote illum info received', illumInfo);
      this.setIllumInfo(illumInfo);
      this.appCallback.setIlluminationButtonState();
    }
  }

  getActiveDeviceIllumInfo() {
    logger.debug('LSDatastreamProtocol::getActiveDeviceIllumInfo() activeSharerIllumInfo:', JSON.stringify(this.onsight.activeSharerIllumInfo));
    // eslint-disable-next-line prefer-destructuring
    const perVideoSourceIllumInfo = this.onsight.activeSharerIllumInfo?.perVideoSourceIllumInfo;
    const illumInfo = {
      videoTorchState: false,
      imgCapTorchModeState: {
        auto: false,
        off: false,
        flash: false,
      },
    };
    logger.debug('LSDatastreamProtocol::getActiveDeviceIllumInfo() perVideoSourceIllumInfo:', JSON.stringify(perVideoSourceIllumInfo));
    if (!perVideoSourceIllumInfo || perVideoSourceIllumInfo?.length < 1) {
      return illumInfo;
    }
    let currentDeviceIllumInfo = perVideoSourceIllumInfo?.find(
      (device) => device?.sourceName === this.onsight.videoConfig.currentSubSource,
    );
    if (!currentDeviceIllumInfo) {
      // eslint-disable-next-line prefer-destructuring
      currentDeviceIllumInfo = perVideoSourceIllumInfo[0];
    }
    logger.debug('LSDatastreamProtocol::getActiveDeviceIllumInfo() currentDeviceIllumInfo:', JSON.stringify(currentDeviceIllumInfo));
    // if (!currentDeviceIllumInfo) {
    //   return illumInfo;
    // }
    illumInfo.videoTorchState = !((currentDeviceIllumInfo.videoSupportedModes & IlluminationModeFlags.IllumModeTorchOn) === 0);
    illumInfo.imgCapTorchModeState.auto = !((currentDeviceIllumInfo.stillImageSupportedModes & IlluminationModeFlags.IllumModeAuto) === 0);
    illumInfo.imgCapTorchModeState.flash = !((currentDeviceIllumInfo.stillImageSupportedModes & IlluminationModeFlags.IllumModeOn) === 0);
    illumInfo.imgCapTorchModeState.off = illumInfo.imgCapTorchModeState.auto || illumInfo.imgCapTorchModeState.flash;

    return illumInfo;
  }

  onStatusEventIllumState(newState) {
    logger.debug('lsds', 'onStatusEventIllumState', newState);

    if (this.onsight.activeSharerPid === this.onsight.localPid) {
      // remote side has requested we change our torch state
      this.setIllumState(newState, true);
    } else {
      // remote side is notifying us it has changed its torch state
      this.appCallback.onRemoteIllumForVideoStateChange(newState);
    }
  }

  onStatusEventIllumEnable(enable) {
    logger.debug('lsds', 'onStatusEventIllumEnable', enable);
  }

  //  Disconnecting the datastream protocol in case of hangup or error
  disconnect = () => {
    logger.info('disconnect');
    if (this.onsight) {
      // Added condition to resolve #680 and #980
      if (this.isloggedInUserActiveVideoSharer()) {
        this.stopStream();
      }
      this.onsight.onDisconnect();
      this.isHandshakeDone = false;
      logger.info(
        'disconnected!!',
      );
    }
  };

  // Send a datastream hangup message
  sendDatastreamHangupMessage = (isAck) => {
    if (
      this.onsight &&
      this.onsight.remotePid !==
      LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID
    ) {
      LSDS.CfgCtrl.SessEventCallDisconnectMsg({ ack: isAck, id: LS_DISCONNECT_ID });
      return true;
    }
    return false;
  };

  muteParticipant = (participantId, toggleMute = false) => {
    logger.debug('MuteParticipant', participantId, toggleMute, this.onsight.localPid);
    if (this.isHandshakeDone) {
      // send cfgctrl to request other participant to change mute status
      LSDS.CfgCtrl.RemoteMicMuteRequest(
        participantId,
      );
    }
  }

  updateMuteFlagForSelf = (muteStatus) => {
    this.onsight.micMute = muteStatus;
    LSDS.CfgCtrl.RemoteMicMuteStatusChanged(
      {
        mute: this.onsight.micMute,
        pid: this.onsight.localPid,
      },
    );
  }

  getMuteFlagForSelf = () => this.onsight.micMute;

  sendGpsPos = (position) => {
    LSDS.CfgCtrl.StatusEventGpsInfo(
      {
        altitude: position?.coords?.altitude?.toString() ?? DEFAULT_GPS_VALUE,
        horizontalAccuracy: position?.coords?.accuracy?.toString() ?? DEFAULT_GPS_VALUE,
        latitude: position?.coords?.latitude >= 0 ? '+' + position.coords.latitude : position?.coords?.latitude?.toString() ?? DEFAULT_GPS_VALUE,
        longitude: position?.coords?.longitude >= 0 ? '+' + position.coords.longitude : position?.coords?.longitude?.toString() ?? DEFAULT_GPS_VALUE,
        pid: this.onsight.localPid,
      },
    );
  }

  // Utility Functions
  sleep(ms) {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}

const LS_DATASTREAM_PROTOCOL = new LSDatastreamProtocol();
export default LS_DATASTREAM_PROTOCOL;
