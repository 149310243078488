/** @module Presentation */
/* eslint-disable import/order */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import {
  withOrientationChange,
  isMobile,
  isMobileOnly,
  isBrowser,
} from 'react-device-detect';

// Component
import Participants from './Participants/Participants';
import CallDashboard from './CallDashboard';
import Sidebar from './Sidebar';
import AudioSource from './AudioSource/AudioSource';
import VideoSource from './VideoSource/VideoSource';
import MoreActions from 'COMPONENTS/CustomComponents/Header/MoreActions';
import Settings from 'COMPONENTS/Settings/Settings';
import MyProfile from 'COMPONENTS/Settings/MyProfile';
import General from 'COMPONENTS/Settings/General';
import Calls from 'COMPONENTS/Settings/Calls';
import BandwidthControl from 'COMPONENTS/Settings/BandwidthControl';
import VideoQuality from 'COMPONENTS/Settings/VideoQuality';

// Custom component
import Header from 'COMPONENTS/CustomComponents/Header/Header';
import Footer from 'COMPONENTS/CustomComponents/Footer/Footer';
import SidebarSwitch from 'COMPONENTS/CustomComponents/SidebarSwitch/SidebarSwitch';

// Constant
import { ROUTES } from 'UTILS/constants/RoutingPathConstants';
import {
  callDashboardIconSet,
  ICON_NAMES,
} from 'UTILS/constants/HeaderConstants';

import { callDashboardFooterIconSet, ICON_NAMES as FOOTER_ICON_NAMES, portraitFooterIconSet } from 'UTILS/constants/FooterConstants';
import { SETTINGS_MENU, SETTINGS, MUTE_ACTION, SIDEBAR_ROUTES, TABLET_WIDTH } from 'UTILS/constants/UtilityConstants';

// Action
import { logoutAction } from 'STORE/Auth/AuthAction';
import { setIncomingCall } from 'STORE/CallControl/CallControlAction';
import { setSelectedVideoProfileAction } from 'STORE/ClientSettings/ClientSettingsAction';

// Selector
import { getAuthToken, getLoggedInUserName } from 'STORE/Auth/AuthSelector';
import { getParticipants } from 'STORE/User/UserSelector';
import {
  getIsIncomingCall,
  getShowVideoFallback,
} from 'STORE/CallControl/CallControlSelector';
import {
  getClientSettingsData,
  getUserData,
  getVideoMediaConfigs,
  getSelectedVideoProfile,
  getNetworkSettings,
  getSecuritySettings,
  getPolicyUISettings,
  getAudioMediaConfigs,
  getClientPermissions,
  isDemoRunning,
} from 'STORE/ClientSettings/ClientSettingsSelector';

// Utility
import CommonUtility from 'UTILS/CommonUtility';
import browserAPI from 'UTILS/BrowserAPI';

// Style
import './CallDashboard.scss';

// I18n
import { translate } from 'SERVICES/i18n';

// Services
import { AppLogger, LOG_NAME } from 'SERVICES/Logging/AppLogger';

const logger = AppLogger(LOG_NAME.CallDashboard);

// eslint-disable-next-line react/prefer-stateless-function
/**
 * @component
 */
class CallDashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSettingsPopup: false,
      isFooterToggled: false,
      callDashboardFooterIcons: [],
      potraitFooterIcons: [],
    };
  }

  componentDidMount() {
    CommonUtility.disableBrowserNavigation();
    // eslint-disable-next-line array-callback-return
    callDashboardFooterIconSet.map((icon, index) => {
      if (icon.iconName === FOOTER_ICON_NAMES.ILLUMINATION && !this.isIlluminationAllowed()) {
        callDashboardFooterIconSet.splice(index, 1);
        portraitFooterIconSet.splice(index, 1);
      }
    });

    this.setState({
      callDashboardFooterIcons: callDashboardFooterIconSet,
      potraitFooterIcons: portraitFooterIconSet,
    });
  }

  componentWillUnmount() {
    CommonUtility.enableBrowserNavigation();
  }

  /**
   * Handles settings sub menu
   * @param {menu object} item The setting menu item
   */
  handleSettingsMenu(selectedMenuId) {
    const selectedMenu = SETTINGS_MENU.find((menu) => menu.id === selectedMenuId);
    const expectedPath = `${this.basePath()}${selectedMenu ? selectedMenu.path : ROUTES.SETTINGS}`;
    if (this.props?.location.pathname !== expectedPath) {
      this.props.history.push(expectedPath);
    }
  }

  isIlluminationAllowed = () => {
    const { Illumination, Flash } = this.props.clientPolicyPermissionsXmlData;
    return Illumination || Flash;
  }

  handleFooterToggleState = (isToggled) => {
    this.setState({ isFooterToggled: isToggled }, () => {
      const subComponentElt = document.getElementById('subcomponent');
      if (this.state.isFooterToggled) {
        // Footer is visible hence increase the height for sub-component
        subComponentElt.classList.remove('call-dashboard-subcomponent-landscape');
        subComponentElt.classList.add('call-dashboard-sub-component-height');
      } else {
        // Footer is collapsed hence increase the height for sub-component
        subComponentElt.classList.add('call-dashboard-subcomponent-landscape');
        subComponentElt.classList.remove('call-dashboard-sub-component-height');
      }
      this.props.handleTeleCanvasOnComponentToggle();
    });
  }

  endCallAndLogout = () => {
    logger.info('Call dashboard -  end call and logout');
    if (this.props.callInProgress) {
      // send logout=true to endCall to identify that the call is being ended as user has clicked logout during a call
      this.props.endCall(true);
    }
    setTimeout(() => {
      logoutAction();
    }, 2000);
  };

  basePath = () => this.props.isDemoModeOn ?
    ROUTES.CALL_DASHBOARD_DEMO : ROUTES.CALL_DASHBOARD;

  goBack = () => {
    if (this.state.showSettingsPopup) {
      this.setState({ showSettingsPopup: false });
    } else {
      this.props.history.push(this.basePath());
    }
  };

  goBackToSettings = () => {
    this.props.history.push(this.basePath() + ROUTES.SETTINGS);
  };

  goBackToDashboard = () => {
    this.props.endCall();
    this.props.history.push(ROUTES.DASHBOARD);
  };

  headerHandler = async (item) => {
    if (item.mainMenu === SETTINGS) {
      this.handleSettingsMenu(item.selectedMenu);
    } else {
      switch (item) {
        case ICON_NAMES.PARTICIPANTS:
          if (
            this.props?.location.pathname !==
            this.basePath() + ROUTES.CALL_PARTICIPANTS
          ) {
            this.props.history.push(
              this.basePath() + ROUTES.CALL_PARTICIPANTS,
            );
          } else {
            this.props.history.push(ROUTES.CALL_DASHBOARD);
          }
          break;
        case ICON_NAMES.SCREEN_SHARE:
          this.props.screenShareProps?.handler();
          break;

        case ICON_NAMES.AUDIODEVICES: {
          const browserPermissionState = await browserAPI.isBrowserPermissionsAllowed({ video: false, audio: true });
          if (!browserPermissionState.audio) {
            // TODO: Show popup
            this.props.setError(
              translate('errors.micBrowserPermission'),
              true,
              translate('APPNAME'),
            );
            logger.warn('CallDashboardContainer::audioSource browser audio permission is not there, returning');
            break;
          }
          // TODO: handle for Apple devices, since isBrowserPermissionsAllowed will not work

          if (
            this.props?.location.pathname !==
            ROUTES.CALL_DASHBOARD + ROUTES.AUDIO_SOURCE
          ) {
            this.props.history.push(
              ROUTES.CALL_DASHBOARD + ROUTES.AUDIO_SOURCE,
            );
          } else {
            this.props.history.push(ROUTES.CALL_DASHBOARD);
          }
          break;
        }
        case ICON_NAMES.VIDEODEVICES:

          if (
            this.props?.location.pathname !==
            ROUTES.CALL_DASHBOARD + ROUTES.VIDEO_SOURCE
          ) {
            this.props.history.push(
              ROUTES.CALL_DASHBOARD + ROUTES.VIDEO_SOURCE,
            );
          } else {
            this.props.history.push(ROUTES.CALL_DASHBOARD);
          }
          break;

        case ICON_NAMES.ICON_MENU:
          if (
            this.props?.location.pathname !==
            ROUTES.CALL_DASHBOARD + ROUTES.MORE_ACTIONS
          ) {
            this.props.history.push(
              ROUTES.CALL_DASHBOARD + ROUTES.MORE_ACTIONS,
            );
          } else {
            this.props.history.push(ROUTES.CALL_DASHBOARD);
          }
          break;
        case ICON_NAMES.SEARCH:
          if (
            this.props?.location.pathname !==
              ROUTES.CALL_DASHBOARD + ROUTES.SEARCH
          ) {
            this.props.history.push(
              ROUTES.CALL_DASHBOARD + ROUTES.SEARCH,
            );
          } else {
            this.props.history.push(ROUTES.CALL_DASHBOARD);
          }
          break;

        case ICON_NAMES.HANGUP:
          this.goBackToDashboard();
          break;

        case ICON_NAMES.SETTINGS:
          // Routing done only for mobile
          if (isMobileOnly) {
            if (
              this.props?.location.pathname !==
              this.basePath() + ROUTES.SETTINGS
            ) {
              this.props.history.push(
                this.basePath() + ROUTES.SETTINGS,
              );
            } else {
              this.props.history.push(this.basePath());
            }
          } else {
            this.setState({ showSettingsPopup: true });
          }
          break;

        default:
      }
    }
  };

  // #region consent dialogue

  onChangeZoomRange = (number) => {
    this.props.onChangeZoomRange(number);
  }

  updateVideoProfiles = (profileToSelect) => {
    const profileToSelectAt = this.props?.videoProfiles?.findIndex(
      (videoProfile) => videoProfile?.name === profileToSelect,
    );
    const selectedProfile = this.props.videoProfiles[profileToSelectAt];
    this.props.setSelectedVideoProfileAction(selectedProfile);
  };

  goBackToMoreActions = () => {
    this.props.history.push(this.basePath() + ROUTES.MORE_ACTIONS);
  };

  footerHandler = (item) => {
    logger.log('Footer icon handler:: item: ', item);
    switch (item) {
      case FOOTER_ICON_NAMES.SNAPSHOT_DISABLED:
        this.props.takePicture();
        break;
      case FOOTER_ICON_NAMES.ILLUMINATION:
        break;
      case FOOTER_ICON_NAMES.TELESTRATION:
        break;
      case FOOTER_ICON_NAMES.PLAY:
        break;
      case FOOTER_ICON_NAMES.RECORD:
        break;
      case FOOTER_ICON_NAMES.ZOOM:
        break;
      case FOOTER_ICON_NAMES.AUDIO:
        break;
      case FOOTER_ICON_NAMES.AI:
        break;
      default:
    }
  }

  checkForEnterKeyPress = (e, item) => {
    if (e.key === 'Enter' && e.target.value !== '') {
      this.headerHandler(item);
    }
  }

  setApplyBackdrop = (value) => {
    this.setState({ isBackdrop: value });
  }

  isSidebarPanelOpen() {
    return (SIDEBAR_ROUTES.includes(
      this.props?.location.pathname,
    ));
  }

  // Specific for issue #24308
  // Iphone 11 landscape issue for call dashboard view port issue
  isDeviceInLandscapeMode() {
    return (CommonUtility.isiPhoneWithiOS14InLandscape() && this.props?.location.pathname === this.basePath());
  }

  render() {
    const { isLandscape, screenShareProps } = this.props;
    const isImageShared = this.props.isImageShareMode || this.props.isImageReceiveMode;
    return (
      <div className={`w-100  d-flex align-center justify-center flex-column call-dashboard-layout 
      ${this.isDeviceInLandscapeMode() ? 'view-port-h' : 'h-100'}`}
      >
        {this.state.isBackdrop && <div className='backdrop-class' />}

        <div
          className={
            this.props?.location.pathname !== this.basePath()
              ? 'd-lg-flex d-none w-100 call-header'
              : 'd-flex  w-100 call-header'
          }
        >
          <Header
            iconSet={callDashboardIconSet}
            activePath={window.location.pathname}
            endCallAndLogout={this.endCallAndLogout}
            clickHandler={this.headerHandler}
            isSettingClosed={this.state.showSettingsPopup}
            muteAudio={() => this.props.muteParticipant(MUTE_ACTION.LOCAL)}
            startOrRequestVideoStream={
              this.props.startOrRequestVideoStream
            }
            isWebappReady={this.props.isWebappReady}
            checkForEnterKeyPress={this.checkForEnterKeyPress}
            screenShareProps={screenShareProps}
            isImageShared={this.props.imageCaptureData !== null}
          />
        </div>

        <div
          id='subcomponent'
          className={`w-100 m-0 p-0 call-dashboard-sub-component 
          ${this.props?.location.pathname !== this.basePath() && !isBrowser && isMobile && window.innerWidth >= TABLET_WIDTH && isLandscape && 'call-dashboard-sub-component-height'}
        ${this.props?.location.pathname !== this.basePath() && !isBrowser
            ? 'h-100'
            : this.props?.location.pathname === this.basePath()
                && this.state.isFooterToggled
                && isLandscape
              ? 'call-dashboard-subcomponent-landscape'
              : 'call-dashboard-sub-component-height'}
        `}
        >
          <Container fluid className='m-0 p-0 call-dashboard-container'>
            {/* Done changes for responsivess  */}
            <Row className='h-100 m-0 p-0'>
              <Col
                lg={
                  this.isSidebarPanelOpen()
                    ? 9
                    : 12
                }
                className={`h-100 p-0 m-0
                      ${isMobile
                  ? !(
                    window.innerWidth >= 1024 &&
                      this.props.isPortrait
                  ) &&
                      !(
                        window.innerWidth === 1024 &&
                        this.props.isLandscape
                      ) // Not equal to ipad Por Portrait and Not equal to ipad Landscape.
                    ? window.innerWidth <= 768 || isMobileOnly // ipad Portrait and mobile (Portrait and Landscape) view.
                      ? 'videoAlignCenterOnMobile'
                      : ''
                    : 'video-align-center-on-ipadPro'
                  : ''
                  }
                  ${!isBrowser && isMobile && this.props.imageCaptureData && this.isSidebarPanelOpen() && 'call-dashboard-sub-component-height'}
                  `}
              >
                <CallDashboard
                  handleLogoutClick={this.endCallAndLogout}
                  acceptCall={this.props.acceptCall}
                  muteUnmuteAudio={
                    this.props.muteUnmuteAudio
                  }
                  muteAudio={this.props.muteAudio}
                  muteUnmute={this.props.muteUnmute}
                  localStream={this.props.localStream}
                  remoteStream={this.props.remoteStream}
                  isLandscape={this.props.isLandscape}
                  activeSharerPId={
                    this.props.activeSharerPId
                  }
                  isPortrait={this.props.isPortrait}
                  rightSidebarIsOpen={this.isSidebarPanelOpen()}
                  isSidebarPanelOpenOnMobile={this.props?.location.pathname !== this.basePath() && !isBrowser}
                  goBackToDashboard={this.goBackToDashboard}
                  isIncomingCall={this.props.isIncomingCall}
                  setIncomingCall={
                    this.props.setIncomingCall
                  }
                  loggedInUserName={
                    this.props.loggedInUserName
                  }
                  showVideoFallback={
                    this.props.showVideoFallback
                  }
                  clearParticipantsAction={
                    this.props.clearParticipantsAction
                  }
                  callInProgress={this.props.callInProgress}
                  participants={this.props.participantsList}
                  displayRemoteStream={
                    this.props.displayRemoteStream
                  }
                  displayLocalStream={
                    this.props.displayLocalStream
                  }
                  selectedVideoProfile={
                    this.props.selectedVideoProfile
                  }
                  startDrawingOnCanvas={
                    this.props.startDrawingOnCanvas
                  }
                  imageCaptureData={this.props.imageCaptureData}
                  isImageReceivingMode={this.props.isImageReceivingMode}
                  isImageShareMode={this.props.isImageShareMode}
                  isImageReceiveMode={this.props.isImageReceiveMode}
                  closeImage={this.props.closeImage}
                  screenShareProps={this.props.screenShareProps}
                  captionStr={this.props.captionStr}
                  isNLPWord={this.props.isNLPWord}
                  captionsOn={this.props.captionsOn}
                  nlpUrl={this.props.nlpUrl}
                  isMeeting={this.props.isMeeting}
                />
              </Col>
              <SidebarSwitch className='h-100 m-0 p-0'>
                <Sidebar
                  className={`${SIDEBAR_ROUTES.includes(
                    this.props?.location.pathname,
                  )
                    ? `sidebar ${this.props?.location.pathname !== this.basePath() && !isBrowser ? 'h-100' : ''}`
                    : ''
                  }`}
                >
                  <Route
                    path={
                      this.props.match.path +
                      ROUTES.CALL_PARTICIPANTS
                    }
                    render={(props) => (
                      <Participants
                        className='h-100'
                        {...props}
                        participants={
                          this.props.participantsList
                        }
                        goBack={this.goBack}
                        startOrRequestVideoStream={
                          this.props
                            .startOrRequestVideoStream
                        }
                        muteParticipant={
                          this.props.muteParticipant
                        }
                        isMeeting={this.props.isMeeting}
                      />
                    )}
                  />

                  <Route
                    path={
                      this.props.match.path +
                      ROUTES.AUDIO_SOURCE
                    }
                    render={(props) => (
                      <AudioSource
                        {...props}
                        // callRecords={callRecords}
                        goBack={this.goBack}
                        changeAudioDevice={
                          this.props.changeAudioDevice
                        }
                        getDeviceList={
                          this.props.getDeviceList
                        }
                      />
                    )}
                  />

                  <Route
                    path={
                      this.props.match.path +
                      ROUTES.VIDEO_SOURCE
                    }
                    render={(props) => (
                      <VideoSource
                        {...props}
                        // callRecords={callRecords}
                        goBack={this.goBack}
                        changeVideoDevice={
                          this.props.changeVideoDevice
                        }
                        getDeviceList={
                          this.props.getDeviceList
                        }
                        selectedVideoProfile={
                          this.props
                            .selectedVideoProfile
                        }
                        setSelectedProfile={(p) =>
                          this.updateVideoProfiles(p)
                        }
                        videoProfiles={
                          this.props.videoProfiles
                        }
                      />
                    )}
                  />
                </Sidebar>
                <Route
                  path={
                    this.props.match.path +
                    ROUTES.MORE_ACTIONS
                  }
                  render={(props) => (
                    <MoreActions
                      {...props}
                      iconSet={callDashboardIconSet[0]}
                      clickHandler={this.headerHandler}
                      goBack={this.goBack}
                      endCallAndLogout={
                        this.endCallAndLogout
                      }
                      isCallDashboard
                    />
                  )}
                />
                {isMobileOnly && ( // Settings side panel route for mobile devices
                  <Route
                    path={
                      this.props.match.path +
                      ROUTES.SETTINGS
                    }
                    render={(props) => (
                      <Settings
                        {...props}
                        isCallDashboard
                        header={translate(
                          'headerMenus.settings',
                        )}
                        iconSet={SETTINGS_MENU}
                        isOpen
                        clickHandler={this.headerHandler}
                        goBack={
                          isMobileOnly
                            ? this.goBackToMoreActions
                            : this.goBack
                        }
                        userData={this.props.userData}
                        clientSettingsData={
                          this.props.clientSettingsData
                        }
                        clientPolicyPermissionsXmlData={
                          this.props
                            .clientPolicyPermissionsXmlData
                        }
                        selectedVideoProfile={
                          this.props
                            .selectedVideoProfile
                        }
                        setSelectedProfile={(p) =>
                          this.updateVideoProfiles(p)
                        }
                        networkSettings={
                          this.props.networkSettings
                        }
                        securitySettings={
                          this.props.securitySettings
                        }
                        policyUISettings={
                          this.props.policyUISettings
                        }
                        videoProfiles={
                          this.props.videoProfiles
                        }
                      />
                    )}
                  />
                )}

                <Route
                  path={
                    this.props.match.path + ROUTES.MY_PROFILE
                  }
                  render={(props) => (
                    <MyProfile
                      {...props}
                      iconSet={SETTINGS_MENU}
                      isOpen
                      isCallDashboard
                      clickHandler={this.headerHandler}
                      goBack={this.goBackToSettings}
                      userData={this.props.userData}
                      clientSettingsData={
                        this.props.clientSettingsData
                      }
                    />
                  )}
                />
                <Route
                  path={this.props.match.path + ROUTES.GENERAL}
                  render={(props) => (
                    <General
                      {...props}
                      isOpen
                      isCallDashboard
                      clickHandler={this.headerHandler}
                      goBack={this.goBackToSettings}
                      userData={this.props.userData}
                      clientSettingsData={
                        this.props.clientSettingsData
                      }
                      securitySettings={
                        this.props.securitySettings
                      }
                    />
                  )}
                />
                <Route
                  path={this.props.match.path + ROUTES.CALLS}
                  render={(props) => (
                    <Calls
                      {...props}
                      isCallDashboard
                      isOpen
                      clickHandler={this.headerHandler}
                      goBack={this.goBackToSettings}
                      userData={this.props.userData}
                      clientSettingsData={
                        this.props.clientSettingsData
                      }
                      securitySettings={
                        this.props.securitySettings
                      }
                      policyUISettings={
                        this.props.policyUISettings
                      }
                    />
                  )}
                />
                <Route
                  path={
                    this.props.match.path +
                    ROUTES.BANDWIDTH_CONTROL
                  }
                  render={(props) => (
                    <BandwidthControl
                      {...props}
                      isCallDashboard
                      isOpen
                      clickHandler={this.headerHandler}
                      goBack={this.goBackToSettings}
                      userData={this.props.userData}
                      networkSettings={
                        this.props.networkSettings
                      }
                    />
                  )}
                />
                <Route
                  path={
                    this.props.match.path +
                    ROUTES.VIDEO_QUALITY
                  }
                  render={(props) => (
                    <VideoQuality
                      {...props}
                      isOpen
                      isCallDashboard
                      clickHandler={this.headerHandler}
                      goBack={this.goBackToSettings}
                      setSelectedProfile={(p) =>
                        this.updateVideoProfiles(p)
                      }
                      selectedVideoProfile={
                        this.props.selectedVideoProfile
                      }
                      videoProfiles={
                        this.props.videoProfiles
                      }
                      clientPolicyPermissionsXmlData={
                        this.props.clientPolicyPermissionsXmlData
                      }
                    />
                  )}
                />
              </SidebarSwitch>
            </Row>
            {
              // Show settings popup in desktop mode(no sidebar routing here)
              this.state.showSettingsPopup && !isMobileOnly && (
                <Settings
                  header={translate('headerMenus.settings')}
                  iconSet={SETTINGS_MENU}
                  isOpen={this.state.showSettingsPopup}
                  clickHandler={this.headerHandler}
                  goBack={
                    isMobileOnly
                      ? this.goBackToMoreActions
                      : this.goBack
                  }
                  userData={this.props.userData}
                  clientSettingsData={
                    this.props.clientSettingsData
                  }
                  clientPolicyPermissionsXmlData={
                    this.props.clientPolicyPermissionsXmlData
                  }
                  selectedVideoProfile={
                    this.props.selectedVideoProfile
                  }
                  setSelectedProfile={(p) =>
                    this.updateVideoProfiles(p)
                  }
                  networkSettings={this.props.networkSettings}
                  securitySettings={this.props.securitySettings}
                  policyUISettings={this.props.policyUISettings}
                  videoProfiles={this.props.videoProfiles}
                />
              )
            }
          </Container>
        </div>
        <div
          className={
            this.props?.location.pathname !== this.basePath()
              ? 'd-lg-flex d-none w-100 footer'
              : 'd-flex w-100 footer'
          }
        >
          <Footer
            className={`align-center ${this.props?.location.pathname !==
              this.basePath()
              ? 'd-lg-flex d-none w-100'
              : 'd-flex w-100'
            } landscape`}
            iconSet={this.state.callDashboardFooterIcons}
            portraitIconSet={this.state.potraitFooterIcons}
            clickHandler={this.footerHandler}
            streamState={this.props.streamState}
            takePicture={this.props.takePicture}
            imageCaptureInProgress={this.props.imageCaptureInProgress}
            lastPicture={this.props.lastPicture}
            stillImageShareModeState={this.props.stillImageShareModeState}
            toggleImageShare={this.props.toggleImageShare}
            isImageCaptureEnable={this.props.isImageCaptureEnable}
            imageCaptureData={this.props.imageCaptureData}
            handleFooterToggleState={this.handleFooterToggleState}
            isImageShared={isImageShared}
            setApplyBackdrop={this.setApplyBackdrop}
            toggleIllumination={this.props.toggleIllumination} // Todo:Torch dummy UI
            setIlluminationButtonState={this.props.setIlluminationButtonState}
            teleHelper={this.props.teleHelper}
            captionsOn={this.props.captionsOn}
            fromLanguage={this.props.fromLanguage}
            toLanguage={this.props.toLanguage}
            updateNLPData={this.props.updateNLPData}
            nlpUrl={this.props.nlpUrl}
            onChangeZoomRange={this.onChangeZoomRange}
            zoomLevel={this.props.zoomLevel}
            zoomMinRange={this.props.zoomMinRange}
            zoomMaxRange={this.props.zoomMaxRange}
            isOnFlashLight={this.props.isOnFlashLight}
            handleFlashLightChange={this.props.handleFlashLightChange}
            togglePauseVideo={this.props.togglePauseVideo}
            imageCapCancelled={this.props.imageCapCancelled}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: getAuthToken(state),
  participantsList: getParticipants(state),
  isIncomingCall: getIsIncomingCall(state),
  loggedInUserName: getLoggedInUserName(state),
  showVideoFallback: getShowVideoFallback(state),
  clientSettingsData: getClientSettingsData(state),
  userData: getUserData(state),
  videoProfiles: getVideoMediaConfigs(state),
  selectedVideoProfile: getSelectedVideoProfile(state),
  networkSettings: getNetworkSettings(state),
  securitySettings: getSecuritySettings(state),
  policyUISettings: getPolicyUISettings(state),
  audioMediaConfigs: getAudioMediaConfigs(state),
  clientPolicyPermissionsXmlData: getClientPermissions(state),
  isDemoModeOn: isDemoRunning(state),
});

// eslint-disable-next-line no-unused-vars
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setIncomingCall,
      setSelectedVideoProfileAction,
    },
    dispatch,
  );
}

export default withOrientationChange(
  connect(mapStateToProps, mapDispatchToProps)(CallDashboardContainer),
);
