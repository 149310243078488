export const NLP_CONNETION_EVENTS = {
  RECEIVE_RECOGNIZING_RESULT: 'ReceiveRecognizingResult',
  RECEIVE_RECOGNITION_RESULT: 'ReceiveRecognitionResult',
  RECEIVE_SYNTH_RESULT: 'ReceiveSynthResult',
  STREAM_FAILED: 'StreamFailed',
};

export const NLP_HUB_METHODS = {
  STREAM_AUDIO: 'StreamAudio',
};

export const LIBOPUS_ENCODER_CONFIG = {
  CHANNELS: 1,
  SAMPLE_RATE: 16000,
  BITRATE: 24000,
  FRAME_SIZE: 20,
  VOICE_OPTIMIZATION: true,
};

export const AUDIO_WORKLET_NODE_EVENT_TYPE = {
  DATA: 'data',
  STOP: 'stop',
};

export const AUDIO_SAMPLE_RATE = 16000;

export const AUDIO_WORKLET_NAME = 'recorder-worklet';

export const RECORDER_WORKLET_PATH = 'worklet/RecorderWorkletProcessor.js';

export const NLP_LANGUAGES = [
  { label: 'Afrikaans', value: 'af', isoCode: 'af-ZA' },
  { label: 'ArabicEgypt', value: 'ar', isoCode: 'ar-EG' },
  { label: 'ArabicIraq', value: 'ar_IQ', isoCode: 'ar-IQ' },
  { label: 'Armenian', value: 'hy', isoCode: 'hy-AM' }, // false
  { label: 'Bulgarian', value: 'bg', isoCode: 'bg-BG' },
  { label: 'Catalan', value: 'ca', isoCode: 'ca-ES' },
  { label: 'ChineseCantonese', value: 'zh_HK', isoCode: 'zh-HK' },
  { label: 'ChineseMandarin', value: 'zh', isoCode: 'zh-CN' },
  { label: 'Croatian', value: 'hr', isoCode: 'hr-HR' },
  { label: 'Czech', value: 'cs', isoCode: 'cs-CZ' },
  { label: 'Danish', value: 'da', isoCode: 'da-DK' },
  { label: 'Dutch', value: 'nl', isoCode: 'nl-NL' },
  { label: 'English', value: 'en', isoCode: 'en-US' },
  { label: 'Filipino', value: 'fil', isoCode: 'fil-PH' },
  { label: 'Finnish', value: 'fi', isoCode: 'fi-FI' },
  { label: 'French', value: 'fr', isoCode: 'fr-FR' },
  { label: 'FrenchCanada', value: 'fr_CA', isoCode: 'fr-CA' },
  { label: 'Georgian', value: 'ka', isoCode: 'ka-GE' }, // false
  { label: 'German', value: 'de', isoCode: 'de-DE' },
  { label: 'Greek', value: 'el', isoCode: 'el-GR' },
  { label: 'Hebrew', value: 'he', isoCode: 'he-IL' },
  { label: 'Hindi', value: 'hi', isoCode: 'hi-IN' },
  { label: 'Hungarian', value: 'hu', isoCode: 'hu-HU' },
  { label: 'Italian', value: 'it', isoCode: 'it-IT' },
  { label: 'Japanese', value: 'ja', isoCode: 'ja-JP' },
  { label: 'Korean', value: 'ko', isoCode: 'ko-KR' },
  { label: 'Norwegian', value: 'nb', isoCode: 'nb-NO' },
  { label: 'Polish', value: 'pl', isoCode: 'pl-PL' },
  { label: 'Portuguese', value: 'pt', isoCode: 'pt-PT' },
  { label: 'PortugueseBrazil', value: 'pt_BR', isoCode: 'pt-BR' },
  { label: 'Romanian', value: 'ro', isoCode: 'ro-RO' },
  { label: 'Russian', value: 'ru', isoCode: 'ru-RU' },
  { label: 'Slovak', value: 'sk', isoCode: 'sk-SK' },
  { label: 'Slovenian', value: 'sl', isoCode: 'sl-SI' },
  { label: 'Spanish', value: 'es', isoCode: 'es-ES' },
  { label: 'Swedish', value: 'sv', isoCode: 'sv-SE' },
  { label: 'Thai', value: 'th', isoCode: 'th-TH' },
  { label: 'Turkish', value: 'tr', isoCode: 'tr-TR' },
  { label: 'Ukranian', value: 'uk', isoCode: 'uk-UA' },
  { label: 'Vietnamese', value: 'vi', isoCode: 'vi-VN' },
];
