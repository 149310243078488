/* eslint-disable react/sort-comp */
/* eslint-disable import/order */
/* eslint-disable react/prop-types */
/* eslint-disable max-classes-per-file */
/* eslint-disable default-param-last */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { withOrientationChange, isBrowser } from 'react-device-detect';
import { ToastContainer } from 'react-toastify';

// Component
import DashboardContainer from 'COMPONENTS/Dashboard/DashboardContainer';
import CallDashboardContainer from 'COMPONENTS/CallDashboard/CallDashboardContainer';

// Custom component
import CustomModal from 'COMPONENTS/CustomComponents/CallingModal/CallingModal';
import ErrorModal from 'COMPONENTS/CustomComponents/ErrorDialogModal/ErrorModal';
import Popup from 'COMPONENTS/CustomComponents/CustomPopup/Popup';
import PrivateRoute from 'ROUTES/PrivateRoute';
import withLoadingSpinner from 'COMPONENTS/HOC/withLoadingSpinner';
import ImageCaptureConsentModal from 'COMPONENTS/CustomComponents/ImageCaptureConsentModal/ImageCaptureConsentModal';
import { showToast, showWarningToast, showBannerToast, dismissToast } from 'COMPONENTS/CustomComponents/Toast/Toast';

// Utility
import CommonUtility from 'UTILS/CommonUtility';

// Translation
import { translate } from 'SERVICES/i18n';

// Action
import {
  setDeviceList,
  setVideoIconAction,
  setAudioIconAction,
  setAudioInputDeviceAction,
  setIncomingCall,
  setAudioOutputDeviceAction,
  setVideoDeviceAction,
  setShowVideoFallbackUI,
  setVideoDeviceListAction,
  setScreenShareAction,
  setVideoPauseAction,
  setStreamShareStateAction,
  setImageShareModeAction,
  setCallStartAction,
  setCallEndAction,
} from 'STORE/CallControl/CallControlAction';
import {
  setParticipantsAction,
  clearParticipantsAction,
  setTelestrationIconDetails,
} from 'STORE/User/UserAction';
import {
  logoutAction,
  getWebRtcTokenAction,
  setLaunchParametersAction,
} from 'STORE/Auth/AuthAction';

import {
  getClientPolicyAction,
  getProfileAction,
  setSelectedVideoProfileAction,
  setVideoMediaConfigsAction,
  setDemoMode,
} from 'STORE/ClientSettings/ClientSettingsAction';

// Constants
import { ACTIONS } from 'UTILS/constants/ActionConstants';
import { OPM_SESSION_TERMINATED } from 'UTILS/constants/LoginConstants';
import { CALL_DASHBOARD, LOCAL_STORAGE_KEY } from 'UTILS/constants/DOMElementConstants';
import { clearAxiosSessionExpiryTimer } from '../../services/AxiosHelper';

// Selector
import {
  getAudioInputDeviceList,
  getAudioOutputDeviceList,
  getVideoInputDeviceList,
  isVideoOn,
  isAudioOn,
  isScreenShareOn,
  getSipUserName,
  getAudioInputDevice,
  getAudioOutputDevice,
  getSelectedVideoDevice,
  getIsVideoPaused,
  getStreamShareState,
} from 'STORE/CallControl/CallControlSelector';
import {
  getAuthToken,
  getLoggedInUserName,
  getLoginRequestTimestamp,
  getIsSessionExpiredFlag,
  getWebRtcToken,
  getLaunchParameters,
  getCustomMessageData,
} from 'STORE/Auth/AuthSelector';
import {
  getParticipants,
  getCallHistoryRecords,
  getTSData,
} from 'STORE/User/UserSelector';
import { getPersonalContacts } from 'STORE/Contacts/ContactSelector';
import {
  getUserData,
  getClientSettingsData,
  getClientPolicyReqTimestamp,
  getProfileReqTimestamp,
  getClientSessionConfigInfo,
  getSelectedVideoProfile,
  getVideoMediaConfigs,
  getAudioMediaConfigs,
  getSecuritySettings,
  getNLPUrl,
  getClientPermissions,
  getPolicyUISettings,
  isDemoRunning,
  getMeetingSettings,
  getOverlaySettings,
  getClientPolicyData,
  getImageCaptureSettings,
} from 'STORE/ClientSettings/ClientSettingsSelector';

// Constant
import { ROUTES } from 'UTILS/constants/RoutingPathConstants';
import {
  MEDIA_SERVER_CONNECTION,
  MEDIA_TYPE,
  CALL_DIRECTION,
  TIMER_CONSTANTS,
  VIDEO_MEDIA_CONFIGS,
  DEFAULT_MEDIA_CONFIG,
  WEB_CALL_STATE,
  CALL_ERROR,
  VIDEO_MEDIA_PROFILE,
  MEDIA_PATH_STATE,
  JPEG_COMPRESSION_QUALITY_DEFAULT,
  SCREEN_SHARE_DEVICE,
  IMAGE_MIME_TYPE,
  FILL_LIGHT_MODE,
  SCREEN_SHARE_STATE,
  SCREEN_SHARE_ON,
  DEFAULT_ZOOM_LEVEL,
  DEFAULT_ZOOM_INFO,
  FF_CAMERA_BUSY_MSG,
  MAX_CAMERA_RES,
} from 'UTILS/constants/MediaServerConstants';
import {
  ParticipantChangeReasons,
  STILL_IMAGE_CAPTURE_STATUS,
  STREAM_STATUS,
  STILL_IMAGE_SHARE_MODE_STATUS,
  IMAGE_SHARE_MODE,
  IllumState,
  LS_DATASTREAM_PARTICIPANTID,
  EZoomDirection,
} from 'UTILS/constants/DatastreamConstant';
import {
  REMOTE_VIDEO_CONSENT,
  OTHER_PARTICIPANT,
  ERROR_TYPES,
  INCOMING_CALL_TAG,
  MUTE_ACTION,
  STREAM_SHARE_STATE,
  STREAM_SHARE_ACTION,
  STREAMER,
  ZOOM_SLIDER_VALUES,
  POLICY,
  PERMISSION,
  VIDEO_CONSENT_MODAL_TIMEOUT,
  ALLOWED_FROM_OPM,
} from 'UTILS/constants/UtilityConstants';
import { TELESTRATION_COLORS } from 'UTILS/constants/TelestrationConstant';
import { NLP_LANGUAGES } from 'UTILS/constants/NlpConstants';
// Services
import CallMgr from 'SERVICES/MediaServerService/CallManager';
import { AppLogger, LOG_NAME } from 'SERVICES/Logging/AppLogger';
import LS_DATASTREAM_PROTOCOL from 'SERVICES/LSDatastream/LSDatastreamProtocol';
import { onsightSysVersion, onsightProductVersion } from 'SERVICES/LSDatastream/Onsight';
import CallStatisticsManager from 'SERVICES/statistics/callstatsmanager';
import Participants from './ParticipantsHelper';
import { initScreenShareMediaProfile } from './AppUtils';
import browserAPI, { GUM_EXCEPTION } from 'UTILS/BrowserAPI';
import * as browserNotification from 'SERVICES/BrowserNotification/BrowserNotificationHelper';
import TelestrationHelper from 'SERVICES/Telestration/TelestrationHelper';
import NlpHelper from 'SERVICES/NLP/NlpHelper';
import { showCallSurvey, registerDemoAction } from 'UTILS/pendoUtils';
import { argbToRGB } from 'SERVICES/lsexif/telestration';
// eslint-disable-next-line no-unused-vars
import ocLogo from 'ASSETS/Logo_small.png';
import MeetingsManager, { MeetingJoinError } from 'SERVICES/Meetings/Meetings';
import { ParticipantState } from 'SERVICES/Meetings/MeetingSubscriber';
import { LAUNCH_PARAMETER_ACTION } from '../../utils/constants/LaunchParameterConstants';
import WebrtcUtils from 'SERVICES/MediaServerService/WebRtcUtils';
import { UserbackAPI as UB } from 'UTILS/userbackUtils';

// Local constants
const AUTO_DISMISS_ERR_DURATION = 3;
const AUTO_SHOW_ERR_DURATION = 5;
const LOCAL_USER = null; // Shorthand for initiating local user actions
const START_STREAM = true;
const STOP_STREAM = !START_STREAM;
const NO_CHANGE = undefined;
const SCREEN_SHARE_OVERRIDE = true;
const CANVAS_MANAGER_TIMEOUT = 50;
const logger = AppLogger(LOG_NAME.AppManager);

// eslint-disable-next-line react/prefer-stateless-function
/**
 * @class
 */
class AppManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callerUserName: null,
      displayname: '',
      // muteAudio: false,
      callInitiatedModal: false,
      receivingCallModal: false,
      joinMeetingModal: false,
      percent: 0,

      /* Error modal states start here */
      showError: false,
      /* Error modal related states end here */

      isVideoConsentModalOpen: false,
      isDeclineVideoConsentModalOpen: false,
      isImageCaptureConsentModalOpen: false,
      isCallRecordingModalOpen: false,
      requesterParticipant: {},
      disabledButton: false,
      localStream: null,
      remoteStream: null,
      // eslint-disable-next-line react/no-unused-state
      imageCaptureInProgress: false,
      lastPicture: null,
      stillImageShareModeState: STILL_IMAGE_SHARE_MODE_STATUS.STILL_IMAGE_SHARE_MODE_EXIT,
      activeSharerPId: undefined,
      endCallOnLogout: false,
      /* This state when true indicates system is ready to take user calls;
      when false, backend is reconnecting or some issue is being auto-corrected */
      /* eslint-disable-next-line react/no-unused-state */
      webappReady: false,
      isScreenShareStartedFromRemote: false,
      // NLP
      captionStr: '',
      isNLPWord: true,
      captionsOn: false,
      toLanguage: NLP_LANGUAGES[12].value,
      fromLanguage: NLP_LANGUAGES[12].value,
      // zoom
      zoomLevel: 1,
      zoomMinRange: ZOOM_SLIDER_VALUES.MIN,
      zoomMaxRange: ZOOM_SLIDER_VALUES.MAX,
      // illumination
      isOnFlashLight: false,
      // TS Color
      tsColor: TELESTRATION_COLORS[0].colorCode,
      remoteTSColor: TELESTRATION_COLORS[0].colorCode,

      imageCapCancelled: false,
    };
    /**
     * @property {CallManager} callMgr  SIP Call Manager instance.
     */
    this.callMgr = null;
    this.checkUpdates = null;
    this.nextWebRtcTokenUpdate = null;
    this.callRecords = [];

    this.imageShare = {
      /**
       * @property {Object} captureData  Last image capture data
       */
      captureData: null,
      /**
       * @property {IMAGE_SHARE_MODE} mode Indicating state of image sharing
       */
      mode: IMAGE_SHARE_MODE.NONE,

      clearState: (instance) => {
        // NEED TO FIX THIS PROPERLY
        if (instance) {
          instance.imageShare.mode = IMAGE_SHARE_MODE.NONE;
          instance.props.setImageShareModeAction(instance.imageShare.mode);
          instance.imageShare.captureData = null;
          instance.setState({
            isImageTransferModalOpen: false,
            percent: 0,
            isReceiveMode: false,
            lastPicture: null,
            imageCaptureInProgress: false,
          });
        }
      },
      /* Image share mode active */
      isActive: () => (this.imageShare.mode !== IMAGE_SHARE_MODE.NONE),

      /* Set image share active */
      setMode: (mode) => {
        this.imageShare.mode = mode;
        this.props.setImageShareModeAction(this.imageShare.mode);
      },
    };

    this.screenShare = {
      state: SCREEN_SHARE_STATE.STOPPED,
      ssMediaProfile: initScreenShareMediaProfile(),
      lastProfile: null,
      lastVideoState: {
        wasOn: false,
        videoSource: null,
        profile: null,
      },
      pushVideoState: () => {
        this.screenShare.lastVideoState = {
          ...this.screenShare.videoStreamState,
          wasOn: LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer(),
          source: this.props.selectedVideoDevice,
          profile: this.props.selectedVideoProfile,
        };
        logger.debug('AppManager::ScreenShare::Previous video state',
          JSON.stringify({
            videoOn: this.screenShare.lastVideoState.wasOn,
            profile: this.screenShare.lastVideoState.profile?.name,
          }));
        // We need to preserve the profile as updateVideoMediaConfig is called much
        // later than the screen share clean up step.
        // We may need to set it selectively based on if video was on
        // But let that be TODO, as there are side-effects related to profile changes
        this.screenShare.lastProfile = this.props.selectedVideoProfile;
      },
      popVideoState: (clearLastState = false) => {
        const prevState = _.clone(this.screenShare.lastVideoState);
        if (clearLastState) this.screenShare.lastVideoState = {};
        return prevState;
      },

      // Allow screen share if policy is enabled and image share not active
      isAllowed: () => (!this.imageShare.isActive()) &&
        (this.props.screenSharePolicy === POLICY.YES) &&
        (window.dynamicEnv.REACT_APP_ALLOW_SCREEN_SHARE === 'true'),

      // Clear all states for screen share, no flows started
      clearState: () => {
        this.screenShare.set_STOPPED();
        this.props.setScreenShareAction(!SCREEN_SHARE_ON);
        this.screenShare.popVideoState(true);
        this.setState({
          isScreenShareStartedFromRemote: false,
        }, () => {
          logger.info('ScreenShare::Cleared states');
        });
      },

      /**
       * Triggers flow for start of screen share
       */
      performStart: async () => {
        logger.info('ScreenShare::Starting...');
        this.screenShare.set_ABOUT_TO_START();
        // Store old video state to be restored
        this.screenShare.pushVideoState();
        if (LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer()) {
          // If we are sharing; preserve the state for later resuming
          // and then initiate change device
          this.changeVideoDevice(SCREEN_SHARE_DEVICE);
        } else {
          try {
            /* This will trigger stop if remote sharing followed by start
            * from handlePrepareToShareStream */
            await this.callMgr.captureAndStoreStreamFromDesktop();
            this.startOrRequestVideoStream(LOCAL_USER,
              STREAM_SHARE_ACTION.START_SHARE,
              MEDIA_TYPE.SCREEN);
          } catch (error) {
            logger.warn('ScreenShare::able to start:', error);
          }
        }
      },

      performStop: () => {
        this.screenShare.set_ABOUT_TO_STOP();
        const lastVideoState = this.screenShare.popVideoState();
        if (lastVideoState.wasOn) {
          // We were streaming before screen share started so need to revert
          logger.info('ScreenShare::Stopping screen share; video share to resume with',
            this.screenShare.lastVideoState.source?.label);
          if (lastVideoState.source) {
            this.screenShare.switchToVideo(lastVideoState.source);
            return;
          }
        }
        // Stop stream share
        logger.info('ScreenShare::Stopping...');
        this.startOrRequestVideoStream(LOCAL_USER, STREAM_SHARE_ACTION.STOP_SHARE,
          MEDIA_TYPE.SCREEN);
        this.screenShare.clearState();
      },

      switchToVideo: (videoSource) => {
        // Only clear state, change to video to be done from changeDevice
        let switchStatus = false;
        logger.info('ScreenShare::',
          'Switching to video with device - ', videoSource?.label);
        this.screenShare.set_ABOUT_TO_STOP();
        // We switch to device share
        if (videoSource) {
          this.changeVideoDevice(videoSource, SCREEN_SHARE_OVERRIDE);
          switchStatus = true;
        }
        this.screenShare.clearState();
        return switchStatus;
      },

      /**
       * Error in screen share; always stop gracefully to revert to a clean state
       * @param {object} error
       */
      handleError: () => {
        if (this.screenShare.check_ABOUT_TO_START()) this.screenShare.clearState();
        else if (this.screenShare.check_STARTED()) {
          this.screenShareHandler(STREAM_SHARE_ACTION.STOP_SHARE);
        }
      },
      setRemoteShareState: (stateFlag) =>
        this.setState({ isScreenShareStartedFromRemote: stateFlag }),
      // FIXME - unclear the use of this
      setLastProfile: () => {
        this.screenShare.lastVideoState.profile = this.props.selectedVideoProfile;
      },
      // eslint-disable-next-line no-unused-vars
      handleStateChange: (prevState) => {
        logger.debug('ScreenShare::State changed from',
          CommonUtility.mapName(SCREEN_SHARE_STATE, prevState),
          'to', CommonUtility.mapName(SCREEN_SHARE_STATE, this.screenShare.state));
        if (this.screenShare.state === SCREEN_SHARE_STATE.STARTED) {
          this.props.setScreenShareAction(SCREEN_SHARE_ON);
        } else if (this.screenShare.state === SCREEN_SHARE_STATE.STOPPED) {
          this.props.setScreenShareAction(!SCREEN_SHARE_ON);
        }
      },
    };
    // Initialize state, and 'set_' and 'check_' state handlers
    CommonUtility.initStateHandlers(this.screenShare,
      SCREEN_SHARE_STATE,
      this.screenShare.handleStateChange,
      SCREEN_SHARE_STATE.STOPPED);

    /**
     * @property {Object} error Call error details when non-null
     */
    this.error = {
      popup: null,
      errDisplayTimer: null,
      clear() {
        if (this.errDisplayTimer !== null) {
          clearTimeout(this.errDisplayTimer);
          this.errDisplayTimer = null;
        }
        // eslint-disable-next-line react/no-unused-class-component-methods
        this.popup = null;
      },
    };

    /* Convenience function to initialize error popups */
    this.initErrorPopups();
    this.browserPermissions = { audio: false, video: false };
    this.videoTorchState = false;
    this.imgCapTorchModeState = {
      auto: false,
      off: false,
      flash: false,
    };
    this.teleHelper = null;
    this.meetingsManager = null;
  }

  // #region Lifecycle methods
  componentDidMount() {
    logger.info(
      'AppManager:componentDidMount::useragent::',
      navigator.userAgent,
    );

    // ----- Perform initializations -----
    this.initiateSipPlugin();

    // Call API after every 10 mins to check for updates & keep OPM session alive
    this.checkUpdates = setInterval(() => {
      if (this.props.authToken) {
        logger.debug('API Call after 10 minutes');
        const lastModifiedProfile = this.props
          .profileRequestTimestamp
          ? this.props.profileRequestTimestamp
          : this.props.loginRequestTimeStamp;
        // Check for client policy updates
        // Dont need to call ClientPolicy API as we are calling either one of the ,
        // API(Profile API),periodically while call is in progress
        if (!this.callMgr.isCallInProgress()) {
          const lastModifiedPolicy = this.props.policyRequestTimestamp
            ? this.props.policyRequestTimestamp
            : this.props.loginRequestTimeStamp;
          this.props.getClientPolicyAction(lastModifiedPolicy);
        }
        // Check for profile updates
        this.props.getProfileAction(lastModifiedProfile);
      }
    },
    Math.max(this.props.sessionPollingInterval * 1000,
      TIMER_CONSTANTS.UPDATES_CHECK_MILLISECONDS));
    Participants.initAPI(
      {
        loggedInUserName: this.props.loggedInUserName,
        localTSColor: this.state.tsColor,
        defaultRemoteTSColor: this.state.remoteTSColor,
      },
      (participants, forceUpdate = false) => {
        /* Update state only if there is a change else skip */
        if (forceUpdate || !_.isEqual(this.props.participants, participants)) {
          this.props.setParticipantsAction(participants);
        }
      },
      () => {
        this.props.clearParticipantsAction();
      },
    );

    this.scheduleNextWebRtcTokenUpdate();

    // ----- Install all event handlers  -----
    // Added to register ondevicechange event handler to fetch updated list of devices
    // Prepare for subsequent calls Get list of available devices
    browserAPI.onDeviceChange((updatedDevices) => {
      logger.info('ondevicechange event', JSON.stringify(updatedDevices));
      this.setListOfDevices(MEDIA_TYPE.ANY,
        { updateVideoMedia: true }); // trigger media update if required
    });
    // Need to revisit and check if react libraries can be used to attach listener
    browserAPI.onOrientationChange(() => this.handleOrientationChange());

    if (this.props.loggedInUserName) {
      const callHistory =
        JSON.parse(
          localStorage.getItem(
            `${this.props.loggedInUserName}${LOCAL_STORAGE_KEY.CALL_HISTORY_RECORD}`,
          ),
        ) || [];
      localStorage.setItem(
        `${this.props.loggedInUserName}${LOCAL_STORAGE_KEY.CALL_HISTORY_RECORD}`,
        JSON.stringify(callHistory),
      );
    }
    this.callRecords = this.getDataFromLocalStorage();
    // FIXME Narrowing the call history issue
    const errRecs = this.callRecords.filter((rec) => rec.id === undefined);
    if (errRecs && errRecs.length > 0) logger.warn('Error records', errRecs);

    // Store NLP data in local storage, state and initialize NlpHelper
    this.setNLPData();

    this.teleHelper =
      new TelestrationHelper(this.props.loggedInUserName);

    // Store local ts color in redux
    this.storeLocalColor();

    // Plumb Tele API to/fro LSDS
    this.teleHelper.initDS(LS_DATASTREAM_PROTOCOL.initTeleCallback(this.teleHelper.api));
    logger.info('TeleHelper initialized');
    // initialize call statistics manager
    if (window.dynamicEnv.REACT_APP_REPORT_CALL_STATS === 'true') {
      CallStatisticsManager.start({
        version: onsightProductVersion(),
        name: this.props.loggedInUserName,
        pid: LS_DATASTREAM_PROTOCOL.getLocalPId(),
        sipServer: this.props.clientSettingsData?.sip_registrar,
        sipUri: this.props.userData?.uri,
        sipTransportType: this.props.clientSettingsData?.sip_transtype === 2 ? 'Tls' : 'Tcp',
        sipRegistered: false,
      });
    }

    // initialize meetings
    if (window.dynamicEnv.REACT_APP_ALLOW_MEETINGS === 'true' &&
      this.props.meetingSettings?.enabled === POLICY.YES &&
      this.props.meetingSettings?.url) {
      this.initializeMeetingsManager();
    }

    // handle pending launch parameters
    const { launchParameters } = this.props;
    if (launchParameters) {
      // clear launch parameters
      this.props.setLaunchParametersAction(null);
      if (launchParameters.action === LAUNCH_PARAMETER_ACTION.MEETING &&
        launchParameters.meetingId) {
        if (window.dynamicEnv.REACT_APP_ALLOW_MEETINGS === 'true') {
          this.meetingsManager?.joinMeeting(launchParameters.meetingId);
        }
      }
    }

    browserAPI.onWindowResize(() => {
      if (!LS_DATASTREAM_PROTOCOL.isActiveSharerUnknown()) {
        setTimeout(() => { // #1688 fix
          if (this.imageShare.captureData) {
            this.teleHelper.reInitializeCanvasManagerForImage(
              this.props.selectedVideoProfile,
            );
          } else {
            this.teleHelper.canvasResize(this.props.selectedVideoProfile);
          }
        }, 50);
      }
    });
    this.setListOfDevices(MEDIA_TYPE.AUDIO, { updateVideoMedia: false });
    UB.initialize();
    registerDemoAction(this.startDemo);
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    // Mute/Un-mute only if call is in progress
    if (this.props.participants && this.props.participants.length > 0) {
      if (prevProps.videoOn !== this.props.videoOn) {
        this.muteUnmuteVideo();
      }
      if (prevProps.audioOn !== this.props.audioOn) {
        this.muteUnmuteAudio();
      }
    }
    if (this.props.isOPMSessionExpired && this.callMgr) {
      this.callMgr.endMediaServerSession();
      this.callMgr = null;
    }
    // Check for the selected video Profile (#577)
    // Check if selectedVideoProfile exists
    // do not execute media config changes if user has logged out during call (#658)
    if (
      this.props.selectedVideoProfile &&
      prevProps.selectedVideoProfile !== this.props.selectedVideoProfile
    ) {
      // Call the function to update video quality here...
      logger.debug(
        'AppManager:componentDidUpdate::video profile has changed:',
        JSON.stringify(this.props.selectedVideoProfile),
        JSON.stringify(this.props.videoMediaConfigs),
        JSON.stringify(this.props.selectedVideoDevice),
      );
      if (this.callMgr && this.callMgr.isCallInProgress()) {
        this.callMgr.changeMediaConfigs(
          this.props.audioMediaConfigs,
          this.props.selectedVideoProfile,
          LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer(),
          this.props.isVideoPaused,
        );
        if (!LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer()) {
          const mediaQualityIndex =
            this.props.selectedVideoProfile.streamQuality;
          const videoMediaConfig = this.props.selectedVideoProfile;

          const audioMediaConfig = this.props.audioMediaConfigs.find(
            (mediaConfig) =>
              mediaConfig.streamQuality === mediaQualityIndex,
          );
          LS_DATASTREAM_PROTOCOL.setCurrentVideoMediaConfig(
            videoMediaConfig,
          );
          LS_DATASTREAM_PROTOCOL.setCurrentAudioMediaConfig(
            audioMediaConfig,
          );
          LS_DATASTREAM_PROTOCOL.changeRemoteStreamQuality(
            mediaQualityIndex,
            videoMediaConfig,
          );
        }
        logger.debug('Reset telestrations when video config change and participants list gets update', prevProps.participants?.length, this.props.participants?.length);
        const FOR_VIDEO_ONLY = true;
        const SEND_ERASE = false;
        this.teleHelper.handleTelestrationsOnActions(
          this.props.selectedVideoProfile,
          !this.imageShare.captureData,
          FOR_VIDEO_ONLY,
          SEND_ERASE,
        );
      }
    }
    if (
      prevProps.userData?.uri !== this.props.userData?.uri &&
      !this.callMgr?.isCallInProgress() &&
      this.props.userData
    ) {
      /*  User's SIP URI has changed,
      There is no ongoing call,
      Call the function to re-register the user with updated SIP URI */
      logger.info('SIP URI has changed, initiating re-registeration');
      const userName = `${MEDIA_SERVER_CONNECTION.SIP}${this.props.userData
        && this.props.userData.uri}`;
      this.callMgr.registerUser(
        true,
        userName, /* Updated user name */
      );
    }
    this.callRecords = this.getDataFromLocalStorage();
    if (this.callMgr?.isCallInProgress()) {
      // Show videofallback UI when no one is sharing video
      if (LS_DATASTREAM_PROTOCOL.isActiveSharerUnknown()) {
        // If no participnat is sharing the video, old image is shared from thick client
        // the video fallback UI should get updated with respect to image data
        this.props.setShowVideoFallbackUI(!this.imageShare.captureData);
      } else {
        this.props.setShowVideoFallbackUI(false);
      }
    }

    // update the webrtc token
    if (this.props.webRtcToken && this.callMgr) {
      this.callMgr.updateWebRtcToken(this.props.webRtcToken);
      this.scheduleNextWebRtcTokenUpdate();
    }

    // Send the updated languages selected by the user and restart nlpAudio
    this.nlpHelper?.restartAudio(this.state.fromLanguage, this.state.toLanguage);

    /*
    * Update participants viewfinder colours
    */
    if (this.callMgr?.isCallInProgress()) {
      let updatedParticipants = this.props.participants;
      // Update local user colour in localstorage on colour change
      if (this.props.userTSData.colorCode !== prevProps.userTSData.colorCode) {
        this.teleHelper.saveTSColor(this.props.userTSData.colorCode);
        // Get the updated participant list
        updatedParticipants = Participants.updateColours(
          this.props.participants,
          this.teleHelper.saveTSColor(),
        );
      }
      if (this.props.participants !== updatedParticipants) {
        Participants.set(updatedParticipants, true);
      }
      // #24404
      if (prevProps.location.pathname !== this.props?.location.pathname
        && window.innerWidth > 1023 && this.props.isPortrait) {
        this.teleHelper.handleTeleCanvasOnComponentToggle(
          this.imageShare.captureData,
          this.props.selectedVideoProfile,
        );
      }
    }
    // Clear captions when captions switch toggled
    if (this.state.captionsOn !== prevState.captionsOn) {
      this.setState({ captionStr: '' });
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    // eslint-disable-next-line no-unused-vars
    this.setState = (state, callback) => { };
    navigator.mediaDevices.ondevicechange = null;
    if (this.callMgr) {
      this.callMgr.endMediaServerSession();
      this.callMgr = null;
    }
    this.error.clear();
    this.props.setVideoIconAction(false);
    this.props.setAudioIconAction(true);
    clearInterval(this.checkUpdates);
    if (window.dynamicEnv.REACT_APP_REPORT_CALL_STATS === 'true') {
      CallStatisticsManager.stop();
    }
    browserAPI.removeOrientationChange(() => logger.info('Removing orientationchange listener'));
    this.teleHelper.clearListners();
    this.videoTorchState = false;
    this.imgCapTorchModeState = {
      auto: false,
      off: false,
      flash: false,
    };
    this.props.setVideoPauseAction(false);
  }
  // #region

  scheduleNextWebRtcTokenUpdate = (retry) => {
    const split = this.props.webRtcToken?.token?.split(',');
    if (split?.length) {
      const expiry = (split?.length) ? parseInt(split[0], 10) : 0;
      const now = Math.floor(Date.now() / 1000);
      /* eslint-disable-next-line no-unsafe-optional-chaining */
      const refresh = expiry - (this.props.webRtcToken?.expirySeconds / 2);
      const timeout = Math.max(refresh - now,
        retry ? TIMER_CONSTANTS.WEBRTC_TOKEN_MIN_UPDATE_REQUEST_PERIOD : 0);

      logger.debug('scheduleNextWebRtcTokenUpdate: token refresh in ' + timeout + ' seconds, token expires in ' + (expiry - now) + ' seconds');
      clearTimeout(this.nextWebRtcTokenUpdate);
      this.nextWebRtcTokenUpdate = setTimeout(() => {
        if (this.props.authToken) {
          this.props.getWebRtcTokenAction().catch(() => {
            // retry the request later
            this.scheduleNextWebRtcTokenUpdate(true);
          });
        }
      }, timeout * 1000);
    }
  }

  onReceivingNlpCaption = (options) => {
    logger.debug('changeNlpState() captionStr:', options);
    this.setState({ captionStr: options?.captionStr, isNLPWord: options?.isNLPWord }, () => {
      setTimeout(() => {
        if (this.state.captionsOn && this.state.captionStr && this.state.isNLPWord) {
          this.setState((prevState) => ({
            captionStr: prevState.captionStr.concat('.'), isNLPWord: false,
          }));
        }
      }, 5000);
    });
  }

  /**
   * Event listener called on change of orientation detected
   * If call is in progress, resets the display and scales in accordance with the orientation
   * @property {Function}
   * @returns none
   */
  handleOrientationChange() {
    if (!this.callMgr?.isCallInProgress()) {
      logger.debug(
        'Call is not in progres, ignoring orientation change:',
        this.callMgr?.isCallInProgress(),
      );
      return;
    }
    const videoConfig = this.props.selectedVideoProfile ??
      VIDEO_MEDIA_CONFIGS[DEFAULT_MEDIA_CONFIG.value];
    const streamQuality = this.props.selectedVideoProfile ?
      this.props.selectedVideoProfile.streamQuality : DEFAULT_MEDIA_CONFIG.value;
    logger.info(`Orientation change:: using Video Config as: ${videoConfig}`);

    if (!this.props.isVideoPaused) {
      this.callMgr.setVideoConfigs(
        videoConfig,
        this.props.isScreenShareStarted,
        this.isloggedInUserVideoSharer(),
      );
    }

    if (LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer()) {
      LS_DATASTREAM_PROTOCOL.notifyAboutChangeInMediaConfig(
        { audio: true, video: true },
        streamQuality,
      );
      // Reset telestrations if active video sharer changes orientation-#1614
      // Timeout is added to receive erase event after STREAM_START_COMPLETE
      setTimeout(() => {
        const FOR_VIDEO_ONLY = true;
        this.teleHelper.handleTelestrationsOnOrientationChange(
          this.imageShare.captureData,
          FOR_VIDEO_ONLY,
        );
      }, 1000);
    }
  }

  /**
   * Gets browsers device lists and
   * sets in reducer if it has been updated
   * independent of if call in progress.
   * Invoked from:
   * 1. AudioSource panel - to get updated list of audio devices
   * 2. VideoSource panel - to get updated list of audio devices
   * 3. On call start - to get latest devices
   * 4. On video share start - to get the latest device list
   * 5. On device change event from browser
   * 6. on sharer change - restores the device list to the local
   * @param {MEDIA_TYPE} mediaType - informational
   * @param {Object} options { updateVideoMedia, forceRefresh, fetchVideoSource, interactive }
   * updateVideoMedia - false, when we are in the middle of starting stream or
   * or invoking the panels, then video media should not be updated
   * forceRefresh - pass this to browser api to force a re-fetch of device list
   * if false, returns from local cache
   * fetchVideoSource - a hint to suggest that app is interested in getting
   *  a video soure access
   * interactive - flag indicating if error should be shown
   */
  // eslint-disable-next-line react/sort-comp
  setListOfDevices = (mediaType = MEDIA_TYPE.ANY,
    options = null) => {
    // Update video sources if remote user not sharing
    let updateVideoList = !LS_DATASTREAM_PROTOCOL.isRemoteUserSharing();
    const updateVideoMedia = options?.updateVideoMedia ?? false;
    const forceRefresh = options?.forceRefresh ?? false;
    const fetchVideoSource = options?.fetchVideoSource ?? false;
    const interactive = options?.interactive ?? false;

    if (mediaType !== MEDIA_TYPE.ANY &&
      (mediaType !== MEDIA_TYPE.VIDEO)) {
      // Mediatype can be audio only
      updateVideoList = false;
    }

    logger.info(`setListOfDevices::Updating devices for ${mediaType}`,
      `${updateVideoList ? 'Updating' : 'Not updating'} video sources,`,
      `${updateVideoMedia ? 'Updating' : 'Not updating'} video media,`,
      `${forceRefresh ? 'Force refresh,' : ','}`,
      `${fetchVideoSource ? 'Fetching video sources' : ''}`);

    return new Promise((resolve, reject) => {
      browserAPI.getDevices(async (devices, err) => {
        if (devices === null) {
          logger.warn('setListOfDevices::Fault in handler!!!');
          reject();
          return;
        }
        logger.info('setListOfDevices::Device list available',
          err ? ' with limitations' : '');
        if (err && interactive &&
          err?.name !== GUM_EXCEPTION.NOT_READABLE_ERROR &&
          !err.message?.includes(FF_CAMERA_BUSY_MSG)) {
          this.setMediaAccessError(mediaType, err?.name);
        }

        const updateDevices = {
          audio: !_.isEqual(
            devices.audioInputDevices,
            this.props.audioInputDeviceList,
          ),
          audioOut: !_.isEqual(
            devices.audioOutputDevices,
            this.props.audioOutputDeviceList,
          ),
          video: !_.isEqual(
            devices.videoInputDevices,
            this.props.videoInputDeviceList,
          ),
        };

        // Do nothing if no change, dont even log

        if (!(updateDevices.audio || updateDevices.audioOut || updateDevices.video)) {
          resolve();
          return;
        }

        logger.debug('setListOfDevices',
          'isequal--audioinput--',
          _.isEqual(
            devices.audioInputDevices,
            this.props.audioInputDeviceList,
          ),
          'new--',
          JSON.stringify(devices.audioInputDevices),
          'old--',
          JSON.stringify(this.props.audioInputDeviceList));

        logger.debug('setListOfDevices',
          'isequal--audiooutput--',
          _.isEqual(
            devices.audioOutputDevices,
            this.props.audioOutputDeviceList,
          ),
          'new--',
          JSON.stringify(devices.audioOutputDevices),
          'old--',
          JSON.stringify(this.props.audioOutputDeviceList));

        logger.debug('setListOfDevices',
          'isequal--videoinput--',
          _.isEqual(
            devices.videoInputDevices,
            this.props.videoInputDeviceList,
          ),
          'new--',
          JSON.stringify(devices.videoInputDevices),
          'old--',
          JSON.stringify(this.props.videoInputDeviceList));

        const diffAudioInputList = _.unionWith(
          _.differenceWith(devices.audioInputDevices, this.props.audioInputDeviceList, _.isEqual),
          _.differenceWith(this.props.audioInputDeviceList, devices.audioInputDevices, _.isEqual),
          _.isEqual,
        );
        const diffAudioOutputList = _.unionWith(
          _.differenceWith(devices.audioOutputDevices, this.props.audioOutputDeviceList, _.isEqual),
          _.differenceWith(this.props.audioOutputDeviceList, devices.audioOutputDevices, _.isEqual),
          _.isEqual,
        );
        const diffVideoInputList = _.unionWith(
          _.differenceWith(devices.videoInputDevices, this.props.videoInputDeviceList, _.isEqual),
          _.differenceWith(this.props.videoInputDeviceList, devices.videoInputDevices, _.isEqual),
          _.isEqual,
        );
        logger.debug(`updateMedia::diffAudioInputList:${JSON.stringify(diffAudioInputList)}
          diffAudioOutputList:${JSON.stringify(diffAudioOutputList)} diffVideoInputList:${JSON.stringify(diffVideoInputList)}`);

        // Update device lists audio +/ video based on remote sharing status
        await this.props.setDeviceList(
          ACTIONS.SET_DEVICE_LIST,
          devices.audioInputDevices,
          devices.audioOutputDevices,
          updateVideoList ? devices.videoInputDevices : this.props.videoInputDeviceList,
        );

        logger.debug('updateMedia::videoInputDeviceList in store:', this.props.videoInputDeviceList);

        // Select Audio +/ Video devices as required based on updateVideoList status
        const selectedSources = this.selectCurrentMediaSource(updateVideoList ?
          MEDIA_TYPE.ANY : MEDIA_TYPE.AUDIO);

        logger.info('setListOfDevices::Device list updated');

        if (selectedSources.video) {
          // Update video media indicates if live media update is allowed / expected
          this.updateMedia(MEDIA_TYPE.VIDEO, selectedSources.video,
            { updateVideoMedia });
        }
        if (selectedSources.audioInput) {
          this.updateMedia(MEDIA_TYPE.AUDIO, selectedSources.audioInput);
        }
        if (selectedSources.audioOutput) {
          this.updateMedia(MEDIA_TYPE.AUDIO, selectedSources.audioOutput,
            { audioOut: true });
        }

        resolve();
      }, { forceRefresh, fetchVideoSource });
    });
  };

  /**
   * Triggers device changes based on the current call state and updates in devices
   * @param {MEDIA_TYPE} mediaType  type of media thats changed
   * @param {MediaDevice} selectedDevice  Current selection of device
   * @param {bool} audioOut true indicates input device, false for output
   * @returns {void}
   */
  updateMedia = (mediaType, selectedDevice,
    options = { audioOut: false, updateVideoMedia: false }) => {
    if (mediaType === MEDIA_TYPE.AUDIO) {
      if (!options.audioOut &&
        (selectedDevice?.deviceId !== this.props.selectedAudioInputDevice?.deviceId)) {
        logger.info('updateMedia::setting audio input',
          selectedDevice?.label);
        this.changeAudioDevice(selectedDevice);
        return;
      }

      if (options.audioOut &&
        (selectedDevice?.deviceId !== this.props.selectedAudioOutputDevice?.deviceId)) {
        logger.info('updateMedia::setting audio output',
          selectedDevice?.label);
        this.changeAudioDevice(selectedDevice, true);
        return;
      }
      return;
    }
    // Update for video
    if (LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer() &&
      (options.updateVideoMedia === true)) { // if media update is allowed
      if (selectedDevice?.deviceId !== this.props.selectedVideoDevice?.deviceId) {
        // Video device change can be triggered only if we are sharing
        logger.info('updateMedia::Triggering updates for video source changes',
          selectedDevice?.label);
        this.changeVideoDevice(selectedDevice);
      } else {
        // Update remote about change in our device config
        this.setVideoDevicesAndSelected(this.props.videoInputDeviceList, selectedDevice);
        LS_DATASTREAM_PROTOCOL.notifyAboutChangeInVideoSources();
      }
    } else if (!LS_DATASTREAM_PROTOCOL.isRemoteUserSharing()) {
      // Updating only with current selection if no one is sharing
      this.setVideoDevicesAndSelected(NO_CHANGE, selectedDevice);
    }
  };

  // #region Callbacks from CallManager on session/call events
  /* eslint-disable-next-line no-unused-vars */
  /**
   * Callback fired from {@link CallManager} to update on session state changes
   * @param {WEB_CALL_STATE} sessionState The call state
   */
  onSessionEvent = (sessionState) => {
    /* eslint-disable-next-line */
    if (sessionState === WEB_CALL_STATE.INITIALIZED) {
      /* eslint-disable-next-line react/no-unused-state */
      this.setState({ webappReady: true });
      this.props.hideSpinner();
    } else if (sessionState === WEB_CALL_STATE.DISCONNECTED) {
      /* eslint-disable-next-line react/no-unused-state */
      this.setState({ webappReady: false });
    } else if (sessionState === WEB_CALL_STATE.RECONNECTED) {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ webappReady: true });
      this.props.hideSpinner();
      this.setError(ERROR_TYPES.sessionUp, { appname: translate('APPNAME') });
    }
    CallStatisticsManager.updateLocalParticipant(
      {
        sipRegistered: sessionState === WEB_CALL_STATE.INITIALIZED,
      },
    );
  }

  /**
   * Called to update on the media path state changed to UP after being DOWN
   * on account of transient network error
   * @param {MEDIA_PATH_STATE} mediaState   Typically UP
   */
  onMediaPathChange = (mediaState) => {
    if (mediaState === MEDIA_PATH_STATE.UP) {
      this.handleDismissError();
      this.props.hideSpinner();
    }
  }

  onCallDisconnect = () => {
    this.callMgr.performLSDisconnect(
      LS_DATASTREAM_PROTOCOL.sendDatastreamHangupMessage(false) === true,
    );
  }

  receivedLocalPID = (pid) => {
    if (LS_DATASTREAM_PROTOCOL && pid) {
      LS_DATASTREAM_PROTOCOL.setLocalPid(pid);
    }
  }

  datastreamHangupMessageReceived = () => {
    LS_DATASTREAM_PROTOCOL.sendDatastreamHangupMessage(true);
  }

  onError = (callState, callError, errInfo = null) => {
    /* The call was in progress when we got a disconnect */
    logger.assert((callState !== undefined) && (callError !== undefined));

    logger.assert(callError !== CALL_ERROR.NONE,
      'APP-FSM: onError callback without error condition!!');
    if (callError === CALL_ERROR.INIT_FAILED) {
      logger.assert(false, 'HANDLE THIS CASE');
    } else if (_.inRange(callError, CALL_ERROR.INIT_ERR,
      CALL_ERROR.MAX_SESSION_ERROR)) {
      this.onSessionError(callError, callState, errInfo);
    } else {
      this.onCallError(callError, callState, errInfo);
    }
  };

  onConsentDialog = (state) => {
    logger.debug('onConsentDialog', state);
  };

  onCallInitiated = (callId) => {
    logger.info(
      'onCallInitiated from',
      this.state.calleeName,
      'with callID:',
      callId,
    );
    this.setState({ disabledButton: false });
    const callDate = new Date().toISOString();
    const calleeName = CommonUtility.getUserNameFromContacts(
      this.state.calleeName,
      this.props.contactList,
    );
    if (
      !CommonUtility.isCallHistoryRecordPresentForCallId(
        callId,
        this.getDataFromLocalStorage(),
      )
    ) {
      const callRecord = {
        callee: calleeName,
        date: callDate,
        direction: CALL_DIRECTION.OUTGOING,
        id: callId,
        caller: this.props.loggedInUserName,
        remoteSipUri: this.state.sipUsername,
      };
      console.debug(`AppManager:: OnCallInitiated:: save call record in history ${JSON.stringify(callRecord)}`);
      this.saveCallHistoryRecordToLocalStorage(
        this.props.loggedInUserName,
        callRecord,
      );
      logger.assert(calleeName !== undefined && callId !== undefined, 'ERROR in call history');
    }
  };

  onIncomingCall = (callerUserName, callId, displayname) => {
    logger.info(
      'onIncomingCall::calledBy::',
      callerUserName,
      callId,
    );

    let userDisplayName = displayname;
    if (userDisplayName && userDisplayName.includes(MEDIA_SERVER_CONNECTION.SIP)) {
      userDisplayName = CommonUtility.getUserNameFromContacts(
        userDisplayName,
        this.props.contactList,
      );
    }

    if (this.meetingsManager?.isInMeeting() &&
      (!this.meetingsManager?.isMeetingHostActive() ||
        !this.meetingsManager?.isMeetingHostAddress(callerUserName))) {
      // decline non-meeting calls while in a meeting
      this.callMgr?.endCall(true);
      return;
    }

    // auto accept the call if we are waiting to join a meeting
    const autoAcceptCall = this.meetingsManager?.isInMeeting();
    if (!autoAcceptCall) {
      // Display browser notification for incoming call
      const notificationText = translate('browserNotification.isCalling', { userDisplayName });
      browserNotification.createNotification(translate('APPNAME'), notificationText, ocLogo, INCOMING_CALL_TAG);
    }

    this.setState({
      callerUserName,
      receivingCallModal: !autoAcceptCall,
      displayname: userDisplayName,
    }, () => {
      const callDate = new Date().toISOString();
      const callerName = CommonUtility.getUserNameFromContacts(
        callerUserName,
        this.props.contactList,
      );
      logger.debug(
        ` [${this.callMgr?.getCallId()}] Incoming call received`,
      );
      if (!CommonUtility.isCallHistoryRecordPresentForCallId(
        callId,
        this.getDataFromLocalStorage(),
      )
      ) {
        const callRecord = {
          callee: this.props.loggedInUserName,
          date: callDate,
          direction: CALL_DIRECTION.INCOMING,
          id: callId,
          caller: this.state.displayname
            ? this.state.displayname
            : callerName,
          remoteSipUri: callerUserName,
        };
        console.debug(`AppManager:: On incoming call: Save call history record ${JSON.stringify(callRecord)}`);
        this.saveCallHistoryRecordToLocalStorage(
          this.props.loggedInUserName,
          callRecord,
        );
      }
      this.onCallStart();

      if (autoAcceptCall) {
        this.acceptCall();
      }
    });
  };

  getMeetingId = () => this.meetingsManager?.getMeetingId();

  setMeetingId = (meetingId) => {
    if (this.canJoinIncomingMeeting(meetingId)) {
      this.meetingsManager?.addCurrentUserAsNewMeetingParticipant(meetingId, true);
    }
  }

  /**
   * User can join the incoming meeting if they are not registered for a meeting
   * or if the user is not active in a meeting they can join the meeting if the
   * incoming meeting is different than the current meeting.
   * @param {string} incomingMeetingId The incoming meeting id.
   * @returns {bool} True if the current user can join the meeting.
   */
  canJoinIncomingMeeting(incomingMeetingId) {
    return incomingMeetingId &&
      (!this.meetingsManager?.isInMeeting() ||
        (!this.meetingsManager?.isActiveInMeeting() && this.getMeetingId() !== incomingMeetingId));
  }

  onMissedCall = (missCallBy, callId, callerUri) => {
    logger.info('Missed call by: ', missCallBy, callId, callerUri);
    const callDate = new Date().toISOString();
    const calledBy = missCallBy && missCallBy.startsWith(MEDIA_SERVER_CONNECTION.SIP) ?
      CommonUtility.getUserNameFromContacts(
        missCallBy,
        this.props.contactList,
      )
      : missCallBy;
    const callRecord = {
      callee: this.props.loggedInUserName,
      date: callDate,
      direction: CALL_DIRECTION.INCOMING,
      id: callId,
      isMissCall: true,
      caller: calledBy,
      remoteSipUri: callerUri,
    };
    console.debug(`AppManager::OnMissedCall: Save call history record ${JSON.stringify(callRecord)}`);
    this.saveCallHistoryRecordToLocalStorage(this.props.loggedInUserName, callRecord);
  };

  onCallAccepted = (acceptedUserName, isIncomingCall, callId) => {
    logger.info(
      `%c [${callId}] Call active with peer ${acceptedUserName}`,
      'color:green',
    );
    // Need to revisit this while supporting multiparty calls
    if (!isIncomingCall) {
      this.hideCallingModal();
      clearAxiosSessionExpiryTimer();
      if (!this.isMeeting()) {
        Participants.set([
          {
            name: this.getUserFullNameFromContacts(
              this.props.loggedInUserName,
            ),
            address: CommonUtility.getUserNameWithDomainFromUri(
              this.props.loggedInUserName,
              false,
            ),
            isLoggedInUser: true,
            isVideoOn: false,
            isMuted: false,
            pId: LS_DATASTREAM_PROTOCOL.getLocalPId(),
            colorCode: this.state.tsColor,
          },
          {
            name:
              this.state.calleeName ||
              this.getUserFullNameFromContacts(acceptedUserName),
            address:
              CommonUtility.getUserNameWithDomainFromUri(
                acceptedUserName,
                false,
              ),
            isLoggedInUser: false,
            isVideoOn: false,
            isMuted: false,
            pId: LS_DATASTREAM_PROTOCOL.getRemotePId(),
            colorCode: this.state.remoteTSColor,
          },
        ], true);
      } else {
        Participants.setInitialMeetingParticipants(
          this.props.participants,
          acceptedUserName,
        );
      }
      this.props.history.push(ROUTES.CALL_DASHBOARD);
      this.onCallStart();
    }

    this.callMgr.changeMediaConfigs(
      this.props.audioMediaConfigs,
      this.props.selectedVideoProfile,
      LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer(),
    );

    this.setIlluminationButtonState(true);
    browserNotification.closeNotification();
    // Display notification of recording;
    this.setState({ isCallRecordingModalOpen: true });
  };

  onHangup = (disconnectLSDS = false) => {
    logger.info(
      ` [${this.callMgr.getCallId()}] Hangup`,
      'endCallOnLogout: ', this.state.endCallOnLogout,
      'disconnectLSDS:', disconnectLSDS,
    );

    if (disconnectLSDS) LS_DATASTREAM_PROTOCOL.disconnect();

    this.onCallEnd();
    // Clear the existing telestrations when call ends.
    this.teleHelper.clearTSArray();
    this.teleHelper.removeTextInput();

    this.setState({
      localStream: null,
      remoteStream: null,
      callerUserName: null,
      calleeName: null,
      isVideoConsentModalOpen: false,
      isDeclineVideoConsentModalOpen: false,
      isImageCaptureConsentModalOpen: false,
      isImageTransferModalOpen: false,
      isScreenShareStartedFromRemote: false,
      isReceiveMode: false,
      percent: 0,
      requesterParticipant: {},
      displayname: '',
      activeSharerPId: LS_DATASTREAM_PROTOCOL.getActiveSharerPid(),
      imageCaptureInProgress: false,
      lastPicture: null,
      stillImageShareModeState: STILL_IMAGE_SHARE_MODE_STATUS.STILL_IMAGE_SHARE_MODE_EXIT,
      captionStr: '',
      isOnFlashLight: false,
      isCallRecordingModalOpen: false,
    });
    LS_DATASTREAM_PROTOCOL.exitImageShare();
    this.imageShare.clearState(this);
    // Do not load dashboard if call is ended on click of logout
    if (!this.state.endCallOnLogout) {
      this.props.history.push(ROUTES.DASHBOARD);
    } else {
      /* FIXME! endCallOnLogout is getting set to a non-boolean value
      leading to hung state */
      // eslint-disable-next-line no-lonely-if
      if (typeof this.state.endCallOnLogout !== 'boolean') {
        logger.error('ERROR!!', this.state.endCallOnLogout);
        this.props.history.push(ROUTES.DASHBOARD);
      }
    }
    this.props.setVideoIconAction(false);
    this.props.setAudioIconAction(true);
    // Reset audio output to default on hangup to be in sync with audio-in and video-in
    // As audio-in and video-in are reset using stream device ids when a call starts
    if (!_.isEmpty(this.props.audioOutputDeviceList)) {
      this.props.setAudioOutputDeviceAction(
        this.props.audioOutputDeviceList[0],
      );
    }
    Participants.clear();
    this.hideCallingModal();
    browserNotification.closeNotification();
    this.nlpHelper?.stopSendingRemoteAudioToNlpServer();
    this.videoTorchState = false;
    this.imgCapTorchModeState = {
      auto: false,
      off: false,
      flash: false,
    };
    this.nlpHelper = null;
  }

  onLocalStreamReceived = async (cbData) => {
    const { stream, isScreenShare, illumInfo } = cbData;
    // TODO: Check and log only necessary info
    logger.info(
      'onLocalStreamReceived::stream',
      CommonUtility.printableId(stream?.id),
      'with', WebrtcUtils.hasVideo(stream), '=> video',
      WebrtcUtils.hasAudio(stream), '=> audio tracks',
      isScreenShare ? 'with screen share started' : '',
      illumInfo, 'illumination permissions',
    );
    /* Do all the state updates together */
    this.setState({
      localStream: stream,
    }, () => {
      this.startDrawingOnCanvas();
      if (LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer()) {
        LS_DATASTREAM_PROTOCOL.setIllumInfo(illumInfo);
      }

      // Set audio source in sync with CallMgr and local stream
      this.setSelectedAudioDevices(true);
    });
  };

  onRemoteStreamReceived = async (stream) => {
    logger.debug(
      'onRemoteStreamReceived:: stream tracks length',
      stream,
      stream.getTracks().length,
    );
    logger.info(' Remote Stream Received');
    this.setState({ remoteStream: stream }, () => {
      this.initializeNLPHelper();
      this.nlpHelper?.sendAudioToNLP(stream);
      // Set audio sink in sync with CallMgr and local stream
      this.setSelectedAudioDevices(true);
    });
  };

  /**
   * CallManager::Callback
   * Called when screen share stopped initiated internally
   * or by screen share icon click
   */
  onScreenShareStopped = async () => {
    logger.debug(
      'onScreenShareStopped()',
    );

    if (!this.props.isScreenShareStarted) return;
    // FIX-SCREEN_SHARE
    this.screenShareHandler(STREAM_SHARE_ACTION.STOP_SHARE); // STOP
  }

  /*
  * Callback from call mgr for setting media config on starting/ stopping
  * screen share
  */
  onScreenShareToggle = (options) => {
    logger.debug('onScreenShareToggle() options:', options);
    this.updateVideoMediaConfig(options.isStarted);
  }

  /*
   * Callback from callMgr on media config update
   */
  onMediaConfigUpdate = async (cbData) => {
    const { mediaType, configData, mediaQualityIndex } = cbData;
    logger.info('Media config updated for ', mediaType);
    switch (mediaType) {
      case MEDIA_TYPE.VIDEO:
        LS_DATASTREAM_PROTOCOL.setCurrentVideoMediaConfig(
          configData,
        );
        break;
      case MEDIA_TYPE.AUDIO:
        LS_DATASTREAM_PROTOCOL.setCurrentAudioMediaConfig(
          configData,
        );
        break;

      default:
        logger.warn('Handle this case');
    }

    if (LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer()) {
      LS_DATASTREAM_PROTOCOL.notifyAboutChangeInMediaConfig(
        {
          video: mediaType === MEDIA_TYPE.VIDEO,
          audio: mediaType === MEDIA_TYPE.AUDIO,
        },
        mediaQualityIndex,
      );
    }
  }

  /**
   * Callback from callMgr on stream quality change
   */
  onStreamQualityChange = async (streamQualityIndx) => {
    LS_DATASTREAM_PROTOCOL.setCurrentStreamQuality(streamQualityIndx);
  }

  /* Activities to be done when call is active */
  onCallStart = async () => {
    logger.info('Performing call start initializations');
    browserAPI.onBrowserPermissionChange(PERMISSION.MIC, (status) => {
      this.browserPermissions = status;
      if (!this.browserPermissions.audio) {
        this.onCallError(CALL_ERROR.AUDIO_PERMISSION_DENIED,
          WEB_CALL_STATE.CALL_IN_PROGRESS);
      }
    });
    browserAPI.onBrowserPermissionChange(PERMISSION.CAMERA, (status) => {
      logger.log('onCallStart Browser permission changed to', status);
      this.browserPermissions = status;
      if (!this.browserPermissions.video) {
        this.onCallError(CALL_ERROR.CAMERA_PERMISSION_DENIED,
          WEB_CALL_STATE.CALL_IN_PROGRESS);
      } else if (LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer() &&
        (this.props.streamShareState === STREAM_SHARE_STATE.STREAMING_STARTED)) {
        // Resume video streaming if permission enabled again
        logger.info('Initiating device change due to browser permission changes');
        this.changeVideoDevice(this.props.selectedVideoDevice);
      }
    });
    // TODO: Is this needed on call start if done after mount?
    await this.setListOfDevices(MEDIA_TYPE.ANY, { updateVideoMedia: true });

    if (window.dynamicEnv.REACT_APP_REPORT_CALL_STATS === 'true') {
      // start a call record
      const callData = CallMgr.getCallData();
      CallStatisticsManager.beginCall(
        {
          direction: callData.isIncoming ? 'Incoming' : 'Outgoing',
          localName: callData.displayName,
          localAddress: callData.isIncoming ? callData.calleeUserName : callData.callerUserName,
          remoteName: callData.isIncoming ? this.state.displayname : this.state.calleeName,
          remoteAddress: callData.isIncoming ? callData.callerUserName : callData.calleeUserName,
          sipSessionId: callData.callId,
          sipServer: this.props.clientSettingsData.sip_registrar,
          sipTransportType: this.props.clientSettingsData.sip_transtype === 2 ? 'Tls' : 'Tcp',
          sipUserName: this.props.clientSettingsData.sip_username,
          callGuid: LS_DATASTREAM_PROTOCOL.getCallGuid(),
          localPid: LS_DATASTREAM_PROTOCOL.getLocalPId(),
          remotePid: LS_DATASTREAM_PROTOCOL.getRemotePId(),
          remoteVersion: LS_DATASTREAM_PROTOCOL.getRemoteVersion(),
          localVersion: onsightSysVersion(),
          userName: this.props.loggedInUserName,
        },
      );
    }
    this.teleHelper.checkIfEventIsForCanvas();
    this.teleHelper.onClickOfOffCanvas();
    this.setNLPData();
    this.storeLocalColor();
    this.props.setScreenShareAction(!SCREEN_SHARE_ON);
    this.props.setStreamShareStateAction(STREAM_SHARE_STATE.UNKNOWN);
    this.props.setCallStartAction();
  }

  /**
   * API: From UI to handle screen share actions to start/stop
   * This will be called from UI/AppManager
   */
  async screenShareHandler(action = STREAM_SHARE_ACTION.TOGGLE) {
    const startShare = !this.props.isScreenShareStarted &&
      ((action === STREAM_SHARE_ACTION.TOGGLE) ||
        (action === STREAM_SHARE_ACTION.SCREEN_SHARER));
    const stopShare = this.props.isScreenShareStarted &&
      ((action === STREAM_SHARE_ACTION.TOGGLE) ||
        (action === STREAM_SHARE_ACTION.STOP_SHARE));
    logger.debug(`${CommonUtility.mapName(STREAM_SHARE_ACTION, action)} requested`);
    if (stopShare) this.screenShare.performStop();
    else if (startShare) this.screenShare.performStart();
    else {
      logger.warn('No action initiated');
    }
  }

  onCallGuidUpdate = (callGuid) => {
    if (window.dynamicEnv.REACT_APP_REPORT_CALL_STATS === 'true') {
      CallStatisticsManager.updateCall({ callGuid });
    }
  }

  onSysVersionUpdate = (remoteVersion) => {
    if (window.dynamicEnv.REACT_APP_REPORT_CALL_STATS === 'true') {
      CallStatisticsManager.updateCall({ remoteVersion });
    }

    // display a warning if the remote user does not officially support the web app
    if (!LS_DATASTREAM_PROTOCOL.isWebAppSupportedByRemoteParticipant()) {
      showWarningToast(`${translate('errors.remoteUserWebAppUnsupported', { remoteName: this.getDirectParticipantName(), app: translate('APPNAME') })}`);
    }
  }

  onDelimitStream = (started, stream) => {
    if (window.dynamicEnv.REACT_APP_REPORT_CALL_STATS === 'true') {
      if (started) {
        CallStatisticsManager.beginStream(stream);
      } else {
        CallStatisticsManager.endStream();
      }
    }
  }

  getCallIncoming = () => {
    const callData = CallMgr.getCallData();
    return (callData ? callData.isIncoming : false);
  }

  handleFlashLightChange = (isOnFlashLight) => {
    this.setState({
      isOnFlashLight,
    });
  }

  getDirectParticipantName = () => (this.getCallIncoming() ? this.state.displayname :
    this.state.calleeName);

  onRemoteIllumForVideoStateChange = (newState, toggle = false) => {
    if (LS_DATASTREAM_PROTOCOL.isActiveSharerUnknown()) {
      logger.warn('onRemoteIllumForVideoStateChange() video is not shared by any user, hence returning');
      return;
    }
    if (toggle) {
      // based on newState toggle
      this.toggleIllumination(FILL_LIGHT_MODE.ON);
    }

    this.videoTorchState = newState === IllumState.On;
    this.setState({ isOnFlashLight: newState === IllumState.On });
    logger.debug('onRemoteIllumForVideoStateChange() toggle torch button state for remote:', newState);
  }

  // eslint-disable-next-line default-param-last
  setIlluminationButtonState = async (disableButton = false, deviceId) => {
    logger.debug('setIlluminationButtonState() disable button:', disableButton, 'deviceId:', deviceId);
    const onButton = document.getElementById('on');
    const offButton = document.getElementById('off');
    const flashButton = document.getElementById('flash');
    const autoButton = document.getElementById('auto');

    const class1 = onButton?.getAttribute('class');
    const class2 = offButton?.getAttribute('class');
    const class3 = flashButton?.getAttribute('class');
    const class4 = autoButton?.getAttribute('class');

    logger.debug('illum button states: ', `onButton ${onButton} offButton ${offButton}, flashButton ${flashButton} autoButton ${autoButton}`);

    if (disableButton && onButton && offButton &&
      flashButton && autoButton) {
      if (!class1.includes('disabled')) {
        onButton.classList.add('disabled');
      }
      if (!class2.includes('disabled')) {
        offButton.classList.add('disabled');
      }
      if (!class3.includes('disabled')) {
        flashButton.classList.add('disabled');
      } if (!class4.includes('disabled')) {
        autoButton.classList.add('disabled');
      }
      logger.debug('setIlluminationButtonState() disabled all button');
      return;
    }

    const illumInfo = LS_DATASTREAM_PROTOCOL.getActiveDeviceIllumInfo();
    const { Illumination, Flash } = this.props.clientPolicyPermissionsXmlData;
    logger.debug('setIlluminationButtonState() illumInfo:', JSON.stringify(illumInfo));

    if (!illumInfo?.videoTorchState || !Illumination) {
      onButton?.classList?.add('disabled');
    } else {
      onButton?.classList?.remove('disabled');
      onButton?.classList?.add('cursor-pointer');
    }
    if (offButton) offButton?.classList?.add('disabled');
    if (flashButton) flashButton?.classList?.add('disabled');
    if (autoButton) autoButton?.classList?.add('disabled');
    if (LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer()) {
      if (!illumInfo?.imgCapTorchModeState?.off || !Flash) {
        offButton?.classList?.add('disabled');
      } else {
        offButton?.classList?.add('cursor-pointer');
        offButton?.classList?.remove('disabled');
      }
      if (!illumInfo?.imgCapTorchModeState?.flash || !Flash) {
        flashButton?.classList?.add('disabled');
      } else {
        flashButton?.classList?.remove('disabled');
        flashButton?.classList?.add('cursor-pointer');
      }
      if (!illumInfo?.imgCapTorchModeState?.auto || !Flash) {
        autoButton?.classList?.add('disabled');
      } else {
        autoButton?.classList?.remove('disabled');
        autoButton?.classList?.add('cursor-pointer');
      }
    }
  }

  /* Call ended, do cleanup */
  onCallEnd = () => {
    // Nothing
    logger.info(`[${this.callMgr.getCallId()}] Call over!`);
    /* Clear callback */
    browserAPI.onBrowserPermissionChange(PERMISSION.MIC, null);
    browserAPI.onBrowserPermissionChange(PERMISSION.CAMERA, null);

    if (window.dynamicEnv.REACT_APP_REPORT_CALL_STATS === 'true') {
      const callData = CallMgr.getCallData();
      CallStatisticsManager.endCall(callData.callAborted);
    }

    if (this.meetingsManager?.isInMeeting()) {
      this.endMeeting();
    }
    // Remove text area elements from telestration if any
    this.teleHelper.reset(this.props.selectedVideoProfile, false, true);
    this.teleHelper.clearListners();
    this.props.setScreenShareAction(!SCREEN_SHARE_ON);
    this.props.setCallEndAction();
  }

  /* Handle call errors from the Media Server layers */
  onCallError = async (callError, callState, errInfo) => {
    logger.info(
      'Error in Call event::',
      CommonUtility.mapName(CALL_ERROR, callError),
      'Current Call State:', callState, errInfo,
    );
    const calleeName = CommonUtility.getUserNameFromContacts(
      this.state.sipUsername,
      this.props.contactList,
    );

    switch (callError) {
      case CALL_ERROR.REG_FAILED:
        this.setError(ERROR_TYPES.registrationErr);
        break;

      case CALL_ERROR.CLIENT_NOT_AVAILABLE:
      case CALL_ERROR.REQUEST_TIMEOUT: // calling req timeout
        if (callState === WEB_CALL_STATE.CALL_INITIATED) {
          this.setError(ERROR_TYPES.userNotAvailable);
        }
        break;

      case CALL_ERROR.CALLEE_BUSY: // line busy
        if (callState === WEB_CALL_STATE.CALL_INITIATED) {
          this.setError(
            translate('errors.lineBusy'),
            true,
            `${translate('errors.userCalling', { calleeName })}`,
            null,
            ERROR_TYPES.lineBusy, // modal id
          );
        }
        break;

      case CALL_ERROR.NOT_FOUND_ERROR:
      case CALL_ERROR.DNS_ERROR:
      case CALL_ERROR.SIP_ERROR_WRONG_STATE:
      case CALL_ERROR.BAD_EXTENSION: // calling to invalid sip user with valid domain
      case CALL_ERROR.INTERNAL_SERVER_ERROR: // calling invalid manually added contact
        if (callState === WEB_CALL_STATE.CALL_INITIATED) {
          this.setError(ERROR_TYPES.userNotAvailable);
        } else {
          // Generic error if call in progress
          this.showCallSurveyOrError();
        }
        break;

      case CALL_ERROR.NO_RESPONSE: // calling invalid manually added contact
        this.setError(ERROR_TYPES.userNotResponding);
        break;

      case CALL_ERROR.CALLEE_UNAVAILABLE: // Decline call btn click
        // no error popup
        break;

      case CALL_ERROR.CALL_CANCELLED:
        this.setError(ERROR_TYPES.callCancelled);
        break;

      case CALL_ERROR.CALL_DECLINED:
        this.setError(ERROR_TYPES.callDeclined);
        break;

      case CALL_ERROR.SESSION_TERMINATED: // when call ends
      case CALL_ERROR.REQUEST_TERMINATED: // cancel call
      case CALL_ERROR.CALL_ENDED:
        this.showCallSurveyOrError();
        break;

      case CALL_ERROR.CALL_DISCONNECT_ERR:
        logger.error('CALL_DISCONNECT_ERR');
        this.setError(ERROR_TYPES.inCallConnectFail);
        break;

      case CALL_ERROR.MEDIA_PATH_DOWN:
        this.setError(ERROR_TYPES.inCallNetworkErr, null, null, AUTO_DISMISS_ERR_DURATION);
        break;

      case CALL_ERROR.SIP_UNKNOWN_ERROR:
        this.setError(ERROR_TYPES.unknownSIPError);
        break;

      case CALL_ERROR.SIP_ERROR_INVALID_REQUEST:
        this.setError(ERROR_TYPES.invalidRequest);
        break;

      case CALL_ERROR.SIP_ERROR_MISSING_SDP:
        this.setError(ERROR_TYPES.missingSDP);
        break;

      case CALL_ERROR.AUDIO_PERMISSION_DENIED:
        logger.log('MEDIA_PERMISSION_DENIED:: this.browserPermissions: ', this.browserPermissions);
        this.handleMediaAccessError(MEDIA_TYPE.AUDIO, errInfo);
        break;

      case CALL_ERROR.CAMERA_PERMISSION_DENIED:
        logger.log('MEDIA_PERMISSION_DENIED:: this.browserPermissions: ', this.browserPermissions);
        this.handleMediaAccessError(MEDIA_TYPE.VIDEO, errInfo);
        break;

      case CALL_ERROR.GET_USER_MEDIA_API_EXCEPTION:
        this.setError(ERROR_TYPES.errorInGettingStream);
        break;

      case CALL_ERROR.UPDATE_ERROR:
        logger.error('Update error', JSON.stringify(errInfo));
        break;

      case CALL_ERROR.WRONG_MESSAGE:
        logger.error('Wrong message', JSON.stringify(errInfo));
        break;

      case CALL_ERROR.DEVICE_NOT_FOUND: {
        const errorType = this.callMgr?.isCallInProgress()
          ? ERROR_TYPES.noCameraDevice
          : ERROR_TYPES.noAudioDevice;
        logger.log('errorType: ', errorType, this.callMgr?.isCallInProgress());
        this.setError(errorType);
      }
        break;
      case CALL_ERROR.SCREEN_SHARE_DECLINED:
        this.setError(ERROR_TYPES.screenShareDeclined);
        break;
      case CALL_ERROR.CALL_NO_ANSWER:
        logger.warn('Call is missed!!');
        break;
      default:
        logger.error(`Call error ${callError} not handled.`);
        break;
    }
    if ((this.state.showError) && (this.error.popup !== null)) {
      return;
    }

    /* Continue to check for Generic errors */
    switch (callError) {
      case OPM_SESSION_TERMINATED:
        this.setError(ERROR_TYPES.callEnded);
        break;

      default:
        break;
    }
    if (!this.state.showError || (this.error.popup === null)) {
      logger.warn(`APP-FSM: Call error ${callError} not handled with state: ${callState}`);
    }
  };

  /**
   * Shows the call survey, or "Call Ended" error message
   * if no call survey is available.
   */
  showCallSurveyOrError = () => {
    if (!this.meetingsManager?.isInMeeting()) {
      if (!showCallSurvey(1000)) {
        this.setError(ERROR_TYPES.callEnded);
      }
    }
  };

  /**
   * Sets error message to be shown to user on account of media error
   * @param {MEDIA_TYPE} mediaType video / audio / unknown
   * @param {GUM_EXCEPTION} errorName Error id
   * @returns {void}
   */
  setMediaAccessError = (mediaType, gumErr = null) => {
    let errorMsg = translate('errors.cameraBrowserPermission');
    if (mediaType === MEDIA_TYPE.SCREEN) {
      this.setError(ERROR_TYPES.screenShareDeclined);
      return;
    }

    if (mediaType === MEDIA_TYPE.AUDIO) {
      // Generic error for audio ??
      errorMsg = translate('errors.micBrowserPermission');
      this.setError(ERROR_TYPES.permissionsError,
        null, null, null, { messageText: errorMsg });
      return;
    }

    if (!gumErr) {
      // Generic error
      this.setError(ERROR_TYPES.permissionsError,
        null, null, null, { messageText: errorMsg });
      return;
    }

    switch (gumErr) {
      case GUM_EXCEPTION.ABORT_ERROR:
        errorMsg = translate('errors.cameraAborted');
        break;
      case GUM_EXCEPTION.NOT_FOUND_ERROR:
        errorMsg = translate('errors.noCameraDevice');
        break;
      case GUM_EXCEPTION.NOT_READABLE_ERROR:
        errorMsg = translate('errors.cameraBusy');
        break;
      case GUM_EXCEPTION.OVER_CONSTRAINED_ERROR:
        errorMsg = translate('errors.cameraOverConstrained');
        break;
      case GUM_EXCEPTION.SECURITY_ERROR:
      case GUM_EXCEPTION.TYPE_ERROR:
        errorMsg = translate('errors.cameraNotAllowed');
        break;
      default:
        break;
    }
    this.setError(ERROR_TYPES.permissionsError,
      null, null, null, { messageText: errorMsg });
  }

  /* Process network error from Helper API */
  onSessionError = (callError, callState) => {
    switch (callError) {
      case CALL_ERROR.NETWORK_ERR:
        if (callState === WEB_CALL_STATE.INITIALIZING) {
          this.setError(ERROR_TYPES.connectFatal);
        } else {
          this.setError(ERROR_TYPES.connectErr);
        }
        break;

      case CALL_ERROR.REG_FAILED:
        this.setError(ERROR_TYPES.registrationErr);
        break;

      default:
        break;
    }

    if (!this.showError || this.error.popup === null) {
      logger.assert(`ERROR! ${callError} not handled`);
    }
  }

  updateState = () => {
    if (this.state.activeSharerPId !== LS_DATASTREAM_PROTOCOL.getActiveSharerPid()) {
      this.setState({
        activeSharerPId: LS_DATASTREAM_PROTOCOL.getActiveSharerPid(),
      });
    }
  }

  // Need to revisit this callback function and remove
  onData = async (event, actions) => {
    await LS_DATASTREAM_PROTOCOL.onEventRecv(event, actions);
    this.updateState();
  };

  /**
   * Callback from LSDS to inform or trigger stream state processing
   * @param {STREAM_SHARE_STATE} state
   */
  async onStreamStateChange(state) {
    logger.info('onStreamStateChange::Stream state changing to:',
      CommonUtility.mapName(STREAM_SHARE_STATE, state),
      LS_DATASTREAM_PROTOCOL.isLocalPId(LS_DATASTREAM_PROTOCOL.getActiveSharerPid()) ?
        'Local' : 'Remote',
      'Sharer PId:', LS_DATASTREAM_PROTOCOL.getActiveSharerPid());
    if (state === this.props.streamShareState) {
      logger.info('App already in required state');
      return;
    }
    this.props.setStreamShareStateAction(state);
    const isLocalStreaming =
      LS_DATASTREAM_PROTOCOL.isLocalPId(LS_DATASTREAM_PROTOCOL.getActiveSharerPid());

    switch (state) {
      case STREAM_SHARE_STATE.READY:
        // Already handled above - this.props.setStreamShareStateAction(state);
        break;

      case STREAM_SHARE_STATE.TOKEN_REQUESTED:
        // No action, other than state update
        break;

      case STREAM_SHARE_STATE.PREPARE_TO_SHARE:
        /* Get canvas ready for streaming */
        logger.info('onStramStateChange::Getting app ready for share start');
        this.handlePrepareToShareStream();
        break;

      case STREAM_SHARE_STATE.STARTING_SHARE:
        break;

      case STREAM_SHARE_STATE.STREAMING_STARTED:
        logger.info('onStreamStateChange::',
          `${isLocalStreaming ? 'Local' : 'Remote'} Streaming live :) !`);
        logger.debug('Reset telestration array when stream is stopped or changed');
        if (this.imageShare.captureData) {
          this.teleHelper.clearTSArray(true);
        } else {
          this.teleHelper.clearTSArray(); // 2435
          this.teleHelper.initializeCanvasManager(
            this.props.selectedVideoProfile,
            CANVAS_MANAGER_TIMEOUT,
          );
        }
        // Update video profile for streaming
        if (isLocalStreaming) {
          this.updateZoomInfo(DEFAULT_ZOOM_INFO);
          this.props.setVideoPauseAction(false);
          this.callMgr?.setPauseVideoState(false);
        }
        break;

      case STREAM_SHARE_STATE.STOP_SHARE:
        logger.info('onStreamStateChange::Stopping video display');
        if (isLocalStreaming) {
          const { Illumination, Flash } = this.props.clientPolicyPermissionsXmlData;
          // If both permissions are not set, send the default illum info to DS
          await this.callMgr?.displayVideo(STOP_STREAM, !Illumination && !Flash);
          if (LS_DATASTREAM_PROTOCOL.isActiveSharerUnknown()) {
            // Clear screen share statel if no one is sharing
            this.screenShare.clearState();
          }
          this.callMgr?.toggleGeoLocationFetcher(false);
          browserAPI.onBrowserPermissionChange(PERMISSION.GEOLOCATION, null);
        }
        this.props.setVideoIconAction(false);
        // Clean up text input element on local stream stop(#1868)
        this.teleHelper.removeTextInput();
        break;

      case STREAM_SHARE_STATE.SHARER_CHANGED:
        // Clean up text input element on remote stream stop(#1868)
        this.teleHelper.removeTextInput();
        if (!LS_DATASTREAM_PROTOCOL.isRemoteUserSharing()) {
          // Restore video device list to reflect local devices
          await this.setListOfDevices(MEDIA_TYPE.VIDEO);
        }
        if (LS_DATASTREAM_PROTOCOL.isActiveSharerUnknown()) {
          this.props.setStreamShareStateAction(STREAM_SHARE_STATE.READY);
        }
        break;

      case STREAM_SHARE_STATE.STREAMING_STOPPED:
        logger.debug('Stream share stopped');
        this.updateZoomInfo(DEFAULT_ZOOM_INFO);
        this.updateZoomLevel(DEFAULT_ZOOM_LEVEL * 10);
        if (!LS_DATASTREAM_PROTOCOL.isRemoteUserSharing()) {
          this.props.setVideoPauseAction(false);
          this.callMgr?.setPauseVideoState(false);
        }
        this.props.setStreamShareStateAction(STREAM_SHARE_STATE.READY);
        break;

      default:
        logger.error('Handle this case', state);
        break;
    }

    /* Update sharer state in participant panel by sharer pid */
    // Check with @ayan - if this should be called every time or certain state
    Participants.updateVideoState(this.props.participants,
      LS_DATASTREAM_PROTOCOL.getActiveSharerPid());
    this.updateState();
    // Reset telestration canvas dimensions when stream is started
  }

  /**
   * Internal
   * Handles local stream start on receiving 'PREPARE_TO_SHARE' event from LSDS
   */
  async handlePrepareToShareStream() {
    // Get current list of devices available for streaming before starting streaming
    const ssToBeStarted = this.screenShare.check_ABOUT_TO_START();
    // Starting video
    this.setListOfDevices(MEDIA_TYPE.VIDEO, {
      updateVideoMedia: false,
      fetchVideoSource: !ssToBeStarted,
      forceRefresh: true,
    })
      .then(async () => {
        // Perform stream start post device updation
        // Get current list of devices available for streaming/
        const selectedVideoDevice = ssToBeStarted ?
          SCREEN_SHARE_DEVICE : this.props.selectedVideoDevice;
        const videoConfig = LS_DATASTREAM_PROTOCOL.getCurrentVideoConfig();
        logger.info('onStreamStateChange::',
          'Display stream from source', selectedVideoDevice?.label);
        if (!selectedVideoDevice) {
          // Sometimes the device list is not getting populated initially
          // Check if the call has a videp input assigned.
          logger.warn('No video device available for call currently',
            'Available video devices are:', JSON.stringify(this.props.videoInputDeviceList));
        }
        const { Illumination, Flash } = this.props.clientPolicyPermissionsXmlData;
        // If both permissions are not set, send the default illum info to DS
        this.callMgr.displayVideo(START_STREAM,
          selectedVideoDevice, videoConfig, !Illumination && !Flash)
          .then(() => {
            /* Update the source list before the stream start */
            this.setVideoDevicesAndSelected(this.props.videoInputDeviceList,
              selectedVideoDevice);
            /* Pre-setup of APP for streaming done; start actual stream */
            LS_DATASTREAM_PROTOCOL.startStream();
            if (ssToBeStarted) this.screenShare.set_STARTED();
          }).catch((err) => {
            // Abort streaming
            logger.warn('onStreamStateChange::',
              'Stream share aborted on account of err for displayVideo', err);
            // Report error to user
            let gumErr = err?.name;
            if (err && err.message?.includes(FF_CAMERA_BUSY_MSG)) {
              gumErr = GUM_EXCEPTION.NOT_READABLE_ERROR;
            }
            this.setMediaAccessError(ssToBeStarted ? MEDIA_TYPE.SCREEN :
              MEDIA_TYPE.VIDEO, gumErr);
            // Update the participants video icon state for participant
            Participants.updateVideoStateById(
              this.props.participants,
              LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID,
            );
            // Inform LSDS for the cleanup due to failure
            if (ssToBeStarted) {
              // Screen share cleanup
              this.screenShare.handleError();
            }
            // Inform LSDS for the cleanup due to failure
            LS_DATASTREAM_PROTOCOL.abortStreamStart();
          });
      });
  }

  onStillImageShareModeStateChange = (state) => {
    if (state === STILL_IMAGE_SHARE_MODE_STATUS.STILL_IMAGE_SHARE_MODE_EXIT) {
      this.imageShare.clearState(this);
      this.teleHelper.initializeCanvasManager(
        this.props.selectedVideoProfile,
        CANVAS_MANAGER_TIMEOUT,
      );
    }
    this.setState({
      stillImageShareModeState: state,
    });
  }

  onStillImageShareReceived = (image) => {
    logger.debug('onStillImageShareReceived()', image);
  }

  onParticipantChange = (participantData) => {
    logger.info('onParticipantChange: ', participantData);
    const callParticipants = _.clone(this.props.participants);
    if (
      participantData.data?.reason ===
      ParticipantChangeReasons.PCR_Addition ||
      participantData.data?.reason ===
      ParticipantChangeReasons.PCR_Addition_AlreadyInConference
    ) {
      const participantToAddAt = callParticipants.findIndex(
        (participant) => participantData.data?.pid === participant.pId,
      );
      if (participantToAddAt === -1) {
        callParticipants.push({
          name: participantData?.data.name,
          address: CommonUtility.getUserNameWithDomainFromUri(
            participantData?.data.addr,
            false,
          ),
          isLoggedInUser: false,
          isVideoOn: false,
          isMuted: false,
          pId: participantData?.data.pid,
          colorCode: TELESTRATION_COLORS[0].colorCode,
        });
      }
      CallStatisticsManager.addConferenceParticipant(participantData.data);
    } else if (
      participantData.data?.reason ===
      ParticipantChangeReasons.PCR_Removal
    ) {
      const participantToRemoveAt = callParticipants.findIndex(
        (participant) => participantData.data?.pid === participant.pId,
      );
      if (participantToRemoveAt > 0) {
        callParticipants.splice(participantToRemoveAt, 1);
      }
      CallStatisticsManager.removeConferenceParticipant(participantData.data);
    }
    if (this.isMeeting()) {
      Participants.updateMeetingParticipants(
        this.props.participants,
        participantData?.data,
      );
    } else {
      Participants.set(callParticipants);
    }
  };

  onSetRemotePid = () => {
    Participants.setRemotePid(this.props.participants);

    if (window.dynamicEnv.REACT_APP_REPORT_CALL_STATS === 'true') {
      // update the current call
      CallStatisticsManager.updateCall({ remotePid: LS_DATASTREAM_PROTOCOL.getRemotePId() });
    }
  };

  onMuteStatusChange = (muteStatusData) => {
    logger.debug('onMuteStatusChange() mute data:', muteStatusData);
    Participants.updateMuteState(this.props.participants,
      muteStatusData.pid, muteStatusData.mute);
    this.handleRemoteAudioSendingtoNlpOnMuteStatusChange(muteStatusData);
  };

  handleRemoteAudioSendingtoNlpOnMuteStatusChange = (muteStatusData) => {
    const nlpManager = this.nlpHelper?.getNlpManager();
    if (muteStatusData.mute) {
      // check if all remote participants are muted
      let allMuted = true;
      this.props.participants?.every((participant) => {
        if (participant.pId !== LS_DATASTREAM_PROTOCOL.getLocalPId() &&
          !participant.isMuted) {
          allMuted = false;
          return false;
        }
        return true;
      });
      logger.debug('handleRemoteAudioSendingtoNlpOnMuteStatusChange() allMute:', allMuted);

      if (allMuted) {
        nlpManager?.softStopSending();
      } else if (!allMuted) {
        nlpManager?.softStartSending(this.state.remoteStream);
      }
    } else if (!muteStatusData.mute) {
      nlpManager?.softStartSending(this.state.remoteStream);
    }
  }

  onMuteRequest = (muteStatusData, toggleMute = false) => {
    if (!LS_DATASTREAM_PROTOCOL.isHandshakeDone) {
      logger.warn('ERROR! Can\'t perform call actions, till handshake complete');
      return;
    }

    const localPId = muteStatusData.find((pid) => LS_DATASTREAM_PROTOCOL.isLocalPId(pid));
    if (localPId) {
      const muteStatus = LS_DATASTREAM_PROTOCOL.getMuteFlagForSelf();
      logger.assert(muteStatus !== this.props.audioOn,
        'onMuteRequest::Mismatch in mutestatus for APP and LS',
        'LS: ', muteStatus,
        'App:', this.props.audioOn);
      if (toggleMute) {
        this.props.setAudioIconAction(muteStatus);
        LS_DATASTREAM_PROTOCOL.updateMuteFlagForSelf(!muteStatus);
      } else {
        this.props.setAudioIconAction(false);
        LS_DATASTREAM_PROTOCOL.updateMuteFlagForSelf(true);
      }
      Participants.updateMuteState(this.props.participants,
        localPId, LS_DATASTREAM_PROTOCOL.getMuteFlagForSelf());
    }
  };

  /**
   * Call manager informed about change in device either on request from App
   * or otherwise
   * @param {object} cbData { audio: true | false, video: true | false }
   *  indicates what changed
   */
  onMediaDeviceChange = async (cbData) => {
    const { changedMedia, device, illumInfo } = cbData;
    logger.info(
      'onMediaDeviceChange() ',
      changedMedia,
      'device', device?.label,
      'illumination permission', illumInfo,
    );

    if (changedMedia?.video || changedMedia?.screen) {
      this.setVideoDevicesAndSelected(this.props.videoInputDeviceList, device);
      // Need to adjust video stream track as per selected config
      const videoConfig = this.props.selectedVideoProfile ??
        VIDEO_MEDIA_CONFIGS[DEFAULT_MEDIA_CONFIG.value];
      const ssToBeStarted = this.props.isScreenShareStarted ||
        this.screenShare.check_ABOUT_TO_START();
      // TODO: Apply constraints in changeDeviceByReplacingTrack's GUM instead of applying here
      this.callMgr.setVideoConfigs(
        videoConfig,
        ssToBeStarted,
        this.isloggedInUserVideoSharer(),
      );
      LS_DATASTREAM_PROTOCOL.setIllumInfo(illumInfo);
      LS_DATASTREAM_PROTOCOL.notifyAboutChangeInCurrentMediaVideoSource();

      // Update config if video to/fro screen sharing through device change
      this.updateVideoMediaConfig(this.screenShare.check_ABOUT_TO_START());

      this.updateZoomLevel(DEFAULT_ZOOM_LEVEL * 10);
      this.props.setVideoPauseAction(false);
      this.callMgr?.setPauseVideoState(false);
      setTimeout(() => {
        const FOR_VIDEO_ONLY = true;
        this.teleHelper.handleTelestrationsOnActions(
          this.props.selectedVideoProfile,
          !this.imageShare.captureData,
          FOR_VIDEO_ONLY,
        );
      }, 1000);
    }
  };

  /**
   * Callback from callMgr on change in stream status as pause/unpause
   * @param {*} videoFrozen
   */
  onStreamFreeze = (videoFrozen = false) => {
    logger.debug('Video stream ', videoFrozen ? 'frozen' : 'unfrozen');
    LS_DATASTREAM_PROTOCOL.notifyAboutStreamFreeze(videoFrozen);
  }

  /**
   * Sets current video source to be used for sharing
   * Updates state with LSDS
   * Can be called while call is active or other wise too.
   * @param {MEDIA_TYPE} mediaType The type of media to select and update,
   * if not set it is both, updates audio or video or both sources
   */
  selectCurrentMediaSource = (mediaType = MEDIA_TYPE.ANY) => {
    logger.info('selectCurrentMedia::Updating local media sources and current selection for - ',
      mediaType === MEDIA_TYPE.ANY ? 'ALL' : 'Audio');
    const selectedDevices = {};

    if ((mediaType === MEDIA_TYPE.VIDEO) ||
      (mediaType === MEDIA_TYPE.ANY)) {
      // Prefer video source if available with CallMgr
      // eslint-disable-next-line prefer-const, no-unsafe-optional-chaining
      let { videoDevice, videoDeviceId } = this.callMgr?.getCurrentVideoSourceId();

      if (this.props.videoInputDeviceList) {
        videoDevice = this.props.videoInputDeviceList.find((device) =>
          device.deviceId === videoDeviceId);
        selectedDevices.video = videoDevice ?? this.props.videoInputDeviceList[0];
      }
    }

    let audioDevice = null;
    let defaultDevice = null;

    // Previous call to 'setListOfDevices' may take a while to populate media
    // so check for input list being valid
    if ((mediaType === MEDIA_TYPE.AUDIO) ||
      (mediaType === MEDIA_TYPE.ANY)) {
      if (this.props.audioInputDeviceList) {
        defaultDevice = this.props.audioInputDeviceList.find((d) => d.isDefault) ??
          this.props.audioInputDeviceList[0];
        // Prefer audio device from callMgr if available else, the selected one
        let deviceId = null;
        let label = null;
        if (this.callMgr) {
          const callAudioDevice = this.callMgr?.getCurrentAudioSourceId();
          if (callAudioDevice) {
            deviceId = callAudioDevice.deviceId;
            label = callAudioDevice.label;
          }
        }
        if (deviceId) {
          audioDevice = this.props.audioInputDeviceList.find((device) =>
            device.deviceId === deviceId);
          if (audioDevice && audioDevice.label !== label) {
            logger.warn('Mismatch in Audio device usage', audioDevice, label);
          }
        } else {
          audioDevice = this.props.selectedAudioInputDevice;
        }
        selectedDevices.audioInput = audioDevice ?? defaultDevice;
      }
      if (this.props.audioOutputDeviceList) {
        // If we are using previously selected output and its available continue with same
        // Else, select a new from the list
        defaultDevice = this.props.audioOutputDeviceList.find((d) => d.isDefault) ??
          this.props.audioOutputDeviceList[0];
        const audioDeviceId = this.callMgr?.getCurrentAudioSinkId() ??
          this.props.selectedAudioOutputDevice?.deviceId;
        audioDevice = this.props.audioOutputDeviceList.find((device) =>
          device.deviceId === audioDeviceId);
        selectedDevices.audioOutput = audioDevice ?? defaultDevice;
      }
    }

    logger.info('selectCurrentMedia::Current selection of media',
      JSON.stringify(selectedDevices));
    return selectedDevices;
  }

  onStreamQualityChanged = (streamQuality) => {
    logger.debug(
      `onStreamQualityChanged() streamQuality:${JSON.stringify(
        streamQuality,
      )}`,
    );
    let videoConfigToSelect;
    if (typeof (streamQuality) === 'number') {
      videoConfigToSelect = this.props.videoMediaConfigs?.find(
        (videoConfig) => videoConfig.streamQuality === streamQuality,
      );
    } else {
      videoConfigToSelect = streamQuality;
    }

    if (!videoConfigToSelect) {
      const index = CommonUtility.getDefaultProfileIndex(this.props.videoMediaConfigs);
      videoConfigToSelect = index >= 0 ? this.props.videoMediaConfigs[index] : null;
      logger.info(`onStreamQualityChanged: unsupported media quality: ${JSON.stringify(streamQuality)}, choosing ${JSON.stringify(videoConfigToSelect)}`);
    }

    if (videoConfigToSelect) {
      this.props.setSelectedVideoProfileAction(videoConfigToSelect);
    }
  };

  /**
   * Callback from LSDS, sets the current video source as selected by remote
   * @param {string} deviceLabel
   * @param {string} devices
   */
  onRemoteVideoSourceChangeRequest = async (deviceLabel, videoDevices = null) => {
    logger.info('Changing video source to', deviceLabel);
    if (LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer()) {
      // Change local source
      const videoDevice = this.props.videoInputDeviceList?.find(
        (device) => device.label.includes(deviceLabel),
      );

      if (videoDevice) {
        if (this.props.isScreenShareStarted) {
          // Switch to video from screen share; initiated from remote
          this.screenShare.switchToVideo(videoDevice);
        } else {
          this.changeVideoDevice(videoDevice);
        }
      } else {
        logger.warn(` onRemoteVideoSourceChangeRequest() couldn't find ${deviceLabel}`);
      }
    } else {
      if (deviceLabel === SCREEN_SHARE_DEVICE.label) {
        // Set remote screen share state
        logger.info('Remote screen share started');
        this.screenShare.setRemoteShareState(true);
      } else if (this.state.isScreenShareStartedFromRemote) {
        // Reset remote screen share state
        logger.info('Remote screen share stopped');
        this.screenShare.setRemoteShareState(false);
      }
      if (LS_DATASTREAM_PROTOCOL.isRemoteUserSharing()) {
        // update video devices along with current selection from remote
        logger.info('Updating remote video sources');
        this.setVideoDevicesAndSelected(videoDevices,
          deviceLabel);
        this.setIlluminationButtonState(deviceLabel === SCREEN_SHARE_DEVICE.label);
      }
    }
  }

  /**
   * LS-Callback
   * Can also be by app or from LSDS when remote sends a device changed request
   * Sets global list of video devices and selected device when updated.
   * When called from app - updates with local list
   * When called LS - updates with remote list
   * @param {list} videoDevices list of {MediaDevice | string}
   * @param {string} currentVideoDevice {MediaDevice | string} =>
   */
  setVideoDevicesAndSelected = (videoDevices, currentVideoDevice) => {
    logger.debug('',
      `${videoDevices ? 'Setting video sources to:' + JSON.stringify(videoDevices) : ''}`,
      `Current device:${JSON.stringify(currentVideoDevice)}`);
    const streamer = LS_DATASTREAM_PROTOCOL.whoIsStreaming();
    let deviceList = null;
    const ssToBeStarted = this.props.isScreenShareStarted ||
      this.screenShare.check_ABOUT_TO_START();
    switch (streamer) {
      case STREAMER.LOCAL:
      case STREAMER.NONE:
        if (currentVideoDevice) {
          /* cases
           1. no video shared, change of selected video device
           2. video shared, change video device
           3. screen being started, -> check_ABOUT_TO_START
           4. screen shared, local video device change
           5. screen shared, remote initiates video device change
           6. switch to screen share while video shared
           Except for 3, and 5, selected device change should update state in store
          */
          if ((!this.screenShare.check_ABOUT_TO_START() && !this.screenShare.check_STARTED()) ||
            (currentVideoDevice.deviceId !== SCREEN_SHARE_DEVICE.deviceId)) {
            // Special ref#1973: We do not update current device state if its screen share
            // for local share
            this.props.setVideoDeviceAction(currentVideoDevice);
          }
          LS_DATASTREAM_PROTOCOL.setCurrentSubSource(currentVideoDevice.label);
        } else {
          this.props.setVideoDeviceAction(currentVideoDevice);
        }

        if (videoDevices !== NO_CHANGE) {
          deviceList = videoDevices.map((device) => device.label);
          if (ssToBeStarted) {
            // Special ref#1973
            deviceList.push(SCREEN_SHARE_DEVICE.label);
          }
          this.props.setVideoDeviceListAction(videoDevices);
          LS_DATASTREAM_PROTOCOL.setAvailableVideoSubsources(deviceList);
        }
        break;

      case STREAMER.REMOTE:
        this.props.setVideoDeviceAction(currentVideoDevice);
        LS_DATASTREAM_PROTOCOL.setCurrentSubSource(currentVideoDevice);
        if (videoDevices !== NO_CHANGE) {
          this.props.setVideoDeviceListAction(videoDevices);
          LS_DATASTREAM_PROTOCOL.setAvailableVideoSubsources(videoDevices);
        }
        break;

      default:
        break;
    }
  }

  setSelectedAudioDevices = (initialSetup = true) => {
    // Set audio sink in sync with CallMgr and local stream
    const selectedDevices = this.selectCurrentMediaSource(MEDIA_TYPE.AUDIO);
    if (initialSetup) {
      if (selectedDevices.audioInput) {
        this.props.setAudioInputDeviceAction(selectedDevices.audioInput);
      }
      if (selectedDevices.audioOutput) {
        this.props.setAudioOutputDeviceAction(selectedDevices.audioOutput);
      }
    }
  }

  // Function to compare video profiles based on width, height, fps, bitrate
  isSameVideoProfile = (availableVideoConfig, videoConfig) => {
    if ((availableVideoConfig.width === videoConfig.width)
      && (availableVideoConfig.height === videoConfig.height)
      && (availableVideoConfig.bitRate === videoConfig.bitRate)
      && (availableVideoConfig.frameRate === videoConfig.frameRate)) {
      return true;
    }
    return false;
  }

  onVideoConfigChanged = (videoConfig) => {
    logger.debug(
      `onVideoConfigChanged() videoConfig:${JSON.stringify(
        videoConfig,
      )}`,
    );
    let availableVideoConfig = this.props.videoMediaConfigs?.find(
      (config) => this.isSameVideoProfile(config, videoConfig),
    );
    const clonedVideoConfig = _.clone(videoConfig);
    logger.debug(`onVideoConfigChanged() availableVideoConfig:${JSON.stringify(availableVideoConfig)}`);

    if (!availableVideoConfig) {
      // this is from other participant
      const currentVideoMediaConfigs = this.props.videoMediaConfigs;
      const fromOtherPartProfileIndex =
        currentVideoMediaConfigs.findIndex(
          (config) =>
            config.streamQuality ===
            VIDEO_MEDIA_PROFILE.FROM_OTHER_PARTICIPANT,
        );
      clonedVideoConfig.streamQuality =
        VIDEO_MEDIA_PROFILE.FROM_OTHER_PARTICIPANT;
      clonedVideoConfig.name = OTHER_PARTICIPANT;

      if (fromOtherPartProfileIndex > -1) {
        currentVideoMediaConfigs[fromOtherPartProfileIndex] =
          clonedVideoConfig;
      } else {
        currentVideoMediaConfigs.push(clonedVideoConfig);
      }
      availableVideoConfig = clonedVideoConfig;
      logger.debug(
        `onVideoConfigChanged() videoMediaConfigs:${JSON.stringify(
          currentVideoMediaConfigs,
        )}`,
      );
      this.props.setVideoMediaConfigsAction(currentVideoMediaConfigs);
    }

    if (
      videoConfig.streamQuality === VIDEO_MEDIA_PROFILE.CUSTOM &&
      (this.props.selectedVideoProfile?.streamQuality ===
        VIDEO_MEDIA_PROFILE.CUSTOM ||
        this.props.selectedVideoProfile?.streamQuality ===
        VIDEO_MEDIA_PROFILE.FROM_OTHER_PARTICIPANT) &&
      this.isSameVideoProfile(this.props.selectedVideoProfile, videoConfig)) {
      logger.debug(
        'onVideoConfigChanged() returning, since existing profile is same.',
      );
      return;
    }
    this.onStreamQualityChanged(availableVideoConfig);
  };

  /**
   * Selects the video configuration to be used based on screen/video share mode
   * @param {bool} isScreenShareStarted
   * @returns {void}
   */
  updateVideoMediaConfig = (isScreenShareStarted = false) => {
    let { videoMediaConfigs } = this.props;
    if (!videoMediaConfigs) {
      logger.warn('updateVideoMediaConfig() videoMediaConfigs is empty, returning');
      return;
    }

    const { ssMediaProfile } = this.screenShare;
    if (isScreenShareStarted) {
      /* Preserve last profile before switching to screen share */
      if (!this.isSameVideoProfile(
        ssMediaProfile, this.props.selectedVideoProfile,
      )) {
        // FIXME - Is this required ?
        this.screenShare.setLastProfile(this.props.selectedVideoProfile);
      }

      videoMediaConfigs.push(ssMediaProfile);
      this.props.setVideoMediaConfigsAction(videoMediaConfigs);
      this.props.setSelectedVideoProfileAction(ssMediaProfile);
      logger.info('updateVideoMediaConfig::Screen share profile set');

      return; // Screen share profile done
    }
    // Not in screen share mode

    const lastVideoProfile = this.screenShare.lastProfile;
    videoMediaConfigs = videoMediaConfigs.filter(
      (mediaProfile) => !this.isSameVideoProfile(ssMediaProfile, mediaProfile),
    );
    logger.debug('updateVideoMediaConfig() after removing SS video profile:', videoMediaConfigs);
    this.props.setVideoMediaConfigsAction(videoMediaConfigs);
    if (lastVideoProfile) {
      this.props.setSelectedVideoProfileAction(lastVideoProfile);
    }
    logger.info('updateVideoMediaConfig::Video share profile set');
    // Video share profile done
  }

  /**
   * Sets error message data
   * @param {string} popupId  Id of the popup to display from errorPopups,
   * for legacy purposes popupId is the messageText
   * @param {object} msgParams When popupId is valid, the parameters
   * as required by the message template text in call to translate.
   * For legacy usage, it is 'isServerError' flag
   * @param {string} heading - not used with popupId
   * For legacy usage, it is heading text value of error modal.
   */
  setError = (
    popupId,
    // eslint-disable-next-line default-param-last
    msgParams = null,
    heading = null,
    autoDismissDuration = null,
    extras = null,
    modalId,
  ) => {
    let messageText = null;
    let messageHdr = null;
    let id; // modal unique id for the errors that donot belong to this.errorpopups
    /* There can only be one modal active; if we are setting
    error then other modals need to be removed */
    this.hideCallingModal();
    /* We hide spinner momentarily to show error; it will popup
    back if the network state remains unchanged */
    this.props.hideSpinner();
    this.error.clear();

    if (Object.keys(this.errorPopups).includes(popupId)) {
      const popup = this.errorPopups[popupId];
      if (extras && extras.messageText) {
        // If message text provided
        messageText = extras.messageText;
      } else if (msgParams) {
        messageText = translate(popup.messageText, msgParams);
      } else {
        messageText = translate(popup.messageText);
      }
      this.error.popup = { ...popup, messageText };
    } else {
      /* Legacy/old way (used for linebusy and self calling popup) */
      const popup = this.errorPopups.genericError;
      messageText = popupId;
      messageHdr = heading;
      id = modalId;
      this.error.popup = { ...popup, messageText, messageHdr, id };
      logger.log('messageText: ', messageText, this.error);
    }

    if (autoDismissDuration) {
      const self = this;
      this.error.errDisplayTimer = setTimeout(() => {
        self.handleDismissError();
      }, autoDismissDuration * 1000);
    }
    this.setState({
      showError: true,
    });
  }
  // #region

  /**
  * Handles media access errors
  */
  handleMediaAccessError = (mediaType, errInfo = null) => {
    this.setMediaAccessError(mediaType, errInfo?.error?.name);
    if (!this.browserPermissions) {
      return;
    }
    logger.info(`Handling media access error for ${mediaType}`);

    if (mediaType === MEDIA_TYPE.AUDIO) {
      if (!this.browserPermissions.audio) {
        // Close audio source panel if open
        if (this.props?.location.pathname ===
          ROUTES.CALL_DASHBOARD + ROUTES.AUDIO_SOURCE) {
          this.props.history.push(ROUTES.CALL_DASHBOARD);
        }
      }
    } else if (mediaType === MEDIA_TYPE.VIDEO) {
      if (LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer()) {
        // Stop video and show video fallback UI when camera permission disables
        this.startOrRequestVideoStream(LOCAL_USER, STREAM_SHARE_ACTION.STOP_SHARE,
          mediaType);
        this.props.setShowVideoFallbackUI(true);
        // Close video source panel if open
        if (this.props?.location.pathname ===
          ROUTES.CALL_DASHBOARD + ROUTES.VIDEO_SOURCE) {
          this.props.history.push(ROUTES.CALL_DASHBOARD);
        }
      }
    } else if (mediaType === MEDIA_TYPE.SCREEN) {
      // Screen share cleanup
      this.screenShare.handleError();
    }
  }

  handleDismissError = () => {
    this.error.clear();
    this.setState({
      showError: false,
    });
    /* Show spinner in case we are facing network issues */
    if (this.callMgr?.isNetReconnecting()) {
      this.props.showSpinner();
      const self = this;
      /* A reconnect error will be shown every AUTO_SHOW_ERR_DURATION seconds */
      this.error.errDisplayTimer = setTimeout(() => {
        if (self.callMgr.isCallInProgress()) {
          self.setError(ERROR_TYPES.inCallNetworkErr, null, null, AUTO_DISMISS_ERR_DURATION);
        } else {
          self.setError(ERROR_TYPES.connectErr);
        }
      }, AUTO_SHOW_ERR_DURATION * 1000);
    }
  };

  handleExit = () => {
    /* We want to be logged out */
    logoutAction();
  };

  hideCallingModal = () => {
    this.setState({
      callInitiatedModal: false,
      receivingCallModal: false,
      disabledButton: false,
    });
  };

  initiateSipPlugin = () => {
    this.callMgr = CallMgr;
    if (!this.props.clientSettingsData || this.props.isOPMSessionExpired) {
      logger.info('Not initializing');
      return;
    }
    logger.debug(
      'initiateSipPlugin:: clientSettingsXml',
      this.props.clientSettingsData,
      this.props.userData,
    );
    const events = {
      /* Session specific callbacks */
      onSessionEvent: this.onSessionEvent,

      /* Call specific callbacks */
      /* Generic call backs */
      onConsentDialog: this.onConsentDialog,
      onCallInitiated: this.onCallInitiated,
      onIncomingCall: this.onIncomingCall,
      onMissedCall: this.onMissedCall,
      onCallAccepted: this.onCallAccepted,
      onHangup: this.onHangup,
      onData: this.onData,
      onMediaPathChange: this.onMediaPathChange,
      onCallDisconnect: this.onCallDisconnect,
      receivedLocalPID: this.receivedLocalPID,
      datastreamHangupMessageReceived: this.datastreamHangupMessageReceived,

      /* Media action */
      mediaAction: {
        onLocalStreamReceived: this.onLocalStreamReceived,
        onRemoteStreamReceived: this.onRemoteStreamReceived,
        onMediaDeviceChange: this.onMediaDeviceChange,
        onStreamFreeze: this.onStreamFreeze,
        onScreenShareStopped: this.onScreenShareStopped,
        onConfigUpdate: this.onMediaConfigUpdate,
        onStreamQualityChange: this.onStreamQualityChange,
        handleErrorForScreenSharing: () => this.screenShare.handleError(),
        onScreenShareToggle: this.onScreenShareToggle,
      },

      /* Call / session errors */
      onError: this.onError,
    };
    const userName = `${MEDIA_SERVER_CONNECTION.SIP}${this.props.userData && this.props.userData.uri}`;
    const displayName = this.getLoggedInUserFullName();
    logger.debug(
      `Initializing Media Server :: username :: ${userName} :: displayName :: ${displayName}`,
    );
    if (this.props.clientSettingsData) {
      this.callMgr.initialize(
        events,
        userName,
        this.props.clientSettingsData,
        this.props.webRtcToken,
        displayName,
      );
    }
    const lsCallbacks = {
      /* Call mgmt */
      endCall: this.endCall,
      /* Video share  */
      onStreamStateChange: (state, cb = null) => this.onStreamStateChange(state, cb),
      onParticipantChange: this.onParticipantChange,
      setRemotePid: this.onSetRemotePid,
      onMuteStatusChange: this.onMuteStatusChange,
      onMuteRequest: this.onMuteRequest,
      onRemoteVideoRequest: this.onRemoteVideoRequest,
      onRemoteVideoDeclineRequest: this.onRemoteVideoDeclineRequest,
      onRemoteVideoSourceChangeRequest: this.onRemoteVideoSourceChangeRequest,
      onStreamQualityChanged: this.onStreamQualityChanged,
      onVideoConfigChanged: this.onVideoConfigChanged,
      // Callbacks for image capture
      captureImage: this.captureImage,
      onStillImageShareModeStateChange: this.onStillImageShareModeStateChange,
      onStillImageShareReceived: this.onStillImageShareReceived,
      onImageTransferCanceled: this.onImageTransferCanceled,
      setImage: this.setImage,
      onReceiveImageTransferProgress: this.onReceiveImageTransferProgress,
      onSendImageTransferProgress: this.onSendImageTransferProgress,

      // Screen sharing callbacks
      stopScreenSharing: () => this.screenShare.stop(),

      /* Required for reporting call statistics */
      onDelimitStream: this.onDelimitStream,
      onCallGuidUpdate: this.onCallGuidUpdate,
      onSysVersionUpdate: this.onSysVersionUpdate,
      /* Callbacks from LSDS */
      getCallIncoming: this.getCallIncoming,
      // Illum callbacks
      onRemoteIllumForVideoStateChange: this.onRemoteIllumForVideoStateChange,
      setIlluminationButtonState: this.setIlluminationButtonState,

      processImageData: this.processImageData,

      // callbacks for zoom
      onReceivingZoomInfo: this.updateZoomInfo,
      onReceivingZoomLevel: this.updateZoomLevel,

      saveTSColor: this.sendTsColor,
      updateRemoteParticipantColor: this.updateRemoteParticipantColor,

      // callbacks for video pause
      togglePauseVideo: this.togglePauseVideo,

      // callbacks for meetings
      setMeetingId: this.setMeetingId,
      getMeetingId: this.getMeetingId,

      // callbacks for gps position
      eventGpsInfo: this.onEventGpsInfo,
    };

    LS_DATASTREAM_PROTOCOL.initAPI(lsCallbacks);
  };

  // Send the local TS color to lsdatastreamprotocol.js
  sendTsColor = () => this.teleHelper.saveTSColor();

  placeCall = (calleeName, sipAddress) => {
    if (this.props.demoMode) {
      return; // Do nothing if in demo mode
    }

    this.props.setAudioIconAction(true);
    logger.info(
      'placecall::',
      sipAddress,
      'WebRTCAddress placecall',
      calleeName,
      sipAddress,
      this.props.userData?.uri,
    );
    if (this.meetingsManager?.isInMeeting()) {
      this.setError(
        translate('errors.callDuringMeeting'),
        true,
        `${translate('errors.userCalling', { calleeName })}`,
        null,
        ERROR_TYPES.callDuringMeeting, // modal id
      );
    } else if (sipAddress === this.props.userData?.uri) {
      // if the user is calling themselves, show error
      this.setError(
        translate('errors.selfCalling'),
        true,
        `${translate('errors.userCalling', { calleeName })}`,
        null,
        ERROR_TYPES.selfCalling, // modal id
      );
    } else {
      const toCall = MEDIA_SERVER_CONNECTION.SIP + sipAddress;

      if (this.callMgr?.doCall(toCall)) {
        /* Changes to show calling pop-up when user places call instead of oncallinitiated
        This is done to enhance user experience as fetching list of devices adds a slight delay
        in executing the oncallinitiated event */
        this.setState({
          calleeName,
          sipUsername: sipAddress,
          callInitiatedModal: true,
          disabledButton: true,
        });
      } else {
        logger.warn('Not starting call as webapp not ready!');
      }
    }
  };

  acceptCall = async () => {
    // eslint-disable-next-line no-unused-expressions
    (this.state.errorPopup !== '') && (this.handleDismissError());
    const { callerUserName } = this.state;
    logger.info(
      'acceptCall::',
      callerUserName,
      'callerUserName, accept call',
    );
    this.props.setIncomingCall(true);
    this.props.setVideoIconAction(false);
    this.props.setAudioIconAction(true);
    if (!this.isMeeting()) {
      // Need to revisit this while supporting multiparty calls
      Participants.set([
        {
          name: this.getUserFullNameFromContacts(
            this.props.loggedInUserName,
          ),
          address: CommonUtility.getUserNameWithDomainFromUri(
            this.props.loggedInUserName,
            false,
          ),
          isLoggedInUser: true,
          isVideoOn: false,
          isMuted: false,
          pId: LS_DATASTREAM_PROTOCOL.getLocalPId(),
          colorCode: this.state.tsColor,
        },
        {
          name:
            this.state.displayname ||
            this.getUserFullNameFromContacts(callerUserName),
          address:
            CommonUtility.getUserNameWithDomainFromUri(callerUserName, false),
          isLoggedInUser: false,
          isVideoOn: false,
          isMuted: false,
          pId: LS_DATASTREAM_PROTOCOL.getRemotePId(),
          colorCode: this.state.remoteTSColor,
        },
      ], true);
    } else {
      const found = this.props.participants.some(
        (p) => p.address === this.props.loggedInUserName,
      );
      if (!found) {
        Participants.set(
          [...this.props.participants,
            {
              name: this.getUserFullNameFromContacts(
                this.props.loggedInUserName,
              ),
              address: CommonUtility.getUserNameWithDomainFromUri(
                this.props.loggedInUserName,
                false,
              ),
              isLoggedInUser: true,
              isVideoOn: false,
              isMuted: false,
              pId: LS_DATASTREAM_PROTOCOL.getLocalPId(),
              colorCode: this.state.tsColor,
            },
          ], true,
        );
      }
      Participants.setInitialMeetingParticipants(
        this.props.participants,
        callerUserName,
      );
    }
    await this.callMgr?.acceptCall();
    this.setState({ receivingCallModal: false });
    this.props.history.push(ROUTES.CALL_DASHBOARD);
    clearAxiosSessionExpiryTimer();
    /* Show fallback UI on accepting the call, until stream is received */
    this.props.setShowVideoFallbackUI(true);
  };

  /**
   * Handles starting demo mode for Pendo onboarding guide purposes
   *
   * @param {bool} modeOn Indicates if the mode should be turned on if true.
   * @returns
   */
  startDemo = (modeOn = true) => {
    if (modeOn === this.props.demoMode) {
      return; // nothing to be done;
    }

    logger.info('', modeOn ? 'Starting' : 'Stopping', 'demo mode');
    this.props.setDemoMode(modeOn);
    if (modeOn) {
      this.bannerToastId = showBannerToast('Call Dashboard Demo Running');
      this.props.setIncomingCall(true);
      const callerUserName = 'john.smith@springct';
      Participants.set([
        {
          name: this.getUserFullNameFromContacts(
            this.props.loggedInUserName,
          ),
          address: CommonUtility.getUserNameWithDomainFromUri(
            this.props.loggedInUserName,
            false,
          ),
          isLoggedInUser: true,
          isVideoOn: false,
          isMuted: false,
          pId: LS_DATASTREAM_PROTOCOL.getLocalPId(),
          colorCode: this.state.tsColor,
        },
        {
          name:
            'John.Smith' ||
            this.getUserFullNameFromContacts(callerUserName),
          address:
            CommonUtility.getUserNameWithDomainFromUri(callerUserName, false),
          isLoggedInUser: false,
          isVideoOn: false,
          isMuted: false,
          pId: LS_DATASTREAM_PROTOCOL.getRemotePId(),
          colorCode: this.state.remoteTSColor,
        },
      ], true);
      this.props.history.push(ROUTES.CALL_DASHBOARD_DEMO);
    } else {
      this.bannerToastId = this.bannerToastId ? dismissToast(this.bannerToastId) : 0;
      this.props.history.push(ROUTES.DASHBOARD);
    }
    this.props.setShowVideoFallbackUI(true);
  }

  /**
   * Performs mute/unmute, acts according to current state of mute from 'audioOn'
   * audioOn === true, audio on, when false, audio muted
   */
  muteUnmuteAudio = () => {
    const { audioOn } = this.props;
    logger.info('Setting Audio', audioOn ? 'on' : 'off');
    this.callMgr?.muteUnmute(MEDIA_TYPE.AUDIO, !audioOn);
  };

  muteUnmuteVideo = () => {
    const { videoOn } = this.props;
    logger.info('Setting Video to mute ', videoOn ? '' : 'off');
    this.callMgr?.muteUnmute(MEDIA_TYPE.VIDEO, !videoOn);
  };

  muteUnmute = (mediaType, flag) => {
    this.callMgr?.muteUnmute(mediaType, flag);
    logger.debug(
      'muteUnmute() isVideoOn:',
      this.props.videoOn,
    );
  };

  changeAudioDevice = async (device, isAudioOutput = false) => {
    logger.debug('changeAudioDevice::To::', device?.label,
      'O/p device:', isAudioOutput);
    if (!isAudioOutput) {
      this.browserPermissions = await browserAPI.isBrowserPermissionsAllowed(
        {
          video: false,
          audio: true,
        },
      );
      if (!this.browserPermissions.audio) {
        logger.warn('changeAudioDevice() audio device permission is not there');
        this.setMediaAccessError(MEDIA_TYPE.AUDIO);
        return;
      }
    }
    // Update global properties to reflect selection change
    if (isAudioOutput) {
      this.props.setAudioOutputDeviceAction(device);
    } else {
      this.props.setAudioInputDeviceAction(device);
    }
    let illumination;
    let flash;
    if (this.props.clientPolicyPermissionsXmlData) {
      const { Illumination, Flash } = this.props.clientPolicyPermissionsXmlData;
      illumination = Illumination;
      flash = Flash;
    }

    // Perform update
    this.callMgr?.changeDevice(
      MEDIA_TYPE.AUDIO,
      device,
      this.props.audioOn,
      null,
      isAudioOutput,
      null,
      !illumination && !flash,
    );
  };

  /**
   *
   * @param {MediaDevice | string} The video device id to change to
   * @param {bool} screenShareOverride when true indicates, change to video device
   * from screen share ; this is currently needed for -
   * 1. remote user switching from screen to video device
   * 2. on stopping screen share, go back to previous video share
   */
  /* Change the current streaming device */
  changeVideoDevice = async (device, screenShareOverride = false) => {
    logger.info('changeVideoDevice::to =>', device?.label);
    const startingScreenShare = device === SCREEN_SHARE_DEVICE;
    const mediaType = startingScreenShare ?
      MEDIA_TYPE.SCREEN : MEDIA_TYPE.VIDEO;

    if (LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer()) {
      if (this.props.isScreenShareStarted) {
        if (screenShareOverride !== SCREEN_SHARE_OVERRIDE) {
          // We will only update the device but not start video share since SS is on
          // unless it's requested from remote
          // Special ref#1973
          logger.info('Device selection now', device?.label);
          this.setVideoDevicesAndSelected(NO_CHANGE, device);
          return;
        }
      }

      // Initiate device change flow
      try {
        const { Illumination, Flash } = this.props.clientPolicyPermissionsXmlData;

        await this.callMgr?.changeDevice(
          mediaType,
          device,
          this.props.audioOn,
          this.props.setShowVideoFallbackUI,
          false,
          this.props.selectedVideoProfile,
          !Illumination && !Flash,
        );
        if (startingScreenShare) this.screenShare.set_STARTED();
        logger.info('Changed video to', device?.label);
      } catch (err) {
        console.error('failure to change to device:', err);
        // Show media error; failure to change to device
        if (startingScreenShare) {
          // Screen share error / cancel
          this.handleMediaAccessError(MEDIA_TYPE.SCREEN, { error: err });
        } else {
          // Video media error
          this.handleMediaAccessError(MEDIA_TYPE.VIDEO, { error: err });
        }
        return;
      }
    } else if (LS_DATASTREAM_PROTOCOL.isRemoteUserSharing()) {
      /* Remote participant sharing */
      LS_DATASTREAM_PROTOCOL.changeRemoteVideoSource(device?.label);
    }
    /* Update global video device prop to match selection */
    this.setVideoDevicesAndSelected(this.props.videoInputDeviceList, device);
  };

  /**
   * Initiates endcall
   * @param {boolean} logout set to true if called on logout from call dashboard
   */
  endCall = (logout = false) => {
    if (this.props.demoMode) {
      this.startDemo(false);
      return;
    }
    logger.info(
      ` [${this.callMgr?.getCallId()}] Initiating end call `,
    );
    const callDate = new Date().toISOString();
    if (
      !CommonUtility.isCallHistoryRecordPresentForCallId(
        this.callMgr?.getCallId(),
        this.getDataFromLocalStorage(),
      )
    ) {
      const callRecord = {
        callee: this.state.calleeName,
        date: callDate,
        direction: CALL_DIRECTION.OUTGOING,
        id: this.callMgr?.getCallId(),
        caller: this.state.callerUserName,
        remoteSipUri: this.state.sipUsername,
      };
      console.debug(`AppManager::On endcall: Save history record ${JSON.stringify(callRecord)} by ${this.state.calleeName}`);
      this.saveCallHistoryRecordToLocalStorage(
        this.props.loggedInUserName,
        callRecord,
      );
      logger.assert(this.state.calleeName !== undefined && this.callMgr.getCallId() !== null, 'ERROR in call history');
    }
    if (this.state.callInitiatedModal) {
      this.hideCallingModal();
    }
    // logout is false by default, will receive as true when user clicks on logout during a call
    this.setState({ endCallOnLogout: logout, displayname: '' });
    this.callMgr?.endCall(true);
    this.teleHelper.clearTSArray();

    if (this.meetingsManager?.isInMeeting()) {
      this.endMeeting();
    }
  };

  endMeeting = () => {
    this.meetingsManager?.exitMeeting();
    // Redirect to login page if guest user leaves the meeting
    if (this.props.userData.is_meeting_guest === POLICY.YES) {
      console.info('Redirecting to login screen as the guest has left/cancelled OR the host has ended the meeting');
      setTimeout(() => {
        Participants.clear();
        this.handleExit();
      }, 2000);
    }
  }

  saveCallHistoryRecordToLocalStorage = (userName, callHistoryRecord) => {
    logger.debug(
      'saveCallHistoryRecordToLocalStorage:: ',
      userName,
      JSON.stringify(callHistoryRecord),
      JSON.parse(
        localStorage.getItem(
          `${this.props.loggedInUserName}${LOCAL_STORAGE_KEY.CALL_HISTORY_RECORD}`,
        ),
      ),
    );
    let callHistoryRecords = [];
    // Parse the serialized data back into an aray of objects
    callHistoryRecords =
      JSON.parse(localStorage.getItem(`${userName}${LOCAL_STORAGE_KEY.CALL_HISTORY_RECORD}`)) || [];
    // Push the new data (whether it be an object or anything else) onto the array
    callHistoryRecords.push(callHistoryRecord);
    // Re-serialize the array back into a string and store it in localStorage
    localStorage.setItem(
      `${userName}${LOCAL_STORAGE_KEY.CALL_HISTORY_RECORD}`,
      JSON.stringify(callHistoryRecords),
    );
  };

  getDataFromLocalStorage = () =>
    JSON.parse(
      localStorage.getItem(`${this.props.loggedInUserName}${LOCAL_STORAGE_KEY.CALL_HISTORY_RECORD}`),
    ) || [];

  getLoggedInUserFullName = () => {
    const names = [];
    if (this.props.userData.firstname?.trim()) names.push(this.props.userData.firstname?.trim());
    if (this.props.userData.lastname?.trim()) names.push(this.props.userData.lastname?.trim());
    return (names.length > 0 ? names.join(' ') : '');
  }

  getUserFullNameFromContacts = (userName) => {
    if (userName === this.props.loggedInUserName) {
      return this.getLoggedInUserFullName();
    }
    return CommonUtility.getUserFullName(userName, this.props.contactList);
  };

  /**
   * UI Helper
   * @returns []
   * Triggers fetch for devices and
   * updates store on completion in the background
   */

  getDeviceList(mediaType = MEDIA_TYPE.ANY) {
    let forceRefresh = true;
    let fetchVideoSource = false;
    const interactive = true;
    if (this.props.demoMode) return; // Do nothing if calldemo running
    if (mediaType === MEDIA_TYPE.VIDEO) {
      // Refresh if no user sharing.
      // If remote sharing the device list need not be updated
      // If local sharing the device list may already be available so no refresh required
      forceRefresh = LS_DATASTREAM_PROTOCOL.isActiveSharerUnknown();
      fetchVideoSource = true;
      this.setListOfDevices(mediaType, { forceRefresh, fetchVideoSource, interactive });
    } else if (mediaType === MEDIA_TYPE.AUDIO) {
      forceRefresh = !this.props.audioInputDeviceList || !this.props.audioInputDeviceList.length;
      this.setListOfDevices(mediaType, { forceRefresh, interactive });
    } else {
      logger.warn('Not a valid scenario');
    }
  }

  /**
   * UI Helper
   * @returns true/false based on if local stream to be displayed
   */
  displayLocalStream() { return LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer(); }

  /**
   * UI Helper
   * @returns true/false based on if remote stream to be displayed
   */
  displayRemoteStream() {
    return LS_DATASTREAM_PROTOCOL.isRemoteUserSharing();
  }

  /**
   * Checks if the local user is actively sharing stream
   * @returns {bool} true if local user is sharing else false
   */
  isloggedInUserVideoSharer() {
    return LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer();
  }

  renderErrorModal = () =>
    (
      <ErrorModal
        className='call-error-dialog'
        open={this.state.showError}
        errorMessage={this.error.popup.messageText}
        header={this.error.popup.messageHdr}
        onClose={this.error.popup.onClose}
        leftButton={this.error.popup.leftButton}
        rightButton={this.error.popup.rightButton}
        id={this.error.popup.id}
        showSpinner={this.error.popup.showSpinner}
        centerClass={this.error.popup.id === ERROR_TYPES.callEnded}
      />
    );

  /**
   * @ayan describe the function briefly
   * @returns none
   */
  startDrawingOnCanvas = () => {
    logger.debug('startDrawingOnCanvas()');
    const videoConfig = LS_DATASTREAM_PROTOCOL.getCurrentVideoConfig();
    if (!videoConfig) {
      logger.warn('Video config not set');
      return;
    }
    /* Check and modify canvas per resolution */
    if (LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer()) {
      this.callMgr.updateCanvas(videoConfig);
    }
  }

  togglePauseVideo = (value) => {
    const currentState = this.props.isVideoPaused ?? false;
    logger.debug('togglePauseVideo() isRequestFromRemote:', value !== undefined, ' currentState:', this.props.isVideoPaused);

    if (currentState === value) {
      logger.debug('togglePauseVideo() returning as currentstate is same as requested');
      return;
    }
    if (LS_DATASTREAM_PROTOCOL.isActiveSharerUnknown()) {
      logger.warn('togglePauseVideo() no active sharer');
      return;
    }
    this.props.setVideoPauseAction(value ?? !currentState);
    if (this.isloggedInUserVideoSharer()) {
      this.callMgr?.togglePauseVideo(value ?? !currentState);
    }

    // If not sharer and recived value via DS , then don't need to send DS message
    if (LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer() ||
      (value === undefined && !LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer())) {
      LS_DATASTREAM_PROTOCOL.notifyAboutStreamFreeze(value ?? !currentState);
    }
  }

  // Function to initialize the meetings manager
  initializeMeetingsManager = () => {
    this.meetingsManager = new MeetingsManager(
      {
        meetingsUrl: this.props.meetingSettings?.url,
        sessionToken: this.props.authToken,
        userUniqueId: this.props.userData.unique_id,
        callbacks:
        {
          meetingStateChangedEvent: (state, ended, attendee = null) => {
            if (!ended) {
              Participants.createMeetingParticipants(
                this.props.participants,
                this.meetingsManager?.getAllParticipants(),
                attendee,
              );
              if (attendee && (attendee.userId === this.props.userData.unique_id)) {
                switch (state) {
                  case ParticipantState.NotRegistered:
                    this.setState({
                      joinMeetingModal: false,
                    });
                    break;
                  case ParticipantState.Waiting:
                  case ParticipantState.InLobby:
                    this.setState({
                      joinMeetingModal: true,
                    });
                    break;
                  case ParticipantState.Active:
                    this.setState({
                      joinMeetingModal: false,
                    });
                    showToast(translate('meeting.statusJoined'));
                    break;
                  default:
                    break;
                }
              }
            } else {
              this.setState({
                joinMeetingModal: false,
              });
            }
          },
          meetingErrorEvent: (error) => {
            console.warn('meetingErrorEvent::A meeting error event has occurred:\n', error);
            let errorDialogId = ERROR_TYPES.meetingError;
            const errorMap = {
              [MeetingJoinError.MeetingNotFound]: ERROR_TYPES.meetingNotFound,
              [MeetingJoinError.MeetingDeleted]: ERROR_TYPES.meetingDeleted,
              [MeetingJoinError.NotHost]: ERROR_TYPES.meetingNotHost,
              [MeetingJoinError.GenericError]: ERROR_TYPES.meetingError,
            };
            errorDialogId = errorMap[error] ?? ERROR_TYPES.meetingError;
            this.meetingError(errorDialogId);
          },
          meetingFullEvent: () => {
            this.meetingError(ERROR_TYPES.meetingFull);
          },
        },
      },
    );
  }

  meetingError(errorDialogId) {
    this.setError(errorDialogId);
    // Redirect the guest user to login screen in case of meeting errors
    if (this.props.userData.is_meeting_guest === POLICY.YES) {
      console.warn('Redirecting to login screen due to meeting error:\n', errorDialogId);
      setTimeout(() => {
        Participants.clear();
        this.handleExit();
      }, 2000);
    }
  }

  renderVideoConsentModals = () => (
    <>
      {this.state.isVideoConsentModalOpen && (
        <Popup
          open={this.state.isVideoConsentModalOpen}
          header={translate('consentDialogue.shareConsent')}
          messageText={translate('consentDialogue.consentMessage', {
            userName: this.state.requesterParticipant?.name,
          })}
          onClose={this.onDeclineVideoShareConsentDialogue}
          rightButton={[
            {
              text: translate('consentDialogue.allow'),
              onClick: this.onAllowConsentDialogue,
            },
          ]}
          leftButton={[
            {
              text: translate('consentDialogue.decline'),
              onClick: this.onDeclineVideoShareConsentDialogue,
            },
          ]}
          className='consent-modal'
          timeout={VIDEO_CONSENT_MODAL_TIMEOUT}
        />
      )}
      {this.state.isDeclineVideoConsentModalOpen && (
        <ErrorModal
          open={this.state.isDeclineVideoConsentModalOpen}
          errorMessage={
            translate(
              'consentDialogue.declineMessage', {
                userName: this.state.requesterParticipant?.name,
              },
            )
          }
          header={translate('consentDialogue.declineHeader')}
          onClose={this.handleVideoConsentOnDecline}
          rightButton={
            [
              {
                text: translate('consentDialogue.ok'),
                onClick: this.handleVideoConsentOnDecline,
              },
            ]}
          className='consent-modal'
          timeout={VIDEO_CONSENT_MODAL_TIMEOUT}
        />
      )}
    </>
  );

  renderCallRecordingModal = () => (
    this.state.isCallRecordingModalOpen &&
    this.props.callInsightsEnabled === POLICY.YES &&
    this.props.saveCallTranscriptEnabled === POLICY.YES && (
      <Popup
        open={this.state.isCallRecordingModalOpen}
        header={translate('APPNAME')}
        messageText={translate('consentDialogue.callRecordingMessage')}
        onClose={this.onCallRecordingDialogue}
        rightButton={[
          {
            text: translate('consentDialogue.ok'),
            onClick: this.onCallRecordingDialogue,
          },
        ]}
        className='consent-modal'
      />
    )
  )

  onCallRecordingDialogue = () => {
    this.setState({ isCallRecordingModalOpen: false });
  }

  onRemoteVideoRequest = (requesterPid = '') => {
    const requesterParticipant = this.props.participants.filter(
      (member) => requesterPid === member.pId,
    );

    if (!requesterParticipant?.[0]) {
      logger.error('ERROR! Remote video request from non-existing participant');
      return;
    }

    if (!LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer()
      && this.props.isVideoConsentAllow === REMOTE_VIDEO_CONSENT.YES
    ) {
      this.setState({
        isVideoConsentModalOpen: true,
        requesterParticipant: requesterParticipant[0],
      });
    } else {
      this.startOrRequestVideoStream(requesterParticipant.pId, STREAM_SHARE_ACTION.START_SHARE,
        MEDIA_TYPE.VIDEO);
    }
  };

  onDeclineVideoShareConsentDialogue = () => {
    this.setState({
      isVideoConsentModalOpen: false,
    });
    LS_DATASTREAM_PROTOCOL.declineRemoteVideoShareRequest(this.state.requesterParticipant.pId);
  };

  onRemoteVideoDeclineRequest = (requesterPid = '') => {
    const participantAt = this.props.participants.findIndex(
      (participant) => requesterPid === participant.pId,
    );

    const requesterParticipant = this.props.participants[participantAt];
    if (!participantAt) {
      logger.error('ERROR! Got a Video decline from non-existing requester', requesterPid);
      return;
    }
    this.setState({
      isDeclineVideoConsentModalOpen: true,
      requesterParticipant,
    });
  }

  handleVideoConsentOnDecline = () => {
    this.setState({
      isDeclineVideoConsentModalOpen: false,
    });
  };

  onAllowConsentDialogue = () => {
    this.startOrRequestVideoStream(LOCAL_USER, STREAM_SHARE_ACTION.START_SHARE,
      MEDIA_TYPE.VIDEO);
    this.setState((prevState) => ({
      isVideoConsentModalOpen: !prevState.isVideoConsentModalOpen,
    }));
  };

  muteParticipant = (action, participantIds = null, toggleMute = true) => {
    switch (action) {
      case MUTE_ACTION.LOCAL:
        /* Local mute/unmute handler */
        this.onMuteRequest([LS_DATASTREAM_PROTOCOL.getLocalPId()], toggleMute);
        return;

      case MUTE_ACTION.LISTED:
        logger.assert(participantIds.length > 0, 'No ids provided for mute operation');
        // special case for local
        if ((participantIds.length === 1) &&
          participantIds.includes(LS_DATASTREAM_PROTOCOL.getLocalPId())) {
          this.onMuteRequest([LS_DATASTREAM_PROTOCOL.getLocalPId()], toggleMute);
          return;
        }
        break;

      case MUTE_ACTION.ALL_EXCEPT_LOCAL:
        participantIds = this.props.participants.reduce((pIds, p) => {
          if (!LS_DATASTREAM_PROTOCOL.isLocalPId(p.pId)) {
            // Send pIds of the participants who are currently in the meeting(meetingState=2)
            if ((!this.isMeeting()) ||
              (this.isMeeting() && (p.meetingState === ParticipantState.Active))) {
              pIds.push(p.pId);
            }
          }
          return pIds;
        }, []);
        logger.debug('PARTICIPANTS (MUTE_ALL): ', participantIds);
        break;

      case MUTE_ACTION.ALL:
        participantIds = this.props.participants.map((p) => p.pId);
        break;

      case MUTE_ACTION.NONE:
      default:
        logger.error('Error! not a valid action for mute');
        break;
    }
    /* Remote mute/unmute handler */
    LS_DATASTREAM_PROTOCOL.muteParticipant(participantIds, toggleMute);
  }

  /**
   * API: to UI
   * Requests a video share start either local or for a participant
   * This function is overloaded and can be called in following conditions
   * 1. User presses on video share start/stop icon - triggeredBy = USER_ACTION
   * 2. Screen share - when remote or local user sharing, triggeredBy = INTERNAL_ACTION,
   * streamShareAction indicating expected action
   * 3. Failure on screen share - for reverting to previous video share state
   * triggeredBy = INTERNAL_ACTION
   * 4. In case consent dialog is shown and user allows - share request
   * 5. TBD
   * @param {string} participant When null defaults to sharing from local
   * @param {STREAM_SHARE_ACTION} streamShareAction Indicates the action intended
   *  by the caller; meant to use to guard against out-of-sync state flows
   * @param {MEDIA_TYPE} streamType -  video / screen
   */
  startOrRequestVideoStream = (participantId = LOCAL_USER,
    streamShareAction = STREAM_SHARE_ACTION.NOT_SET,
    streamType = MEDIA_TYPE.VIDEO) => {
    if (!LS_DATASTREAM_PROTOCOL.isHandshakeDone) {
      logger.warn('ERROR! Can\'t start stream. Handshake not done!');
      return;
    }

    if ((participantId === LOCAL_USER) ||
      LS_DATASTREAM_PROTOCOL.isLocalPId(participantId)) {
      // Request for local user stream state change
      if (streamType === MEDIA_TYPE.VIDEO) {
        // If local screen share on?
        if (this.props.isScreenShareStarted) {
          const switchedToVideo = this.screenShare.switchToVideo(this.props.selectedVideoDevice);
          if (switchedToVideo) return;
          logger.warn('We can not transition from screen share to video; is source not selected?');
          streamShareAction = STREAM_SHARE_ACTION.STOP_SHARE; // overwrite to stop screen share
        }

        // Handle remote screen share if running
        if (this.state.isScreenShareStartedFromRemote) {
          // Case where user has pressed video share icon so we need to stop screen share and
          // change to video stream
          this.screenShare.setRemoteShareState(false);
        }
      }
      // Continue to inform peer about start/stop of current stream
      // Actual stream start to be initiated on token acquisition
      // (in handlePrepareToShareStream)

      logger.info('startOrRequestVideoStream::',
        `${CommonUtility.mapName(STREAM_SHARE_ACTION, streamShareAction)}`,
        `For - ${CommonUtility.mapName(MEDIA_TYPE, streamType)}`);

      logger.debug(
        'startOrRequestVideoStream::VideoProfile in use:',
        JSON.stringify(this.props.selectedVideoProfile),
      );

      LS_DATASTREAM_PROTOCOL.streamButtonClicked(
        streamShareAction,
      );

      console.debug('AppManager::OverlaySettings:', this.props?.overlaySettings);
      if (this.props?.overlaySettings?.isEnabledForLocation
          || this.props.allowGpsLocation === POLICY.YES) {
        browserAPI.onBrowserPermissionChange(PERMISSION.GEOLOCATION, (state) => {
          this.callMgr.toggleGeoLocationFetcher(state,
            state === false ? undefined : this.handleGpsPosChange);
        });
      }
      this.callMgr.setOverlaySettings(this.props?.overlaySettings, this.handleGpsPosChange);
      this.callMgr.setLocationStateForMedia(
        this.props.allowGpsLocation === POLICY.YES,
        this.handleGpsPosChange,
      );
    } else {
      logger.info('startOrRequestVideoStream::Toggle stream share for remote participant:',
        participantId);
      LS_DATASTREAM_PROTOCOL.requestRemoteStream(participantId);
    }
  };

  handleGpsPosChange = (position) => {
    if (LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer() &&
      this.props.allowGpsLocation === POLICY.YES) {
      LS_DATASTREAM_PROTOCOL.sendGpsPos(position);
    }
  }

  onEventGpsInfo = (position, isGpsRequested) => {
    if (!LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer() ||
      this.props.allowGpsLocation !== POLICY.YES) {
      return;
    }
    if (isGpsRequested) {
      LS_DATASTREAM_PROTOCOL.sendGpsPos(this.callMgr.getGeoLocationData());
      return;
    }
    // TODO: set position into remote stream info
    logger.debug('onEventGpsInfo received position:', position);
  }

  // #endregion

  // #region Image Capture Consent Dialogue

  renderImageCaptureConsentModals = () => (
    <>
      {!this.isSidebarPanelOpenOnTablet() && this.state.isImageCaptureConsentModalOpen && (
        <ImageCaptureConsentModal
          open={!this.isSidebarPanelOpenOnTablet() && this.state.isImageCaptureConsentModalOpen}
          header={translate('imageCaptureConsent.imageCapture')}
          headerClass='align-center'
          onClose={this.onDismissImageCaptureConsentModal}
          rightButton={[
            {
              text: translate('imageCaptureConsent.share'),
              onClick: this.onShareImageCaptureConsentDialogue,
            },
          ]}
          leftButton={[
            {
              text: translate('imageCaptureConsent.dismiss'),
              onClick: () => { this.onDismissImageCaptureConsentModal(true); },
            },
          ]}
          buttonClass='align-center'
          modalClass='image-capture-modal'
          overlayClass='image-consent-modal-wrapper'
        />
      )}
      {this.state.isImageTransferModalOpen && (
        <ImageCaptureConsentModal
          open={this.state.isImageTransferModalOpen}
          header={translate('imageCaptureConsent.transferringImage')}
          onClose={this.onCancelImageTransferModal}
          leftButton={[
            {
              text: translate('imageCaptureConsent.cancel'),
              onClick: this.onCancelImageTransferModal,
            },
          ]}
          isProgressBar
          percent={this.state.percent}
          messageText={this.state.isReceiveMode ? 'imageCaptureConsent.receiving' : 'imageCaptureConsent.sending'}
          modalClass='transfer-modal'
          overlayClass='image-transfer-modal-wrapper'
        />
      )}
    </>
  );

  /**
   * API: Called by UI control as well as from LSDS on remote request
   * @param {*} initiatedByRemote true if initiated from LSDS request
   * @returns async/await returns the picture if successful else error
   *  indicating details of failure
   */
  takePicture = async (initiatedByRemote = false) => {
    if (this.state.imageCaptureInProgress) {
      logger.warn('Previous image capture in progress!');
      return;
    }

    if (LS_DATASTREAM_PROTOCOL.getStreamState() !== STREAM_STATUS.STREAM_STARTED) {
      logger.warn('Can not capture, as no stream share not active.');
      return;
    }

    this.setState({ imageCapCancelled: false });

    let picture = null;

    try {
      if (LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer()) {
        // Take a local picture
        logger.info('Taking picture');
        this.captureImage(initiatedByRemote, (options) => {
          picture = options.imageData;
          this.imageShare.setMode(IMAGE_SHARE_MODE.SEND);
          this.performPostImgCapActions(picture, options.error);
        });
      } else {
        // Request a picture from remote - NEED TO CHECK THIS PART
        logger.info('Requesting remote image');
        picture = await LS_DATASTREAM_PROTOCOL.requestImageFromRemote(
          JPEG_COMPRESSION_QUALITY_DEFAULT,
        );
        this.performPostImgCapActions(picture);
      }
    } catch (err) {
      logger.warn('Error taking picture', err);
      this.setError(ERROR_TYPES.imageCaptureFailed);
      this.imageShare.clearState(this);
      this.onDismissImageCaptureConsentModal();
    }
  }

  performPostImgCapActions = (picture, error = null) => {
    if (picture) {
      this.imageShare.captureData = picture;
      document.getElementById(CALL_DASHBOARD.PHOTO_ID).src = URL.createObjectURL(
        new Blob([picture.data], { type: IMAGE_MIME_TYPE.JPEG } /* (1) */),
      );
      this.teleHelper.reInitializeCanvasManagerForImage(
        this.props.selectedVideoProfile,
        CANVAS_MANAGER_TIMEOUT,
        {
          TeleV3: _.cloneDeep(picture.TeleV3),
          mediaConfig: picture.config,
        },
      );
      this.setState({
        lastPicture: picture,
        isImageCaptureConsentModalOpen: true,
      });
      logger.info('performPostImgCapActions() Take picture complete', picture);
    } else {
      logger.warn('No picture data received:', error);
      // Release resource when no image recieved
      this.setError(ERROR_TYPES.imageCaptureFailed);
      this.imageShare.clearState(this);
      this.onDismissImageCaptureConsentModal();
    }
  }

  toggleImageShare = async () => {
    logger.info('toggleImageShare', this.state.lastPicture, LS_DATASTREAM_PROTOCOL.getStillImageShareState());
    if (this.state.lastPicture && LS_DATASTREAM_PROTOCOL.getStillImageShareState() ===
      STILL_IMAGE_SHARE_MODE_STATUS.STILL_IMAGE_SHARE_MODE_EXIT) {
      try {
        await LS_DATASTREAM_PROTOCOL.requestImageShare(this.state.lastPicture);
      } catch (e) {
        logger.error('Error requesting image share', e);
        if (this.state.isImageTransferModalOpen) {
          this.setState({
            isImageTransferModalOpen: false,
            percent: 0,
            isReceiveMode: false,
          });
        }
        LS_DATASTREAM_PROTOCOL.exitImageShare();
        this.imageShare.clearState(this);
      }
    } else {
      LS_DATASTREAM_PROTOCOL.exitImageShare();
      this.imageShare.clearState(this);
      if (!LS_DATASTREAM_PROTOCOL.isActiveSharerUnknown()) {
        this.teleHelper.canvasResize(this.props.selectedVideoProfile);
      }
    }
  }

  onShareImageCaptureConsentDialogue = async () => {
    this.setState({ isImageTransferModalOpen: true, isImageCaptureConsentModalOpen: false });
    if (this.imageShare.captureData) {
      logger.debug(' Share image with ImageData :', this.imageShare.captureData);
      this.toggleImageShare();
      this.setState({ isImageCaptureConsentModalOpen: false });
    }
  }

  onDismissImageCaptureConsentModal = (isReinitializeCanvas = false) => {
    LS_DATASTREAM_PROTOCOL.exitImageShare();
    this.imageShare.clearState(this);
    this.setState({ isImageCaptureConsentModalOpen: false }, () => {
      if (isReinitializeCanvas) {
        this.teleHelper.clearImageTelestrationsOnly();
        this.teleHelper.initializeCanvasManager(
          this.props.selectedVideoProfile,
          CANVAS_MANAGER_TIMEOUT,
        );
      }
    });
  }

  onReceiveImageTransferProgress = (transferredData) => {
    const { bytesReceived, fileSize } = transferredData;
    if (fileSize !== 0) {
      const percentage = Math.floor((bytesReceived * 100) / fileSize);
      if (percentage < 100) {
        this.setState({
          percent: percentage,
          isImageTransferModalOpen: true,
          isReceiveMode: true,
        });
      } else if (percentage === 0 || percentage === 100) {
        this.setState({
          isImageTransferModalOpen: false,
          percent: 0,
          isReceiveMode: false,
        });
      }
    }
  }

  /**
   * This will get called whenever local image capture is to be initiated
   * @Ruchika - check if the state updates across takePicture and captureImage are proper
   * @param {*} captureOptions Provides the options required for capturing image
   */
  // eslint-disable-next-line default-param-last
  captureImage = async (initiatedByRemote = false, callback) => {
    const quality = JPEG_COMPRESSION_QUALITY_DEFAULT;
    const permissions = undefined;
    console.log('captureImage:', quality, permissions);
    const isScreenShare =
      this.props.selectedVideoDevice?.deviceId === SCREEN_SHARE_DEVICE.deviceId;

    logger.debug('captureImage()');
    // Proceed only if we are sharing
    const videoConfig = LS_DATASTREAM_PROTOCOL.getCurrentVideoConfig();
    if (!initiatedByRemote) {
      LS_DATASTREAM_PROTOCOL.notifyAboutSelfImageCapture(
        STILL_IMAGE_CAPTURE_STATUS.STILL_IMAGE_CAPTURE_REQUESTED_REMOTE,
      );
    }

    /* Set state to disable controls while capture in progress */
    this.setState({ imageCaptureInProgress: true }, () => {
    // new Promise((resolve, reject) => {
      let myDate = new Date(Date.now());
      logger.debug('captureImage() capture starting:', myDate.getHours() + ':' + ('0' + (myDate.getMinutes())).slice(-2) + ':' + myDate.getSeconds() + ':' + myDate.getMilliseconds());
      this.callMgr?.takePicture(
        {
          videoConfig,
          quality,
          isScreenShare,
          imgCapTorchModeState: this.imgCapTorchModeState,
          permissions,
          isVideoPaused: this.props.isVideoPaused,
          activeDeviceIllumInfo: LS_DATASTREAM_PROTOCOL.getActiveDeviceIllumInfo(),
          productVersion: onsightProductVersion(),
          imgMaxHeight: this.props.maxCaptureHeight,
        },
        this.teleHelper.setTeleV3DataForImage,
        this.props.allowGpsLocation === ALLOWED_FROM_OPM,
      ).then((imageData) => {
        myDate = new Date(Date.now());
        logger.debug('captureImage() capture complete:', myDate.getHours() + ':' + ('0' + (myDate.getMinutes())).slice(-2) + ':' + myDate.getSeconds() + ':' + myDate.getMilliseconds());
        LS_DATASTREAM_PROTOCOL.notifyAboutSelfImageCapture(
          STILL_IMAGE_CAPTURE_STATUS.STILL_IMAGE_CAPTURE_COMPLETE,
        );
        /* Update state */
        this.setState({
          imageCaptureInProgress: false,
        });
        logger.info('Image capture complete!', imageData);
        // resolve(imageData);
        callback({ imageData });
      })
        .catch((error) => {
          logger.warn('Error in capturing image', error);
          LS_DATASTREAM_PROTOCOL.notifyAboutSelfImageCapture(
            STILL_IMAGE_CAPTURE_STATUS.STILL_IMAGE_CAPTURE_ERROR,
          );
          this.setState({
            imageCaptureInProgress: false,
          });
          // Exit image share and clear image state param
          this.onDismissImageCaptureConsentModal();
          callback({ error });
        });
    },
    );
  };

  /**
   *
   * @param {*} transferredData
   */
  onSendImageTransferProgress = (transferredData) => {
    const { bytesReceived, fileSize } = transferredData;
    if (fileSize !== 0) {
      const percentage = Math.floor((bytesReceived * 100) / (fileSize));
      if (percentage <= 100) {
        this.setState({
          percent: percentage,
          isImageTransferModalOpen: true,
          isReceiveMode: false,
        }, () => {
          if (percentage === 100) {
            const self = this;
            setTimeout(() => {
              self.setState({
                isImageTransferModalOpen: false,
                percent: 0,
                isReceiveMode: false,
              });
            }, 300);
          }
        });
      }
    }
  }

  onCancelImageTransferModal = (event) => {
    logger.log('Image transfer cancled by event::', event);
    LS_DATASTREAM_PROTOCOL.cancelImageTransfer(true);
    this.setState({ lastPicture: null, imageCaptureInProgress: false });

    this.teleHelper.initializeCanvasManager(
      this.props.selectedVideoProfile,
      CANVAS_MANAGER_TIMEOUT,
    );
  }

  onImageTransferCanceled = () => {
    logger.info('Image transfer cancelled');
    this.imageShare.clearState(this);
    this.teleHelper.initializeCanvasManager(
      this.props.selectedVideoProfile,
      CANVAS_MANAGER_TIMEOUT,
    );
    this.setState({ imageCapCancelled: true });
  }

  allowImageCapture = () => (!this.state.imageCaptureInProgress &&
    this.state.stillImageShareModeState ===
    STILL_IMAGE_SHARE_MODE_STATUS.STILL_IMAGE_SHARE_MODE_EXIT &&
    LS_DATASTREAM_PROTOCOL.getStreamState() === STREAM_STATUS.STREAM_STARTED
    && !this.state.isImageCaptureConsentModalOpen);

  setImage = (imageData, isRemoteShare) => {
    if (document.getElementById(CALL_DASHBOARD.PHOTO_ID)) {
      this.imageShare.captureData = imageData;
      if (imageData) {
        this.imageShare.setMode(
          isRemoteShare === 1
            ? IMAGE_SHARE_MODE.RECEIVE
            : IMAGE_SHARE_MODE.SEND,
        );
        document.getElementById(CALL_DASHBOARD.PHOTO_ID).src = URL.createObjectURL(
          new Blob([imageData], { type: IMAGE_MIME_TYPE.JPEG } /* (1) */),
        );
        // Clear the image telestrations when another image is
        // shared from OC when no one is video sharer.
        this.teleHelper.clearImageTelestrationsOnly();
        this.teleHelper.reInitializeCanvasManagerForImage(
          this.props.selectedVideoProfile,
          CANVAS_MANAGER_TIMEOUT,
        );
      }
      // Set this after the share mode set
      this.setState({ lastPicture: imageData });
      logger.info('Received image from remote');
    }
  }
  // #endregion

  toggleIllumination = (mode) => {
    logger.debug('toggleIllumination() mode:', mode);
    if (!LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer() && mode === FILL_LIGHT_MODE.ON) {
      logger.debug('toggleIllumination() for remote:', this.videoTorchState);
      this.videoTorchState = !this.videoTorchState;
      LS_DATASTREAM_PROTOCOL.setIllumState(this.videoTorchState ? IllumState.On : IllumState.Off);
      return;
    }
    if (this.props?.selectedVideoDevice?.label === SCREEN_SHARE_DEVICE.label) {
      logger.warn('toggleIllumination() Illumination is not allowed during screen share');
      return;
    }

    switch (mode) {
      case FILL_LIGHT_MODE.ON:
        try {
          this.callMgr.toggleTorch(!this.videoTorchState,
            LS_DATASTREAM_PROTOCOL.getActiveDeviceIllumInfo()?.videoTorchState);
          this.videoTorchState = !this.videoTorchState;
          LS_DATASTREAM_PROTOCOL.setIllumState(
            !this.videoTorchState ? IllumState.On : IllumState.Off,
          );
        } catch (error) {
          logger.warn('Error in toggling videoTorchState:', !this.videoTorchState, error);
        }
        break;
      case FILL_LIGHT_MODE.OFF:
        this.imgCapTorchModeState.off = !this.imgCapTorchModeState.off;
        if (this.imgCapTorchModeState.off) {
          this.imgCapTorchModeState.auto = false;
          this.imgCapTorchModeState.flash = false;
        }
        break;
      case FILL_LIGHT_MODE.AUTO:
        this.imgCapTorchModeState.auto = !this.imgCapTorchModeState.auto;
        if (this.imgCapTorchModeState.auto) {
          this.imgCapTorchModeState.off = false;
          this.imgCapTorchModeState.flash = false;
        }
        break;
      case FILL_LIGHT_MODE.FLASH:
        this.imgCapTorchModeState.flash = !this.imgCapTorchModeState.flash;
        if (this.imgCapTorchModeState.flash) {
          this.imgCapTorchModeState.off = false;
          this.imgCapTorchModeState.auto = false;
        }
        break;
      default:
        logger.warn('toggleIllumination() mode:', mode, ' is not handled');
        break;
    }
  }

  /* Initializes all error modal definitiations used by the component */
  initErrorPopups = () => {
    const cancelCallButton = {
      text: translate('connect.cancel'),
      onClick: () => {
        this.handleDismissError('cancel');
        const self = this;
        setTimeout(() => {
          logger.info('Cancelling call');
          self.callMgr?.abortCall();
        }, TIMER_CONSTANTS.EVENT_LOOP_BREAKER);
      },
    };

    const cancelButton = {
      text: translate('connect.cancel'),
      onClick: () => {
        this.handleDismissError('cancel');
      },
    };

    const okToExitButton = {
      text: translate('connect.ok'),
      onClick: () => {
        this.handleDismissError();
        this.handleExit();
      },
    };

    const okButton = {
      text: translate('connect.ok'),
      onClick: this.handleDismissError,
    };

    this.errorPopups = {
      genericError: {
        messageText: '',
        messageHdr: 'APPNAME',
        rightButton: [okButton],
        onClose: this.handleDismissError,
      },
      userNotAvailable: {
        messageText: 'errors.clientIsNotAvailable',
        messageHdr: 'APPNAME',
        rightButton: [okButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.userNotAvailable,
      },
      userNotResponding: {
        messageText: 'errors.clientNotResponding',
        messageHdr: 'APPNAME',
        rightButton: [okButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.userNotResponding,
      },
      /* Error without any call in progress */
      connectErr: {
        messageText: 'connect.networkErrMsg',
        messageHdr: 'errors.serverErrorHeading',
        rightButton: [okButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.connectErr,
      },
      inCallNetworkErr: {
        messageText: 'connect.reconnectMessage',
        messageHdr: 'connect.callReconnect',
        rightButton: [cancelCallButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.inCallNetworkErr,
        showSpinner: true,
      },
      inCallConnectFail: {
        messageText: 'connect.reconnectErrmsg',
        messageHdr: 'connect.callReconnect',
        /*
        leftButton: [cancelButton],
        rightButton: [reconnectButton],
        */
        rightButton: [cancelButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.inCallConnectFail,
      },
      connectFatal: {
        messageText: 'errors.serverConnection',
        messageHdr: 'errors.serverErrorHeading',
        rightButton: [okToExitButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.connectFatal,
      },
      callEnded: {
        messageText: 'errors.callEnded',
        messageHdr: 'APPNAME',
        rightButton: [okButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.callEnded,
      },
      sessionUp: {
        messageText: 'connect.networkUp',
        messageHdr: 'APPNAME',
        rightButton: [okButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.sessionUp,
      },
      callUp: {
        messageText: 'connect.callUp',
        messageHdr: 'APPNAME',
        rightButton: [okButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.callUp,
      },
      registrationErr: {
        messageText: 'errors.registrationErr',
        messageHdr: 'errors.serverErrorHeading',
        rightButton: [okToExitButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.registrationErr,
      },
      callDeclined: {
        messageText: 'errors.callDeclined',
        messageHdr: 'APPNAME',
        rightButton: [okButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.callDeclined,
      },
      callCancelled: {
        messageText: 'errors.callCancelled',
        messageHdr: 'APPNAME',
        rightButton: [okButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.callCancelled,
      },
      errorInGettingStream: {
        messageText: 'errors.errorInGettingStream',
        messageHdr: 'APPNAME',
        rightButton: [okButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.errorInGettingStream,
      },
      permissionsError: {
        messageText: 'errors.browserPermissions',
        messageHdr: 'APPNAME',
        rightButton: [okButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.permissionsError,
      },
      missingSDP: {
        messageText: 'errors.missingSDP',
        messageHdr: 'errors.serverErrorHeading',
        rightButton: [okButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.missingSDP,
      },
      invalidRequest: {
        messageText: 'errors.invalidRequest',
        messageHdr: 'errors.serverErrorHeading',
        rightButton: [okButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.invalidRequest,
      },
      unknownSIPError: {
        messageText: 'errors.internalError',
        messageHdr: 'errors.serverErrorHeading',
        rightButton: [okButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.unknownSIPError,
      },
      noCameraDevice: {
        messageText: 'errors.noCameraDevice',
        messageHdr: 'APPNAME',
        rightButton: [okButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.noCameraDevice,
      },
      noAudioDevice: {
        messageText: 'errors.noAudioDevice',
        messageHdr: 'APPNAME',
        rightButton: [okButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.noAudioDevice,
      },
      imageCaptureFailed: {
        messageText: 'errors.imageCaptureFailed',
        messageHdr: 'APPNAME',
        rightButton: [okButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.imageCaptureFailed,
      },
      screenShareDeclined: {
        messageText: 'errors.screenShareDeclined',
        messageHdr: 'APPNAME',
        rightButton: [okButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.screenShareDeclined,
      },
      meetingNotFound: {
        messageText: 'errors.meetingNotFound',
        messageHdr: 'APPNAME',
        rightButton: [okButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.meetingNotFound,
      },
      meetingDeleted: {
        messageText: 'errors.meetingDeleted',
        messageHdr: 'APPNAME',
        rightButton: [okButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.meetingDeleted,
      },
      meetingError: {
        messageText: 'errors.meetingError',
        messageHdr: 'APPNAME',
        rightButton: [okButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.meetingError,
      },
      meetingNotHost: {
        messageText: 'errors.meetingNotHost',
        messageHdr: 'APPNAME',
        rightButton: [okButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.meetingNotHost,
      },
      meetingFull: {
        messageText: 'errors.meetingFull',
        messageHdr: 'APPNAME',
        rightButton: [okButton],
        onClose: this.handleDismissError,
        id: ERROR_TYPES.meetingFull,
      },
    };
  }

  /**
   *
   * @returns if sidebar is open on the Tablet in portrait mode
   */
  isSidebarPanelOpenOnTablet() {
    return this.props?.location.pathname !== ROUTES.CALL_DASHBOARD && !isBrowser
      && window.innerWidth < 1023 && this.props.isPortrait;
  }

  // Store NLP data in local storage, state and initialize NlpHelper
  setNLPData = (enableCaptions, fromLang, toLang) => {
    let nlpData = {};
    // Already existing data or default data
    if (!enableCaptions && (!fromLang) && (!toLang)) {
      const defaultData = {
        captionsOn: false,
        toLanguage: NLP_LANGUAGES[12].value,
        fromLanguage: NLP_LANGUAGES[12].value,
      };
      nlpData = JSON.parse(localStorage.getItem(
        `${this.props.loggedInUserName}${LOCAL_STORAGE_KEY.NLP_DATA}`,
      )) || defaultData;
    } else {
      // Update data
      nlpData = {
        captionsOn: enableCaptions,
        toLanguage: toLang,
        fromLanguage: fromLang,
      };
    }
    // Store the NLP data
    this.setState({
      captionsOn: nlpData.captionsOn,
      fromLanguage: nlpData.fromLanguage,
      toLanguage: nlpData.toLanguage,
    }, () => {
      localStorage.setItem(
        `${this.props.loggedInUserName}${LOCAL_STORAGE_KEY.NLP_DATA}`,
        JSON.stringify(nlpData),
      );
      logger.debug('Appmanager::setnlpdata::', nlpData);
      this.initializeNLPHelper();
    });
  }

  initializeNLPHelper = () => {
    if (!this.nlpHelper && this.props.nlpUrl) {
      logger.debug('initializeNLPHelper');
      this.nlpHelper = new NlpHelper({
        authToken: this.props.authToken,
        toLanguage: this.state.toLanguage,
        fromLanguage: this.state.fromLanguage,
        callback: this.onReceivingNlpCaption,
        nlpUrl: this.props.nlpUrl,
        loggedInUserName: this.props.loggedInUserName,
      });
    }
  }

  // Image meta data actions for telestration
  processImageData = (imageData) => {
    if (imageData) {
      this.teleHelper.handleImageData(imageData);
    } else {
      logger.warn('No image data found to take action on');
    }
  }

  // remoteColorCode - colour code of remote participant in case of peer to peer call
  // colourData - colourData array of all users received from OC app
  updateRemoteParticipantColor = (remoteColorCode, colourData) => {
    let participantList = this.props.participants;
    const index = participantList.findIndex(
      (p) => p.pId === LS_DATASTREAM_PROTOCOL.getRemotePId(),
    );

    participantList = Participants.updateRemoteColour(participantList, colourData);
    this.setState({ remoteTSColor: argbToRGB(remoteColorCode) }, () => {
      if (index >= 0) {
        participantList[index].colorCode = argbToRGB(remoteColorCode);
        Participants.set(participantList, true);
        this.props.setParticipantsAction(participantList);
      }
    });
  }

  // Store the TS color of the local user and update in color palette
  storeLocalColor = () => {
    if (this.teleHelper) {
      const tsColorCode = this.teleHelper?.saveTSColor();
      this.setState({ tsColor: tsColorCode }, () => {
        const colorObj = Object.assign(this.props.userTSData,
          { ...this.props.userTSData, colorCode: tsColorCode });
        this.props.setTelestrationIconDetails(colorObj);
      });
    }
  }

  // zoom
  updateZoomInfo = (zoomInfo) => {
    logger.debug('onReceivingZoomInfo() zoomInfo:', zoomInfo);
    // Update zoom slider, based on received range
    this.setState({
      // eslint-disable-next-line no-unsafe-optional-chaining
      zoomMinRange: zoomInfo?.levelMin / parseFloat(10),
      // eslint-disable-next-line no-unsafe-optional-chaining
      zoomMaxRange: zoomInfo?.levelDigitalMax / parseFloat(10),
    });
  }

  updateZoomLevel = (zoomValue) => {
    logger.debug('onReceivingZoomLevel() zoomLevelInfo:', zoomValue);
    zoomValue /= parseFloat(10);

    this.onChangeZoomRange(zoomValue, true);
  }

  onChangeZoomRange = (zoomRange, isRemoteReq = false) => {
    logger.debug('onChangeZoomRange() zoomRange:', zoomRange, ' current zoomlevel:', this.state.zoomLevel);
    if (this.state.zoomLevel === zoomRange) {
      return;
    }

    if (LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer()) {
      this.callMgr.changeVideoZoomLevel(zoomRange);
    }
    if (LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer() ||
    (!LS_DATASTREAM_PROTOCOL.isloggedInUserActiveVideoSharer() && !isRemoteReq)) {
      const direction = zoomRange > this.state.zoomLevel ?
        EZoomDirection.ZoomTele : EZoomDirection.ZoomWide;
      LS_DATASTREAM_PROTOCOL.notifyAboutZoomLevelChange(zoomRange, direction);
    }
    this.updateZoomlevelOnUI(zoomRange);
  }

  updateZoomlevelOnUI = (value) => {
    logger.debug('updateZoomlevelUI() zoomLevelInfo:', value);
    this.setState({ zoomLevel: value });
  }

  isMeeting = () => !!(((window.dynamicEnv.REACT_APP_ALLOW_MEETINGS === 'true') &&
    (this.props.meetingSettings?.enabled === POLICY.YES) &&
    (this.props.meetingSettings?.url) && (this.getMeetingId())));

  handleTeleCanvasOnComponentToggle = () => {
    if (!LS_DATASTREAM_PROTOCOL.isActiveSharerUnknown()) {
      this.teleHelper.handleTeleCanvasOnComponentToggle(
        this.imageShare.captureData,
        this.props.selectedVideoProfile,
      );
    }
  }

  render() {
    const isCallerStream =
      this.props.loggedInUserName &&
      this.state.callerUserName &&
      this.props.loggedInUserName !== this.state.callerUserName;

    const screenShareProps = {
      startedFromRemote: this.state.isScreenShareStartedFromRemote,
      isAllowed: () => this.screenShare.isAllowed(),
      handler: () => this.screenShareHandler(),
    };

    return (
      <>
        {this.renderVideoConsentModals()}
        {this.renderImageCaptureConsentModals()}
        {this.renderCallRecordingModal()}
        <CustomModal
          open={this.state.callInitiatedModal}
          text={translate('videoCalling.calling')}
          text1={CommonUtility.getUserNameFromContacts(
            this.state.calleeName,
            this.props.contactList,
          )}
          center
          showCloseIcon={false}
          incomingCall={false}
          classNames={{
            modal: 'custom-modal',
          }}
          handleDismissError={this.endCall}
          centeredButton
          disabledButton={this.state.disabledButton}
          closeOnOverlayClick={false}
        />
        <CustomModal
          open={this.state.receivingCallModal}
          text={this.state.displayname}
          text1={translate('videoCalling.isCalling')}
          center
          showCloseIcon={false}
          incomingCall
          classNames={{
            modal: 'custom-modal',
          }}
          closeOnOverlayClick={false}
          multiButtons
          leftButton={[
            {
              text: translate('videoCalling.decline'),
              onClick: () => this.endCall(),
            },
          ]}
          rightButton={[
            {
              text: translate('videoCalling.accept'),
              onClick: this.acceptCall,
            },
          ]}
        />
        <ToastContainer />

        {this.error.popup && this.renderErrorModal()}

        <PrivateRoute
          component={DashboardContainer}
          path={ROUTES.DASHBOARD}
          placeCall={this.placeCall}
          getCallHistoryRecords={this.getDataFromLocalStorage}
          callRecords={this.callRecords}
          isWebappReady={this.state.webappReady}
          joinMeetingModal={this.state.joinMeetingModal}
          cancelMeeting={this.endMeeting}
        />
        {this.teleHelper ?
          (
            <PrivateRoute
              component={CallDashboardContainer}
              path={[ROUTES.CALL_DASHBOARD, ROUTES.CALL_DASHBOARD_DEMO]}
              /* Under trial
              key={this.props.demoMode ? ROUTES.CALL_DASHBOARD_DEMO :
                ROUTES.CALL_DASHBOARD}
              */
              acceptCall={this.acceptCall}
              muteParticipant={this.muteParticipant}
              // muteAudio={this.state.muteAudio}
              muteUnmute={this.muteUnmute}
              localStream={this.state.localStream}
              remoteStream={this.state.remoteStream}
              endCall={this.endCall}
              isCallerStream={isCallerStream}
              activeSharerPId={this.state.activeSharerPId}
              isImageShareMode={
                this.imageShare.mode === IMAGE_SHARE_MODE.SEND
              }
              isImageReceiveMode={
                this.imageShare.mode === IMAGE_SHARE_MODE.RECEIVE
              }
              imageCaptureInProgress={this.state.imageCaptureInProgress}
              lastPicture={this.state.lastPicture}
              stillImageShareModeState={this.state.stillImageShareModeState}
              isloggedInUserVideoSharer={this.isloggedInUserVideoSharer}
              changeAudioDevice={this.changeAudioDevice}
              changeVideoDevice={this.changeVideoDevice}
              getDeviceList={(mediaType) => this.getDeviceList(mediaType)}
              displayLocalStream={this.displayLocalStream}
              displayRemoteStream={this.displayRemoteStream}
              selectedVideoProfile={this.props.selectedVideoProfile}
              startDrawingOnCanvas={this.startDrawingOnCanvas}
              isVideoConsentAllow={this.props.isVideoConsentAllow}
              startOrRequestVideoStream={(participantId) => this.startOrRequestVideoStream(
                participantId, STREAM_SHARE_ACTION.TOGGLE, MEDIA_TYPE.VIDEO,
              )}
              imageCaptureData={this.imageShare.captureData}
              takePicture={this.takePicture}
              toggleImageShare={this.toggleImageShare}
              selectedVideoDevice={this.props.selectedVideoDevice}
              isWebappReady={this.state.webappReady}
              isImageCaptureEnable={this.allowImageCapture}
              setError={this.setError}
              closeImage={this.onDismissImageCaptureConsentModal}
              setShowVideoFallbackUI={this.props.setShowVideoFallbackUI}
              teleHelper={this.teleHelper}
              screenShareProps={screenShareProps}
              toggleIllumination={this.toggleIllumination}
              setIlluminationButtonState={this.setIlluminationButtonState}
              captionStr={this.state.captionStr}
              isNLPWord={this.state.isNLPWord}
              captionsOn={this.state.captionsOn}
              fromLanguage={this.state.fromLanguage}
              toLanguage={this.state.toLanguage}
              updateNLPData={this.setNLPData}
              nlpUrl={this.props.nlpUrl}
              onChangeZoomRange={this.onChangeZoomRange}
              zoomLevel={this.state.zoomLevel}
              zoomMinRange={this.state.zoomMinRange}
              zoomMaxRange={this.state.zoomMaxRange}
              selectedLocalColor={this.teleHelper.saveTSColor()}
              isOnFlashLight={this.state.isOnFlashLight}
              handleFlashLightChange={this.handleFlashLightChange}
              togglePauseVideo={this.togglePauseVideo}
              imageCapCancelled={this.state.imageCapCancelled}
              isMeeting={this.isMeeting()}
              handleTeleCanvasOnComponentToggle={this.handleTeleCanvasOnComponentToggle}
            />
          ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: getAuthToken(state),
  loggedInUserName: getLoggedInUserName(state),
  audioInputDeviceList: getAudioInputDeviceList(state),
  audioOutputDeviceList: getAudioOutputDeviceList(state),
  videoInputDeviceList: getVideoInputDeviceList(state),
  audioOn: isAudioOn(state),
  videoOn: isVideoOn(state),
  contactList: getPersonalContacts(state),
  userData: getUserData(state),
  sipUserName: getSipUserName(state), // Temporary to get sipUser from query parameter
  clientSettingsData: getClientSettingsData(state),
  selectedAudioInputDevice: getAudioInputDevice(state),
  selectedAudioOutputDevice: getAudioOutputDevice(state),
  selectedVideoDevice: getSelectedVideoDevice(state),
  participants: getParticipants(state),
  isOPMSessionExpired: getIsSessionExpiredFlag(state),
  loginRequestTimeStamp: getLoginRequestTimestamp(state),
  policyRequestTimestamp: getClientPolicyReqTimestamp(state),
  profileRequestTimestamp: getProfileReqTimestamp(state),
  sessionPollingInterval:
    getClientSessionConfigInfo(state)?.session_polling_interval_seconds,
  callHistoryRecords: getCallHistoryRecords(state),
  selectedVideoProfile: getSelectedVideoProfile(state),
  videoMediaConfigs: getVideoMediaConfigs(state),
  audioMediaConfigs: getAudioMediaConfigs(state),
  isVideoConsentAllow:
    getSecuritySettings(state)?.remote_live_video_share_consent_prompt,
  nlpUrl: getNLPUrl(state),
  userTSData: getTSData(state),
  clientPolicyPermissionsXmlData: getClientPermissions(state),
  screenSharePolicy: getPolicyUISettings(state)?.screen_sharing_enabled,
  isScreenShareStarted: isScreenShareOn(state),
  isVideoPaused: getIsVideoPaused(state),
  webRtcToken: getWebRtcToken(state),
  demoMode: isDemoRunning(state),
  launchParameters: getLaunchParameters(state),
  meetingSettings: getMeetingSettings(state),
  overlaySettings: getOverlaySettings(state),
  streamShareState: getStreamShareState(state),
  customMessageData: getCustomMessageData(state),
  allowGpsLocation: getSecuritySettings(state)?.allow_gps_location,
  saveCallTranscriptEnabled: getClientPolicyData(state)?.security?.save_call_transcript_enabled,
  callInsightsEnabled: getClientPolicyData(state)?.ai?.call_insights_enabled,
  maxCaptureHeight: getImageCaptureSettings(state)?.max_capture_height ?
    parseInt(getImageCaptureSettings(state)?.max_capture_height, 10) : MAX_CAMERA_RES.HEIGHT,
});

// eslint-disable-next-line no-unused-vars
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setDeviceList,
      setVideoIconAction,
      setAudioIconAction,
      setParticipantsAction,
      clearParticipantsAction,
      setAudioInputDeviceAction,
      setAudioOutputDeviceAction,
      setVideoDeviceAction,
      setIncomingCall,
      setShowVideoFallbackUI,
      getClientPolicyAction,
      getProfileAction,
      setSelectedVideoProfileAction,
      setVideoDeviceListAction,
      setVideoMediaConfigsAction,
      setTelestrationIconDetails,
      setScreenShareAction,
      setVideoPauseAction,
      getWebRtcTokenAction,
      setDemoMode,
      setLaunchParametersAction,
      setStreamShareStateAction,
      setImageShareModeAction,
      setCallStartAction,
      setCallEndAction,
    },
    dispatch,
  );
}

export default withLoadingSpinner(
  withOrientationChange(
    withRouter(
      connect(mapStateToProps, mapDispatchToProps)(AppManager),
    ),
  ),
  { spinnerText: translate('errors.reconnecting') },
);
