/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-unsafe-optional-chaining */
import React, { Component } from 'react';

import { Row, Col, Form } from 'react-bootstrap';
// Translation
import { translate } from 'SERVICES/i18n';

// Custom component
import CustomForm from 'COMPONENTS/CustomComponents/CustomForm/CustomForm';
import CustomDropdown from 'COMPONENTS/CustomComponents/CustomDropdown/CustomDropdown';
import { isMobileOnly } from 'react-device-detect';

// Constants
import { VIDEO_PROFILES, UNITS, OTHER_PARTICIPANT } from 'UTILS/constants/UtilityConstants';

// Style
import './Settings.scss';
import '../../resources/styles/globalStyles.scss';

// component
import Sidebar from 'COMPONENTS/Dashboard/Sidebar';

// Asset
import { ReactComponent as BackIcon } from 'ASSETS/icon_back_arrow.svg';

import _ from 'lodash';

import { VIDEO_MEDIA_PROFILE, SS_MEDIA_CONFIG } from 'UTILS/constants/MediaServerConstants';

export default class VideoQuality extends Component {
  static videoMediaConfigs = _.clone(VIDEO_PROFILES);

  componentDidMount() {
    this.setVideoProfiles();
  }

  componentDidUpdate() {
    this.setVideoProfiles();
  }

  setVideoProfiles() {
    const videoConfigs = this.props?.data?.videoProfiles || this.props?.videoProfiles;
    console.debug(`VideoQuality::setVideoProfiles() new videoConfigs:${JSON.stringify(videoConfigs)}
      old videoConfigs:${JSON.stringify(VideoQuality.videoMediaConfigs)}`);

    // clear array
    VideoQuality.videoMediaConfigs.length = 0;

    // Add built-in profiles based on client permissions
    const clientPolicyPermissionsXmlData = this.props?.data?.clientPolicyPermissionsXmlData || this.props?.clientPolicyPermissionsXmlData
    const { SQLow, SQMed, SQHigh, SQHD, SQFULLHD } = clientPolicyPermissionsXmlData;
    VIDEO_PROFILES.forEach((vp) => {
      const includeProfile = ((vp.streamQuality === VIDEO_MEDIA_PROFILE.LOW && SQLow) ||
        (vp.streamQuality === VIDEO_MEDIA_PROFILE.MEDIUM && SQMed) ||
        (vp.streamQuality === VIDEO_MEDIA_PROFILE.HIGH && SQHigh) ||
        (vp.streamQuality === VIDEO_MEDIA_PROFILE.HD && SQHD) ||
        (vp.streamQuality === VIDEO_MEDIA_PROFILE.FHD && SQFULLHD));
      if (includeProfile) {
        VideoQuality.videoMediaConfigs.push(vp);
      }
    });

    // Get custom profiles if any
    const customProfiles = videoConfigs?.filter(
      (value) => !VideoQuality.videoMediaConfigs.some((videoConfig) => videoConfig.OPMvalue?.includes(value.name)),
    );

    // Add these custom profiles in the dropdown
    // eslint-disable-next-line array-callback-return
    customProfiles?.map((cp) => {
      VideoQuality.videoMediaConfigs.push(
        {
          label: cp?.name,
          value: cp?.name,
          streamQuality: VIDEO_MEDIA_PROFILE.CUSTOM,
          OPMvalue: cp?.name,
        },
      );
    });

    // Remove 'from other participant' from dropdown,
    // if 'from other participant' is not present in videoConfigs
    if (VideoQuality.videoMediaConfigs.find((i) => i.OPMvalue === OTHER_PARTICIPANT) &&
      (!videoConfigs.find((j) => j.streamQuality === VIDEO_MEDIA_PROFILE.FROM_OTHER_PARTICIPANT))) {
      VideoQuality.videoMediaConfigs = VideoQuality.videoMediaConfigs.filter((i) => (i.OPMvalue !== OTHER_PARTICIPANT));
    }

    // Add 'from other participant' in dropdown if we get streamquality '6'
    videoConfigs.forEach((config) => {
      if (config.streamQuality === VIDEO_MEDIA_PROFILE.FROM_OTHER_PARTICIPANT && (!VideoQuality.videoMediaConfigs.find((i) => i.streamQuality === VIDEO_MEDIA_PROFILE.FROM_OTHER_PARTICIPANT)).length === 0) {
        VideoQuality.videoMediaConfigs.push({ label: OTHER_PARTICIPANT, value: OTHER_PARTICIPANT, streamQuality: VIDEO_MEDIA_PROFILE.FROM_OTHER_PARTICIPANT, OPMvalue: OTHER_PARTICIPANT });
      }
    });

    // If screen share is false, remove it from the dropdown as well
    if (VideoQuality.videoMediaConfigs.find((i) => i.OPMvalue === SS_MEDIA_CONFIG.name) &&
      (!videoConfigs.find((j) => j.name === SS_MEDIA_CONFIG.name))) {
      VideoQuality.videoMediaConfigs = VideoQuality.videoMediaConfigs.filter((i) => (i.OPMvalue !== SS_MEDIA_CONFIG.name));
    }
    console.debug(`VideoQuality::setVideoProfiles() videoMediaConfigs:${JSON.stringify(VideoQuality.videoMediaConfigs)}`);
  }

  update = (opt) => {
    if (this.props?.data) {
      this.props?.data?.setSelectedProfile(opt.OPMvalue);
    } else {
      this.props?.setSelectedProfile(opt.OPMvalue);
    }
  }

  setSelectedQuality = (profileToSelect) => {
    if (!profileToSelect) {
      console.warn('Profile is not defined');
      return null;
    }
    this.setVideoProfiles();
    const selectedProfile = VideoQuality.videoMediaConfigs?.find((profile) =>
      profile?.OPMvalue === profileToSelect?.name);
    console.debug('VideoQuality::setSelectedQuality() selectedProfile:', JSON.stringify(selectedProfile), JSON.stringify(profileToSelect));
    if (selectedProfile) {
      return selectedProfile;
    }
    console.warn('VideoQuality::setSelectedQuality():: Returning to default video profile as selectedProfile is undefined');
    return VideoQuality.videoMediaConfigs[0];
  }

  renderContent = (p) => {
    // flag checks whether its mobile view and not under video source panel to apply CSS and show/hide title accordingly
    const isMobileViewForSettings = (isMobileOnly && !this.props.inSourcePanel);
    return (
      <Row className={`${!this.props.inSourcePanel ? 'px-3' : ''} ${isMobileViewForSettings ? 'landscape-side-margins mt-3' : ''}`}>
        <CustomForm
          formId='videoQuality'
        >
          <Form.Group className={`pb-2 ${this.props.inSourcePanel && 'px-0'}`}>
            <Row>
              {isMobileViewForSettings && (
                <Col>
                  <Form.Label>
                    <span className='p-0 font-regular'>
                      {translate('settings.videoQuality')}
                    </span>
                  </Form.Label>
                </Col>
              )}
              <Col md={!this.props.inSourcePanel ? 6 : 8} lg={!this.props.inSourcePanel ? 6 : 10} sm={!this.props.inSourcePanel ? 6 : 8} xs={!this.props.inSourcePanel ? 6 : 9} className={`pr-1 settings-video-quality ${!isMobileOnly && 'mb-2'}`}>
                <CustomDropdown
                  id='service-type'
                  type='select'
                  className='cursor-pointer dropdn'
                  options={VideoQuality.videoMediaConfigs}
                  selectedOption={this.setSelectedQuality(p)}
                  handleChange={(option) => this.update(option)}
                />
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className={`p-2 ${this.props.inSourcePanel && 'px-0'}`}>
            <Row>
              <Col>
                <Form.Label>
                  <span className='p-0 font-semi'>
                    {translate('settings.resolution')}
                  </span>
                </Form.Label>
              </Col>
              <Col className={`${isMobileViewForSettings && 'align-right'}`}>
                <Form.Label>
                  <span className='p-0 font-semi'>
                    {`${p?.width} X ${p?.height}`}
                  </span>
                </Form.Label>
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className={`p-2 ${this.props.inSourcePanel && 'px-0'}`}>
            <Row>
              <Col>
                <Form.Label>
                  <span className='p-0 font-semi'>
                    {translate('settings.frameRate')}
                  </span>
                </Form.Label>
              </Col>
              <Col className={`${isMobileViewForSettings && 'align-right'}`}>
                <Form.Label>
                  <span className='p-0 font-semi'>
                    {` ${p?.frameRate / UNITS.CONVERSION_VALUE} ${UNITS.FPS} `}
                  </span>
                </Form.Label>
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className={`p-2 ${this.props.inSourcePanel && 'px-0'}`}>
            <Row>
              <Col>
                <Form.Label>
                  <span className='p-0 font-semi'>
                    {translate('settings.videoBitRate')}
                  </span>
                </Form.Label>
              </Col>
              <Col className={`${isMobileViewForSettings && 'align-right'}`}>
                <Form.Label>
                  <span className='p-0 font-semi'>
                    {` ${p?.bitRate / UNITS.CONVERSION_VALUE} ${UNITS.KBPS} `}
                  </span>
                </Form.Label>
              </Col>
            </Row>
          </Form.Group>
        </CustomForm>
      </Row>
    );
  }

  render() {
    const profile = this.props?.data?.selectedVideoProfile
      ? this.props?.data?.selectedVideoProfile
      : this.props?.selectedVideoProfile;
    const isMobileViewForSettings = (isMobileOnly && !this.props.inSourcePanel);
    return (
      <div className={`${!this.props.inSourcePanel && `videoQuality h-100 ${this.props.isCallDashboard ? 'position-absolute' : ''}`}`}>
        {isMobileViewForSettings ?
          /** Mobile view  for video quality under settings */
          (
            <Sidebar>
              <Row lg={8} md={8} sm={8} xs={8} className={`pt-2 px-3 ${isMobileOnly && 'landscape-side-margins'}`}>
                <Col className='d-lg-none d-xl-none' xs={1} sm={1} lg={1} md={1}>
                  <BackIcon
                    className='back-icon'
                    alt='back'
                    role='presentation'
                    onClick={this.props.goBack}
                  />
                </Col>
                <Col className='alignment'>
                  <span className='header-label ps-1'>
                    {translate('settings.videoQuality')}
                  </span>
                </Col>

              </Row>
              <Row className='px-2'>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className='divider' />
                </Col>
              </Row>

              {this.renderContent(profile)}
            </Sidebar>
          ) :
          /** Web view for video quality under settings and web and mobile view for video quality under video source panel */
          (
            <div>
              {this.renderContent(profile)}
            </div>
          )}

      </div>
    );
  }
}
