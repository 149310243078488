import { ACTIONS } from 'UTILS/constants/ActionConstants';
import Utils from 'UTILS/CommonUtility';

export const THEME_STORAGE_KEY = {
  THEME_DEF: 'theme_key',
  BRAND_DATA: 'brand_key',
  LOGO: 'logo_key',
  BRAND_RESOURCE_URL: 'brand_resource_key',
  TRANSLATION: 'translation',
};

export function setThemeAction(theme, colors, themeVersion) {
  if (colors) {
    Utils.localStorage.setItem(`${THEME_STORAGE_KEY.THEME_DEF}_${themeVersion}`, colors);
  }
  return {
    type: ACTIONS.SET_THEME,
    theme,
  };
}

export function setBrandAction(brandName, title) {
  Utils.localStorage.setItem(THEME_STORAGE_KEY.BRAND_DATA, { brandName, title });
  return {
    type: ACTIONS.SET_BRAND,
    brandName,
    title,
  };
}

export function setBrandUrlAction(brandUrl) {
  Utils.localStorage.setItem(THEME_STORAGE_KEY.LOGO, brandUrl);
  return {
    type: ACTIONS.SET_BRAND_URL,
    brandUrl,
  };
}

export function setBrandResourceUrlAction(resourceUrl) {
  Utils.localStorage.setItem(THEME_STORAGE_KEY.BRAND_RESOURCE_URL, resourceUrl);
  return {
    type: ACTIONS.SET_BRAND_RESOURCE_URL,
    resourceUrl,
  };
}
