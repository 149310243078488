/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Row, Col } from 'react-bootstrap';
import { isBrowser, isMobileOnly, isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

// Utility
import Util from 'UTILS/CommonUtility';
import browserAPI from 'UTILS/BrowserAPI';
import WebrtcUtils from 'SERVICES/MediaServerService/WebRtcUtils';

import { isDemoRunning } from 'STORE/ClientSettings/ClientSettingsSelector';
import { isScreenShareOn } from 'STORE/CallControl/CallControlSelector';
// Constant
import { ROUTES } from 'UTILS/constants/RoutingPathConstants';
import { TABLET_WIDTH } from 'UTILS/constants/UtilityConstants';
import { CALL_DASHBOARD } from 'UTILS/constants/DOMElementConstants';
import { ParticipantState } from 'SERVICES/Meetings/MeetingSubscriber';
// Services
import { AppLogger, LOG_NAME } from 'SERVICES/Logging/AppLogger';

// ICONS
import { ReactComponent as CloseIcon } from 'ASSETS/icon_close.svg';

const logger = AppLogger(LOG_NAME.CallDashboard);
const USE_CANVAS_FOR_SCALING = window.dynamicEnv.REACT_APP_SCALE_VIDEO_ENABLE === 'true' && Util.BrowserSupportsCanvasScaling();

// eslint-disable-next-line react/prefer-stateless-function
class CallDashboard extends Component {
  oldCaptions = '';

  captionsSize = 0;

  orientationChange = false;

  // #region LifeCycle Methods

  componentDidMount() {
    logger.debug('CallDashboard', this.props.key, this.props.isDemoRunning);
    if (!this.props.isDemoRunning &&
      !this.props.callInProgress &&
      !this.props.isIncomingCall &&
      !WebrtcUtils.hasTracks(this.props.remoteStream) &&
      !WebrtcUtils.hasTracks(this.props.localStream)
    ) {
      this.props.history.push(ROUTES.DASHBOARD);
    }
    this.isRemoteVideoSet = false;
    this.isLocalVideoSet = false;
    this.isAudioSet = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedVideoProfile !== this.props.selectedVideoProfile) {
      return;
    }
    logger.debug(
      'CallDashboard::',
      'Prev LocalStream:', Util.printableStreamId(prevProps.localStream),
      'Current LocalStream:', Util.printableStreamId(this.props.localStream),
      'Previous RemoteStream:', Util.printableStreamId(prevProps.remoteStream),
      'Current RemoteStream:', Util.printableStreamId(this.props.remoteStream),
      'Active sharer PId:', this.props.activeSharerPId,
    );

    let localUpdated = false;
    let remoteUpdated = false;
    /* If previously local stream not set or not same as current one */
    if (this.props.localStream?.id !== prevProps.localStream?.id) {
      localUpdated = true;
      logger.debug('CallDashboard::Local stream view may need update');
    }
    if ((this.props.remoteStream?.id !== prevProps.remoteStream?.id) ||
      (this.props.activeSharerPId !== prevProps.activeSharerPId)) {
      remoteUpdated = true;
      logger.debug('CallDashboard::Remote stream view may need update');
    }

    const selfVideoEle = browserAPI.getLocalHtmlVideoEle(USE_CANVAS_FOR_SCALING, this.props.screenShareOn);
    if (this.isLocalVideoSet && selfVideoEle?.srcObject === null) {
      // Seen this where the flag and element are out of sync
      // Watch for this log and to be fixed
      logger.warn('CallDashboard::Setting local video to not set');
      this.isLocalVideoSet = false;
    }

    // TODO: Need to confirm the change with @ayan
    if (this.isLocalStream() && (localUpdated || !this.isLocalVideoSet)) {
      browserAPI.attachStreamToMediaElement(
        selfVideoEle,
        this.props.localStream,
      );
      this.isLocalVideoSet = true;
    } else if (!this.isLocalStream()) {
      logger.log('CallDashboard::Stopping stream on canvas');
      this.isLocalVideoSet = false;
    }

    const remoteVideoEle = document.getElementById(CALL_DASHBOARD.REMOTE_VIDEO_ID);
    if (this.isRemoteVideoSet && remoteVideoEle?.srcObject === null) {
      // Seen this where the flag and element are out of sync
      // Watch for this log and to be fixed
      logger.warn('CallDashboard::Setting remote video to unset');
      this.isRemoteVideoSet = false;
    }
    if (this.isRemoteStream() && (remoteUpdated || !this.isRemoteVideoSet)) {
      // @ayan - Why create a new stream?
      const videoStream = new MediaStream();
      videoStream.addTrack(WebrtcUtils.getVideoTrack(this.props.remoteStream));
      browserAPI.attachStreamToMediaElement(
        remoteVideoEle,
        videoStream,
      );
      logger.info('CallDashboard::Remote stream attached to videoElement');
      this.isRemoteVideoSet = true;
    }

    // Remote audio is needed irrespective of who is sharing video stream
    if (this.isRemoteAudioStream() && (remoteUpdated || !this.isAudioSet)) {
      const audioStream = new MediaStream();
      audioStream.addTrack(WebrtcUtils.getAudioTrack(this.props.remoteStream));
      logger.debug('CallDashboard::Play remote audio on non safari');
      browserAPI.attachStreamToMediaElement(
        document.getElementById(CALL_DASHBOARD.REMOTE_AUDIO_ID),
        audioStream,
        true, // isAudio
      );
      this.isAudioSet = true;
    }
    if (this.props.isIncomingCall) {
      this.props.setIncomingCall(false);
    }

    // NLP captions update
    browserAPI.onOrientationChange(() => {
      // Don't repeat the captions update after orientation change
      this.orientationChange = true;
      setTimeout(() => {
        this.orientationChange = false;
      }, 1000);
    });
    if (this.props.captionStr && this.props.captionStr.length > 0) {
      if (!prevProps.isNLPWord && this.orientationChange === false && (prevProps.captionStr !== this.props.captionStr) && this.props.captionsOn === true) {
        if (this.oldCaptions.length > 0 && this.props.captionStr.length > 0) {
          this.oldCaptions += '\n';
          this.captionsSize += 1;
        }
        const oldCaptionsWithNextLine = this.oldCaptions.replace(/\n/g, '');
        if (oldCaptionsWithNextLine && oldCaptionsWithNextLine.length > 0) {
          const captions = oldCaptionsWithNextLine.split('.');
          if (captions && captions.length > 2) {
            this.oldCaptions = captions.slice(-2);
            this.oldCaptions += '\n';
            this.captionsSize = 3;
          }
        }
        this.oldCaptions = this.oldCaptions.concat(prevProps.captionStr);
      }
    }
    // Clear captions when captions switch toggled
    if (this.props.captionsOn !== prevProps.captionsOn) {
      this.oldCaptions = '';
      this.captionsSize = 0;
    }
  }

  // #endregion

  // Clear NLP captions on unmount
  componentWillUnmount() {
    this.oldCaptions = '';
    this.captionsSize = 0;
    browserAPI.removeOrientationChange(() => logger.info('Removing orientationchange listener from calldashboard'));
  }

  /**
   * check if tablet is in landscape mode or other devices are in portrait mode then add width 100% else height 100%
   * to fit to height for landscape and fit to width for portrait
   * @returns image dimensions on various devices
   */
  getImageDimensions = () => {
    logger.debug('CallDashboard::getImageDimensions ',
      `Window Width: ${window.innerWidth} X Height: ${window.innerHeight}`,
      `Portrait: ${!this.props.isPortrait} isBrowser: ${!isBrowser}`,
      `isSidebarPanelOpen:  ${this.props.isSidebarPanelOpenOnMobile}`);
    return (window.innerWidth >= TABLET_WIDTH && !this.props.isPortrait && !isBrowser) || (window.innerHeight > window.innerWidth) ? 'w-100' : 'h-100';
  }

  /**
   *
   * @returns jsx for captured image when video call is going on and user clicks on camera icon
   */
  displayCapturedImage = () => {
    const imageClass = this.getImageDimensions();
    return (
      <div
        id='image_capture'
        className={`${!this.props.imageCaptureData ? 'd-none' : 'align-center'}
                ${window.innerHeight < window.innerWidth ? 'h-100' : !this.props.isSidebarPanelOpenOnMobile ? 'video-image-container' : ''}`
        }
      >
        <Col
          md={12}
          className={`video-image-panel ${!this.props.imageCaptureData ? 'd-none' : window.innerHeight > window.innerWidth ? 'vertical-video' : window.innerWidth >= 1023 && !this.props.isPortrait && !isBrowser ? 'tab-width' : 'horizontal-video'}
                ${this.props.isImageShareMode
            ? 'image-send-mode'
            : this.props.isImageReceiveMode
              ? 'image-receive-mode'
              : ''
            }
            ${this.props.rightSidebarIsOpen ? 'hide-exit-image' : ''}
          `}
        >
          <CloseIcon
            alt='close button'
            className='cursor-pointer exit-image close-icon'
            onClick={() => {
              this.props.closeImage();
            }}
          />
          <img
            className={imageClass}
            id={CALL_DASHBOARD.PHOTO_ID}
            alt='The screen capture
                will appear in this box.'
          />
          <canvas
            className={imageClass}
            id={CALL_DASHBOARD.TELESTRATION_CANVAS_ID_IMG}
          />
        </Col>
      </div>
    );
  }

  /**
   *
   * @returns jsx for displaying video of a logged in  / local user
   */
  displayLocalUserVideo = () => (
    (WebrtcUtils.hasTracks(this.props.localStream) > 0) && this.props.displayLocalStream() && (
      <Row className='w-100 p-0 m-0 video-call-panel'>
        <Col className='p-0 m-0 w-100 align-center h-100'>
          <video
            id={CALL_DASHBOARD.LOCAL_VIDEO_ID}
            autoPlay
            muted
            playsInline
            style={{
              transform: 'scale(0.001)',
              position: 'absolute',
            }}
          />
          <video
            id={CALL_DASHBOARD.SELF_VIDEO_ID}
            className={`${this.props.imageCaptureData ? 'd-none' : ''}
                  ${window.innerWidth >= 1024 && this.props.isPortrait && !this.props.rightSidebarIsOpen // Participants panel close in ipad Portrait.
              ? 'videoAlignCenterOnMobile'
              : isBrowser
                ? 'video-style-on-browser'
                : this.props.isLandscape
                  ? 'video-style-on-landscape'
                  : ''}
                ${this.props.screenShareOn && 'screen-share-border'}                    
                  `
            }
            autoPlay
            muted
            width='100%'
            playsInline
            height='100%'
          />
          {(this.props.displayLocalStream() && !this.props.imageCaptureData && window.dynamicEnv.REACT_APP_ALLOW_TELESTRATION === 'true') && (
            <canvas id={CALL_DASHBOARD.TELESTRATION_CANVAS_ID} />)
          }
        </Col>
      </Row>
    )
  );

  /**
   *
   * @returns jsx for displaying video of a remote user
   */
  displayRemoteUserVideo = () => (
    WebrtcUtils.hasTracks(this.props.remoteStream) > 0 && (
      <Row className={`${this.props.displayRemoteStream() ? 'h-100' : ''}`}>
        <Col className={`p-0  ${this.props.displayRemoteStream() ? 'align-center h-100' : ''}`}>
          {this.props.displayRemoteStream() && (
            <video
              id={CALL_DASHBOARD.REMOTE_VIDEO_ID}
              className={`${this.props.imageCaptureData ? 'd-none' : ''}
                    ${window.innerWidth >= 1024 && this.props.isPortrait && !this.props.rightSidebarIsOpen
                ? `videoAlignCenterOnMobile ${this.props.imageCaptureData ? 'd-none' : ''}`
                : isBrowser
                  ? 'video-style-on-browser'
                  : this.props.isLandscape
                    ? 'video-style-on-landscape'
                    : ''}
                  ${((this.props.isPortrait && this.props.screenShareProps?.startedFromRemote) || (this.props.isPortrait && this.props.rightSidebarIsOpen)) ? 'screen-share-border-remote-mobile' : this.props.screenShareProps?.startedFromRemote ? 'screen-share-border-remote' : ''}    
                    `
              }
              autoPlay
              width='100%'
              playsInline
              height='100%'
            />
          )
          }
          {(this.props.displayRemoteStream() && !this.props.imageCaptureData && window.dynamicEnv.REACT_APP_ALLOW_TELESTRATION === 'true') && (
            <canvas id={CALL_DASHBOARD.TELESTRATION_CANVAS_ID} />)
          }
          <audio
            id={CALL_DASHBOARD.REMOTE_AUDIO_ID}
            autoPlay
          />
        </Col>
      </Row>
    )
  )

  /**
   *
   * @returns jsx for displaying default UI when no user is sharing video
   */
  showDefaultUIForVideoCall = () => {
    const participantsList = this.props.isMeeting ?
      this.props.participants.filter((p) => p.meetingState === ParticipantState.Active) :
      this.props.participants;
    logger.debug('Video tracks length:: Remote::Local', WebrtcUtils.hasTracks(this.props.remoteStream),
      WebrtcUtils.hasTracks(this.props.localStream));

    return (
      this.props.showVideoFallback && (
        <Row className='video-fallback-UI justify-content-center video-call-panel-landscape'>
          {participantsList?.length > 0 && participantsList.map((participant) => (
            <Col key={participant.pId} className={`px-3 align-center avatar-col ${isMobileOnly && 'pt-3'}`}>
              <div>
                <div
                  className='avatar align-center'
                  style={{
                    backgroundColor: participant.colorCode,
                  }}
                >
                  <span className='avatar-text'>
                    <div className='letter-position'>
                      {Util.findFirstChar(
                        participant?.name,
                      )?.substring(0, 2)}
                    </div>
                  </span>
                </div>
                <div className={`avatar-name-text align-center  ${isMobileOnly ? 'pt-2' : 'pt-4'}`}>
                  {participant.name?.split(' ')[0]}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      )
    );
  }

  // The container in which the captions will be displayed
  captionsUI = () => (
    <Row
      className={((window.innerWidth < 1024) && isMobile) ?
        (this.props.isPortrait ? 'captions-container-device' : 'w-50 captions-container-device') :
        'captions-container'}
    >
      <div className={(this.captionsSize >= 2) ? 'b-0 abs-position' : 'abs-position'}>
        {this.oldCaptions.length > 0 && (
          this.oldCaptions && (
            <p className='nlp-line'>
              {this.oldCaptions}
            </p>
          )
        )}
        <p className={this.props.isNLPWord ?
          (this.oldCaptions.length > 0 ? 'nlp-word b-0' : 'nlp-word') :
          (this.oldCaptions.length > 0 ? 'nlp-line b-0' : 'nlp-line')}
        >
          {this.props.captionStr}
        </p>
      </div>
    </Row>
  )

  isLocalStream() {
    return (WebrtcUtils.hasVideo(this.props.localStream) &&
      (USE_CANVAS_FOR_SCALING ? !!document.getElementById(CALL_DASHBOARD.LOCAL_VIDEO_ID) :
        !!document.getElementById(CALL_DASHBOARD.SELF_VIDEO_ID)));
  }

  isRemoteStream() {
    return (WebrtcUtils.hasVideo(this.props.remoteStream) &&
      !!document.getElementById(CALL_DASHBOARD.REMOTE_VIDEO_ID));
  }

  isRemoteAudioStream() {
    return (WebrtcUtils.hasAudio(this.props.remoteStream));
  }

  render() {
    return (
      <>
        {this.props.captionsOn && this.props.nlpUrl && (window.dynamicEnv.REACT_APP_ALLOW_NLP === 'true') && this.captionsUI()}
        {this.props.isDemoRunning && <div className='tooltip-container'><span /></div>}
        {this.displayCapturedImage()}
        {this.displayLocalUserVideo()}
        {this.displayRemoteUserVideo()}
        {this.showDefaultUIForVideoCall()
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  screenShareOn: isScreenShareOn(state),
  isDemoRunning: isDemoRunning(state),
});

export default withRouter(connect(mapStateToProps)(CallDashboard));
