/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';

// Custom component
import TextFieldComponent from 'COMPONENTS/CustomComponents/TextFieldComponent/TextFieldComponent';
import CustomForm from 'COMPONENTS/CustomComponents/CustomForm/CustomForm';
import CustomButton from 'COMPONENTS/CustomComponents/CustomButton/CustomButton';

// Utility
import { translate } from 'SERVICES/i18n';

// Constants
import { SSO_LOGIN } from 'UTILS/constants/DOMElementConstants';
import { LOGIN_OPTIONS } from 'UTILS/constants/LoginConstants';

import './Login.scss';

function SSOLogin({ setErrorModal, toggleSignInOptions, handleSSOLoginAPI, ssoDomain }) {
  const companyDomainRef = React.createRef(null);

  // State variables
  const [validated, setValidated] = useState(false);
  const [companyDomain, setCompanyDomain] = useState(ssoDomain || '');
  const [isLoginDisabled, setLoginDisabled] = useState(true);

  const resetError = () => {
    setValidated(false);
  };

  const checkSignInDisability = () => {
    setLoginDisabled(companyDomain.length === 0);
  };

  React.useEffect(() => {
    resetError();
    checkSignInDisability();
  }, [companyDomain]);

  const handleChange = (event) => {
    setCompanyDomain(event.target.value);
  };

  // This function is used for local validations for company domain
  const validateDomain = () => {
    let isValid = true;
    if (!companyDomainRef.current.checkValidity()) {
      setErrorModal(
        translate('contacts.webAddrErrorMsg'),
        true,
        'login.invalidGuestCredsModalHeader',
      );
      isValid = false;
    }
    return isValid;
  };

  // Validate company domain and call login api in case of valid domain
  const handleSSOLoginClick = (event) => {
    event.preventDefault();
    setValidated(true);
    if (validateDomain()) {
      handleSSOLoginAPI(companyDomain);
    }
  };

  const companyDomainValidator = (companyDomainName) => {
    if (!companyDomainName) {
      return false;
    }
    return true;
  };
  // SSO login UI
  const sSOLoginView = () => (
    <>
      <Col className='pt-3 mt-3'>
        <span className='login-with-sso'>{ translate('ssoLogin.signinWithSSO')}</span>
      </Col>
      <TextFieldComponent
        type='text'
        id='company-domain'
        value={companyDomain}
        placeholder={translate('ssoLogin.enterCompanyDomain')}
        maxLength={100}
        inputRef={companyDomainRef}
        invalid={!companyDomainValidator(companyDomain)}
        handleChange={(event) => handleChange(event)}
        className={`${isMobileOnly ? 'text-mob' : 'text-desktop'} pt-2 mt-3`}
      />
      <Row className='m-2' />
      <Col className={` mt-3 ${isMobileOnly ? 'login-btn-mob' : 'login-btn-desktop'}`} md={12} sm={12}>

        <CustomButton
          className='w-100 rounded-0'
          type='submit'
          text='ssoLogin.loginButton'
          disabled={isLoginDisabled}
        />
      </Col>
      <Col className='pt-3 pb-1'>
        <span
          role='button'
          className='sign-in-options'
          tabIndex={0}
          onClick={
          () => toggleSignInOptions(LOGIN_OPTIONS.USERNAME)
        }
          onKeyDown={() => toggleSignInOptions(LOGIN_OPTIONS.USERNAME)}
        >
          { translate('ssoLogin.signinWithUsername') }
        </span>
      </Col>
    </>
  );

  return (
    <Row>
      <Col className='align-center p-0'>
        <CustomForm
          formId={SSO_LOGIN.FORM_ID}
          onSubmit={handleSSOLoginClick}
          validated={validated}
        >
          <Row className='px-4 py-3'>
            {sSOLoginView()}
          </Row>
        </CustomForm>
      </Col>
    </Row>
  );
}
export default SSOLogin;
