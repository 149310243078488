import React from 'react';
/* eslint-disable react/prop-types */

// Constant
import {
  ROUTES,
} from 'UTILS/constants/RoutingPathConstants';

class LandingPage extends React.Component {
  componentDidMount() {
    // render login page by default
    // eslint-disable-next-line no-unused-expressions
    this.props.history && this.props.history.push(ROUTES.LOGIN);
  }

  render() {
    return <div />;
  }
}

/*
LandingPage.propTypes = {
  history: PropTypes.objectOf,
};

LandingPage.defaultProps = {
  history: {},
};
*/

export default LandingPage;
