/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Row, Col, Form } from 'react-bootstrap';

// Component
import Sidebar from 'COMPONENTS/Dashboard/Sidebar';

// Custom component
import TextFieldComponent from 'COMPONENTS/CustomComponents/TextFieldComponent/TextFieldComponent';
import CustomForm from 'COMPONENTS/CustomComponents/CustomForm/CustomForm';
import CustomButton from 'COMPONENTS/CustomComponents/CustomButton/CustomButton';

// Utility
import CommonUtility from 'UTILS/CommonUtility';

// Translation
import { translate } from 'SERVICES/i18n';

// Constant
import { BUTTON_TYPES } from 'UTILS/constants/UtilityConstants';

// Asset
import { ReactComponent as BackIcon } from 'ASSETS/icon_back_arrow.svg';

// Style
import '../Dashboard.scss';
import '../../../resources/styles/globalStyles.scss';

// eslint-disable-next-line react/prefer-stateless-function
export default class AddContact extends Component {
  constructor(props) {
    super(props);
    this.nameRef = React.createRef(null);
    this.webAddrRef = React.createRef(null);
    this.state = {
      name: '',
      webAddr: '',
      errors: {},
      validated: false,
    };
  }

  setValidated = (value) => {
    this.setState({ validated: value });
  };

  resetError = () => {
    this.setState({ errors: {} });
    this.setValidated(false);
  };

  handleChange = (e, type) => {
    this.setState({
      [type]: e.target.value,
    }, () => { this.resetError(); });
  };

  onAddClick = (e) => {
    e.preventDefault();
    document.documentElement.scrollTop = 0;
    this.setValidated(true);

    if (this.validateInputs()) {
      this.props.onAddClick(this.state.name, this.state.webAddr);
      this.props.onCancelClick();
    }
  };

  // Address field validation to check for the presence of '@' only
  validateInputs = () => {
    let isValid = true;
    if (!CommonUtility.isValidUsername(this.state.webAddr)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          webAddr: translate('contacts.webAddrErrorMsg'),
        },
      }));
      isValid = false;
    }
    return isValid;
  };

  render() {
    return (
      <Sidebar>
        <div className='scroll-div-add contact-title'>
          <Row lg={8} md={8} sm={8} className='m-1 page-header d-flex px-3'>
            <Col className='d-lg-none d-xl-none' xs={1}>
              <BackIcon
                className='back-icon'
                alt='back'
                role='presentation'
                onClick={this.props.onCancelClick}
              />
            </Col>
            <Col className='contacts-header d-flex justify-content-center justify-content-lg-start ps-0 pe-4 p-lg-0'>
              {translate('contacts.addContacts')}
            </Col>
          </Row>

          <div className='divider' />

          {/* <div className='addcontact-scroll'> */}
          <Row className='contact-list mt-3 px-3'>
            <CustomForm
              formId='addContact'
              onSubmit={this.onAddClick}
              validated={this.state.validated}
            >
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Label className='form-label'>
                  {translate('contacts.contactName')}
                </Form.Label>
                <TextFieldComponent
                  type='text'
                  id='name'
                  value={this.state.name}
                  placeholder=''
                  maxLength={100}
                  className='field'
                  inputRef={this.nameRef}
                  handleChange={(event) => this.handleChange(event, 'name')}
                />
              </Form.Group>

              <Form.Group className='mb-3' controlId='formBasicPassword'>
                <Form.Label className='form-label'>
                  {translate('contacts.webRTCAddress')}
                </Form.Label>
                <TextFieldComponent
                  type='text'
                  id='webAddr'
                  value={this.state.webAddr}
                  placeholder=''
                  maxLength={100}
                  className='field'
                  inputRef={this.webAddrRef}
                  invalid={!CommonUtility.isValidUsername(this.state.webAddr)}
                  handleChange={(event) => this.handleChange(event, 'webAddr')}
                  errorMessage={this.state.errors.webAddr}
                />
              </Form.Group>

              <div className='align-items-end-center pb-2'>
                <CustomButton
                  className='cancel-btn mx-2'
                  onClick={this.props.onCancelClick}
                  text='contacts.cancelAddContact'
                  variant={BUTTON_TYPES.SECONDARY}
                />
                <CustomButton
                  type='submit'
                  className='add-btn'
                  text='contacts.addContact'
                  disabled={!this.state.name || !this.state.webAddr}
                />
              </div>
            </CustomForm>
          </Row>
          {/* </div> */}
        </div>
      </Sidebar>
    );
  }
}
