export const AXIOS_CONSTANTS = {
  OPEN_APIS: { '/Session': 'POST' },

  REQUEST_HEADERS: {
    AUTH_TOKEN: 'Authorization',
    CONTENT_TYPE: 'Content-Type',
    CONTENT_TYPE_JSON: 'application/json',
    SESSION_TOKEN: 'Session',
  },
  REQUEST_TIMEOUT: 120000,
};

export const API_RESPONSE_CODE = {
  SUCCESS: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED_USER: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  PAYLOAD_TOO_LARGE: 413,
  BAD_GATEWAY: 502,
};

export const API_FAILURE_CODES = {
  AUTHENTICATION_ERROR: 1,
  ACCESS_DENIED_ERROR: 2,
  SERVER_ERROR: 3,
};

export const OPERATION_STATUS = {
  SUCCESS: 0,
  FAILURE: 1,
  NETWORK_NOT_REACHABLE: 3,
  NOT_FOUND: 4,
  SOMETHING_WENT_WRONG: 5,
};

export const API_URIS = {
  LOGIN: '/Session',
  LOGOUT: '/Session',
  GLOBAL_CONTACT: '/contacts/global/search',
  PERSONAL_CONTACT: '/contacts/personal',
  POLICY: '/client/policy',
  PROFILE: '/profile',
  CHANGE_PASSWORD: '/credentials/password',
  REPORT_STATISTICS: '/calls/statistics',
  REPORT_STATUS: '/client/status',
  WEBRTC_TOKEN: '/credentials/webrtctoken',
  GUEST_INVITE_MEETING: '/users/meetingguest',
  INVITE_GUEST: '/users/guest',
};

export const API_METHODS = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PUT: 'PUT',
};

export const REST_SERVICE = '/OamClientRestService';
