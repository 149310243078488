import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

// I18n
import { translate } from 'SERVICES/i18n';

// Components
import CustomPopover from 'COMPONENTS/CustomComponents/CustomPopover/CustomPopover';
import CustomButton from 'COMPONENTS/CustomComponents/CustomButton/CustomButton';

// Constants
import { BUTTON_TYPES, POPOVER_PLACEMENT } from 'UTILS/constants/UtilityConstants';

// Style
import './MeetingsPopover.scss';

function MeetingsPopover({ isPopoverOpen, togglePopover, cancelMeeting }) {
  const handleClickOutside = () => {
  };

  const displayMeetingsPopover = () => (
    <Row className='popup-text'>
      <Col xs={8}>{translate('meeting.statusWaitingForHost')}</Col>
      <Col xs={2}>
        <CustomButton
          className='align-center action-buttons cancel-button'
          onClick={cancelMeeting}
          text='meeting.dismiss'
          variant={BUTTON_TYPES.SECONDARY}
        />
      </Col>
    </Row>
  );

  return (
    <CustomPopover
      isPopoverOpen={isPopoverOpen}
      togglePopover={togglePopover}
      menuPlacement={POPOVER_PLACEMENT.TOP}
      closeCustomPopover={handleClickOutside}
      title=''
      showHeader={false}
      child={displayMeetingsPopover()}
      target='dashboard-header'
      id='meetings-popover'
      className='meetings-popover'
    />
  );
}

MeetingsPopover.propTypes = {
  isPopoverOpen: PropTypes.bool.isRequired,
  togglePopover: PropTypes.func.isRequired,
  cancelMeeting: PropTypes.func.isRequired,
};

MeetingsPopover.defaultProps = {
};

export default MeetingsPopover;
