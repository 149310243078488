/* eslint-disable default-param-last */
import { isAndroid, isMobile, isFirefox } from 'react-device-detect';
import _ from 'lodash';
import {
  MEDIA_TYPE,
  CAMERA_FACING_MODE,
  SCREEN_SHARE_DEVICE,
  VIDEO_CONTENT_HINT,
  AUDIO_CONTENT_HINT,
  IMAGE_MIME_TYPE,
  CANVAS_MAX_PIXEL_LIMIT,
  IMAGE_CAPTURE_ENCODER_OPTION,
  MAX_CAMERA_RES,
  FILL_LIGHT_MODE,
  DEFAULT_ZOOM_LEVEL,
  GUM_CONSTRAINTS_KEY,
  CALL_ERROR,
  DEVICE_NOT_FOUND,
  WEB_CALL_EVENT,
  IMG_CAP_LANDSCAPE_ASP_RATIO,
  SS_MEDIA_CONFIG,
} from 'UTILS/constants/MediaServerConstants';
import { CALL_DASHBOARD } from 'UTILS/constants/DOMElementConstants';
import { VID_IMG_OVERLAY_TEXT_LOC, VID_IMG_OVERLAY_TEXT_SIZE, GEO_LOCATION_POSITION_ERROR, VIDEO_EVENT } from 'UTILS/constants/UtilityConstants';

// Utility
import Utils from 'UTILS/CommonUtility';
import WebrtcUtils from 'SERVICES/MediaServerService/WebRtcUtils';

// Services
import { AppLogger, LOG_NAME } from 'SERVICES/Logging/AppLogger';

import * as workerTimers from 'worker-timers';
import browserAPI from 'UTILS/BrowserAPI';

const USE_CANVAS_FOR_SCALING = window.dynamicEnv.REACT_APP_SCALE_VIDEO_ENABLE === 'true' && Utils.BrowserSupportsCanvasScaling();
const IMAGE_CAPTURE_IOS_AND_MAC_DELAY_TIMEOUT = 700;
const IMAGE_CAPTURE_DELAY_TIMEOUT = 200;
const IMAGE_CAPTURE_VIDEO_RESIZE_DELAY_TIMEOUT = 1000;
const IMAGE_CAPTURE_FRAMERATE = 30;
const logger = AppLogger(LOG_NAME.MediaHandler);
const VID_IMG_OVERLAY_TXT_CNFG = {
  FONT: 'px Arial',
  FILL_STYLE: 'white',
  STROKE_STYLE: 'black',
  SCALE_FACTOR: {
    MULT_FACTOR: 0.00082,
    ADD_FACTOR: 0.14,
  },
  DIST_BTWN_TEXTS: 30,
  GPS_CNFG: {
    HIGH_ACCURACY: false,
    TIMEOUT_IN_MS: 27000,
    MAX_AGE: 0,
  },
  ALIGNMENT_FOR_RIGHT_SIDE: 'right',
};

/**
 * @class
 */
class MediaHandler {
  /**
   * @constructor
   * @param {object} callbacks Defines the callbacks from handler to the upper layer
   */
  constructor(callbacks) {
    this.callbacks = callbacks;
    this.canvas = {};
    this.webPeerData = {
      pc: null,
      myStream: null,
    };
    this.videoImageOverlays = {
      showTimestamp: false,
      showGps: false,
      gpsCord: null,
      textLocation: VID_IMG_OVERLAY_TEXT_LOC.TOP_RIGHT,
      textSize: VID_IMG_OVERLAY_TEXT_SIZE.LARGE,
    };
    this.callFunctionState = {
      scaleUpCanvasZoomFactor: DEFAULT_ZOOM_LEVEL,
      isTorchForVideoEnabled: false,
      isVideoPaused: false,
    };
    this.streamData = {
      localVideoStream: null,
      videoDeviceId: null,
      screenShareCloseTimer: null,
      sharedVideoMediaType: MEDIA_TYPE.NOT_SET,
    };
    this.locationData = {
      geoLocationData: null,
      gpsCordWatchId: null,
      enabled: false,
    };
  }

  SetState = {
    setPauseVideoState: (state) => {
      this.callFunctionState.isVideoPaused = state;
    },
    setTorchStateForVideo: (state) => {
      this.callFunctionState.isTorchForVideoEnabled = state;
    },
  };

  ClearState = {
    clearPeerConnection: () => {
      this.webPeerData.pc = null;
      this.webPeerData.myStream = null;
    },
    clearLocationData: () => {
      this.locationData.gpsCordWatchId = null;
      this.locationData.geoLocationData = null;
      this.locationData.enabled = false;
    },
    clearOverlayState: () => {
      this.videoImageOverlays.showTimestamp = false;
      this.videoImageOverlays.showGps = false;
      this.videoImageOverlays.gpsCord = null;
      this.videoImageOverlays.textLocation = VID_IMG_OVERLAY_TEXT_LOC.TOP_RIGHT;
      this.videoImageOverlays.textSize = VID_IMG_OVERLAY_TEXT_SIZE.LARGE;
    },
    clearCallFunctionState: () => {
      this.callFunctionState.scaleUpCanvasZoomFactor = DEFAULT_ZOOM_LEVEL;
      this.callFunctionState.isTorchForVideoEnabled = false;
      this.callFunctionState.isVideoPaused = false;
    },
    clearStreamData: () => {
      this.streamData.localVideoStream = null;
      this.streamData.videoDeviceId = null;
      this.streamData.screenShareCloseTimer = null;
      this.streamData.sharedVideoMediaType = MEDIA_TYPE.NOT_SET;
    },
    clearAllState: () => {
      this.ClearState.clearPeerConnection();
      if (this.canvasDrawTimer) {
        try {
          workerTimers.clearInterval(this.canvasDrawTimer);
        } catch (error) {
          logger.warn('Error in clearing canvas worker timer:', error);
        }
        this.canvasDrawTimer = null;
        this.canvas = {};
      }
      this.canvasAnimId = cancelAnimationFrame(this.canvasAnimId);
      this.ClearState.clearOverlayState();
      this.ClearState.clearLocationData();
      this.ClearState.clearCallFunctionState();
      this.ClearState.clearStreamData();
    },
    clearAndResetScreenShareCloseTimer: () => {
      clearTimeout(this.streamData.screenShareCloseTimer);
      this.streamData.screenShareCloseTimer = null;
    },
  };

  Overlay = {
    toggleGpsOverlayVisibility: (state, callback) => {
      this.videoImageOverlays.showGps = state;
      this.Location.startOrStopGettingCurrentPos(callback);
    },
    toggleTimeStampOverlayVisibility: (state) => {
      this.videoImageOverlays.showTimestamp = state;
    },
    drawOverlayOnCanvas: (canvas) => {
      const context = canvas?.getContext('2d');
      if (!context) {
        canvas = null;
        return;
      }

      const scaleFactor = ((VID_IMG_OVERLAY_TXT_CNFG.SCALE_FACTOR.MULT_FACTOR * canvas.width) +
        VID_IMG_OVERLAY_TXT_CNFG.SCALE_FACTOR.ADD_FACTOR).toFixed(2);
      const fontSize = this.videoImageOverlays.textSize.fontSize * scaleFactor;
      let fontPosX = this.videoImageOverlays.textLocation.X * scaleFactor;
      let fontPosY = this.videoImageOverlays.textLocation.Y * scaleFactor;

      this.Overlay.configureCanvasForTextOverlays(context, fontSize);

      const cord = this.videoImageOverlays.gpsCord;
      const currentTimestamp = Utils.getCurrentTimeStamp();

      if (this.videoImageOverlays.textLocation === VID_IMG_OVERLAY_TEXT_LOC.TOP_RIGHT ||
          this.videoImageOverlays.textLocation === VID_IMG_OVERLAY_TEXT_LOC.TOP_LEFT) {
        fontPosX = this.videoImageOverlays.textLocation === VID_IMG_OVERLAY_TEXT_LOC.TOP_RIGHT ?
          canvas.width - fontPosX : fontPosX;

        if (this.videoImageOverlays.showTimestamp) {
          WebrtcUtils.drawTextOverlayOnCanvas(context, currentTimestamp, fontPosX, fontPosY);
        }
        if (this.videoImageOverlays.showGps && cord) {
          WebrtcUtils.drawTextOverlayOnCanvas(context, cord, fontPosX,
            this.videoImageOverlays.showTimestamp ?
              fontPosY + (VID_IMG_OVERLAY_TXT_CNFG.DIST_BTWN_TEXTS * scaleFactor) : fontPosY);
        }
      } else if (this.videoImageOverlays.textLocation === VID_IMG_OVERLAY_TEXT_LOC.BOTTOM_RIGHT ||
        this.videoImageOverlays.textLocation === VID_IMG_OVERLAY_TEXT_LOC.BOTTOM_LEFT) {
        fontPosY = canvas.height - fontPosX;
        fontPosX = this.videoImageOverlays.textLocation === VID_IMG_OVERLAY_TEXT_LOC.BOTTOM_RIGHT ?
          canvas.width - fontPosX : fontPosX;

        if (this.videoImageOverlays.showTimestamp) {
          WebrtcUtils.drawTextOverlayOnCanvas(context, currentTimestamp, fontPosX,
            (this.videoImageOverlays.showGps && cord) ?
              fontPosY - (VID_IMG_OVERLAY_TXT_CNFG.DIST_BTWN_TEXTS * scaleFactor) : fontPosY);
        }
        if (this.videoImageOverlays.showGps && cord) {
          WebrtcUtils.drawTextOverlayOnCanvas(context, cord, fontPosX, fontPosY);
        }
      }
    },
    configureCanvasForTextOverlays: (context, fontSize) => {
      context.font = `${fontSize}${VID_IMG_OVERLAY_TXT_CNFG.FONT}`;
      context.fillStyle = VID_IMG_OVERLAY_TXT_CNFG.FILL_STYLE;
      context.strokeStyle = VID_IMG_OVERLAY_TXT_CNFG.STROKE_STYLE;
      context.lineWidth = this.videoImageOverlays.textSize.lineWidth;
      if (this.videoImageOverlays.textLocation === VID_IMG_OVERLAY_TEXT_LOC.TOP_RIGHT ||
        this.videoImageOverlays.textLocation === VID_IMG_OVERLAY_TEXT_LOC.BOTTOM_RIGHT) {
        context.textAlign = VID_IMG_OVERLAY_TXT_CNFG.ALIGNMENT_FOR_RIGHT_SIDE;
      }
    },
    setVideoImgOverlayTextSize: (textSize) => {
      this.videoImageOverlays.textSize = textSize;
    },
    setVideoImgOverlayTextlocation: (textLocation) => {
      this.videoImageOverlays.textLocation = textLocation;
    },
  };

  PauseLiveVideo = {
    togglePauseVideo: (state) => {
      logger.debug('togglePauseVideo() state:', state);
      try {
        const localVideo = document.getElementById(CALL_DASHBOARD.LOCAL_VIDEO_ID);
        if (state) {
          if (!localVideo.paused) {
            localVideo.pause();
            return;
          }
          const onVideoPlaying = () => {
            setTimeout(() => {
              if (this.callFunctionState.isVideoPaused) {
                localVideo.pause();
              }
              localVideo.removeEventListener(VIDEO_EVENT.PLAYING, onVideoPlaying);
            }, 100);
          };
          localVideo.addEventListener(VIDEO_EVENT.PLAYING, onVideoPlaying);
        } else {
          localVideo.play();
        }
      } catch (error) {
        logger.warn('Error in toggle Pause Video error:', error);
      }
    },
  };

  VideoStream = {
    /**
     * Called to process and display local stream
     * This method modifies stream and sender with
     * track from selected webcam device
     *
     * @param {object} videoMConfig
     * @param {callback} updateCB
     * @returns {void}
     * @throw error in case issue with stream grabbing
     */
    displayStream: async (videoMConfig, selectedVideoDeviceId, updateCB) => {
      logger.info(`displayStream::Starting stream for device: ${Utils.printableId(selectedVideoDeviceId)}`);
      logger.debug(`displayStream::Starting stream display with videoConfig:${JSON.stringify(videoMConfig)}`);
      const rtpVideoSender = this.WebRTCPeer.getRtcRtpSender(MEDIA_TYPE.VIDEO);
      const videoMediaConfig = _.clone(videoMConfig);

      if (!rtpVideoSender) {
        logger.warn('displayStream() no video sender found');
        return;
      }
      // const myStream = WebrtcUtils.getStreamOfPC(this.webPeerData.pc);
      const { myStream } = this.webPeerData;

      const currentTrack = rtpVideoSender?.track;
      logger.debug('displayStream currentTrack', currentTrack);
      if (WebrtcUtils.hasVideo(myStream) &&
      (currentTrack.id !== WebrtcUtils.getVideoTrack(myStream)?.id)) {
      /* Checking if the track ids are matching or else we already have some mismatch */
        logger.assert(currentTrack.id !== myStream.getVideoTracks()[0].id,
          'Track ids not matching! Sender track id:', currentTrack.id,
          'Local stream track', WebrtcUtils.getVideoTrack(myStream)?.id);
      }

      // Need to stop current track before replacing track, if android
      if (isAndroid && currentTrack) {
        WebrtcUtils.removeTracksByType(myStream, MEDIA_TYPE.VIDEO);
        logger.info('Removed track from outbound stream for device',
          currentTrack?.getSettings()?.deviceId);
      }

      // FIXME: Eliminate call data from this function
      const isScreenShare = (selectedVideoDeviceId === SCREEN_SHARE_DEVICE.deviceId) &&
      !!navigator.mediaDevices.getDisplayMedia;

      // Note: If canvas capture is enabled, not doing anything on orientation change
      if (window.orientation === 0 && (!USE_CANVAS_FOR_SCALING ||
      (USE_CANVAS_FOR_SCALING && window.dynamicEnv.REACT_APP_LOCK_VIDEO_LANDSCAPE === 'true'))) {
        logger.info(
          'displayStream setVideoConfigs() this is potrait and we need to alter width height',
        );
        const currentWidth = videoMediaConfig?.width;
        videoMediaConfig.width = videoMediaConfig?.height;
        videoMediaConfig.height = currentWidth;
      }

      const videoConfig = {
        facingMode: !selectedVideoDeviceId && isMobile ?
          { exact: CAMERA_FACING_MODE.ENVIRONMENT } : undefined,
        width: { ideal: videoMediaConfig?.width },
        height: { ideal: videoMediaConfig?.height },
        // eslint-disable-next-line no-unsafe-optional-chaining
        frameRate: { ideal: videoMediaConfig?.frameRate / 1000 },
        deviceId: selectedVideoDeviceId ?? undefined,
      };

      logger.debug(` Getting new stream for device ${Utils.printableId(videoConfig.deviceId)}`);
      let gumStream = null;
      // We are not catching the error thrown in getUserMedia, MediaHandler
      // will do the needful
      if (isScreenShare && this.streamData.localVideoStream && Utils.isSafari()) {
        gumStream = this.streamData.localVideoStream;
      } else {
        gumStream = await WebrtcUtils.getUserMedia({
          isVideo: true,
          isDisplay: isScreenShare,
          videoConfig,
        }, this.callbacks.screenShareEnded);
      }

      if (gumStream === null) {
        logger.error('displaystream Error getting userMediaStream');
        throw (new Error('MediaHandler::Error getting stream'));
      }

      this.MediaConfig.setMaxBitrate(MEDIA_TYPE.VIDEO, videoMediaConfig?.bitRate);

      const newTrack = gumStream?.getVideoTracks()[0];
      logger.info('displaystream: Added video track to outbound stream from device',
        Utils.printableId(newTrack?.getSettings()?.deviceId),
        'track id: ', Utils.printableId(newTrack.id));
      await WebrtcUtils.replaceTrack(rtpVideoSender, newTrack);

      if (!isAndroid) {
        WebrtcUtils.removeTracksByType(myStream, MEDIA_TYPE.VIDEO);
      }

      myStream?.addTrack(newTrack);
      logger.info('displaystream: Added video track to outbound stream from device',
        Utils.printableId(newTrack?.getSettings()?.deviceId),
        'track id: ', Utils.printableId(newTrack.id));
      WebrtcUtils.setTrackContentHint(newTrack, VIDEO_CONTENT_HINT.DETAIL);
      if (isScreenShare && Utils.isSafari()) {
        this.ClearState.clearAndResetScreenShareCloseTimer();
      }
      /* Having a callback since async method can not return */
      if (updateCB) updateCB(gumStream, newTrack?.getSettings()?.deviceId);
    },
    /**
     * Stops the camera stream , and replaces the display with black dummy video
     * @param {object} stream
     */
    stopStreamDisplay: async () => {
      const newTrack = this.Canvas.getBlackDummyVideo()?.getVideoTracks()[0];
      const rtpVideoSender = this.WebRTCPeer.getRtcRtpSender(MEDIA_TYPE.VIDEO);

      // const myStream = WebrtcUtils.getStreamOfPC(this.webPeerData.pc);
      const { myStream } = this.webPeerData;
      WebrtcUtils.removeTracksByType(myStream, MEDIA_TYPE.VIDEO);
      logger.info('stopStreamDisplay:: Appling dummy track to sender', newTrack.id);
      await WebrtcUtils.replaceTrack(rtpVideoSender, newTrack);
      myStream.addTrack(newTrack);
    },
    /**
     * Pauses video temporarily to handle image capture
     * @param {*} videoConfig
     * @category API
     */
    pauseVideo: async (videoConfig) => {
      logger.info('Pausing video');

      if (this.canvasDrawTimer) {
        try {
          workerTimers.clearInterval(this.canvasDrawTimer);
        } catch (error) {
          logger.warn('Error in clearing canvas worker timer:', error);
        }
        this.canvasDrawTimer = null;
      }
      this.canvasAnimId = this.Canvas.cancelAnimationFrame(this.canvasAnimId);
      const videoSender = this.WebRTCPeer.getRtcRtpSender(MEDIA_TYPE.VIDEO);
      const dummyVideoTrack =
      this.Canvas.getBlackDummyVideo(videoConfig.width, videoConfig.height)?.getVideoTracks()[0];
      await videoSender?.replaceTrack(dummyVideoTrack);
    },
    /**
     * Resume video share after image capture complete
     * @param {*} videoConfig
     * @param {*} isScreenShare
     */
    resumeVideo: (videoConfig, isScreenShare, isVideoPaused = false, isUserSharing = true) => {
      try {
        logger.debug(`Resuming video with config: ${JSON.stringify(videoConfig)}`);
        if (!isVideoPaused) {
          if (Utils.isIOS()) {
            this.MediaConfig.setVideoConfigs(
              videoConfig,
              isScreenShare,
              isUserSharing,
            );
          } else {
            const rtpSender = this.WebRTCPeer.getRtcRtpSender(MEDIA_TYPE.VIDEO);

            let currentTrack = rtpSender?.track;
            if (USE_CANVAS_FOR_SCALING) {
              currentTrack = this.streamData.localVideoStream?.getVideoTracks()[0];
            }
            const isPortrait = window.orientation === 0;
            currentTrack?.applyConstraints({
              height: isPortrait ? videoConfig.width : videoConfig.height,
              width: isPortrait ? videoConfig.height : videoConfig.width,
              frameRate: videoConfig.frameRate,
            });
            if (!isScreenShare && this.callFunctionState.isTorchForVideoEnabled) {
              currentTrack?.applyConstraints({
                advanced:
                [
                  {
                    torch: this.callFunctionState.isTorchForVideoEnabled,
                  },
                ],
              });
            }
          }

          this.Canvas.updateCanvas(videoConfig, isVideoPaused);
          const localVideoEle = USE_CANVAS_FOR_SCALING ?
            document.getElementById(CALL_DASHBOARD.LOCAL_VIDEO_ID) :
            document.getElementById(CALL_DASHBOARD.SELF_VIDEO_ID);
          if (localVideoEle) {
            localVideoEle.style.zIndex = 'initial';
            localVideoEle.play();
          }
        }
        if (USE_CANVAS_FOR_SCALING &&
          this.callFunctionState.scaleUpCanvasZoomFactor !== DEFAULT_ZOOM_LEVEL) {
          this.ZoomLiveVideo.zoomCanvas(this.callFunctionState.scaleUpCanvasZoomFactor);
        }
      } catch (error) {
        logger.warn('resumeVideo() error:', error);
      }
    },
    /**
     * We can't trigger getDisplayMedia(to capture stream) based on event on iOS/safari
     * due to strict privacy policy,
     * it should be triggered on actual user gesture,
     * we need to first get the Screen share stream and store on click of SS button
     * and then if token exachange is successful then we can continue with that stored SS stream
     * @returns {void}
     * @throws {error}
     */
    captureAndStoreStreamFromDesktop: async () => {
      if (!Utils.isSafari()) {
        logger.debug('captureAndStoreStreamFromDesktop() only permitted for Safari, returning');
        return;
      }
      try {
        const localVideoStream = await navigator.mediaDevices.getDisplayMedia();
        this.streamData.localVideoStream = localVideoStream;
        this.streamData.localVideoStream.getVideoTracks()[0].onended = () => {
          this.callbacks.appCallback(WEB_CALL_EVENT.SCREEN_SHARE_STOPPED, { cause: 'onerror' });
        };
        this.streamData.screenShareCloseTimer = setTimeout(() => {
          logger.warn('captureAndStoreStreamFromDesktop() SS timed out in handshake, stopping tracks.');
          this.streamData.screenShareCloseTimer = null;
          this.streamData.localVideoStream.getTracks().forEach((track) => {
            track.stop();
          });
        }, 2000);
      } catch (error) {
        logger.warn('captureAndStoreStreamFromDesktop:Failed to get stream from desktop:', error);
        this.callbacks.appNotifyError(CALL_ERROR.SCREEN_SHARE_DECLINED);
        throw error;
      }
    },
    setLocalVideoStream: (stream) => {
      this.streamData.localVideoStream = stream;
    },
  };

  MediaDevice = {
  /**
   * Enforces changes triggered by change of media source
   *
   * @param {enum} mediaType AUDIO | VIDEO | SCREEN
   * @param {*} device new device to be used
   * @param {function} setShowVideoFallbackUI
   * @param {bool} isAudioOutput
   * @returns n/a
   */
    changeDeviceByReplacingTrack: async (mediaType, device,
      setShowVideoFallbackUI = null, isAudioOutput = false) => {
      logger.info(`changeDeviceByReplacingTrack:: ${isAudioOutput ? 'out' : ''}`,
        `mediaType:${mediaType} device:${device.label}`);

      // const myStream = WebrtcUtils.getStreamOfPC(this.webPeerData.pc);
      const { myStream } = this.webPeerData;

      if (!myStream) {
        logger.warn('changeDeviceByReplacingTrack::WebRTC not up yet!');
        return;
      }
      logger.assert(device !== null, 'Device not specified for track change for ', mediaType);

      const isVideo = (mediaType === MEDIA_TYPE.VIDEO) ||
      (mediaType === MEDIA_TYPE.SCREEN);
      const isAudio = mediaType === MEDIA_TYPE.AUDIO;
      const isScreenShare = (mediaType === MEDIA_TYPE.SCREEN) &&
      (device.deviceId === SCREEN_SHARE_DEVICE.deviceId);
      const mediaTrack = WebrtcUtils.getMediaTrack(myStream, mediaType);
      const currentDeviceId = mediaTrack?.getSettings()?.deviceId;

      if (currentDeviceId === device?.deviceId) {
        logger.debug('changeDeviceByReplacingTrack::No change in device');
        return;
      }

      if (isVideo && setShowVideoFallbackUI) {
        logger.info('setting fallback UI');
        setShowVideoFallbackUI(true);
      }

      // stop canvas
      if (isScreenShare && Utils.isSafari()) {
        this.Canvas.cancelAnimationFrame(this.canvasAnimId);
      }

      logger.debug('Video tracks in stream', myStream.getVideoTracks());
      const sender = this.WebRTCPeer.getRtcRtpSender(mediaType);
      let currentTrack = sender?.track;
      if (USE_CANVAS_FOR_SCALING) {
        currentTrack = WebrtcUtils.getMediaTrack(myStream, mediaType);
      }
      logger.debug(`current track for ${currentTrack?.kind} from device ${currentTrack?.label}`);
      // Need to stop current track before replacing track, for Android
      if (isAndroid) {
        if (isAudio && currentTrack) {
          WebrtcUtils.removeTrackFromStream(myStream, currentTrack);
        } else {
          WebrtcUtils.removeTracksByType(myStream, mediaType);
        }
      }

      logger.debug('Getting new stream for device', device.label);
      // Get new stream based on new device and constraints
      // May throw error which will be handled by caller
      const stream = await WebrtcUtils.getUserMedia({
        isVideo,
        isAudio,
        isAudioOutput,
        isDisplay: isScreenShare,
        videoDeviceId: isVideo ? device.deviceId : undefined,
        audioDeviceId: isAudio ? device.deviceId : undefined,
      }, this.callbacks.screenShareEnded);

      if (stream === null) {
      // Failure
        throw new Error('MediaHandler::Error getting stream for media with id', device.deviceId);
      }

      const newTrack = WebrtcUtils.getMediaTrack(stream, mediaType);
      logger.debug(
        'changeDeviceByReplacingTrack::previous track was from:', currentTrack?.label,
        'with that from:', newTrack.label,
      );

      // Confirm weather we could acquire the track from the device requested for audio / video
      if (!isScreenShare && (device.label !== newTrack?.label)) {
        logger.warn(`Could not obtain required device's ${device?.label} media. Got instead from ${newTrack?.label}`);
      }

      // Update in sender
      if (!isVideo || !USE_CANVAS_FOR_SCALING || (Utils.isSafari() && isScreenShare)) {
        logger.info('Replacing track in sender with track from device:',
          Utils.printableId(newTrack.getSettings()?.deviceId));
        await WebrtcUtils.replaceTrack(sender, newTrack);
        WebrtcUtils.setTrackContentHint(newTrack, isScreenShare ? VIDEO_CONTENT_HINT.DETAIL :
          AUDIO_CONTENT_HINT.SPEECH);
        if (Utils.isSafari() && isScreenShare) {
          browserAPI.attachStreamToMediaElement(
            document.getElementById(CALL_DASHBOARD.SELF_VIDEO_ID),
            stream,
          );
        }
      }

      // Update stream
      /* Remove previous tracks from stream, prior to adding;
    for android we cleared earlier */
      if (isVideo) {
        if (!isAndroid) {
          WebrtcUtils.removeTracksByType(myStream, MEDIA_TYPE.VIDEO);
        }
      } else {
        WebrtcUtils.removeTracksByType(myStream, MEDIA_TYPE.AUDIO);
        logger.debug(
          'changeDeviceByReplacingTrack::after tracks replaced:: myStream audioTracks:',
          myStream?.getAudioTracks()
            ?.length,
        );
      }

      logger.debug(`Added ${newTrack?.kind} track by device ${newTrack?.label}`);
      myStream?.addTrack(newTrack);
      logger.debug('Updated stream tracks are:', myStream.getTracks());
      logger.info('changeDeviceByReplacingTrack complete.');
    },
    updateSharedVideoType: (mediaType) => {
      logger.debug('updateSharedVideoType() mediaType:', mediaType);
      this.streamData.sharedVideoMediaType = mediaType;
    },
    getCurrentMediaDeviceId: (mediaType) => {
      // const myStream = WebrtcUtils.getStreamOfPC(this.webPeerData.pc);
      const { myStream } = this.webPeerData;
      const mediaTrack = mediaType === MEDIA_TYPE.VIDEO ?
        myStream?.getVideoTracks()[0] :
        myStream?.getAudioTracks()[0];
      return mediaTrack?.getSettings()?.deviceId;
    },
    setCurrentVideoDeviceId: (deviceId) => {
      this.streamData.videoDeviceId = deviceId;
    },
  };

  WebRTCPeer = {
  /**
   * Gets RTP sender by media type
   * @param {enum} mediaType
   * @returns sender if available for the given media type (audio/video)
   */
    getRtcRtpSender: (mediaType) => {
      console.debug('MediaHandler::getRtpSender() pc:', this.webPeerData.pc);
      if (mediaType === MEDIA_TYPE.SCREEN) {
      // Handling for SCREEN as media type
        return this.webPeerData.pc?.getSenders().find((s) =>
          s?.track && s?.track?.kind === MEDIA_TYPE.VIDEO);
      }
      return this.webPeerData.pc?.getSenders().find((s) =>
        s?.track && s?.track?.kind === mediaType);
    },
    setPeerConnection: (pc) => {
      this.webPeerData.pc = pc;
    },
    setMyStream: (stream) => {
      this.webPeerData.myStream = stream;
    },
  };

  MediaTrack = {
    /**
     * Capturing track from canvas to send to the peer
     * @param {*} canvasEle
     * @param {*} videoConfig
     * @returns none
     */
    replaceVideoTrackWithCanvas: async (canvasEle, isVideoPaused, videoConfig) => {
      logger.info('Replace video track with canvas track on', canvasEle.id, isVideoPaused);
      canvasEle.width = videoConfig.width;
      canvasEle.height = videoConfig.height;
      const fps = parseInt(videoConfig.frameRate, 10) / 1000;

      this.Canvas.startDrawingOnCanvas();

      let canvasStream = null;
      try {
      // Need to call getContext before capture stream on FF
      // https://bugzilla.mozilla.org/show_bug.cgi?id=1572422
        if (isFirefox) {
          canvasEle.getContext('2d');
        }
        canvasStream = canvasEle.captureStream(fps);
      } catch (error) {
        logger.warn('Error in capturing stream from canvas:', error);
        return;
      }
      const selfVideoEle = document.getElementById(CALL_DASHBOARD.SELF_VIDEO_ID);
      if (selfVideoEle) {
        selfVideoEle.srcObject = canvasStream;
      } else {
        logger.warn('Not showing local video as, selfVideo element is not found:', selfVideoEle);
      }
      const videoSender = this.WebRTCPeer.getRtcRtpSender(MEDIA_TYPE.VIDEO);
      const trackReplaced = await WebrtcUtils.replaceTrack(
        videoSender, canvasStream?.getVideoTracks()[0],
      );
      if (trackReplaced) {
        WebrtcUtils.setTrackContentHint(canvasStream?.getVideoTracks()[0],
          VIDEO_CONTENT_HINT.DETAIL);
        logger.info('Sender track replaced with canvas capture track');
      } else {
        logger.warn('Could not replace track with canvas');
      }
    },
  };

  Canvas = {
    getCanvas: (canvasId = null) => {
      if (this.canvas[canvasId]) return this.canvas[canvasId];
      this.canvas[canvasId] = document.createElement('canvas');
      this.canvas[canvasId].id = canvasId;
      logger.info('Canvas \'', canvasId, '\'created');
      return this.canvas[canvasId];
    },
    /**
     * Draws localVideo(selfVideo) into canvas periodically
     * This function helps in case where requested mediaConfig can't be satisfied by the webcam,
     * it will match video res to the selected mediaconfig and sends the canvas capture stream
     * @param {*} animCanvasId
     * @returns none
     */
    startDrawingOnCanvas: () => {
      const selfVideo = document.getElementById(CALL_DASHBOARD.LOCAL_VIDEO_ID);
      const canvas = this.Canvas.getCanvas(CALL_DASHBOARD.CANVAS_FOR_SCALE_UP);
      if (this.canvasDrawTimer) {
        try {
          workerTimers.clearInterval(this.canvasDrawTimer);
        } catch (error) {
          logger.warn('Error in clearing canvas worker timer:', error);
        }
        this.canvasDrawTimer = null;
      }
      this.canvasAnimId = this.Canvas.cancelAnimationFrame(this.canvasAnimId);

      if (!selfVideo) {
        return;
      }

      if (Utils.isSafari() || Utils.isIOS()) {
        try {
          this.Canvas.drawVideoFrameOnScaleUpCanvas(selfVideo);
        } catch (error) {
          logger.warn('Error in drawing on canvas:', error);
        }

        // NOTE: Facing issue(captureStream is not working) with setInterval on Safari/iOS,
        // instead used requestAnimationFrame
        // But there is limitation, if browser is minimized, stream will be paused.
        this.Canvas.requestAnimationFrame();
      } else if (!this.canvasDrawTimer) {
      // NOTE: To get consistency in performance worker-timers has been used
      // over setInterval, to avoid throtling when browser is not in focus/if minimized.
        this.canvasDrawTimer = workerTimers.setInterval(() => {
          try {
            if (selfVideo) {
              this.Canvas.drawVideoFrameOnScaleUpCanvas(selfVideo);
            } else {
              logger.info('Drawing blank screen');
              canvas.getContext('2d').fillRect(0, 0, canvas.width, canvas.height);
            }
          } catch (error) {
            logger.warn('Error in drawing on canvas:', error);
          }
        }, 100);
      }
    },
    requestAnimationFrame: () => {
      if (!this.callFunctionState.isVideoPaused) {
        this.canvasAnimId = requestAnimationFrame(this.Canvas.startDrawingOnCanvas.bind(this));
      }
    },
    cancelAnimationFrame: () => {
      if (this.animCanvasId) {
        logger.info('cancellingAnimationFrame');
        cancelAnimationFrame(this.animCanvasId);
      }
      return null;
    },
    drawVideoFrameOnScaleUpCanvas: (video, canvas = null) => {
      if (!canvas) {
        canvas = this.Canvas.getCanvas(CALL_DASHBOARD.CANVAS_FOR_SCALE_UP);
      }

      try {
        const context = canvas.getContext('2d');
        if (context) {
          context.clearRect(0, 0, canvas.width, canvas.height);
          if (video.readyState === video.HAVE_ENOUGH_DATA) {
            // Draw video frames w.r.t the current zoom level
            const vidX = video.videoWidth *
            ((this.callFunctionState.scaleUpCanvasZoomFactor - 1) /
              (2 * this.callFunctionState.scaleUpCanvasZoomFactor));
            const vidY = video.videoHeight *
            ((this.callFunctionState.scaleUpCanvasZoomFactor - 1) /
              (2 * this.callFunctionState.scaleUpCanvasZoomFactor));
            if (this.streamData.sharedVideoMediaType === MEDIA_TYPE.SCREEN) {
              const { videoWidth } = video;
              const { videoHeight } = video;
              const hRatio = canvas.width / videoWidth;
              const vRatio = canvas.height / videoHeight;
              const ratio = this.streamData.sharedVideoMediaType === MEDIA_TYPE.SCREEN ?
                Math.min(hRatio, vRatio) : Math.max(hRatio, vRatio);
              const centerShiftX = (canvas.width - videoWidth * ratio) / 2;
              const centerShiftY = (canvas.height - videoHeight * ratio) / 2;
              context.drawImage(video, vidX, vidY,
                videoWidth / this.callFunctionState.scaleUpCanvasZoomFactor,
                videoHeight / this.callFunctionState.scaleUpCanvasZoomFactor,
                centerShiftX, centerShiftY,
                videoWidth * ratio, videoHeight * ratio);
            } else {
              context.drawImage(video, vidX, vidY,
                video.videoWidth / this.callFunctionState.scaleUpCanvasZoomFactor,
                video.videoHeight / this.callFunctionState.scaleUpCanvasZoomFactor,
                0, 0, canvas.width, canvas.height);
            }

            this.Overlay.drawOverlayOnCanvas(canvas);
          }
        }
      } catch (error) {
        logger.warn('drawVideoFrameOnScaleUpCanvas() error:', error);
      }
    },
    /**
   * Sets the canvas width height as per selected video config
   * Drawa video frames on scale-up canvas
   * Replaces RtcPeer sender track with the track from canvas CaptureStream
   *
   * @param {*} videoConfig
   * @returns none
   * @category API
   */
    updateCanvas: async (videoConfig, isVideoPaused = false) => {
      logger.info('UpdateCanvas current videoDeviceId:', this.streamData.videoDeviceId);
      if (!USE_CANVAS_FOR_SCALING ||
      ((this.streamData.videoDeviceId === SCREEN_SHARE_DEVICE.deviceId)
        && Utils.isSafari())) {
        logger.debug('UpdateCanvas for screenshare on safari, hence we dont need canvas');
        return;
      }
      // Start screen share and end call
      await this.MediaTrack.replaceVideoTrackWithCanvas(
        this.Canvas.getCanvas(CALL_DASHBOARD.CANVAS_FOR_SCALE_UP), isVideoPaused, videoConfig,
      );
    },
    getStreamWithMicAudioAndFakeVideo: async () => {
      let audioStream;
      try {
        audioStream = await WebrtcUtils.getAudioStream();
      } catch (error) {
        logger.error('getStreamWithMicAudioAndFakeVideo() error in capturing audio:', error);
        if (`${error}`.includes(DEVICE_NOT_FOUND)) {
          this.callbacks.appNotifyError(CALL_ERROR.DEVICE_NOT_FOUND);
        } else {
          this.callbacks.appNotifyError(CALL_ERROR.AUDIO_PERMISSION_DENIED, error);
        }
        throw error;
      }
      const fakeVideoStream = this.Canvas.getBlackDummyVideo();
      return WebrtcUtils.getCompositeStream(audioStream, fakeVideoStream);
    },
    /**
     * Displays a dummy black screen while video sharing is not enabled
     * @param {integer} width
     * @param {integer} height
     * @returns MediaStream containing dummy video with empty frames
     */
    getBlackDummyVideo: (width = 320, height = 240) => {
      logger.log(`getBlackDummyVideo of res: ${width}x${height}`);
      const canvas = Object.assign(this.Canvas.getCanvas(CALL_DASHBOARD.VIDEO_CANVAS_ID),
        { width, height });
      canvas.getContext('2d').fillRect(0, 0, width, height);
      const stream = canvas.captureStream();
      logger.info('Added blank video track on canvas');
      return stream;
    },
  };

  ZoomLiveVideo = {
    zoomCanvas: (zoomfactor) => {
      logger.debug('zoomCanvas() zoomfactor:', zoomfactor);
      if (!USE_CANVAS_FOR_SCALING) {
        return;
      }
      this.callFunctionState.scaleUpCanvasZoomFactor = zoomfactor;
    },
  };

  ImageCapture = {
  // eslint-disable-next-line consistent-return
    captureImage: (
      videoStream,
      torchMode,
      isTorchForVideoEnabled,
      illumInfo,
      isScreenShare = false,
      permissions = {},
      isVideoPaused = false,
      imgMaxHeight = MAX_CAMERA_RES.HEIGHT,
    ) =>
    // eslint-disable-next-line consistent-return
      new Promise((resolve, reject) => {
        let myDate = new Date(Date.now());
        logger.info('Image capture starting: ', myDate.getHours() + ':' + ('0' + (myDate.getMinutes())).slice(-2) + ':' + myDate.getSeconds() + ':' + myDate.getMilliseconds());

        const rtpSender = this.WebRTCPeer.getRtcRtpSender(MEDIA_TYPE.VIDEO);
        let currentTrack = rtpSender?.track;

        // If is video paused, return existing canvas frame
        if (isVideoPaused) {
          const scaleUpCanvasEle = this.Canvas.getCanvas(CALL_DASHBOARD.CANVAS_FOR_SCALE_UP);
          if (!scaleUpCanvasEle) {
            // eslint-disable-next-line no-promise-executor-return
            return reject(new Error('No scale-up/video canvas found'));
          }
          // Following line captures the image in un-zoomed state
          // this.ZoomLiveVideo.zoomCanvas(scaleUpCanvasEle, DEFAULT_ZOOM_LEVEL, true);
          // eslint-disable-next-line no-promise-executor-return
          return this.ImageCapture.getBlobFromCanvas(scaleUpCanvasEle, IMAGE_MIME_TYPE.JPEG)
            .then((blob) => {
              const imageRes = {
                height: currentTrack.getSettings().height,
                width: currentTrack.getSettings().width,
              };
              myDate = new Date(Date.now());
              logger.debug('captureImage() after getImage', myDate.getHours() + ':' + ('0' + (myDate.getMinutes())).slice(-2) + ':' + myDate.getSeconds() + ':' + myDate.getMilliseconds());
              if (!blob) {
                return reject(new Error('captured image is null'));
              }
              logger.debug('captureImage() captureImage imagedata:', blob);
              return resolve({ imageData: blob, imageRes });
            });
        }

        if (USE_CANVAS_FOR_SCALING) {
          currentTrack = videoStream?.getVideoTracks()[0];
        }
        if (imgMaxHeight > MAX_CAMERA_RES.HEIGHT) {
          imgMaxHeight = MAX_CAMERA_RES.HEIGHT;
        }
        logger.debug('captureImage() imgMaxHeight:', imgMaxHeight);

        let height = imgMaxHeight;
        const aspectRatio = window.orientation === 0 ?
          1 / IMG_CAP_LANDSCAPE_ASP_RATIO : IMG_CAP_LANDSCAPE_ASP_RATIO;
        logger.debug('captureImage() aspectRatio:', aspectRatio);

        let width = height * aspectRatio;
        // locking the res in landscape, if the device is in potriat mode
        if (window.orientation === 0 && Utils.isIOS()) {
          width = imgMaxHeight * IMG_CAP_LANDSCAPE_ASP_RATIO;
        } else if (isAndroid) {
          height = imgMaxHeight * IMG_CAP_LANDSCAPE_ASP_RATIO;
          width = imgMaxHeight;
        }
        logger.debug('captureImage:: max res:', `${width}x${height}`);

        const isImageCaptureApiSupported = browserAPI.isImageCaptureSupported();

        // If ImageCapture is supported and User wants to capture image with flash,
        // since because of this bug
        // https://bugs.chromium.org/p/chromium/issues/detail?id=720250
        // sometimes Flash doesn't work,
        //  hence keeping applyConstraints torch to true
        const enableTorch = (isTorchForVideoEnabled && !isImageCaptureApiSupported
        && illumInfo?.videoTorchState) ||
        (isTorchForVideoEnabled && torchMode.flash
          && illumInfo?.videoTorchState && isImageCaptureApiSupported);
        logger.debug('captureImage() Applying constraints to track:',
          `width:${width} height:${height} aspectRatio:${aspectRatio}`);
        currentTrack?.applyConstraints({
          height: { ideal: isScreenShare ? MAX_CAMERA_RES.HEIGHT : height },
          width: { ideal: isScreenShare ? MAX_CAMERA_RES.WIDTH : width },
          frameRate: IMAGE_CAPTURE_FRAMERATE,
        }).then(() => {
          const video = USE_CANVAS_FOR_SCALING ?
            document.getElementById(CALL_DASHBOARD.LOCAL_VIDEO_ID) :
            document.getElementById(CALL_DASHBOARD.SELF_VIDEO_ID);
          const videoTrack = videoStream?.getVideoTracks()[0];
          const { Flash } = permissions;
          const useImageCaptureAPI = (isTorchForVideoEnabled || torchMode?.auto || torchMode?.flash)
            && Flash
            && isImageCaptureApiSupported && !isScreenShare;

          let imageCaptureTimeout;

          const onResize = async (e) => {
            video.removeEventListener(VIDEO_EVENT.RESIZE, onResize);
            clearTimeout(imageCaptureTimeout);
            myDate = new Date(Date.now());
            logger.debug('captureImage() res changed:', myDate.getHours() + ':' + ('0' + (myDate.getMinutes())).slice(-2) + ':' + myDate.getSeconds() + ':' + myDate.getMilliseconds());
            logger.debug('res changed:', e.target.videoWidth, e.target.videoHeight);

            setTimeout(async () => {
              try {
                const imageData = useImageCaptureAPI ?
                  await this.ImageCapture.getImageFromVideoTrack({ width, height },
                    videoTrack, isTorchForVideoEnabled, !enableTorch ? torchMode : undefined) :
                  await this.ImageCapture.getImageFromCanvas(video,
                    isScreenShare ? { width, height } : null);
                myDate = new Date(Date.now());
                logger.debug('captureImage() after getImage', myDate.getHours() + ':' + ('0' + (myDate.getMinutes())).slice(-2) + ':' + myDate.getSeconds() + ':' + myDate.getMilliseconds());
                if (!imageData) {
                  return reject(new Error('captured image is null'));
                }
                logger.debug('captureImage() captureImage imagedata:', imageData);
                return resolve({ imageData: imageData.blob, imageRes: imageData.imgRes });
              } catch (error) {
                logger.warn('Error in captureImage:', error);
                return reject(error);
              }
            // Need some time, allow high res video to be played on selfVideo,
            // otherwise image can be blank
            // Made timeout value as 100 for #2564
            }, (Utils.isIOS() || Utils.isMac()) ?
              IMAGE_CAPTURE_IOS_AND_MAC_DELAY_TIMEOUT : IMAGE_CAPTURE_DELAY_TIMEOUT);
          };

          imageCaptureTimeout = setTimeout(async () => {
            imageCaptureTimeout = null;
            video.removeEventListener(VIDEO_EVENT.RESIZE, onResize);
            logger.warn('captureImage() video resize is not triggered, trying to capture image');
            const imageData = useImageCaptureAPI ?
              await this.ImageCapture.getImageFromVideoTrack({ width, height },
                videoTrack, isTorchForVideoEnabled, !enableTorch ? torchMode : undefined) :
              await this.ImageCapture.getImageFromCanvas(video,
                isScreenShare ? { width, height } : null);
            if (!imageData) {
              return reject(new Error('captured image is null'));
            }
            logger.debug('captureImage() captureImage imagedata:', imageData);
            return resolve({ imageData: imageData.blob, imageRes: imageData.imgRes });
          }, IMAGE_CAPTURE_VIDEO_RESIZE_DELAY_TIMEOUT);

          video.addEventListener(VIDEO_EVENT.RESIZE, onResize);
        }).catch((error) => {
          logger.error('captureImage() failed in apply constraints:', error);
          reject(error);
        });
      }),
    getImageFromCanvas: async (video, imgMaxRes = null) => {
      logger.debug('getImageFromCanvas() video: ', video, imgMaxRes);
      if (!video) {
        return null;
      }

      const imageData = await this.ImageCapture.getBlobFromMedia(
        video, IMAGE_MIME_TYPE.JPEG, imgMaxRes,
      );
      return imageData;
    },
    /**
    * Grabs frame using ImageCapture Web API
    * https://developer.mozilla.org/en-US/docs/Web/API/ImageCapture
    * @param {*} videoTrack
    * @returns Promise resolving a data URL containing a representation of the image
    */
    getImageFromVideoTrack: (videoConfig, videoTrack, isTorchForVideoEnabled, torchMode) =>
      new Promise((resolve, reject) => {
        if (!browserAPI.isImageCaptureSupported()) {
          reject(new Error('ImageCapture API is not supported'));
        }
        if (!videoTrack || videoTrack.readyState === 'ended' || videoTrack.kind !== MEDIA_TYPE.VIDEO) {
          reject(new Error('Issue with video track.'));
        }
        logger.info('getImageFromVideoTrack() Grabing frame torchMode:', torchMode, isTorchForVideoEnabled);
        const imageCapture = new ImageCapture(videoTrack);
        let fillLightMode;
        if (torchMode) {
          if (isTorchForVideoEnabled) {
            fillLightMode = FILL_LIGHT_MODE.FLASH;
          } else {
            const modeValue = Object.keys(torchMode).find((key) => torchMode[key] === true);
            logger.debug('getImageFromVideoTrack() fillLightMode:', modeValue);
            fillLightMode = modeValue;
          }
        }
        imageCapture.takePhoto({
          fillLightMode,
          imageHeight: videoConfig.height,
          imageWidth: videoConfig.width,
        })
          .then(async (blob) => {
            logger.debug('getImageFromVideoTrack() image blob', blob);
            if (!blob) {
              reject(new Error('MediaHandler::getImageFromVideoTrack() image blob is null'));
            }
            this.Canvas.addZoomAndOverlayIntoBlob(blob).then((imageData) => {
              if (!imageData) {
                reject(new Error('MediaHandler::getImageFromVideoTrack() imageData is null'));
              }
              resolve(imageData);
            }).catch((err) => {
              logger.warn('getImageFromVideoTrack() error in addZoomAndOverlayIntoBlob:', err);
              reject(err);
            });
          })
          .catch((error) => {
            logger.warn('getImageFromVideoTrack() error in grabFrame:', error);
            reject(error);
          });
      }),
    getBlobFromCanvas: (canvas, mime) => new Promise((resolve) => {
      canvas.toBlob((blob) => {
        console.log('getBlobFromCanvas() blob:', blob);
        resolve(blob);
      }, mime, Utils.isIOS() ? IMAGE_CAPTURE_ENCODER_OPTION : undefined);
    }),
    /**
    * Draws image from HTMLVideoElement or ImageBitmap on canvas and returns blob
    * @param {*} mediaToBeDrawn
    * @param {*} mime
    * @returns A data URL containing a representation of the image
    */
    getBlobFromMedia: async (mediaToBeDrawn, mime, imgMaxRes = null) => {
      if (!mediaToBeDrawn || !mime) {
        return null;
      }
      const isVideo = mediaToBeDrawn instanceof HTMLVideoElement;
      logger.debug('getBlobFromMedia() mime', mime, ' mediaToBeDrawn ', mediaToBeDrawn, ' isVideo:', isVideo);

      let canvas = document.createElement('canvas');
      logger.debug('getBlobFromMedia() videoWidth:', mediaToBeDrawn.videoWidth, ' videoHeight:', mediaToBeDrawn.videoHeight);
      // We need to limit pixels drawn on canvas because of iOS safari browser limitations.
      if (Utils.isSafari() && Utils.isIOS()) {
        const limitedSize = WebrtcUtils.limitPixelSizeToBeDrawnToCanvas(
          {
            width: isVideo ? mediaToBeDrawn.videoWidth : mediaToBeDrawn.width,
            height: isVideo ? mediaToBeDrawn.videoHeight : mediaToBeDrawn.height,
          },
          CANVAS_MAX_PIXEL_LIMIT,
        );
        canvas.width = limitedSize.width;
        canvas.height = limitedSize.height;
      } else {
        canvas.width = isVideo ? mediaToBeDrawn.videoWidth : mediaToBeDrawn.width;
        canvas.height = isVideo ? mediaToBeDrawn.videoHeight : mediaToBeDrawn.height;
      }
      if (this.streamData.sharedVideoMediaType === MEDIA_TYPE.SCREEN && imgMaxRes) {
        canvas.width = imgMaxRes.width;
        canvas.height = imgMaxRes.height;
      }
      const { height, width } = canvas;
      logger.debug('getBlobFromMedia() canvas height, width: ', canvas.height, canvas.width);
      const context = canvas.getContext('2d');
      let blob = null;
      if (context) {
        try {
          this.Canvas.drawVideoFrameOnScaleUpCanvas(mediaToBeDrawn, canvas);
          // Note: Since Safari doesnt support jpg, and sometimes png hangs the tab.
          blob = await this.ImageCapture.getBlobFromCanvas(
            canvas, mime, canvas.width, canvas.height,
          );
        } catch (error) {
          logger.warn('getBlobFromMedia() Error in drawing image:', error);
          WebrtcUtils.releaseCanvas(canvas);
          canvas = null;
          return null;
        }
        WebrtcUtils.releaseCanvas(canvas);
        canvas = null;
        return { blob, imgRes: { width, height } };
      }
      WebrtcUtils.releaseCanvas(canvas);
      canvas = null;
      return null;
    },
    /**
     * Add zoom and overlay into blob and returns image blob and image dimension
     * @param {Blob} blob to be modified
     * @returns image blob and image dimension
     */
    addZoomAndOverlayIntoBlob: (blob) => new Promise((resolve) => {
      const a = new FileReader();
      a.onload = resolve;
      a.readAsDataURL(blob);
    }).then((e) => new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve(this.ImageCapture.getBlobFromMedia(img, IMAGE_MIME_TYPE.JPEG));
      };
      img.src = e.target.result;
    })),
  };

  Illumination = {
    toggleIllumination: (videoTrack, torch) => {
      try {
        videoTrack.applyConstraints({
          advanced: [
            {
              torch,
            },
          ],
        });
        this.callFunctionState.isTorchForVideoEnabled = torch;
      } catch (err) {
        logger.error('toggleIllumination() Error in applying constrains on track: ', err);
        throw (err);
      }
    },
  };

  MediaConfig = {
    /**
     * @param {*} bitrate
     * @param {*} frameRate
     */
    adjustVideoBitrate: (bitrate, frameRate) => {
      if (!isAndroid || (frameRate <= 0)) {
        // Adjust for android only
        return;
      }

      frameRate /= 1000;
      logger.debug('adjustVideoBitrate to bitrate:', bitrate, 'framerate', frameRate);
      // for some android devices with specific encoders only,
      // it seems that the maxbitrate scales with framerate
      const rtpSender = this.WebRTCPeer.getRtcRtpSender(MEDIA_TYPE.VIDEO);
      const gpuInfo = Utils.getGpuInfo();
      const capabilities = rtpSender?.track?.getCapabilities();
      logger.debug(
        'adjustBitrate::parameters - ',
        bitrate,
        frameRate,
        gpuInfo,
        capabilities,
      );
      if (
        gpuInfo &&
          gpuInfo.vendor?.toLowerCase().includes('qualcomm') &&
          gpuInfo.chipset?.toLowerCase().includes('adreno') &&
          capabilities &&
          capabilities.frameRate.max > frameRate
      ) {
        const factor = capabilities.frameRate.max / frameRate;
        const adjustedBitrate = bitrate * factor;
        rtpSender.getStats().then((stats) => {
          stats.forEach((stat) => {
            if (
              stat.type === 'codec' &&
                (stat.mimeType.toLowerCase().includes('h264') ||
                  stat.mimeType.toLowerCase().includes('vp8'))
            ) {
              // evidence online says that only h264 and vp8 use hardware encoding
              logger.debug(
                `adjustBitrate() adjusting video bitrate from ${bitrate} to ${adjustedBitrate} on with a factor of ${factor}`,
                'MimeType: ', stat.mimetype,
                JSON.stringify(stat),
              );
              this.MediaConfig.setMaxBitrate(
                MEDIA_TYPE.VIDEO,
                adjustedBitrate,
              );
            }
          });
        });
      }
    },
    /**
         * @param {*} mediaType
         * @param {*} bitrate
         * @returns none
         */
    setMaxBitrate: async (mediaType, bitrate) => {
      logger.debug(
        `setMaxBitrate() about to set maxBitrate:${bitrate} for media ${mediaType}`,
      );
      const rtpSender = this.WebRTCPeer.getRtcRtpSender(mediaType);
      if (!rtpSender) {
        logger.warn(
          'setMaxBitrate() no sender found of mediaType:',
          mediaType,
        );
        return;
      }

      let parameters = rtpSender.getParameters();

      if (!parameters) {
        parameters = {};
      }

      if (!parameters.encodings) {
        parameters.encodings = [{}];
      }

      parameters.encodings[0].maxBitrate = bitrate;
      await rtpSender.setParameters(parameters);
      logger.info('Bit rate adjusted to', bitrate, 'for -', mediaType);
    },
    /**
     *
     * @param {MediaConstraint} constraints
     * @param {MEDIA_TYPE} mediaType
     * @param {bool} isScreenShare
     * @returns none
     * @category Media
     */
    applyMediaConstraints: async (constraints, mediaType, isScreenShare = false) => {
      logger.info(
        `applyMediaConstraints() Setting constraints:${JSON.stringify(
          constraints,
        )} for media:${mediaType}`,
      );
      const rtpSender = this.WebRTCPeer.getRtcRtpSender(mediaType);
      if (USE_CANVAS_FOR_SCALING && (!rtpSender || !rtpSender.track)) {
        logger.warn(
          'applyMediaConstraints() no sender found for mediaType:',
          mediaType,
        );
        return;
      }
      logger.debug('applyMediaConstraints() localVideoStream:', this.streamData.localVideoStream);
      // const myStream = WebrtcUtils.getStreamOfPC(this.webPeerData.pc);
      const { myStream } = this.webPeerData;
      let currentTrack = rtpSender?.track;
      if (USE_CANVAS_FOR_SCALING) {
        currentTrack = mediaType === MEDIA_TYPE.VIDEO ?
          this.streamData.localVideoStream?.getVideoTracks()[0] :
          myStream?.getAudioTracks()[0];
      }
      if (!currentTrack) {
        logger.error('Handle this case');
        return;
      }

      const deviceId = currentTrack.getSettings()?.deviceId;
      logger.debug('applyMediaConstraints() to track from deviceId:', deviceId);

      // For iOS we need to use GUM again, otherwise captured image will not have landscape lock,
      // For others, we have called initial GUM with MAX res
      // so that scaling down won't be a problem using appluConstraints
      if (!USE_CANVAS_FOR_SCALING || mediaType === MEDIA_TYPE.AUDIO || isScreenShare
        || (mediaType === MEDIA_TYPE.VIDEO && !Utils.isIOS())) {
        if (isScreenShare && (GUM_CONSTRAINTS_KEY.ADVANCED in constraints)) {
          constraints.advanced = undefined;
        }
        try {
          if (mediaType === MEDIA_TYPE.AUDIO) {
            await currentTrack?.applyConstraints(constraints);
          } else {
            await this.MediaConfig.applyMediaConstraintsOnVideoTrack(
              currentTrack, constraints, isScreenShare,
            );
            if (!isScreenShare && this.callFunctionState.isTorchForVideoEnabled) {
              await currentTrack?.applyConstraints({
                advanced: [
                  {
                    torch: this.callFunctionState.isTorchForVideoEnabled,
                  },
                ],
              });
            }
          }
          WebrtcUtils.setTrackContentHint(
            currentTrack,
            mediaType === MEDIA_TYPE.VIDEO ? VIDEO_CONTENT_HINT.DETAIL :
              AUDIO_CONTENT_HINT.SPEECH,
          );
        } catch (err) {
          logger.warn('The constraints could not be satisfied by the available devices.', err);
        }
        return;
      }

      logger.debug('Removing track from', myStream.getVideoTracks());
      myStream?.getVideoTracks()?.forEach((track) => {
        track?.stop();
        myStream?.removeTrack(track);
      });

      logger.debug('Tracks in local stream', myStream.getVideoTracks());
      constraints.deviceId = deviceId;
      logger.debug(`applyMediaConstraints() constraints:${JSON.stringify(constraints)}`);
      WebrtcUtils.getUserMedia({ isVideo: true,
        isAudio: false,
        isAudioOutput: false,
        isDisplay: false,
        videoDeviceId: deviceId,
        audioDeviceId: null,
        videoConfig: constraints })
        .then(async (stream) => {
          const newTrack = stream.getVideoTracks()[0];
          logger.debug(
            'applyMediaConstraints() newTrack, currentTrack:',
            { newTrack, currentTrack },
          );
          logger.debug('applyMediaConstraints() ActiveDeviceIllumInfo:',
            JSON.stringify(this.callFunctionState.isTorchForVideoEnabled));
          if (this.callFunctionState.isTorchForVideoEnabled) {
            try {
              await newTrack.applyConstraints({
                advanced: [
                  {
                    torch: this.callFunctionState.isTorchForVideoEnabled,
                  },
                ],
              });
            } catch (error) {
              logger.warn('applyMediaConstraints() error in applying torch constraints:', error);
            }
          }

          WebrtcUtils.setTrackContentHint(newTrack, VIDEO_CONTENT_HINT.DETAIL);
          // FIXME: Context - mismatchHack
          logger.debug(`Adding track ${newTrack.id} to mystream`);
          myStream?.addTrack(newTrack);
          this.callbacks.updateLocalStream(isScreenShare);
          const myDate = new Date(Date.now());
          logger.debug('applyconstrainst() done with apply constraints', myDate.getHours() + ':'
            + ('0' + (myDate.getMinutes())).slice(-2) + ':'
            + myDate.getSeconds() + ':' + myDate.getMilliseconds());
        }).catch((err) => {
          logger.warn('applyMediaConstraints() error in gum:', err);
          // What should user get notified ?
          this.callbacks.appNotifyError(CALL_ERROR.GET_USER_MEDIA_API_EXCEPTION);
        });
    },
    /**
     * @param {object} audioMediaConfigs
     * @returns none
     * @category Media
     */
    applyAudioConfigsToStream: (audioMediaConfigs, isUserSharing) => {
      logger.debug(
        `applyAudioConfigsToStream() about to set audio media configs ${JSON.stringify(
          audioMediaConfigs,
        )}`,
      );
      if (!audioMediaConfigs) {
        return;
      }
      this.MediaConfig.setMaxBitrate(MEDIA_TYPE.AUDIO, audioMediaConfigs.bitRate);
      this.MediaConfig.setAudioConfigs(
        audioMediaConfigs.sampleRate,
        audioMediaConfigs.channels,
        isUserSharing,
      );
    },
    /**
     *
     * @param {integer} sampleRate
     * @param {integer} channelCount
     * @returns none
     * @category API
     */
    setAudioConfigs: async (sampleRate, channelCount, isUserSharing) => {
      logger.debug(
        `setAudioConfigs() about to set sampleRate:${sampleRate} and channelCount:${channelCount}`,
      );
      if (
        !channelCount &&
      !sampleRate &&
      sampleRate === 0 &&
      channelCount === 0
      ) {
        logger.warn(
          'setAudioConfigs() invalid media config values',
        );
        return;
      }

      if (isUserSharing) {
        await this.MediaConfig.applyMediaConstraints(
          {
            channelCount: { ideal: channelCount },
            sampleRate: { ideal: sampleRate },
          },
          MEDIA_TYPE.AUDIO,
        );
      }
    },
    /**
     *
     * @param {object} videoConfig
     * @param {bool} isScreenShare
     * @returns None
     * @category Media
     */
    setVideoConfigs: async (videoConfig, isScreenShare, isUserSharing) => {
      logger.debug(`setVideoConfigs() about to set video config ${JSON.stringify(videoConfig)}`,
        `isScreenShare:${isScreenShare}`);
      let width = videoConfig.width && parseInt(videoConfig.width, 10);
      let height = videoConfig.height && parseInt(videoConfig.height, 10);
      const frameRate = videoConfig.frameRate && parseInt(videoConfig.frameRate, 10);
      if (
        !width ||
      width === 0 ||
      !height ||
      height === 0 ||
      !frameRate ||
      frameRate === 0
      ) {
        logger.warn(
          'setVideoConfigs() invalid video width or height or frameRate',
        );
        return;
      }

      // Note: If canvas capture is enabled, not doing anything on orientation change
      if (window.orientation === 0 && (!USE_CANVAS_FOR_SCALING ||
      (USE_CANVAS_FOR_SCALING && window.dynamicEnv.REACT_APP_LOCK_VIDEO_LANDSCAPE === 'true'))) {
        logger.info(
          'setVideoConfigs() this is potrait and we need to alter width height',
        );
        const currentWidth = width;
        width = height;
        height = currentWidth;
      }
      const aspectRatio = width / height;

      logger.info(`setVideoConfigs() orientation:${window.orientation}
      width:${width} height:${height}`);

      if (isUserSharing) {
        await this.MediaConfig.applyMediaConstraints(
          {
            width: { ideal: width },
            height: { ideal: height },
            frameRate: { ideal: frameRate / 1000 },
            aspectRatio: USE_CANVAS_FOR_SCALING ? undefined : aspectRatio,
            advanced: !isScreenShare && this.callFunctionState.isTorchForVideoEnabled ?
              [
                {
                  torch: this.callFunctionState.isTorchForVideoEnabled,
                },
              ] : undefined,
          },
          MEDIA_TYPE.VIDEO,
          isScreenShare,
        );
      }
    },
    /**
     *
     * @param {object} videoMediaConfigs
     * @param {object} isUserSharing
     * @param {bool} isDesktopStream
     * @category Media
     */
    applyVideoConfigsToStream: async (videoConfig, isUserSharing = false,
      isDesktopStream = false, isVideoPaused = false) => {
      this.MediaConfig.setMaxBitrate(
        MEDIA_TYPE.VIDEO,
        videoConfig.bitRate,
      ).then(() => {
        this.MediaConfig.adjustVideoBitrate(
          videoConfig.bitRate,
          videoConfig.frameRate,
        );
      });
      await this.MediaConfig.setVideoConfigs(
        videoConfig,
        isDesktopStream,
        isUserSharing,
      );
      await this.Canvas.updateCanvas(videoConfig, isVideoPaused && isUserSharing);
      if (isUserSharing && this.callFunctionState.scaleUpCanvasZoomFactor !== DEFAULT_ZOOM_LEVEL) {
        this.ZoomLiveVideo.zoomCanvas(this.callFunctionState.scaleUpCanvasZoomFactor);
      }
      if (isUserSharing && isVideoPaused) {
        this.PauseLiveVideo.togglePauseVideo(isVideoPaused);
      }
    },
    /**
     *
     * @param {MediaStreamTrack} video track
     * @param {MediaTrackConstraints} constraints
     * @param {Boolean} isScreenShare
     * @returns None
     * @category Media
     */
    applyMediaConstraintsOnVideoTrack: async (track, constraints, isScreenShare = false) => {
      if (!track || !constraints || track.kind !== MEDIA_TYPE.VIDEO) {
        logger.warn('Not applying media constarints:', constraints, ' on track:', track,
          ' because either some props are empty or track kind is not video');
        return;
      }
      try {
        const browserInfo = Utils.getBrowserVersion();
        if (isScreenShare && browserInfo?.browser !== 'Safari' &&
          constraints?.width?.ideal < SS_MEDIA_CONFIG.width) {
          return;
        }
        let isSafariWithIssueAndSS = browserInfo?.browser === 'Safari' && isScreenShare;
        let version;
        if (isSafariWithIssueAndSS && browserInfo?.version) {
          version = parseFloat(browserInfo?.version);
        }
        // Due to the issue in Safari: https://bugs.webkit.org/show_bug.cgi?id=247310
        // we need to set max instead of ideal for media constraints(width, height)
        // If not set black/green scrren is observed for Share screen from MAC Safari V>=16
        // Also, there is black bar observed for current window sharing at right side,
        // Also mediaconfig changes doesn't work. Need to check when there is a fix and need to
        // put the upper bound for version.
        isSafariWithIssueAndSS = version && version >= 16;
        logger.debug('applyMediaConstraintsOnVideoTrack() constraints:', constraints,
          ' isSafariWithIssueAndSS:', isSafariWithIssueAndSS, ' browserInfo:', browserInfo);

        await track?.applyConstraints(
          {
            height: isSafariWithIssueAndSS ?
              { max: constraints.height.ideal } : constraints.height,
            width: isSafariWithIssueAndSS ?
              { max: constraints.width.ideal } : constraints.width,
            aspectRatio: constraints.aspectRatio,
            frameRate: constraints.frameRate,
          },
        );
      } catch (error) {
        logger.warn('displayStream::Failed to apply constraints on screen share, error:', error);
        throw (error);
      }
    },
  };

  Location = {
    /**
     * @returns GeoLocation Data (lat, long, alt etc)
     */
    getGeoLocationData: () => this.locationData.geoLocationData,
    setLocationState: (state, callback) => {
      this.locationData.enabled = state;
      this.Location.startOrStopGettingCurrentPos(callback);
    },
    startOrStopGettingCurrentPos: (callback) => {
      browserAPI.clearWatchPosition(this.locationData.gpsCordWatchId);
      this.locationData.gpsCordWatchId = null;
      if (!this.videoImageOverlays.showGps && !this.locationData.enabled) {
        return;
      }
      this.locationData.gpsCordWatchId =
        browserAPI.watchPosition((position) => {
          this.locationData.geoLocationData = position;
          if (this.videoImageOverlays.showGps) {
            const latitude = Utils.convertGpsDDtoDms(Math.abs(position.coords.latitude));
            const longitude = Utils.convertGpsDDtoDms(Math.abs(position.coords.longitude));
            const posWRTequator = position.coords.latitude < 0 ? 'S' : 'N';
            const posWRTmeridian = position.coords.longitude < 0 ? 'W' : 'E';
            this.videoImageOverlays.gpsCord = `${latitude} ${posWRTequator} ${longitude} ${posWRTmeridian}`;
          }
          if (typeof callback === 'function') {
            callback(position);
          }
        }, (err) => {
          // logger.warn('Error in getting geo location:', err);
          if (err?.code === GEO_LOCATION_POSITION_ERROR.PERMISSION_DENIED ||
            err?.code === GEO_LOCATION_POSITION_ERROR.POSITION_UNAVAILABLE) {
            this.videoImageOverlays.gpsCord = null;
            this.locationData.geoLocationData = null;
          }
        },
        {
          enableHighAccuracy: VID_IMG_OVERLAY_TXT_CNFG.GPS_CNFG.HIGH_ACCURACY,
          timeout: VID_IMG_OVERLAY_TXT_CNFG.GPS_CNFG.TIMEOUT_IN_MS,
          maximumAge: VID_IMG_OVERLAY_TXT_CNFG.GPS_CNFG.MAX_AGE,
        });
    },
  };
}

export default MediaHandler;
