import axiosHelper from 'SERVICES/AxiosHelper';
import { ACTIONS } from 'UTILS/constants/ActionConstants';
import CommonUtility from 'UTILS/CommonUtility';
import { API_METHODS, API_URIS } from 'UTILS/constants/ApiConstants';
import { X_PATHS } from 'UTILS/constants/UtilityConstants';
import { AppLogger, TraceFlags, LOG_NAME } from 'SERVICES/Logging/AppLogger';

const logger = AppLogger(LOG_NAME.ContactsAPI);

export function searchGlobalContacts(searchText) {
  return (dispatch) => new Promise((resolve, reject) => {
    axiosHelper
      .callAPI({
        httpMethod: API_METHODS.GET,
        uri: API_URIS.GLOBAL_CONTACT,
        requestParams: {
          UniqueId: '',
          NameSearchString: searchText,
          LocationType: 0,
          StartRecord: 0,
        },
      })
      .then((res) => {
        logger.tracer({
          eventType: TraceFlags.TYPE.RSP,
          message: res,
          msgType: TraceFlags.MESSAGE.OPM_API,
        });

        resolve(res);
        const contactsjson = CommonUtility.convertXmlToJSONGeneric(
          res.data.contactsXml, X_PATHS.CONTACTS,
        );
        const contactList = [];
        // Convert object to array of contact objects
        Object.values(contactsjson).forEach((contact) => {
          contactList.push(contact);
        });
        if (contactList && contactList.length > 0) {
          dispatch({
            type: ACTIONS.SET_GLOBAL_CONTACTS,
            globalContacts: contactList,
          });
        }
      })
      .catch((error) => {
        console.error('ContactAction::searchGlobalContacts::Error retrieving global contacts', JSON.stringify(error));
        reject(error);
      });
  });
}

export function fetchPersonalContacts(searchText) {
  return (dispatch) => new Promise((resolve, reject) => {
    axiosHelper
      .callAPI({
        httpMethod: API_METHODS.GET,
        uri: API_URIS.PERSONAL_CONTACT,
        requestParams: {
          ModifiedSinceUtc: 0,
          NameSearchString: searchText || '',
          LocationType: 0,
          StartRecord: 0,
        },
      })
      .then((res) => {
        console.debug('ContactAction::fetchPersonalContacts::Success !! Personal directory contacts retrieved');
        resolve(res);
        const contactsjson = CommonUtility.convertXmlToJSONGeneric(
          res.data.contactsXml, X_PATHS.CONTACTS,
        );
        logger.tracer({
          dirn: TraceFlags.DIRECTION.IN,
          msgType: TraceFlags.MESSAGE.OPM_API,
          type: TraceFlags.TYPE.RSP,
          message: JSON.stringify(contactsjson),
        });
        const contactList = [];
        // Convert object to array of contact objects
        Object.values(contactsjson).forEach((contact) => {
          contactList.push(contact);
        });
        dispatch({
          type: ACTIONS.SET_PERSONAL_CONTACTS,
          personalContacts: contactList,
        });
        // Get the inviter id in case of guest user
        if (res.data.inviterUniqueId) {
          dispatch({
            type: ACTIONS.SET_INVITER_ID,
            inviterUniqueId: res.data.inviterUniqueId,
          });
        }
      })
      .catch((error) => {
        console.error('ContactAction::fetchPersonalContacts::Error retrieving personal contacts', JSON.stringify(error));
        reject(error);
      });
  });
}

export function addSearchedContact(id) {
  return () => new Promise((resolve, reject) => {
    axiosHelper
      .callAPI({
        httpMethod: API_METHODS.POST,
        uri: `${API_URIS.PERSONAL_CONTACT}/${id}`,
        requestBody: {
          uniqueId: id,
        },
      })
      .then((res) => {
        console.debug('ContactAction::addSearhedContact::Success  !!Contact added to Personal directory', JSON.stringify(res));
        this.fetchPersonalContacts(''); // Fetch updated contactlist after adding
        resolve(res);
      })
      .catch((error) => {
        console.error('ContactAction::addSearchedContact::Error adding personal contact', JSON.stringify(error));
        reject(error);
      });
  });
}

export function addNewContact(name, address) {
  return () => new Promise((resolve, reject) => {
    axiosHelper
      .callAPI({
        httpMethod: API_METHODS.POST,
        uri: API_URIS.PERSONAL_CONTACT,
        requestBody: {
          name,
          address,
          addressSecondary: '',
          Type: 5,
        },
      })
      .then((res) => {
        console.debug('ContactAction::addNewContact::Success!! New Contact added to Personal directory', JSON.stringify(res));
        this.fetchPersonalContacts(''); // Fetch updated contactlist after adding
        resolve(res);
      })
      .catch((error) => {
        console.error('ContactAction::addNewContact::Error adding new contact', JSON.stringify(error));
        reject(error);
      });
  });
}

export function deleteContact(id) {
  return () => new Promise((resolve, reject) => {
    axiosHelper
      .callAPI({
        httpMethod: API_METHODS.DELETE,
        uri: API_URIS.PERSONAL_CONTACT,
        requestBody: {
          uniqueId: id,
        },
      })
      .then((res) => {
        console.debug('ContactAction::deleteContact::Success!! Contact deleted', JSON.stringify(res));
        this.fetchPersonalContacts(''); // Fetch updated contactlist after deleting
        resolve(res);
      })
      .catch((error) => {
        console.error('ContactAction::deleteContact::Error deleting contact', JSON.stringify(error));
        reject(error);
      });
  });
}

// Clear global contacts options list once a contact is selected and added
export function clearGlobalContacts() {
  return (dispatch) => new Promise(() => {
    dispatch({
      type: ACTIONS.CLEAR_GLOBAL_CONTACTS,
    });
  });
}

// Invite guest from contacts panel
export function inviteGuestAction(data) {
  return () => new Promise((resolve, reject) => {
    axiosHelper
      .callAPI({
        httpMethod: API_METHODS.POST,
        uri: API_URIS.INVITE_GUEST,
        requestBody: data,
      })
      .then((res) => {
        console.debug('ContactAction::inviteGuest::Success!! Guest user invitation is sent!', JSON.stringify(res));
        resolve(res);
      })
      .catch((error) => {
        console.error('ContactAction::inviteGuest::Error sending guest invitation:', JSON.stringify(error));
        reject(error);
      });
  });
}
