// eslint-disable-next-line func-names

import { themeColors } from 'STYLES/Branding/themeColors';

const transparent = 'transparent';
const white = '#ffffff';

// NOTE: Always update this version whenever a new parameter is added in the below theme
export const themeVersion = 0.1;

function getTheme(colors) {
  const safeWrap = (themeProp) => colors[themeProp] ?? themeColors[themeProp];

  const theme = {
    headerFooter: {
      background: safeWrap('headerFooterColor'),
    },
    iconBtn: {
      background: safeWrap('iconHoverColor'),
    },
    activeIcon: {
      background: safeWrap('iconActiveColor'),
    },
    button: {
      background: safeWrap('buttonColor'),
      color: safeWrap('buttonTextColor') ?? white,
    },
    secondaryButton: {
      background: safeWrap('buttonTransparentColor') ?? transparent,
      color: safeWrap('buttonTextColor') ?? white,
    },
    border: {
      borderColor: safeWrap('borderColor'),
      sidebarBorderTop: safeWrap('sidebarBorderTop'),
      dividerBorderColor: safeWrap('dividerBorderColor'),
    },
    sidebar: {
      background: safeWrap('sidebarColor'),
    },
    textbox: {
      background: safeWrap('textboxColor'),
      textColor: safeWrap('inputAutoFillTextColor'),
    },
    customTextbox: {
      background: safeWrap('customTextFieldColor'),
    },
    dropdown: {
      background: safeWrap('dropdownColor'),
      hoverColor: safeWrap('dropdownHover'),
    },
    loginLayout: {
      background: safeWrap('loginLayoutColor'),
    },
    modal: {
      background: safeWrap('modalLayoutColor'),
      boxShadow: safeWrap('modalBoxShadow'),
    },
    arrowColor: {
      background: safeWrap('arrowColor'),
    },
    addContactTextField: {
      background: safeWrap('addContactTextField'),
    },
    tabFocusColor: {
      background: safeWrap('tabFocusColor'),
    },
    toastBackgroundColor: {
      background: safeWrap('toastBackgroundColor'),
    },
    toastBorderColor: {
      color: safeWrap('toastBorderColor'),
    },
    settingsTextField: {
      background: safeWrap('settingsTextFieldColor'),
    },
    telestration: {
      background: safeWrap('telestrationPopoverColor'),
      headerBackground: safeWrap('telestrationHeaderColor'),
    },
    radioButton: {
      backgroundColor: safeWrap('radioButtonColor'),
      borderColor: safeWrap('radioButtonColor'),
    },
  };
  return theme;
}

export default getTheme;
