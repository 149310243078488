/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { Col } from 'react-bootstrap';

// Style
import './CallDashboard.scss';

export default class Sidebar extends PureComponent {
  render() {
    return (
      <Col lg={3} sm={12} className={`m-0 ${this.props.className}`}>
        {this.props.children}
      </Col>
    );
  }
}
