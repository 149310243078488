/* eslint-disable import/order */
import axios from 'axios';

// Component
import { getErrorInfo, getAPIErrorInfo } from './errorLookup';

// Utility
import { translate } from 'SERVICES/i18n';
import { AppLogger, TraceFlags, LOG_NAME } from 'SERVICES/Logging/AppLogger';
// Constant
import {
  AXIOS_CONSTANTS,
  // API_RESPONSE_CODE,
  OPERATION_STATUS,
  REST_SERVICE,
  API_FAILURE_CODES,
  API_METHODS,
  API_URIS,
} from 'UTILS/constants/ApiConstants';
import { LOCAL_STORAGE_KEY } from 'UTILS/constants/DOMElementConstants';

// Selector
import { getAuthToken, getLoggedInUserName } from 'STORE/Auth/AuthSelector';
import { getWebAppState } from 'STORE/CallControl/CallControlSelector';
import getStore from 'STORE/Store';

import { ACTIONS } from 'UTILS/constants/ActionConstants';

const logger = AppLogger(LOG_NAME.OPMAPI);

class AxiosHelper {
  static singleInstance = null;

  static sessionExpiredTimeout = null;

  static getInstance() {
    if (!AxiosHelper.singleInstance) {
      AxiosHelper.singleInstance = new AxiosHelper();
    }
    return AxiosHelper.singleInstance;
  }
  /**
   *
   * @param {*} httpRequest: {
   *    baseUrl: <SERVER_BASE_URL>,
   *    uri: <uri for eg. /api/login>;
   *    httpMethod: <GET/POST etc.>,
   *    requestBody: <reqBody to post>,
   *    requestParams: <reqParam in GET call>,
   *    requestHeaders: <custom req header>
   * }
   */

  callAPI(httpRequest) {
    let { requestHeaders } = httpRequest;
    if (!requestHeaders) {
      requestHeaders = {};
      httpRequest.requestHeaders = requestHeaders;
    }
    // add auth token in the header
    if (
      !(
        AXIOS_CONSTANTS.OPEN_APIS[httpRequest.uri] &&
        AXIOS_CONSTANTS.OPEN_APIS[httpRequest.uri] === httpRequest.httpMethod
      )
    ) {
      const { store } = getStore();
      const authToken = getAuthToken(
        store.getState(),
      );
      requestHeaders[AXIOS_CONSTANTS.REQUEST_HEADERS.AUTH_TOKEN] =
        `${AXIOS_CONSTANTS.REQUEST_HEADERS.SESSION_TOKEN} ${authToken}`;
    }

    // add content type as application/json if not specified
    if (!requestHeaders[AXIOS_CONSTANTS.REQUEST_HEADERS.CONTENT_TYPE]) {
      requestHeaders[AXIOS_CONSTANTS.REQUEST_HEADERS.CONTENT_TYPE] =
        AXIOS_CONSTANTS.REQUEST_HEADERS.CONTENT_TYPE_JSON;
    }

    // use default server url if not specified in the httpRequest
    httpRequest.baseUrl = httpRequest.baseUrl
      ? httpRequest.baseUrl
      : `${window.dynamicEnv.REACT_APP_SERVER_BASE_URL}${REST_SERVICE}`;

    return this.callActualAPI(httpRequest);
  }

  callActualAPI(httpRequest) {
    logger.tracer({
      type: TraceFlags.TYPE.REQ,
      headers: httpRequest,
      message: httpRequest.body,
      msgType: TraceFlags.MESSAGE.OPM_API,
      dirn: TraceFlags.DIRECTION.OUT,
    });
    clearTimeout(AxiosHelper.sessionExpiredTimeout);
    AxiosHelper.sessionExpiredTimeout = undefined;
    // eslint-disable-next-line no-alert
    return new Promise((resolve, reject) => {
      axios({
        method: httpRequest.httpMethod,
        baseURL: httpRequest.baseUrl,
        url: httpRequest.uri,
        headers: httpRequest.requestHeaders,
        data: httpRequest.requestBody,
        params: httpRequest.requestParams,
        // Resolve only if the status code is 200
        // validateStatus: (status) => status === API_RESPONSE_CODE.SUCCESS,
        timeout: AXIOS_CONSTANTS.REQUEST_TIMEOUT, // currently it's 2 sec
        withCredentials: false,
      })
        // eslint-disable-next-line no-unused-vars
        .then((successResponse) => {
          resolve(successResponse);
          this.triggerFlagModification();
          logger.tracer({
            type: TraceFlags.TYPE.RSP,
            message: successResponse.data ? JSON.stringify(successResponse.data) : undefined,
            msgType: TraceFlags.MESSAGE.OPM_API,
            dirn: TraceFlags.DIRECTION.IN,
          });
        })
        .catch((error) => {
          logger.error(
            `Error Calling api ${httpRequest.uri}: ${JSON.stringify(error)}`,
          );
          const { store } = getStore();
          const webAppState = getWebAppState(store.getState());
          if (error.response && error.response.data &&
            error.response.data.AuthReasonCode === API_FAILURE_CODES.AUTHENTICATION_ERROR &&
            !webAppState.callInProgress) {
            store.dispatch({
              type: ACTIONS.SET_SESSION_EXPIRED_FLAG,
              isSessionExpired: true,
            });
            return;
          }
          const failure = this.handleFailure(error);
          reject(failure);
        });
    });
  }

  handleFailure = (error) => {
    logger.log('ERROR Response', JSON.stringify(error), error.response);

    if (!error.response) {
      if (((typeof error === 'string') &&
        (error.contains('Network Error'))) ||
        ((typeof error === 'object') &&
          (error.message === 'Network Error'))) {
        return {
          status: OPERATION_STATUS.NETWORK_NOT_REACHABLE,
          data: error?.response?.data,
          error: getErrorInfo(OPERATION_STATUS.NETWORK_NOT_REACHABLE),
        }; // not adding else as returning object eslint firing error
      }
      return {
        status: OPERATION_STATUS.FAILURE,
        data: error?.response?.data,
        error: getErrorInfo(OPERATION_STATUS.SOMETHING_WENT_WRONG),
      }; // not adding else as returning object eslint firing error
    }
    if (error.response?.data?.errors) {
      const apiError = error.response.data.errors;
      if (!apiError.message) {
        apiError.message = translate('errors.someThingWentWrong');
      }

      return {
        status: OPERATION_STATUS.FAILURE,
        data: error?.response?.data,
        error: {
          ...apiError,
          isServerError:
            error.response.status.toString().startsWith('5') &&
            error.response.status.toString() !== '500',
        },
      };
    }
    return {
      status: OPERATION_STATUS.FAILURE,
      data: error?.response?.data,
      error: {
        ...getAPIErrorInfo(error.response.status, error.response.data),
        isServerError:
          error.response.status.toString().startsWith('5') &&
          error.response.status.toString() !== '500',
      },
    };
  };

  logoutUser() {
    const { store } = getStore();
    const username = getLoggedInUserName(store.getState());
    return this.callAPI({
      httpMethod: API_METHODS.DELETE,
      uri: API_URIS.LOGOUT,
      requestBody: {},
    }).then((res) => {
      logger.debug('Explicit logout on session expiration');
      logger.tracer({
        type: TraceFlags.TYPE.RSP,
        message: res.body,
        msgType: TraceFlags.MESSAGE.OPM_API,
        dirn: TraceFlags.DIRECTION.IN,
      });
      if (username) {
        localStorage.removeItem(`${username}${LOCAL_STORAGE_KEY.IV}}`);
        localStorage.removeItem(username);
      }
      // eslint-disable-next-line no-use-before-define
      clearAxiosSessionExpiryTimer();
      store.dispatch({
        type: ACTIONS.SET_SESSION_EXPIRED_FLAG,
        isSessionExpired: false,
      });
      store.dispatch({
        type: ACTIONS.CLEAR_STORE,
      });
    }).catch((error) => {
      logger.debug('Error while explicit logout on session expiration', JSON.stringify(error));
      // eslint-disable-next-line no-use-before-define
      clearAxiosSessionExpiryTimer();
      store.dispatch({
        type: ACTIONS.CLEAR_STORE,
      });
    });
  }

  triggerFlagModification = () => {
    const { store } = getStore();
    // Add extra check for session_expiry_minutes so that timer will update properly
    if (AxiosHelper.sessionExpiredTimeout === undefined &&
      store.getState().ClientSettingsReducer.clientSessionConfigInfo?.session_expiry_minutes) {
      AxiosHelper.sessionExpiredTimeout = setTimeout(() => {
        store.dispatch({
          type: ACTIONS.SET_SESSION_EXPIRED_FLAG,
          isSessionExpired: true,
        });
      }, parseInt(
        store.getState().ClientSettingsReducer.clientSessionConfigInfo.session_expiry_minutes,
        10,
      ) * 60000);
    }
  };
}

const axiosHelper = AxiosHelper.getInstance();

export default axiosHelper;

export function clearAxiosSessionExpiryTimer() {
  clearTimeout(AxiosHelper.sessionExpiredTimeout);
  AxiosHelper.sessionExpiredTimeout = undefined;
}
