import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Select, { components, createFilter } from 'react-select';

// Translation
import { translate } from 'SERVICES/i18n';

// Asset
import { ReactComponent as DownArrowIcon } from 'ASSETS/icon_chevron_down.svg';

// Style
import './CustomSearchableDropdown.scss';

const { Input } = components;

class CustomSearchableDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputText: '',
    };

    this.filterConfig = {
      ignoreCase: true,
      matchFrom: 'start',
    };
  }

  customDownArrow = () => (<DownArrowIcon id='down_arrow' className='down-arrow-icon' />)

  inputBox = (props) => <Input {...props} title={props.selectProps.inputValue} isHidden={false} />;

  handleInputChange = (inputText, action) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      this.setState({ inputText });
      const selectedOption = inputText ? {
        label: inputText,
        value: inputText,
      } : {};
      this.props.onInputChange(selectedOption);
    }
  };

  handleChange = (event) => {
    this.props.onChange(event);
    this.setState({
      inputText: event.label,
    });
  };

  render() {
    return (
      <div className='custom-searchable-dropdown'>
        <Select
          components={{
            Input: this.inputBox,
            IndicatorSeparator: () => null,
            DropdownIndicator: this.customDownArrow,
          }}
          isSearchable
          id={this.props.id}
          tabSelectsValue={false}
          hideSelectedOptions={false}
          options={this.props.options}
          backspaceRemovesValue={false}
          onChange={this.handleChange}
          isDisabled={this.props.disabled}
          autoFocus={this.props.autoFocus}
          value={this.props.selectedOption}
          inputValue={this.state.inputText}
          onInputChange={this.handleInputChange}
          menuPlacement={this.props.menuPlacement}
          classNamePrefix='custom-searchable-dropdown'
          filterOption={createFilter(this.filterConfig)}
          placeholder={translate(this.props.placeholder)}
          noOptionsMessage={() => translate('noResultsFound')}
          controlShouldRenderValue={!isEmpty(this.props.selectedOption)}
        />
      </div>
    );
  }
}

CustomSearchableDropdown.propTypes = {
  id: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onInputChange: PropTypes.func,
  placeholder: PropTypes.string,
  menuPlacement: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  selectedOption: PropTypes.object,
};

CustomSearchableDropdown.defaultProps = {
  id: '',
  options: [],
  disabled: false,
  autoFocus: false,
  selectedOption: {},
  onChange: () => null,
  placeholder: 'select',
  menuPlacement: 'bottom',
  onInputChange: () => null,
};

export default CustomSearchableDropdown;
