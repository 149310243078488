export const getAuthToken = (state) => state.AuthReducer.authToken;
export const getLoggedInUserName = (state) => state.AuthReducer.loggedInUserName;
export const getNetworkConnectionFlag = (state) => state.AuthReducer.isNetworkLost;
export const getIsSessionExpiredFlag = (state) => state.AuthReducer.isSessionExpired;
export const getLoginRequestTimestamp = (state) => state.AuthReducer.loginRequestTimestamp;
export const getCustomerInfo = (state) => ({
  customerUniqueId: state.AuthReducer.customerUniqueId,
  customerName: state.AuthReducer.customerName,
});
export const getWebRtcToken = (state) => state.AuthReducer.webRtcToken;
export const getLaunchParameters = (state) => state.AuthReducer.launchParameters;
export const getCustomMessageData = (state) => state.AuthReducer.customMessageData;
