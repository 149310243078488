/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
import { isAndroid } from 'react-device-detect';
import { AppLogger, LOG_LEVEL, LOG_NAME } from 'SERVICES/Logging/AppLogger';
import { v4 as uuidv4 } from 'uuid';
import { MEDIA_QUALITY } from 'UTILS/constants/MediaServerConstants';
import {
  PADDING_CHARSET,
  SALT_HEX_STRING,
} from './constants/LoginConstants';
import { AES, KEY_CONSTANT, X_PATHS } from './constants/UtilityConstants';

const MAX_TRUNCATE_LEN = 12;
/* eslint-disable-next-line prefer-regex-literals */
const emailRegEx = new RegExp('[^@]+@[^@]+\\.[^@]{1,}');
/* eslint-disable-next-line prefer-regex-literals */
const phoneRegEx = new RegExp('[^a-zA-Z]{2,}$');
const timeRegEx = /^[0-9\b]+$/;
// Avoiding detailed logging generally from utility functions,
// log in callers if must
const logger = AppLogger(LOG_NAME.CommonUtils, LOG_LEVEL.INFO);

export default class CommonUtility {
  static isValidUsername(userName) {
    return !((!userName) || (!userName.length > 0) || (!userName.includes('@')));
  }

  // Guest email address validator
  // Only check for presence of @ and . at proper positions
  static isValidEmail(address) {
    return !(!emailRegEx.test(address));
  }

  // Guest phone number validator(Letters not allowed, length > 1)
  static isValidPhone(phone) {
    return !(!phoneRegEx.test(phone));
  }

  // Guest expiry time validator(allow only integers/digits)
  static isValidTime(time) {
    return !(!timeRegEx.test(time));
  }

  static getUserNameFromContacts(sipAddress, contactList) {
    if (contactList && contactList.length > 0) {
      let isContactExist = null;
      // eslint-disable-next-line array-callback-return
      contactList?.map((user) => {
        if (user?.address === sipAddress) {
          isContactExist = user;
        }
      });
      if (isContactExist) {
        return isContactExist.name;
      }
    }
    return sipAddress;
  }

  /**
 * @param {} callId
 * @param {} callHistoryRecords
 * @returns Will return if a call record if exist in call history
 */
  static isCallHistoryRecordPresentForCallId(callId, callHistoryRecords) {
    if (callHistoryRecords && callHistoryRecords.length > 0) {
      const isRecordExist = callHistoryRecords.find((record) => record.id === callId);
      return isRecordExist;
    }
    return false;
  }

  //  This utility method will parse the XML into JSON.
  static convertXmlToJson(xmlString) {
    const xmlDoc = new DOMParser().parseFromString(xmlString, 'text/xml');
    if (xmlDoc.hasChildNodes()) {
      const firstChild = xmlDoc.childNodes.item(0);
      const json = {
        [firstChild.nodeName]: this.xmlToJson(firstChild),
      };
      return json;
    }
    return {};
  }

  // This method will convert XML to JSON into attributes and its contents as a value.
  static xmlToJson(xml) {
    const json = {
      attributes: {},
      contents: {},
    };
    if (xml.attributes) {
      const numberOfAttributes = xml.attributes.length;
      if (numberOfAttributes > 0) {
        for (let i = 0; i < numberOfAttributes; i += 1) {
          const attribute = xml.attributes.item(i);
          json.attributes[attribute.nodeName] = attribute.nodeValue;
        }
      }
    }
    if (xml.nodeType === 3) {
      json.contents = xml.nodeValue;
    }
    if (
      xml.hasChildNodes() &&
      xml.childNodes.length === 1 &&
      xml.childNodes[0].nodeType === 3
    ) {
      json.contents = xml.childNodes[0].nodeValue;
    } else if (xml.hasChildNodes()) {
      const numberOfChilds = xml.childNodes.length;
      for (let i = 0; i < numberOfChilds; i += 1) {
        const child = xml.childNodes.item(i);
        if (child.nodeType === 3) {
          json.contents = xml.nodeValue;
        } else if (json.contents[child.nodeName] instanceof Array) {
          json.contents[child.nodeName].push(this.xmlToJson(child));
        } else {
          const keys = Object.keys(json.contents);
          if (keys.includes(child.nodeName)) {
            json.contents[child.nodeName] = [json.contents[child.nodeName]];
            json.contents[child.nodeName].push(this.xmlToJson(child));
          } else {
            json.contents[child.nodeName] = this.xmlToJson(child);
          }
        }
      }
    }
    return json;
  }

  static convertXmlToJSONGeneric(xmlString, xpath) {
    const json = {};
    const xmlDoc = new DOMParser().parseFromString(xmlString, 'text/xml');
    const nodes = xmlDoc.evaluate(
      xpath,
      xmlDoc,
      null,
      XPathResult.ANY_TYPE,
      null,
    );
    let result = nodes.iterateNext();
    let at = 0;
    while (result) {
      if (result.nodeType === 1 && result.childNodes?.length < 2) {
        json[result.nodeName] = result.textContent;
      } else {
        if (xpath === X_PATHS.CLIENT_PERMISSIONS) {
          json[at] = parseInt(result.textContent, 10);
        } else {
          json[at] = this.processNode(result);
        }
        at += 1;
      }
      result = nodes.iterateNext();
    }
    return json;
  }

  static convertToClientPermissions(clientPolicyXml, permissionDef) {
    // Get the nested permissionsXml path
    const clientPermissionsXml = this.convertXmlToJSONGeneric(
      this.cleanXML(clientPolicyXml),
      X_PATHS.CLIENT_POLICY_PERMISSIONS_XML,
    );
    const clientPermissions = this.convertXmlToJSONGeneric(
      clientPermissionsXml?.permissions,
      X_PATHS.CLIENT_PERMISSIONS,
    );
    return this.getClientPermissions(clientPermissions, permissionDef);
  }

  /**
   *
   * @param {array} permissionArray actual array
   * @param {array} permissionDef the definition of array
   * @returns object containing whether permissions are enabled/ disabled
   */
  static getClientPermissions(permissionArray, permissionDef) {
    const result = Object.values(permissionArray);

    const permissionObj = {};
    permissionDef.forEach((flagDef) => {
      const found = !result.includes(flagDef.id);
      permissionObj[flagDef.name] = found;
    });

    return permissionObj;
  }

  static getDefaultProfileIndex(videoProfiles) {
    // return first built-in profile index
    for (let sqIndex = 0; sqIndex < MEDIA_QUALITY.length; sqIndex += 1) {
      if (MEDIA_QUALITY[sqIndex] !== 'SQCustom') {
        const index = videoProfiles.findIndex((profile) =>
          profile.streamQuality === sqIndex);
        if (index >= 0) return index;
      }
    }

    // return first custom profile index
    return videoProfiles.findIndex((profile) => profile.StreamQuality === 'SQCustom');
  }

  static processNode(result) {
    const json = {};
    if (result.attributes) {
      const numberOfAttributes = result.attributes.length;
      if (numberOfAttributes > 0) {
        for (let i = 0; i < numberOfAttributes; i += 1) {
          const attribute = result.attributes.item(i);
          json[attribute.nodeName] = attribute.nodeValue;
        }
      }
    }
    if (
      result.hasChildNodes() &&
      result.childNodes.length === 1 &&
      result.childNodes[0].nodeType === 3) {
      json[result.nodeName] = result.childNodes[0].nodeValue;
    } else if (result.hasChildNodes()) {
      const numberOfChilds = result?.childNodes.length;
      for (let i = 0; i < numberOfChilds; i += 1) {
        const child = result.childNodes.item(i);
        if (child.nodeType === 3) {
          json[result.nodeName] = child?.nodeValue;
        } else if (json[child.nodeName] instanceof Array) {
          json[child.nodeName].push(this.processNode(child));
        } else {
          const keys = Object.keys(json);
          if (keys.includes(child.nodeName)) {
            json[child.nodeName] = [json[child.nodeName]];
            json[child.nodeName].push(this.processNode(child));
          } else {
            json[child.nodeName] = Object.keys(this.processNode(child)).length > 0 ? this.processNode(child) : '';
            json[child.nodeName] = json[child?.nodeName][child?.nodeName]
              ? json[child.nodeName][child.nodeName]
              : json[child.nodeName];
          }
        }
      }
    }
    return json;
  }

  static cleanXML(xml) {
    if (xml) {
      const xmlString = xml.toString();
      let cleanedForWhiteSpaces = '';
      let cleanedForNewLine = '';
      let cleanedXML = '';

      const isContainWhiteSpaces = /\s/.test(xmlString);
      const isContainNewLine = /\n/.test(xmlString);
      const isContainSpaces = /\t/.test(xmlString);

      if (isContainNewLine) {
        cleanedXML = xmlString.replace(/\n/g, '');
      }
      if (isContainSpaces || (cleanedXML && cleanedXML.length > 0)) {
        const xmlStringToclean = cleanedXML ?
          cleanedXML.toString() : xmlString;
        cleanedForNewLine = xmlStringToclean.replace(/\t/g, '');
        cleanedXML = cleanedForNewLine;
      }
      if (isContainWhiteSpaces || (cleanedXML && cleanedXML.length > 0)) {
        const xmlStringToclean = cleanedXML ? cleanedXML.toString() : xmlString;
        cleanedForWhiteSpaces = xmlStringToclean.trim();
        cleanedXML = cleanedForWhiteSpaces;
      }
      return cleanedXML;
    }
    return undefined;
  }

  static disableBrowserNavigation() {
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      window.history.forward();
    };
  }

  static enableBrowserNavigation() {
    if (typeof window.onpopstate === 'function') {
      window.onpopstate = null;
    }
  }

  static findFirstChar(name) {
    let shortName = '';
    shortName = name?.charAt(0);
    name?.split('').forEach((c, index) => {
      if (c === ' ') {
      /* eslint-disable-next-line no-unsafe-optional-chaining */
        shortName += name?.charAt(index + 1); // shortName.concat(name.charAt(index + 1));
      }
    });
    return shortName?.toUpperCase();
  }

  static getUserFullName(userData, contacts) {
    let userAdress = '';
    if (userData?.startsWith('sip:')) {
      userAdress = userData?.replace('sip:', '');
    }
    const user = contacts.find((contact) => contact.address === userAdress);
    return user ? user.name : undefined;
  }

  static getUserNameWithDomainFromUri(sipUri, truncate = true) {
    const userAddress = sipUri?.startsWith('sip:') ? sipUri.replace('sip:', '') : sipUri;
    if (truncate === false) return userAddress;
    return `${userAddress?.split('@', 2)[0]}@${userAddress?.split('@')[1].split('.')[0]}`;
  }

  static setCookie(cName, cValue, expDays) {
    const date = new Date();
    date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + date.toUTCString();
    document.cookie = cName + '=' + cValue + '; ' + expires + '; path=/';
  }

  static getCookie(cName) {
    const name = cName + '=';
    const cDecoded = decodeURIComponent(document.cookie); // to be careful
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res;
  }

  /**
   * @param  {} password <String>
   */

  static getKeyMaterial = async (password) => {
    const enc = new TextEncoder();
    const keyMaterial = await window.crypto.subtle.importKey(
      KEY_CONSTANT.type.raw,
      enc.encode(password),
      KEY_CONSTANT.algorithm.PBKDF2,
      false,
      [KEY_CONSTANT.purpose.deriveBits, KEY_CONSTANT.purpose.deriveKey],
    );
    return keyMaterial;
  };

  /**
   * @param  {} password <String>
   */

  static getEncryptionKeyFromPassword = async (password) => {
    let passwordForKey = password;
    if (password.length < 16) {
      passwordForKey = this.addPaddingToPassword(password);
    }
    if (password.length > 16) {
      passwordForKey = password.substring(0, 15);
    }
    const result = await this.getKeyMaterial(passwordForKey);
    return result;
  };

  /**
   * @param  {} password <String>
   */

  static getActualEncryptionDecryptionKey = async (password) => {
    const encryptionKeyFromPassword = await this.getEncryptionKeyFromPassword(
      password,
    );
    const encKey = await window.crypto.subtle.deriveKey(
      {
        name: KEY_CONSTANT.algorithm.PBKDF2,
        salt: this.stringToArrayBuffer(SALT_HEX_STRING),
        iterations: 100000,
        hash: KEY_CONSTANT.hash.sha_256,
      },
      encryptionKeyFromPassword,
      { name: AES.GCM, length: 256 },
      true,
      [KEY_CONSTANT.purpose.encrypt, KEY_CONSTANT.purpose.decrypt],
    );
    return encKey;
  };

  /**
   * @param  {} password <String>
   * @param  {} plaintext <String>
   * @param  {} iv <Unit8Array>
   */

  static encrypt = async (password, plaintext, iv) => {
    const encKey = await this.getActualEncryptionDecryptionKey(password);
    const encryptedData = await window.crypto.subtle.encrypt(
      {
        name: AES.GCM,
        iv,
      },
      encKey,
      this.stringToArrayBuffer(plaintext),
    );
    return encryptedData;
  };

  /**
   * @param  {} password <String>
   * @param  {} plaintext <String>
   * @param  {} iv <Unit8Array>
   */

  static decrypt = async (password, plaintext, iv) => {
    const encKey = await this.getActualEncryptionDecryptionKey(password);
    const decryptedData = await window.crypto.subtle.decrypt(
      {
        name: AES.GCM,
        iv: new Uint8Array(iv.split(',').map((entry) => Number(entry))),
      },
      encKey,
      this.stringToArrayBuffer(plaintext),
    );
    return decryptedData;
  };

  /**
   * @param  {} str <String>
   */

  static stringToArrayBuffer(str) {
    const buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
    const bufView = new Uint16Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i += 1) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }

  /**
   * @param  {} buf <ArrayBuffer>
   */

  static arrayBufferToString(buf) {
    return String.fromCharCode.apply(null, new Uint16Array(buf));
  }

  /**
   * @param  {} password <String>
   */

  static addPaddingToPassword(password) {
    const paddingCharsLength = (16 - password.length) / 2;
    for (let index = 0; index < paddingCharsLength; index += 1) {
      const element = PADDING_CHARSET[index];
      password = `${element}${password}${element}`;
    }
    return password;
  }

  static #gpuInfo;

  static getGpuInfo() {
    if (!this.#gpuInfo) {
      this.#gpuInfo = {
        vendor: 'Unknown',
        chipset: 'Unknown',
      };

      const gl = document.createElement('canvas').getContext('webgl');
      if (gl) {
        const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');

        if (debugInfo) {
          this.#gpuInfo = {
            vendor: gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL),
            chipset: gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL),
          };
        }
      }
    }
    return this.#gpuInfo;
  }

  static isIOS() {
    return (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
      !window.MSStream;
  }

  /* Maps id to name mainly for descriptive logging purposes */
  static mapName(obj, val) {
    const idx = Object.values(obj).indexOf(val);
    if (idx >= 0) {
      return Object.keys(obj)[idx];
    }
    // Return the value back in case fails to map
    return val;
  }

  static isSafari() {
    return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') === -1 &&
      navigator.userAgent.indexOf('FxiOS') === -1;
  }

  static isIosAndSafari() {
    return /iP(ad|hone|od).+Version\/[\d\\.]+.*Safari/i.test(navigator.userAgent);
  }

  /**
   * Generate a random number for use as 'id'
   * @param {number} max Indicates the range max
   * @returns [number]
   */
  static getRandomId(max = 1000) {
    return Math.floor((Math.random() * max) + 1);
  }

  /**
   * Returns stream id in a printable form, suitable for logger.log display
   */
  static printableStreamId(stream) {
    return (stream?.id?.length > MAX_TRUNCATE_LEN) ?
    // eslint-disable-next-line no-unsafe-optional-chaining
      stream?.id?.substr(0, MAX_TRUNCATE_LEN) + '...' : stream?.id;
  }

  /**
   * Returns id in a printable form, suitable for logger.log display
   */
  static printableId(id) {
    return (id?.length > MAX_TRUNCATE_LEN) ?
      id.substr(0, MAX_TRUNCATE_LEN) + '...' : id;
  }

  /**
   * Returns boolean based on if OS is android and chromium engine version is of same
   */
  static isAndroidChromeVersion(ver) {
    return isAndroid && Boolean(navigator.userAgentData?.brands.find(({ brand, version }) =>
      (brand === 'Chromium' || brand === 'Microsoft Edge')
      && parseFloat(version, 10) === ver));
  }

  static BrowserSupportsCanvasScaling() {
    if (this.isAndroidChromeVersion(104) ||
      this.isAndroidChromeVersion(105) ||
      this.isAndroidChromeVersion(106) || this.isIOSOlderThan(15)) {
      // see #2140 and https://bugs.chromium.org/p/chromium/issues/detail?id=1359303
      return false;
    }
    return true;
  }

  // Manages 'state' attribute to the object and corresponding setters
  // for each value
  // TODO: Make 'state' attribute dynamic
  static initStateHandlers(obj, states, stateChangeHandler = null) {
    // eslint-disable-next-line
    Object.keys(states).forEach(function (key, index) {
      let handler = 'set_' + key.toUpperCase();
      obj[handler] = () => {
        const prevState = obj.state;
        obj.state = states[key];
        if (typeof stateChangeHandler === 'function') stateChangeHandler(prevState);
      };
      handler = 'check_' + key.toUpperCase();
      obj[handler] = () => obj.state === states[key];
    });
  }

  /**
   * Converts the image to binary data
   * @param {image} img
   * @returns base64 encoded image data
   */
  static getBase64Image(img) {
    const canvas = document.createElement('canvas_for_img');
    const ctx = canvas.getContext('2d');
    // Set width and height
    canvas.width = img.width;
    canvas.height = img.height;
    // Draw the image
    ctx.drawImage(img, 0, 0);
    const dataUrl = canvas.toDataURL('image/png');
    return dataUrl.replace(/^data:image\/(png|jpg);base64,/, '');
  }

  static getImageData(imgUrl, errorCB = null) {
    const img = new Image();
    img.src = imgUrl;
    // Required for getting asset from branding website
    img.setAttribute('crossorigin', 'anonymous');
    // eslint-disable-next-line func-names
    img.onload = () => {
      this.getBase64Image(img);
    };
    img.onerror = (err) => {
      if (errorCB) errorCB(imgUrl, err);
      logger.warn('Error loading from', imgUrl, err);
    };
  }

  /**
   * Utility functions to set/get local storage data by
   * key
   * TODO: Always pass data in string format while setting key in local storage
   */
  static localStorage = {
    getItem: (key, defaultVal = null) => {
      let data = null;
      try {
        const keyVal = localStorage.getItem(key);
        data = keyVal ? JSON.parse(keyVal) : null;
      } catch (err) {
        logger.warn('Error getting Key:', key, 'from storage : ', err);
      }
      return data ?? defaultVal;
    },
    setItem: (key, data) => {
      try {
        localStorage.setItem(key, JSON.stringify(data));
      } catch (err) {
        logger.warn('Error writing Key:', key, 'to storage : ', err);
      }
    },
  }

  static isIOSOlderThan(version) {
    const agent = window.navigator.userAgent;
    const start = agent.indexOf('OS ');
    let iOSVersionDetected;
    let isOlder = false;
    if (this.isIOS() && start > -1) {
      iOSVersionDetected = window.Number(agent.substring(start + 3, start + 6).replace('_', '.'));
      isOlder = iOSVersionDetected < version;
      logger.debug(`iOS version detected is  ${iOSVersionDetected} `);
    }
    return isOlder;
  }

  static isMac() {
    return navigator.userAgent.match(/Mac OS/i);
  }

  /**
   * Performs an action after the specified delay.
   * Typically used for handling actions out of the calling event loop.
   *
   * @param {INT} timeout Timeout in mseconds
   * @param {function} action Callback being called on timeout
   * @returns {null}
   */
  static initiateDelayedAction(timeout, action) {
    if (!action) return; // Error
    setTimeout(() => {
      logger.info('DELAY::Calling action');
      action();
    }, timeout);
  }

  /**
   * Binds the method to object
   * @param {object} obj Object to bind the given method to
   * @param {string} methodName Name of method
   * @returns {function}
   */
  static bindMethod(obj, methodName) {
    const method = obj[methodName];
    if (typeof method.bind === 'function') {
      return method.bind(obj);
    }
    try {
      return Function.prototype.bind.call(method, obj);
    } catch (e) {
      // Missing bind shim or IE8 + Modernizr, fallback to wrapping
      // eslint-disable-next-line prefer-rest-params
      return Function.prototype.apply.apply(method, [obj, arguments]);
    }
  }

  static convertGpsDDtoDms(value) {
    if (!value) return null;
    let decimalPart = value % 1;
    const degree = `${value - decimalPart}°`;
    value = decimalPart * 60;
    decimalPart = value % 1;
    const minutes = `${value - decimalPart}'`;
    const seconds = `${(decimalPart * 60).toFixed(2)}"`;
    return (degree + minutes + seconds);
  }

  /**
 * Current timestamp in format YYYY/MM/DD HH:MM:SS
 * @returns current timestamp
 */
  static getCurrentTimeStamp = () => {
    const dt = new Date();
    return dt.getFullYear() + '/' + ('00' + (dt.getMonth() + 1)).slice(-2) + '/' + ('00' + dt.getDate()).slice(-2) + ' ' +
      ('00' + dt.getHours()).slice(-2) + ':' + ('00' + dt.getMinutes()).slice(-2) + ':' + ('00' + dt.getSeconds()).slice(-2);
  }

  // Checks if the process flag from env is set as 'true'
  static isEnvFlagSet = (flag) => window.dynamicEnv[flag] === 'true'
  /**
   * To get the unique universal id.
   * @returns the unique id
   */

  static getUniqueUniversalID() {
    return uuidv4();
  }

  static getBrowserVersion() {
    const { userAgent } = navigator;
    const findVersion = (browserIdStr) => `${userAgent.split(' ').find((row) => row.includes(browserIdStr))?.split(browserIdStr)[1]}`;
    if (userAgent.includes('Firefox/')) {
      return { browser: 'Firefox', version: findVersion('Firefox/') };
    }
    if (userAgent.includes('Edg/')) {
      return { browser: 'Edge', version: findVersion('Edg/') };
    }
    if (userAgent.includes('Chrome/')) {
      return { browser: 'Chrome', version: findVersion('Chrome/') };
    }
    if (userAgent.includes('Safari/')) {
      return { browser: 'Safari', version: findVersion('Version/') };
    }
    return null;
  }

  static isiPhoneWithiOS14InLandscape() {
    return (navigator.userAgent.indexOf('iPhone OS 14') !== -1 && window.innerWidth > window.innerHeight);
  }
}
