// eslint-disable-next-line import/prefer-default-export
export const LAUNCH_PARAMETER_QUERY_STRING = {
  USERNAME: 'u',
  PASSWORD: 'p',
  MEETING_ID: 'id',
  SSO_TOKEN: 'tk',
  SSO_USERNAME: 'u',
  SSO_LOGIN_RESULT: 'ssost',
  SSO_REGISTER_RESULT: 'ssoexst',
};

export const LAUNCH_PARAMETER_ACTION = {
  LAUNCH: 'launch',
  MEETING: 'meeting',
  SSO: 'sso',
};
