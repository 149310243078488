import axiosHelper from 'SERVICES/AxiosHelper';
import { ACTIONS } from 'UTILS/constants/ActionConstants';
import CommonUtility from 'UTILS/CommonUtility';
import { X_PATHS } from 'UTILS/constants/UtilityConstants';
import { setClientPolicyAction } from 'STORE/CommonStoreActions';
import {
  API_METHODS,
  API_URIS,
} from '../../utils/constants/ApiConstants';

// eslint-disable-next-line import/prefer-default-export
export function getClientPolicyAction(lastModified) {
  return (dispatch) => new Promise((resolve, reject) => {
    axiosHelper
      .callAPI({
        httpMethod: API_METHODS.GET,
        uri: API_URIS.POLICY,
        requestParams: {
          ModifiedSinceUtc: lastModified || 0, // Send reqtimestamputc from prev res
        },
      })
      .then((res) => {
        console.debug('ClientSettingsAction::getClientPolicy::Success  !!', res);
        console.info('ClientSettingsAction::getClientPolicy::Success  !!');
        resolve(res);

        // Save the latest client policy request timestamp
        dispatch({
          type: ACTIONS.SET_CLIENT_POLICY_REQUEST_TIMESTAMP,
          clientPolicyRequestTimestamp: res.data.RequestTimestampUtc,
        });

        // video profiles
        const videoProfilesXml = CommonUtility.convertXmlToJSONGeneric(
          res.data.clientPolicyXml,
          X_PATHS.CLIENT_POLICY_VIDEO_PROFILES,
        );
        const profiles = CommonUtility.convertXmlToJSONGeneric(
          CommonUtility.cleanXML(videoProfilesXml.profiles),
          X_PATHS.VIDEO_PROFILES,
        );
        const videoProfilesArray = [];
        // Convert object to array of contact objects
        if (profiles) {
          Object.values(profiles).forEach((videoProfiles) => {
            videoProfilesArray.push(videoProfiles);
          });
        }

        if (res.data?.clientPolicyXml && videoProfilesArray) {
          dispatch({
            type: ACTIONS.SET_VIDEO_MEDIA_CONFIG,
            videoProfileData: videoProfilesArray,
          });
        }
        setClientPolicyAction(res, dispatch);
      })
      .catch((error) => {
        console.error('ClientSettingsAction::getClientPolicy::Error  !!', error);
        reject(error);
      });
  });
}

export function getProfileAction(lastModified) {
  return (dispatch) => new Promise((resolve, reject) => {
    axiosHelper
      .callAPI({
        httpMethod: API_METHODS.GET,
        uri: API_URIS.PROFILE,
        requestParams: {
          ModifiedSinceUtc: lastModified || 0, // Send reqtimestamputc from prev res
        },
      })
      .then((res) => {
        console.debug('ClientSettingsAction::getProfileAction::Success  !!', res);
        console.info('ClientSettingsAction::getProfileAction::Success  !!');
        resolve(res);

        // Save the latest Profile request timestamp
        dispatch({
          type: ACTIONS.SET_PROFILE_REQUEST_TIMESTAMP,
          profileRequestTimestamp: res.data.RequestTimestampUtc,
        });

        // Convert userXml to JSON format
        const userProfileJSON = CommonUtility.convertXmlToJSONGeneric(
          res.data.userXml,
          X_PATHS.USER_XML,
        );

        console.debug('ClientSettingsAction::getProfileAction::userProfileJSON::', userProfileJSON);

        // If xml is null, there is no update, else save the updated info
        if (res.data.userXml && userProfileJSON) {
          dispatch({
            type: ACTIONS.SET_USER_NAME,
            loggedInUserName: userProfileJSON.username,
          });
          dispatch({
            type: ACTIONS.SET_USER_XML_DATA,
            userData: userProfileJSON,
          });
        }
      })
      .catch((error) => {
        console.error('ClientSettingsAction::getProfileAction::Error  !!', error);
        reject(error);
      });
  });
}

export function setSelectedVideoProfileAction(selectedProfile) {
  // FIXME: Can be removed once issue with profile drop down identified
  if (selectedProfile === null) {
    console.error('Null profile');
  }
  return (dispatch) => new Promise(() => {
    dispatch({
      type: ACTIONS.SET_SELECTED_VIDEO_PROFILE,
      selectedVideoProfile: selectedProfile,
    });
  });
}

export function setVideoMediaConfigsAction(videoConfigs) {
  return (dispatch) => new Promise(() => {
    dispatch({
      type: ACTIONS.SET_VIDEO_MEDIA_CONFIGS,
      videoMediaConfigs: videoConfigs,
    });
  });
}

export function setDemoMode(mode) {
  return (dispatch) => new Promise(() => {
    dispatch({
      type: ACTIONS.SET_DEMO_MODE,
      demoMode: mode,
    });
  });
}
