import getStore from 'STORE/Store';
import _ from 'lodash';
import {
  getUserData,
} from 'STORE/ClientSettings/ClientSettingsSelector';
import {
  getCustomerInfo,
} from 'STORE/Auth/AuthSelector';

const CALL_SURVEY_GUIDE_NAME = 'Call Survey';

const processPendoReady = () => {
  console.info('Pendo:: Ready');
};

const processGuidesFailed = (arg) => {
  console.info('Pendo:: GuideFailed', arg);
};

const processGuidesLoaded = (arg) => {
  console.info('Pendo:: GuidesLoaded', arg);
};

const processGuideSeen = (arg) => {
  console.info('Pendo:: GuideSeen', arg);
};

const processGuideDismissed = (arg) => {
  console.info('Pendo:: GuideDismissed', arg);
};

export const initializePendo = () => {
  if (window.dynamicEnv.REACT_APP_PENDO_ENABLE === 'true') {
    const { store } = getStore();
    const userData = getUserData(
      store.getState(),
    );
    const customerInfo = getCustomerInfo(
      store.getState(),
    );
    if (!_.isEmpty(userData)) {
      const customerDomain = userData.username.split('@')[1];
      window.pendo.initialize({
        visitor: {
          id: userData.unique_id,
          env: window.dynamicEnv.REACT_APP_DEPLOYMENT_ENV,
          isGuest: userData.is_guest === 'yes',
        },
        account: {
          id: window.dynamicEnv.REACT_APP_DEPLOYMENT_ENV + '_' + customerDomain,
          env: window.dynamicEnv.REACT_APP_DEPLOYMENT_ENV,
          customerId: customerInfo.customerUniqueId,
          customerName: customerInfo.customerName,
          customerDomain,
        },
        events: {
          ready: processPendoReady,
          guidesLoaded: processGuidesLoaded,
          guidesFailed: processGuidesFailed,
          guideSeen: processGuideSeen,
          guideDismissed: processGuideDismissed,
        },
      });
    }
  }
};

export const showGuideByName = (name, timeout = 100) => {
  // show the pendo guide after the specified timeout
  setTimeout(() => window.pendo.showGuideByName(name), timeout);
};

export const showCallSurvey = (timeout = 100) => {
  if (window.dynamicEnv.REACT_APP_PENDO_ENABLE === 'true') {
    if (window.pendo.findGuideByName(CALL_SURVEY_GUIDE_NAME)) {
      showGuideByName(CALL_SURVEY_GUIDE_NAME, timeout);
      return true;
    }
  }
  return false;
};

export const showGuides = (timeout = 100) => {
  setTimeout(() => {
    window.pendo.getAllGuides();
  }, timeout);
};

// Ugly! Checks if the guide is showing for onboarding - not used currently
export const isDemoGuideActive = () => (window.pendo.getActiveGuide()?.guide?.id === 'N1Zosh1PECYhuzlKS8CMyxvOrEU');

/**
 * AppManager registers the callback function that would control the call demo mode.
 * We expose an interface for Pendo guides to control demo mode
 * @param {function} demoAction function to be called for demo mode start/stop
 */
export const registerDemoAction = (demoAction) => {
  if (window.dynamicEnv.REACT_APP_PENDO_ENABLE !== 'true') return;

  const START_ACTION = true;
  window.pendoActions = {
    startDemo: () => {
      console.debug('Pendo::Demo starting');
      demoAction(START_ACTION);
    },
    endDemo: () => {
      demoAction(!START_ACTION);
      console.debug('Pendo::Demo ended');
    },
  };
};
