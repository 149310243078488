import { ACTIONS } from 'UTILS/constants/ActionConstants';

const initialState = {
  userData: null,
  clientSettingsData: null,
  clientPolicyUISettings: null,
  clientSessionConfigInfo: null,
  clientPolicyData: null,
  clientPolicyRequestTimestamp: 0,
  profileRequestTimestamp: 0,
  clientPermissions: null,
  videoMediaConfig: null,
  selectedVideoProfile: null,
  networkSettings: null,
  audioMediaConfigs: null,
  videoMediaConfigs: null,
  securitySettings: null,
  nlpUrl: null,
  askAndSearchParams: null,
  workspaceSettings: null,
  demoMode: false,
  meetingSettings: null,
  guestInvitePolicy: null,
  guestCustomFields: [],
  imageCaptureSettings: null,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  // console.debug('ClientSettingsReducer::state', state, 'action::', action);
  switch (action.type) {
    case ACTIONS.SET_USER_XML_DATA:
      return {
        ...state,
        userData: action.userData,
      };
    case ACTIONS.SET_SIP_CLIENT_SETTINGS_XML:
      return {
        ...state,
        clientSettingsData: action.clientSettings,
      };
    case ACTIONS.SET_POLICY_UI_SETTINGS:
      return {
        ...state,
        clientPolicyUISettings: action.clientPolicyUISettings,
      };
    case ACTIONS.SET_CLIENT_SESSION_CONFIG_INFO:
      return {
        ...state,
        clientSessionConfigInfo: action.clientSessionConfigInfo,
      };
    case ACTIONS.SET_CLIENT_POLICY_DATA:
      return {
        ...state,
        clientPolicyData: action.clientPolicyData,
      };
    case ACTIONS.SET_CLIENT_POLICY_REQUEST_TIMESTAMP:
      return {
        ...state,
        clientPolicyRequestTimestamp: action.clientPolicyRequestTimestamp,
      };
    case ACTIONS.SET_PROFILE_REQUEST_TIMESTAMP:
      return {
        ...state,
        profileRequestTimestamp: action.profileRequestTimestamp,
      };
    case ACTIONS.SET_CLIENT_PERMISSIONS:
      return {
        ...state,
        clientPermissions: action.clientPermissions,
      };
    case ACTIONS.SET_VIDEO_MEDIA_CONFIG: // comes from OPM
      return {
        ...state,
        videoMediaConfig: action.videoProfileData,
      };
    case ACTIONS.SET_VIDEO_MEDIA_CONFIGS:
      return {
        ...state,
        videoMediaConfigs: action.videoMediaConfigs,
      };
    case ACTIONS.SET_AUDIO_MEDIA_CONFIGS:
      return {
        ...state,
        audioMediaConfigs: action.audioMediaConfigs,
      };
    case ACTIONS.SET_SELECTED_VIDEO_PROFILE:
      return {
        ...state,
        selectedVideoProfile: action.selectedVideoProfile,
      };
    case ACTIONS.SET_NETWORK_SETTINGS:
      return {
        ...state,
        networkSettings: action.networkSettings,
      };
    case ACTIONS.SET_WORKSPACE_SETTINGS:
      return {
        ...state,
        workspaceSettings: action.workspaceSettings,
      };
    case ACTIONS.SET_SECURITY_SETTINGS:
      return {
        ...state,
        securitySettings: action.securitySettings,
      };
    case ACTIONS.SET_NLP_URL:
      return {
        ...state,
        nlpUrl: action.nlpUrl,
      };
    case ACTIONS.SET_ASK_AND_SEARCH_PARAMS:
      return {
        ...state,
        askAndSearchParams: action.askAndSearchParams,
      };
    case ACTIONS.SET_DEMO_MODE:
      return {
        ...state,
        demoMode: action.demoMode,
      };
    case ACTIONS.SET_MEETING_SETTINGS:
      return {
        ...state,
        meetingSettings: action.meetingSettings,
      };
    case ACTIONS.SET_VIDEO_OVERLAY_SETTINGS:
      return {
        ...state,
        videoOverlaySettings: action.videoOverlaySettings,
      };
    case ACTIONS.SET_GUEST_INVITE_POLICY:
      return {
        ...state,
        guestInvitePolicy: action.guestInvitePolicyData,
      };
    case ACTIONS.SET_GUEST_CUSTOM_FIELDS:
      return {
        ...state,
        guestCustomFields: action.guestCustomFields,
      };
    case ACTIONS.SET_IMAGE_SETTINGS:
      return {
        ...state,
        imageCaptureSettings: action.imageCaptureSettingsData,
      };
    default:
      return state;
  }
};
