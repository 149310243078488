import { LANGUAGE } from 'UTILS/constants/LanguageConstants';
import { ACTIONS } from 'UTILS/constants/ActionConstants';

const INITIAL_STATE = {
  language: LANGUAGE.ENGLISH,
};

// eslint-disable-next-line func-names
export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case ACTIONS.SET_LANGUAGE:
      return { ...state, language: action.language };

    default:
      return state;
  }
}
