/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';

// Custom component
import TextFieldComponent from 'COMPONENTS/CustomComponents/TextFieldComponent/TextFieldComponent';
import CustomForm from 'COMPONENTS/CustomComponents/CustomForm/CustomForm';
import CustomButton from 'COMPONENTS/CustomComponents/CustomButton/CustomButton';

// Utility
import { translate } from 'SERVICES/i18n';
import { GUEST_LOGIN } from 'UTILS/constants/DOMElementConstants';

// Asset
import { ReactComponent as ProfileNameIcon } from 'ASSETS/icon_username.svg';
import { ReactComponent as EmailIcon } from 'ASSETS/MailOutlined.svg';

// Constant
import CommonUtility from 'UTILS/CommonUtility';
import { BUTTON_TYPES } from 'UTILS/constants/UtilityConstants';

import './Login.scss';

/**
 * @param {number} meetingId meeting id
 * @param {function} setErrorModal Error popup in case of invalid creds
 * @param {function} handleGuestLoginAPI Pass input values to the guest login API
 * @param {function} onCancel Action to be taken on cancel button click
 * @returns jsx for the Guest login View
 */
function GuestLogin({ meetingId, setErrorModal, handleGuestLoginAPI, onCancel }) {
  const guestNameRef = React.createRef(null);
  const guestEmailRef = React.createRef(null);
  const GUEST_NAME = 'guestName';
  const GUEST_EMAIL = 'guestEmail';
  // State variables
  const [validated, setValidated] = useState(false);
  const [guestFullName, setGuestFullName] = useState('');
  const [guestFirstName, setGuestFirstName] = useState('');
  const [guestLastName, setGuestLastName] = useState('');
  const [guestEmailAddr, setGuestEmailAddr] = useState('');
  const [isLoginDisabled, setLoginDisabled] = useState(true);

  const resetError = () => {
    setValidated(false);
  };

  const checkSignInDisability = () => {
    if (guestFullName.length === 0 || guestEmailAddr.length === 0) {
      setLoginDisabled(true);
    } else {
      setLoginDisabled(false);
    }
  };

  React.useEffect(() => {
    if (guestFullName.trim().split(' ').length === 1) {
      setGuestFirstName(guestFullName.trim());
      setGuestLastName('');
    } else {
      setGuestFirstName(guestFullName.trim().split(' ').slice(0, -1).join(' '));
      setGuestLastName(guestFullName.trim().split(' ').pop());
    }
    if (!guestFullName) {
      setGuestEmailAddr('');
    }
    resetError();
    checkSignInDisability();
  }, [guestFullName]);

  React.useEffect(() => {
    resetError();
    checkSignInDisability();
  }, [guestEmailAddr]);

  const handleChange = (event, type) => {
    switch (type) {
      case GUEST_NAME:
        setGuestFullName(event.target.value);
        break;
      case GUEST_EMAIL:
        setGuestEmailAddr(event.target.value.trim());
        break;
      default:
    }
  };

  // This function is used for local validations for guest user meeting login
  const validateGuestInputs = () => {
    let isValid = true;
    // Validate guest emailid
    if (!CommonUtility.isValidEmail(guestEmailAddr)) {
      setErrorModal(
        translate('contacts.webAddrErrorMsg'),
        true,
        'login.invalidGuestCredsModalHeader',
      );
      isValid = false;
    }

    if (!guestEmailRef.current.checkValidity()) {
      setErrorModal(
        translate('contacts.webAddrErrorMsg'),
        true,
        'login.invalidGuestCredsModalHeader',
      );
      isValid = false;
    }
    return isValid;
  };

  // Validate inputs and send to api in case of valid inputs
  const handleGuestLoginClick = (e) => {
    e.preventDefault();
    setValidated(true);
    if (validateGuestInputs() && meetingId) {
      handleGuestLoginAPI(
        guestFirstName,
        guestLastName,
        guestEmailAddr,
      );
    }
  };

  // Guest login UI for meeting
  const guestLoginView = () => (
    <>
      <TextFieldComponent
        type='text'
        id={GUEST_LOGIN.NAME_ID}
        value={guestFullName}
        placeholder={translate('login.guestName')}
        maxLength={100}
        leftIcon={ProfileNameIcon}
        inputRef={guestNameRef}
        handleChange={(event) => handleChange(event, GUEST_NAME)}
        className={`${isMobileOnly ? 'text-mob' : 'text-desktop'}`}
      />
      <Row className='m-2' />
      <TextFieldComponent
        type='text'
        id={GUEST_LOGIN.EMAILADDR_ID}
        value={guestEmailAddr}
        placeholder={translate('login.guestEmail')}
        maxLength={100}
        leftIcon={EmailIcon}
        inputRef={guestEmailRef}
        handleChange={(event) => handleChange(event, GUEST_EMAIL)}
        className={`${isMobileOnly ? 'text-mob' : 'text-desktop'}`}
        disabled={!guestFullName}
      />
      <Row className='m-2' />
      <Col className={`${isMobileOnly ? 'login-btn-mob mt-3' : 'login-btn-desktop mt-3'}`} md={12} sm={12}>
        <CustomButton
          className='w-100 rounded-0'
          type='submit'
          text='login.guestLoginButton'
          disabled={isLoginDisabled}
        />
      </Col>

      <Col className={`${isMobileOnly ? 'login-btn-mob mt-3' : 'login-btn-desktop mt-3'}`} md={12} sm={12}>
        <CustomButton
          className='w-100 rounded-0'
          disabled={false}
          text='meeting.cancel'
          onClick={() => onCancel()}
          variant={BUTTON_TYPES.SECONDARY}
        />
      </Col>
    </>
  );

  return (
    <Row>
      <Col className='align-center p-0'>
        <CustomForm
          formId={GUEST_LOGIN.FORM_ID}
          onSubmit={handleGuestLoginClick}
          validated={validated}
        >
          <Row className='px-4 py-3'>
            {guestLoginView()}
          </Row>
        </CustomForm>
      </Col>
    </Row>
  );
}
export default GuestLogin;
