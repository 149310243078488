// Regex Patterns
export const EMAIL_PATTERN = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]{1,30}$';

export const USERNAME_PATTERN = /^[a-zA-Z0-9-_.!~()$]+$/;

export const USERNAME_DOMAIN_PATTERN = /^[a-zA-Z0-9]*$/;

export const LOGIN_FORM_FIELDS = {
  EMAIL: 'email',
  PASSWORD: 'password',
  SSO_COMPANY_NAME: 'company-name',
};

export const SSO_LOGIN_OPM_URL = 'OamClientWebService/ClientServices?sso&Action=Login&Method=SAML&ConfigurationFriendlyName=&Domain';
export const SSO_LOGIN_RELAY_STATE = '&SessionToken=';

export const SSO_LOGIN_URL_FIELDS = {
  SSO_SOURCE: 'WebClient',
  SSO_LANGUAGE: 'en',
  SSO_METADATA_TOKEN: 'none',
  SSO: '/sso/',
};

export const LOGIN_OPTIONS = {
  USERNAME: 'username',
  SSO: 'sso',
};

// Login Response
export const LOGIN_RESPONSES = {
  INVALID_ATTEMPTS: -1,
  MIN_ATTEMPTS_REMAINING: 0,
  SUCCESS: 1,
  ACCOUNT_LOCKED: 4,
  PASSWORD_EXPIRED: 5,
  NOT_LICENSED_FOR_PRODUCT: 9,
  MAX_SESSION_EXCEED: 12,
  TEMP_PASSWORD: 14,
  FAILURE: 15,
  INVALID_CREDENTIALS: 15,
};

// Meeting Guest login responses
export const GUEST_LOGIN_RESPONSES = {
  UNKNOWN: 0,
  SUCCESS: 1,
  NO_USER_LICENSE: 3,
  SIP_ACCOUNT_POOL_EMPTY: 4,
  INVITES_NOT_ALLOWED: 6,
  SIP_SERVER_TEMP_UNAVAILABLE: 7,
  MEETING_GUESTS_NOT_ALLOWED: 13,
};

// SSO login responses
export const SSO_LOGIN_RESULT = {
  SUCCESS: 'Success',
  SAML_GENERAL_FAILURE: 'SamlGeneralFailure',
  SAML_FEDERATION_ATRIBUTE_MSSING: 'SamlFederationAttributeMissing  ',
  SAML_IDP_NAME_MISMATCH: 'SamlIdpNameMismatch',
  SAML_NOT_ENABLED: 'SamlNotEnabled',
  SAML_ACCOUNT_PROVISIONIG_FAILED: 'SamlAccountProvisioningFailed',
};

export const SSO_LOGIN_REGISTER_RESULT = {
  CUSTOMER_DOES_NOT_EXIST: 'CustomerDoesNotExist',
  EMAIL_DOMAIN_NOT_ALLOWED: 'EmailDomainNotAllowed',
  USER_ALREADY_EXIST: 'UserAlreadyExists',
  PASSWORD_POLICY_VIOLATION: 'PasswordPolicyViolation',
  NO_USER_LICENCE_AVAIALABLE: 'NoUserLicensesAvailable',
  INVALID_USER_NAME: 'InvalidUserName',
  INVALID_FIRST_NAME: 'InvalidFirstName',
  INVALID_EMAIL_ADDRESS: 'InvalidEmailAddress',
  REGISTRATION_UNKNOWN: 'Unknown',
  INCORRECT_CREATION_KEY: 'IncorrectCreationKey',
  REGISTRATION_DISABLED: 'RegistrationDisabled',
};

// Guest invite responses
export const GUEST_INVITE_RESPONSES = {
  UNKNOWN: 0,
  SUCCESS: 1,
  EMAIL_EXISTS: 2,
  NO_USER_LICENSE: 3,
  SIP_ACCOUNT_POOL_EMPTY: 4,
  INVALID_EXPIRY: 5,
  INVITES_NOT_ALLOWED: 6,
  SIP_SERVER_TEMP_UNAVAILABLE: 7,
  SMS_INVITES_NOT_ALLOWED: 8,
  INVALID_PHONE: 9,
  MAX_MESSAGE_LENGTH_EXCEEDED: 10,
  INVITER_EMAIL_UNAVAILABLE: 11,
  INVALID_EMAIL: 12,
};

export const OPM_SESSION_TERMINATED = 20;

export const PADDING_CHARSET = ['%', '$', '&', '!', '#', '^', '*', '?'];

export const SALT_HEX_STRING = 'A009C1A485912C6AE630D3E744240B04B006123AD3A195C04596FF042E7B040FDCAB00E10B3C682D359ADF100B7C6E630D3E23AD3A195C04596FF042040FDCAB';

export const WEB_SERVICE = '/OamClientWebService';

export const FORGOT_PASSWORD_LINK = '/ClientServices?forgot';

export const CHANGE_PASSWORD_RESULT_TYPES = {
  POLICY_VIOLATION: 2,
  SUCCESS: 1,
  OLD_NEW_MATCH: 6,
  OLD_PASSWORD_WRONG: 5,
};

export const DEFAULT_LANGUAGE = { label: 'English', value: 'en' };

export const GUEST_INVITE_LANGUAGES = [
  { label: 'Chinese', value: 'zh' },
  { label: 'English', value: 'en' },
  { label: 'French', value: 'fr' },
  { label: 'German', value: 'de' },
  { label: 'Italian', value: 'it' },
  { label: 'Japanese', value: 'ja' },
  { label: 'Korean', value: 'ko' },
  { label: 'Portuguese', value: 'pt' },
  { label: 'Portuguese (Brazil)', value: 'pt_BR' },
  { label: 'Russian', value: 'ru' },
  { label: 'Spanish', value: 'es' },
  { label: 'Swedish', value: 'sv' },
];
