import { ReactComponent as FilesIcon } from 'ASSETS/icon_files.svg';
import { ReactComponent as VideoIcon } from 'ASSETS/icon_video.svg';
import { ReactComponent as AudioIcon } from 'ASSETS/icon_call_audio.svg';
import React from 'react';
import PropTypes from 'prop-types';

class Thumbnail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      base64: null,
    };
  }

  componentDidMount() {
    this.props.thumbnailFunction(
      this.props.sessionToken,
      this.props.doc,
      this.props.workspaceSettings,
    )
      .then((res) => {
        this.setState(() => ({
          base64: res,
        }));
      });
  }

  render() {
    if (this.state.base64 != null) return (<img src={this.state.base64} width='32' height='32' alt={this.props.doc.uniqueId} />);
    if (this.props.doc.type === 'Video') return (<VideoIcon className='video-icon' />);
    if (this.props.doc.type === 'Audio') return (<AudioIcon className='audio-icon' />);
    return (<FilesIcon className='files-icon' />);
  }
}

Thumbnail.propTypes = {
  thumbnailFunction: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sessionToken: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  doc: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  workspaceSettings: PropTypes.any.isRequired,
};
export default Thumbnail;
