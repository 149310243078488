/**
 * @file API for Userback bug reporting.
 */

// Utility
import Utils from 'UTILS/CommonUtility';

import getStore from 'STORE/Store';
import {
  getUserData,
} from 'STORE/ClientSettings/ClientSettingsSelector';
import Userback, { getUserback } from '@userback/widget';
import { AppLogger, LOG_NAME } from 'SERVICES/Logging/AppLogger';

export const ENABLE_USERBACK_WIDGET = false;
const logger = AppLogger(LOG_NAME.AppServices);
/**
 * Exposes API for controlling userback bug reporting
 */
export const UserbackAPI = {
  /**
   * Initializes the Userback interface with the token and user credentials
   *
   * @returns [void]
   */
  initialize: () => {
    if (!Utils.isEnvFlagSet('REACT_APP_USERBACK_ENABLE')) return;

    const { store } = getStore();
    const userData = getUserData(
      store.getState(),
    );
    if (!userData) {
      logger.error('User data is not found!!', userData);
      return;
    }
    Userback(window.dynamicEnv.REACT_APP_USERBACK_TOKEN,
      {
        email: userData.email,
        name: userData.username,
        autohide: !ENABLE_USERBACK_WIDGET,
        on_load: () => {
          logger.info('Userback::Initialized');
          // Assign global action handler for triggering from external scripts
          window.userbackActions = {
            reportBug: () => {
              logger.info('Userback: Reporting bug');
              UserbackAPI.reportBug();
            },
            submitFeedback: () => {
              logger.info('Userback: Submiting feedback');
              UserbackAPI.submitFeedback();
            },
          };
        },
      });
  },

  /**
   * This interface controls display of userback's default widget
   * that can be used for triggering reports
   *
   * @returns [void]
   */
  widget: {
    show: () => getUserback().show(),
    hide: () => getUserback().hide(),
  },

  /** Trigger bug / feedback report */
  reportBug: () => getUserback().open('bug'),
  submitFeedback: (openForm = false) => getUserback().open('feature_request', openForm ? 'form' : undefined),
};
