/**
 * Contains common READ ONLY functions exported and used across ALL of APP
 */
import {
  LS_DATASTREAM_PARTICIPANTID,
} from 'UTILS/constants/DatastreamConstant';

import LS_DATASTREAM_PROTOCOL from 'SERVICES/LSDatastream/LSDatastreamProtocol';

export const dummy = 0;
// It will return true if the logged in user is active video sharer
export const isLoggedInUserActiveVideoSharer = () =>
  (LS_DATASTREAM_PROTOCOL.onsight.activeSharerPid === LS_DATASTREAM_PROTOCOL.onsight.localPid
    && LS_DATASTREAM_PROTOCOL.onsight.activeSharerPid !==
  LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID);

export const isRemoteUserSharing = () =>
  ((LS_DATASTREAM_PROTOCOL.onsight.activeSharerPid !== LS_DATASTREAM_PROTOCOL.onsight.localPid) &&
  (LS_DATASTREAM_PROTOCOL.onsight.activeSharerPid !==
    LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID));

export const getRemotePId = () =>
  (LS_DATASTREAM_PROTOCOL.onsight.remotePid !==
    LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID
    ? LS_DATASTREAM_PROTOCOL.onsight.remotePid
    : LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID);

export const getLocalPId = () =>
  (LS_DATASTREAM_PROTOCOL.onsight.localPid !==
    LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID
    ? LS_DATASTREAM_PROTOCOL.onsight.localPid
    : LS_DATASTREAM_PARTICIPANTID.UNKNOWN_PARTICIPANTID);

export const getCurrentVideoConfig = () => LS_DATASTREAM_PROTOCOL.onsight.currentMediaVideoConfig;
