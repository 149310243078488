/* eslint-disable import/prefer-default-export */
import isEmpty from 'lodash/isEmpty';
import { ACTIONS } from 'UTILS/constants/ActionConstants';
import CommonUtility from 'UTILS/CommonUtility';
import {
  X_PATHS,
  CLIENT_PERMISSIONS,
  POLICY,
  OPM_VIDEO_OVERLAY_TEXT_SIZE,
  OPM_VIDEO_OVERLAY_POSITION,
  OVERLAY_SHOW_LOCATION,
  OVERLAY_SHOW_DATE_TIME,
  OVERLAY_POSITION,
  OVERLAY_TEXT_SIZE,
} from 'UTILS/constants/UtilityConstants';

const setClientPolicyAction = (res, dispatch) => {
  const policyUISettingsXml = CommonUtility.convertXmlToJSONGeneric(
    res.data.clientPolicyXml,
    X_PATHS.CLIENT_POLICY_UI_SETTINGS,
  );
  // Convert clientPolicyXml into JSON format
  const clientPolicyJSON = CommonUtility.convertXmlToJSONGeneric(
    res.data.clientPolicyXml,
    X_PATHS.CLIENT_POLICY,
  );

  // Get the client permissions
  const clientPermissionsData = CommonUtility.convertToClientPermissions(
    res.data.clientPolicyXml,
    CLIENT_PERMISSIONS,
  );

  // Network settings
  const networkSettings = CommonUtility.convertXmlToJSONGeneric(
    res.data.clientPolicyXml,
    X_PATHS.CLIENT_POLICY_NETWORK,
  );

  // Workspace settings
  const workspaceSettings = CommonUtility.convertXmlToJSONGeneric(
    res.data.clientPolicyXml,
    X_PATHS.CLIENT_POLICY_WORKSPACE,
  );

  // Security settings
  const securitySettings = CommonUtility.convertXmlToJSONGeneric(
    res.data.clientPolicyXml,
    X_PATHS.CLIENT_POLICY_SECURITY,
  );

  // AI Settings
  const aiSettings = CommonUtility.convertXmlToJSONGeneric(
    (res.data.clientPolicyXml),
    X_PATHS.CLIENT_POLICY_AI_SETTINGS,
  );

  const nlpUrl = CommonUtility.convertXmlToJSONGeneric(
    (aiSettings.AISettings),
    X_PATHS.CLIENT_POLICY_NLP_URL,
  );

  const askAndSearchParams = CommonUtility.convertXmlToJSONGeneric(
    (aiSettings.AISettings),
    X_PATHS.CLIENT_POLICY_ASK_AND_SEARCH_PARAMS,
  );

  // Meeting settings
  const meetingSettings = CommonUtility.convertXmlToJSONGeneric(
    res.data.clientPolicyXml,
    X_PATHS.CLIENT_POLICY_MEETINGS,
  );

  // Overlay settings
  const overlayPosition = CommonUtility.convertXmlToJSONGeneric(
    res.data.clientPolicyXml,
    X_PATHS.CLIENT_POLICY_OVERLAY_POSITION,
  );

  const overlayTextSize = CommonUtility.convertXmlToJSONGeneric(
    res.data.clientPolicyXml,
    X_PATHS.CLIENT_POLICY_OVERLAY_TEXT_SIZE,
  );

  const isOverlayEnabledForDateTime = CommonUtility.convertXmlToJSONGeneric(
    res.data.clientPolicyXml,
    X_PATHS.CLIENT_POLICY_OVERLAY_SHOW_DATE_TIME,
  );

  const isOverlayEnabledForLocation = CommonUtility.convertXmlToJSONGeneric(
    res.data.clientPolicyXml,
    X_PATHS.CLIENT_POLICY_OVERLAY_SHOW_LOCATION,
  );

  const videoOverlaySettings = {
    isEnabledForLocation: isOverlayEnabledForLocation[OVERLAY_SHOW_LOCATION] === POLICY.YES,
    isEnabledForDateTime: isOverlayEnabledForDateTime[OVERLAY_SHOW_DATE_TIME] === POLICY.YES,
    position: OPM_VIDEO_OVERLAY_POSITION[overlayPosition[OVERLAY_POSITION]],
    textSize: OPM_VIDEO_OVERLAY_TEXT_SIZE[overlayTextSize[OVERLAY_TEXT_SIZE]],
  };

  // Guest invite policy
  const guestInvitePolicy = CommonUtility.convertXmlToJSONGeneric(
    res.data.clientPolicyXml,
    X_PATHS.CLIENT_POLICY_GUEST_INVITE,
  );
  const customFieldsArray = [];
  if (guestInvitePolicy?.custom_fields) {
    const customFields = CommonUtility.convertXmlToJSONGeneric(
      CommonUtility.cleanXML(guestInvitePolicy?.custom_fields),
      X_PATHS.GUEST_CUSTOM_FIELDS,
    );
    // Convert object to array of objects
    Object.values(customFields).forEach((c) => {
      customFieldsArray.push(c);
    });
  }
  // Custom message
  const customMessage = CommonUtility.convertXmlToJSONGeneric(
    res.data.customFormsXml,
    X_PATHS.CUSTOM_MESSAGE,
  );
  // Image capture settings
  const imageCaptureSettings = CommonUtility.convertXmlToJSONGeneric(
    res.data.clientPolicyXml,
    X_PATHS.CLIENT_POLICY_IMAGES,
  );

  // If xml is null, there is no update, else save the updated info
  if (res.data?.clientPolicyXml && clientPolicyJSON) {
    dispatch({
      type: ACTIONS.SET_CLIENT_POLICY_DATA,
      clientPolicyData: clientPolicyJSON[0],
    });
  }
  if (res.data?.clientPolicyXml && policyUISettingsXml) {
    dispatch({
      type: ACTIONS.SET_POLICY_UI_SETTINGS,
      clientPolicyUISettings: policyUISettingsXml,
    });
  }
  if (res.data?.clientPolicyXml && networkSettings) {
    dispatch({
      type: ACTIONS.SET_NETWORK_SETTINGS,
      networkSettings,
    });
    const webRtcToken = networkSettings?.webrtc ?
      JSON.parse(networkSettings?.webrtc)?.authToken : null;

    dispatch({
      type: ACTIONS.SET_WEBRTC_TOKEN,
      webRtcToken: webRtcToken ?? null,
    });
  }
  if (res.data?.clientPolicyXml && workspaceSettings) {
    dispatch({
      type: ACTIONS.SET_WORKSPACE_SETTINGS,
      workspaceSettings,
    });
  }
  if (res.data?.clientPolicyXml && securitySettings) {
    dispatch({
      type: ACTIONS.SET_SECURITY_SETTINGS,
      securitySettings,
    });
  }
  if (res.data?.clientPolicyXml && aiSettings) {
    if (nlpUrl?.NLPEndpoint) {
      dispatch({
        type: ACTIONS.SET_NLP_URL,
        nlpUrl: nlpUrl?.NLPEndpoint ? JSON.parse(nlpUrl?.NLPEndpoint)?.Url : null,
      });
    }
    if (askAndSearchParams?.AskAndSearchParams) {
      dispatch({
        type: ACTIONS.SET_ASK_AND_SEARCH_PARAMS,
        askAndSearchParams: askAndSearchParams?.AskAndSearchParams ?
          JSON.parse(askAndSearchParams?.AskAndSearchParams) : null,
      });
    }
  }
  if (res.data?.clientPolicyXml && meetingSettings) {
    dispatch({
      type: ACTIONS.SET_MEETING_SETTINGS,
      meetingSettings,
    });
  }
  if (res.data?.clientPolicyXml && clientPermissionsData) {
    dispatch({
      type: ACTIONS.SET_CLIENT_PERMISSIONS,
      clientPermissions: clientPermissionsData,
    });
  }
  if (res.data?.clientPolicyXml && videoOverlaySettings) {
    dispatch({
      type: ACTIONS.SET_VIDEO_OVERLAY_SETTINGS,
      videoOverlaySettings,
    });
  }
  if (res.data.clientPolicyXml && guestInvitePolicy) {
    dispatch({
      type: ACTIONS.SET_GUEST_INVITE_POLICY,
      guestInvitePolicyData: guestInvitePolicy,
    });
  }
  if (res.data.clientPolicyXml && guestInvitePolicy && customFieldsArray) {
    if (!isEmpty(customFieldsArray[0].CustomField)) {
      const guestCustomFields = customFieldsArray[0].CustomField.map((field) => {
        if (!field.CustomValue) {
          field.options = [];
          field.CustomValue = [];
          return field;
        }
        if (Array.isArray(field.CustomValue)) {
          const options = [];
          const fieldOptions = field.CustomValue.map(
            (val) => {
              options.push(val.CustomValueName.toLowerCase());
              return { label: val.CustomValueName, value: val.CustomValueName };
            },
          );
          field.CustomValue = fieldOptions;
          field.options = options;
        } else {
          field.options = [field.CustomValue.CustomValueName.toLowerCase()];
          field.CustomValue = [{
            label: field.CustomValue.CustomValueName,
            value: field.CustomValue.CustomValueName,
          }];
        }
        return field;
      });

      dispatch({
        type: ACTIONS.SET_GUEST_CUSTOM_FIELDS,
        guestCustomFields,
      });
    }
  }
  if (res.data.customFormsXml && customMessage) {
    dispatch({
      type: ACTIONS.SET_CUSTOM_MESSAGE,
      cutsomMessageData: customMessage,
    });
  }
  if (res.data.clientPolicyXml && imageCaptureSettings) {
    dispatch({
      type: ACTIONS.SET_IMAGE_SETTINGS,
      imageCaptureSettingsData: imageCaptureSettings,
    });
  }
};

export { setClientPolicyAction };
