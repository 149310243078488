import { ReactComponent as EraserIcon } from 'ASSETS/icon_eraser.svg';
import { ReactComponent as UndoIcon } from 'ASSETS/icon_undo.svg';
import { ReactComponent as TextIcon } from 'ASSETS/icon_text.svg';
import { ReactComponent as ArrowLineIcon } from 'ASSETS/icon_arrow_line.svg';
import { ReactComponent as EllipseIcon } from 'ASSETS/icon_ellipse.svg';
import { ReactComponent as RectangleIcon } from 'ASSETS/icon_rectangle.svg';
import { ReactComponent as LineIcon } from 'ASSETS/icon_line.svg';
import { ReactComponent as FreehandIcon } from 'ASSETS/icon_freehand.svg';

export const TELESTRATION_ICON_SET = [
  {
    iconName: 'freehand',
    icon: FreehandIcon,
    disabled: false,
    id: 'freehand',
    type: 0,
    className: 'freehand-icon',
  },
  {
    iconName: 'line',
    icon: LineIcon,
    disabled: false,
    id: 'line',
    type: 2,
    className: 'line-icon',
  },
  {
    iconName: 'rectangle',
    icon: RectangleIcon,
    disabled: false,
    id: 'rectangle',
    type: 3,
    className: 'rectangle-icon',
  },
  {
    iconName: 'arrowLine',
    icon: ArrowLineIcon,
    disabled: false,
    id: 'arrow_line',
    type: 4,
    className: 'arrow-line-icon',
  },
  {
    iconName: 'text',
    icon: TextIcon,
    disabled: false,
    id: 'text',
    type: 5,
    className: 'text-icon',
  },
  {
    iconName: 'ellipse',
    icon: EllipseIcon,
    disabled: false,
    id: 'ellipse',
    type: 1,
    className: 'ellipse-icon',
  },
  {
    iconName: 'undo',
    icon: UndoIcon,
    disabled: false,
    id: 'undo',
    className: 'undo-icon',
  },
  {
    iconName: 'erase',
    icon: EraserIcon,
    disabled: false,
    id: 'erase',
    className: 'erase-icon',
  },
  {
    iconName: 'color',
    disabled: false,
    id: 'color',
    className: 'color-icon',
  },
];

export const TELESTRATION_ITEM_TYPE = {
  LINE: { name: 'line', type: 2 },
  ARROW_LINE: { name: 'arrow_line', type: 4 },
  RECTANGLE: { name: 'rectangle', type: 3 },
  ELLIPSE: { name: 'ellipse', type: 1 },
  TEXT: { name: 'text', type: 5 },
  FREE_HAND: { name: 'freehand', type: 0 },
};

export const EXTENDED_FUNCTIONS = {
  UNDO: { name: 'undo', value: 547 },
  REDO: { name: 'redo', value: '' },
  ERASE: { name: 'erase', value: 162 },
};

export const TELESTRATION_COLORS = [
  { id: 1, colorCode: '#75140C' },
  { id: 2, colorCode: '#E93323' },
  { id: 3, colorCode: '#E933F6' },
  { id: 4, colorCode: '#7514F5' },
  { id: 5, colorCode: '#75147B' },
  { id: 6, colorCode: '#0000F4' },
  { id: 7, colorCode: '#00007A' },
  { id: 8, colorCode: '#377E7F' },
  { id: 9, colorCode: '#808026' },
  { id: 10, colorCode: '#377E21' },
  { id: 11, colorCode: '#75FB4C' },
  { id: 12, colorCode: '#75FBFD' },
  { id: 13, colorCode: '#FFFF54' },
  { id: 14, colorCode: '#EE8733' },
  { id: 15, colorCode: '#000000' },
  { id: 16, colorCode: '#555555' },
  { id: 17, colorCode: '#AAAAAA' },
  { id: 18, colorCode: '#FFFFFF' },
];

export const DEFAULT_FONT_SIZE = 12;
export const DEFAULT_FONT_ID = 12;
export const CANVAS_TELE_FONT_SIZE_RESOLUTION = 240;
export const DEFAULT_COORDS = [];
export const DEFAULT_ARGB_COLOR = -1;
export const DEFAULT_COLOR_FLAG = -1;
export const DEFAULT_TEXT = '';
export const DEFAULT_NUM = 0;
export const DEFAULT_THICKNESS = 0;
export const DEFAULT_TEXT_INPUT_SIZE = 200;
export const FREE_HAND_COORDS_BATCH = {
  INIT: 6,
  SUBSEQUENT: 10,
};
export const UNDO_TELE_COORDS_LEN = 2;

export const TELESTRATION_MOUSE_EVENTS = {
  CLICK: 'click',
  MOUSE_DOWN: 'mousedown',
  MOUSE_UP: 'mouseup',
  MOUSE_MOVE: 'mousemove',
  TOUCH_START: 'touchstart',
  TOUCH_MOVE: 'touchmove',
  TOUCH_END: 'touchend',
};

export const TELEV3_CHAR_CODE_PID = 0x10000;

export const TELEV3_REMOTE_COLOR_BIN = 0x0001;
