/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import Modal from 'react-responsive-modal';

import CustomButton from 'COMPONENTS/CustomComponents/CustomButton/CustomButton';

// Translation
import { translate } from 'SERVICES/i18n';

// Constant
import { BUTTON_TYPES } from 'UTILS/constants/UtilityConstants';

// Asset
import { ReactComponent as IncomingCallIcon } from 'ASSETS/icon_call_incomming.svg';
import { ReactComponent as OutgoingCallIcon } from 'ASSETS/icon_call_outgoing.svg';

// Style
import 'react-responsive-modal/styles.css';
import './CallingModal.scss';

// eslint-disable-next-line react/prefer-stateless-function
class CallingModal extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      isOpen: nextProps.open,
    };
  }

  render() {
    return (
      <Modal
        role='dialog'
        open={this.state.isOpen}
        center
        showCloseIcon={false}
        classNames={{
          modal: 'custom-modal',
        }}
        onClose={this.props.handleDismissError}
        closeOnOverlayClick={false}
        onEscKeyDown={this.props.handleDismissError}
        focusTrapped={false}
      >
        <Row className='modal-body' tabIndex='-1'>
          <Row className='justify-content-center margin-auto'>
            <span className='align-center'>{this.props.incomingCall ? <IncomingCallIcon className='incoming-call-icon' /> : <OutgoingCallIcon className='outgoing-call-icon' />}</span>
          </Row>
          <Row className='mb-4 margin-auto'>
            <span
              className={`p-0 justify-content-center ${this.props.incomingCall ? 'calling-name cen-text' : 'calling-text'} `}
            >
              <div className='ellipsis'>
                {translate(this.props.text)}
              </div>
            </span>
            <span
              className={`p-0 justify-content-center ${this.props.incomingCall ? 'calling-text' : 'calling-name cen-text'} `}
            >
              {translate(this.props.text1)}
            </span>
          </Row>

          {this.props.centeredButton && (
            <Row className='pt-1 mt-3 centered-button align-items-end-center'>
              <CustomButton
                id='calling-modal-dismiss-button'
                className='align-center action-buttons permission-button'
                onClick={this.props.handleDismissError}
                disabled={this.props.disabledButton}
                text='contacts.cancelAddContact'
                variant={BUTTON_TYPES.SECONDARY}
              />
            </Row>
          )}
          {this.props.multiButtons && (
            <Row className='pt-3 centered-button align-items-end-center'>
              {this.props.leftButton &&
                this.props.leftButton.map((button, index) => (
                  <CustomButton
                    id={`calling-modal-button-${index}`}
                    key={index}
                    className={`align-center action-buttons cancel-button permission-button ${button.buttonClass}`}
                    onClick={button.onClick}
                    text={button.text}
                    variant={BUTTON_TYPES.SECONDARY}
                  />
                ))}
              {this.props.rightButton &&
                this.props.rightButton.map((button, index) => (
                  <CustomButton
                    key={index}
                    className={`align-center action-buttons permission-button ${button.buttonClass}`}
                    onClick={button.onClick}
                    disabled={button.disabled}
                    type={button.type}
                    text={button.text}
                  />
                ))}
            </Row>
          )}
        </Row>
      </Modal>
    );
  }
}

CallingModal.propTypes = {
  text: PropTypes.string,
  // okButton: PropTypes.objectOf,
  // cancelButton: PropTypes.objectOf,
  handleDismissError: PropTypes.func,
  open: PropTypes.bool,
};

CallingModal.defaultProps = {
  text: '',
  // okButton: {},
  // cancelButton: {},
  handleDismissError: () => { },
  open: false,
};

export default CallingModal;
