/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';

// Component
import Sidebar from 'COMPONENTS/Dashboard/Sidebar';
import CustomSearchableDropdown from 'COMPONENTS/CustomComponents/CustomSearchableDropdown/CustomSearchableDropdown';

// Custom component
import TextFieldComponent from 'COMPONENTS/CustomComponents/TextFieldComponent/TextFieldComponent';
import CustomForm from 'COMPONENTS/CustomComponents/CustomForm/CustomForm';
import CustomDropdown from 'COMPONENTS/CustomComponents/CustomDropdown/CustomDropdown';
import Switch from 'react-switch';
import CustomButton from 'COMPONENTS/CustomComponents/CustomButton/CustomButton';
import ErrorModal from 'COMPONENTS/CustomComponents/ErrorDialogModal/ErrorModal';
import CustomCheckbox from 'COMPONENTS/CustomComponents/CustomCheckbox/CustomCheckbox';
import CustomRadioButton from 'COMPONENTS/CustomComponents/CustomRadioButton/CustomRadioButton';

// Utility
import CommonUtility from 'UTILS/CommonUtility';

// Constant
import { SWITCH_COMPONENT, BUTTON_TYPES, POLICY } from 'UTILS/constants/UtilityConstants';
import { GUEST_INVITE_LANGUAGES, DEFAULT_LANGUAGE } from 'UTILS/constants/LoginConstants';
import { GUEST_INVITE } from 'UTILS/constants/DOMElementConstants';

// Translation
import { translate } from 'SERVICES/i18n';

// Asset
import { ReactComponent as BackIcon } from 'ASSETS/icon_back_arrow.svg';

// Style
import '../Dashboard.scss';
import '../../../resources/styles/globalStyles.scss';
import _ from 'lodash';

/**
 * @param {function} onCancelClick Close on click of back or cancel
 * @param {function} sendGuestInvite Pass input values to the guest invite API
 * @param {function} addToContacts Handle adding existing users to contacts function
 * @param {function} onCancelExistingUsers Handle cancel click from existing users UI
 * @param {object} guestSettings min and max exiry values coming from clientsettingsconfiginfo xml
 * @param {object} policy Guest invite client policy object
 * @param {array} existingUsers array of existing users coming from existingUsersXml
 * @param {array} customFields array of custom field value and key
 * @returns jsx for the Guest invite form UI
 */

function GuestInvite({ onCancelClick, sendGuestInvite, addToContacts, onCancelExistingUsers,
  guestSettings, policy, personalContacts, existingUsers = [], customFields = [] }) {
  const fnameRef = React.createRef(null);
  const messageRef = React.createRef(null);
  const emailphoneRef = React.createRef(null);
  const timeRef = React.createRef(null);

  const radioOptions = ['emailOption', 'smsOption'];
  const expiryOptions = [{ label: 'Days', value: 'Days' }];
  const userModeOptions = [{ label: 'Expert', value: 0 }, { label: 'Field', value: 1 }];
  const fieldTypes = {
    FIRSTNAME: 'firstName',
    LASTNAME: 'lastName',
    EMAIL: 'email',
    PHONE: 'phone',
    MESSAGE: 'message',
    SELECTED_EXPIRY_OPTION: 'selectedExpiryOption',
    TIME: 'time',
    CALL_OPTION: 'callOption',
    LANGUAGE: 'language',
    USERMODE: 'userMode',
  };
  // The hours option will only be available if the minimum guest expiry time
  // that comes down from OPM is less than 24 hours.
  if (guestSettings.minExpiry < 24) {
    expiryOptions.push({ label: 'Hours', value: 'Hours' });
  }

  // States
  const [validated, setValidated] = useState(false);
  const [openPopup, setErrorPopup] = useState(false);
  const [errors, setErrors] = useState({});
  const [existingUsersUI, showExistingUsersUI] = useState(false);
  const [contactsToAdd, setContactsToAdd] = useState([]);
  const [popupMessage, setPopupErrorMsg] = useState('');
  const [fields, setFields] = useState({
    callOption: policy?.allow_setting_call_host_immediately === POLICY.YES,
    language: DEFAULT_LANGUAGE,
    charCount: 0,
    selectedOption: radioOptions[0],
    // eslint-disable-next-line no-nested-ternary
    selectedExpiryOption: parseInt(policy?.expiry_hours_default, 10) > 23 ?
      expiryOptions[0] : expiryOptions[1] ? expiryOptions[1] : expiryOptions[0],
    userMode: policy?.user_mode_default === '0' ? userModeOptions[0] : userModeOptions[1],
    message: '',
    lastName: '',
    phone: '',
    time: parseInt(policy?.expiry_hours_default, 10) > 23 ?
      parseInt(policy?.expiry_hours_default, 10) / 24 :
      parseInt(policy?.expiry_hours_default, 10),
  });
  const [customFieldValues, setCustomFields] = useState({});
  const [customFieldValuePairs, setCustomFieldValuePairs] = useState({});
  const [canAddContact, setCanAddContact] = useState(false);

  const maxSMSMsgLength = parseInt(policy?.max_sms_message_length, 10);
  const visibilityState = { visible: false };

  // Required to switch to the existing users UI
  useEffect(() => {
    if (existingUsers.length > 0) {
      showExistingUsersUI(true);
    }
  });

  const setVisibilty = () => {
    if (existingUsersUI && !visibilityState.visible) {
      setTimeout(() => {
        document.getElementById('email_ui')?.scrollIntoView(false);
        visibilityState.visible = true;
      }, 50);
    }
  };

  useEffect(() => {
    if (_.isEqual(fields.selectedExpiryOption.value, expiryOptions[0].value)) { // days
      setFields({ ...fields, timeHours: fields.time * 24 });
    } else { // hours
      setFields({ ...fields, timeHours: fields.time });
    }
    setVisibilty();
  }, [fields.time, existingUsersUI]);

  const resetError = () => {
    setErrors({});
    setValidated(false);
  };

  const handleChange = (event, type) => {
    resetError();
    switch (type) {
      case radioOptions[0]: setFields({ ...fields, selectedOption: type, phone: '', email: '' });
        break;
      case radioOptions[1]: setFields({ ...fields, selectedOption: type, phone: '', email: '' });
        if (fields.message.length > maxSMSMsgLength) {
          setFields({ ...fields, message: '', charCount: 0 });
        }
        break;
      case fieldTypes.FIRSTNAME: case fieldTypes.LASTNAME: case fieldTypes.TIME:
        if ((type === fieldTypes.TIME && (CommonUtility.isValidTime(event.target.value) || event.target.value === ''))
          || type !== fieldTypes.TIME) {
          setFields({ ...fields, [type]: event.target.value });
        }
        break;
      case fieldTypes.EMAIL: setFields({ ...fields, email: event.target.value, phone: '' });
        break;
      case fieldTypes.PHONE: setFields({ ...fields, phone: event.target.value, email: '' });
        break;
      case fieldTypes.MESSAGE: if (fields.selectedOption === radioOptions[0] ||
        (event.target.value.length <= maxSMSMsgLength &&
          fields.selectedOption === radioOptions[1])) {
        setFields({ ...fields, message: event.target.value, charCount: event.target.value.length });
        setErrors({});
        setValidated(false);
      } else if (fields.selectedOption === radioOptions[1] &&
        (event.target.value.length === (maxSMSMsgLength + 1))) {
        setFields({ ...fields, message: fields.message, charCount: event.target.value.length });
        setErrors({ ...errors, message: translate('guestInvite.invalidMessage') });
        setValidated(true);
      }
        break;
      case fieldTypes.SELECTED_EXPIRY_OPTION: case fieldTypes.LANGUAGE: case fieldTypes.USERMODE:
        setFields({ ...fields, [type]: event });
        break;
      case fieldTypes.CALL_OPTION: setFields({ ...fields, callOption: !fields.callOption });
        break;
      default:
    }
  };

  // Close mandatory fields error popup
  const closeErrorPopup = () => {
    setErrorPopup(false);
    setPopupErrorMsg('');
  };

  const checkCustomFieldValue = () => {
    const customFieldValidation = {};
    customFields.forEach((field) => {
      if (customFieldValues[field.CustomFieldName] &&
        (!field.options.includes(customFieldValues[field.CustomFieldName].value.toLowerCase()))) {
        customFieldValidation[field.CustomFieldName] = true;
      }
    });
    return customFieldValidation;
  };

  const validateInputs = () => {
    const contact =
      (fields.selectedOption === radioOptions[0] ? fieldTypes.EMAIL : fieldTypes.PHONE);
    let isValid = true;
    let customFieldsNotFilled = false;
    // Checking for required custom fields here
    if (!_.isEmpty(customFields)) {
      const requiredFields = customFields.filter((field) => field.Required === 'true');
      requiredFields.map((req) => {
        if (!Object.keys(customFieldValuePairs).includes(req.CustomFieldName)) {
          customFieldsNotFilled = true;
        }
      });
    }
    const customFieldErrors = checkCustomFieldValue();
    if (!fields.firstName?.trim() || !fields[contact] || !fields.time ||
      errors.message || customFieldsNotFilled) {
      // Show mandatory fields error popup
      isValid = false;
      setErrorPopup(true);
      setPopupErrorMsg(translate('errors.mandatoryFieldsMsg'));
    } else if (!_.isEmpty(customFieldErrors)) {
      isValid = false;
      setErrorPopup(true);
      setPopupErrorMsg(translate('guestInvite.customFieldError',
        { customField: Object.keys(customFieldErrors)[0] }));
    } else if (
      contact === fieldTypes.EMAIL && (CommonUtility.isValidEmail(fields.email) === false)) {
      isValid = false;
      setErrors({ ...errors, email: translate('guestInvite.invalidEmail') });
      document.getElementById(GUEST_INVITE.EMAIL_FIELD)?.scrollIntoView(false);
    } else if (
      contact === fieldTypes.PHONE && (CommonUtility.isValidPhone(fields.phone) === false)) {
      isValid = false;
      setErrors({ ...errors, phone: translate('guestInvite.invalidPhone') });
      document.getElementById(GUEST_INVITE.PHONE_FIELD)?.scrollIntoView(false);
    } else if (_.isEqual(fields.selectedExpiryOption, expiryOptions[0])) { // days (1 to 8)
      const minExpiryDays = Math.ceil(guestSettings.minExpiry / 24);
      const maxExpiryDays = Math.ceil(guestSettings.maxExpiry / 24);
      if (fields.time < minExpiryDays || fields.time > maxExpiryDays) {
        isValid = false;
        setErrors({
          ...errors,
          time: translate('guestInvite.invalidExpiryDays',
            { min: minExpiryDays, max: maxExpiryDays }),
        });
        document.getElementById(GUEST_INVITE.EXPIRY_TIME_FIELD)?.scrollIntoView(false);
      }
    } else if (fields.time < guestSettings.minExpiry ||
      fields.time > 23) {
      // Hours must be specified between 1-23.
      isValid = false;
      setErrors({
        ...errors,
        time: translate('guestInvite.invalidExpiryHours',
          { min: guestSettings.minExpiry, max: 23 }),
      });
      document.getElementById(GUEST_INVITE.EXPIRY_TIME_FIELD)?.scrollIntoView(false);
    }
    setValidated(!isValid);
    return isValid;
  };

  const setData = () => {
    const data = {
      firstName: fields.firstName?.trim(),
      lastName: fields.lastName?.trim(),
      emailAddress: fields.email?.trim(),
      phoneNumber: fields.phone?.trim(),
      message: fields.message,
      customFieldValuePairs: JSON.stringify(customFieldValuePairs),
      userMode: fields.userMode?.value,
      toLanguage: fields.language?.value,
      accountExpiryHours: fields.timeHours,
      ignoreDuplicateEmail: false,
      callInviterAutomatically: fields.callOption,
    };
    return data;
  };

  const onSendClick = (event) => {
    event.preventDefault();
    if (validateInputs()) {
      const data = setData();
      sendGuestInvite(data);
    }
  };

  const cancelFromExistingUsersUI = () => {
    showExistingUsersUI(false);
    onCancelExistingUsers();
  };

  // On selecting users to add to the personal contacts from the existing users
  const onSelectContacts = (event) => {
    const contacts = contactsToAdd;
    if (event.target.checked) {
      contacts.push(event.target?.id);
      setContactsToAdd(contacts);
    } else {
      const filteredContacts = contacts.filter((contact) => contact !== event.target?.id);
      setContactsToAdd(filteredContacts);
    }
  };

  // Add to contacts button click
  const onAddContacts = () => {
    if (contactsToAdd.length > 0) {
      contactsToAdd.forEach((contact, index) => {
        if (index === contactsToAdd.length - 1) {
          addToContacts(contact, contactsToAdd.length);
        } else {
          addToContacts(contact);
        }
      });
    } else {
      setErrorPopup(true);
      setPopupErrorMsg(translate('guestInvite.addError'));
    }
  };

  // Whether to create the invite even if a user with that email address already exists.
  const sendInvite = () => {
    let data = setData();
    data = { ...data, ignoreDuplicateEmail: true };
    sendGuestInvite(data);
  };

  // Handle custom field dropdowns and also create an object to be sent to the API
  const handleCustomFields = (event, type) => {
    const tempCustomFieldValues = { ...customFieldValues, [type]: event };
    if (!event.value) {
      delete tempCustomFieldValues[type];
    }

    setCustomFields({ ...tempCustomFieldValues });

    const tempCustomFieldValuePairs = { ...customFieldValuePairs, [type]: event.value };
    if (!event.value) {
      delete tempCustomFieldValuePairs[type];
    }
    setCustomFieldValuePairs({ ...tempCustomFieldValuePairs });
  };

  // Buttons for existing users UI
  const renderContactButtons = () => (
    <Row className='m-0'>
      <Col lg={6} sm={3} md={3} xs={6} className='p-1 align-center'>
        <CustomButton
          className='cancel-btn w-100'
          onClick={(event) => cancelFromExistingUsersUI(event)}
          variant={BUTTON_TYPES.SECONDARY}
        >
          {translate('contacts.cancelAddContact')}
        </CustomButton>
      </Col>
      <Col lg={6} sm={4} md={4} xs={6} className='p-1 align-center'>
        <CustomButton
          className='add-btn w-100'
          onClick={(event) => sendInvite(event)}
        >
          {translate('guestInvite.sendInvite')}
        </CustomButton>
      </Col>
      <Col className='p-1 align-center'>
        <CustomButton
          className={`add-btn w-100 ${!canAddContact && 'disabled-state'}`}
          onClick={() => onAddContacts()}
        >
          {translate('guestInvite.addButton')}
        </CustomButton>
      </Col>
      <br />
      <br />
    </Row>
  );

  // Create existing users array and checking for their presence in the personal dir
  const getExistingUsers = () => {
    const existingUsersArray = [];
    existingUsers?.map((existingUser) => {
      if (personalContacts?.find((contact) => contact.uniqueId === existingUser.unique_id)) {
        existingUsersArray.push({ ...existingUser, isPersonalContact: true });
      } else {
        existingUsersArray.push({ ...existingUser, isPersonalContact: false });
        if (canAddContact === false) setCanAddContact(true);
      }
    });
    return existingUsersArray;
  };

  // Existing email id users UI
  const renderExistingUsers = (user) => (
    <Row className={`${user.isPersonalContact ? 'm-1 mt-2 disabled-state' : 'm-1 mt-2'}`}>
      <Col className='m-1' xs={8} md={9} sm={9} lg={8}>
        <Row className='existing-name' title={user.firstname + ' ' + user.lastname}>
          <span className='p-0 ellipsis'>
            {user.firstname}
            &nbsp;
            {user.lastname}
          </span>
        </Row>
        <Row className='existing-email' title={user.username}>
          <span className='p-0 ellipsis'>
            {user.username}
          </span>
        </Row>
      </Col>
      <Col className='align-center'>
        <CustomCheckbox
          id={user.unique_id}
          onCheckboxChange={(event) => onSelectContacts(event)}
          checked={!!user.isPersonalContact}
        />
      </Col>
    </Row>
  );
  // Handle already existing email id case
  const existingEmailUI = () => (
    <>
      <Form.Group className='guest-field mb-3' id='email_ui'>
        <Form.Label className='guest-field m-1'>
          {translate('guestInvite.existingUserMessage1', { address: existingUsers[0]?.email })}
        </Form.Label>
        <Form.Label className='guest-field m-1'>
          {translate('guestInvite.existingUserMessage2')}
        </Form.Label>
      </Form.Group>
      <div className='contact-divider mt-3' />
      <Form.Group className='mb-1'>
        <Form.Label className='m-1 mt-3 existing-email'>
          {translate('guestInvite.selectMessage')}
        </Form.Label>
      </Form.Group>
      {getExistingUsers().map((user) => renderExistingUsers(user))}
      {renderContactButtons()}
      {setVisibilty()}
      <br />
    </>
  );

  // Email or Phone no. based on the user's selection
  const renderOptions = (opt) => (
    <Form.Group className='mb-3'>
      <Form.Label className='guest-field'>
        {opt === radioOptions[0] ? translate('guestInvite.email') + '*' : translate('guestInvite.phone')}
      </Form.Label>
      <TextFieldComponent
        type={opt === radioOptions[0] ? 'text' : 'tel'}
        id={opt === radioOptions[0] ? GUEST_INVITE.EMAIL_FIELD : GUEST_INVITE.PHONE_FIELD}
        value={opt === radioOptions[0] ? fields.email : fields.phone}
        placeholder={opt === radioOptions[0] ? '' : translate('guestInvite.phonePlaceholder')}
        maxLength={100}
        className='field'
        inputRef={emailphoneRef}
        invalid={opt === radioOptions[0] ? (!!errors.email) : !!errors.phone}
        handleChange={(event) =>
          handleChange(event, opt === radioOptions[0] ? fieldTypes.EMAIL : fieldTypes.PHONE)}
        errorMessage={opt === radioOptions[0] ? errors.email : errors.phone}
      />
    </Form.Group>
  );

  // Dropdown common UI
  const renderDropdown = (id, options, selectedOption) => (
    <CustomDropdown
      id={id}
      type='select'
      className='cursor-pointer dropdn'
      options={options}
      selectedOption={selectedOption}
      handleChange={(event) => handleChange(event, id)}
    />
  );

  // Custom fields UI
  const renderCustomFields = (field, options) => (
    fields && options.length > 0 && (
      <Form.Group as={Row} className='mb-3' key={field.CustomFieldName}>
        <Col md={6} lg={6} sm={6} xs={6} className='align-self-center'>
          <Form.Label className='guest-field'>
            {field.CustomFieldName}
            {field.Required === 'true' && ' *'}
          </Form.Label>
        </Col>
        <Col md={6} lg={6} sm={6} xs={6}>
          <CustomSearchableDropdown
            id={field.CustomFieldName}
            options={options}
            selectedOption={customFieldValues[field.CustomFieldName] ?? {}}
            onChange={(event) => handleCustomFields(event, field.CustomFieldName)}
            onInputChange={(event) => handleCustomFields(event, field.CustomFieldName)}
          />
        </Col>
      </Form.Group>
    )
  );

  const renderButtons = () => (
    <div className='align-items-end-center mb-2'>
      <CustomButton
        className='cancel-btn mx-2'
        onClick={() => onCancelClick()}
        variant={BUTTON_TYPES.SECONDARY}
      >
        {translate('contacts.cancelAddContact')}
      </CustomButton>
      <CustomButton
        type='submit'
        className='add-btn mx-2'
      >
        {translate('guestInvite.send')}
      </CustomButton>
    </div>
  );

  const renderFormUI = () => (
    <CustomForm
      formId='guestInvite'
      onSubmit={(event) => onSendClick(event)}
      validated={validated}
    >
      <div className='px-2'>
        <Form.Group className='mb-3'>
          <Form.Label className='guest-field'>
            {translate('guestInvite.firstName')}
          </Form.Label>
          <TextFieldComponent
            type='text'
            id='guestInvite-firstname'
            value={fields.firstName}
            placeholder=''
            maxLength={100}
            className='field'
            inputRef={fnameRef}
            handleChange={(event) => handleChange(event, fieldTypes.FIRSTNAME)}
          />
        </Form.Group>
        <Form.Group className='mb-3'>
          <Form.Label className='guest-field'>
            {translate('guestInvite.lastName')}
          </Form.Label>
          <TextFieldComponent
            type='text'
            id='guestInvite-lastname'
            value={fields.lastName}
            placeholder=''
            maxLength={100}
            className='field'
            handleChange={(event) => handleChange(event, fieldTypes.LASTNAME)}
          />
        </Form.Group>
        <div className='contact-divider' />
        <Form.Group as={Row} className={`${policy?.allow_sms_invites === POLICY.YES ? 'mb-3 mt-1' : 'mb-3 disabled-state'}`}>
          <Form.Label column className='guest-field'>
            {translate('guestInvite.method')}
          </Form.Label>
          <Col className='mt-2'>
            <CustomRadioButton
              type='radio'
              id='email-option'
              className='mt-1'
              name='method'
              checked={fields.selectedOption === radioOptions[0]}
              onChange={(event) => handleChange(event, radioOptions[0])}
            />
            <span className='px-2 guest-field'>{translate('guestInvite.email')}</span>
          </Col>
          <Col className='mt-2'>
            <CustomRadioButton
              type='radio'
              id='sms-option'
              className='mt-1'
              name='method'
              checked={fields.selectedOption === radioOptions[1]}
              onChange={(event) => handleChange(event, radioOptions[1])}
            />
            <span className='px-2 guest-field'>{translate('guestInvite.sms')}</span>
          </Col>
        </Form.Group>
        {renderOptions(fields.selectedOption)}
        <Form.Group as={Row} className='mb-3'>
          <Form.Label column className='guest-field'>
            {translate('guestInvite.language')}
          </Form.Label>
          <Col>
            {renderDropdown(fieldTypes.LANGUAGE, GUEST_INVITE_LANGUAGES, fields.language)}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className={`${policy?.allow_setting_expiry === POLICY.YES ? 'mb-3' : 'mb-3 disabled-state'}`}>
          <Form.Label column className='guest-field pr-0'>
            {translate('guestInvite.expiry')}
          </Form.Label>
          <Col md={3} xs={4} sm={4} className='px-0'>
            <TextFieldComponent
              type='text'
              id={GUEST_INVITE.EXPIRY_TIME_FIELD}
              value={fields.time}
              placeholder=''
              maxLength={100}
              className='field mx-0'
              inputRef={timeRef}
              invalid={!!errors.time}
              handleChange={(event) => handleChange(event, fieldTypes.TIME)}
              errorMessage={errors.time}
              halfWidth
              disabled={policy?.allow_setting_expiry !== POLICY.YES}
            />
          </Col>
          <Col md={4} xs={4} sm={4} className='ps-0'>
            {renderDropdown(
              fieldTypes.SELECTED_EXPIRY_OPTION, expiryOptions, fields.selectedExpiryOption,
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className='mb-3'>
          <Form.Label column md='8' sm='8' xs='8' className='guest-field'>
            {translate('guestInvite.callOption')}
          </Form.Label>
          <Col md={4} sm={4} xs={4} className='pt-3 d-flex justify-content-end'>
            <Switch
              onChange={(event) => handleChange(event, fieldTypes.CALL_OPTION)}
              checked={fields.callOption}
              uncheckedIcon={false}
              checkedIcon={false}
              onColor={SWITCH_COMPONENT.SELECT_COLOR}
              onHandleColor={SWITCH_COMPONENT.BACKGROUND_COLOR}
              className={fields.callOption ? 'checked-button' : 'unchecked-button'}
              handleDiameter={SWITCH_COMPONENT.DIAMETER}
              height={SWITCH_COMPONENT.HEIGHT}
              width={SWITCH_COMPONENT.WIDTH}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className={`mb-3 ${policy?.allow_setting_user_mode === POLICY.YES ? '' : 'disabled-state'}`}>
          <Form.Label column className='guest-field '>
            {translate('guestInvite.userMode')}
          </Form.Label>
          <Col>
            {renderDropdown(fieldTypes.USERMODE, userModeOptions, fields.userMode)}
          </Col>
        </Form.Group>
        {
          customFields?.length > 0 && customFields?.map(
            (field) => renderCustomFields(field, field.CustomValue),
          )}
        <Form.Group className='mb-3'>
          <Form.Label className='guest-field'>
            {translate('guestInvite.message')}
          </Form.Label>
          <TextFieldComponent
            type='textarea'
            id='message'
            value={fields.message}
            placeholder=''
            className='field h-100'
            inputRef={messageRef}
            invalid={!!errors.message}
            handleChange={(event) => handleChange(event, fieldTypes.MESSAGE)}
            errorMessage={errors.message}
            rows={4}
          />
          <Form.Label className='guest-field'>
            <p className='char-count'>
              {`${translate('guestInvite.charCount')} (${fields.charCount})`}
            </p>
          </Form.Label>
        </Form.Group>
        {renderButtons()}
      </div>
    </CustomForm>
  );

  return (
    <>
      <Sidebar className='overflow-auto'>
        <Row lg={8} md={8} sm={8} className='m-1 page-header d-flex px-3'>
          <Col className='d-lg-none d-xl-none' xs={1}>
            <BackIcon
              className='back-icon'
              alt='back'
              role='presentation'
              onClick={existingUsersUI ?
                () => cancelFromExistingUsersUI() :
                () => onCancelClick()}
            />
          </Col>
          <Col className='contacts-header d-flex justify-content-center justify-content-lg-start ps-0 pe-4 p-lg-0'>
            {translate('guestInvite.title')}
          </Col>
        </Row>

        <div className='divider' />

        <Row className='guest-invite-margin guest-invite-scroll mx-0 px-1'>
          {existingUsersUI === true ? existingEmailUI() : renderFormUI()}
        </Row>
      </Sidebar>
      {openPopup === true && (
        <ErrorModal
          open={openPopup}
          header={translate('errors.serverErrorHeading')}
          errorMessage={popupMessage}
          onClose={() => closeErrorPopup()}
          rightButton={[
            {
              text: translate('login.okButton'),
              onClick: () => closeErrorPopup(),
            },
          ]}
        />
      )}

    </>
  );
}
export default GuestInvite;
