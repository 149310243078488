/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable newline-per-chained-call */
import axiosHelper from 'SERVICES/AxiosHelper';
import { ACTIONS } from 'UTILS/constants/ActionConstants';
import { LOCAL_STORAGE_KEY } from 'UTILS/constants/DOMElementConstants';
import CommonUtility from 'UTILS/CommonUtility';
import { CLIENT_PERMISSIONS, X_PATHS } from 'UTILS/constants/UtilityConstants';
import { onsightSysVersion, onsightProductVersion } from 'SERVICES/LSDatastream/Onsight';
import { isMobile, deviceType, mobileVendor, browserName, browserVersion, fullBrowserVersion } from 'react-device-detect';
import { create } from 'xmlbuilder2';

import {
  VIDEO_CONFIGS_FIELDS,
  AUDIO_CONFIGS_FIELDS,
  AUDIO_CONFIG_RESOLUTION,
  AUDIO_CONFIG_SAMPLE_RATE,
  AUDIO_CONFIG_RESOLUTION_CONVERTED,
  AUDIO_CONFIG_SAMPLE_RATE_CONVERTED,
  MEDIA_QUALITY,
  VIDEO_ASS_FACTOR,
  VIDEO_CODEC,
  VIDEO_ASS_MINIMUM_BITRATE,
  VIDEO_MEDIA_CONTENTS,
  VIDEO_SEQ_MODE,
  VIDEO_ASS_ENABLED,
  VIDEO_AUTO_SKIP,
  VIDEO_SOFT_START_ENABLED,
  AUDIO_SOURCE,
  AUDIO_CHANNEL,
  AUDIO_CODEC,
} from 'UTILS/constants/MediaServerConstants';
import { setClientPolicyAction } from 'STORE/CommonStoreActions';
import {
  API_METHODS,
  API_URIS,
} from '../../utils/constants/ApiConstants';

export function saveAuthInfoAction(authToken) {
  return (dispatch) => {
    dispatch({ type: ACTIONS.SET_AUTH_TOKEN, authToken });
  };
}

export function loginAction(UserName, Password, isSSO = false) {
  // authToken) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      // eslint-disable-next-line no-use-before-define
      callLoginApi(dispatch, resolve, reject, UserName, Password, isSSO);
    });
}

export function logoutAction() {
  return axiosHelper.logoutUser();
}

export const setNetworkConnectionFlag = (value) => (dispatch) => {
  dispatch({
    type: ACTIONS.NETWORK_CONNECTION_LOST,
    isNetworkLost: value,
  });
};

export const setVideoConfigs = (profileConfigs) => {
  const videoConfigs = profileConfigs.map((profileConfig) => ({
    assEnabled: VIDEO_ASS_ENABLED,
    assFactor: VIDEO_ASS_FACTOR,
    assMinimumBitRate: VIDEO_ASS_MINIMUM_BITRATE,
    autoSkip: VIDEO_AUTO_SKIP,
    bitRate: parseInt((profileConfig[VIDEO_CONFIGS_FIELDS.BITRATE]), 10),
    codec: VIDEO_CODEC,
    frameRate: parseInt(profileConfig[VIDEO_CONFIGS_FIELDS.FRAME_RATE], 10),
    gopSize: parseInt(profileConfig[VIDEO_CONFIGS_FIELDS.GOP_SIZE], 10),
    height: parseInt(profileConfig[VIDEO_CONFIGS_FIELDS.HEIGHT], 10),
    mediaContents: VIDEO_MEDIA_CONTENTS,
    peakBitRate: parseInt(profileConfig[VIDEO_CONFIGS_FIELDS.PEAK_BITRATE], 10),
    seqMode: VIDEO_SEQ_MODE,
    softStartEnabled: VIDEO_SOFT_START_ENABLED,
    streamQuality: MEDIA_QUALITY.indexOf(profileConfig[VIDEO_CONFIGS_FIELDS.STREAM_QUALITY]),
    width: parseInt(profileConfig[VIDEO_CONFIGS_FIELDS.WIDTH], 10),
    name: profileConfig.Name,
  }
  ));
  return videoConfigs;
};

export const setAudioConfigs = (profileConfigs) => {
  const audioConfigs = profileConfigs.map((profileConfig) => ({
    audioSource: AUDIO_SOURCE,
    bitRate: parseInt(profileConfig[AUDIO_CONFIGS_FIELDS.BITRATE], 10),
    channels: AUDIO_CHANNEL,
    codec: AUDIO_CODEC,
    gainPercent: parseInt(profileConfig[AUDIO_CONFIGS_FIELDS.GAIN_PERCENT], 10),
    resolution: profileConfig[AUDIO_CONFIGS_FIELDS.RESOLUTION] === AUDIO_CONFIG_RESOLUTION ?
      AUDIO_CONFIG_RESOLUTION_CONVERTED :
      profileConfig[AUDIO_CONFIGS_FIELDS.RESOLUTION],
    sampleRate: profileConfig[AUDIO_CONFIGS_FIELDS.AUDIO_SAMPLE_RATE] === AUDIO_CONFIG_SAMPLE_RATE ?
      AUDIO_CONFIG_SAMPLE_RATE_CONVERTED :
      profileConfig[AUDIO_CONFIGS_FIELDS.AUDIO_SAMPLE_RATE],
    streamQuality: MEDIA_QUALITY.indexOf(profileConfig[AUDIO_CONFIGS_FIELDS.STREAM_QUALITY]),
  }
  ));
  return audioConfigs;
};

/**
 * @param  {} callback
 * @param  {} dispatch
 */

export const clearAllReducer =
  (callback = () => { }) =>
    (dispatch) => {
      dispatch({
        type: ACTIONS.CLEAR_STORE,
      });
      callback();
    };

/**
 * @param  {} dispatch
 * @param  {} resolve
 * @param  {} reject
 * @param  {} UserName
 * @param  {} Password
 */

function callLoginApi(dispatch, resolve, reject, UserName, Password, isSSO) {
  const iV = localStorage.getItem(`${UserName}${LOCAL_STORAGE_KEY.IV}`);
  let previousSessionId = '';
  if (iV && localStorage.getItem(UserName) && Object.keys(localStorage).includes(UserName)) {
    CommonUtility.decrypt(Password, localStorage.getItem(UserName), iV).then(
      (sessionIdBuffer) => {
        previousSessionId = CommonUtility.arrayBufferToString(sessionIdBuffer);
        loginAxiosCall(
          dispatch,
          resolve,
          reject,
          UserName,
          Password,
          previousSessionId,
          isSSO,
        );
      },
    ).catch((error) => {
      console.error('Auth Action:: Decryption failed due to invalid input!', error, 'errors.invalidUsernameOrPassword');
      // If user entered wrong password than the previous used password for encryption,
      // we need to login user with entered password without prev session id (#472)
      loginAxiosCall(
        dispatch,
        resolve,
        reject,
        UserName,
        Password,
        previousSessionId,
        isSSO,
      );
    });
  } else {
    loginAxiosCall(
      dispatch,
      resolve,
      reject,
      UserName,
      Password,
      previousSessionId,
      isSSO,
    );
  }
}
/**
 * @param  {} dispatch
 * @param  {} resolve
 * @param  {} reject
 * @param  {} UserName
 * @param  {} Password
 * @param  {} previousSessionId
 */

export const loginAxiosCall = (
  dispatch,
  resolve,
  reject,
  UserName,
  Password,
  previousSessionId,
  isSSO,
) => {
  const localSysVersion = onsightSysVersion();
  const deviceInfoXml = create({ version: '1.0', encoding: 'UTF-8', standalone: 'yes' })
    .ele('device_info')
    .ele('manufacturer').txt(isMobile ? mobileVendor : '').up()
    .ele('hardware').txt(localSysVersion.appVer.hwInfo.hw).up()
    .ele('device').txt(deviceType).up()
    .ele('model').txt(localSysVersion.appVer.hwInfo.model).up()
    .ele('sdk_level').txt('0').up()
    .ele('sdk_version').up()
    .ele('os_type').txt(localSysVersion.appVer.hwInfo.os).up()
    .ele('os_version').txt(localSysVersion.appVer.hwInfo.osVersion).up()
    .ele('os_version_ext').txt(localSysVersion.appVer.hwInfo.osExt).up()
    .ele('browser_name').txt(browserName).up()
    .ele('browser_version').txt(fullBrowserVersion).up()
    .ele('gpu_vendor').txt(CommonUtility.getGpuInfo().vendor).up()
    .ele('gpu_chipset').txt(CommonUtility.getGpuInfo().chipset).up()
    .up()
    .end({ prettyPrint: false });

  axiosHelper
    .callAPI({
      httpMethod: API_METHODS.POST,
      uri: API_URIS.LOGIN,
      requestHeaders: null,
      requestBody: {
        UserName,
        Password: !isSSO ? Password : '',
        token: isSSO ? Password : '',
        hostName: browserName + ' ' + browserVersion,
        ProductId: localSysVersion.appVer.product,
        ProductVersion: onsightProductVersion(),
        DeviceInfo: deviceInfoXml,
        OfflineTokenCredentials: '',
        previousSessionId,
        allowCaptureModeLogin: false,
      },
    })
    .then((res) => {
      const IV = window.crypto.getRandomValues(new Uint8Array(16));
      console.debug('AuthAction:: api response', res);
      if (res.data.sessionId !== '') {
        CommonUtility.encrypt(Password, res.data.sessionId, IV).then(
          (encdata) => {
            localStorage.setItem(UserName, CommonUtility.arrayBufferToString(encdata));
            localStorage.setItem(`${UserName}${LOCAL_STORAGE_KEY.IV}`, IV);
            dispatch({
              type: ACTIONS.SET_AUTH_TOKEN,
              authToken: res.data.sessionId,
            });
          },
        ).catch((error) => {
          console.error('AuthAction:: Error while encrypting sessionid', error);
        });
      }
      const userXML = CommonUtility.convertXmlToJSONGeneric(
        res.data.userXml,
        X_PATHS.USER_XML,
      );
      const clientSettingsXML = CommonUtility.convertXmlToJSONGeneric(
        res.data.clientSettingsXml,
        X_PATHS.CLIENT_SETTING,
      );
      const clientSessionConfigInfoXML = CommonUtility.convertXmlToJSONGeneric(
        res.data.clientSessionConfigInfoXml,
        X_PATHS.CLIENT_SESSION_CONFIG,
      );
      // video profiles
      const videoProfilesXml = CommonUtility.convertXmlToJSONGeneric(
        res.data.clientPolicyXml,
        X_PATHS.CLIENT_POLICY_VIDEO_PROFILES,
        );
      const profiles = CommonUtility.convertXmlToJSONGeneric(
        CommonUtility.cleanXML(videoProfilesXml.profiles),
        X_PATHS.VIDEO_PROFILES,
        );
      const videoProfilesArray = [];
      if (profiles) {
        const { SQLow, SQMed, SQHigh, SQHD, SQFULLHD } =
          CommonUtility.convertToClientPermissions(res.data.clientPolicyXml, CLIENT_PERMISSIONS);
        Object.values(profiles).forEach((videoProfiles) => {
          // filter profiles based on client permissions
          if ((videoProfiles.StreamQuality === 'SQLow' && SQLow) ||
            (videoProfiles.StreamQuality === 'SQMed' && SQMed) ||
            (videoProfiles.StreamQuality === 'SQHigh' && SQHigh) ||
            (videoProfiles.StreamQuality === 'SQHD' && SQHD) ||
            (videoProfiles.StreamQuality === 'SQFULLHD' && SQFULLHD) ||
            (videoProfiles.StreamQuality === 'SQCustom')) {
              videoProfilesArray.push(videoProfiles);
          }
        });
      }
      if (userXML && userXML.uri) {
        dispatch({
          type: ACTIONS.SET_USER_NAME,
          loggedInUserName: userXML.username,
        });
      }

      dispatch({
        type: ACTIONS.SET_CUSTOMER_INFO,
        customerUniqueId: res.data.customerUniqueId,
        customerName: res.data.customerName,
      });
      dispatch({
        type: ACTIONS.SET_USER_XML_DATA,
        userData: userXML,
      });
      dispatch({
        type: ACTIONS.SET_SIP_CLIENT_SETTINGS_XML,
        clientSettings: clientSettingsXML,
      });
      dispatch({
        type: ACTIONS.SET_CLIENT_SESSION_CONFIG_INFO,
        clientSessionConfigInfo: clientSessionConfigInfoXML[0],
      });
      dispatch({
        type: ACTIONS.SET_LOGIN_REQUEST_TIMESTAMP,
        loginRequestTimestamp: res.data.RequestTimestampUtc,
      });
      dispatch({
        type: ACTIONS.SET_VIDEO_MEDIA_CONFIG,
        videoProfileData: videoProfilesArray,
      });
      const videoConfigs = setVideoConfigs(videoProfilesArray);
      dispatch({
        type: ACTIONS.SET_VIDEO_MEDIA_CONFIGS,
        videoMediaConfigs: videoConfigs,
      });
      dispatch({
        type: ACTIONS.SET_AUDIO_MEDIA_CONFIGS,
        audioMediaConfigs: setAudioConfigs(videoProfilesArray),
      });

      // Set default selected as lower built-in, or first custom profile
      if (videoConfigs.length > 0) {
        dispatch({
          type: ACTIONS.SET_SELECTED_VIDEO_PROFILE,
          selectedVideoProfile: videoConfigs[CommonUtility.getDefaultProfileIndex(videoConfigs)],
        });
      }

      setClientPolicyAction(res, dispatch);
      resolve(res);
    })
    .catch((error) => {
      console.error('error :---->', error);
      reject(error);
    });
};
/**
 * @param  {} meetingId
 * @param  {} firstName
 * @param  {} lastName
 * @param  {} emailAddr
 */
export const createGuestUser = (
  meetingId,
  firstName,
  lastName,
  guestEmailId,
) => () =>
    new Promise((resolve, reject) => {
      // eslint-disable-next-line no-use-before-define
      axiosHelper
        .callAPI({
          httpMethod: API_METHODS.POST,
          uri: API_URIS.GUEST_INVITE_MEETING,
          requestHeaders: null,
          requestBody: {
            meetingId,
            firstName,
            lastName,
            emailAddress: guestEmailId,
          },
        })
        .then((res) => {
          resolve(res);
        }).catch((error) => {
          console.error('AuthAction::error while creating guest user', JSON.stringify(error));
          reject(error);
        });
    });

export function changePasswordAction(userName, oldPassword, newPassword) {
  return () => new Promise((resolve, reject) => {
    axiosHelper
      .callAPI({
        httpMethod: API_METHODS.PUT,
        uri: API_URIS.CHANGE_PASSWORD,
        requestBody: {
          userName,
          oldPassword,
          newPassword,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.error('Error in change password', error);
        reject(error);
      });
  });
}

export function getWebRtcTokenAction() {
  return (dispatch) => new Promise((resolve, reject) => {
    axiosHelper
      .callAPI({
        httpMethod: API_METHODS.GET,
        uri: API_URIS.WEBRTC_TOKEN,
      })
      .then((res) => {
        dispatch({
          type: ACTIONS.SET_WEBRTC_TOKEN,
          webRtcToken: res.data,
        });
        resolve(res);
      })
      .catch((error) => {
        console.error('Error getting webrtc token', error);
        reject(error);
      });
  });
}

export function setLaunchParametersAction(launchParameters) {
  return (dispatch) => {
    dispatch({
      type: ACTIONS.SET_LAUNCH_PARAMETERS,
      launchParameters,
    });
  };
}
