import NlpManager from 'SERVICES/NLP/NlpManager';

// Services
import { AppLogger, LOG_NAME } from 'SERVICES/Logging/AppLogger';

const logger = AppLogger(LOG_NAME.NLPHelper);

export default class NlpHelper {
  constructor(options) {
    logger.debug('NlpHelper constructor');
    this.nlpManager = new NlpManager();
    this.callback = options.callback;
    this.authToken = options.authToken;
    this.toLanguage = options.toLanguage;
    this.fromLanguage = options.fromLanguage;
    this.nlpUrl = options.nlpUrl;
  }

  sendAudioToNLP = (stream) => {
  // start sending remote audio to nlp
    if (window.dynamicEnv.REACT_APP_ALLOW_NLP !== 'true' || !this.nlpManager) {
      logger.debug('NlpHelper::sendAudioToNLP() not sending, returning');
      return;
    }
    logger.debug('NlpHelper::sendAudioToNLP()');
    const audioStream = new MediaStream();
    audioStream.addTrack(stream.getAudioTracks()[0]);
    this.nlpManager.startSendingRemoteAudioToNlpServer({
      audioStream,
      serverUrl: this.nlpUrl,
      hubToken: this.authToken,
      wordCallback: this.onNlpWord,
      sentenceCallback: this.onNlpSentence,
      errorCallback: this.onNlpError,
      toLang: this.toLanguage,
      fromLang: this.fromLanguage,
    });
  }

  stopSendingRemoteAudioToNlpServer = () => {
    if (this.nlpManager && window.dynamicEnv.REACT_APP_ALLOW_NLP === 'true') {
      this.nlpManager.stopSendingRemoteAudioToNlpServer();
      this.nlpManager = null;
    }
  }

  onNlpWord = (word) => {
    const wordObj = JSON.parse(word);
    logger.debug('NlpHelper::onNlpWord() receivied word:', word);
    if (typeof this.callback === 'function') {
      this.callback({ captionStr: wordObj.text, isNLPWord: true });
    }
  }

  onNlpSentence = (sentence) => {
    const lineObj = JSON.parse(sentence);
    logger.debug('NlpHelper::onNlpSentence() receivied word:', sentence);
    if (typeof this.callback === 'function') {
      this.callback({ captionStr: lineObj.text, isNLPWord: false });
    }
  }

  onNlpError = (error) => {
    logger.warn('NlpHelper::onNlpError() got error callback:', error);
    this.nlpManager = null;
  }

  restartAudio(fromLang, toLang) {
    if ((this.fromLanguage !== fromLang) || (this.toLanguage !== toLang)) {
      this.nlpManager?.restartAudio(fromLang, toLang);
      this.fromLanguage = fromLang;
      this.toLanguage = toLang;
    }
  }

  getNlpManager = () => this.nlpManager;
}
