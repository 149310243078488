/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';

import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

// component
import Sidebar from 'COMPONENTS/Dashboard/Sidebar';

// Translation
import { translate } from 'SERVICES/i18n';

// Asset
import { ReactComponent as BackIcon } from 'ASSETS/icon_back_arrow.svg';
// Action
import { logoutAction } from 'STORE/Auth/AuthAction';
// constants
import { ICON_NAMES } from 'UTILS/constants/HeaderConstants';

// Style
import './Header.scss';

class MoreActions extends Component {
  logout = () => {
    console.info('logout');
    // Added code to end call before logout for mobile devices
    // TODO: Make changes to have all logout related code in one place like AppManager
    // and pass it to other components so clean up like end call can be made in one place
    const logoutHandler = this.props.endCallAndLogout ?
      this.props.endCallAndLogout : logoutAction;
    logoutHandler();
  };

  render() {
    return (
      <Sidebar
        isFromCallDashboard={this.props.isCallDashboard}
      >
        <Row lg={8} md={8} sm={8} xs={8} className='landscape-side-margins pt-2 px-3'>
          <Col className='d-lg-none d-xl-none' xs={1} sm={1} lg={1} md={1}>
            <BackIcon
              className='back-icon'
              alt='back'
              role='presentation'
              onClick={this.props.goBack}
            />
          </Col>
          <Col className='alignment'>
            <span className='more-actions-header ps-1'>
              {translate('headerMenus.moreActions')}
            </span>
          </Col>

        </Row>
        <Row className='px-2'>
          <Col lg={12} md={12} sm={12} xs={12}>
            <div className='divider' />
          </Col>
        </Row>
        {this.props.iconSet.dropdownDetails.map((data) => (
          <Row
            lg={8}
            md={8}
            sm={8}
            className={`mt-3 alignment landscape-side-margins more-action-items px-3 ${data.disabled ? ' disabled' : ''}`}
            key={data.name}
            hidden={data.hidden}
          >
            <div
              role='presentation'
              onClick={() =>
                data.name === ICON_NAMES.LOGOUT
                  ? this.logout()
                  : this.props.clickHandler(data.id)
              }
              className='pl-2 align-horizontal-start'
              id={data.id}
            >
              <span className='svg-icon'>
                <data.icon className={data.className} />
              </span>
              <span className='ps-3 more-action-items'>
                {data?.name}
              </span>
            </div>
          </Row>
        ))
        }
      </Sidebar>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {},
    dispatch,
  );
}
export default connect(null, mapDispatchToProps)(MoreActions);
