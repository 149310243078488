// eslint-disable-next-line no-undef
export const ACTIONS = {
  SET_USER_PROFILE: 'SET_USER_PROFILE',
  SET_AUTH_TOKEN: 'SET_AUTH_TOKEN',
  SET_THEME: 'SET_THEME',
  CLEAR_THEME: 'CLEAR_THEME',
  SET_LANGUAGE: 'SET_LANGUAGE',
  ADD_CONTACT: 'ADD_CONTACT',
  SET_DEVICE_LIST: 'SET_DEVICE_LIST',
  CLEAR_DEVICE_LIST: 'CLEAR_DEVICE_LIST',
  CLEAR_STORE: 'CLEAR_STORE',
  SET_USER_NAME: 'SET_USER_NAME',
  SET_CUSTOMER_INFO: 'SET_CUSTOMER_INFO',
  SET_VIDEO_ON_OFF: 'SET_VIDEO_ON_OFF',
  SET_AUDIO_ON_OFF: 'SET_AUDIO_ON_OFF',
  ADD_PARTICIPANTS: 'ADD_PARTICIPANTS',
  CLEAR_PARTICIPANTS: 'CLEAR_PARTICIPANTS',
  SET_SELECTED_ICON: 'SET_SELECTED_ICON',
  SET_SELECTED_ILLUMINATION_ITEM: 'SET_SELECTED_ILLUMINATION_ITEM',
  SET_VIDEO_CALL_PLUGIN_HANDLE: 'SET_VIDEO_CALL_PLUGIN_HANDLE',
  SET_SIP_CALL_PLUGIN_HANDLE: 'SET_SIP_CALL_PLUGIN_HANDLE',
  CLEAR_VIDEO_CALL_PLUGIN_HANDLE: 'CLEAR_VIDEO_CALL_PLUGIN_HANDLE',
  CLEAR_SIP_CALL_PLUGIN_HANDLE: 'CLEAR_SIP_CALL_PLUGIN_HANDLE',
  SET_VIDEO_DEVICES: 'SET_VIDEO_DEVICES',
  SET_VIDEO_DEVICE: 'SET_VIDEO_DEVICE',
  SET_AUDIO_INPUT_DEVICE: 'SET_AUDIO_INPUT_DEVICE',
  SET_AUDIO_OUTPUT_DEVICE: 'SET_AUDIO_OUTPUT_DEVICE',
  SET_BRAND: 'SET_BRAND',
  SET_USER_XML_DATA: ' SET_USER_XML_DATA',
  SET_SIP_USER: 'SET_SIP_USER', // temporary to get sip user from query parameter
  SET_SIP_CLIENT_SETTINGS_XML: 'SET_SIP_CLIENT_SETTINGS_XML',
  SET_GLOBAL_CONTACTS: 'SET_GLOBAL_CONTACTS',
  SET_PERSONAL_CONTACTS: 'SET_PERSONAL_CONTACTS',
  CLEAR_GLOBAL_CONTACTS: 'CLEAR_GLOBAL_CONTACTS',
  SET_POLICY_UI_SETTINGS: 'SET_POLICY_UI_SETTINGS',
  SET_IS_INCOMING_CALL: 'SET_IS_INCOMING_CALL',
  SET_SHOW_VIDEO_FALLBACK: 'SET_SHOW_VIDEO_FALLBACK',
  NETWORK_CONNECTION_LOST: 'NETWORK_CONNECTION_LOST',
  SET_BRAND_URL: 'SET_BRAND_URL',
  IS_DEFAULT_THEME: 'IS_DEFAULT_THEME',
  SET_SESSION_EXPIRED_FLAG: 'SET_SESSION_EXPIRED_FLAG',
  SET_CLIENT_SESSION_CONFIG_INFO: 'SET_CLIENT_SESSION_CONFIG_INFO',
  SET_CLIENT_POLICY_DATA: 'SET_CLIENT_POLICY_DATA',
  SET_LOGIN_REQUEST_TIMESTAMP: 'SET_LOGIN_REQUEST_TIMESTAMP',
  SET_CLIENT_POLICY_REQUEST_TIMESTAMP: 'SET_CLIENT_POLICY_REQUEST_TIMESTAMP',
  SET_PROFILE_REQUEST_TIMESTAMP: 'SET_PROFILE_REQUEST_TIMESTAMP',
  SET_CLIENT_PERMISSIONS: 'SET_CLIENT_PERMISSIONS',
  SET_VIDEO_MEDIA_CONFIG: 'SET_VIDEO_MEDIA_CONFIG',
  SET_SELECTED_VIDEO_PROFILE: 'SET_SELECTED_VIDEO_PROFILE',
  SET_NETWORK_SETTINGS: 'SET_NETWORK_SETTINGS',
  SET_VIDEO_MEDIA_CONFIGS: 'SET_VIDEO_MEDIA_CONFIGS',
  SET_AUDIO_MEDIA_CONFIGS: 'SET_AUDIO_MEDIA_CONFIGS',
  SET_SECURITY_SETTINGS: 'SET_SECURITY_SETTINGS',
  SET_INVITER_ID: 'SET_INVITER_ID',
  SET_STATISTICS_CALLS: 'SET_STATISTICS_CALLS',
  CLEAR_STATISTICS_CALLS: 'CLEAR_STATISTICS_CALLS',
  SET_NLP_URL: 'SET_NLP_URL',
  SET_ASK_AND_SEARCH_PARAMS: 'SET_ASK_AND_SEARCH_PARAMS',
  SET_BRAND_RESOURCE_URL: 'SET_BRAND_RESOURCE_URL',
  SET_SCREEN_SHARE_ON: 'SET_SCREEN_SHARE_ON',
  SET_VIDEO_PAUSE: 'SET_VIDEO_PAUSE',
  SET_WEBRTC_TOKEN: 'SET_WEBRTC_TOKEN',
  SET_WORKSPACE_SETTINGS: 'SET_WORKSPACE_SETTINGS',
  SET_DEMO_MODE: 'SET_DEMO_MODE',
  SET_LAUNCH_PARAMETERS: 'SET_LAUNCH_PARAMETERS',
  SET_MEETING_SETTINGS: 'SET_MEETING_SETTINGS',
  SET_VIDEO_OVERLAY_SETTINGS: 'SET_VIDEO_OVERLAY_SETTINGS',
  SET_STREAM_SHARE_STATE: 'SET_STREAM_SHARE_STATE',
  SET_IMAGE_SHARE_MODE: 'SET_IMAGE_SHARE_MODE',
  INIT_STATE: 'INIT_STATE',
  SET_CALL_STATUS: 'SET_CALL_STATUS',
  SET_GUEST_INVITE_POLICY: 'SET_GUEST_INVITE_POLICY',
  SET_GUEST_CUSTOM_FIELDS: 'SET_GUEST_CUSTOM_FIELDS',
  SET_CUSTOM_MESSAGE: 'SET_CUSTOM_MESSAGE',
  SET_CUSTOM_MESSAGE_DISABLE: 'SET_CUSTOM_MESSAGE_DISABLE',
  SET_IMAGE_SETTINGS: 'SET_IMAGE_SETTINGS',
};

export const EMTRY_ACTIONS = {
  SET_USER_PROFILE: 'SET_USER_PROFILE',
};
