import {
  SS_MEDIA_CONFIG,
} from 'UTILS/constants/MediaServerConstants';

import {
  STREAM_SHARE_STATE,
} from 'UTILS/constants/UtilityConstants';

/**
 * Initializes screen share profile parameters from environment variables
 * happens only the first time in the webapp session
 */
// eslint-disable-next-line import/prefer-default-export
export function initScreenShareMediaProfile() {
  const ssMediaProfileEnv = window.dynamicEnv.REACT_APP_SS_MEDIA_PROFILE;
  const profile = SS_MEDIA_CONFIG;
  try {
    console.debug('SSController::initScreenShareMediaProfile() ssMediaProfile object:', ssMediaProfileEnv);
    profile.width = ssMediaProfileEnv.width ??
    profile.width;
    profile.height = ssMediaProfileEnv.height ??
    profile.height;
    profile.frameRate = ssMediaProfileEnv.frameRate ??
    profile.frameRate;
    profile.bitrate = ssMediaProfileEnv.bitrate ??
    profile.bitrate;
    console.debug('SSController::initSetScreenShareMediaProfile() final ssMediaProfile:', profile);
  } catch (error) {
    console.warn('SSController::initScreenShareMediaProfile() error in setting SS media profile:', error);
  }
  return profile;
}

export function isStreamRequested(streamShareState) {
  return (streamShareState !== STREAM_SHARE_STATE.READY) &&
    (streamShareState !== STREAM_SHARE_STATE.STREAMING_STOPPED &&
    (streamShareState !== STREAM_SHARE_STATE.STREAMING_STARTED));
}

export function isStreamStarted(streamShareState) {
  return streamShareState === STREAM_SHARE_STATE.STREAMING_STARTED;
}

export function readyToShare(streamShareState) {
  return (streamShareState !== STREAM_SHARE_STATE.UNKNOWN);
}
