/* eslint-disable react/prop-types */
import React from 'react';

// constants
import { SETTINGS_MENU } from 'UTILS/constants/UtilityConstants';

// components
import MyProfile from './MyProfile';
import General from './General';
import Calls from './Calls';
import VideoQuality from './VideoQuality';
import BandwidthControl from './BandwidthControl';

class ExpandedComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let Component = null;
    let data = {};
    switch (this.props.selection) {
      case SETTINGS_MENU[0].id:
        data = {};
        Component = MyProfile;
        data.userData = this.props.userData;
        data.clientSettingsData = this.props.clientSettingsData;
        break;
      case SETTINGS_MENU[1].id:
        data = {};
        Component = General;
        data.securitySettings = this.props.securitySettings;
        break;
      case SETTINGS_MENU[2].id:
        data = {};
        Component = Calls;
        data.policyUISettings = this.props.policyUISettings;
        data.securitySettings = this.props.securitySettings;
        break;
      case SETTINGS_MENU[3].id:
        data = {};
        Component = VideoQuality;
        data.selectedVideoProfile = this.props.selectedVideoProfile;
        data.setSelectedProfile = (p) => this.props.setSelectedProfile(p);
        data.videoProfiles = this.props.videoProfiles;
        data.clientPolicyPermissionsXmlData = this.props.clientPolicyPermissionsXmlData;
        break;
      case SETTINGS_MENU[4].id:
        data = {};
        Component = BandwidthControl;
        data.networkSettings = this.props.networkSettings;
        break;
      default: Component = MyProfile;
        break;
    }

    return <div><Component data={data} /></div>;
  }
}

export default ExpandedComponent;
