/* eslint-disable react/prop-types */
/* eslint-disable no-unsafe-optional-chaining */
import React, { Component } from 'react';
import RangeSlider from 'react-rangeslider';
import { Row, Col, Form } from 'react-bootstrap';

// Translation
import { translate } from 'SERVICES/i18n';

// Custom component
import CustomForm from 'COMPONENTS/CustomComponents/CustomForm/CustomForm';
import Switch from 'react-switch';
import { isMobileOnly } from 'react-device-detect';

// Constants
import { UNITS, RANGE_SLIDER_SIZES, RANGE_SLIDER_TOOLTIP, SWITCH_COMPONENT } from 'UTILS/constants/UtilityConstants';

// Style
import './Settings.scss';
import '../../resources/styles/globalStyles.scss';
import 'react-rangeslider/lib/index.css';

// component
import Sidebar from 'COMPONENTS/Dashboard/Sidebar';

// Asset
import { ReactComponent as BackIcon } from 'ASSETS/icon_back_arrow.svg';

export default class BandwidthControl extends Component {
  constructor(props) {
    super(props);

    const PERMISSION_NO = 'no';

    this.state = {
      bandwidthControl: this.props?.data ?
        (this.props.data?.networkSettings?.bandwidth_control_enabled !== PERMISSION_NO)
        : (this.props?.networkSettings?.bandwidth_control_enabled !== PERMISSION_NO),
    };
  }

  toggleSwitch = () => {
    this.setState((prevState) => ({ bandwidthControl: prevState.bandwidthControl + 1 }));
  }

  renderSlider = (nw) => (
    <RangeSlider
      orientation='horizontal'
      size={RANGE_SLIDER_SIZES.SMALL}
      min={UNITS.MIN_BIT_RATE_KBPS}
      max={UNITS.MAX_BIT_RATE_KBPS}
      tooltip={RANGE_SLIDER_TOOLTIP.OFF}
      value={nw?.bandwidth_control_maximum / UNITS.CONVERSION_VALUE}
    // convert 'BPS' to 'kbps' by dividing BPS/1000
    />
  )

  mobileView = (nw) => (
    <>
      <Row>
        <Col sm={8} xs={8}>
          <span className='p-0 font-semi'>
            {translate('settings.MaximumBitRate')}
          </span>
        </Col>
        <Col sm={2} xs={2} className='align-right p-0 disabled-state'>
          <span className='bandwidth-value-mobile p-1'>
            {nw?.bandwidth_control_maximum ?
              nw?.bandwidth_control_maximum / UNITS.CONVERSION_VALUE :
              UNITS.CONVERSION_VALUE
            }
          </span>
        </Col>
        <Col sm={2} xs={2} className='align-right p-0 disabled-state'>
          <span className='bandwidth-unit-mobile'>
            {UNITS.KBPS}
          </span>
        </Col>
      </Row>
      <Row sm={12} xs={12} className='disabled-state pt-2 align-right'>
        <div>
          {this.renderSlider(nw)}
        </div>
      </Row>
    </>
  )

  webView = (nw) => (
    <Row className='align-center'>
      <Col md={4} lg={4} sm={4}>
        <span className='p-0 font-semi'>
          {translate('settings.MaximumBitRate')}
        </span>
      </Col>
      <Col md={4} lg={5} sm={4} className='p-0 pt-1 disabled-state'>
        {this.renderSlider(nw)}
      </Col>
      <Col md={2} lg={2} sm={2} className='align-right disabled-state'>
        <span className='bandwidth-value p-1'>
          {nw?.bandwidth_control_maximum ?
            nw?.bandwidth_control_maximum / UNITS.CONVERSION_VALUE :
            UNITS.CONVERSION_VALUE
          }
        </span>
      </Col>
      <Col md={1} lg={1} sm={1} className='align-right disabled-state'>
        <span className='bandwidth-unit'>
          {UNITS.KBPS}
        </span>
      </Col>
    </Row>
  )

  renderContent = (nw) => (
    <Row className={`px-3 ${isMobileOnly && 'landscape-side-margins mt-3'}`}>
      <CustomForm
        formId='bandwidthControl'
      >
        <Form.Group className='p-2'>
          <Row>
            <Col md={8} lg={8} sm={9} xs={10}>
              <Form.Label>
                <span className='p-0 font-regular'>
                  {translate('settings.bandwidthControlText1')}
                </span>
              </Form.Label>
            </Col>
            <Col className='align-horizontal-end self-align-center disabled-state align-right p-0'>
              <Switch
                onChange={this.toggleSwitch}
                checked={this.state.bandwidthControl}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor={SWITCH_COMPONENT.SELECT_COLOR}
                onHandleColor={SWITCH_COMPONENT.BACKGROUND_COLOR}
                className={
                  this.state.bandwidthControl
                    ? 'checked-button'
                    : 'unchecked-button'
                }
                handleDiameter={SWITCH_COMPONENT.DIAMETER}
                height={SWITCH_COMPONENT.HEIGHT}
                width={SWITCH_COMPONENT.WIDTH}
              />
            </Col>
          </Row>
        </Form.Group>

        <Form.Group className='p-2'>
          <Row>
            <Form.Label>
              {isMobileOnly ? this.mobileView(nw) : this.webView(nw)}
            </Form.Label>
          </Row>
        </Form.Group>
      </CustomForm>
    </Row>
  )

  render() {
    const nw = this.props.data?.networkSettings ?
      this.props.data?.networkSettings :
      this.props?.networkSettings;

    return (
      <div className={`bandwidth-control  h-100 ${this.props.isCallDashboard ? 'position-absolute' : ''}`}>
        {(isMobileOnly) ?
          /** Mobile view */
          (
            <Sidebar>
              <Row lg={8} md={8} sm={8} xs={8} className={`pt-2 px-3  ${isMobileOnly && 'landscape-side-margins'}`}>
                <Col className='d-lg-none d-xl-none' xs={1} sm={1} lg={1} md={1}>
                  <BackIcon
                    className='back-icon'
                    alt='back'
                    role='presentation'
                    onClick={this.props.goBack}
                  />
                </Col>
                <Col className='alignment'>
                  <span className='header-label ps-1'>
                    {translate('settings.bandwidthControl')}
                  </span>
                </Col>

              </Row>
              <Row className='px-2'>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className='divider' />
                </Col>
              </Row>
              {this.renderContent(nw)}
            </Sidebar>
          ) :
          /** Web view */
          (
            <div>
              {this.renderContent(nw)}
            </div>
          )}
      </div>
    );
  }
}
