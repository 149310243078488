/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';

// Modal
import Modal from 'react-responsive-modal';

// React bootstrap
import { Row, Col } from 'react-bootstrap';

// CSS
import './Settings.scss';

// Translation
import { translate } from 'SERVICES/i18n';

// Asset
import { ReactComponent as CloseIcon } from 'ASSETS/icon_close.svg';
import { ReactComponent as BackIcon } from 'ASSETS/icon_back_arrow.svg';

// constants
import { SETTINGS_MENU, SETTINGS } from 'UTILS/constants/UtilityConstants';

import { isMobileOnly, isDesktop } from 'react-device-detect';

// component
import SideNav from 'COMPONENTS/Dashboard/SideNav';
import ErrorModal from 'COMPONENTS/CustomComponents/ErrorDialogModal/ErrorModal';
import CustomButton from 'COMPONENTS/CustomComponents/CustomButton/CustomButton';
import Sidebar from 'COMPONENTS/Dashboard/Sidebar';
import ExpandedComponent from './ExpandedComponent';

class Settings extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.isOpen,
      showErrorPopup: false,
      selectedMenu: SETTINGS_MENU[0].id,
    };
  }

  handleDismissError = () => {
    this.setState({
      showErrorPopup: false,
    });
  };

  // eslint-disable-next-line react/no-unused-class-component-methods, default-param-last
  setError = (message, isServerError = false, heading) => {
    console.error(message, 'err message');
    this.setState({
      showErrorPopup: true,
      serverErrorMessage: isServerError ? message : '',
      errorHeading: isServerError ? heading : '',
    });
  };

  handleMenuItemClicked = (item) => {
    this.setState({ selectedMenu: item });
  }

  render() {
    return (
      <div
        className={`settings-sidebar h-100 ${this.props.isCallDashboard ? 'position-absolute' : ''}`}
        tabIndex='-1'
      >
        <ErrorModal
          open={this.state.showErrorPopup}
          header={this.state.errorHeading}
          errorMessage={this.state.serverErrorMessage}
          onClose={this.handleDismissError}
          rightButton={[
            {
              text: translate('login.okButton'),
              onClick: this.handleDismissError,
            },
          ]}
        />

        {
          (isMobileOnly) ? (
            <Sidebar>
              <Row lg={8} md={8} sm={8} xs={8} className='landscape-side-margins pt-2 px-3'>
                <Col className='d-lg-none d-xl-none' xs={1} sm={1} lg={1} md={1}>
                  <BackIcon
                    className='back-icon'
                    alt='back'
                    role='presentation'
                    onClick={this.props.goBack}
                  />
                </Col>
                <Col className='alignment'>
                  <span className='header-label ps-1'>
                    {translate('headerMenus.settings')}
                  </span>
                </Col>
              </Row>
              <Row className='px-2'>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className='divider' />
                </Col>
              </Row>
              <div className='settings-scroll-landscape'>
                {this.props.iconSet.map((data) => (
                  <Row
                    lg={8}
                    md={8}
                    sm={8}
                    className={`mt-3 px-3 alignment landscape-side-margins more-action-items ${data.disabled ? ' disabled' : ''}
                    `}
                    key={data.name}
                  >
                    <div
                      role='presentation'
                      onClick={() =>
                        this.props.clickHandler({ mainMenu: SETTINGS, selectedMenu: data.id })
                      }
                      className='p-1 align-horizontal-start'
                    >
                      <span className='ps-1 more-action-items'>
                        {data.name}
                      </span>
                    </div>
                  </Row>
                ))
                }
              </div>
            </Sidebar>
          ) : (
            <Modal
              role='dialog'
              open={this.state.isOpen}
              center
              showCloseIcon={false}
              classNames={{
                modal: 'custom-modal-settings',
              }}
              onClose={this.props.onClose}
              closeOnOverlayClick={false}
              onEscKeyDown={this.props.onClose}
              focusTrapped={false}
            >
              <Row className='settings-modal-body'>
                <Col className='settings'>
                  <Row className='modal-header'>
                    <Col className='pl-4'>
                      <span className='header-style'>
                        {translate(this.props.header)}
                      </span>
                    </Col>
                    <Col>
                      <span className='close-btn'>
                        <CloseIcon
                          alt={translate('settings.closeButton')}
                          role='presentation'
                          className='close-icon cursor-pointer'
                          onClick={this.props.goBack}
                        />
                      </span>
                    </Col>
                  </Row>
                  <Row className='p-2 modal-body-contents '>
                    {/* customComponent/s imported should go here */}
                    <Col className='left-panel'>
                      <SideNav>
                        {this.props.iconSet.map((data) => (
                          <Row key={data.name}>
                            <CustomButton
                              className={`settings-tabs ${this.state.selectedMenu === data.id && 'active-icon'} `}
                              key={data.name}
                              disabled={data.disabled}
                              onClick={() =>
                                this.handleMenuItemClicked(data.id)
                              }
                              variant=''
                            >
                              <span className='ellipsis'>
                                {data.name}
                              </span>
                            </CustomButton>
                          </Row>
                        ))
                        }

                      </SideNav>
                    </Col>
                    <Col
                      md={isDesktop ? 8 : 7}
                      sm={7} // samsung tab s4
                      className='right-side'
                    >
                      <ExpandedComponent
                        selection={this.state.selectedMenu}
                        userData={this.props.userData}
                        clientSettingsData={this.props.clientSettingsData}
                        selectedVideoProfile={this.props.selectedVideoProfile}
                        setSelectedProfile={(profile) => this.props.setSelectedProfile(profile)}
                        networkSettings={this.props.networkSettings}
                        securitySettings={this.props.securitySettings}
                        policyUISettings={this.props.policyUISettings}
                        videoProfiles={this.props.videoProfiles}
                        clientPolicyPermissionsXmlData={this.props.clientPolicyPermissionsXmlData}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal>
          )}
      </div>
    );
  }
}

export default Settings;
