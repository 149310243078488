/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Modal from 'react-responsive-modal';

import CustomButton from 'COMPONENTS/CustomComponents/CustomButton/CustomButton';

// Translation
import { translate } from 'SERVICES/i18n';

// Constant
import { BUTTON_TYPES } from 'UTILS/constants/UtilityConstants';

// Asset
import { ReactComponent as DotSpinnerIcon } from 'ASSETS/DotSpinner.svg';

// Style
import 'react-responsive-modal/styles.css';
import './ErrorModal.scss';

// eslint-disable-next-line react/prefer-stateless-function
class ErrorModal extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      isOpen: nextProps.open,
    };
  }

  componentWillUnmount() {
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
      this.closeTimeout = null;
    }
  }

  checkForModalTimeout() {
    if (this.props.timeout) {
      this.closeTimeout = setTimeout(() => {
        if (this.props.open) {
          this.props.onClose();
        }
      }, this.props.timeout);
    }
  }

  render() {
    const errorMessage = this.props.interpolationText
      ? translate(this.props.errorMessage, {
        interpolationText: this.props.interpolationText,
      })
      : translate(this.props.errorMessage);
    this.checkForModalTimeout();
    return (
      <Modal
        modalId={this.props.id ? this.props.id : ''}
        role='dialog'
        open={this.state.isOpen}
        center
        showCloseIcon={false}
        classNames={{
          modal: `custom-modal  ${this.props.className}`,
        }}
        onClose={this.props.onClose}
        closeOnOverlayClick={false}
        onEscKeyDown={this.props.onClose}
        focusTrapped={false}
      >
        <Row className='modal-body' tabIndex='-1'>
          <Col>
            {
              this.props.showSpinner && (
                <div className='d-flex justify-content-end'>
                  <DotSpinnerIcon className='dot-spinner-icon' />
                </div>
              )}
            <Row className={`${this.props.centerClass && 'justify-content-center'} p-2 pt-0 modal-header ellipsis`}>
              {translate(this.props.header)}
            </Row>
            <Row className={`p-2 modal-body-contents text-wrap ${this.props.centerClass && 'justify-content-center'}`}>
              {translate(errorMessage)}
            </Row>
            <Row className={`${this.props.centerClass ? 'center-items' : 'align-items-end-center'} pt-4 `}>
              {this.props.leftButton &&
                this.props.leftButton.map((button, index) => (
                  <CustomButton
                    id='error-modal-left'
                    key={index}
                    className={`align-center action-buttons cancel-button ${button.buttonClass}`}
                    onClick={button.onClick}
                    text={button.text}
                    variant={BUTTON_TYPES.SECONDARY}
                  />
                ))}
              {this.props.rightButton &&
                this.props.rightButton.map((button, index) => (
                  <CustomButton
                    id='error-modal-right'
                    tabIndex='0'
                    key={index}
                    className={`align-center action-buttons ${button.buttonClass}`}
                    onClick={button.onClick}
                    disabled={button.disabled}
                    type={button.type}
                    text={button.text}
                  />
                ))}
            </Row>
          </Col>
        </Row>
      </Modal>
    );
  }
}

ErrorModal.propTypes = {
  header: PropTypes.string,
  interpolationText: PropTypes.string,
  errorMessage: PropTypes.string,
  // okButton: PropTypes.objectOf,
  // cancelButton: PropTypes.objectOf,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  showSpinner: PropTypes.bool,
};

ErrorModal.defaultProps = {
  header: '',
  interpolationText: '',
  errorMessage: '',
  // okButton: {},
  // cancelButton: {},
  onClose: () => { },
  open: false,
  showSpinner: false,
};

export default ErrorModal;
